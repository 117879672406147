import { Instance, SnapshotOut, types } from "mobx-state-tree"
import { EpgData } from '../epg-data/EpgData';
import { QuetoneData } from '../quetone-data/QuetoneData';
import { DpccLogData } from '../dpcc-log-data/DpccLogData';
import { BquetoneScheduleData } from '../bquetone-schedule-data/BquetoneScheduleData';
import { selectOptions } from "../../components/forms/CSelect";
import { DpaudioData } from "../dpcc-log-data/DpaudioData";

/**
 * # Quetone
 * 
 * Quetone을 설명하세요.
 */
export const Quetone = types
  .model("Quetone")
  // --------------------------------------------------------------------------
  .props({
    /**
     * A quetone
     * alarm event data 로 위에 차트
     *  epgData + quetoneData 로 타임라인
     * 
     * B quetone
     * bqtoneScheduleData + quetoneData
     */

    serviceId: types.optional(types.number, 0),
    chNm: types.optional(types.string, ''),
    nextQuetoneDate: types.optional(types.maybeNull(types.string), ''),
    epgData: types.optional(types.maybeNull(types.array(EpgData)), []),
    quetoneData: types.optional(types.maybeNull(types.array(QuetoneData)), []),
    dpccLogData: types.optional(types.maybeNull(types.array(DpccLogData)), []),
    dpaudioData: types.optional(types.maybeNull(types.array(DpaudioData)), []),
    bqtoneScheduleData: types.optional(types.maybeNull(types.array(BquetoneScheduleData)), []),
  })
  // --------------------------------------------------------------------------
  // MUTATEs ONLY! - 모델 상태를 변경
  // REQUESTs는 스토어 모델에 작성하세요.
  // ex) yarn gen model quetone --store
  .actions((self) => ({
    /*
     * 여러 prop을 한번에 수정
     */
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as IQuetone;
      (Object.keys(newProps) as TQuetoneKeys[]).forEach((key) => {
        // @ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type TQuetone = Instance<typeof Quetone>;
type TQuetoneSnapshot = SnapshotOut<typeof Quetone>

export interface IQuetone extends TQuetone { }
export type TQuetoneKeys = keyof TQuetoneSnapshot & string;
export interface IQuetoneSnapshot extends TQuetoneSnapshot { }
export const createQuetone = () =>
  types.optional(Quetone, {
    serviceId: 0,
    chNm: '',
    epgData: null,
    quetoneData: null,
    dpccLogData: null,
    dpaudioData: null,
    bqtoneScheduleData: null,
  } as TQuetone);


export interface IQuetoneFilter {
  type?: 'a' | 'b' | 'd' | 'dpcc' | 'dpaudio';
  quetoneDtSt?: string;
  quetoneDtEd?: string;
  chSvcIdList?: string[];
  chSvcIdLists?: selectOptions[];
  dpDtSt?: string;
  dpDtEd?: string;
  menuType?: string;

  occurredEvent?: boolean;
}

export type TQuetoneFilter = IQuetoneFilter;