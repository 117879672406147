import { Instance, SnapshotOut, types } from "mobx-state-tree"

/**
 * # BquetoneScheduleData
 * 
 * BquetoneScheduleData을 설명하세요.
 */
export const BquetoneScheduleData = types
  .model("BquetoneScheduleData")
  // --------------------------------------------------------------------------
  .props({
    serviceId: types.optional(types.number, 0),
    startTime: types.optional(types.number, 0),
    endTime: types.optional(types.number, 0),
    durationTime: types.optional(types.maybeNull(types.number), null),
    cmGubun: types.optional(types.string, '')
  })
  // --------------------------------------------------------------------------
  // MUTATEs ONLY! - 모델 상태를 변경
  // REQUESTs는 스토어 모델에 작성하세요.
  // ex) yarn gen model bquetoneScheduleData --store
  .actions((self) => ({
    /*
     * 여러 prop을 한번에 수정
     */
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as IBquetoneScheduleData;
      (Object.keys(newProps) as TBquetoneScheduleDataKeys[]).forEach((key) => {
        // @ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type TBquetoneScheduleData = Instance<typeof BquetoneScheduleData>;
type TBquetoneScheduleDataSnapshot = SnapshotOut<typeof BquetoneScheduleData>

export interface IBquetoneScheduleData extends TBquetoneScheduleData { }
export type TBquetoneScheduleDataKeys = keyof TBquetoneScheduleDataSnapshot & string;
export interface IBquetoneScheduleDataSnapshot extends TBquetoneScheduleDataSnapshot { }
export const createBquetoneScheduleData = () =>
  types.optional(BquetoneScheduleData, {
    serviceId: 0,
    cmGubun: ''
  } as TBquetoneScheduleData);
