import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { withEnvironment } from '../extensions/with-environment';
import { IRatingStatistics, withRootStore } from '../../models';
import { RatingStatisticsApi, TGetRatingStatisticssResult } from '../../services';
import { RatingStatistics, TRatingStatisticsFilter } from '../rating-statistics/RatingStatistics';
import { timestampToDate } from '../../utils/dateExchanger';
/**
 * # RatingStatisticsStore
 *
 * RatingStatisticsStore을 설명하세요.
 */

export const RatingStatisticsStore = types
  .model('RatingStatisticsStore')
  .props({
    // 시청률
    ratingStatisticss: types.optional(types.array(RatingStatistics), []),
    // 점유율
    occupyStatistices: types.optional(types.array(RatingStatistics), []),
    // 시청가구
    viewsStatistices: types.optional(types.array(RatingStatistics), []),
  })
  .extend(withEnvironment)
  .extend(withRootStore)
  .views((self) => ({}))
  .actions((self) => ({
    handleResponseData: (dataList: IRatingStatistics[]) => {
      dataList.forEach((item: IRatingStatistics) => {
        item.statDt = item.statDt ? timestampToDate(Number.parseInt(item.statDt)) : '';
        item.preStatDt = item.preStatDt ? timestampToDate(Number.parseInt(item.preStatDt)) : '';
      });
      return dataList;
    },
    cleanStore: () => {
      self.ratingStatisticss.clear();
      self.occupyStatistices.clear();
      self.viewsStatistices.clear();
    },
    // 시청률
    setRatingStatisticss: (ratingStatisticss: IRatingStatistics[]) => {
      self.ratingStatisticss.replace(ratingStatisticss);
    },
    // 전유율
    setOccupyStatistices: (occupyStatistices: IRatingStatistics[]) => {
      self.occupyStatistices.replace(occupyStatistices);
    },
    // 시청가구
    setViewsStatistices: (viewsStatistices: IRatingStatistics[]) => {
      self.viewsStatistices.replace(viewsStatistices);
    },
  }))
  .actions((self) => ({
    /**
     * 시청률 통계 조회
     * @param filter
     */
    getRatingsStat: async (filter: TRatingStatisticsFilter) => {
      self.cleanStore();
      try {
        const api: RatingStatisticsApi = new RatingStatisticsApi(self.environment.api);
        let result: TGetRatingStatisticssResult;

        result = await api.getRatingsStat(filter);

        if (self.rootStore.responseStore.getResponseResult(result) && result.data) {
          self.setRatingStatisticss(self.handleResponseData(result.data));
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },
    /**
     * 점유율 통계 조회
     * @param filter
     */
    getOccupyStat: async (filter: TRatingStatisticsFilter) => {
      self.cleanStore();
      try {
        const api: RatingStatisticsApi = new RatingStatisticsApi(self.environment.api);
        let result: TGetRatingStatisticssResult;

        result = await api.getOccupyStat(filter);

        if (self.rootStore.responseStore.getResponseResult(result) && result.data) {
          self.setOccupyStatistices(self.handleResponseData(result.data));
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },
    /**
     * 시청가구 통계 조회
     * @param filter
     */
    getViewsStat: async (filter: TRatingStatisticsFilter) => {
      self.cleanStore();
      try {
        const api: RatingStatisticsApi = new RatingStatisticsApi(self.environment.api);
        let result: TGetRatingStatisticssResult;

        result = await api.getViewStat(filter);

        if (self.rootStore.responseStore.getResponseResult(result) && result.data) {
          self.setViewsStatistices(self.handleResponseData(result.data));
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },
  }));

// --------------------------------------------------------------------------
type TRatingStatisticsStore = Instance<typeof RatingStatisticsStore>;
type TRatingStatisticsStoreSnapshot = SnapshotOut<typeof RatingStatisticsStore>;

export interface IRatingStatisticsStore extends TRatingStatisticsStore {}
export type TRatingStatisticsStoreKeys = keyof TRatingStatisticsStoreSnapshot & string;
export interface IRatingStatisticsStoreSnapshot extends TRatingStatisticsStoreSnapshot {}
export const createRatingStatisticsStore = () =>
  types.optional(RatingStatisticsStore, {} as TRatingStatisticsStore);
