export const timestampToDate = (dateTimeStampValue: number) => {
  if (!dateTimeStampValue) {
    return ``;
  }
  var day = new Date(dateTimeStampValue);
  day.setHours(day.getHours() + 9);
  return day.toISOString().replace('T', ' ').substring(0, 19);
};

export const strStampToDate = (str: string | null) => {
  if (!str) {
    return ``;
  }

  var day = new Date(Number.parseInt(str));
  day.setHours(day.getHours() + 9);
  return day.toISOString().replace('T', ' ').substring(0, 19);
};

export const dateToTimestamp = (dateValue: any) => {
  return new Date(dateValue).getTime();
};
