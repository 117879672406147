import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { SnackbarKey, useSnackbar } from 'notistack';
// ----------------------------------------------------------------------

interface SnackBarCloseButtonProp {
  id: SnackbarKey;
}

export default function SnackBarCloseButton( { id }: SnackBarCloseButtonProp) {
  const { closeSnackbar } = useSnackbar();

  return (
    <IconButton
      onClick={() => {
        closeSnackbar(id);
      }}
    >
      <CloseIcon />
    </IconButton>
  );
}
