import { Instance, SnapshotOut, types } from 'mobx-state-tree';

/**
 * # Response
 *
 * Response을 설명하세요.
 */
export const Response = types
  .model('Response')
  // --------------------------------------------------------------------------
  .props({
    resultCode: types.optional(types.maybeNull(types.string), null),
    errorCode: types.optional(types.maybeNull(types.string), null),
    title: types.optional(types.maybeNull(types.string), null),
    errorMessage: types.optional(types.maybeNull(types.string), null),
    details: types.optional(types.maybeNull(types.array(types.string)), null),
  })
  .actions((self) => ({
    resetResponseInfo: () => {
      self.resultCode = null;
      self.errorCode = null;
      self.title = null;
      self.errorMessage = null;
      self.details = null;
      // self.details.replace([] as string[]);
    },
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as IResponse;
      (Object.keys(newProps) as TResponseKeys[]).forEach((key) => {
        //@ts-ignore
        self[key] = newProps[key];
      });
    },
    reset: () => {
      self.resultCode = null;
      self.errorCode = null;
      self.title = null;
      self.errorMessage = null;
      self.details = null;
    },
  }));

// --------------------------------------------------------------------------
type TResponse = Instance<typeof Response>;
type TResponseSnapshot = SnapshotOut<typeof Response>;

export interface IResponse extends TResponse {}
export type TResponseKeys = keyof TResponseSnapshot & string;
export interface IResponseSnapshot extends TResponseSnapshot {}
export const createResponse = () =>
  types.optional(Response, {
    resultCode: null,
    errorCode: null,
    title: null,
    errorMessage: null,
    details: [] as string[],
  } as TResponse);
