import { Instance, SnapshotOut, types } from 'mobx-state-tree';

/**
 * Model description here for TypeScript hints.
 */

export const UserLoginHistoryModel = types
  .model('UserLoginHistory')
  .props({
    loginId: types.optional(types.maybeNull(types.string), ''),
    userNm: types.optional(types.maybeNull(types.string), ''),
    clntAddr: types.optional(types.maybeNull(types.string), ''),
    sucesYn: types.optional(types.boolean, true),
    loginFailErr: types.optional(types.maybeNull(types.string), ''),
    loginReqTime: types.optional(types.string, ''),

    //검색조건
    // regSdt: types.optional(types.number || types.string, 0),
    // regEdt: types.optional(types.number || types.string, 0),
  })
  .views((self) => ({}))
  .actions((self) => ({
    resetUser: () => {
      self.loginId = '';
      self.userNm = '';
      self.clntAddr = '';
      self.sucesYn = true;
      self.loginFailErr = '';
      self.loginReqTime = '';
    },

    setProps: (props: { [key: string]: any }) => {
      const updated = { ...self, ...props } as IUserLoginHistory;
      (Object.keys(updated) as TUserLoginHistoryTypeKey[]).forEach((key) => {
        if (typeof self[key] === typeof updated[key]) {
          //@ts-ignore
          self[key] = updated[key];
        } else {
          throw new Error(`${key.toString} Input Type Error`);
        }
      });
    },
    // setDefaultUsrgrpOptions: (defaultUsrgrpOptions: IModelCode[]) => {
    //   self.defaultUsrgrpOptions.replace(defaultUsrgrpOptions);
    // },
  }));

type TUserLoginHistoryType = Instance<typeof UserLoginHistoryModel>;
type TUserLoginHistorySnapshotType = SnapshotOut<typeof UserLoginHistoryModel>;

export interface IUserLoginHistory extends TUserLoginHistoryType {}
export type TUserLoginHistoryTypeKey = keyof TUserLoginHistorySnapshotType & string;
export interface IUserLoginHistorySnapshot extends TUserLoginHistorySnapshotType {}

