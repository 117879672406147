import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { IntrlkSysSrvr } from '../intrlk-sys-srvr/IntrlkSysSrvr';
import { createModelCode } from '../model-code/ModelCode';
import { createSimpleUser } from '../simple-user/SimpleUser';

export const IntrlkSys = types
  .model('IntrlkSys')
  .props({
    intrlkSysSid: types.optional(types.number, 0),
    intrlkSysNm: types.optional(types.string, ''),
    sysNm: types.optional(types.string, ''),
    sysMngr: types.optional(types.maybeNull(types.string), ''),
    mnfcSid: types.optional(types.number, 0),
    mnfcNm: types.optional(types.maybeNull(types.string), ''),
    vendorSid: types.optional(types.number, 0),
    vendorNm: types.optional(types.maybeNull(types.string), ''),
    haType: types.maybeNull(createModelCode()),
    sysType: types.maybeNull(createModelCode()),
    vip: types.optional(types.maybeNull(types.string), ''),
    mgmtEquipType: types.optional(types.maybeNull(types.string), ''),
    regDt: types.optional(types.string, ''),
    updDt: types.optional(types.string, ''),
    //구버전
    regId: types.optional(types.number, 0),
    updId: types.optional(types.number, 0),
    //신버전
    regUser: types.maybeNull(createSimpleUser()),
    updUser: types.maybeNull(createSimpleUser()),

    intrlkSysSrvrList: types.optional(types.array(IntrlkSysSrvr), []),
  })
  .actions((self) => ({
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as IIntrlkSys;
      (Object.keys(newProps) as TIntrlkSysKeys[]).forEach((key) => {
        //@ts-ignore
        self[key] = newProps[key];
      });
    },
    resetIntrlkSys: () => {
      self.intrlkSysSid = 0;
      self.intrlkSysNm = '';
      self.sysNm = '';
      self.sysMngr = '';
      self.mnfcSid = 0;
      self.mnfcNm = '';
      self.vendorSid = 0;
      self.vendorNm = '';
      self.vip = '';
      self.mgmtEquipType = '';
      self.regDt = '';
      self.regId = 0;
      self.updDt = '';
      self.updId = 0;
    },
  }));

type TIntrlkSys = Instance<typeof IntrlkSys>;
type TIntrlkSysSnapshot = SnapshotOut<typeof IntrlkSys>;

export interface IIntrlkSys extends TIntrlkSys {}
export type TIntrlkSysKeys = keyof TIntrlkSysSnapshot & string;
export interface IIntrlkSysSnapshot extends TIntrlkSysSnapshot {}
export const createIntrlkSys = () =>
  types.optional(IntrlkSys, {
    intrlkSysSid: 0,
    intrlkSysNm: '',
    sysNm: '',
    sysMngr:'',
    mnfcSid: 0,
    mnfcNm: '',
    vendorSid: 0,
    vendorNm: '',
    vip: '',
    mgmtEquipType: '',
    regDt: '',
    regId: 0,
    updDt: '',
    updId: 0,
  } as TIntrlkSys);
