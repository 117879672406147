import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { createModelCode } from '../model-code/ModelCode';
import { createSimpleUser } from './../simple-user/SimpleUser';

export const IntrlkSysSrvr = types
  .model('IntrlkSysSrvr')
  .props({
    intrlkSysSrvrSid: types.optional(types.number, 0),
    intrlkSysSid: types.optional(types.number, 0),
    sysNm: types.optional(types.maybeNull(types.string), ''),
    haSttus: createModelCode(),
    mngIp: types.optional(types.string, ''),
    //helthySttus: createModelCode(),
    regDt: types.optional(types.string, ''),
    updDt: types.optional(types.string, ''),
    //구버전
    regId: types.optional(types.number, 0),
    updId: types.optional(types.number, 0),
    //신버전
    regUser: types.maybeNull(createSimpleUser()),
    updUser: types.maybeNull(createSimpleUser()),
    haSttusCode: types.optional(types.number, 0),
    //helthySttusCode: types.optional(types.number, 0),
    sysSrvrNm: types.optional(types.maybeNull(types.string), ''),
  })
  .actions((self) => ({
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as IIntrlkSysSrvr;
      (Object.keys(newProps) as TIntrlkSysSrvrKeys[]).forEach((key) => {
        //@ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

type TIntrlkSysSrvr = Instance<typeof IntrlkSysSrvr>;
type TIntrlkSysSrvrSnapshot = SnapshotOut<typeof IntrlkSysSrvr>;

export interface IIntrlkSysSrvr extends TIntrlkSysSrvr {}
export type TIntrlkSysSrvrKeys = keyof TIntrlkSysSrvrSnapshot & string;
export interface IIntrlkSysSrvrSnapshot extends TIntrlkSysSrvrSnapshot {}
export const createIntrlkSysSrvr = () =>
  types.optional(IntrlkSysSrvr, {
    intrlkSysSrvrSid: 0,
    intrlkSysSid: 0,
    sysSrvrNm: '',
    mngIp: '',
    regDt: '',
    regId: 0,
    updDt: '',
    updId: 0,
  } as TIntrlkSysSrvr);

export interface IIntrlkSysSrvrFilter {
  intrlkSysSid?: number;
  sysNm?: string;
  intrlkSysSrvrSid?: number;
  sysSrvrNm?: string;
}
