import { getTypeByValue } from '@mui/utils/integerPropType';
import { Instance, SnapshotOut, types } from 'mobx-state-tree';

/**
 * # DpLogData
 * 
 * DpLogData을 설명하세요.
 */
export const DpccLogData = types
  .model("DpccLogData")
  // --------------------------------------------------------------------------
  .props({
    dpccDtSt: types.optional(types.maybeNull(types.number), null),
    dpccDtEd: types.optional(types.maybeNull(types.number), null),
    chSvcId: types.optional(types.number, 0),
    signlLoc: types.optional(types.string, ''),
    startYn: types.optional(types.boolean, false),
    subTitleYn: types.optional(types.boolean, true),
    subTitleRealYn: types.optional(types.boolean, true),
    chNm: types.optional(types.string, ''),
    title: types.optional(types.string, ''),
    regDt: types.optional(types.maybeNull(types.number), null),
  })
  // --------------------------------------------------------------------------
  // MUTATEs ONLY! - 모델 상태를 변경
  // REQUESTs는 스토어 모델에 작성하세요.
  // ex) yarn gen model dpccLogData --store
  .actions((self) => ({
    /*
     * 여러 prop을 한번에 수정
     */
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as IDpccLogData;
      (Object.keys(newProps) as TDpccLogDataKeys[]).forEach((key) => {
        // @ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type TDpccLogData = Instance<typeof DpccLogData>;
type TDpccLogDataSnapshot = SnapshotOut<typeof DpccLogData>

export interface IDpccLogData extends TDpccLogData { }
export type TDpccLogDataKeys = keyof TDpccLogDataSnapshot & string;
export interface IDpccLogDataSnapshot extends TDpccLogDataSnapshot { }
export const createDpccLogData = () =>
  types.optional(DpccLogData, {
    chSvcId: 0,
    signlLoc: '',
    startYn: false,
    chNm: '',
  } as TDpccLogData);
