import { ApiResponse } from 'apisauce';
import { Api } from '../api/api';
import { getGeneralApiProblem } from '../api/api-problem';
import { RESULT_CODE } from './../../components/settings/SettingConstEnum';
import { ILinkPortSnapshot } from './../../models/link-port/LinkPort';
import { IPortSnapshot } from './../../models/port/Port';
import { IResponseSnapshot } from './../../models/response/Response';
import { TLinksResult, TSelectableResult } from './LinkPortTypes';

/**
 * # LinkPort Api 서비스
 *
 * 서비스의 설명을 작성하세요.
 *
 * ## 사용방법
 *
 * ```ts
 * const service = new LinkPortApi();
 *
 * ```
 */
export class LinkPortApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  // /topology/{topolId}/link/{topolLinkId}/port
  async getLinksFromTopology(topolId: string, topolLinkId: string): Promise<TLinksResult> {
    try {
      const url: string = `/topology/${topolId}/link/${topolLinkId}/port`;
      const response: ApiResponse<any> = await this.api.apisauce.get(url);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;
      if (responseInfo.resultCode === RESULT_CODE.SUCCESS) {
        if (response.data.data) {
          return {
            kind: `ok`,
            responseInfo: responseInfo,
            linkPorts: response.data.data,
          };
        }
      }
      return { kind: `ok`, responseInfo: responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  // topology/{topolId}/link/{topolLinkId}/port/{topolEquipPortCnctSid}
  async putLinkOfTopology(
    linkPorts: ILinkPortSnapshot[],
    topolId: string,
    linkId: string,
  ): Promise<TLinksResult> {
    try {
      const url: string = `/topology/${topolId}/link/${linkId}/port`;

      const response: ApiResponse<any> = await this.api.apisauce.put(url, linkPorts);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;
      if (responseInfo.resultCode === RESULT_CODE.SUCCESS) {
        if (response.data.data) {
          return {
            kind: `ok`,
            responseInfo: responseInfo,
            linkPorts: response.data.data,
          };
        }
      }
      return { kind: `ok`, responseInfo: responseInfo };
    } catch (e) {
      return { kind: `bad-data` };
    }
  }

  async getSelectablePorts(
    srcEquipGroupSid: number,
    srcGroupYn: boolean,
    dstEquipGroupSid: number,
    dstGroupYn: boolean,
  ): Promise<TSelectableResult> {
    try {
      const url: string = `/topology/link/port/select`;
      const param: any = {
        srcEquipGroupSid: srcEquipGroupSid,
        srcGroupYn: srcGroupYn,
        dstEquipGroupSid: dstEquipGroupSid,
        dstGroupYn: dstGroupYn,
      };
      const response: ApiResponse<any> = await this.api.apisauce.get(url, param);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;

      if (responseInfo.resultCode === RESULT_CODE.SUCCESS) {
        if (response.data.data) {
          let srcPortList: IPortSnapshot[] = response.data.data.srcPortList as IPortSnapshot[];
          let dstPortList: IPortSnapshot[] = response.data.data.dstPortList as IPortSnapshot[];

          return {
            srcPortList: srcPortList,
            dstProtList: dstPortList,
            kind: `ok`,
            responseInfo: responseInfo,
          };
        }
      }

      return { kind: `ok`, responseInfo: responseInfo };
    } catch (e) {
      return { kind: `bad-data` };
    }
  }

  // 실제 장비 연결 포트 목록 조회 API - /topology/link/port/real
  async getRealPortOfLink(
    srcEquipGroupSid: number,
    srcGroupYn: boolean,
    dstEquipGroupSid: number,
    dstGroupYn: boolean,
  ): Promise<TLinksResult> {
    try {
      const url: string = `/topology/link/port/real`;
      const param: any = {
        srcEquipGroupSid: srcEquipGroupSid,
        srcGroupYn: srcGroupYn,
        dstEquipGroupSid: dstEquipGroupSid,
        dstGroupYn: dstGroupYn,
      };
      // ?srcEquipGroupSid=164&srcGroupYn=false&dstEquipGroupSid=157&dstGroupYn=false
      // const response: ApiResponse<any> = await this.api.apisauce.get(url, param);
      const response: ApiResponse<any> = await this.api.apisauce.get(
        url + `?srcEquipGroupSid=161&srcGroupYn=false&dstEquipGroupSid=157&dstGroupYn=false`,
      );

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;

      if (responseInfo.resultCode === RESULT_CODE.SUCCESS) {
        if (response.data.data) {
          for (let i = 0; i < response.data.data.length; i++) {
            delete response.data.data[i].dstDevSid;
            delete response.data.data[i].srcDevSid;
            delete response.data.data[i].equipCnctSid;
          }
          return {
            kind: `ok`,
            responseInfo: responseInfo,
            linkPorts: response.data.data as ILinkPortSnapshot[],
          };
        }
      }
      return { kind: `ok`, responseInfo: responseInfo };
    } catch (e) {
      return { kind: `bad-data` };
    }
  }
}
