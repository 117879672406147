import moment from "moment";

/**
 * 숫자 랜덤 추출
 * @param max  최대 값
 * @returns
 */
export function randomNumber(max: number) {
  return Math.floor(Math.random() * (max + 1));
}

/**
 * rgb 랜덤 추출
 * @returns
 */
export function randomRgbColor() {
  let r = randomNumber(255);
  let g = randomNumber(255);
  let b = randomNumber(255);
  return [r, g, b];
}

/**
 * reg Hex 랜덤 추출
 * @returns
 */
export function randomHexColor() {
  let [r, g, b] = randomRgbColor();

  let hr = r.toString(16).padStart(2, '0');
  let hg = g.toString(16).padStart(2, '0');
  let hb = b.toString(16).padStart(2, '0');

  return '#' + hr + hg + hb;
}

export function getEventState(state: string | null | undefined = '') {
  let res: string = '';
  switch (state) {
    case 'Outstanding':
      res = '발생';
      break;
    case 'Acknowledged':
      res = '인지';
      break;
    case 'Cleared':
      res = '삭제';
      break;
    case 'Terminated':
      res = '종료';
      break;
    case 'Purged':
      res = '제거';
      break;
    default:
      res = '알수없음'
      break;
  }

  return res;
}

export function formatEventTime(t: string | number | null) {
  if(t === null || t === ''){
    return '';
  }
  return moment(t).format('MM-DD HH:mm:ss');
}

export function formatEventTimeBasic(t: string | number | null) {
  if(t === null || t === ''){
    return '';
  }
  return moment(t).format('HH:mm:ss');
}


export function sleep(ms: number) {
  const wakeUpTime = Date.now() + ms;
  while (Date.now() < wakeUpTime) {}
}
