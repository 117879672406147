import { Instance, SnapshotOut, types } from "mobx-state-tree"

  import { withEnvironment } from "../extensions/with-environment"; 
  import { 
    IChannelDbs,
    createChannelDbs,
    IChannelDbsSnapshot,
    ChannelDbs
  } from "../../models/channel-dbs/ChannelDbs"; 
  import { 
    ChannelDbsApi
  } from "../../services/channel-dbs/ChannelDbs"; 
  import {
    TGetChannelDbsBadgeResult,
    TGetChannelDbsResult
  } from "../../services/channel-dbs/ChannelDbsTypes"; 
import { withRootStore } from "../extensions/with-root-store";
import { timestampToDate } from "../../utils/dateExchanger";
import { DbsInfo, IDbsInfo, IDbsInfoSnapshot } from "../channel-dbs/DbsIno";

/**
 * # ChannelDbsStore
 * 
 * ChannelDbsStore을 설명하세요.
 */
export const ChannelDbsStore = types
  .model("ChannelDbsStore")
  // --------------------------------------------------------------------------
  .props({
    badge: types.optional(types.boolean, true),
    channelDbs : createChannelDbs(),
    dbsInfoList : types.optional(types.array(DbsInfo), [])
  })
  .extend(withRootStore)
  .extend(withEnvironment)
  .views((self) => ({}))
  // --------------------------------------------------------------------------
  // MUTATEs - 모델 상태를 변경
  .actions((self) => ({
    setBadge: (badge: boolean) => {
      self.badge = badge;
    },
    setChannelDbs: (channelDbs: IChannelDbsSnapshot) => {
      self.channelDbs = channelDbs as IChannelDbs;
    },
    setChannelDbsParams: (dbsInfoList: IDbsInfoSnapshot[]) => {
      if(!dbsInfoList)
      {
        return;
      }
      self.channelDbs?.dbsInfoList?.replace(dbsInfoList as IDbsInfo[]);
      // self.channelDbs.dbsInfoList = dbsInfoList as IDbsInfo[];
    },
    setDdbsInfoList: (dbsInfoList: IDbsInfoSnapshot[]) => {
      if (!dbsInfoList) {
        return;
      }
      self.dbsInfoList.replace(dbsInfoList as IDbsInfo[]);
    }
  }))
  // --------------------------------------------------------------------------
  // REQUESTs - 서비스 요청 및 기타 인터페이스 요청 
  .actions((self) => ({
    /**
     * 전체 목록을 Api를 통해 조회
     * 
     * 조회한 결과로 ChannelDbss를 교체한다. 실패시 에러 로그를 남긴다.
     */
    getBadge: async () => {
      try {
        const channelDbsApi : ChannelDbsApi = new ChannelDbsApi(self.environment.api);
        const result: TGetChannelDbsBadgeResult = await channelDbsApi.getChannelDbsBadge();

        if (self.rootStore.responseStore.getResponseResult(result)) {
          if(result.badge != null)
          {
            self.setBadge(result.badge);
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },
    getChannelDbs: async () => {
      self.setDdbsInfoList([]);
      try {
        const channelDbsApi : ChannelDbsApi = new ChannelDbsApi(self.environment.api);
        const result: TGetChannelDbsResult = await channelDbsApi.getChannelDbs();

        if (self.rootStore.responseStore.getResponseResult(result)) {
          if(result.channelDbs)
          {
            if (result.channelDbs.regDt) {
              result.channelDbs.regDt = timestampToDate(Number.parseInt(result.channelDbs.regDt));
            }
            if (result.channelDbs.updDt) {
              result.channelDbs.updDt = timestampToDate(Number.parseInt(result.channelDbs.updDt));
            }

            if (result.channelDbs.dbsInfoList) {
              // result.channelDbs.dbsInfoList.forEach((item: IDbsInfoSnapshot) => {
              //   if (item.tbCh && item.tbCh.regDt) {
              //     item.tbCh.regDt = timestampToDate(Number.parseInt(item.tbCh.regDt));
              //   }
              //   if (item.tbCh && item.tbCh.updDt) {
              //     item.tbCh.updDt = timestampToDate(Number.parseInt(item.tbCh.updDt));
              //   }
              // });

              self.setDdbsInfoList(result.channelDbs.dbsInfoList);
            }
            self.setChannelDbs(result.channelDbs);
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },
    putChannelDbs: async (dbsInfoList: IDbsInfo[]) => {
      try {
        //@ts-ignore
        let channelDbs: IChannelDbs = createChannelDbs();
        channelDbs.chDbsSid = self.channelDbs.chDbsSid;
        channelDbs.fileNm = self.channelDbs.fileNm;
        channelDbs.wrkMemo = self.channelDbs.wrkMemo;
        //@ts-ignore
        channelDbs.dbsInfoList = dbsInfoList as IDbsInfo[];

        const channelDbsApi : ChannelDbsApi = new ChannelDbsApi(self.environment.api);
        const result: TGetChannelDbsResult = await channelDbsApi.putChannelDbs(channelDbs);

        if(self.rootStore.responseStore.getResponseResult(result))
        {
          self.setBadge(true);
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    }
  }))

// --------------------------------------------------------------------------
type TChannelDbsStore = Instance<typeof ChannelDbsStore>;
type TChannelDbsStoreSnapshot = SnapshotOut<typeof ChannelDbsStore>

export interface IChannelDbsStore extends TChannelDbsStore {}
export type TChannelDbsStoreKeys = keyof TChannelDbsStoreSnapshot & string;
export interface IChannelDbsStoreSnapshot extends TChannelDbsStoreSnapshot {}
export const createChannelDbsStore = () => 
  types.optional(ChannelDbsStore, {} as TChannelDbsStore);
