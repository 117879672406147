import { Instance, SnapshotOut, types } from 'mobx-state-tree';

/**
 * # Voc
 *
 * Voc을 설명하세요.
 */
export const Voc = types
  .model('Voc')
  // --------------------------------------------------------------------------
  .props({
    //eaiSeq: types.optional(types.number, 0),
    vocSeq: types.optional(types.string, ''),
    eaiSeqList: types.optional(types.string, ''),
    stsCode: types.optional(types.maybeNull(types.string), ''),
    svcId: types.optional(types.maybeNull(types.string), ''),
    officeScode: types.optional(types.maybeNull(types.string), ''),
    reportOpinion: types.optional(types.maybeNull(types.string), ''),
    prodCode: types.optional(types.maybeNull(types.string), ''),
    prodSubCode: types.optional(types.maybeNull(types.string), ''),
    typeCode: types.optional(types.maybeNull(types.string), ''),
    vocResultCode: types.optional(types.maybeNull(types.string), ''),
    custReqsInfo: types.optional(types.maybeNull(types.string), ''),
    receiptDt: types.optional(types.maybeNull(types.string), ''),
    receiptCenterCode: types.optional(types.maybeNull(types.string), ''),
    ttid: types.optional(types.maybeNull(types.string), ''),
    svcMainTypeCode: types.optional(types.maybeNull(types.string), ''),
    svcSubTypeCode: types.optional(types.maybeNull(types.string), ''),
    svcSubSubTypeCode: types.optional(types.maybeNull(types.string), ''),
    svcSubSubSubTypeCode: types.optional(types.maybeNull(types.string), ''),
    causeDeptDstInCode: types.optional(types.maybeNull(types.string), ''),
    prmVocChargeDeptCode: types.optional(types.maybeNull(types.string), ''),
    troubFacility: types.optional(types.maybeNull(types.string), ''),
    troubCause: types.optional(types.maybeNull(types.string), ''),
    procSummery: types.optional(types.maybeNull(types.string), ''),
    openDt: types.optional(types.maybeNull(types.string), ''),
    repairCode: types.optional(types.maybeNull(types.string), ''),
    sidoCode: types.optional(types.maybeNull(types.string), ''),
  })
  // --------------------------------------------------------------------------
  // MUTATEs ONLY! - 모델 상태를 변경
  // REQUESTs는 스토어 모델에 작성하세요.
  // ex) yarn gen model voc --store
  .actions((self) => ({
    /*
     * 여러 prop을 한번에 수정
     */
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as IVoc;
      (Object.keys(newProps) as TVocKeys[]).forEach((key) => {
        // @ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type TVoc = Instance<typeof Voc>;
type TVocSnapshot = SnapshotOut<typeof Voc>;

export interface IVoc extends TVoc {}
export type TVocKeys = keyof TVocSnapshot & string;
export interface IVocSnapshot extends TVocSnapshot {}
export const createVoc = () =>
  types.optional(Voc, {
    //eaiSeq: 0,
    vocSeq: '',
    eaiSeqList: '',
    stsCode: '',
    svcId: '',
    officeScode: '',
    reportOpinion: '',
    prodCode: '',
    prodSubCode: '',
    typeCode: '',
    vocResultCode: '',
    custReqsInfo: '',
    receiptDt: '',
    receiptCenterCode: '',
    ttid: '',
    svcMainTypeCode: '',
    svcSubTypeCode: '',
    svcSubSubTypeCode: '',
    svcSubSubSubTypeCode: '',
    causeDeptDstInCode: '',
    prmVocChargeDeptCode: '',
    troubFacility: '',
    troubCause: '',
    procSummery: '',
    openDt: '',
    repairCode: '',
    sidoCode: '',
  } as TVoc);

export interface IVocFilter {
  svcId?: string;
  stsCode?: string;
  regDtSt?: string | number;
  regDtEd?: string | number;
  prodSubCode?: string;
  svcMainTypeCode?: string;
  svcSubTypeCode?: string;
  sidoCode?: string;
}
export type TVocFilter = IVocFilter;
