import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { IPairAlarm } from '../../models';
import { PairAlarmApi, TGetPairAlarmsResult } from '../../services';
import { withEnvironment } from '../extensions/with-environment';
import {
  TGetPairAlarmResult,
  TPostPairAlarmResult,
} from './../../services/pair-alarm/PairAlarmTypes';
import { withRootStore } from './../extensions/with-root-store';
import { IPagination, IPaginationSnapshot, createPagination } from './../pagination/Pagination';
import { IPairAlarmSnapshot, IPairAlarmFilter, createPairAlarm } from './../pair-alarm/PairAlarm';

/**
 * # PairAlarmStore
 *
 * PairAlarmStore을 설명하세요.
 */
export const PairAlarmStore = types
  .model('PairAlarmStore')
  // --------------------------------------------------------------------------
  .props({
    pairAlarms: types.optional(types.array(createPairAlarm()), []),
    selctedPairAlarm: types.maybeNull(createPairAlarm()),
    pagination: createPagination(),
    deleteOccEventTypeSids: types.optional(types.array(types.number), []),
  })
  .extend(withEnvironment)
  .extend(withRootStore)
  // eslint-disable-line @typescript-eslint/no-unused-vars
  .views((self) => ({}))
  // --------------------------------------------------------------------------
  // MUTATEs - 모델 상태를 변경
  .actions((self) => ({
    setPairAlarms: (pairAlarms: IPairAlarmSnapshot[]) => {
      self.pairAlarms.replace(pairAlarms as IPairAlarm[]);
    },
    setSelectedPairAlarm: (pairAlarm: IPairAlarmSnapshot) => {
      self.selctedPairAlarm = pairAlarm as IPairAlarm;
    },
    appendDeleteOccEventTypeSids: (occTypeSid: number) => {
      self.deleteOccEventTypeSids?.push(occTypeSid);
    },
    replaceDeleteOccEventTypeSids: (occTypeSids: number[]) => {
      self.deleteOccEventTypeSids?.replace(occTypeSids);
    },
    setPagination: (pagination: IPaginationSnapshot) => {
      self.pagination = pagination as IPagination;
    },
  }))
  // --------------------------------------------------------------------------
  // REQUESTs - 서비스 요청 및 기타 인터페이스 요청
  .actions((self) => ({
    /**
     * INF_CAIMS_00055
     * 설정된 알람 목록을 조회한다.
     * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=1695154716
     * @param pairAlarmFilter
     */
    gets: async (pairAlarmFilter?: IPairAlarmFilter) => {
      self.setPairAlarms([]);
      try {
        const pairAlarmApi: PairAlarmApi = new PairAlarmApi(self.environment.api);
        let result: TGetPairAlarmsResult;
        if (pairAlarmFilter) {
          result = await pairAlarmApi.gets(self.pagination, pairAlarmFilter);
        } else {
          result = await pairAlarmApi.gets(self.pagination);
        }

        if (
          self.rootStore.responseStore.getResponseResult(result) &&
          result.pagination &&
          result.pagination.page === self.pagination.page
        ) {
          if (result.pairAlarms) {
            self.setPairAlarms(result.pairAlarms);
          }
          if (result.pagination) {
            self.setPagination(result.pagination);
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },

    /**
     * INF_CAIMS_00056
     * 해당 Pair 알람의 상세정보를 조회한다.
     * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=935231493
     * @param pairAlarmSid
     */
    get: async (pairAlarmSid: number) => {
      self.selctedPairAlarm = null;
      try {
        const pairAlarmApi: PairAlarmApi = new PairAlarmApi(self.environment.api);
        const result: TGetPairAlarmResult = await pairAlarmApi.get(pairAlarmSid);

        if (self.rootStore.responseStore.getResponseResult(result)) {
          if (result.pairAlarm) {
            self.setSelectedPairAlarm(result.pairAlarm);
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },

    /**
     * INF_CAIMS_00057
     * 새로운 Pair 알람을 등록한다.
     * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=1472439387
     * @param pairAlarm
     */
    post: async (pairAlarm: IPairAlarmSnapshot) => {
      self.selctedPairAlarm = null;
      try {
        const pairAlarmApi: PairAlarmApi = new PairAlarmApi(self.environment.api);
        const result: TPostPairAlarmResult = await pairAlarmApi.post(pairAlarm);

        if (self.rootStore.responseStore.getResponseResult(result)) {
          if (result.pairAlarm) {
            self.setSelectedPairAlarm(result.pairAlarm);
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },

    /**
     * INF_CAIMS_00058
     * 해당 Pair 알람의 정보를 수정한다.
     * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=273969609
     * @param pairAlarm
     */
    put: async (pairAlarm: IPairAlarmSnapshot) => {
      self.selctedPairAlarm = null;
      try {
        const pairAlarmApi: PairAlarmApi = new PairAlarmApi(self.environment.api);
        const result: TPostPairAlarmResult = await pairAlarmApi.put(pairAlarm);

        if (self.rootStore.responseStore.getResponseResult(result)) {
          if (result.pairAlarm) {
            self.setSelectedPairAlarm(result.pairAlarm);
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },
    /**
     * INF_CAIMS_00059
     * 해당 Pair 알람을 삭제한다.
     * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=588343690
     */
    delete: async (pairAlarmSid: number) => {
      try {
        const pairAlarmApi: PairAlarmApi = new PairAlarmApi(self.environment.api);
        const result: TPostPairAlarmResult = await pairAlarmApi.delete(pairAlarmSid);
        self.rootStore.responseStore.getResponseResult(result);
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },
    /**
     * INF_CAIMS_00060
     * 여러개 Pair 알람을 한번에 삭제한다.
     * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=151683040
     */
    deletes: async () => {
      try {
        const pairAlarmApi: PairAlarmApi = new PairAlarmApi(self.environment.api);
        const result: TPostPairAlarmResult = await pairAlarmApi.deletes(
          self.deleteOccEventTypeSids,
        );
        self.rootStore.responseStore.getResponseResult(result);
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },
  }));

// --------------------------------------------------------------------------
type TPairAlarmStore = Instance<typeof PairAlarmStore>;
type TPairAlarmStoreSnapshot = SnapshotOut<typeof PairAlarmStore>;

export interface IPairAlarmStore extends TPairAlarmStore {}
export type TPairAlarmStoreKeys = keyof TPairAlarmStoreSnapshot & string;
export interface IPairAlarmStoreSnapshot extends TPairAlarmStoreSnapshot {}
export const createPairAlarmStore = () => types.optional(PairAlarmStore, {} as TPairAlarmStore);
