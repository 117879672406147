import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { createModelCode } from '../model-code/ModelCode';
import { createSimpleUser } from '../simple-user/SimpleUser';

export const StbStatisticsData = types
  .model('StbStatisticsData')
  .props({
    model: types.optional(types.maybeNull(types.string), ''),
    region: types.optional(types.maybeNull(types.string), ''),
    cnt: types.optional(types.maybeNull(types.number), 0),
    preCnt: types.optional(types.maybeNull(types.number), 0),
    rateCnt: types.optional(types.number, 0),
  })
  .actions((self) => ({
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as IStbStatisticsData;
      (Object.keys(newProps) as TStbStatisticsDataKeys[]).forEach((key) => {
        //@ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type TStbStatisticsData = Instance<typeof StbStatisticsData>;
type TStbStatisticsDataSnapshot = SnapshotOut<typeof StbStatisticsData>;

export interface IStbStatisticsData extends TStbStatisticsData {}
export type TStbStatisticsDataKeys = keyof TStbStatisticsDataSnapshot & string;
export interface IStbStatisticsDataSnapshot extends TStbStatisticsDataSnapshot {}
export const createStbStatisticsData = () =>
  types.optional(StbStatisticsData, {
    model: '',
    region: '',
    cnt: 0,
    preCnt: 0,
    rateCnt: 0,
  } as TStbStatisticsData);
