import { TableRow } from '@mui/material';
import { withStyles } from '@mui/styles';

export const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:hover': {
      backgroundColor: 'rgba(145, 158, 171, 0.2) !important',
    },
    backgroundColor: 'transparent !important',
    whiteSpace: 'nowrap',
    '& td': {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
  },
}))(TableRow);

export default StyledTableRow;
