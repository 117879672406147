import { isNull, isUndefined } from 'lodash';
import { toJS } from 'mobx';
import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { createAuthDefaultModel } from '../../models/auth/Auth';
import { withEnvironment } from '../../models/extensions/with-environment';
import { IUser } from '../../models/user/user';
import { PATH_AUTH } from '../../routes/paths';
import { AuthApi } from './../../services/auth/auth-api';
import {
  GetLoginResult,
  GetLoginTypeResult,
  GetLoginUserInfoResult, LoginResponseTypeResult,
} from './../../services/auth/auth-result-types';
import { IAuthUser, IAuthUserSnapshot } from './../auth-user/AuthUser';
import { withRootStore } from './../extensions/with-root-store';
import {useNavigate} from "react-router-dom";
import {createLoginResponse, ILoginResponse, ILoginResponseSnapshot} from "../login-response/LoginResponse";

/**
 * Model description here for TypeScript hints.
 */
export const LOGIN_USER_STORAGE_NM = 'loginUser';
export const LAST_VIEW_PATH = 'lastViewPath';
export const NOTICE_TEMP = 'temp';
export const AuthStoreModel = types
  .model('AuthStore')
  .props({
    errorMessage: types.optional(types.string, ''),
    isDuplicated: types.optional(types.boolean, false),
    authStatus: createAuthDefaultModel(),
    loginType: types.optional(types.boolean,false),
    loginResponse: createLoginResponse(),
  })
  .extend(withEnvironment)
  .extend(withRootStore)
  .views((self) => ({}))
  // MUTATEs
  .actions((self) => ({
    setIsDuplicated: (isDuplicated: boolean) => {
      self.isDuplicated = isDuplicated;
    },
    setErrorMessage: (errorMessage: string) => {
      self.errorMessage = errorMessage;
    },

    setLastViewPathStorageData: (path?: string) => {
      if (path) {
        window.localStorage.setItem(LAST_VIEW_PATH, path);
      } else {
        window.localStorage.removeItem(LAST_VIEW_PATH);
      }
    },

    processAfterLoginSuccess: async (user: IAuthUserSnapshot) => {
      window.localStorage.setItem(LOGIN_USER_STORAGE_NM, JSON.stringify(user));
      user.menuList = user.menuList.sort(compare);
      self.authStatus.isInitialized = true;
      self.authStatus.isAuthenticated = true;
      self.rootStore.commonStore.socketCommunication();
      window.location.href = '/';
    },
    processAfterLogout: async () => {
      window.localStorage.removeItem(LOGIN_USER_STORAGE_NM);
      window.localStorage.removeItem(LAST_VIEW_PATH);
      self.authStatus.isInitialized = false;
      self.authStatus.isAuthenticated = false;
      self.rootStore.commonStore.socketMessageEnd();
      self.rootStore.commonStore.resetSoketSnackBar();
      self.rootStore.errorAlertStore.resetErrorAlert();
      window.location.href = PATH_AUTH.login;
    },

    getLoginUserStorageData: (): IAuthUserSnapshot | null => {
      const userStr: string | null = window.localStorage.getItem(LOGIN_USER_STORAGE_NM);
      if (userStr) {
        const user: IAuthUserSnapshot = JSON.parse(userStr) as IAuthUser;
        if (isNull(user?.userSid) || isUndefined(user?.userSid)) {
          return null;
        }
        return user;
      }
      return null;
    },
    setLoginType: (loginType: boolean)=> {
      self.loginType = loginType;
    },
    setLoginResponse: (loginResponse: ILoginResponse) => {
      self.loginResponse = loginResponse;
    }
  }))
  // CALL REST APIs
  .actions((self) => ({
    /**
     * Login된 세션의 User 정보
     */
    getLoginUserInfo: async () => {
      try {
        const authApi: AuthApi = new AuthApi(self.environment.api);
        const result: GetLoginUserInfoResult = await authApi.getLoginUserInfo();

        if (self.rootStore.responseStore.getResponseResult(result)) {
          if (result.user) {
            await self.processAfterLoginSuccess(result.user);
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },
    getLoginType: async (id: string, pw: string) => {
      try {
        const authApi: AuthApi = new AuthApi(self.environment.api);
        const result: GetLoginTypeResult = await authApi.getLoginType(id, pw);
        if (self.rootStore.responseStore.getResponseResult(result)) {
          if(result.result){
            self.setLoginType(result.result);
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },

    /**
     * Session방식의 Logout 함수입니다.
     * @param id
     */
    logout: async () => {
      const authApi: AuthApi = new AuthApi(self.environment.api);
      const result: GetLoginResult = await authApi.logout();

      await self.processAfterLogout();
    },
    loginProcess: async (id:string, pwd:string, type:string) => {
      try {
        const authApi: AuthApi = new AuthApi(self.environment.api);
        const result:LoginResponseTypeResult = await authApi.loginProcess(id, pwd,type);
        const loginResponse = result?.loginResponse;
        if(loginResponse)
        {
          self.setLoginResponse(loginResponse);
        }
      } catch (e){
        self.rootStore.responseStore.errorProcessing(e);
      }
    },
    otpProcess: async (otpNumber:string) => {
      try{
        const authApi: AuthApi = new AuthApi(self.environment.api);
        const result: LoginResponseTypeResult = await authApi.otpProcess(otpNumber);
        const loginResponse = result?.loginResponse;
        if(loginResponse)
        {
          self.setLoginResponse(loginResponse);
        }
      }catch (e)
      {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },


    // eslint-disable-line @typescript-eslint/no-unused-vars
    resetPassword: async (email: string) => {}, // eslint-disable-line @typescript-eslint/no-unused-vars
    updateProfile: async (user: IUser) => {}, // eslint-disable-line @typescript-eslint/no-unused-vars
  })); // eslint-disable-line @typescript-eslint/no-unused-vars

type AuthStoreType = Instance<typeof AuthStoreModel>;
export interface AuthStore extends AuthStoreType {}
type AuthStoreSnapshotType = SnapshotOut<typeof AuthStoreModel>;
export interface AuthStoreSnapshot extends AuthStoreSnapshotType {}
type AuthStoreTypeKey = keyof AuthStoreSnapshotType;

const compare = (a: any, b: any) => {
  if (a.menuPsid === null && b.menuPsid != null) {
    return -1;
  } else if (a.menuPsid != null && b.menuPsid === null) {
    return 1;
  } else if (a.menuPsid === null && b.menuPsid === null) {
    if (a.menuOrder > b.menuOrder) {
      return 1;
    }
    if (a.menuOrder < b.menuOrder) {
      return -1;
    }
  } else {
    if (a.menuPsid < b.menuPsid) {
      return -1;
    }
    if (a.menuPsid > b.menuPsid) {
      return 1;
    }
    if (a.menuPsid === b.menuPsid) {
      if (a.menuOrder > b.menuOrder) {
        return 1;
      }
      if (a.menuOrder < b.menuOrder) {
        return -1;
      }
    }
  }
  return 0;
};
