import { Instance, SnapshotOut, types } from 'mobx-state-tree';

/**
 * # WatchStatisticsData
 *
 * WatchStatisticsData을 설명하세요.
 */
export const WatchStatisticsData = types
  .model('WatchStatisticsData')
  // --------------------------------------------------------------------------
  .props({
    statDt: types.number,
    chSvcId: types.maybeNull(types.number),
    chNo: types.number,
    chNm: types.maybeNull(types.string),
    rank: types.maybeNull(types.number),
    rating: types.maybeNull(types.number),
    occupyRank: types.maybeNull(types.number),
    occupyRating: types.maybeNull(types.number),
  })
  // --------------------------------------------------------------------------
  // MUTATEs ONLY! - 모델 상태를 변경
  // REQUESTs는 스토어 모델에 작성하세요.
  // ex) yarn gen model watchStatisticsData --store
  .actions((self) => ({
    /*
     * 여러 prop을 한번에 수정
     */
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as IWatchStatisticsData;
      (Object.keys(newProps) as TWatchStatisticsDataKeys[]).forEach((key) => {
        //@ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type TWatchStatisticsData = Instance<typeof WatchStatisticsData>;
type TWatchStatisticsDataSnapshot = SnapshotOut<typeof WatchStatisticsData>;

export interface IWatchStatisticsData extends TWatchStatisticsData {}
export type TWatchStatisticsDataKeys = keyof TWatchStatisticsDataSnapshot & string;
export interface IWatchStatisticsDataSnapshot extends TWatchStatisticsDataSnapshot {}
export const createWatchStatisticsData = () =>
  types.optional(WatchStatisticsData, {} as TWatchStatisticsData);
