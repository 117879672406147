import { createSnmpAuthInfo } from './../snmp-auth-info/SnmpAuthInfo';
import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { createAuthInfo } from './../auth-info/AuthInfo';
import { createModelCode } from './../model-code/ModelCode';
import { createSimpleUser } from './../simple-user/SimpleUser';

/**
 * # Connection
 *
 * Connection을 설명하세요.
 */
export const Connection = types
  .model('Connection')
  // --------------------------------------------------------------------------
  .props({
    connSid: types.optional(types.number, 0),
    connNm: types.optional(types.maybeNull(types.string), null),
    prtl: types.maybeNull(createModelCode()),
    urlPfix: types.optional(types.maybeNull(types.string), null),
    ipadr: types.optional(types.maybeNull(types.string), null),
    port: types.optional(types.maybeNull(types.string), null),
    authMethod: types.maybeNull(createModelCode()),
    authId: types.optional(types.maybeNull(types.string), null),
    authPwd: types.optional(types.maybeNull(types.string), null),
    psphrs: types.optional(types.maybeNull(types.string), null),
    prvtKey: types.optional(types.maybeNull(types.string), null),
    fingerprint: types.optional(types.maybeNull(types.string), null),
    cmnty: types.optional(types.maybeNull(types.string), null),
    intrlkSysSrvrSid: types.optional(types.maybeNull(types.number), null),
    intrlkSysSrvrNm: types.optional(types.maybeNull(types.string), null),
    equipSid: types.optional(types.maybeNull(types.number), null),
    equipHostNm: types.optional(types.maybeNull(types.string), null),
    delYn: types.optional(types.maybeNull(types.boolean), null),
    regDt: types.optional(types.maybeNull(types.string), null),
    regUser: types.maybeNull(createSimpleUser()),
    updDt: types.optional(types.maybeNull(types.string), null),
    updUser: types.maybeNull(createSimpleUser()),
    whatSvr: types.optional(types.maybeNull(types.boolean), null),
    snmpAuthInfo: types.maybeNull(createSnmpAuthInfo()),
    httpAuthInfo: types.maybeNull(createAuthInfo()),
    cliAuthInfo: types.maybeNull(createAuthInfo()),
  })
  // --------------------------------------------------------------------------
  // MUTATEs ONLY! - 모델 상태를 변경
  // REQUESTs는 스토어 모델에 작성하세요.
  // ex) yarn gen model connection --store
  .actions((self) => ({
    /*
     * 여러 prop을 한번에 수정
     */
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as IConnection;
      (Object.keys(newProps) as TConnectionKeys[]).forEach((key) => {
        // @ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type TConnection = Instance<typeof Connection>;
type TConnectionSnapshot = SnapshotOut<typeof Connection>;

export interface IConnection extends TConnection {}
export type TConnectionKeys = keyof TConnectionSnapshot & string;
export interface IConnectionSnapshot extends TConnectionSnapshot {}
export const createConnection = () =>
  types.optional(Connection, {
    connSid: 0,
    connNm: null,
    prtl: null,
    urlPfix: null,
    ipadr: null,
    port: null,
    authMethod: null,
    authId: null,
    authPwd: null,
    psphrs: null,
    prvtKey: null,
    fingerprint: null,
    cmnty: null,
    intrlkSysSrvrNm: null,
    intrlkSysSrvrSid: null,
    equipHostNm: null,
    equipSid: null,
    delYn: null,
    whatSvr: null,
    regDt: null,
    regUser: null,
    updDt: null,
    updUser: null,
  } as TConnection);

export interface IConnectionFilter {
  connSid?: number | null;
  equipOrSrvrNm?: string;
  prtl?: number;
  ipadr?: string;
  port?: string;
  regDtSt?: number;
  regDtEd?: number;
}
