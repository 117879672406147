import { FormProvider } from "../../components/hook-form";
import { useForm } from "react-hook-form";
import { LoadingButton } from "@mui/lab";
import CFormSetItem from "../../components/CFormSetItem";
import CTextField from "../../components/forms/CTextField";
import CFormSet from '../../components/CFormSet';

import { useEffect, useRef, useState } from "react";
import { ALERT_MSG, BUTTON_LABEL } from "../../components/settings/SettingConstEnum";
import { ISopSnapshot, useStores } from "../../models";
import CAlert from "../../components/CAlert";
import { useNavigate } from "react-router-dom";
import Label from "../../components/Label";
import CButtonSetItem from "../../components/CButtonSetItem";
import CButtonSet from "../../components/CButtonSet";
import { OtpApi } from "../../services/otp/Otp";
import { Environment } from "../../models/environment";
type InitialValues = {
    otp: string;
    otp2:string;
};
type category = 'success' | 'info' | 'warning' | 'error' | 'question';

export function OtpModal () {
    const basicMin = 3;
    const { authStore } = useStores();
    const [min, setMin] = useState("3");
    const [sec, setSec] = useState("0");
    const [otp, setOtp] = useState("");
    const [times, setTimes] = useState("");
    const [isOtpExpired, setIsOtpExpired] = useState(false);
    const [isOtpResend, setIsOtpResend] = useState(false);
    const [alertConetnType, setAlertContenType] = useState('');
    const [isOtpButton, setIsOtpButton] = useState(false);
    const [isEmpty, setIsEmpty] = useState(true);
    const [buttonType, setButtonType] = useState<category>('success');
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [isAlertOpen, setIsAlertOpen] = useState<boolean>(false);
    const navigate = useNavigate();

    const defaultValues = {
        otp: '',
    };

    const methods = useForm<InitialValues>({
        defaultValues,
    });

    // const setFocus = useForm();

    const {
        formState: { isSubmitting }
        ,getValues
        ,setValue
        ,setFocus
        ,handleSubmit
    } = methods;

    const setAuthData = () => {
        let values = getValues('otp');
        values = values.toString().replace(/[^0-9]/g,'');
        if(values.length > 6)
        {
            setIsEmpty(false);
            setOtp(values.substring(0,6));
        }
        else if(values.length === 0) {
            setIsEmpty(true);
            setValue('otp',values);
            setOtp(values)
        }
        else{
            setIsEmpty(false);
            setOtp(values);
        }
    }

    const otpExpiredClose = () => {
        if(sessionStorage.getItem("loginTime"))
        {
            sessionStorage.removeItem("loginTime");
        }
        setIsOtpExpired(false);
        setIsOtpButton(true);
    }

    useEffect(() => {
        let item = sessionStorage.getItem("loginTime");
        if(item)
        {
            let loginDate = new Date(parseInt(item));
            let nowDate = new Date();
            let milliSeconds = Math.abs(nowDate.getTime() - loginDate.getTime());
            let secValue = Math.floor((milliSeconds/1000) % 60);
            let minValue = Math.floor((milliSeconds / (1000 * 60 )) % 60 );

            if(minValue < basicMin)
            {
                minValue = basicMin - minValue;
                if(secValue > 0)
                {
                    minValue--;
                    secValue = 60 - secValue;
                }
                else {
                    secValue = 0;
                }
                setMin(minValue.toString());
                setSec(secValue.toString());
                let temp = min + ":" + (parseInt(sec) < 10 ? "0" + sec : sec);
                setTimes(temp);
                // {min}:{parseInt(sec) < 10 ? `0${sec}` : sec}
            }
            else
            {
                setIsOtpExpired(true);
            }
        }
        else{
            navigate('/');
        }
        setFocus('otp');

    },[]);

    useEffect(()=>{
        const countdown = setInterval(() => {
            if(sessionStorage.getItem("loginTime"))
            {
                if(sec && min)
                {
                    if (parseInt(sec) > 0) {
                        let chSec = (parseInt(sec) - 1).toString();
                        setSec(chSec);
                        sessionStorage.setItem("sec",chSec);
                    }
                    if (parseInt(sec) === 0) {
                        if (parseInt(min) === 0) {
                            sessionStorage.removeItem("sec");
                            sessionStorage.removeItem("min");
                            setIsOtpExpired(true);
                            clearInterval(countdown);
                        } else {
                            let chMin = (parseInt(min) - 1).toString();
                            setMin(chMin);
                            setSec('59');
                            let temp = min + ":" + (parseInt(sec) < 10 ? "0" + sec : sec);
                            setTimes(temp);
                            sessionStorage.setItem("sec",'59');
                            sessionStorage.setItem("min", chMin);
                        }
                    }
                }
            }
        }, 1000);
        return () => clearInterval(countdown);
    },[min,sec]);

    const setIsAlertClose = () => setIsAlertOpen(false);

    const loginSucccess = async () => {
        await authStore.getLoginUserInfo();
    }

    const sendOtp = async () => {
        await authStore.otpProcess(otp).then(() =>{
            const loginResponse = authStore.loginResponse;
            if(loginResponse)
            {
                if(loginResponse.loginResult === "SUCCESS")
                {
                    if(sessionStorage.getItem("sec"))
                    {
                        sessionStorage.removeItem("sec");
                    }
                    if(sessionStorage.getItem("min"))
                    {
                        sessionStorage.removeItem("min");
                    }
                    if(sessionStorage.getItem("loginTime"))
                    {
                        sessionStorage.removeItem("loginTime");
                    }
                    if(sessionStorage.getItem("login"))
                    {
                        sessionStorage.removeItem("login");
                    }
                    loginSucccess();
                }
                else
                {
                    const message = loginResponse.errorMessage;
                    setErrorMessage(message ? message : '');
                    setIsAlertOpen(true);
                }
            }
        });
    }
    return (
        <>
            <FormProvider methods={methods} onSubmit={handleSubmit(sendOtp)}>
                <CFormSet column={4}>
                    <CFormSetItem grid={2} sx={{ margin: 'auto', textAlign: 'center', paddingLeft: '0' }}>
                        <CTextField
                            label="OTP번호"
                            raw={otp}
                            onChangeCallback={setAuthData}
                            name={"otp"}>
                        </CTextField>
                    </CFormSetItem>
                    <CFormSetItem grid={1} sx={{ marginTop: '18px', textAlign: 'right' }}>
                        <Label sx={{ fontSize: '20px' }}>{min}:{parseInt(sec) < 10 ? `0${sec}` : sec}</Label>
                    </CFormSetItem>

                    <CFormSetItem grid={1} sx={{ marginTop:'10px', textAlign: 'center' }}>
                        {!isOtpButton && (
                            <LoadingButton
                                fullWidth
                                size="small"
                                type="button"
                                variant="contained"
                                color="secondary"
                                disabled={isEmpty}
                                loading={isSubmitting}
                                onClick={() => sendOtp()}
                            >
                                {'확인'}
                            </LoadingButton>
                            )
                        }
                        {isOtpButton &&
                            (
                            <CButtonSet sx={{ marginTop: '1px', textAlign: 'center' }} justifyContent={'flex-end'}>
                                <CButtonSetItem size={'small'} onClick={()=>{
                                    const resendApi = async () =>{
                                        let env = new Environment();
                                        env.setup();
                                        let api:OtpApi  = new OtpApi(env.api);
                                        const response = await api.get();
                                        if(response)
                                        {
                                            if(typeof response === "boolean")
                                            {
                                                setAlertContenType('재전송에 성공하였습니다.');
                                                sessionStorage.setItem("loginTime",new Date().getTime().toString());
                                                setSec("0");
                                                setMin("3");
                                                setButtonType('success');
                                                setIsOtpButton(false);
                                            }
                                            else{
                                                setButtonType('error');
                                                setAlertContenType(response);
                                            }
                                        }else{
                                            setButtonType('error');
                                            setAlertContenType(`재전송에 실패하였습니다.`);
                                        }
                                        setIsOtpResend(true);
                                    }
                                    resendApi();
                                }}>
                                    {BUTTON_LABEL.RE_SEND}
                                </CButtonSetItem>
                            </CButtonSet>
                            )
                        }
                    </CFormSetItem>
                </CFormSet>
            </FormProvider>
            <CAlert
                key={'OTP-EXPIRED'}
                isAlertOpen={isOtpExpired}
                alertCategory={'error'}
                alertContent={ALERT_MSG.common.otpExpired}
                alertTitle={'Dr.Genie OTP'}
                handleAlertClose={otpExpiredClose}
                hasCancelButton={false}
            />
            <CAlert
                key={'OTP-RESEND'}
                isAlertOpen={isOtpResend}
                alertCategory={buttonType}
                alertContent={alertConetnType}
                alertTitle={'Dr.Genie OTP'}
                handleAlertClose={()=>{setIsOtpResend(false)}}
                hasCancelButton={false}
            />
            <CAlert
                key={'Login-Error'}
                isAlertOpen={isAlertOpen}
                alertCategory={"error"}
                alertContent={errorMessage}
                alertTitle={'로그인 에러'}
                handleAlertClose={setIsAlertClose}
                hasCancelButton={false}
            />
        </>
    );
}