import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { selectOptions } from '../../components/forms/CSelect';
import { createModelCode } from '../model-code/ModelCode';
import { createSimpleUser } from '../simple-user/SimpleUser';
import { RealtimeAlarm } from '../../screens/AlarmManagement/Realtime/RealtimeAlarmTable';

/**
 * # RealTimeAlarm
 *
 * RealTimeAlarm을 설명하세요.
 */
export const RealTimeAlarm = types
  .model('RealTimeAlarm')
  // --------------------------------------------------------------------------
  .props({
    id: types.optional(types.number, 0),
    systemName: types.maybeNull(types.string), //	시스템이름	Code
    eventId: types.optional(types.string, ''), //	이벤트 ID	Integer
    eventTime: types.maybeNull(types.string), //	이벤트 시간	Date
    eventState: types.maybeNull(createModelCode()), //	이벤트 상태	Code
    instanceType: types.maybeNull(createModelCode()), //	인스턴스 유형	Code
    instanceId: types.maybeNull(types.string), //	인스턴스 아이디	String
    instanceName: types.maybeNull(types.string), // 인스턴스 이름	String
    instancePortId: types.maybeNull(types.string), // 	포트 아이디	String
    instancePortName: types.maybeNull(types.string), // 	포트 이름	String
    instanceFullName: types.maybeNull(types.string), // 	인스턴스 전체 이름	String
    targetInstanceType: types.maybeNull(createModelCode()), //	대상 인스턴스 유형	Code
    targetInstanceId: types.maybeNull(types.string), // 대상 인스턴스 아이디	String
    targetInstanceName: types.maybeNull(types.string), //	대상 인스턴스 이름	String
    affectChannel: types.maybeNull(types.string),
    instanceTypez: types.maybeNull(createModelCode()),//	끝지점 인스턴스 유형	Code
    instanceIdz:	types.maybeNull(types.string),// 끝지점 인스턴스 아이디	String
    instanceNamez:	types.maybeNull(types.string),//	끝지점 인스턴스 이름	String
    instancePortIdz:	types.maybeNull(types.string),//	끝지점 포트 아이디	String
    instancePortNamez:	types.maybeNull(types.string),// 끝지점 포트명	String
    instanceFullNamez:	types.maybeNull(types.string),//	끈지점 인스턴스 전체 이름	String
    eventType: types.maybeNull(createModelCode()),//	이벤트 유형	Code
    
    probableCause: types.maybeNull(types.string),// 발생원인	String
    specificProblem:	types.maybeNull(types.string),//	상세원인	String
    perceivedSeverity: types.maybeNull(createModelCode()),// 등급	Code
    originalPerceivedSeverity: types.maybeNull(createModelCode()),//	등급	Code
    additionalText:	types.maybeNull(types.string),// 	추가 텍스트	String
    originalAdditionalText:	types.maybeNull(types.string),// 	original 추가 텍스트	String
    additionalInformation: types.maybeNull(types.optional(types.string, '')),// 	추가 정보	String
    correlatedEventId:	types.maybeNull(types.string),// 	연관 이벤트 아이디	String
    originalEventId:	types.maybeNull(types.string),// 	이벤트 아이디	String
    externalEventId:	types.maybeNull(types.string),// 	외부 이벤트 아이디	String
    receivedEventMessage:	types.maybeNull(types.string),// 	이벤트 수신 메시지	String
    operationContext:	types.maybeNull(types.string),// 	운영 내용	String
    similarAlarmCount:	types.maybeNull(types.string),// 	동일 알람 건수	String
    operatorNote:	types.maybeNull(types.string),// 	운영자 메모	String

    oprtrNote:	types.maybeNull(types.string),// 	운영자 메모	String
    oprtrNoteCtg: types.maybeNull(createModelCode()), 
    noteProblCause: types.maybeNull(types.string),
    
    cleared: types.optional(types.boolean, false),//	Clear 여부	Boolean
    originalEventTime: types.maybeNull(types.string),//	original 이벤트 발생시간
    clearanceTime: types.maybeNull(types.string),//	Clear 일시	Date
    acknowledgedTime: types.maybeNull(types.string),//	인지 시간	Date
    clearanceAccount:	types.maybeNull(createSimpleUser()),// 	Clear 처리 사용자	Object
    acknowledgedAccount:	types.maybeNull(createSimpleUser()),// 	인지자	Object
    message:	types.maybeNull(types.optional(types.string, '')),// 	메시지	String

    eventDuration: types.maybeNull(types.number), // 지속시간 (seconds)
    sourceEventTime: types.maybeNull(types.string),//	MV 알람 발생 시각 Date. (only for channel alarm)

    // children: types.maybeNull(types.array(types.model('RealtimeAlarm'))),
    children: types.optional(types.maybeNull(types.array(types.frozen('RealtimeAlarm'))), []),
  })
  // --------------------------------------------------------------------------
  // MUTATEs ONLY! - 모델 상태를 변경
  // REQUESTs는 스토어 모델에 작성하세요.
  // ex) yarn gen model history --store
  .actions((self) => ({
    /*
     * 여러 prop을 한번에 수정
     */
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as IRealTimeAlarm;
      (Object.keys(newProps) as TRealTimeAlarmKeys[]).forEach((key) => {
        //@ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type TRealTimeAlarm = Instance<typeof RealTimeAlarm>;
type TRealTimeAlarmSnapshot = SnapshotOut<typeof RealTimeAlarm>;

export interface IRealTimeAlarm extends TRealTimeAlarm {}
export type TRealTimeAlarmKeys = keyof TRealTimeAlarmSnapshot & string;
export interface IRealTimeAlarmSnapshot extends TRealTimeAlarmSnapshot {}

export const createRealTimeAlarm = () =>
  types.optional(RealTimeAlarm, {
    originalEventTime: '',
    acknowledgedTime: '',
    clearanceTime: ''
  } as TRealTimeAlarm);

export interface IRealTimeAlarmFilter {
  instanceType?: selectOptions;
  eventType?: selectOptions;
  probableCause?: string;
  specificProblem?: string;
  perceivedSeverity?: selectOptions;
  eventTimeSt?: string;
  eventTimeEd?: string;
  affectChannel?: string;
  targetInstanceName?: string;
  instanceName?: string;
}
