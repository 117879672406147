import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { withEnvironment } from '../extensions/with-environment';
import { ChannelCodeApi } from './../../services/channel-code/ChannelCode';
import {
  TGetChannelCodeItems,
  TGetChannelCodeResult,
  TGetChannelCodesResult,
  TGetChildCodesResult,
  TPostChannelCodeResult,
  TUpdateChannelCodeResult,
} from './../../services/channel-code/ChannelCodeTypes';
import { timestampToDate } from './../../utils/dateExchanger';
import {
  ChannelCode,
  createChannelCode,
  IChannelCode,
  IChannelCodeSnapshot,
} from './../channel-code/ChannelCode';
import { withRootStore } from './../extensions/with-root-store';
import { IModelCode, IModelCodeSnapshot, ModelCode } from './../model-code/ModelCode';
import { createPagination } from './../pagination/Pagination';

/**
 * # ChannelCodeStore
 *
 * ChannelCodeStore을 설명하세요.
 */
export const ChannelCodeStore = types
  .model('ChannelCodeStore')
  // --------------------------------------------------------------------------
  .props({
    channelCodes: types.optional(types.array(ChannelCode), []),
    selectedChannelCode: types.maybeNull(createChannelCode()),
    childCodeList: types.optional(types.array(ModelCode), []),

    //9001 :채널 유형
    channelTypeCodes: types.optional(types.maybeNull(types.array(ModelCode)), []),
    //9003 : 전송망 공급사 유형
    trmNetVendrCodes: types.optional(types.maybeNull(types.array(ModelCode)), []),
    //9005 : 중계기 유형
    repeaterCodes: types.optional(types.maybeNull(types.array(ModelCode)), []),
    //9006 : PSI_IN
    psiInCodes: types.optional(types.maybeNull(types.array(ModelCode)), []),
    //9007 : 수급정보
    sadInfoCodes: types.optional(types.maybeNull(types.array(ModelCode)), []),
    pagination: createPagination(),
  })
  .extend(withRootStore)
  .extend(withEnvironment)
  // eslint-disable-line @typescript-eslint/no-unused-vars
  .views((self) => ({}))
  // --------------------------------------------------------------------------
  // MUTATEs - 모델 상태를 변경
  .actions((self) => ({
    setChannelCodes: (channelCodes: IChannelCodeSnapshot[]) => {
      self.channelCodes.replace(channelCodes as IChannelCode[]);
    },
    setSelectedChannelCode: (channelCode: IChannelCodeSnapshot) => {
      self.selectedChannelCode = channelCode as IChannelCode;
    },
    resetSelectedChannelCode: () => {
      self.selectedChannelCode = null;
    },
    setChildCodeList: (childList: IModelCodeSnapshot[]) => {
      self.childCodeList.replace(childList as IModelCode[]);
    },
    setChannelTpyeCodes: (codes: IModelCodeSnapshot[]) => {
      self.channelTypeCodes?.replace(codes as IModelCode[]);
    },
    setTrmNetVendrCodes: (codes: IModelCodeSnapshot[]) => {
      self.trmNetVendrCodes?.replace(codes as IModelCode[]);
    },
    setRepeaterCodes: (codes: IModelCodeSnapshot[]) => {
      self.repeaterCodes?.replace(codes as IModelCode[]);
    },
    setPsiInCodes: (codes: IModelCodeSnapshot[]) => {
      self.psiInCodes?.replace(codes as IModelCode[]);
    },
    setSadInfoCodes: (codes: IModelCodeSnapshot[]) => {
      self.sadInfoCodes?.replace(codes as IModelCode[]);
    },
  }))
  // --------------------------------------------------------------------------
  // REQUESTs - 서비스 요청 및 기타 인터페이스 요청
  .actions((self) => ({
    /**
     * INF_CAIMS_00203
     * 채널코드 전체 목록을 조회한다. (채널코드만 조회된다. = 코드항목은 조회안함.)
     * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=1523906553
     * @param channelCodeFilter
     * @returns
     */
    gets: async (channelCodeFilter?: TChannelCodeFilter) => {
      self.setChannelCodes([]);
      try {
        const channelCodeApi: ChannelCodeApi = new ChannelCodeApi(self.environment.api);
        const result: TGetChannelCodesResult = await channelCodeApi.gets(
          self.pagination,
          channelCodeFilter,
        );

        if (self.rootStore.responseStore.getResponseResult(result) && result.channelCodes) {
          self.setChannelCodes(result.channelCodes);
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },

    /**
     * INF_CAIMS_00204
     * 해당 채널코드의 상세정보를 조회한다. (해당 코드의 하위항목들이 함께 조회됨)
     * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=835915838
     * @param cdId
     */
    get: async (cdId: number) => {
      self.resetSelectedChannelCode();
      try {
        const channelCodeApi: ChannelCodeApi = new ChannelCodeApi(self.environment.api);
        const result: TGetChannelCodeResult = await channelCodeApi.get(cdId);
        if (self.rootStore.responseStore.getResponseResult(result)) {
          if (result.channelCode) {
            self.setSelectedChannelCode(result.channelCode);
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },

    /**
     * INF_CAIMS_00206
     * 해당 채널코드의 정보를 수정한다.
     * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=707501025
     * @param channelCode
     */
    put: async (channelCode: IChannelCodeSnapshot) => {
      self.resetSelectedChannelCode();
      try {
        const channelCodeApi: ChannelCodeApi = new ChannelCodeApi(self.environment.api);
        const result: TUpdateChannelCodeResult = await channelCodeApi.put(channelCode);
        if (self.rootStore.responseStore.getResponseResult(result)) {
          if (result.channelCode) {
            self.setSelectedChannelCode(result.channelCode);
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },

    /**
     * INF_CAIMS_00205
     * 해당 채널코드에 코드항목을 등록한다.
     * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=406741055
     * @param channelCode
     */
    itemPost: async (channelCode: IChannelCodeSnapshot) => {
      self.resetSelectedChannelCode();
      try {
        const channelCodeApi: ChannelCodeApi = new ChannelCodeApi(self.environment.api);
        const result: TPostChannelCodeResult = await channelCodeApi.itemPost(channelCode);

        if (self.rootStore.responseStore.getResponseResult(result)) {
          if (result.channelCode) {
            self.setSelectedChannelCode(result.channelCode);
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },

    /**
     * INF_CAIMS_00209
     * 해당 채널코드의 사용여부가 True인 하위항목(자식코드) 목록을 조회한다.
     * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=1062607803
     * @param cdId
     */
    getChildCodes: async (cdId: number) => {
      self.setChildCodeList([]);
      try {
        const channelCodeApi: ChannelCodeApi = new ChannelCodeApi(self.environment.api);
        const result: TGetChildCodesResult = await channelCodeApi.getChildCodes(cdId);

        if (self.rootStore.responseStore.getResponseResult(result)) {
          if (result.childList) {
            self.setChildCodeList(result.childList);
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },

    /**
     * INF_CAIMS_00229
     * 전체 채널코드의 코드항목을 조회한다.
     * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=574023193
     */
    getChannelCodes: async () => {
      self.rootStore.responseStore.responseInfo.reset();
      try {
        const channelCodeApi: ChannelCodeApi = new ChannelCodeApi(self.environment.api);
        const result: TGetChannelCodeItems = await channelCodeApi.getChannelCodes();

        if (self.rootStore.responseStore.getResponseResult(result)) {
          if (result.codeList) {
            self.setChannelTpyeCodes(result.codeList['9001'] as IModelCodeSnapshot[]);
            self.setTrmNetVendrCodes(result.codeList['9003'] as IModelCodeSnapshot[]);
            self.setRepeaterCodes(result.codeList['9005'] as IModelCodeSnapshot[]);
            self.setPsiInCodes(result.codeList['9006'] as IModelCodeSnapshot[]);
            self.setSadInfoCodes(result.codeList['9007'] as IModelCodeSnapshot[]);
            // self.setIsSuccess(true);
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },
  }));

// --------------------------------------------------------------------------
type TChannelCodeStore = Instance<typeof ChannelCodeStore>;
type TChannelCodeStoreSnapshot = SnapshotOut<typeof ChannelCodeStore>;

export interface IChannelCodeStore extends TChannelCodeStore {}
export type TChannelCodeStoreKeys = keyof TChannelCodeStoreSnapshot & string;
export interface IChannelCodeStoreSnapshot extends TChannelCodeStoreSnapshot {}
export const createChannelCodeStore = () =>
  types.optional(ChannelCodeStore, {} as TChannelCodeStore);

export type TChannelCodeFilter = {
  //   page		조회 페이지 번호		Number
  // size		조회 개수		Number
  // cdId		코드ID		Number
  // cdNm		코드명		String
  // cdPid		부모코드 ID		Number
  // cdPnm		부모코드명		String
  // useYn		사용여부		Boolean
  cdId?: number;
  cdNm?: string;
  cdPid?: number;
  cdPnm?: string;
  useYn?: boolean;
};
