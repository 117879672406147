import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { IRuleset, IRulesetFilter } from '../../models';
import {
  RulesetApi, TClass,
  TGetRulesetResult,
  TGetRulesetsResult,
  TTestRulesetResult,
} from '../../services';
import { withEnvironment } from '../extensions/with-environment';
import { TPostRulesetResult } from './../../services/ruleset/RulesetTypes';
import { withRootStore } from './../extensions/with-root-store';
import { createPagination, IPagination, IPaginationSnapshot } from './../pagination/Pagination';
import { createRuleset, IRulesetSnapshot } from './../ruleset/Ruleset';

/**
 * # RulesetStore
 *
 * RulesetStore을 설명하세요.
 */
export const RulesetStore = types
  .model('RulesetStore')
  // --------------------------------------------------------------------------
  .props({
    rulesets: types.optional(types.array(createRuleset()), []),
    selectedRuleset: types.maybeNull(createRuleset()),
    pagination: createPagination(),
    deleteRulesetList: types.optional(types.array(types.number), []),
    testResult: types.optional(types.maybeNull(types.string), null),
    classList: types.optional(types.array(types.string),[]),
  })
  .extend(withEnvironment)
  .extend(withRootStore)
  // eslint-disable-line @typescript-eslint/no-unused-vars
  .views((self) => ({}))
  // --------------------------------------------------------------------------
  // MUTATEs - 모델 상태를 변경
  .actions((self) => ({
    /**
     * rulesets을 교체
     *
     * @param `rulesets` 새로운 모델의 배열
     */
    setRulesets: (rulesets: IRulesetSnapshot[]) => {
      self.rulesets.replace(rulesets as IRuleset[]);
    },
    /**
     * Paginaiotn 객체 교체
     * @param pagination
     */
    setPagination: (pagination: IPaginationSnapshot) => {
      self.pagination = pagination as IPagination;
    },
    /**
     * Response의 실 Data 제외 객체 교체
     * @param responseInfo
     */
    /**
     * 현재 선택된 룰셋 객체 교체
     * @param ruleset
     */
    setSelectedRuleset: (ruleset: IRulesetSnapshot) => {
      self.selectedRuleset = ruleset as IRuleset;
    },
    /**
     * 룰셋 삭제 리스트 교체
     * @param deleteRulesetList
     */
    setDeleteRulesetList: (deleteRulesetList: number[]) => {
      self.deleteRulesetList.replace(deleteRulesetList);
    },
    /**
     * 룰셋 삭제 리스트에 룰셋Sid 추가
     * @param ruleSetSid
     */
    appendDeleteRulesetList: (ruleSetSid: number) => {
      self.deleteRulesetList.push(ruleSetSid);
    },
    /**
     * 테스트 결과를 교체
     * @param testResult
     */
    setTestResult: (testResult: string) => {
      self.testResult = testResult;
    },
    /**
     * 룰셋 클래스 리스트
     * @param classList
     */
    setClassList: (classList: string[]) => {
      self.classList.replace(classList);
    },
  }))
  // --------------------------------------------------------------------------
  // REQUESTs - 서비스 요청 및 기타 인터페이스 요청
  .actions((self) => ({
    /**
     * INF_CAIMS_00042
     * 룰셋 목록을 조회한다.
     * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=1838651141
     */
    gets: async (rulesetFilter?: IRulesetFilter) => {
      self.setRulesets([]);
      try {
        const rulesetApi: RulesetApi = new RulesetApi(self.environment.api);
        let result: TGetRulesetsResult;
        if (rulesetFilter) {
          result = await rulesetApi.gets(self.pagination, rulesetFilter);
        } else {
          result = await rulesetApi.gets(self.pagination);
        }

        if (
          self.rootStore.responseStore.getResponseResult(result) &&
          result.pagination &&
          result.pagination.page === self.pagination.page
        ) {
          if (result.rulesets) {
            self.setRulesets(result.rulesets);
          }
          if (result.pagination) {
            self.setPagination(result.pagination);
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },
    /**
     * INF_CAIMS_00043
     * 해당 룰셋의 상세정보를 조회한다.
     * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=1005912353
     */
    get: async (ruleSetSid: number) => {
      self.selectedRuleset = null;
      try {
        const rulesetApi: RulesetApi = new RulesetApi(self.environment.api);
        const result: TGetRulesetResult = await rulesetApi.get(ruleSetSid);

        if (self.rootStore.responseStore.getResponseResult(result)) {
          if (result.ruleset) {
            self.setSelectedRuleset(result.ruleset);
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },
    /**
     * INF_CAIMS_00044
     * 새로운 룰셋을 등록한다.
     * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=1123699119
     */
    post: async (ruleset: IRulesetSnapshot) => {
      self.selectedRuleset = null;
      try {
        const rulesetApi: RulesetApi = new RulesetApi(self.environment.api);
        const result: TPostRulesetResult = await rulesetApi.post(ruleset);

        if (self.rootStore.responseStore.getResponseResult(result)) {
          if (result.ruleset) {
            self.setSelectedRuleset(result.ruleset);
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },
    /**
     * INF_CAIMS_00045
     * 룰셋 정보를 수정한다.
     * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=1096816438
     */
    put: async (ruleset: IRulesetSnapshot) => {
      self.selectedRuleset = null;
      try {
        const rulesetApi: RulesetApi = new RulesetApi(self.environment.api);
        const result: TPostRulesetResult = await rulesetApi.put(ruleset);

        if (self.rootStore.responseStore.getResponseResult(result)) {
          if (result.ruleset) {
            self.setSelectedRuleset(result.ruleset);
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },
    /**
     * INF_CAIMS_00046
     * 룰셋 정보를 삭제한다.
     * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=1510356721
     */
    delete: async (ruleSetSid: number) => {
      try {
        const rulesetApi: RulesetApi = new RulesetApi(self.environment.api);
        const result: TPostRulesetResult = await rulesetApi.delete(ruleSetSid);
        self.rootStore.responseStore.getResponseResult(result);
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },
    /**
     * INF_CAIMS_00047
     * 여러개 룰셋을 한번에 삭제한다.
     * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=395346139
     */
    deleteList: async () => {
      try {
        const rulesetApi: RulesetApi = new RulesetApi(self.environment.api);
        const result: TPostRulesetResult = await rulesetApi.deleteList(self.deleteRulesetList);
        self.rootStore.responseStore.getResponseResult(result);
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },
    /**
     * INF_CAIMS_00048
     * 해당 룰셋을 테스트 한다.
     * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=1178202965
     * @returns
     */
    rulesetTest: async (ruleSetSid: number, testStirng: string) => {
      self.setTestResult('');
      try {
        const rulesetApi: RulesetApi = new RulesetApi(self.environment.api);
        const result: TTestRulesetResult = await rulesetApi.rulesetTest(ruleSetSid, testStirng);

        if (self.rootStore.responseStore.getResponseResult(result)) {
          if (result.testResult) {
            self.setTestResult(result.testResult);
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },
    /**
     * 룰셋 클래스를 가지고 온다.
     */
    getClassList: async() => {
      try{
        const rulesetAip: RulesetApi = new RulesetApi(self.environment.api);
        const result: TClass = await rulesetAip.getClassList();

        if (self.rootStore.responseStore.getResponseResult(result)) {
          if (result.classList) {
            self.setClassList(result.classList);
          }
        }
      }
      catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },
  }));

// --------------------------------------------------------------------------
type TRulesetStore = Instance<typeof RulesetStore>;
type TRulesetStoreSnapshot = SnapshotOut<typeof RulesetStore>;

export interface IRulesetStore extends TRulesetStore {}
export type TRulesetStoreKeys = keyof TRulesetStoreSnapshot & string;
export interface IRulesetStoreSnapshot extends TRulesetStoreSnapshot {}
export const createRulesetStore = () => types.optional(RulesetStore, {} as TRulesetStore);
