import { Instance, SnapshotOut, types } from 'mobx-state-tree';

export const BankModel = types
  .model('Bank')
  .props({
    bankSeq: types.string,
    bankName: types.string,
    bankCd: types.maybe(types.string),
    useYn: types.maybe(types.string),
    regDtStr: types.maybe(types.string),
    regId: types.maybe(types.string),
    regName: types.maybe(types.string),
    updId: types.maybeNull(types.string),
    updName: types.maybeNull(types.string),
    updDtStr: types.maybeNull(types.string),
  })
  .actions((self) => ({
    updateProps: (props: { [key: string]: any }) => {
      const updated = { ...self, ...props } as Bank;
      (Object.keys(updated) as BankTypeKey[]).forEach((key) => {
        if (typeof self[key] === typeof updated[key]) {
          self[key] = updated[key];
        } else {
          throw new Error(`${key.toString} Input Type Error`);
        }
      });
    },
    resetBank: () => {
      self.bankSeq = '';
      self.bankName = '';
      self.bankCd = '';
    },
  }));

type BankType = Instance<typeof BankModel>;
export interface Bank extends BankType {}
type BankSnapshotType = SnapshotOut<typeof BankModel>;
export type BankTypeKey = keyof BankSnapshotType;
export interface BankSnapshot extends BankSnapshotType {}
export const createBankDefaultModel = () =>
  types.optional(BankModel, {
    bankSeq: '',
    bankName: '',
    bankCd: '',
    useYn: '',
    regDtStr: '',
    regId: '',
    regName: '',
    updId: '',
    updName: '',
    updDtStr: '',
  });
