import {
  destroy,
  Instance,
  ReferenceIdentifier,
  SnapshotIn,
  SnapshotOut,
  types,
} from 'mobx-state-tree';
import { ITopology, ITopologySnapshotOut, Topology } from '../../models/topology/Topology';
import {
  TDeleteResult,
  TGetResult,
  TGetsResult,
  TPostResult,
  TPutResult,
} from '../../services/api/api-result';
import { TGetTopologiesResult } from '../../services/topology/TopologyTypes';
import { withEnvironment } from '../extensions/with-environment';
import { withRootStore } from '../extensions/with-root-store';
import { createPagination } from '../pagination/Pagination';
import { ITopologySnapshotIn } from '../topology/Topology';
import { TopologyApi } from './../../services/topology/Topology';
import { TGetTopologyResult } from './../../services/topology/TopologyTypes';
import { IPagination, IPaginationSnapshot } from './../pagination/Pagination';
import { PersistenceState } from './types';
import { forEach } from 'lodash';
import { toJS } from 'mobx';

/**
 * # Topology Store
 *
 * 네트워크 다이어그램의 데이터를 관리한다.
 */
export const TopologyStore = types
  .model('TopologyStore', {
    topologies: types.optional(types.array(Topology), []),
    activated: types.maybeNull(types.reference(types.late(() => Topology))),
    topology: types.optional(types.string, ''),
    topologyList: types.optional(types.array(types.string), []),
    topolId: types.optional(types.string, ''),
    pagination: createPagination(),

    chartPortTraffic: types.optional(types.string, ''),
    chartEquipments: types.optional(types.string, ''),
    chartChannels: types.optional(types.string, ''),
    alarmToggle: types.optional(types.boolean, false),

    equipList: types.optional(types.array(types.string), []),
  })
  .extend(withRootStore)
  .extend(withEnvironment)
  .views((self) => ({
    get news() {
      return self.topologies.filter((t) => t.state === PersistenceState.NEW);
    },
    get updates() {
      return self.topologies.filter((t) => t.state === PersistenceState.DIRTY);
    },
    get deletes() {
      return self.topologies.filter((t) => t.state === PersistenceState.DELETED);
    },
    get json() {
      return JSON.stringify(self);
    },

    // 여러개를 로딩시 문제됨.
    // get equiplist() {
    //   let list: string[] = [];
    //   const nodes = JSON.parse(self.topology).nodes;
    //   if(nodes){
    //     Object.keys(nodes).map((node) => {
    //       const equips = nodes[node].userData.equipList;
    //       if (equips.length > 0 && equips[0] !== 0) {
    //         list = list.concat(equips);
    //       }
    //     });
    //   }
    //   list = list.map(v=> '' + v);
    //   return list.filter((v, i) => list.indexOf(v) === i);
    // }
  }))
  // --------------------------------------------------------------------------
  // MUTATEs - 모델 상태를 변경
  .actions((self) => ({
    setTopolId: (topolId: string) => {
      self.topolId = topolId;
    },
    setTopology: (topology: string) => {
      self.topology = topology;

      /**
       * 백석, 원뷰 등에서 equip id 가 있는 알람만 필터링하기 위해 저장함
       */
      if (topology !== '') {
        const json = JSON.parse(topology);

        let has: boolean = false;
        self.equipList.forEach((e: any) => {
          const j = JSON.parse(e);
          if (j.id === json.id) {
            has = true;
          }
        });
        if (!has) {
          let list: string[] = [];
          if (json.nodes) {
            Object.keys(json.nodes).map((node) => {
              const equips = json.nodes[node].userData.equipList;
              if (equips.length > 0 && equips[0] !== 0) {
                list = list.concat(equips);
              }
            });
          }
          list = list.map(v => '' + v);
          list = list.filter((v, i) => list.indexOf(v) === i);

          const obj = {
            id: json.id,
            name: json.name,
            equips: list
          };

          self.equipList.replace([...self.equipList, JSON.stringify(obj)]);

        }
        // console.log('set equips ', toJS(self.equipList));

      }

    },
    setTopologyList: (topologyList: string[]) => {
      self.topologyList.replace(topologyList);
    },
    setPaignation: (pagination: IPaginationSnapshot) => {
      self.pagination = pagination as IPagination;
    },
    topologyToJson: (): Map<string, any> => {
      return new Map();
    },
    //---------
    activate: (topology: ReferenceIdentifier) => {
      // @ts-ignore: ReferenceItentifier< string | number > 타입이지만, snapshot이 발생하여 참조되는 모델의 타입으로 변형된다.
      self.activated = topology;
    },
    setChartEquipments: (data: string) => {
      self.chartEquipments = data;
    },
    setChartPortTraffic: (data: string) => {
      self.chartPortTraffic = data;
    },
    setChartChannels: (data: string) => {
      self.chartChannels = data;
    },
    setAlarmToggle: () => {
      self.alarmToggle = !self.alarmToggle;
    }
  }))
  .actions((self) => ({
    /**
     * 토폴로지의 데이터를 갱신하고 선택된 토폴로지로 지정
     * @param topology
     */
    set: (topology: ITopology) => {
      const index = self.topologies.findIndex((t) => t.id === topology.id);
      if (index === -1) {
        self.topologies.push(topology);
      } else {
        self.topologies[index] = topology;
      }
      self.activate(topology.id);
    },

    /**
     * topologies을 새로운 배열로 교체
     *
     * @param `topologys` 새로운 모델의 배열
     */
    replace: (topologys: ITopology[]) => {
      self.topologies.replace(topologys);
    },

    /**
     * 선택한 토폴로지를 삭제
     * @param topology
     */
    remove: (topology: ITopology) => {
      destroy(topology);
    },

    /**
     * 새로운 토폴로지 생성
     *
     * 아직 저장되지 않은 상태로 클라이언트 상에만 존재
     *
     */
    add: (snapshot: ITopologySnapshotIn, activated = false) => {
      // const topology = createTopology(snapshot);
      self.topologies.push(snapshot);
      if (activated) {
        self.activate(snapshot.id);
      }
    },
  }))

  // --------------------------------------------------------------------------
  // REQUESTs - 서비스 요청 및 기타 인터페이스 요청
  .actions((self) => ({
    /**
     *
     */
    getTopologyList: async (page: number, size: number) => {
      try {
        const topologyApi: TopologyApi = new TopologyApi(self.environment.api);
        const result: TGetTopologiesResult = await topologyApi.getTopologies(page, size);
        console.log(result);
        if (self.rootStore.responseStore.getResponseResult(result)) {
          if (result.topologies) {
            self.setTopologyList(result.topologies);
          }
          if (result.pagination) {
            self.setPaignation(result.pagination);
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },
    getChannelTopology: async (svcChId: number) => {
      try {
        const topologyApi: TopologyApi = new TopologyApi(self.environment.api);
        const result: TGetTopologyResult = await topologyApi.getChannelTopology(svcChId);

        self.setTopology('');
        if (self.rootStore.responseStore.getResponseResult(result)) {
          if (result.topology) {
            self.setTopology(result.topology);
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },
    getTopology: async (id: string) => {
      try {
        const topologyApi: TopologyApi = new TopologyApi(self.environment.api);
        const result: TGetTopologyResult = await topologyApi.getTopology(id);

        if (self.rootStore.responseStore.getResponseResult(result)) {
          if (result.topology) {
            self.setTopology(result.topology);
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },
    deleteTopology: async (id: string) => {
      try {
        const topologyApi: TopologyApi = new TopologyApi(self.environment.api);
        const result: TGetTopologyResult = await topologyApi.deleteTopology(id);

        self.rootStore.responseStore.getResponseResult(result);
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },
    updateTopology: async (topology: string) => {
      try {
        const topologyApi: TopologyApi = new TopologyApi(self.environment.api);
        const result: TGetTopologyResult = await topologyApi.updateTopology(topology);

        self.rootStore.responseStore.getResponseResult(result);
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },
    insertTopology: async (topology: string) => {
      try {
        const topologyApi: TopologyApi = new TopologyApi(self.environment.api);
        const result: TGetTopologyResult = await topologyApi.insertTopology(topology);

        if (self.rootStore.responseStore.getResponseResult(result)) {
          if (result.topology) {
            self.setTopology(result.topology);
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },
    //---------
    /**
     * 전체 목록을 Api를 통해 조회
     *
     * 조회한 결과로 Topologies를 교체한다.
     * 실패시 에러 로그를 남긴다.
     */
    gets: async () => {
      const topologyApi: TopologyApi = new TopologyApi(self.environment.api);
      const result: TGetsResult<ITopology> = await topologyApi.gets();
      if (result.kind === 'ok') {
        console.log(result.data);
        self.replace(result.data);
      } else {
        console.error(result);
      }
    },

    /**
     * 토폴로지 상세 조회
     *
     */
    get: async (id?: string) => {
      if (!id && !self.activated) {
        console.log('선택된 토폴로지가 없습니다.');
        return;
      }
      id = id || self.activated!.id;
      const topologyApi: TopologyApi = new TopologyApi(self.environment.api);
      const result: TGetResult<ITopology> = await topologyApi.get(id);
      if (result.kind === 'ok') {
        self.set(result.data);
      } else {
        console.error(result.kind);
      }
    },

    /**
     * 새로운 토폴로지 생성
     *
     * @returns
     */
    post: async (topology?: ITopologySnapshotIn) => {
      if (!topology && !self.activated) {
        console.log('선택된 토폴로지가 없습니다.');
        return;
      }
      const _topology = (topology || self.activated) as ITopologySnapshotOut;
      if (_topology?.state !== 'NEW') {
        console.log('새로 생성한 토폴로지가 아닙니다.');
        return;
      }

      const topologyApi: TopologyApi = new TopologyApi(self.environment.api);
      const result: TPostResult<ITopology> = await topologyApi.post(_topology);

      if (result.kind === 'ok') {
        self.set({ ...result.data, state: 'CLEAN' });
      } else {
        console.error(result.kind);
      }
    },

    /**
     * 토폴로지의 변경사항을 저장
     * @param topology 저장할 토폴로지
     * @returns
     */
    put: async (topology?: ITopologySnapshotOut) => {
      if (!topology && !self.activated) {
        console.log('저장할 토폴로지가 없습니다.');
        return;
      }

      const _topology = (topology || self.activated) as ITopologySnapshotOut;

      if (_topology?.state !== 'DIRTY') {
        console.log('토폴로지에 변경사항이 없습니다.');
        return;
      }

      const topologyApi: TopologyApi = new TopologyApi(self.environment.api);
      const result: TPutResult<ITopology> = await topologyApi.put(_topology);

      if (result.kind === 'ok') {
        self.set({ ...result.data, state: 'CLEAN' });
      } else {
        console.error(result.kind);
      }
    },

    /**
     * 토폴로지 삭제
     *
     * @returns
     */
    delete: async () => {
      if (!self.activated) {
        console.log('삭제할 토폴로지가 없습니다.');
        return;
      }

      if (self.activated) {
        const topologyApi: TopologyApi = new TopologyApi(self.environment.api);
        const result: TDeleteResult<ITopology> = await topologyApi.delete(self.activated.id);
        if (result.kind === 'ok') {
          self.remove(self.activated);
        } else {
          console.error(result.kind);
        }
      } else {
        self.remove(self.activated);
      }
    },

    deletes: () => {
      if (self.deletes.length === 0) {
        console.log('삭제할 토폴로지가 없습니다.');
        return;
      }

      const topologyApi: TopologyApi = new TopologyApi(self.environment.api);

      self.deletes.forEach(async (topology) => {
        if (topology) {
          const result: TDeleteResult<ITopology> = await topologyApi.delete(topology.id);
          if (result.kind === 'ok') {
            self.remove(topology);
          } else {
            console.error(result.kind);
          }
        } else {
          self.remove(topology);
        }
      });
    },

    /**
     * oneview chart
     * @param type equipment | porttraffic | channel
     */
    getChartData: async (type: string) => {
      const api: TopologyApi = new TopologyApi(self.environment.api);
      const result: any = await api.getChartData(type);
      if (result.kind === 'ok') {
        switch (type) {
          case 'equipment':
            self.rootStore.loadingStore.setMessage('Loading Equipments..');
            self.setChartEquipments(JSON.stringify(result.data.data));
            break;
          case 'porttraffic':
            self.rootStore.loadingStore.setMessage('Loading Port Traffic..');
            self.setChartPortTraffic(JSON.stringify(result.data.data));
            break;
          case 'channel':
            self.rootStore.loadingStore.setMessage('Loading Channels..');
            self.setChartChannels(JSON.stringify(result.data.data));
            self.rootStore.realtimeAlarmStore.setChannels(JSON.stringify(result.data.data));
            break;
        }
      } else {
        console.error(result.kind);
      }
    },
  }))
  // Lifecycle
  .actions((self) => ({
    afterCreate: () => {
      // console.log('TopologyStore: after create')
    },
    beforeDestroy: () => { },
    afterAttach: () => { },
    beforeDettach: () => { },
  }));

// --------------------------------------------------------------------------
type TTopologyStore = Instance<typeof TopologyStore>;
type TTopologyStoreSnapshotIn = SnapshotIn<typeof TopologyStore>;
type TTopologyStoreSnapshotOut = SnapshotOut<typeof TopologyStore>;

export interface ITopologyStore extends TTopologyStore { }
export type TTopologyStoreKeys = keyof TTopologyStoreSnapshotOut & string;
export interface ITopologyStoreSnapshotIn extends TTopologyStoreSnapshotIn { }
export interface ITopologyStoreSnapshotOut extends TTopologyStoreSnapshotOut { }
export const createTopologyStore = (args?: ITopologyStoreSnapshotIn) => {
  return TopologyStore.create({
    topologies: [],
    ...args,
  });
};
