import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { VocStatisticsApi, TGetVocStatisticsResult } from '../../services';
import { withEnvironment } from '../extensions/with-environment';
import { withRootStore } from '../extensions/with-root-store';
import {
  IStbStatistics,
  IStbStatisticsSnapshot,
  StbStatistics,
} from '../stb-statistics/StbStatistics';
import {
  IVocStatistics,
  IVocStatisticsSnapshot,
  VocStatistics,
  TVocStatisticsFilter,
} from '../voc-statistics/VocStatistics';

export const VocStatisticsStore = types
  .model('VocStatisticsStore')
  .props({
    vocStatisticss: types.optional(types.array(VocStatistics), []),
    vocStatisticssForOneViewMap: types.optional(types.array(StbStatistics), []),
    vocStatisticssForOneViewChart: types.optional(types.array(StbStatistics), []),
  })
  .extend(withEnvironment)
  .extend(withRootStore)
  .views((self) => ({}))
  .actions((self) => ({
    setVocStatisticss: (vocStatisticss: IVocStatisticsSnapshot[]) => {
      self.vocStatisticss.replace(vocStatisticss as IVocStatistics[]);
    },
    setVocStatisticssForOneViewMap: (vocStatisticss: IStbStatisticsSnapshot[]) => {
      self.vocStatisticssForOneViewMap.replace(vocStatisticss as IStbStatistics[]);
    },
    resetVocStatisticssForOneViewMap: () => {
      self.vocStatisticssForOneViewMap.replace([]);
    },
    setVocStatisticssForOneViewChart: (vocStatisticss: IStbStatisticsSnapshot[]) => {
      self.vocStatisticssForOneViewChart.replace(vocStatisticss as IStbStatistics[]);
    },
    resetVocStatisticssForOneViewChart: () => {
      self.vocStatisticssForOneViewChart.replace([]);
    },
  }))
  .actions((self) => ({
    /**
     * INF_CAIMS_00303,304,305
     * VOC 지역별, 시간별, 일별 통계 데이터 조회
     * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=1207726427
     * @param vocStatisticsFilter
     * @returns
     */
    gets: async (vocStatisticsFilter?: TVocStatisticsFilter) => {
      self.setVocStatisticss([]);
      try {
        const vocStatisticsApi: VocStatisticsApi = new VocStatisticsApi(self.environment.api);
        let result: TGetVocStatisticsResult;

        result = await vocStatisticsApi.gets(vocStatisticsFilter);

        if (self.rootStore.responseStore.getResponseResult(result)) {
          if (result.data) {
            self.setVocStatisticss(result.data);
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },
  }));

type TVocStatisticsStore = Instance<typeof VocStatisticsStore>;
type TVocStatisticsStoreSnapshot = SnapshotOut<typeof VocStatisticsStore>;

export interface IVocStatisticsStore extends TVocStatisticsStore {}
export type TVocStatisticsStoreKeys = keyof TVocStatisticsStoreSnapshot & string;
export interface IVocStatisticsStoreSnapshot extends TVocStatisticsStoreSnapshot {}
export const createVocStatisticsStore = () =>
  types.optional(VocStatisticsStore, {} as TVocStatisticsStore);
