import { Theme } from '@mui/material/styles';
import { scaleBand } from 'd3-scale';
import { eachWeekendOfMonth } from 'date-fns';

// ----------------------------------------------------------------------
// MuiInput-root 
// MuiInput-underline 
// MuiInputBase-root 
// MuiInputBase-colorPrimary 
// MuiInputBase-formControl 
// MuiInputBase-sizeSmall 
// MuiInputBase-adornedEnd 
// css-88wb6w-MuiInputBase-root-MuiInput-root
export default function Input(theme: Theme) {
  return {
    MuiInputBase: {
      styleOverrides: {
        root: {
          width: '100%',
          minHeight: 30,
          '&.Mui-disabled': {
            '& svg': { color: theme.palette.text.disabled },
          },
          paddingLeft: 8,
          '&.MuiInput-root': {
            marginTop: 0,
          },

          '&.MuiTablePagination-input': {
            width: 'auto'
          }
        },
        input: {
          '&::placeholder': {
            opacity: 1,
            color: theme.palette.text.disabled,
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          width: '100%',
          '&  input:-webkit-autofill,input:-webkit-autofill:focus': {
            transition: 'background-color 600000s 0s, color 600000s 0s',
          },
          paddingBottom: 0,
        },
        underline: {
          '&:before': {
            borderBottomColor: theme.palette.grey[500_56],
            transition: 'all 200ms ease-out',
          },
          '&:after': {
            borderBottomColor: theme.palette.secondary.light,
            transition: 'all 200ms ease-out',
          },
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.grey[500_12],
          '&:hover': {
            backgroundColor: theme.palette.grey[500_16],
          },
          '&.Mui-focused': {
            backgroundColor: theme.palette.action.focus,
          },
          '&.Mui-disabled': {
            backgroundColor: theme.palette.action.disabledBackground,
          },
        },
        underline: {
          '&:before': {
            borderBottomColor: theme.palette.grey[500_56],
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.grey[500_32],
          },
          '&.Mui-disabled': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: theme.palette.action.disabledBackground,
              backgroundColor: theme.palette.action.disabledBackground,
            },
          },
        },
      },
    },

    MuiFormControl: {
      styleOverrides: {
        root: {
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          flexWrap: 'nowrap',
          whiteSpace: 'nowrap',
          '&:hover': {
            '.MuiFormLabel-root:not(.Mui-focused)': {
              borderBottomWidth: 2,
              borderBottomColor: theme.palette.grey[0],
              transition: 'all 200ms ease-out',
            },
            '.MuiInput-root:before': {
              borderBottomWidth: 2,
              borderBottomColor: theme.palette.grey[0],
              transition: 'all 200ms ease-out',
            },
            '.MuiFormLabel-root:is(.Mui-error)': {
              borderBottomColor: theme.palette.error.main,
            },
          },
          '&:before': {
            borderBottomColor: theme.palette.grey[500_56],
            transition: 'all 200ms ease-out',
          },
          '&:after': {
            borderBottomColor: theme.palette.secondary.light,
            transition: 'all 200ms ease-out',
          },
          // '&:has(.Mui-error)': {
          //   display: 'block'
          // }
          '&.MuiTextField-root': {
            marginBottom: 0,
          },
        },
      }
    },

    MuiFormLabel: {
      styleOverrides: {
        root: {
          '&:not(.MuiInputLabel-outlined)': {
            display: 'flex',
            borderBottom: '1px solid',
            borderBottomColor: theme.palette.grey[500_56],
            transform: 'translate(0, 0) scale(1)',
            marginTop: 0,
            paddingTop: 2,
            paddingRight: 8,
            minHeight: 30,
            height: '100%',
            alignSelf: 'flex-end',
            transition: 'all 200ms ease-out',
            '&.Mui-focused': {
              color: theme.palette.secondary.light,
              transform: 'translate(0, 0) scale(1)',
              borderBottomWidth: 2,
              borderBottomColor: theme.palette.secondary.light,
              transition: 'all 200ms ease-out',
            },
            '&.Mui-error': {
              color: theme.palette.error.main,
              borderBottomWidth: 2,
              borderBottomColor: theme.palette.error.main,
              transition: 'all 200ms ease-out',
            }
          },
        },
        asterisk: {
          color: theme.palette.secondary.light,
          marginLeft: 4,
        }

      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          '&:not(.MuiInputLabel-outlined)': {
            position: 'relative',
            display: 'inline-table',
            transform: 'translate(0, 0) scale(1)',
            overflow: 'unset',
            // width: '100%',
            minWidth: 80,
            paddingTop: 2,
          },
          '&:is(.MuiInputLabel-outlined)': {
            fontSize: '1rem',
          },
        }
      }
    },
  };
}
