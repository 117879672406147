import { ApiResponse } from 'apisauce';
import { INode } from '../../models';
import { Api } from '../api/api';
import { CaiApiOkResponseData, CaiApiResponseData, getGeneralApiProblem } from '../api/api-problem';
import { TDeleteResult, TGetResult, TGetsResult, TPostResult, TPutResult } from '../api/api-result';

/**
 * # Node의 Api 인터페이스 클라이언트
 *
 * 토폴로지 상 Node의 기본 CRUD를 제공
 *
 * ## 사용방법
 *
 * ```ts
 * const api = new NodeApi();
 * api.gets();
 * api.get(1);
 * api.post({});
 * api.put({});
 * api.delete(1);
 * ```
 */
export class NodeApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  async gets(params?: Map<string, any>): Promise<TGetsResult<INode>> {
    try {
      const url = '/node';
      const response: ApiResponse<CaiApiResponseData<INode>> = await this.api.apisauce.get(
        url,
        params,
      );

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) {
          return problem;
        }
      }

      const result = response.data as CaiApiOkResponseData<INode>;
      return {
        kind: 'ok',
        data: result.data as INode[],
        pagenation: result.pagenation,
      };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async get(id: number): Promise<TGetResult<INode>> {
    try {
      const url = `/node/${id}`;
      const response: ApiResponse<CaiApiResponseData<INode>> = await this.api.apisauce.get(url);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) {
          return problem;
        }
      }

      const node: INode = (response.data as CaiApiOkResponseData<INode>)?.data as INode;
      return { kind: 'ok', data: node };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async post(payload: INode): Promise<TPostResult<INode>> {
    try {
      const url = '/node';
      const response: ApiResponse<CaiApiResponseData<INode>> = await this.api.apisauce.post(
        url,
        payload,
      );

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) {
          return problem;
        }
      }

      const node: INode = (response.data as CaiApiOkResponseData<INode>)?.data as INode;
      return { kind: 'ok', data: node };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async put(payload: INode): Promise<TPutResult<INode>> {
    try {
      const url = `/node/${payload.id}`;
      const response: ApiResponse<CaiApiResponseData<INode>> = await this.api.apisauce.put(
        url,
        payload,
      );

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) {
          return problem;
        }
      }

      const node: INode = (response.data as CaiApiOkResponseData<INode>).data as INode;
      return { kind: 'ok', data: node };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async delete(id: string | string[]): Promise<TDeleteResult<INode>> {
    try {
      const url = `/node` + (typeof id === 'string' ? `/${id}` : `?id=${id.join(',')}`);
      const response: ApiResponse<CaiApiResponseData<null>> = await this.api.apisauce.delete(url);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) {
          return problem;
        }
      }

      return { kind: 'ok' };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }
}
