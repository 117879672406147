import { withRootStore } from './../extensions/with-root-store';
import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import {
  ChartApi,
  GetDashboardCountResult,
  GetGenderCountResult,
  GetDashboardDataResult,
} from '../../services';
import { ChartModel, ChartSnapshot } from '../../models/chart/chart';
import { withEnvironment } from '../extensions/with-environment';

export const ChartStoreModel = types
  .model('ChartStore')
  .props({
    personCount: types.maybe(types.number),
    registrationCount: types.maybe(types.number),
    registrationMaleCount: types.maybeNull(types.number),
    patientMaleCount: types.maybeNull(types.number),
    registrationFemaleCount: types.maybeNull(types.number),
    patientFemaleCount: types.maybeNull(types.number),
    registrationDxCountList: types.optional(types.array(ChartModel), []),
    registrationBcmCountList: types.optional(types.array(ChartModel), []),
    bankCategoryList: types.optional(types.array(ChartModel), []),
    ageGenderList: types.optional(types.array(ChartModel), []),
    genderDxCategoryList: types.optional(types.array(ChartModel), []),
    businessCode: types.maybe(types.string),
    coopBank: types.maybe(types.string),
    year: types.maybe(types.string),
    loading: types.maybe(types.boolean),
  })
  .extend(withEnvironment)
  .extend(withRootStore)
  .actions((self) => ({
    resetSearchChart: () => {
      self.year = '';
      self.businessCode = '';
      self.coopBank = '';
    },
    setLoading: (loading: boolean) => {
      self.loading = loading;
    },
    setBusinessCode: (businessCode: string) => {
      self.businessCode = businessCode;
    },
    setCoopBank: (coopBank: string) => {
      self.coopBank = coopBank;
    },
    setYear: (year: string) => {
      self.year = year;
    },
    setPersonCount: (personCount: number) => {
      self.personCount = personCount;
    },
    setRegistrationCount: (registrationCount: number) => {
      self.registrationCount = registrationCount;
    },
    setRegistrationMaleCount: (registrationMaleCount: number) => {
      self.registrationMaleCount = registrationMaleCount;
    },
    setPatientMaleCount: (patientMaleCount: number) => {
      self.patientMaleCount = patientMaleCount;
    },
    setRegistrationFemaleCount: (registrationFemaleCount: number) => {
      self.registrationFemaleCount = registrationFemaleCount;
    },
    setPatientFemaleCount: (patientFemaleCount: number) => {
      self.patientFemaleCount = patientFemaleCount;
    },
    setRegistrationDxCountList: (registrationDxList: ChartSnapshot[]) => {
      self.registrationDxCountList.replace(registrationDxList);
    },
    setRegistrationBcmCountList: (registrationBcmList: ChartSnapshot[]) => {
      self.registrationBcmCountList.replace(registrationBcmList);
    },
    setBankCategoryList: (bankCategoryList: ChartSnapshot[]) => {
      self.bankCategoryList.replace(bankCategoryList);
    },
    setAgeGenderList: (ageGenderList: ChartSnapshot[]) => {
      self.ageGenderList.replace(ageGenderList);
    },
    setGenerDxCategoryList: (genderDxCategoryList: ChartSnapshot[]) => {
      self.genderDxCategoryList.replace(genderDxCategoryList);
    },
  }))
  .actions((self) => ({
    /**
     * 환자 수 를 가져오는 API를 호출합니다.
     * @param year
     * @param businessCode
     * @param coopBank
     */
    getPersonCount: async () => {
      const chartApi: ChartApi = new ChartApi(self.environment.api);
      const result: GetDashboardCountResult = await chartApi.getPersonCount(
        self.year,
        self.businessCode,
        self.coopBank,
      );

      if (result.kind === 'ok') {
        if (result.count) {
          self.setPersonCount(result.count);
        } else {
          self.setPersonCount(0);
        }
      } else {
      }
    },

    /** 접수 건수를 가져오는 API를 호출합니다.
     * @param year
     * @param businessCode
     * @param coopBank
     */
    getRgistrationCount: async () => {
      const chartApi: ChartApi = new ChartApi(self.environment.api);
      const result: GetDashboardCountResult = await chartApi.getRegistrationCount(
        self.year,
        self.businessCode,
        self.coopBank,
      );

      if (result.kind === 'ok') {
        if (result.count) {
          self.setRegistrationCount(result.count);
        } else {
          self.setRegistrationCount(0);
        }
      } else {
      }
    },

    /** 전체 임상정보 건수를 가져오는 API를 호출합니다.
     * @param year
     * @param businessCode
     * @param coopBank
     */
    getRegistrationCount: async () => {
      const chartApi: ChartApi = new ChartApi(self.environment.api);
      const result: GetDashboardCountResult = await chartApi.getRegistrationCount(
        self.year,
        self.businessCode,
        self.coopBank,
      );

      if (result.kind === 'ok') {
        if (result.count) {
          self.setRegistrationCount(result.count);
        } else {
          self.setRegistrationCount(0);
        }
      } else {
      }
    },

    /** 성별 비율을 가져오는 API를 호출합니다.
     * @param year
     * @param businessCode
     * @param coopBank
     */
    getGenderCount: async () => {
      const chartApi: ChartApi = new ChartApi(self.environment.api);
      const result: GetGenderCountResult = await chartApi.getGenderCount(
        self.year,
        self.businessCode,
        self.coopBank,
      );

      if (result.kind === 'ok') {
        if (result.registrationMaleCount) {
          self.setRegistrationMaleCount(result.registrationMaleCount);
        } else {
          self.setRegistrationMaleCount(0);
        }

        if (result.patientMaleCount) {
          self.setPatientMaleCount(result.patientMaleCount);
        } else {
          self.setPatientMaleCount(0);
        }

        if (result.registrationFemaleCount) {
          self.setPatientFemaleCount(result.registrationFemaleCount);
        } else {
          self.setPatientFemaleCount(0);
        }

        if (result.patientFemaleCount) {
          self.setPatientFemaleCount(result.patientFemaleCount);
        } else {
          self.setPatientFemaleCount(0);
        }
      } else {
        self.setRegistrationMaleCount(0);
        self.setPatientMaleCount(0);
        self.setPatientFemaleCount(0);
        self.setPatientFemaleCount(0);
      }
    },

    /** 질환별 수집 현황을 가져오는 API를 호출합니다.
     * @param year
     * @param businessCode
     * @param coopBank
     */
    getRegistrationDxCount: async () => {
      const chartApi: ChartApi = new ChartApi(self.environment.api);
      const result: GetDashboardDataResult = await chartApi.getRegistrationDxCount(
        self.year,
        self.businessCode,
        self.coopBank,
      );

      if (result.kind === 'ok') {
        if (result.resultList) {
          self.setRegistrationDxCountList(result.resultList);
        } else {
          self.setRegistrationDxCountList([]);
        }
      } else {
      }
    },

    /** 사업별 수집 현황을 가져오는 API를 호출합니다.
     * @param year
     * @param businessCode
     * @param coopBank
     */
    getRegistrationBcmCount: async () => {
      const chartApi: ChartApi = new ChartApi(self.environment.api);
      const result: GetDashboardDataResult = await chartApi.getRegistrationBcmCount(
        self.year,
        self.businessCode,
        self.coopBank,
      );

      if (result.kind === 'ok') {
        if (result.resultList) {
          self.setRegistrationBcmCountList(result.resultList);
        } else {
          self.setRegistrationBcmCountList([]);
        }
      } else {
      }
    },

    /** 전체 은행별 수집 현황을 가져오는 API를 호출합니다.
     * @param year
     * @param businessCode
     * @param coopBank
     */
    getBankCategory: async () => {
      const chartApi: ChartApi = new ChartApi(self.environment.api);
      const result: GetDashboardDataResult = await chartApi.getBankCategory(
        self.year,
        self.businessCode,
        self.coopBank,
      );

      if (result.kind === 'ok') {
        if (result.resultList) {
          self.setBankCategoryList(result.resultList);
        } else {
          self.setBankCategoryList([]);
        }
      } else {
      }
    },

    /** 연령 성별 수집 현황을 가져오는 API를 호출합니다.
     * @param year
     * @param businessCode
     * @param coopBank
     */
    getAgeAndGender: async () => {
      const chartApi: ChartApi = new ChartApi(self.environment.api);
      const result: GetDashboardDataResult = await chartApi.getAgeAndGender(
        self.year,
        self.businessCode,
        self.coopBank,
      );

      if (result.kind === 'ok') {
        if (result.resultList) {
          self.setAgeGenderList(result.resultList);
        } else {
          self.setAgeGenderList([]);
        }
      } else {
      }
    },

    /** 질환별 성별 현황을 가져오는 API를 호출합니다.
     * @param year
     * @param businessCode
     * @param coopBank
     */
    getGenderDxCategory: async () => {
      const chartApi: ChartApi = new ChartApi(self.environment.api);
      const result: GetDashboardDataResult = await chartApi.getGnederDxCategory(
        self.year,
        self.businessCode,
        self.coopBank,
      );

      if (result.kind === 'ok') {
        if (result.resultList) {
          self.setGenerDxCategoryList(result.resultList);
        } else {
          self.setGenerDxCategoryList([]);
        }
      } else {
      }
    },
  }));

type ChartStoreType = Instance<typeof ChartStoreModel>;
export interface ChartStore extends ChartStoreType {}
type ChartStoreSnapshotType = SnapshotOut<typeof ChartStoreModel>;
export interface ChartStoreSnapshot extends ChartStoreSnapshotType {}
export const createChartStoreDefaultModel = () => {};
