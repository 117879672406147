import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { CC } from '../../commonCodes/commonCodes';
import {
  TGetEquipGroupResult,
  TGetEquipGroupsResult,
} from '../../services/equip-group/EquipGroupTypes';

import { EquipGroupApi } from '../../services/equip-group/EquipGroup';
import { IEquipGroup, IEquipGroupSnapshot } from '../equip-group/EquipGroup';
import { Equipment, IEquipment } from '../equipment/Equipment';
import { withEnvironment } from '../extensions/with-environment';
import {
  TPostEquipGroupResult,
  TUpdateEquipGroupResult,
} from './../../services/equip-group/EquipGroupTypes';
import { EquipGroup } from './../equip-group/EquipGroup';
import { IEquipmentSnapshot } from './../equipment/Equipment';
import { withRootStore } from './../extensions/with-root-store';
import {
  createModelCode,
  IModelCode,
  IModelCodeSnapshot,
  ModelCode,
} from './../model-code/ModelCode';

export const EquipGroupStore = types
  .model('EquipGroupStore')
  // --------------------------------------------------------------------------
  .props({
    equipGroups: types.optional(types.array(EquipGroup), []),
    equipGroup: types.optional(EquipGroup, {}),
    codeTypes: types.optional(types.array(ModelCode), []),
    equipSidList: types.optional(types.array(types.number), []),
    addEquipmentList: types.optional(types.array(Equipment), []),
    isSearchEnabled: types.optional(types.boolean, true),
    equipGroupType: createModelCode(),
  })
  .extend(withEnvironment)
  .extend(withRootStore)
  // eslint-disable-line @typescript-eslint/no-unused-vars
  .views((self) => ({}))
  // --------------------------------------------------------------------------
  // MUTATEs - 모델 상태를 변경
  .actions((self) => ({
    setEquipGroupType: (equipType: IModelCodeSnapshot) => {
      self.equipGroupType = equipType as IModelCode;
    },
    setIsSearchEnabled: (isSearchEnabled: boolean) => {
      self.isSearchEnabled = isSearchEnabled;
    },
    replaceAddEquipmentList: (equipments: IEquipmentSnapshot[]) => {
      self.addEquipmentList.replace(equipments as IEquipment[]);
    },
    appendAddEquipmnetList: (equipmnet: IEquipmentSnapshot) => {
      self.addEquipmentList.push(equipmnet as IEquipment);
    },
    setEquipSidList: (equipSidList: number[]) => {
      self.equipSidList.replace(equipSidList);
    },
    appendEquipSidList: (equipSid: number) => {
      self.equipSidList.push(equipSid);
    },
    deleteEquipSidInList: (equipSid: number) => {
      self.equipSidList.remove(equipSid);
    },
    setEquipGroups: (equipGroups: IEquipGroupSnapshot[]) => {
      self.equipGroups.replace(equipGroups as IEquipGroup[]);
    },
    setEquipGroup: (equipGroup: IEquipGroupSnapshot) => {
      self.equipGroup = { ...(equipGroup as IEquipGroup) };
    },

    setCodeTypes: () => {
      let codeTypes: IModelCodeSnapshot[] = [];

      if (self.equipGroups === null) {
        return;
      }

      self.equipGroups.forEach((item, index) => {
        let codeType: IModelCodeSnapshot = {
          value: item.equipGroupNm,
          code: item.equipGroupSid,
          pcode: item.equipType.code,
        } as IModelCodeSnapshot;

        codeTypes.push(codeType);
      });

      self.codeTypes.replace(codeTypes as IModelCode[]);
    },

    getCodeType: (value: string): IModelCodeSnapshot => {
      let code: any = NaN;
      let keys = Object.keys(CC.EQUIP_TYPE);
      type equipKey = keyof typeof CC.EQUIP_TYPE;
      (keys as equipKey[]).forEach((key, index) => {
        if (value === key) {
          code = CC.EQUIP_TYPE[key];
        }
      });
      let codeTypeList = CC.EQUIP_TYPE.__list__;

      let result: IModelCodeSnapshot = {} as IModelCodeSnapshot;
      codeTypeList.forEach((codeType, index) => {
        if (codeType.code === code) {
          result = codeType as IModelCodeSnapshot;
        }
      });
      return result;
    },
  }))
  // --------------------------------------------------------------------------
  // REQUESTs - 서비스 요청 및 기타 인터페이스 요청
  .actions((self) => ({
    gets: async (size: number, page: number) => {
      try {
        const equipGroupApi: EquipGroupApi = new EquipGroupApi(self.environment.api);
        const result: TGetEquipGroupsResult = await equipGroupApi.gets(
          page,
          size,
          self.equipGroupType as IModelCodeSnapshot,
        );

        if (self.rootStore.responseStore.getResponseResult(result)) {
          if (result.equipGroups) {
            self.setEquipGroups(result.equipGroups);
            self.setCodeTypes();
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },
    get: async (equipGroupSid: number) => {
      try {
        const equipGroupApi: EquipGroupApi = new EquipGroupApi(self.environment.api);
        const result: TGetEquipGroupResult = await equipGroupApi.get(equipGroupSid);

        if (self.rootStore.responseStore.getResponseResult(result)) {
          if (result.equipGroup) {
            self.setEquipGroup(result.equipGroup);
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },
    post: async (equipGroup: IEquipGroupSnapshot) => {
      try {
        const equipGroupApi: EquipGroupApi = new EquipGroupApi(self.environment.api);
        const result: TPostEquipGroupResult = await equipGroupApi.post(equipGroup);

        if (self.rootStore.responseStore.getResponseResult(result)) {
          if (result.equipGroup) {
            self.setEquipGroup(result.equipGroup);
          }
        }
      } catch (e) {
        console.log(e);
        self.rootStore.responseStore.errorProcessing(e);
      }
    },
    put: async (equipGroup: IEquipGroupSnapshot) => {
      try {
        const equipGroupApi: EquipGroupApi = new EquipGroupApi(self.environment.api);
        const result: TUpdateEquipGroupResult = await equipGroupApi.put(equipGroup);

        if (self.rootStore.responseStore.getResponseResult(result)) {
          if (result.equipGroup) {
            self.setEquipGroup(result.equipGroup);
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },
    settingEquiplist: () => {
      let list: IEquipmentSnapshot[] = [] as IEquipmentSnapshot[];
      let addList: IEquipmentSnapshot[] = [] as IEquipmentSnapshot[];
      self.rootStore.equipmentStore.equipments.forEach((item, listIndex) => {
        let element: IEquipmentSnapshot = item as IEquipmentSnapshot;
        let isExist: boolean = true;
        self.equipSidList.forEach((equipSid, index) => {
          if (equipSid === element.equipSid) {
            isExist = false;
          }
        });
        if (isExist) {
          list.push(element);
        } else {
          addList.push(element);
        }
      });

      self.rootStore.equipmentStore.setEquipments(list);
      self.setEquipSidList([]);
      console.log(`settingEquipList`, self.equipSidList as Number[]);
      console.log(`settingEquipList`, addList);
      return addList;
    },
  }));

// --------------------------------------------------------------------------
type TEquipGroupStore = Instance<typeof EquipGroupStore>;
type TEquipGroupStoreSnapshot = SnapshotOut<typeof EquipGroupStore>;

export interface IEquipGroupStore extends TEquipGroupStore {}
export type TEquipGroupStoreKeys = keyof TEquipGroupStoreSnapshot & string;
export interface IEquipGroupStoreSnapshot extends TEquipGroupStoreSnapshot {}
export const createEquipGroupStore = () => types.optional(EquipGroupStore, {} as TEquipGroupStore);
