import { Instance, SnapshotOut, types } from 'mobx-state-tree';

export const StbSimulator = types
  .model('StbSimulator')
  .props({
    ID: types.optional(types.string, ''),
    NAME: types.optional(types.string, ''),
    RESULT_CD: types.optional(types.string, ''),
    RUN_MODE: types.optional(types.string, ''),
    SAID: types.optional(types.string, ''),
    STB_STATE: types.optional(types.string, ''),
    ERR_MSG: types.optional(types.string, ''),
  })
  .actions((self) => ({
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as IStbSimulator;
      (Object.keys(newProps) as TStbSimulatorKeys[]).forEach((key) => {
        //@ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type TStbSimulator = Instance<typeof StbSimulator>;
type TStbSimulatorSnapshot = SnapshotOut<typeof StbSimulator>;

export interface IStbSimulator extends TStbSimulator {}
export type TStbSimulatorKeys = keyof TStbSimulatorSnapshot;
export interface IStbSimulatorSnapshot extends TStbSimulatorSnapshot {}
export const createStbSimulator = () => types.optional(StbSimulator, {} as TStbSimulator);
