import { destroy, Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree';
import { LinkApi } from '../../services';
import {
  TDeleteResult,
  TGetResult,
  TGetsResult,
  TPostResult,
  TPutResult,
} from '../../services/api/api-result';
import { withEnvironment } from '../extensions/with-environment';
import { withRootStore } from '../extensions/with-root-store';
import { ILink, ILinkSnapshotIn, Link } from './Link';

/**
 * # LinkStore
 *
 * LinkStore을 설명하세요.
 */
export const LinkStore = types
  .model('LinkStore')
  // --------------------------------------------------------------------------
  .props({
    links: types.optional(types.array(Link), []),
    activated: types.maybeNull(types.reference(Link)),
  })
  .extend(withRootStore)
  .extend(withEnvironment)
  // eslint-disable-line @typescript-eslint/no-unused-vars
  .views((self) => ({}))
  // --------------------------------------------------------------------------
  // MUTATEs - 모델 상태를 변경
  .actions((self) => ({
    /**
     * 링크 찾기
     * @param link 찾을 링크의 정보
     * @returns ILink
     */
    find: (link: ILink | ILinkSnapshotIn | string) => {
      const id = typeof link === 'string' ? link : link.id;
      return self.links.find((l) => l.id === id);
    },
  }))
  //
  .actions((self) => ({
    /**
     * 링크를 활성화
     * @param link
     */
    activate: (link: ILink | ILinkSnapshotIn | string) => {
      const id = typeof link === 'string' ? link : link.id;
      // @ts-ignore
      self.activated = id;
    },

    /**
     * links을 교체
     *
     * @param `links` 새로운 모델의 배열
     */
    replace: (links: (ILink | ILinkSnapshotIn)[]) => {
      self.activated = null;
      // @ts-ignore
      self.links.replace(links);
    },

    /**
     * 링크 추가
     *
     * @param link
     */
    add: (link: ILink | ILinkSnapshotIn) => {
      self.links.push(link);
      // @ts-ignore
      self.activated = link.id;
    },

    /**
     * 링크 수정
     *
     * @param link
     */
    set: (link: ILink | ILinkSnapshotIn) => {
      const linkIndex = self.links.findIndex((l) => l.id === link.id);
      let links = [...self.links];
      // @ts-ignore
      links.splice(linkIndex, 1, link);
      self.links.replace(links);
    },

    /**
     * 링크 삭제
     *
     * @param link
     */
    remove: (link: ILink | ILinkSnapshotIn | string) => {
      if (!link) {
        return;
      }
      const found = self.find(link);
      if (found) {
        destroy(found);
      }
    },
  }))
  // --------------------------------------------------------------------------
  // REQUESTs - 서비스 요청 및 기타 인터페이스 요청
  .actions((self) => ({
    /**
     * 링크 목록 조회
     *
     * 조회한 결과로 Links를 교체한다. 실패시 에러 로그를 남긴다.
     */
    gets: async () => {
      const api = new LinkApi(self.environment.api);
      const result: TGetsResult<ILink> = await api.gets();
      if (result.kind === 'ok') {
        self.replace(result.data);
      } else {
        console.error(result.kind);
      }
    },

    /**
     * 링크 상세 조회
     */
    get: async (link?: ILink) => {
      const id = link ? link.id : self.activated?.id;
      if (id) {
        const api = new LinkApi(self.environment.api);
        const result: TGetResult<ILink> = await api.get(id);
        if (result.kind === 'ok') {
          self.set(result.data);
        } else {
          console.error(result.kind);
        }
      }
    },

    /**
     * 링크 생성
     */
    post: async () => {
      const api: LinkApi = new LinkApi(self.environment.api);
      const result: TPostResult<ILink> = await api.post(self.activated!);
      if (result.kind === 'ok') {
        self.add(result.data);
      } else {
        console.error(result.kind);
      }
    },

    /**
     * 링크 수정
     */
    put: async () => {
      const api: LinkApi = new LinkApi(self.environment.api);
      const result: TPutResult<ILink> = await api.put(self.activated!);
      if (result.kind === 'ok') {
        self.set(result.data);
      } else {
        console.error(result.kind);
      }
    },

    /**
     * 링크 삭제
     * @param link
     */
    delete: async (link: ILinkSnapshotIn) => {
      const api: LinkApi = new LinkApi(self.environment.api);
      const result: TDeleteResult<ILink> = await api.delete(link.id!);
      if (result.kind === 'ok') {
        self.remove(link);
      } else {
        console.error(result.kind);
      }
    },
  }));

// --------------------------------------------------------------------------
type TLinkStore = Instance<typeof LinkStore>;
type TLinkStoreSnapshotIn = SnapshotIn<typeof LinkStore>;
type TLinkStoreSnapshotOut = SnapshotOut<typeof LinkStore>;

export interface ILinkStore extends TLinkStore {}
export type TLinkStoreKeys = keyof TLinkStoreSnapshotOut & string;
export interface ILinkStoreSnapshotIn extends TLinkStoreSnapshotIn {}
export interface ILinkStoreSnapshotOut extends TLinkStoreSnapshotOut {}
export const createLinkStore = (args?: ILinkStoreSnapshotIn) =>
  LinkStore.create({
    links: [],
    ...args,
  });
