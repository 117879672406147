import { ApiResponse } from 'apisauce';
import {
  IPaginationSnapshot,
  IResponseSnapshot,
  IKdbFilter,
  IKdbSnapshot,
  TKdbFilter,
} from '../../models';
import { Api } from '../api/api';
import { getGeneralApiProblem } from '../api/api-problem';
import {
  TDeleteKdbResult,
  TGetKdbResult,
  TGetKdbsResult,
  TPostKdbResult,
  TUpdateKdbResult,
} from './KdbTypes';

/**
 * # Kdb Api 서비스
 *
 * 서비스의 설명을 작성하세요.
 *
 * ## 사용방법
 *
 * ```ts
 * const service = new KdbApi();
 *
 * ```
 */
export class KdbApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  /**
   * INF_CAIMS_00112
   * 전체 KDB 목록을 조회한다.
   * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=1031196181
   * @param pagination
   * @param kdbFilter
   * @returns
   */
  async gets(pagination: IPaginationSnapshot, kdbFilter?: TKdbFilter): Promise<TGetKdbsResult> {
    try {
      const url = '/kdb';
      let params: any = {};
      if (kdbFilter) {
        params = kdbFilter;
      }

      params.size = pagination.size;
      params.page = pagination.page;

      const response: ApiResponse<any> = await this.api.apisauce.get(url, params);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;

      return {
        kind: 'ok',
        kdbs: response.data.data,
        pagination: response.data.pagination,
        responseInfo: responseInfo,
      };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  /**
   * INF_CAIMS_00113
   * 해당 KDB의 상세정보를 조회한다.
   * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=810774455
   * @param kdbSid
   * @returns
   */
  async get(kdbSid: Number): Promise<TGetKdbResult> {
    try {
      const url = `/kdb/${kdbSid.toString()}`;
      const response: ApiResponse<any> = await this.api.apisauce.get(url);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;

      if (responseInfo.resultCode === 'S') {
        return {
          kind: 'ok',
          kdb: response.data.data,
          responseInfo: responseInfo,
        };
      }

      return {
        kind: 'ok',
        responseInfo: responseInfo,
      };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  /**
   * INF_CAIMS_00114
   * 새로운 KDB를 등록한다.
   * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=1655752089
   * @param kdb
   * @returns
   */
  async post(kdb: IKdbSnapshot): Promise<TPostKdbResult> {
    try {
      const url = '/kdb';
      const params = createSendData(kdb);
      const response: ApiResponse<any> = await this.api.apisauce.post(url, params);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const resposneInfo: IResponseSnapshot = response.data;

      if (resposneInfo.resultCode === 'S') {
        if (response.data.data) {
          const kdb: IKdbSnapshot = response.data.data;
          return {
            kind: 'ok',
            kdb: kdb,
            responseInfo: resposneInfo,
          };
        }
      }

      return {
        kind: 'ok',
        responseInfo: resposneInfo,
      };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  /**
   * INF_CAIMS_00115
   * 해당 KDB를 수정한다.
   * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=720957831
   * @param kdb
   * @returns
   */
  async put(kdb: IKdbSnapshot): Promise<TUpdateKdbResult> {
    try {
      const url = `/kdb/${kdb.kdbSid}`;
      const params = createSendData(kdb);
      const response: ApiResponse<any> = await this.api.apisauce.put(url, params);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;
      if (responseInfo)
        if (response.data.data) {
          const kdb: IKdbSnapshot = response.data.data;
          return {
            kind: 'ok',
            responseInfo: responseInfo,
            kdb: kdb,
          };
        }

      return {
        kind: 'ok',
        responseInfo: responseInfo,
      };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  /**
   * INF_CAIMS_00116
   * 해당 KDB를 삭제한다.
   * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=721135938
   * @param id
   * @returns
   */
  async delete(id: number): Promise<TDeleteKdbResult> {
    try {
      const url = `/kdb/${id}`;
      const response: ApiResponse<any> = await this.api.apisauce.delete(url);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;

      return {
        kind: 'ok',
        responseInfo: responseInfo,
      };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  /**
   * INF_CAIMS_00117
   * 여러개 KDB를 한번에 삭제한다.
   * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=956395104
   * @param kdbSidList
   * @returns
   */
  async deletes(kdbSidList: number[]): Promise<TDeleteKdbResult> {
    try {
      let param: string = ``;
      kdbSidList.forEach((item: number, index: number) => {
        param += `kdbSidList=${item.toString()}`;
        if (index !== kdbSidList.length - 1) {
          param += `&`;
        }
      });

      const url = `/kdb?${param}`;

      const response: ApiResponse<any> = await this.api.apisauce.delete(url);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;

      return {
        kind: 'ok',
        responseInfo: responseInfo,
      };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }
}

const createSendData = (kdb: IKdbSnapshot) => {
  let sendData: any = {};

  if (kdb.kdbSid !== undefined) {
    sendData.kdbSid = kdb.kdbSid;
  }

  if (kdb.instType !== undefined) {
    sendData.instType = kdb.instType?.code;
  }

  if (kdb.eventType !== undefined) {
    sendData.eventType = kdb.eventType?.code;
  }

  if (kdb.problCause !== undefined) {
    sendData.problCause = kdb.problCause;
  }

  if (kdb.spcfProbl !== undefined) {
    sendData.spcfProbl = kdb.spcfProbl;
  }

  if (kdb.trtSchm !== undefined) {
    sendData.trtSchm = kdb.trtSchm;
  }

  return sendData;
};
