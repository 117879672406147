import { Instance, SnapshotOut, types } from "mobx-state-tree"
import {ChPerfQltyRaw, createChPerfQltyRaw, IChPerfQltyRaw} from "../ch-perf-qlty-raw/ChPerfQltyRaw";
import {withRootStore} from "../extensions/with-root-store";
import {withEnvironment} from "../extensions/with-environment";
import {IMisTransMissionFilter} from "../mis-trans-mission-graph/MisTransMissionGraph";
import {ChPerfQltyRawApi, TGetChPerfQltyRawResult} from "../../services";
/**
 * # ChPerfQltyRawStore
 * 
 * ChPerfQltyRawStore을 설명하세요.
 */
export const ChPerfQltyRawStore = types
  .model("ChPerfQltyRawStore")
  // --------------------------------------------------------------------------
  .props({
    chPerfQltyRaws : types.optional(types.array(ChPerfQltyRaw), []),
    misTranGraph: createChPerfQltyRaw(),
  })
  .extend(withEnvironment)
  .extend(withRootStore)
  // eslint-disable-line @typescript-eslint/no-unused-vars
  .views((self) => ({}))
  // --------------------------------------------------------------------------
  // MUTATEs - 모델 상태를 변경
  .actions((self) => ({
    /**
     * chPerfQltyRaws을 교체
     *
     * @param `chPerfQltyRaws` 새로운 모델의 배열
     */
    setChPerfQltyRaws: (chPerfQltyRaws: IChPerfQltyRaw[]) => {
      self.chPerfQltyRaws.replace(chPerfQltyRaws);
    },
    setMisTransGraph: (misTransGraph: IChPerfQltyRaw) => {
      self.misTranGraph = misTransGraph;
    }
  }))
  // --------------------------------------------------------------------------
  // REQUESTs - 서비스 요청 및 기타 인터페이스 요청 
  .actions((self) => ({
    /**
     * 전체 목록을 Api를 통해 조회
     * 
     * 조회한 결과로 ChPerfQltyRaws를 교체한다. 실패시 에러 로그를 남긴다.
     */
    get: async (filter?:IMisTransMissionFilter) => {
      const chPerfQltyRawApi : ChPerfQltyRawApi = new ChPerfQltyRawApi(self.environment.api);
      const result: TGetChPerfQltyRawResult = await chPerfQltyRawApi.get(filter);
      if (result.kind === 'ok') {
        self.setMisTransGraph(result.chPerfQltyRaw);
      }
      else {
        console.error(result.kind);
      }
    }
  }))

// --------------------------------------------------------------------------
type TChPerfQltyRawStore = Instance<typeof ChPerfQltyRawStore>;
type TChPerfQltyRawStoreSnapshot = SnapshotOut<typeof ChPerfQltyRawStore>

export interface IChPerfQltyRawStore extends TChPerfQltyRawStore {}
export type TChPerfQltyRawStoreKeys = keyof TChPerfQltyRawStoreSnapshot & string;
export interface IChPerfQltyRawStoreSnapshot extends TChPerfQltyRawStoreSnapshot {}
export const createChPerfQltyRawStore = () => 
  types.optional(ChPerfQltyRawStore, {} as TChPerfQltyRawStore);
