import { getGeneralApiProblem } from '../../services';
import { ApiResponse } from 'apisauce';
import { Api } from '../../services';
import { GetBusinessListResult, GetBankByBusinessResult } from '../../services';

export class BusinessApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  /** 사업 목록을 가져오는 API입니다.
   * @param startYear
   */
  async getBusinessList(startYear?: string): Promise<GetBusinessListResult> {
    try {
      const SUB_URL = '/business?startYear=' + startYear;
      const response: ApiResponse<any> = await this.api.apisauce.get(SUB_URL);
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      return {
        kind: 'ok',
        resultList: response.data.responseData,
      };
    } catch (e) {
      return Promise.resolve({ kind: 'notOk' });
    }
  }

  /** 사업별 은행 목록을 가져오는 API입니다.
   * @param businessSeq
   */
  async getBankByBusiness(businessSeq?: string | number): Promise<GetBankByBusinessResult> {
    try {
      const SUB_URL = '/business/selectBankByBusiness?businessSeq=' + businessSeq;
      const response: ApiResponse<any> = await this.api.apisauce.get(SUB_URL);
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      return {
        kind: 'ok',
        resultList: response.data.responseData,
      };
    } catch (e) {
      return { kind: 'notOk' };
    }
  }
}
