import { Instance, SnapshotOut, types } from "mobx-state-tree"

/**
 * # QuetoneData
 * 
 * QuetoneData을 설명하세요.
 */
export const QuetoneData = types
  .model("QuetoneData")
  // --------------------------------------------------------------------------
  .props({
    quetoneDt: types.optional(types.maybeNull(types.number), null),
    quetoneDtEd: types.optional(types.maybeNull(types.number), null), // DB 모델은 없고 자막방송인경우 자막이 없는것을 표시하기 위해 추가함.
    chSvcId: types.optional(types.number, 0),
    quetoneType: types.optional(types.string, ''),
    signlLoc: types.optional(types.string, ''),
    startYn: types.optional(types.boolean, false),
    chNm: types.optional(types.string, ''),
    isPrediction: types.optional(types.maybeNull(types.boolean),false),
    subTitleRealYn: types.optional(types.boolean, true), // DB 모델은 없고, 자막 방송인데 자막이 없으면 false
  })
  // --------------------------------------------------------------------------
  // MUTATEs ONLY! - 모델 상태를 변경
  // REQUESTs는 스토어 모델에 작성하세요.
  // ex) yarn gen model quetoneData --store
  .actions((self) => ({
    /*
     * 여러 prop을 한번에 수정
     */
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as IQuetoneData;
      (Object.keys(newProps) as TQuetoneDataKeys[]).forEach((key) => {
        // @ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type TQuetoneData = Instance<typeof QuetoneData>;
type TQuetoneDataSnapshot = SnapshotOut<typeof QuetoneData>

export interface IQuetoneData extends TQuetoneData { }
export type TQuetoneDataKeys = keyof TQuetoneDataSnapshot & string;
export interface IQuetoneDataSnapshot extends TQuetoneDataSnapshot { }
export const createQuetoneData = () =>
  types.optional(QuetoneData, {
    quetoneDt: null,
    quetoneDtEd: null,
    chSvcId: 0,
    quetoneType: '',
    signlLoc: '',
    startYn: false,
    chNm: '',
    subTitleRealYn: true,
  } as TQuetoneData);
