import { ApiResponse } from 'apisauce';
import {
  IPaginationSnapshot,
  ISnmpRulesetFilter,
  ISnmpRulesetSnapshot,
  TSnmpRulsetFilter,
} from '../../models';
import { IResponseSnapshot } from '../../models/response/Response';
import { dateToTimestamp } from '../../utils/dateExchanger';
import { Api } from '../api/api';
import { getGeneralApiProblem } from '../api/api-problem';
import {
  TDeleteSnmpRulesetResult,
  TGetSnmpRulesetResult,
  TGetSnmpRulesetsResult,
  TMibUploadResult,
  TUpdateSnmpRulesetResult,
} from './SnmpRulesetTypes';

export class SnmpRulesetApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  /**
   * INF_CAIMS_00049
   * SNMP 룰셋 목록을 조회한다.
   * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=488542543
   * @param pagination
   * @param snmpRulesetFilter
   * @returns
   */
  async gets(
    pagination: IPaginationSnapshot,
    snmpRulesetFilter?: TSnmpRulsetFilter,
  ): Promise<TGetSnmpRulesetsResult> {
    try {
      const url = '/ruleset/snmp';
      let params: any = {};

      if (snmpRulesetFilter) {
        params = filterSendData(snmpRulesetFilter);
      }

      params.size = pagination.size;
      params.page = pagination.page;

      const response: ApiResponse<any> = await this.api.apisauce.get(url, params);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;

      return {
        kind: 'ok',
        snmpRulesets: response.data.data,
        pagination: response.data.pagination,
        responseInfo: responseInfo,
      };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  /**
   * INF_CAIMS_00050
   * 해당 SNMP 룰셋의 상세정보를 조회한다.
   * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=1080779067
   * @param trapId
   * @returns
   */
  async get(trapId: string): Promise<TGetSnmpRulesetResult> {
    try {
      const url = `/ruleset/snmp/${trapId}`;
      const params = {};
      const response: ApiResponse<any> = await this.api.apisauce.get(url);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;

      return { kind: 'ok', snmpRuleset: response.data.data, responseInfo: responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  /**
   * INF_CAIMS_00052
   * 해당 SNMP 룰셋을 수정한다.
   * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=1636399592
   * @param snmpRuleset
   * @returns
   */
  async put(snmpRuleset: ISnmpRulesetSnapshot): Promise<TUpdateSnmpRulesetResult> {
    try {
      const url = `/ruleset/snmp/${snmpRuleset.trapId}`;
      let params: any = {};
      if (snmpRuleset) {
        params = createSendData(snmpRuleset);
      }
      const response: ApiResponse<any> = await this.api.apisauce.put(url, params);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;

      return {
        kind: 'ok',
        snmpRuleset: response.data.data,
        responseInfo: responseInfo,
      };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  /**
   * INF_CAIMS_00053
   * 해당 SNMP 룰셋을 삭제한다.
   * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=1526422337
   * @param trapId
   * @returns
   */
  async delete(trapId: string): Promise<TDeleteSnmpRulesetResult> {
    try {
      const url = `/ruleset/snmp/${trapId}`;
      const response: ApiResponse<any> = await this.api.apisauce.delete(url);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;

      return {
        kind: 'ok',
        responseInfo: responseInfo,
      };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  /**
   * INF_CAIMS_00053
   * 해당 SNMP 룰셋을 삭제한다.
   * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=1526422337
   * @param deleteSnmnpRulesetList
   * @returns
   */
  async deletes(deleteSnmnpRulesetList: string[]): Promise<TDeleteSnmpRulesetResult> {
    try {
      let param: string = ``;
      deleteSnmnpRulesetList.forEach((item: string, index: number) => {
        param += `trapIdList=${item}`;
        if (index !== deleteSnmnpRulesetList.length - 1) {
          param += `&`;
        }
      });

      const url = `/ruleset/snmp?${param}`;

      const response: ApiResponse<any> = await this.api.apisauce.delete(url);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;

      return {
        kind: 'ok',
        responseInfo: responseInfo,
      };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  /**
   * INF_CAIMS_00232
   * MIB 파일을 업로드하여 SNMP TRAP 정보를 등록한다.
   * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=965032247
   * @param fileList
   * @param intrlkSysSid
   * @param errorSkip
   * @returns
   */
  async mibUpload(
    fileList: File[],
    intrlkSysSid: number,
    errorSkip?: boolean,
  ): Promise<TMibUploadResult> {
    let url: string;

    if (errorSkip != undefined) {
      url = '/ruleset/snmp/mibUpload?intrlkSysSid=' + intrlkSysSid + '&errorSkip=' + errorSkip;
    } else {
      url = '/ruleset/snmp/mibUpload?intrlkSysSid=' + intrlkSysSid;
    }

    const formData = new FormData();

    if (fileList) {
      fileList.forEach((item: File) => {
        formData.append('fileList', item);
      });
    }

    await this.api.apisauce.setHeader('Content-Type', 'multipart/form-data');
    const response: ApiResponse<any> = await this.api.apisauce.post(url, formData);
    await this.api.apisauce.setHeader('Content-Type', 'application/json');

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    const responseInfo: IResponseSnapshot = response.data;

    return {
      kind: 'ok',
      snmpRuleset: response.data.data,
      responseInfo: responseInfo,
    };
  }
}

//createParams 에서 사용되는 ANY 타입은
//파라미터 변경으로 인한 ANY 로 사용 중
//정확한 규격이 Fix 되면 인터페이스로 변환할 것
const createSendData = (snmpRuleset: ISnmpRulesetSnapshot) => {
  let sendData: any = {};

  if (snmpRuleset.intrlkSysSid) {
    sendData.intrlkSysSid = snmpRuleset.intrlkSysSid;
  }

  if (snmpRuleset.trapNm !== undefined) {
    sendData.trapNm = snmpRuleset.trapNm;
  }

  if (snmpRuleset.entprOid !== undefined) {
    sendData.entprOid = snmpRuleset.entprOid;
  }

  if (snmpRuleset.mibNm !== undefined) {
    sendData.mibNm = snmpRuleset.mibNm;
  }

  if (snmpRuleset.apdInfo !== undefined) {
    sendData.apdInfo = snmpRuleset.apdInfo;
  }

  typeof snmpRuleset.useYn === 'boolean'
    ? (sendData.useYn = snmpRuleset.useYn)
    : (sendData.useYn = false);

  if (snmpRuleset.eventType) {
    sendData.eventType = snmpRuleset.eventType;
  }

  if (snmpRuleset.svrt) {
    sendData.svrt = snmpRuleset.svrt;
  }

  if (snmpRuleset.problCas !== undefined) {
    sendData.problCas = snmpRuleset.problCas;
  }

  if (snmpRuleset.sttusOid !== undefined) {
    sendData.sttusOid = snmpRuleset.sttusOid;
  }

  if (snmpRuleset.spcfProb !== undefined) {
    sendData.spcfProb = snmpRuleset.spcfProb;
  }

  if (snmpRuleset.spcfProbVarOidList) {
    sendData.spcfProbVarOidList = snmpRuleset.spcfProbVarOidList;
  }

  if (snmpRuleset.trapSvrtStngList) {
    sendData.trapSvrtStngList = [];
    snmpRuleset.trapSvrtStngList.map((item) => {
      const trapSvrtStng: any = {};
      if (item.sttusVal !== undefined) {
        trapSvrtStng.sttusVal = item.sttusVal;
      }

      if (item.trapId !== undefined) {
        trapSvrtStng.trapId = item.trapId;
      }

      if (item.svrt) {
        trapSvrtStng.svrt = item.svrt;
      }

      sendData.trapSvrtStngList.push(trapSvrtStng);
    });
  }

  if(snmpRuleset.msg) {
    sendData.msg = snmpRuleset.msg;
  }

  if(snmpRuleset.msgOid) {
    sendData.msgOid = snmpRuleset.msgOid;
  }

  return sendData;
};

const filterSendData = (snmpRulesetFilter: ISnmpRulesetFilter) => {
  let params: any = {};

  if (snmpRulesetFilter.trapId !== undefined) {
    params.trapId = snmpRulesetFilter.trapId;
  }

  if (snmpRulesetFilter.intrlkSysSid) {
    params.intrlkSysSid = snmpRulesetFilter.intrlkSysSid;
  }

  if (snmpRulesetFilter.trapNm !== undefined) {
    params.trapNm = snmpRulesetFilter.trapNm;
  }

  if (snmpRulesetFilter.eventType) {
    params.eventType = snmpRulesetFilter.eventType;
  }

  if (snmpRulesetFilter.mibNm !== undefined) {
    params.mibNm = snmpRulesetFilter.mibNm;
  }

  if (snmpRulesetFilter.useYn) {
    params.useYn = snmpRulesetFilter.useYn;
  }

  if (snmpRulesetFilter.regDtSt) {
    params.regDtSt = dateToTimestamp(snmpRulesetFilter.regDtSt);
  }

  if (snmpRulesetFilter.regDtEd) {
    params.regDtEd = dateToTimestamp(snmpRulesetFilter.regDtEd);
  }

  return params;
};
