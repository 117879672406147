import { RESULT_CODE } from './../../components/settings/SettingConstEnum';
import { CommonCode } from './../../models/common-code/CommonCode';
import { ApiResponse } from 'apisauce';
import { template } from 'lodash';
import {
  IEquipment,
  IEquipmentFliter,
  IEquipmentSnapshot,
  IPaginationSnapshot,
  TEquipmentFliter,
} from '../../models';
import { Equipment } from '../../models/equipment/Equipment';
import { IResponseSnapshot } from '../../models/response/Response';
import { timestampToDate } from '../../utils/dateExchanger';
import { Api } from '../api/api';
import { getGeneralApiProblem, TGeneralApiResponse } from '../api/api-problem';
import { IPairEquipmentSnapshot } from './../../models/pair-equipment/PairEquipment';
import { IPortSnapshot } from './../../models/port/Port';
import { dateToTimestamp } from './../../utils/dateExchanger';
import {
  TCheckDeleteEquipmentResult,
  TCheckHaTypeResult,
  TDeleteEquipmentResult,
  TDuplicateEquipmentName,
  TGetEquipmentResult,
  TGetEquipmentsResult,
  TGetRackToCodeResult,
  TPostEquipmentResult,
  TUpdateEquipmentResult, TUploadResult,
} from './EquipmentTypes';
import { IHaTypeCheckResult } from '../../models/equipment/HaTypeCheckResult';
import { IUploadResult } from '../../models/equipment/UploadResult';
import { TGetChannelsResult } from '../channel/ChannelTypes';
import { leastIndex } from 'd3';

export class EquipmentApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }


  /**
   * 장비 위치 정보
   * @use channel topology
   * @param equipSidList 
   * @returns 
   */
  async getEquipmentRacks(equipSidList: number[]): Promise<TGetEquipmentsResult> {
    try {
      let param: string = ``;
      equipSidList.forEach((item: number, index: number) => {
        param += `equipSidList=${item}`;
        if (index !== equipSidList.length - 1) {
          param += `&`;
        }
      });
      const SUB_RUL: string = `/equipment/rack?${param}`;
      const response: ApiResponse<any> = await this.api.apisauce.get(SUB_RUL);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;
      if (responseInfo.resultCode === 'S') {
        if (response.data.data) {
          response.data.data.forEach((item: IEquipmentSnapshot) => {
            item.regDt = timestampToDate(Number.parseInt(item.regDt ?? ''));
            item.updDt = timestampToDate(Number.parseInt(item.updDt ?? ''));
          });
          return { kind: `ok`, responseInfo: responseInfo, equipments: response.data.data };
        }
      }
      return { kind: `ok`, responseInfo: responseInfo, equipments: [] };
    } catch (e) {
      return { kind: `bad-data` };
    }
  }

  /**
   * 전체 Rack정보를 코드형태로 조회한다.
   * @returns
   */
  async getRackToCode(): Promise<TGetRackToCodeResult> {
    try {
      const url = '/equipment/rack/code';

      const response: ApiResponse<any> = await this.api.apisauce.get(url);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;

      return {
        kind: 'ok',
        codes: response.data.data,
        responseInfo: responseInfo,
      };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  /**
   * INF_CAIMS_00130
   * 전체 장비 목록을 조회한다.
   * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=610397168
   * @param pagination
   * @param equipmentFilter
   * @returns
   */
  async gets(
    pagination: IPaginationSnapshot,
    equipmentFilter?: TEquipmentFliter,
  ): Promise<TGetEquipmentsResult> {
    try {
      const url = '/equipment';
      let params: any = {};
      let selectedEquipSidList: string = ``;

      if (equipmentFilter) {
        params.equipSid = equipmentFilter.equipSid ? equipmentFilter.equipSid : null;
        params.hostNm = equipmentFilter.hostNm;
        params.equipType = equipmentFilter.equipType;
        params.equipRole = equipmentFilter.equipRole;
        params.trtResol = equipmentFilter.trtResol;
        params.repEquipSid = equipmentFilter.repEquipSid;
        params.repYn = equipmentFilter.repYn;
        params.haType = equipmentFilter.haType;
        params.equipLo = equipmentFilter.equipLo;
        params.equipMngr = equipmentFilter.equipMngr;
      }

      params.size = pagination.size;
      params.page = pagination.page;

      if (equipmentFilter && equipmentFilter.selectedEquipSidList) {
        equipmentFilter.selectedEquipSidList.forEach((item: number, index: number) => {
          selectedEquipSidList += `selectedEquipSidList=${item}`;
          if (
            equipmentFilter.selectedEquipSidList &&
            index !== equipmentFilter.selectedEquipSidList.length - 1
          ) {
            selectedEquipSidList += `&`;
          }
        });
      }

      const SUB_URL = `/equipment?${selectedEquipSidList}`;

      const response: ApiResponse<any> = await this.api.apisauce.get(SUB_URL, params);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;
      if (responseInfo.resultCode === 'S') {
        if (response.data.data.length > 0) {
          response.data.data.forEach((item: IEquipmentSnapshot) => {
            item.regDt = timestampToDate(Number.parseInt(item.regDt ?? ''));
            item.updDt = timestampToDate(Number.parseInt(item.updDt ?? ''));
            if (item.buyDate) {
              item.buyDate = timestampToDate(Number.parseInt(item.buyDate));
            }
            if (item.installDate) {
              item.installDate = timestampToDate(Number.parseInt(item.installDate));
            }
          });
          const equipments: IEquipmentSnapshot[] = response.data.data;
          const pagination: IPaginationSnapshot = response.data.pagination;
          return {
            kind: `ok`,
            responseInfo: responseInfo,
            equipments: equipments,
            pagination: pagination,
          };
        } else {
          return {
            kind: `ok`,
            responseInfo: responseInfo,
            equipments: [],
            pagination: response.data.pagination,
          };
        }
      }
      return { kind: `ok`, responseInfo: responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  /**
   * INF_CAIMS_00246 getsChLineup
   * 전체 장비 목록을 조회한다.
   * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=979341252
   * @param pagination
   * @param equipmentFilter
   * @returns
   */
  async getsChLineup(
    pagination: IPaginationSnapshot,
    equipmentFilter?: TEquipmentFliter,
  ): Promise<TGetEquipmentsResult> {
    try {
      const url = '/equipment';
      let params: any = {};
      let selectedEquipSidList: string = ``;

      if (equipmentFilter) {
        params.equipSid = equipmentFilter.equipSid ? equipmentFilter.equipSid : null;
        params.hostNm = equipmentFilter.hostNm;
        params.equipType = equipmentFilter.equipType;
        params.equipRole = equipmentFilter.equipRole;
        params.trtResol = equipmentFilter.trtResol;
        params.repEquipSid = equipmentFilter.repEquipSid;
        params.repYn = equipmentFilter.repYn;
        params.equipLo = equipmentFilter.equipLo;
        params.equipMngr = equipmentFilter.equipMngr;
      }

      params.size = pagination.size;
      params.page = pagination.page;

      if (equipmentFilter && equipmentFilter.selectedEquipSidList) {
        equipmentFilter.selectedEquipSidList.forEach((item: number, index: number) => {
          selectedEquipSidList += `selectedEquipSidList=${item}`;
          if (
            equipmentFilter.selectedEquipSidList &&
            index !== equipmentFilter.selectedEquipSidList.length - 1
          ) {
            selectedEquipSidList += `&`;
          }
        });
      }

      const SUB_URL = `/equipment/chlineup?${selectedEquipSidList}`;

      const response: ApiResponse<any> = await this.api.apisauce.get(SUB_URL, params);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;
      if (responseInfo.resultCode === 'S') {
        if (response.data.data.length > 0) {
          response.data.data.forEach((item: IEquipmentSnapshot) => {
            item.regDt = timestampToDate(Number.parseInt(item.regDt ?? ''));
            item.updDt = timestampToDate(Number.parseInt(item.updDt ?? ''));
            if (item.buyDate) {
              item.buyDate = timestampToDate(Number.parseInt(item.buyDate));
            }
            if (item.installDate) {
              item.installDate = timestampToDate(Number.parseInt(item.installDate));
            }
          });
          const equipments: IEquipmentSnapshot[] = response.data.data;
          const pagination: IPaginationSnapshot = response.data.pagination;
          return {
            kind: `ok`,
            responseInfo: responseInfo,
            equipments: equipments,
            pagination: pagination,
          };
        } else {
          return {
            kind: `ok`,
            responseInfo: responseInfo,
            equipments: [],
            pagination: response.data.pagination,
          };
        }
      }
      return { kind: `ok`, responseInfo: responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  /**
   * INF_CAIMS_00131
   * 해당 장비의 상세정보를 조회한다.
   * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=2109751758
   * @param equipSid
   * @returns
   */
  async get(equipSid: number): Promise<TGetEquipmentResult> {
    try {
      const url = '/equipment/' + equipSid;
      const response: ApiResponse<any> = await this.api.apisauce.get(url);
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;

      if (responseInfo.resultCode === 'S') {
        if (response.data.data) {
          response.data.data.regDt = timestampToDate(Number.parseInt(response.data.data.regDt));
          response.data.data.updDt = timestampToDate(Number.parseInt(response.data.data.updDt));
          if (response.data.data.buyDate) {
            response.data.data.buyDate = timestampToDate(
              Number.parseInt(response.data.data.buyDate),
            );
          }
          if (response.data.data.installDate) {
            response.data.data.installDate = timestampToDate(
              Number.parseInt(response.data.data.installDate),
            );
          }
          if (response.data.data.portList) {
            response.data.data.portList.forEach((item: any) => {
              item.regDt = timestampToDate(Number.parseInt(item.regDt));
              item.updDt = timestampToDate(Number.parseInt(item.updDt));
            });
            if (response.data.data.memEquipList) {
              response.data.data.memEquipList.forEach((item: any) => {
                item.regDt = timestampToDate(Number.parseInt(item.regDt));
                item.updDt = timestampToDate(Number.parseInt(item.updDt));
                if (item.buyDate) {
                  item.buyDate = timestampToDate(Number.parseInt(item.buyDate));
                }
                if (item.installDate) {
                  item.installDate = timestampToDate(Number.parseInt(item.installDate));
                }
                if (item.portList) {
                  item.portList.forEach((item2: any) => {
                    item2.regDt = timestampToDate(Number.parseInt(item2.regDt));
                    item2.updDt = timestampToDate(Number.parseInt(item2.updDt));
                  });
                }
              });
            }
          }
        }
        const equipment: IEquipmentSnapshot = response.data.data;
        return { kind: `ok`, responseInfo: responseInfo, equipment: equipment };
      }

      return { kind: 'ok', responseInfo: responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  /**
   * INF_CAIMS_00132
   * 새로운 장비를 등록한다.
   * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=1159795797
   * @param equipment
   * @returns
   */
  async post(equipment: IEquipmentSnapshot): Promise<TPostEquipmentResult> {
    try {
      const url = '/equipment';
      const params = createSendData(equipment);
      const response: ApiResponse<any> = await this.api.apisauce.post(url, params);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;

      if (responseInfo.resultCode === 'S') {
        if (response.data.data) {
          response.data.data.regDt = timestampToDate(Number.parseInt(response.data.data.regDt));
          response.data.data.updDt = timestampToDate(Number.parseInt(response.data.data.updDt));
          if (response.data.data.buyDate) {
            response.data.data.buyDate = timestampToDate(
              Number.parseInt(response.data.data.buyDate),
            );
          }
          if (response.data.data.installDate) {
            response.data.data.installDate = timestampToDate(
              Number.parseInt(response.data.data.installDate),
            );
          }
          if (response.data.data.portList) {
            response.data.data.portList.forEach((item: any) => {
              item.regDt = timestampToDate(Number.parseInt(item.regDt));
              item.updDt = timestampToDate(Number.parseInt(item.updDt));
            });
            if (response.data.data.memEquipList) {
              response.data.data.memEquipList.forEach((item: any) => {
                item.regDt = timestampToDate(Number.parseInt(item.regDt));
                item.updDt = timestampToDate(Number.parseInt(item.updDt));
                if (item.buyDate) {
                  item.buyDate = timestampToDate(Number.parseInt(item.buyDate));
                }
                if (item.installDate) {
                  item.installDate = timestampToDate(Number.parseInt(item.installDate));
                }
                if (item.portList) {
                  item.portList.forEach((item2: any) => {
                    item2.regDt = timestampToDate(Number.parseInt(item2.regDt));
                    item2.updDt = timestampToDate(Number.parseInt(item2.updDt));
                  });
                }
              });
            }
          }
          const equipment: IEquipmentSnapshot = response.data.data;
          return { kind: `ok`, responseInfo: responseInfo, equipment: equipment };
        }
      }

      return { kind: 'ok', responseInfo: responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  /**
   * INF_CAIMS_00133
   * 해당 장비의 정보를 수정한다.
   * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=1787617019
   * @param equipment
   * @returns
   */
  async put(equipment: IEquipment): Promise<TUpdateEquipmentResult> {
    try {
      const url = '/equipment/' + equipment.equipSid;
      const params = createSendData(equipment);
      const response: ApiResponse<any> = await this.api.apisauce.put(url, params);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;
      if (responseInfo.resultCode === 'S') {
        if (response.data.data) {
          response.data.data.regDt = timestampToDate(Number.parseInt(response.data.data.regDt));
          response.data.data.updDt = timestampToDate(Number.parseInt(response.data.data.updDt));
          if (response.data.data.buyDate) {
            response.data.data.buyDate = timestampToDate(
              Number.parseInt(response.data.data.buyDate),
            );
          }
          if (response.data.data.installDate) {
            response.data.data.installDate = timestampToDate(
              Number.parseInt(response.data.data.installDate),
            );
          }
          if (response.data.data.portList) {
            response.data.data.portList.forEach((item: any) => {
              item.regDt = timestampToDate(Number.parseInt(item.regDt));
              item.updDt = timestampToDate(Number.parseInt(item.updDt));
            });
            if (response.data.data.memEquipList) {
              response.data.data.memEquipList.forEach((item: any) => {
                item.regDt = timestampToDate(Number.parseInt(item.regDt));
                item.updDt = timestampToDate(Number.parseInt(item.updDt));
                if (item.buyDate) {
                  item.buyDate = timestampToDate(Number.parseInt(item.buyDate));
                }
                if (item.installDate) {
                  item.installDate = timestampToDate(Number.parseInt(item.installDate));
                }
                if (item.portList) {
                  item.portList.forEach((item2: any) => {
                    item2.regDt = timestampToDate(Number.parseInt(item2.regDt));
                    item2.updDt = timestampToDate(Number.parseInt(item2.updDt));
                  });
                }
              });
            }
          }
          const equipment: IEquipmentSnapshot = response.data.data;
          return { kind: `ok`, responseInfo: responseInfo, equipment: equipment };
        }
      }

      return { kind: 'ok', responseInfo: responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  /**
   * INF_CAIMS_00134
   * 해당 장비를 삭제한다.
   * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=1995563321
   * @param equipSid
   * @returns
   */
  async delete(equipSid: number): Promise<TDeleteEquipmentResult> {
    try {
      const url = '/equipment/' + equipSid;
      const response: ApiResponse<any> = await this.api.apisauce.delete(url);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;

      return {
        kind: 'ok',
        responseInfo: responseInfo,
      };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  /**
   * INF_CAIMS_00135
   * 선택한 장비목록을 삭제한다.
   * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=1650217314
   * @param deleteEquipmentList
   * @returns
   */
  async deletes(deleteEquipmentList: number[]): Promise<TDeleteEquipmentResult> {
    try {
      let param = '';
      deleteEquipmentList.forEach((item: number, index: number) => {
        param += 'equipSidList=' + item;
        if (index !== deleteEquipmentList.length - 1) {
          param += '&';
        }
      });

      const url = '/equipment?' + param;
      const response: ApiResponse<any> = await this.api.apisauce.delete(url);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;

      return {
        kind: 'ok',
        responseInfo: responseInfo,
      };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  /**
   * INF_CAIMS_00240
   * 특정 장비 호스트 이름이 데이터베이스에 이미 존재하는지 확인한다.
   * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=1765331369
   * @param hostNm
   * @returns
   */
  async checkDuplicatedEquipmentName(hostNm: string): Promise<TDuplicateEquipmentName> {
    try {
      const SUB_URL: string = `/equipment/nameCheck?hostNm=${hostNm}`;
      const response: ApiResponse<any> = await this.api.apisauce.get(SUB_URL);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;
      if (responseInfo.resultCode === RESULT_CODE.SUCCESS) {
        return {
          kind: 'ok',
          result: response.data.data.result,
          responseInfo: responseInfo,
        };
      }

      return {
        kind: 'ok',
        responseInfo: responseInfo,
      };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  /**
   * INF_CAIMS_00245
   * 특정 장비를 삭제 할 수 있는지 확인한다.
   * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=1805737500
   * @param equipSidList
   * @returns
   */
  async checkDeleteEquipment(equipSidList: number[]): Promise<TCheckDeleteEquipmentResult> {
    try {
      let param: string = ``;
      equipSidList.forEach((item: number, index: number) => {
        param += `equipSidList=${item}`;
        if (index !== equipSidList.length - 1) {
          param += `&`;
        }
      });
      const SUB_RUL: string = `/equipment/deleteCheck?${param}`;
      const response: ApiResponse<any> = await this.api.apisauce.get(SUB_RUL);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;
      if (responseInfo.resultCode === 'S') {
        if (response.data.data) {
          const checkResult: string[] = response.data.data;
          return { kind: `ok`, responseInfo: responseInfo, checkResult: checkResult };
        }
      }
      return { kind: `ok`, responseInfo: responseInfo };
    } catch (e) {
      return { kind: `bad-data` };
    }
  }

  async checkHaType(equipment: IEquipmentSnapshot): Promise<TCheckHaTypeResult> {
    try {
      const url = '/equipment/haTypeCheck';
      const params = createSendData(equipment);
      const response: ApiResponse<any> = await this.api.apisauce.post(url, params);
      const responseInfo: IResponseSnapshot = response.data;

      const checkHaResult: IHaTypeCheckResult = response.data.data;
      if (responseInfo.resultCode === 'S') {
        return { kind: `ok`, responseInfo: responseInfo, checkHaResult: checkHaResult };
      }
      return { kind: `ok`, responseInfo: responseInfo, checkHaResult: checkHaResult };
    } catch (e) {
      return { kind: `bad-data` };
    }
  }

  async downloadTemplate(): Promise<TDeleteEquipmentResult> {
    const url: string = '/equipment/download/template';
    return this.api.download(url, 'temp');
  }

  async downloadExcel(equipmentFilter: IEquipmentFliter): Promise<TDeleteEquipmentResult> 
  {
    let url: string = '/equipment/download?';

    if (equipmentFilter) {
      if(equipmentFilter.equipSid)
      {
        url = `${url}equipSid=${equipmentFilter.equipSid}&`;
      }
      if(equipmentFilter.hostNm)
      {
        url = `${url}hostNm=${equipmentFilter.hostNm}&`;
      }
      if(equipmentFilter.equipType)
      {
        url = `${url}equipType=${equipmentFilter.equipType}&`;
      }
    }
    return this.api.download(url, 'temp');
  }

  async upload(files: File[]): Promise<TUploadResult> {
    const url: string = '/equipment/excelUpload';
    const form = new FormData();
    files.forEach(value => form.append('files', value));
    await this.api.apisauce.setHeader('Content-Type', 'multipart/form-data');
    const response: ApiResponse<any> = await this.api.apisauce.post(url, form);
    await this.api.apisauce.setHeader('Content-Type', 'application/json');
    const responseInfo: IResponseSnapshot = response.data;
    if (responseInfo) {
      if (responseInfo.resultCode === 'S') {
        const uploadResult: IUploadResult = response.data.data;
        return { kind: 'ok', responseInfo: response.data, uploadResult: uploadResult };
      }
      else {
        throw response.data;
      }
    }
    return { kind: 'ok', responseInfo: response.data };

  }

  async getChannelListUsingEquipment(equipSid: string): Promise<TGetChannelsResult> {
    try {
      const url = `/equipment/${equipSid}/using/channel`;

      const response: ApiResponse<any> = await this.api.apisauce.get(url);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;

      return {
        kind: 'ok',
        channels: response.data.data,
        responseInfo: responseInfo,
      };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }
}

//createParams 에서 사용되는 ANY 타입은
//파라미터 변경으로 인한 ANY 로 사용 중
//정확한 규격이 Fix 되면 인터페이스로 변환할 것
// const createParams = (equiment: IEquipmentSnapshot) => {
//   let params: any = {};

//   if (equiment.hostNm !== undefined) {
//     params.hostNm = equiment.hostNm;
//   }
//   if (equiment.modelNm !== undefined) {
//     params.modelNm = equiment.modelNm;
//   }
//   if (equiment.equipType) {
//     params.equipType = equiment.equipType.code;
//   }
//   if (equiment.equipRole) {
//     params.equipRole = equiment.equipRole.code;
//   }
//   if (equiment.trtResol) {
//     params.trtResol = equiment.trtResol.code;
//   }
//   if (equiment.mnfcSid) {
//     params.mnfcSid = equiment.mnfcSid;
//   }
//   if (equiment.mgmtIp !== undefined) {
//     params.mgmtIp = equiment.mgmtIp;
//   }
//   if (equiment.vendorSid) {
//     params.vendorSid = equiment.vendorSid;
//   }
//   if (equiment.buyDate) {
//     params.buyDate = dateToTimestamp(equiment.buyDate);
//   }
//   if (equiment.installDate) {
//     params.installDate = dateToTimestamp(equiment.installDate);
//   }
//   if (equiment.frmwrVer !== undefined) {
//     params.frmwrVer = equiment.frmwrVer;
//   }
//   if (equiment.modulInclYn !== undefined) {
//     params.modulInclYn = equiment.modulInclYn;
//   }
//   if (equiment.haType) {
//     params.haType = equiment.haType.code;
//   }
//   if (equiment.repYn !== undefined) {
//     params.repYn = equiment.repYn;
//   }
//   if (equiment.equipOprtSttus) {
//     params.equipOprtSttus = equiment.equipOprtSttus.code;
//   }
//   if (equiment.etnlEquipId !== undefined) {
//     params.etnlEquipId = equiment.etnlEquipId;
//   }
//   if (equiment.intrlkSysSid) {
//     params.intrlkSysSid = equiment.intrlkSysSid;
//   }
//   if (equiment.intrlkSysSrvrSid) {
//     params.intrlkSysSrvrSid = equiment.intrlkSysSrvrSid;
//   }
//   if (equiment.portList) {
//     params.portList = [];
//     equiment.portList.map((item, index) => {
//       const port: any = {};
//       if (item.portSid) {
//         port.portSid = item.portSid;
//       }
//       if (item.portNm !== undefined) {
//         port.portNm = item.portNm;
//       }
//       if (item.portIp !== undefined) {
//         port.portIp = item.portIp;
//       }
//       if (item.idx) {
//         port.idx = item.idx;
//       }
//       if (item.portRole) {
//         port.portRole = item.portRole.code;
//       }
//       if (item.portSttus) {
//         port.portSttus = item.portSttus.code;
//       }
//       if (item.portType) {
//         port.portType = item.portType.code;
//       }
//       if (item.portDescr !== undefined) {
//         port.portDescr = item.portDescr;
//       }
//       params.portList.push(port);
//     });
//   }
//   if (equiment.repEquipSid) {
//     params.repEquipSid = equiment.repEquipSid;
//   }

//   if (equiment.memEquipList) {
//     params.memEquipList = [];
//     equiment.memEquipList.map((item: IPairEquipmentSnapshot, index) => {
//       const pairEquip: any = {};
//       if (item.equipSid) {
//         pairEquip.equipSid = item.equipSid;
//       }
//       if (item.hostNm !== undefined) {
//         pairEquip.hostNm = item.hostNm;
//       }
//       if (item.modelNm !== undefined) {
//         pairEquip.modelNm = item.modelNm;
//       }
//       if (item.equipType) {
//         pairEquip.equipType = item.equipType.code;
//       }
//       if (item.equipRole) {
//         pairEquip.equipRole = item.equipRole.code;
//       }
//       if (item.trtResol) {
//         pairEquip.trtResol = item.trtResol.code;
//       }
//       if (item.mnfcSid) {
//         pairEquip.mnfcSid = item.mnfcSid;
//       }
//       if (item.vendorSid) {
//         pairEquip.vendorSid = item.vendorSid;
//       }
//       if (item.buyDate) {
//         pairEquip.buyDate = dateToTimestamp(item.buyDate);
//       }
//       if (item.installDate) {
//         pairEquip.installDate = dateToTimestamp(item.installDate);
//       }
//       if (item.mgmtIp !== undefined) {
//         pairEquip.mgmtIp = item.mgmtIp;
//       }
//       if (item.frmwrVer !== undefined) {
//         pairEquip.frmwrVer = item.frmwrVer;
//       }
//       if (item.modulInclYn !== undefined) {
//         pairEquip.modulInclYn = item.modulInclYn;
//       }
//       if (item.haType) {
//         pairEquip.haType = item.haType.code;
//       }
//       if (item.repYn !== undefined) {
//         pairEquip.repYn = item.repYn;
//       }
//       if (item.equipOprtSttus) {
//         pairEquip.equipOprtSttus = item.equipOprtSttus.code;
//       }

//       if (item.helthySttus) {
//         pairEquip.helthySttus = item.helthySttus.code;
//       }

//       if (item.etnlEquipId !== undefined) {
//         pairEquip.etnlEquipId = item.etnlEquipId;
//       }
//       if (item.intrlkSysSid) {
//         pairEquip.intrlkSysSid = item.intrlkSysSid;
//       }
//       if (item.intrlkSysSrvrSid) {
//         pairEquip.intrlkSysSrvrSid = item.intrlkSysSrvrSid;
//       }
//       if (item.portList) {
//         pairEquip.portList = [];
//         item.portList.map((item: IPortSnapshot, index) => {
//           const port: any = {};
//           if (item.portSid) {
//             port.portSid = item.portSid;
//           }
//           if (item.portNm !== undefined) {
//             port.portNm = item.portNm;
//           }
//           if (item.portIp !== undefined) {
//             port.portIp = item.portIp;
//           }
//           if (item.idx) {
//             port.idx = item.idx;
//           }
//           if (item.portRole) {
//             port.portRole = item.portRole.code;
//           }
//           if (item.portSttus) {
//             port.portSttus = item.portSttus.code;
//           }
//           if (item.portType) {
//             port.portType = item.portType.code;
//           }
//           if (item.portDescr !== undefined) {
//             port.portDescr = item.portDescr;
//           }
//           pairEquip.portList.push(port);
//         });
//       }
//       params.memEquipList.push(pairEquip);
//     });
//   }

//   return params;
// };

const createSendData = (equipment: IEquipmentSnapshot) => {
  let result: any = {};
  if (equipment.equipSid !== undefined) {
    result.equipSid = equipment.equipSid;
  }
  if (equipment.hostNm !== undefined) {
    result.hostNm = equipment.hostNm;
  }
  if (equipment.modelNm !== undefined) {
    result.modelNm = equipment.modelNm;
  }

  if (equipment.equipType) {
    result.equipType = equipment.equipType.code;
  }
  if (equipment.equipCtg) {
    result.equipCtg = equipment.equipCtg.code;
  }
  if (equipment.equipRole) {
    result.equipRole = equipment.equipRole.code;
  }
  if (equipment.equipMngr) {
    result.equipMngr = equipment.equipMngr;
  }
  if (equipment.equipLo) {
    result.equipLo = equipment.equipLo;
  }

  if (equipment.trtResol) {
    result.trtResol = equipment.trtResol.code;
  }
  if (equipment.mnfcSid) {
    result.mnfcSid = equipment.mnfcSid;
  }
  if (equipment.vendorSid) {
    result.vendorSid = equipment.vendorSid;
  }

  if (equipment.rack) {
    result.rackSid = equipment.rack.code;
  }

  if (equipment.slotNo) {
    result.slotNo = equipment.slotNo;
  }

  if (equipment.buyDate) {
    result.buyDate = dateToTimestamp(equipment.buyDate);
  }
  if (equipment.installDate) {
    result.installDate = dateToTimestamp(equipment.installDate);
  }

  if (equipment.mgmtIp !== undefined) {
    result.mgmtIp = equipment.mgmtIp;
  }
  if (equipment.frmwrVer !== undefined) {
    result.frmwrVer = equipment.frmwrVer;
  }
  // if (equipment.modulInclYn !== undefined) {
  //   result.modulInclYn = equipment.modulInclYn;
  // }

  if (equipment.haType) {
    result.haType = equipment.haType.code;
  }
  if (equipment.haSttus) {
    result.haSttus = equipment.haSttus.code;
  }
  if (equipment.repYn !== undefined) {
    result.repYn = equipment.repYn;
  }
  if (equipment.equipOprtSttus) {
    result.equipOprtSttus = equipment.equipOprtSttus.code;
  }
  if (equipment.etnlEquipId !== undefined) {
    result.etnlEquipId = equipment.etnlEquipId;
  }
  if (equipment.intrlkSysSid) {
    result.intrlkSysSid = equipment.intrlkSysSid;
  }
  if (equipment.intrlkSysSrvrSid) {
    result.intrlkSysSrvrSid = equipment.intrlkSysSrvrSid;
  }
  if (equipment.repEquipSid) {
    result.repEquipSid = equipment.repEquipSid;
  }
  if (equipment.portList) {
    result.portList = [];
    let portList = equipment.portList;
    portList.forEach((item: IPortSnapshot) => {
      let port: any = {};
      if (item.portSid !== undefined) {
        port.portSid = item.portSid;
      }
      if (item.portNm !== undefined) {
        port.portNm = item.portNm;
      }
      if (item.portIp !== undefined) {
        port.portIp = item.portIp;
      }
      if (item.idx) {
        port.idx = item.idx;
      }
      if (item.portType) {
        port.portType = item.portType.code;
      }
      if (item.portRole) {
        port.portRole = item.portRole.code;
      }
      if (item.portSttus) {
        port.portSttus = item.portSttus.code;
      }
      if (item.portDescr !== undefined) {
        port.portDescr = item.portDescr;
      }
      if(item.dstPortSid !== undefined){
        port.dstPortSid= item.dstPortSid
      }
      if(item.dstEquipSid !== undefined){
        port.dstEquipSid= item.dstEquipSid
      }
      result.portList.push(port);
    });
  }

  if (equipment.memEquipList) {
    result.memEquipList = [];
    const memEquipList = equipment.memEquipList;
    memEquipList.forEach((item: IPairEquipmentSnapshot) => {
      let pairEquiment: any = {};
      if (item.equipSid !== undefined) {
        pairEquiment.equipSid = item.equipSid;
      }
      if (item.hostNm !== undefined) {
        pairEquiment.hostNm = item.hostNm;
      }
      if (item.modelNm !== undefined) {
        pairEquiment.modelNm = item.modelNm;
      }
      if (item.equipType) {
        pairEquiment.equipType = item.equipType.code;
      }
      if (item.equipRole) {
        pairEquiment.equipRole = item.equipRole.code;
      }
      if (item.trtResol) {
        pairEquiment.trtResol = item.trtResol.code;
      }
      if (item.mnfcSid) {
        pairEquiment.mnfcSid = item.mnfcSid;
      }


      if (item.rack) {
        pairEquiment.rackSid = item.rack.code;
      }

      if (equipment.slotNo) {
        result.slotNo = equipment.slotNo;
      }
      if (item.vendorSid) {
        pairEquiment.vendorSid = item.vendorSid;
      }
      if (item.buyDate) {
        pairEquiment.buyDate = dateToTimestamp(item.buyDate);
      }
      if (item.installDate) {
        pairEquiment.installDate = dateToTimestamp(item.installDate);
      }

      if (item.mgmtIp !== undefined) {
        pairEquiment.mgmtIp = item.mgmtIp;
      }
      if (item.frmwrVer !== undefined) {
        pairEquiment.frmwrVer = item.frmwrVer;
      }
      // if (item.modulInclYn !== undefined) {
      //   pairEquiment.modulInclYn = item.modulInclYn;
      // }
      if (item.haType) {
        pairEquiment.haType = item.haType.code;
      }
      if (item.haSttus) {
        pairEquiment.haSttus = item.haSttus.code;
      }
      if (item.repYn !== undefined) {
        pairEquiment.repYn = item.repYn;
      }
      if (item.equipOprtSttus) {
        pairEquiment.equipOprtSttus = item.equipOprtSttus.code;
      }

      if (item.etnlEquipId !== undefined) {
        pairEquiment.etnlEquipId = item.etnlEquipId;
      }
      if (item.intrlkSysSid) {
        pairEquiment.intrlkSysSid = item.intrlkSysSid;
      }
      if (item.intrlkSysSrvrSid) {
        pairEquiment.intrlkSysSrvrSid = item.intrlkSysSrvrSid;
      }
      if (item.repEquipSid) {
        pairEquiment.repEquipSid = item.repEquipSid;
      }
      if (item.repEquipNm) {
        pairEquiment.repEquipNm = item.repEquipNm;
      }

      if (item.portList) {
        pairEquiment.portList = [];
        item.portList.forEach((portItem: IPortSnapshot) => {
          let port: any = {};
          if (portItem.portSid !== undefined) {
            port.portSid = portItem.portSid;
          }
          if (portItem.portNm !== undefined) {
            port.portNm = portItem.portNm;
          }
          if (portItem.portIp !== undefined) {
            port.portIp = portItem.portIp;
          }
          if (portItem.idx) {
            port.idx = portItem.idx;
          }
          if (portItem.portType) {
            port.portType = portItem.portType.code;
          }
          if (portItem.portRole) {
            port.portRole = portItem.portRole.code;
          }
          if (portItem.portSttus) {
            port.portSttus = portItem.portSttus.code;
          }
          if (portItem.portDescr !== undefined) {
            port.portDescr = portItem.portDescr;
          }
          pairEquiment.portList.push(port);
        });
      }
      result.memEquipList.push(pairEquiment);
    });
  }
  return result;
};
