import { createResponse } from '../response/Response';
import { withRootStore } from './../extensions/with-root-store';
import { createPagination, IPagination, IPaginationSnapshot } from '../pagination/Pagination';
import { types, Instance, SnapshotOut } from 'mobx-state-tree';
import { StbSimulatorApi } from '../../services';
import {
  TGetStbSimulatorResult,
  TPostStbSimulatorResult,
} from '../../services/stb-simulator/StbSimulatorTypes';
import { withEnvironment } from '../extensions/with-environment';
import {
  createStbSimulator,
  IStbSimulator,
  IStbSimulatorSnapshot,
} from '../stb-simulator/StbSimulator';

/**
 * # StbSimulatorStore
 *
 * StbSimulatorStore을 설명하세요.
 */
export const StbSimulatorStore = types
  .model('StbSimulatorStore')
  .props({
    stbSimulators: types.optional(types.array(createStbSimulator()), []),
    deleteStbList: types.optional(types.array(types.string), []),
    pagination: createPagination(),
    responseInfo: createResponse(),
    checkDuplicatedSaid: types.optional(types.boolean, false),
  })
  .extend(withEnvironment)
  .views((self) => ({}))
  .extend(withRootStore)
  .actions((self) => ({
    /**
     * stbSimulators을 교체
     *
     * @param stbFilter
     */
    setStbSimulators: (stbSimulators: IStbSimulatorSnapshot[]) => {
      self.stbSimulators.replace(stbSimulators as IStbSimulator[]);
    },

    setCurrentStateStbSimulators: (stbSimulators: IStbSimulator[]) => {
      self.stbSimulators.replace(stbSimulators as IStbSimulator[]);
    },
  }))
  // --------------------------------------------------------------------------
  // REQUESTs - 서비스 요청 및 기타 인터페이스 요청
  .actions((self) => ({
    
    /**
     * 
     * 현재 노출된 채널명을 조회
     * 조회한 결과로 channelName을 교체한다.
     * 
     * @param saidList string[]
     */
    getCurrentState: async (saidList: string[]) => {
      try {
        const stbSimulatorApi: StbSimulatorApi = new StbSimulatorApi(self.environment.api);
        let result: TGetStbSimulatorResult;
        result = await stbSimulatorApi.getCurrentState(saidList);

        if (self.rootStore.responseStore.getResponseResult(result)) {
          if (result.stbSimulators) {
            self.setStbSimulators(result.stbSimulators);
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },
     /**
     * 
     * saidList와 리모컨 keyCd를 이용해 해당 stb에 정보를 교체한다.
     * 
     * @param params any 
     */
    getRemoteSignal: async (params: any) => {
      try {
        const stbSimulatorApi: StbSimulatorApi = new StbSimulatorApi(self.environment.api);
        let result: TPostStbSimulatorResult = await stbSimulatorApi.getRemoteSignal(params);

        if (self.rootStore.responseStore.getResponseResult(result)) {
          if (result.stbSimulators) {
            self.setStbSimulators(result.stbSimulators);
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },
  }));

// --------------------------------------------------------------------------
type TStbSimulatorStore = Instance<typeof StbSimulatorStore>;
type TStbSimulatorStoreSnapshot = SnapshotOut<typeof StbSimulatorStore>;

export interface IStbSimulatorStore extends TStbSimulatorStore {}
export type TStbSimulatorStoreKeys = keyof TStbSimulatorStoreSnapshot & string;
export interface IStbSimulatorStoreSnapshot extends TStbSimulatorStoreSnapshot {}
export const createStbSimulatorStore = () => types.optional(StbSimulatorStore, {} as TStbSimulatorStore);
export interface IStbSimulatorFilter {
  said?: string;
  stbMo?: string;
  helthySttus?: { code: 0; value: ''; pcode: 0 };
  ctg?: { code: 0; value: ''; pcode: 0 };
  lo?: { code: 0; value: ''; pcode: 0 };
}

export type TStbSimulatorFilter = IStbSimulatorFilter;