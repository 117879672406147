import { ApiResponse } from 'apisauce';
import { CC } from '../../commonCodes/commonCodes';
import { selectOptions } from '../../components/forms/CSelect';
import {
  IResponseSnapshot,
  IStbStatisticsSnapshot,
  TStbStatisticsFilter,
} from '../../models';
import { dateToTimestamp, timestampToDate } from '../../utils/dateExchanger';
import { Api } from '../api/api';
import { getGeneralApiProblem } from '../api/api-problem';
import { TGetVocStatisticsResultForOneview } from '../voc-statistics/VocStatisticsTypes';
import { TGetWatchStatisticsResultForOneview } from '../watch-statistics/WatchStatisticsTypes';
import {
  TGetStbStatisticsPacketLossResult,
  TGetStbStatisticsAlarmResult,
  TGetRegionResult,
  TGetTop7ModelResult,
  TGetStbStatisticsOneviewResult,
} from './StbStatisticsTypes';

/**
 * # StbStatistics Api 서비스
 *
 * 서비스의 설명을 작성하세요.
 *
 * ## 사용방법
 *
 * ```ts
 * const service = new StbStatisticsApi();
 *
 * ```
 */
export class StbStatisticsApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  /**
   * INF_CAIMS_00300
   * UCEMS 패킷로스 통계 데이터를 제공한다.
   * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=1207726427
   * @param stbStatisticsFilter
   * @returns
   */
  async packetLossGets(
    stbStatisticsFilter?: TStbStatisticsFilter,
  ): Promise<TGetStbStatisticsPacketLossResult> {
    try {
      let regionList: string = ``;
      let params: any = {};
      let statType: number = 0;
      if (stbStatisticsFilter) {
        statType = stbStatisticsFilter.statType;

        //@ts-ignore
        delete stbStatisticsFilter.statType;

        params = stbStatisticsFilter;

        if (stbStatisticsFilter.regionList) {
          stbStatisticsFilter.regionList.forEach((item: selectOptions, index: number) => {
            regionList += `regionList=${item.code}`;
            if (index + 1 !== stbStatisticsFilter.regionList?.length) {
              regionList += `&`;
            }
          });
        }
      }
      if (params) {
        params.statDtSt = dateToTimestamp(params.statDtSt);
        params.statDtEd = dateToTimestamp(params.statDtEd);
      }

      let url = `/stat/stb/packetloss/${statType}?${regionList}`;
      stbStatisticsFilter?.useOneview && (url = `/oneview/stb/rtploss`);

      //@ts-ignore
      delete params.regionList;

      const response: ApiResponse<any> = await this.api.apisauce.get(url, params);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      if (response.data.data) {
        response.data.data.forEach((item: IStbStatisticsSnapshot, index: number) => {
          item.statDt = timestampToDate(Number.parseInt(item.statDt));
          item.preStatDt = item.preStatDt ? timestampToDate(Number.parseInt(item.preStatDt)) : '';
        });
      }

      const responseInfo: IResponseSnapshot = response.data;

      return {
        kind: 'ok',
        packetloss: response.data.data,
        responseInfo: responseInfo,
      };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  /**
   * get map data for oneview
   * @param stbStatisticsFilter
   * @returns
   */
  async getMapData(
    stbStatisticsFilter?: TStbStatisticsFilter,
  ): Promise<TGetVocStatisticsResultForOneview> {
    try {
      let regionList: string = ``;
      let params: any = {};
      let statType: number = 0;

      if (stbStatisticsFilter) {
        statType = stbStatisticsFilter.statType;

        //@ts-ignore
        delete stbStatisticsFilter.statType;

        params = stbStatisticsFilter;

      }

      let url = `/stat/stb/alarm/${statType}?${regionList}`;
      stbStatisticsFilter?.useOneview && (url = `/oneview/stb/alarm/${statType || CC.STAT_TYPE.Model}`);

      if (params) {
        params.statDtSt = dateToTimestamp(params.statDtSt);
        params.statDtEd = dateToTimestamp(params.statDtEd);
      }

      //@ts-ignore
      delete params.regionList;

      const response: ApiResponse<any> = await this.api.apisauce.get(url, params);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;

      return {
        kind: 'ok',
        data: response.data.data,
        responseInfo: responseInfo,
      };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  /**
   * INF_CAIMS_00301
   * UCEMS 장애 알람 통계 데이터를 제공한다.
   * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=1089610836
   * @param stbStatisticsFilter
   * @returns
   */
  async alarmGets(
    stbStatisticsFilter?: TStbStatisticsFilter,
  ): Promise<TGetStbStatisticsAlarmResult> {
    try {
      let regionList: string = ``;
      let params: any = {};
      let statType: number = 0;
      let statUnit: number = 0;

      if (stbStatisticsFilter) {
        statType = stbStatisticsFilter.statType;
        if(stbStatisticsFilter?.statUnit)
        {
          statUnit = stbStatisticsFilter?.statUnit;
        }

        //@ts-ignore
        delete stbStatisticsFilter.statType;
        delete stbStatisticsFilter.statUnit;

        params = stbStatisticsFilter;

        if (stbStatisticsFilter.regionList) {
          stbStatisticsFilter.regionList.forEach((item: selectOptions, index: number) => {
            regionList += `regionList=${item.code}`;
            if (index + 1 !== stbStatisticsFilter.regionList?.length) {
              regionList += `&`;
            }
          });
        }
      }

      let url = `/stat/stb/alarm/type/${statType}/unit/${statUnit}?${regionList}`;
      stbStatisticsFilter?.useOneview && (url = `/oneview/stb/alarm/${statType || CC.STAT_TYPE.Model}`);

      if (params) {
        params.statDtSt = dateToTimestamp(params.statDtSt);
        params.statDtEd = dateToTimestamp(params.statDtEd);
      }

      //@ts-ignore
      delete params.regionList;

      const response: ApiResponse<any> = await this.api.apisauce.get(url, params);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      if (response.data.data) {
        response.data.data.forEach((item: IStbStatisticsSnapshot, index: number) => {
          item.statDt = timestampToDate(Number.parseInt(item.statDt));
          item.preStatDt = item.preStatDt ? timestampToDate(Number.parseInt(item.preStatDt)) : '';
        });
      }

      const responseInfo: IResponseSnapshot = response.data;

      return {
        kind: 'ok',
        alarm: response.data.data,
        responseInfo: responseInfo,
      };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async stbMapGets(
    stbStatisticsFilter?: TStbStatisticsFilter,
  ): Promise<TGetWatchStatisticsResultForOneview> {
    try {
      let regionList: string = ``;
      let params: any = {};
      let statType: number = 0;

      if (stbStatisticsFilter) {
        statType = stbStatisticsFilter.statType;

        //@ts-ignore
        delete stbStatisticsFilter.statType;

        params = stbStatisticsFilter;

        if (stbStatisticsFilter.regionList) {
          stbStatisticsFilter.regionList.forEach((item: selectOptions, index: number) => {
            regionList += `regionList=${item.code}`;
            if (index + 1 !== stbStatisticsFilter.regionList?.length) {
              regionList += `&`;
            }
          });
        }
      }

      let url = `/stat/stb/alarm/${statType}?${regionList}`;
      stbStatisticsFilter?.useOneview && (url = `/oneview/stb/alarm/${statType || CC.STAT_TYPE.Model}`);

      if (params) {
        params.statDtSt = dateToTimestamp(params.statDtSt);
        params.statDtEd = dateToTimestamp(params.statDtEd);
      }

      //@ts-ignore
      delete params.regionList;

      const response: ApiResponse<any> = await this.api.apisauce.get(url, params);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      if (response.data.data) {
        response.data.data.forEach((item: IStbStatisticsSnapshot, index: number) => {
          item.statDt = timestampToDate(Number.parseInt(item.statDt));
          item.preStatDt = item.preStatDt ? timestampToDate(Number.parseInt(item.preStatDt)) : '';
        });
      }

      const responseInfo: IResponseSnapshot = response.data;

      return {
        kind: 'ok',
        data: response.data.data,
        responseInfo: responseInfo,
      };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  /**
   * @param stbStatisticsFilter
   * @returns
   */
  async modelGets(
    stbStatisticsFilter?: TStbStatisticsFilter,
  ): Promise<TGetStbStatisticsOneviewResult> {
    try {
      let regionList: string = ``;
      let params: any = {};
      let statType: number = 0;

      if (stbStatisticsFilter) {
        statType = stbStatisticsFilter.statType;

        //@ts-ignore
        delete stbStatisticsFilter.statType;

        params = stbStatisticsFilter;

        if (stbStatisticsFilter.regionList) {
          stbStatisticsFilter.regionList.forEach((item: selectOptions, index: number) => {
            regionList += `regionList=${item.code}`;
            if (index + 1 !== stbStatisticsFilter.regionList?.length) {
              regionList += `&`;
            }
          });
        }
      }

      let url = `/stat/stb/alarm/${statType}?${regionList}`;
      stbStatisticsFilter?.useOneview && (url = `/oneview/stb/alarm/${statType || CC.STAT_TYPE.Model}`);

      if (params) {
        params.statDtSt = dateToTimestamp(params.statDtSt);
        params.statDtEd = dateToTimestamp(params.statDtEd);
      }

      //@ts-ignore
      delete params.regionList;

      const response: ApiResponse<any> = await this.api.apisauce.get(url, params);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;

      return {
        kind: 'ok',
        data: response.data.data,
        responseInfo: responseInfo,
      };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  /**
   * INF_CAIMS_00301
   * ''광역시', '도'에 해당하는 지역코드 항목을 조회한다.
   * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=379400282
   * @returns
   */
  async regionGets(): Promise<TGetRegionResult> {
    try {
      const url = '/sido/code';

      const response: ApiResponse<any> = await this.api.apisauce.get(url);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;

      return {
        kind: 'ok',
        regions: response.data.data,
        responseInfo: responseInfo,
      };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }



  /**
   * @returns
   */
   async top7ModelGets(): Promise<TGetTop7ModelResult> {
    try {
      const url = '/stb/alarm/top/model';

      const response: ApiResponse<any> = await this.api.apisauce.get(url);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;

      return {
        kind: 'ok',
        models: response.data.data,
        responseInfo: responseInfo,
      };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }
}
