import { Instance, SnapshotOut, types } from "mobx-state-tree"
import { createSimpleUser } from "../simple-user/SimpleUser";
import { DbsInfo } from "./DbsIno";

/**
 * # ChannelDbs
 * 
 * ChannelDbs을 설명하세요.
 */
export const ChannelDbs = types
  .model("ChannelDbs")
  // --------------------------------------------------------------------------
  .props({
    chDbsSid: types.optional(types.number, 0),
    fileNm: types.optional(types.maybeNull(types.string), ''),
    wrkMemo: types.optional(types.maybeNull(types.string), ''),
    dbsInfoList: types.optional(types.maybeNull(types.array(DbsInfo)), []),

    regDt: types.optional(types.maybeNull(types.string), ''),
    regUser: types.maybeNull(createSimpleUser()),
    updDt: types.optional(types.maybeNull(types.string), ''),
    updUser: types.maybeNull(createSimpleUser())
  })
  // --------------------------------------------------------------------------
  // MUTATEs ONLY! - 모델 상태를 변경
  // REQUESTs는 스토어 모델에 작성하세요.
  // ex) yarn gen model channelDbs --store
  .actions((self) => ({
    /*
     * 여러 prop을 한번에 수정
     */
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as IChannelDbs;
      (Object.keys(newProps) as TChannelDbsKeys[]).forEach((key) => {
        //@ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type TChannelDbs = Instance<typeof ChannelDbs>;
type TChannelDbsSnapshot = SnapshotOut<typeof ChannelDbs>;

export interface IChannelDbs extends TChannelDbs {};
export type TChannelDbsKeys = keyof TChannelDbsSnapshot & string;
export interface IChannelDbsSnapshot extends TChannelDbsSnapshot {}
export const createChannelDbs = () => 
  types.optional(ChannelDbs, {
    chDbsSid: 0,
    fileNm: null,
    wrkMemo: null,
    dbsInfoList: null,
    regDt: null,
    regUser: null,
    updDt: null,
    updUser: null
  } as TChannelDbs);