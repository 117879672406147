import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { createModelCode, ModelCode } from '../model-code/ModelCode';
import { TEquipmentKeys } from './Equipment';

export const HaTypeCheckResult = types
  .model('HaTypeCheckResult')
  .props({
    success: types.optional(types.boolean, false),
    repHaType: types.maybeNull(createModelCode()),
    memHaType: types.maybeNull(createModelCode()),
  })
  .actions((self) => ({
    resetHaTypeCheckResult: () => {
      self.success = false;
      self.repHaType = ModelCode.create({
        code: 0,
        pcode: 0,
        value: '',
      });
      self.memHaType = ModelCode.create({
        code: 0,
        pcode: 0,
        value: '',
      });
    },
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as IHaTypeCheckResult;
      (Object.keys(newProps) as TEquipmentKeys[]).forEach((key) => {
        //@ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

type THaTypeCheckResult = Instance<typeof HaTypeCheckResult>;
type THaTypeCheckResultSnapshotType = SnapshotOut<typeof HaTypeCheckResult>;

export interface IHaTypeCheckResult extends THaTypeCheckResult {}
export interface IHaTypeCheckResultSnapshot extends THaTypeCheckResultSnapshotType {}
export const createHaTypeCheckResult = () =>
  types.optional(HaTypeCheckResult, {} as THaTypeCheckResult);
