import { motion, AnimatePresence } from 'framer-motion';
// material
import { Dialog, Box, Paper, DialogProps } from '@mui/material';
//
import { varFade } from './variants';
import { makeStyles } from '@mui/styles';

// ----------------------------------------------------------------------

interface DialogAnimateProps extends DialogProps {
  animate?: object;
  onClose?: VoidFunction;
  fixedTop?: boolean;
}

const useStyles = makeStyles({
  topScrollPaper: {
    alignItems: 'flex-start',
    marginTop: window.innerHeight - (window.innerHeight / 5) * 4,
  },
  topPaperScrollBody: {
    verticalAlign: 'top',
  },
});

export default function DialogAnimate({
  open = false,
  maxWidth = 'md',
  animate,
  onClose,
  children,
  fixedTop = false,
  ...other
}: DialogAnimateProps) {
  const classes = useStyles();
  return (
    <AnimatePresence>
      {open && (
        <Dialog
          classes={
            fixedTop
              ? {
                  scrollPaper: classes.topScrollPaper,
                  paperScrollBody: classes.topPaperScrollBody,
                }
              : {}
          }
          fullWidth
          maxWidth={maxWidth}
          open={open}
          onClose={onClose}
          PaperComponent={(props) => (
            <Box
              component={motion.div}
              {...(animate || varFade)}
              sx={{
                width: '100%',
                maxHeight: '100%',
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'center',
              }}
            >
              <Box onClick={onClose} sx={{ width: '100%', maxHeight: '100%', position: 'fixed' }} />
              <Paper {...props}>{props.children}</Paper>
            </Box>
          )}
          {...other}
        >
          {children}
        </Dialog>
      )}
    </AnimatePresence>
  );
}
