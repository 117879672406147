import { toJS } from 'mobx';
import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { ISnmpRuleset, SnmpRuleset } from '../../models/snmp-ruleset/SnmpRuleset';
import {
  SnmpRulesetApi,
  TDeleteSnmpRulesetResult,
  TGetSnmpRulesetResult,
  TGetSnmpRulesetsResult,
  TMibUploadResult,
  TUpdateSnmpRulesetResult,
} from '../../services';
import { timestampToDate } from '../../utils/dateExchanger';
import { withEnvironment } from '../extensions/with-environment';
import { ModelCode } from '../model-code/ModelCode';
import { createSnmpRuleset, ISnmpRulesetSnapshot } from '../snmp-ruleset/SnmpRuleset';
import { ITrap } from '../trap/Trap';
import { withRootStore } from './../extensions/with-root-store';
import { createPagination, IPagination, IPaginationSnapshot } from './../pagination/Pagination';

export const SnmpRulesetStore = types
  .model('SnmpRulesetStore')
  .props({
    snmpRulesets: types.optional(types.array(SnmpRuleset), []),
    selectedSnmpRuleset: types.maybeNull(createSnmpRuleset()),
    pagination: createPagination(),
    deleteSnmnpRulesetList: types.optional(types.array(types.string), []),
  })
  .extend(withEnvironment)
  .extend(withRootStore)
  .views((self) => ({}))
  .actions((self) => ({
    setSnmpRulesets: (snmpRulesets: ISnmpRuleset[]) => {
      self.snmpRulesets.replace(snmpRulesets);
    },
    setSelectedSnmpRuleset: (snmpRuleset: ISnmpRulesetSnapshot) => {
      self.selectedSnmpRuleset = snmpRuleset as ISnmpRuleset;
    },
    setPagination: (pagination: IPaginationSnapshot) => {
      self.pagination = pagination as IPagination;
    },

    setDeleteSnmnpRulesetList: (trapIdList: string[]) => {
      self.deleteSnmnpRulesetList.replace(trapIdList);
    },
    appendDeleteSnmnpRulesetList: (deleteTrapId: string) => {
      self.deleteSnmnpRulesetList.push(deleteTrapId);
    },
  }))
  .actions((self) => ({
    /**
     * INF_CAIMS_00049
     * SNMP 룰셋 목록을 조회한다.
     * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=488542543
     * @param snmpRulesetFilter
     */
    gets: async (snmpRulesetFilter?: TSnmpRulsetFilter) => {
      self.setSnmpRulesets([]);
      try {
        const snmpRulesetApi: SnmpRulesetApi = new SnmpRulesetApi(self.environment.api);
        let result: TGetSnmpRulesetsResult;
        if (snmpRulesetFilter) {
          result = await snmpRulesetApi.gets(self.pagination, snmpRulesetFilter);
        } else {
          result = await snmpRulesetApi.gets(self.pagination);
        }

        if (
          self.rootStore.responseStore.getResponseResult(result) &&
          result.pagination &&
          result.pagination.page === self.pagination.page
        ) {
          if (result.snmpRulesets) {
            result.snmpRulesets.forEach((item: ISnmpRulesetSnapshot) => {
              item.regDt = timestampToDate(Number.parseInt(item.regDt));
              item.updDt = timestampToDate(Number.parseInt(item.updDt));
              item.msg = item.msg ? item.msg : "";
              item.msgOid = item.msgOid ? item.msgOid : [];
            });

            self.setSnmpRulesets(result.snmpRulesets);
          }
          if (result.pagination) {
            self.setPagination(result.pagination);
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },

    /**
     * INF_CAIMS_00050
     * 해당 SNMP 룰셋의 상세정보를 조회한다.
     * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=1080779067
     * @param trapId
     */
    get: async (trapId: string) => {
      self.selectedSnmpRuleset = null;
      try {
        const snmpRulesetApi: SnmpRulesetApi = new SnmpRulesetApi(self.environment.api);
        const result: TGetSnmpRulesetResult = await snmpRulesetApi.get(trapId);

        if (self.rootStore.responseStore.getResponseResult(result)) {
          if (result.snmpRuleset) {
            result.snmpRuleset.regDt = timestampToDate(Number.parseInt(result.snmpRuleset.regDt));
            result.snmpRuleset.updDt = timestampToDate(Number.parseInt(result.snmpRuleset.updDt));
            result.snmpRuleset.msg = result.snmpRuleset.msg ? result.snmpRuleset.msg : "";
            result.snmpRuleset.msgOid = result.snmpRuleset.msgOid ? result.snmpRuleset.msgOid as any : [];

            if (result.snmpRuleset.sttusOid) {
              if (result.snmpRuleset.trapVarList) {
                result.snmpRuleset.trapVarList.map((li) => {
                  li.trapVarSttusList
                    .filter((sttusli) => {
                      if (result.snmpRuleset) {
                        return sttusli.varOid === result.snmpRuleset.sttusOid;
                      }
                    })
                    .filter((matchedSttusli) => {
                      if (result.snmpRuleset) {
                        if (result.snmpRuleset.trapSvrtStngList) {
                          result.snmpRuleset.trapSvrtStngList.map((trapSvrtStng) => {
                            if (matchedSttusli.sttusVal === trapSvrtStng.sttusVal) {
                              trapSvrtStng.sttusValCode = ModelCode.create({
                                pcode: 0,
                                code: +trapSvrtStng.sttusVal,
                                value: matchedSttusli.sttusNm + '(' + trapSvrtStng.sttusVal + ')',
                              });
                            }
                          });
                        }
                      }
                    });
                });
              }
            }

            let oidList: any = [];
            if (result.snmpRuleset.spcfProbVarOidList) {
              result.snmpRuleset.spcfProbVarOidList.forEach((item: string) => {
                if (result.snmpRuleset) {
                  if (result.snmpRuleset.trapVarList) {
                    result.snmpRuleset.trapVarList.forEach((trap: ITrap) => {
                      if (trap.varOid === item) {
                        oidList.push(item + '(' + trap.varNm + ')');
                      }
                    });
                  }
                }
              });
              if (oidList.length > 0) {
                result.snmpRuleset.spcfProbVarOidList = oidList;
              }
            }

            self.setSelectedSnmpRuleset(result.snmpRuleset);
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },

    /**
     * INF_CAIMS_00052
     * 해당 SNMP 룰셋을 수정한다.
     * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=1636399592
     * @param snmpRuleset
     */
    put: async (snmpRuleset: ISnmpRulesetSnapshot) => {
      self.selectedSnmpRuleset = null;
      try {
        const snmpRulesetApi: SnmpRulesetApi = new SnmpRulesetApi(self.environment.api);
        const result: TUpdateSnmpRulesetResult = await snmpRulesetApi.put(snmpRuleset);

        if (self.rootStore.responseStore.getResponseResult(result)) {
          if (result.snmpRuleset) {
            result.snmpRuleset.regDt = timestampToDate(Number.parseInt(result.snmpRuleset.regDt));
            result.snmpRuleset.updDt = timestampToDate(Number.parseInt(result.snmpRuleset.updDt));

            self.setSelectedSnmpRuleset(result.snmpRuleset);
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },

    /**
     * INF_CAIMS_00053
     * 해당 SNMP 룰셋을 삭제한다.
     * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=1526422337
     * @param trapId
     */
    delete: async (trapId: string) => {
      try {
        const snmpRulesetApi: SnmpRulesetApi = new SnmpRulesetApi(self.environment.api);
        const result: TDeleteSnmpRulesetResult = await snmpRulesetApi.delete(trapId);
        self.rootStore.responseStore.getResponseResult(result);
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },

    /**
     * INF_CAIMS_00054
     * 여러개 snmp 룰셋을 한번에 삭제한다.
     * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=678410793
     */
    deletes: async () => {
      try {
        const snmpRulesetApi: SnmpRulesetApi = new SnmpRulesetApi(self.environment.api);
        const result: TDeleteSnmpRulesetResult = await snmpRulesetApi.deletes(
          self.deleteSnmnpRulesetList,
        );

        self.rootStore.responseStore.getResponseResult(result);
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },

    /**
     * INF_CAIMS_00232
     * MIB 파일을 업로드하여 SNMP TRAP 정보를 등록한다.
     * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=965032247
     * @param fileList
     * @param intrlkSysSid
     * @param errorSkip
     */
    mibUpload: async (fileList: File[], intrlkSysSid: number, errorSkip?: boolean) => {
      self.selectedSnmpRuleset = null;
      try {
        const snmpRulesetApi: SnmpRulesetApi = new SnmpRulesetApi(self.environment.api);
        errorSkip = false;
        const result: TMibUploadResult = await snmpRulesetApi.mibUpload(
          fileList,
          intrlkSysSid,
          errorSkip,
        );

        if (self.rootStore.responseStore.getResponseResult(result)) {
          if (result.snmpRuleset) {
            self.setSelectedSnmpRuleset(result.snmpRuleset);
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },
  }));

// --------------------------------------------------------------------------
type TSnmpRulesetStore = Instance<typeof SnmpRulesetStore>;
type TSnmpRulesetStoreSnapshot = SnapshotOut<typeof SnmpRulesetStore>;

export interface ISnmpRulesetStore extends TSnmpRulesetStore {}
export type TSnmpRulesetStoreKeys = keyof TSnmpRulesetStoreSnapshot & string;
export interface ISnmpRulesetStoreSnapshot extends TSnmpRulesetStoreSnapshot {}
export const createSnmpRulesetStore = () =>
  types.optional(SnmpRulesetStore, {} as TSnmpRulesetStore);

export interface ISnmpRulesetFilter {
  trapId?: string;
  intrlkSysSid?: number | string;
  trapNm?: string;
  eventType?: number | string;
  mibNm?: string;
  useYn?: boolean;
  regDtSt?: string | number;
  regDtEd?: string | number;
}

export type TSnmpRulsetFilter = ISnmpRulesetFilter;
