import { Instance, SnapshotOut, types } from 'mobx-state-tree';

export const ModelAllRole = types
  .model('ModelAllRole')
  .props({
    allUsrgrpSid: types.optional(types.maybeNull(types.array(types.number)), null),
    allRoleId: types.optional(types.maybeNull(types.array(types.string)), null),
  })
  .actions((self) => ({
    resetModelAllRole: () => {
      self.allUsrgrpSid = null;
      self.allRoleId = null;
    },
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as IModelAllRole;
      (Object.keys(newProps) as TModelAllRoleKeys[]).forEach((key) => {
        //@ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

type TModelAllRole = Instance<typeof ModelAllRole>;
type TModelAllRoleSnapshot = SnapshotOut<typeof ModelAllRole>;

export interface IModelAllRole extends TModelAllRole {}
export type TModelAllRoleKeys = keyof TModelAllRoleSnapshot & string;
export interface IModelAllRoleSnapshot extends TModelAllRoleSnapshot {}
export const createModelAllRole = () =>
  types.optional(ModelAllRole, {
    allUsrgrpSid: null,
    allRoleId: null,
  } as TModelAllRole);
