import { Instance, SnapshotOut, types } from 'mobx-state-tree';

export const DpaudioData = types
  .model("DpaudioData")
  // --------------------------------------------------------------------------
  .props({
    title: types.optional(types.string, ''),
    dpaudioDtSt:  types.optional(types.maybeNull(types.number), null),
    dpaudioDtEd:  types.optional(types.maybeNull(types.number), null),
    chSvcId: types.optional(types.number, 0),
    chNm: types.optional(types.string, ''),
    commentaryYn: types.optional(types.boolean, false),
    commentaryRealMainYn: types.optional(types.boolean, false),
    commentaryRealBackupYn: types.optional(types.boolean, false),
  })
  // --------------------------------------------------------------------------
  // MUTATEs ONLY! - 모델 상태를 변경
  // REQUESTs는 스토어 모델에 작성하세요.
  // ex) yarn gen model dpccLogData --store
  .actions((self) => ({
    /*
     * 여러 prop을 한번에 수정
     */
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as IDpaudioData;
      (Object.keys(newProps) as TDpaudioDataKeys[]).forEach((key) => {
        // @ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type TDpaudioData = Instance<typeof DpaudioData>;
type TDpaudioDataSnapshot = SnapshotOut<typeof DpaudioData>

export interface IDpaudioData extends TDpaudioData { }
export type TDpaudioDataKeys = keyof TDpaudioDataSnapshot & string;
export interface IDpaudioDataSnapshot extends TDpaudioDataSnapshot { }
export const createDpaudioData = () =>
  types.optional(DpaudioData, {
    title: '',
    dpaudioDtSt: null,
    dpaudioDtEd: null,
    chSvcId: 0,
    chNm: '',
  } as TDpaudioData);
