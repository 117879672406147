import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { createSimpleUser } from './../simple-user/SimpleUser';

export const CommonCode = types
  .model('CommonCode')
  .props({
    cdId: types.number,
    cdPid: types.number,
    cdNm: types.string,
    srcNm: types.string,
    cdDispNm: types.string,
    cdDispNmEng: types.string,
    cdOrder: types.number,
    refId: types.string,
    useYn: types.boolean,
    dscrpt: types.string,
    regDt: types.string,
    updDt: types.string,
    //구버전
    regId: types.number,
    updId: types.number,
    //신버전
    regUser: types.maybeNull(createSimpleUser()),
    updUser: types.maybeNull(createSimpleUser()),
  })
  .actions((self) => ({
    resetMdoel: () => {
      const today = new Date().getTime();
      self.cdId = -1;
      self.cdPid = -1;
      self.cdNm = '';
      self.srcNm = '';
      self.cdDispNm = '';
      self.cdDispNmEng = '';
      self.cdOrder = -1;
      self.refId = '';
      self.useYn = false;
      self.dscrpt = '';
      self.regDt = '';
      self.regId = -1;
      self.updDt = '';
    },
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as ICommonCode;
      (Object.keys(newProps) as TCommonCodeKeys[]).forEach((key) => {
        //@ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

type TCommonCode = Instance<typeof CommonCode>;
type TCommonCodeSnapshot = SnapshotOut<typeof CommonCode>;

export interface ICommonCode extends TCommonCode {}
export type TCommonCodeKeys = keyof TCommonCodeSnapshot & string;
export interface ICommonCodeSnapshot extends TCommonCodeSnapshot {}
export const createCommonCode = () =>
  types.optional(CommonCode, {
    cdId: 0,
    cdPid: 0,
    cdNm: '',
    srcNm: '',
    cdDispNm: '',
    cdDispNmEng: '',
    cdOrder: 0,
    refId: '',
    useYn: false,
    dscrpt: '',
    regDt: '',
    regId: 0,
    updDt: '',
    updId: 0,
  } as TCommonCode);
