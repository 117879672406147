import { Instance, SnapshotOut, types } from 'mobx-state-tree';

/**
 * # AcntStts
 *
 * AcntStts을 설명하세요.
 */
export const AcntStts = types
  .model('AcntStts')
  .props({
    code: types.optional(types.number, 0),
    value: types.optional(types.string, ''),
  })
  .actions((self) => ({
    resetAcntStts: () => {
      self.code = 0;
      self.value = '';
    },
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as IAcntStts;
      (Object.keys(newProps) as TAcntSttsKeys[]).forEach((key) => {
        //@ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type TAcntStts = Instance<typeof AcntStts>;
type TAcntSttsSnapshot = SnapshotOut<typeof AcntStts>;

export interface IAcntStts extends TAcntStts {}
export type TAcntSttsKeys = keyof TAcntSttsSnapshot & string;
export interface IAcntSttsSnapshot extends TAcntSttsSnapshot {}
export const createAcntStts = () =>
  types.optional(AcntStts, {
    code: 0,
    value: '',
  } as TAcntStts);
