import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { type } from 'os';
import { Aco } from '../aco/Aco';
import { createAdvm } from '../advm/Advm';
import { Encod } from '../encod/Encod';
import { HdMux } from '../hd-mux/HdMux';
import { InputSw } from '../input-sw/InputSw';
import { createModelCode, ModelCode } from '../model-code/ModelCode';
import { OutputSw } from '../output-sw/OutputSw';
import { createPpCompany } from '../pp-company/PpCompany';
import { SdMux } from '../sd-mux/SdMux';
import { SdSw } from '../sd-sw/SdSw';
import { Trcod } from '../trcod/Trcod';
import { createSimpleUser } from './../simple-user/SimpleUser';

export const Channel = types
  .model('Channel')
  .props({
    /**2022-04-29 기준
     *  재설계 된 API를 바탕으로 작성
     *  기존 파라미터 제거
     */
    sdSwList: types.optional(types.maybeNull(types.array(SdSw)), []),
    trcodList: types.optional(types.maybeNull(types.array(Trcod)), []),
    encodList: types.optional(types.maybeNull(types.array(Encod)), []),
    inputSwList: types.optional(types.maybeNull(types.array(InputSw)), []),
    acoList: types.optional(types.maybeNull(types.array(Aco)), []),
    hdMuxList: types.optional(types.maybeNull(types.array(HdMux)), []),
    sdMuxList: types.optional(types.maybeNull(types.array(SdMux)), []),
    outputSwList: types.optional(types.maybeNull(types.array(OutputSw)), []),

    chSvcId: types.optional(types.number, 0),
    chNo: types.optional(types.maybeNull(types.number), 0),
    chNm: types.optional(types.maybeNull(types.string), ''),
    chType: types.maybeNull(createModelCode()),
    trtResol: createModelCode(),
    duplSglType: createModelCode(),
    adultYn: types.optional(types.boolean, false),
    seeznIntrlkYn: types.optional(types.boolean, false),
    hcnIntrlkYn: types.optional(types.boolean, false),
    skylifeIntrlkYn: types.optional(types.boolean, false),
    sadInfo: types.maybeNull(createModelCode()),
    araIntrlkYn: types.optional(types.boolean, false),
    advm: types.maybeNull(createAdvm()),
    advmMemo: types.optional(types.maybeNull(types.string), ''),
    hdMip: types.optional(types.string, ''),
    sdMip: types.optional(types.maybeNull(types.string), ''),
    psiIn: createModelCode(),
    pat: types.optional(types.maybeNull(types.number), 0),
    pmt: types.optional(types.maybeNull(types.number), 0),
    videoPid: types.optional(types.number, 0),
    audio1Pid: types.optional(types.number, 0),
    audio2Pid: types.optional(types.maybeNull(types.number), 0),
    acapAit: types.optional(types.maybeNull(types.number), 0),
    webAit: types.optional(types.maybeNull(types.number), 0),
    acapOc: types.optional(types.maybeNull(types.number), 0),
    webOc: types.optional(types.maybeNull(types.number), 0),
    videoStchYn: types.optional(types.boolean, false),
    dpsonSubtitYn: types.optional(types.boolean, false),
    vcMultiYn: types.optional(types.boolean, false),
    dplxYn: types.optional(types.boolean, false),
    casEcodYn: types.optional(types.boolean, false),
    ecmPid: types.optional(types.maybeNull(types.number), 0),
    pp: types.maybeNull(createPpCompany()),
    encodCcYn: types.optional(types.boolean, false),
    encodHdMip: types.optional(types.maybeNull(types.string), ''),
    encodSdMip: types.optional(types.maybeNull(types.string), ''),
    encodQtoneAPid: types.optional(types.maybeNull(types.number), 0),
    encodQtoneBPid: types.optional(types.maybeNull(types.number), 0),
    encodQtoneDPid: types.optional(types.maybeNull(types.number), 0),
    encodPmtPid: types.optional(types.maybeNull(types.number), 0),
    encodVideoPid: types.optional(types.maybeNull(types.number), 0),
    encodAudioPid: types.optional(types.maybeNull(types.number), 0),
    encodAudio2Pid: types.optional(types.maybeNull(types.number), 0),
    helthySttus: types.maybeNull(createModelCode()),
    rglsSttus: types.maybeNull(createModelCode()),
    rglsComotDate: types.optional(types.maybeNull(types.string), ''),
    regDt: types.optional(types.maybeNull(types.string), ''),
    regUser: types.maybeNull(createSimpleUser()),
    updDt: types.optional(types.maybeNull(types.string), ''),
    updUser: types.maybeNull(createSimpleUser()),
    ktTrmNetVendr: types.maybeNull(createModelCode()),
    ktCircuitNoMain: types.optional(types.maybeNull(types.string), ''),
    ktCircuitNoBackup: types.optional(types.maybeNull(types.string), ''),
    ktTrsmDept: types.optional(types.maybeNull(types.string), ''),
    ppNm: types.optional(types.maybeNull(types.string),''),
    ppSid: types.optional(types.maybeNull(types.number),0),
    msppNm: types.optional(types.maybeNull(types.string),''),

    wrkMemo: types.optional(types.maybeNull(types.string),null),

    dedicatedChYn: types.optional(types.boolean, false),
    dbsChNm: types.optional(types.maybeNull(types.string), null),
    /*
     * 화면에서만 사용
     */

    acoAraList: types.optional(types.maybeNull(types.array(Aco)), []),
  })
  .actions((self) => ({
    resetChannel: () => {
      self.sdSwList = null;
      self.trcodList = null;
      self.encodList = null;
      self.inputSwList = null;
      self.acoList = null;
      self.hdMuxList = null;
      self.sdMuxList = null;
      self.outputSwList = null;
      self.pp = null;
      self.advm = null;
      self.chSvcId = 0;
      self.chNo = 0;
      self.chNm = '';
      self.chType = ModelCode.create({
        code: 0,
        value: '',
      });
      self.trtResol = ModelCode.create({
        code: 0,
        value: '',
      });
      self.duplSglType = ModelCode.create({
        code: 0,
        value: '',
      });
      self.seeznIntrlkYn = false;
      self.hcnIntrlkYn = false;
      self.skylifeIntrlkYn = false;
      self.adultYn = false;
      self.sadInfo = ModelCode.create({
        code: 0,
        value: '',
      });
      self.psiIn = ModelCode.create({
        code: 0,
        value: '',
      });
      self.advmMemo = '';
      self.hdMip = '';
      self.sdMip = null;
      self.pat = 0;
      self.pmt = 0;
      self.videoPid = 0;
      self.audio1Pid = 0;
      self.audio2Pid = 0;
      self.acapAit = 0;
      self.webAit = 0;
      self.acapOc = 0;
      self.webOc = 0;
      self.videoStchYn = false;
      self.dpsonSubtitYn = false;
      self.vcMultiYn = false;
      self.dplxYn = false;
      self.casEcodYn = false;
      self.ecmPid = 0;
      self.helthySttus = ModelCode.create({
        code: 0,
        value: '',
      });
      self.rglsSttus = ModelCode.create({
        code: 0,
        value: '',
      });
      self.ktTrmNetVendr = ModelCode.create({
        code: 0,
        value: '',
      });
      self.ktCircuitNoBackup = '';
      self.ktCircuitNoMain = '';
      self.ktTrsmDept = '';
      self.rglsComotDate = '';
    },
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as IChannel;
      (Object.keys(newProps) as TChannelKeys[]).forEach((key) => {
        //@ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

type TChannelType = Instance<typeof Channel>;
type TChannelSnapshot = SnapshotOut<typeof Channel>;

export interface IChannel extends TChannelType {}
export type TChannelKeys = keyof TChannelSnapshot & string;
export interface IChannelSnapshot extends TChannelSnapshot {}
export const createChannel = () =>
  types.optional(Channel, {
    sdSwList: null,
    trcodList: null,
    encodList: null,
    inputSwList: null,
    acoList: null,
    hdMuxList: null,
    sdMuxList: null,
    outputSwList: null,
    pp: null,
    chSvcId: 0,
    chNo: 0,
    chNm: '',
    advmMemo: '',
    hdMip: '',
    sdMip: null,
    pat: 0,
    pmt: 0,
    videoPid: 0,
    audio1Pid: 0,
    audio2Pid: 0,
    acapAit: 0,
    webAit: 0,
    acapOc: 0,
    webOc: 0,
    adultYn: false,
    videoStchYn: false,
    dpsonSubtitYn: false,
    vcMultiYn: false,
    dplxYn: false,
    casEcodYn: false,
    ecmPid: 0,
    rglsComotDate: '',
    wrkMemo: null,
    dedicatedChYn: false
  });

//폼 등록 시 초기값 설정에 사용
//값이 0인 경우 필수값 체크 유효성에 문제가 있어 생성함
type TDefaultChannel = typeof defaultChannel;
export interface IDefaultChannel extends TDefaultChannel {}
export const defaultChannel = {
  wrkMemo: null,
  //기본정보
  chSvcId: '',
  chNo: '',
  chNm: '',
  chType: '',
  trtResol: '',
  duplSglType: '',
  seeznIntrlkYn: false,
  hcnIntrlkYn: false,
  skylifeIntrlkYn: false,
  sadInfo: '',
  //기본정보>광고정보

  araIntrlkYn: false,
  advmSpatnYn: false,
  psiIn: '',
  advmYn: false,
  skylife: false,
  hcnAdvmSpatnYn: false,
  advmMemo: '',
  qtoneANo: '',
  qtoneBNo: '',
  qtoneDPid: '',
  hdMip: '',
  clearMip: null,
  pat: 0,
  pmt: '',
  videoPid: '',
  audio1Pid: '',
  audio2Pid: '',
  acapAit: '',
  webAit: '',
  acapOc: '',
  webOc: '',
  skylifeCircuitSpatnYn: false,
  hcnCircuitSpatnYn: false,
  //기본정보>부가서비스정보
  videoStchYn: false,
  dpsonSubtitYn: false,
  vcMultiYn: false,
  dplxYn: false,
  casEcodYn: false,
  ecmPid: 1289,
  //PP사 / 전용회선
  trmNetVendr: '',
  ktTrsmDept: '',
  sdSwList: [
    {
      mainYn: true,
    },
  ],
  trcodList: [
    {
      mainYn: true,
    },
  ],
  encodList: [
    {
      mainYn: true,
    },
  ],
  inputSwList: [
    {
      mainYn: true,
    },
  ],
  acoList: [
    {
      mainYn: true,
    },
  ],
  acoAraList: [
    {
      mainYn: true,
    },
  ],
  hdMuxList: [
    {
      mainYn: true,
    },
  ],
  sdMuxList: [
    {
      mainYn: true,
    },
  ],
  outputSwList: [
    {
      mainYn: true,
    },
  ],
  ppNm: '',
  encodCcYn: false,
  // encodHdMip: null,
  // encodSdMip: null,
  encodPmtPid: '',
  encodVideoPid: '',
  encodAudioPid: '',
  encodAudio2Pid: '',
  dedicatedChYn: false
};
