import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { TEquipmentKeys } from './Equipment';

export const UploadResult = types
    .model('UploadResult')
    .props({
        successList: types.optional(types.array(types.string),[]),
        errorList: types.optional(types.array(types.string),[]),
    })
    .actions((self) => ({
        setProps: (props: { [key: string]: any }) => {
            const newProps = { ...self, ...props } as IUploadResult;
            (Object.keys(newProps) as TEquipmentKeys[]).forEach((key) => {
                //@ts-ignore
                self[key] = newProps[key];
            });
        },
    }));

type TUploadResult = Instance<typeof UploadResult>;
type TUploadResultSnapshotType = SnapshotOut<typeof UploadResult>;

export interface IUploadResult extends TUploadResult {}
export interface IUploadResultSnapshotType extends TUploadResultSnapshotType {}

export const createUploadResult = () =>
    types.optional(UploadResult, {} as IUploadResult);
