import { ApisauceInstance, create } from 'apisauce';
import { IResponseSnapshot } from '../../models';
import { TCheckIdResult } from '../channel/ChannelTypes';
import { ApiConfig, DEFAULT_API_CONFIG } from './api-config';

/**
 * Manages all requests to the API.
 */
export class Api {
  /**
   * The underlying apisauce instance which performs the requests.
   */
  //@ts-ignore
  apisauce: ApisauceInstance;

  /**
   * Configurable options.
   */
  config: ApiConfig;

  /**
   * Creates the api.
   *
   * @param config The configuration to use.
   */
  constructor(config: ApiConfig = DEFAULT_API_CONFIG) {
    this.config = config;
  }

  /**
   * Sets up the API.  This will be called during the bootup
   * sequence and will happen before the first React component
   * is mounted.
   *
   * Be as quick as possible in here.
   */
  setup() {
    this.apisauce = create({
      baseURL: this.config.url,
      timeout: this.config.timeout,
      headers: {
        Accept: 'application/json',
      },
      withCredentials: this.config.withCredentials,
    });
  }

  /**
   * file download api
   *
   * @author yjkim
   */
  async download(url: string, fileName: string): Promise<TCheckIdResult> {
    await fetch(process.env.REACT_APP_API_URL + url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application',
      },
      credentials: 'include',
    })
      .then((response) => {
        fileName = response?.headers?.get('Content-Disposition')?.split('"')[1] || fileName;
        fileName = decodeURI(fileName);
        return response.blob();
      })
      .then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode?.removeChild(link);
      });
    return { kind: 'ok', responseInfo: {} as IResponseSnapshot };
  }

  async downloadFileToURL(url: string, fileName: string): Promise<any> {
    return fetch(process.env.REACT_APP_API_URL + url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application',
      },
      credentials: 'include',
    })
      .then((response) => {
        fileName = response?.headers?.get('Content-Disposition')?.split('"')[1] || fileName;
        fileName = decodeURI(fileName);
        return response.blob();
      })
      .then((blob) => {
        return { ok: true, responseInfo: window.URL.createObjectURL(new Blob([blob])) };
      });
  }
}
