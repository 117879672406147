import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { withEnvironment } from '../extensions/with-environment';

export const ErrorStore = types
  .model('ErrorStore')
  .props({
    errorCode: types.optional(types.string, ''),
    title: types.optional(types.string, ''),
    errorMessage: types.optional(types.string, ''),
  })
  .extend(withEnvironment)
  .views((self) => ({}))
  .actions((self) => ({
    setProps: (props: { [key: string]: any }) => {
      const updated = { ...self, ...props } as IErrorStore;
      (Object.keys(updated) as TErrorStoreKeys[]).forEach((key) => {
        if (typeof self[key] === typeof updated[key]) {
          //@ts-ignore
          self[key] = updated[key];
        } else {
          throw new Error(`${key.toString} Input Type Error`);
        }
      });
    },
  }))

  .actions((self) => ({}));

type TErrorStore = Instance<typeof ErrorStore>;
type TErrorStoreSnapshot = SnapshotOut<typeof ErrorStore>;

export interface IErrorStore extends TErrorStore {}
export type TErrorStoreKeys = keyof TErrorStoreSnapshot & string;
export interface IErrorStoreSnapshot extends TErrorStoreSnapshot {}
export const createErrorStore = () => types.optional(ErrorStore, {} as TErrorStore);
