//
// components
import CDialog from '../../components/CDialog';
import NavSection from '../../components/NavSection';
import { Scrollbar } from '../../components/Scrollbar';
import { MHidden } from '../../components/material-extend';
// hooks
import { useCollapseDrawer } from '../../hooks/useCollapseDrawer';
import { IUsrgrpMenuSnapshot, useStores } from '../../models';
// material
import LogoutIcon from '@mui/icons-material/Logout';
import {
  Box,
  CardActionArea,
  Drawer,
  IconButton,
  Link,
  Stack,
  Tooltip,
  Typography
} from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import { isNull } from 'lodash';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { ReactComponent as LogoSVG } from '../../assets/images/logo.svg';
import '../../components/forms/CEditor-Main.css';
import '../../components/forms/CEditor-dark.css';
import { FORMSET_ITEM_LABEL } from '../../components/settings/SettingConstEnum';
import { IAuthUserSnapshot } from '../../models/auth-user/AuthUser';
import { CallApiToStore } from '../../screens/Home';
import { MyPageModal } from '../../screens/login/MyPage/MyPageModal';
import sidebarConfig, { IIconObject, iconArray } from './SidebarConfig';
// ----------------------------------------------------------------------

interface IMenuChild {
  title: string;
  path: string;
}
interface IMenu {
  title: string;
  icon?: JSX.Element;
  children:
  | IMenuChild[]
  | {
    title: string;
    icon?: JSX.Element;
    children?: {
      title: string;
      path: string;
    }[];
  }[];
}

interface IItem {
  items: IMenu[];
}

const getIcon = (title: string) => {
  let forIcon: JSX.Element | undefined;
  iconArray.forEach((item: IIconObject) => {
    if (item.title === title) {
      forIcon = item.icon as JSX.Element;
    }
  });
  return forIcon;
};

const DRAWER_WIDTH = 280;
const COLLAPSE_WIDTH = 102;
const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.complex,
    }),
  },
}));

const AccountStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(1),
  borderRadius: 8,
  backgroundColor: theme.palette.grey[500_12],
  justifyContent: 'space-between'
}));

// ------------------------------------------------------₩----------------

type IconCollapseProps = {
  onToggleCollapse: VoidFunction;
  collapseClick: boolean;
};

function IconCollapse({ onToggleCollapse, collapseClick }: IconCollapseProps) {
  return (
    <Tooltip title="Mini Menu">
      <CardActionArea
        onClick={onToggleCollapse}
        sx={{
          width: 18,
          height: 18,
          display: 'flex',
          cursor: 'pointer',
          borderRadius: '50%',
          alignItems: 'center',
          color: 'text.primary',
          justifyContent: 'center',
          border: 'solid 1px currentColor',
          ...(collapseClick && {
            borderWidth: 2,
          }),
        }}
      >
        <Box
          sx={{
            width: 8,
            height: 8,
            borderRadius: '50%',
            bgcolor: 'currentColor',
            transition: (theme) => theme.transitions.create('all'),
            ...(collapseClick && {
              width: 0,
              height: 0,
            }),
          }}
        />
      </CardActionArea>
    </Tooltip>
  );
}

type DashboardSidebarProps = {
  isOpenSidebar: boolean;
  onCloseSidebar: VoidFunction;
};

export default observer(function DashboardSidebar({
  isOpenSidebar,
  onCloseSidebar,
}: DashboardSidebarProps) {
  const { pathname } = useLocation();
  const { authStore, loadingStore, userStore, notificationStore } = useStores();
  const { isCollapse, collapseClick, collapseHover, onToggleCollapse, onHoverEnter, onHoverLeave } = useCollapseDrawer();
  // 메뉴 관련
  const [menuSidebar, setMenuSidebar] = useState<any>(sidebarConfig());
  // 마이페이지 관련
  const [isMyPageOpen, setIsMyPageOpen] = useState<boolean>(false);
  // 유저 정보 관련
  const user: IAuthUserSnapshot | null = authStore.getLoginUserStorageData();
  const loginUser = localStorage.getItem('loginUser');
  const userStorage = JSON.parse(loginUser ?? '{}');
  const lastLogin = (userStorage !== '') ? moment(userStorage.lastLoginDt).format('YYYY-MM-DD HH:mm:ss') : '';

 

  // -------------------------------------------
  // use Effect
  // -------------------------------------------
  useEffect(() => {
    getMenu();
  }, []);

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
  }, [pathname]);

  // -------------------------------------------
  // 일반 함수
  // -------------------------------------------
  /**
   * 메뉴 생성
   */
  const getMenu = async () => {
    if (isNull(user)) {
      authStore.logout();
    }
    const menuList = user?.menuList;
    let menuChildList: IUsrgrpMenuSnapshot[] = [];
    let resultMenu: IItem[] = [];

    menuList?.forEach((menu: IUsrgrpMenuSnapshot) => {

      if (!menu.menuPsid && menu.menuNm) {
        let pMenu: IMenu[] = [
          {
            title: menu.menuNm,
            icon: getIcon(menu.menuNm),
            children: [],
          },
        ];

        menuList?.forEach((subMenu: IUsrgrpMenuSnapshot) => {
          if (subMenu.menuPsid && subMenu.menuPsid === menu.menuSid) {
            if (!subMenu.menuUri) {
              let child: { title: string; path: string }[] = [];
              menuList?.forEach((childMenu: IUsrgrpMenuSnapshot) => {
                if (subMenu.menuSid === childMenu.menuPsid) {
                  child.push({ title: childMenu.menuNm + '', path: childMenu.menuUri + '' });
                }
              });
              //@ts-ignore
              pMenu[0].children.push({
                title: subMenu.menuNm + ``,
                icon: getIcon(subMenu.menuNm + ''),
                children: child,
              });
            } else {
              pMenu[0].children.push({
                title: subMenu.menuNm + ``,
                path: subMenu.menuUri + ``,
              });
            }
          }
        });
        resultMenu.push({ items: pMenu });
      } else {
        menuChildList.push(menu);
      }
    });
    setMenuSidebar(resultMenu);
  };

  // 마이페이지 오픈
  const openMyPage = () => {
    const innerFunction = async () => {
      await CallApiToStore(userStore.getMyself(), 'api', loadingStore);
      await setIsMyPageOpen(true);
    };
    innerFunction();
  }

  // 마이페이지 클록즈
  const handleMyPageClose = () => setIsMyPageOpen(false);

  // -------------------------------------------
  // 컨포넌트
  // -------------------------------------------
  const renderContent = (
    <Scrollbar
      sx={{
        height: '100%',
        '& .simplebar-content': { height: '100%', display: 'flex', flexDirection: 'column' },
      }}
    >
      <Stack
        spacing={3}
        sx={{
          px: 2.5,
          pt: 3,
          pb: 2,
          ...(isCollapse && {
            alignItems: 'center',
          }),
        }}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Box sx={{ display: 'inline-flex' }}>
            {!isCollapse ? (
              <Typography fontWeight={800} variant="h6">
                <LogoSVG style={{ margin: 'auto', marginLeft: '4px', minWidth: '150px', maxWidth: '200px' }} fill={'#FFF'} />
              </Typography>
            ) : (
              <Typography fontWeight={800} variant="h6" whiteSpace={'nowrap'}>
                Dr.GENIE
              </Typography>
            )}
          </Box>

          <MHidden width="lgDown">
            {!isCollapse && (
              <IconCollapse onToggleCollapse={onToggleCollapse} collapseClick={collapseClick} />
            )}
          </MHidden>
        </Stack>

        {isCollapse ? (
          <></>
        ) : (
          <Link
            underline="none"
            component={RouterLink}
            to="#"
            sx={{ cursor: 'default' }}
          >
            <AccountStyle>
              <Box sx={{ ml: 2, cursor: 'pointer' }} onClick={openMyPage}>
                <Typography variant="subtitle1" sx={{ color: 'text.primary', fontWeight: 'bold' }}>
                  {user?.userNm}
                </Typography>
                
                {/* 권한 그룹 리스트 */}
                { userStorage?.usrgrpList.map((list: any, i: number) => (
                    <Typography key={'list' + i} variant="body2" sx={{ color: 'text.secondary' }}>
                      {userStorage?.usrgrpList.length > 1 ?  i+1 + '. ' : ''}{list.usrgrpNm}
                    </Typography>
                ))}
                {/* 마지막 로그인 일시 */}
                {(lastLogin && isNaN(Date.parse(lastLogin)) == false) && (
                    <Tooltip title="마지막 접속일자">
                      <Typography variant="body2" sx={{
                        color: 'text.secondary'
                      }}>
                        {lastLogin}
                      </Typography>
                    </Tooltip>
                )}
              </Box>
              {/* 로그아웃 */}
              {isNull(authStore.getLoginUserStorageData) === false && (
                <Tooltip title="로그아웃">
                  <IconButton
                    sx={{ float: 'right' }}
                    onClick={(e) => {
                      authStore.logout();
                    }}
                  >
                    <LogoutIcon></LogoutIcon>
                  </IconButton>
                </Tooltip>
              )}
            </AccountStyle>
          </Link>
        )}
      </Stack>
      <NavSection navConfig={menuSidebar} isShow={!isCollapse} />
    </Scrollbar>
  );

  // -------------------------------------------
  // 렌더링
  // -------------------------------------------
  return (
    <React.Suspense
      fallback={null}
    >
      <RootStyle
        sx={{
          width: {
            lg: isCollapse ? COLLAPSE_WIDTH : DRAWER_WIDTH,
          },
          ...(collapseClick && {
            position: 'absolute',
          }),
        }}
      >
        <Drawer
          open
          variant="persistent"
          onMouseEnter={onHoverEnter}
          onMouseLeave={onHoverLeave}
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: 'background.default',
              ...(isCollapse && {
                width: COLLAPSE_WIDTH,
              }),
              ...(collapseHover && {
                borderRight: 0,
                backdropFilter: 'blur(6px)',
                WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
                boxShadow: (theme) => theme.customShadows.z20,
                bgcolor: (theme) => alpha(theme.palette.background.default, 0.88),
              }),
            },
          }}
        >
          {renderContent}
        </Drawer>
        
          <CDialog
            maxWidth={'sm'}
            isDialogOpen={isMyPageOpen}
            dialogTitle={FORMSET_ITEM_LABEL.systemManagement.myPage.basic}
            handleDialogClose={handleMyPageClose}
            keyId={'myPage'}
          >
            <MyPageModal isMyPageOpen={isMyPageOpen} handleMypageClose={handleMyPageClose} />
          </CDialog>
        

      </RootStyle>
    </React.Suspense>
  );
});

