import { ApiResponse } from "apisauce";
import { Api } from "../api/api";
import { getGeneralApiProblem } from "../api/api-problem";
import { ChannelDbs, IChannelDbs, IChannelDbsSnapshot, IResponseSnapshot } from '../../models';
import { 
  TGetChannelDbsBadgeResult,
  TGetChannelDbsResult,
  TUpdateChannelDbsResult,
 } from "./ChannelDbsTypes";
import { createParamsWithType } from "../../utils/paramsUtil";

  /**
  * # ChannelDbs Api 서비스
  * 
  * 서비스의 설명을 작성하세요.
  * 
  * ## 사용방법
  * 
  * ```ts
  * const service = new ChannelDbsApi();
  * 
  * ```
  */
 export class ChannelDbsApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  async getChannelDbsBadge(): Promise<TGetChannelDbsBadgeResult> {
    try {
      const url = `/channel/dbs/badge`;
      const params = {};
      const response: ApiResponse<any> = await this.api.apisauce.get( url, params );
  
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;

      return {
        kind: 'ok',
        badge: response.data.data,
        responseInfo: responseInfo,
      };
    } catch (e) {
      return { kind: "bad-data" };
    }
  }

  async getChannelDbs(): Promise<TGetChannelDbsResult> {
    try {
      const url = `/channel/dbs`;
      const params = {};
      const response: ApiResponse<any> = await this.api.apisauce.get( url, params );
  
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;

      return {
        kind: 'ok',
        channelDbs: response.data.data,
        responseInfo: responseInfo,
      };
    } catch (e) {
      return { kind: "bad-data" };
    }
  }

  async putChannelDbs(channelDbs: IChannelDbs): Promise<TUpdateChannelDbsResult> {
    try {
      const url = `/channel/dbs`;
      const params = createParamsWithType(channelDbs, ChannelDbs);
      const response: ApiResponse<any> = await this.api.apisauce.put( url, params );
  
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;
      
      return {
        kind: 'ok',
        channelDbs: response.data.data,
        responseInfo: responseInfo,
      };

    } catch (e) {
      return { kind: "bad-data" };
    }
  }
}

// const createParams = (channelDbs: IChannelDbs, type:any ) =>
// {
//   const params: any = {};

//   Object.keys(type.properties).forEach((key) => {
//     //@ts-ignore
//     params[key] = channelDbs[key];
//   });

//   return params;
// }