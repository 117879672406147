import { createChangeValue } from './../change-value/ChangeValue';
import { Instance, SnapshotOut, types } from 'mobx-state-tree';

/**
 * # PpCompnayHistoryContent
 *
 * PpCompnayHistoryContent을 설명하세요.
 */
export const PpCompnayHistoryContent = types
  .model('PpCompnayHistoryContent')
  // --------------------------------------------------------------------------
  .props({
    ppSid: types.maybeNull(createChangeValue()),
    ppNm: types.maybeNull(createChangeValue()),
    msppNm: types.maybeNull(createChangeValue()),
    msppPort: types.maybeNull(createChangeValue()),
    niSid: types.maybeNull(createChangeValue()),
    niNm: types.maybeNull(createChangeValue()),
    skylifeCircuitSpatnYn: types.maybeNull(createChangeValue()),
    hcnCircuitSpatnYn: types.maybeNull(createChangeValue()),
    ktTrsmDept: types.maybeNull(createChangeValue()),
    ktTrmNetVendr: types.maybeNull(createChangeValue()),
    ktCircuitNoMain: types.maybeNull(createChangeValue()),
    ktCircuitNoBackup: types.maybeNull(createChangeValue()),
    ktCircuitNoSpare: types.maybeNull(createChangeValue()),
    skylifeTrmNetVendr: types.maybeNull(createChangeValue()),
    skylifeCircuitNoBackup: types.maybeNull(createChangeValue()),
    skylifeCircuitNoSpare: types.maybeNull(createChangeValue()),
    hcnTrmNetVendr: types.maybeNull(createChangeValue()),
    hcnCircuitNoMain: types.maybeNull(createChangeValue()),
    hcnCircuitNoBackup: types.maybeNull(createChangeValue()),
    hcnCircuitNoSpare: types.maybeNull(createChangeValue()),
  })
  // --------------------------------------------------------------------------
  // MUTATEs ONLY! - 모델 상태를 변경
  // REQUESTs는 스토어 모델에 작성하세요.
  // ex) yarn gen model ppCompnayHistoryContent --store
  .actions((self) => ({
    /*
     * 여러 prop을 한번에 수정
     */
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as IPpCompnayHistoryContent;
      (Object.keys(newProps) as TPpCompnayHistoryContentKeys[]).forEach((key) => {
        self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type TPpCompnayHistoryContent = Instance<typeof PpCompnayHistoryContent>;
type TPpCompnayHistoryContentSnapshot = SnapshotOut<typeof PpCompnayHistoryContent>;

export interface IPpCompnayHistoryContent extends TPpCompnayHistoryContent {}
export type TPpCompnayHistoryContentKeys = keyof TPpCompnayHistoryContentSnapshot & string;
export interface IPpCompnayHistoryContentSnapshot extends TPpCompnayHistoryContentSnapshot {}
export const createPpCompnayHistoryContent = () =>
  types.optional(PpCompnayHistoryContent, {} as TPpCompnayHistoryContent);
