import { ApiResponse } from "apisauce";
import { Api } from "../api/api";
import { getGeneralApiProblem } from "../api/api-problem";
import { IQuetone, IQuetoneFilter, IQuetoneSnapshot, IResponseSnapshot, TQuetoneFilter } from '../../models';
import {
  TGetQuetoneResult,
  TGetQuetonesResult,
} from "./QuetoneTypes";
import { dateToTimestamp, timestampToDate } from "../../utils/dateExchanger";
import { IEpgDataSnapshot } from "../../models/epg-data/EpgData";
import { selectOptions } from "../../components/forms/CSelect";
import { isObject } from "lodash";

/**
* # Quetone Api 서비스
* 
* 서비스의 설명을 작성하세요.
* 
* ## 사용방법
* 
* ```ts
* const service = new QuetoneApi();
* 
* ```
*/

export class QuetoneApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  async getQuetones(
    filter?: TQuetoneFilter
  ): Promise<TGetQuetonesResult> {
    try {

      const type = filter?.type || 'a';
      let url = `/quetone/${type}`;

      if (type === 'dpcc') {
        url = '/dpcc';
      }
      else if(type === 'dpaudio')
      {
        url = '/dpaudio';
      }
      let chSvcIdList: string = ``; // event.targetInstanceId
      let params: any = {};  
      if (filter) {
        
        params.chSvcIdList = '';

        if(filter.chSvcIdLists){
          params = filter;
          filter.chSvcIdLists?.forEach((id: selectOptions) => {
            chSvcIdList += `chSvcIdList=${Number(id.code)}&`;
          });
          chSvcIdList = chSvcIdList.substring(0, chSvcIdList.length -1);
          url += `?${chSvcIdList}`;
        }else{
          filter.chSvcIdList?.forEach((id: string) => {
            params.chSvcIdList += `${id}`;
          });
        }

        if (type === 'dpcc' || type === 'dpaudio') {
          params.dpDtSt = dateToTimestamp(filter.dpDtSt);
          params.dpDtEd = dateToTimestamp(filter.dpDtEd);
        }else{
          params.quetoneDtSt = dateToTimestamp(filter.quetoneDtSt);
          params.quetoneDtEd = dateToTimestamp(filter.quetoneDtEd);
        }
      }
      
      delete params.chSvcIdLists;
      const response: ApiResponse<any> = await this.api.apisauce.get(url, params);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;
      return {
        kind: 'ok',
        data: response.data.data.data,
        responseInfo: responseInfo,
      };

    } catch (e) {
      return { kind: "bad-data" };
    }
  };

  async getQuetone(id:number): Promise<TGetQuetoneResult> {
    try {
      const url = `/quetone/${id}`;
      const params = {};
      const response: ApiResponse<any> = await this.api.apisauce.get( url, params );

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const quetone: IQuetone = response.data.results;
      return { kind: "ok", data: quetone, responseInfo: response.data };

    } catch (e) {
      return { kind: "bad-data" };
    }
  }

}