import { ApiResponse } from 'apisauce';
import { TSystemStatusDashboardStoreKeys } from '../../models/system-status-dashboard-store/SystemStatusDashboardStore';
import { Api, getGeneralApiProblem } from '../api';
import { TGetSystemStatusDashboardResult } from './SystemStatusDashboardTypes';
import { IResponseSnapshot } from '../../models';
import { RESULT_CODE } from '../../components/settings/SettingConstEnum';

export class SystemStatusDashboardApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  async gets(): Promise<TGetSystemStatusDashboardResult> {
    try {
      const SUB_URL: string = 'dashboard/system/status';
      const response: ApiResponse<any> = await this.api.apisauce.get(SUB_URL);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;
      if (responseInfo.resultCode === RESULT_CODE.SUCCESS) {
        return {
          kind: 'ok',
          data: response.data.data,
          responseInfo: responseInfo,
        };
      }

      return { kind: `ok`, responseInfo: responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }
}
