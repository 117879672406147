import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { selectOptions } from '../../components/forms/CSelect';

export const OneViewStatisticsGraph = types
  .model('OneViewStatisticsGraph')
  .props({
    dateList: types.optional(types.array(types.string), []),
    totalCnt: types.optional(types.array(types.number), []),
    addCnt: types.optional(types.array(types.number), []),
    lineData: types.optional(types.array(types.number), []),
  })
  .actions((self) => ({
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as IOneViewStatisticsGraph;
      (Object.keys(newProps) as TOneViewStatisticsGraphKeys[]).forEach((key) => {
        //@ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type TOneViewStatisticsGraph = Instance<typeof OneViewStatisticsGraph>;
type TOneViewStatisticsGraphSnapshot = SnapshotOut<typeof OneViewStatisticsGraph>;

export interface IOneViewStatisticsGraph extends TOneViewStatisticsGraph {}
export type TOneViewStatisticsGraphKeys = keyof TOneViewStatisticsGraphSnapshot & string;
export interface IOneViewStatisticsGraphSnapshot extends TOneViewStatisticsGraphSnapshot {}
export const createOneViewStatisticsGraph = () =>
  types.optional(OneViewStatisticsGraph, {} as TOneViewStatisticsGraph);
