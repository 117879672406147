import { Instance, SnapshotOut, types } from "mobx-state-tree"

/**
 * # QuetoneGraph
 * 
 * QuetoneGraph을 설명하세요.
 */
export const QuetoneGraph = types
  .model("QuetoneGraph")
  // --------------------------------------------------------------------------
  .props({
      intervalPrediction: types.optional(types.maybeNull(types.number),0),
      timeStamp: types.optional(types.maybeNull(types.string),""),
      avg: types.optional(types.maybeNull(types.number), 0),
      anomalyYn: types.optional(types.maybeNull(types.boolean),false),
      anomalyType: types.optional(types.maybeNull(types.number),0),
      channelSid: types.optional(types.maybeNull(types.number),0),
      channelName: types.optional(types.maybeNull(types.string),""),
      aiType: types.optional(types.maybeNull(types.string),""),
      predValue: types.optional(types.maybeNull(types.array(types.number)),[]),
      realValue: types.optional(types.maybeNull(types.array(types.number)),[]),
      minValue: types.optional(types.maybeNull(types.array(types.number)),[]),
      maxValue: types.optional(types.maybeNull(types.array(types.number)),[]),
      diffValue: types.optional(types.maybeNull(types.array(types.number)),[]),
      redline: types.optional(types.maybeNull(types.array(types.number)),[]),
      times: types.optional(types.maybeNull(types.array(types.string)),[]),

  })
  // --------------------------------------------------------------------------
  // MUTATEs ONLY! - 모델 상태를 변경
  // REQUESTs는 스토어 모델에 작성하세요.
  // ex) yarn gen model quetoneGraph --store
  .actions((self) => ({
    /*
     * 여러 prop을 한번에 수정
     */
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as IQuetoneGraph;
      (Object.keys(newProps) as TQuetoneGraphKeys[]).forEach((key) => {
          // @ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type TQuetoneGraph = Instance<typeof QuetoneGraph>;
type TQuetoneGraphSnapshot = SnapshotOut<typeof QuetoneGraph>

export interface IQuetoneGraph extends TQuetoneGraph {}
export type TQuetoneGraphKeys = keyof TQuetoneGraphSnapshot & string;
export interface IQuetoneGraphSnapshot extends TQuetoneGraphSnapshot {}
export const createQuetoneGraph = () => 
  types.optional(QuetoneGraph, {
      intervalPrediction: 0,
      anomalyYn: false,
  } as TQuetoneGraph);
