import { ApiResponse } from 'apisauce';
import {
  Api,
  GetDashboardCountResult,
  GetDashboardDataResult,
  GetGenderCountResult,
  getGeneralApiProblem,
} from '../../services';

export class ChartApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  /** 연령 성별 수집 현황을 가져오는 API입니다.
   * @param year
   * @param businessCode
   * @param coopBank
   */
  async getPersonCount(
    year: any,
    businessCode: any,
    coopBank: any,
  ): Promise<GetDashboardCountResult> {
    try {
      const SUB_URL = '/dashboard/person/cnt';
      const sendData = {
        biobank: coopBank,
        businessCode: businessCode,
        businessSeq: '',
        businessStartYear: year,
      };
      const response: ApiResponse<any> = await this.api.apisauce.post(SUB_URL, sendData);
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      return {
        kind: 'ok',
        count: response.data.responseData,
      };
    } catch (e) {
      return { kind: 'notOk' };
    }
  }

  /** 접수 건수를 가져오는 API입니다.
   * @param year
   * @param businessCode
   * @param coopBank
   */
  async getRegistrationCount(
    year: any,
    businessCode: any,
    coopBank: any,
  ): Promise<GetDashboardCountResult> {
    try {
      const SUB_URL = '/dashboard/registration/cnt';
      const sendData = {
        biobank: coopBank,
        businessCode: businessCode,
        businessSeq: '',
        businessStartYear: year,
      };
      const response: ApiResponse<any> = await this.api.apisauce.post(SUB_URL, sendData);
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      return {
        kind: 'ok',
        count: response.data.responseData,
      };
    } catch (e) {
      return { kind: 'notOk' };
    }
  }

  /** 성별 비율을 가져오는 API입니다.
   * @param year
   * @param businessCode
   * @param coopBank
   */
  async getGenderCount(year: any, businessCode: any, coopBank: any): Promise<GetGenderCountResult> {
    try {
      const SUB_URL = '/dashboard/gender/cnt';
      const sendData = {
        biobank: coopBank,
        businessCode: businessCode,
        businessSeq: '',
        businessStartYear: year,
      };

      const response: ApiResponse<any> = await this.api.apisauce.post(SUB_URL, sendData);
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      return {
        kind: 'ok',
        registrationMaleCount: response.data.responseData[0].value,
        patientMaleCount: response.data.responseData[0].pvalue,
        registrationFemaleCount: response.data.responseData[1].value,
        patientFemaleCount: response.data.responseData[1].pvalue,
      };
    } catch (e) {
      return { kind: 'notOk' };
    }
  }

  /** 질환별 수집 현황을 가져오는 API입니다.
   * @param year
   * @param businessCode
   * @param coopBank
   */
  async getRegistrationDxCount(
    year: any,
    businessCode: any,
    coopBank: any,
  ): Promise<GetDashboardDataResult> {
    try {
      const SUB_URL = '/dashboard/registration/cnt/dx';
      const sendData = {
        biobank: coopBank,
        businessCode: businessCode,
        businessSeq: '',
        businessStartYear: year,
      };

      const response: ApiResponse<any> = await this.api.apisauce.post(SUB_URL, sendData);
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      return {
        kind: 'ok',
        resultList: response.data.responseData,
      };
    } catch (e) {
      return { kind: 'notOk' };
    }
  }

  /** 사업별 수집 현황을 가져오는 API입니다.
   * @param year
   * @param businessCode
   * @param coopBank
   */
  async getRegistrationBcmCount(
    year: any,
    businessCode: any,
    coopBank: any,
  ): Promise<GetDashboardDataResult> {
    try {
      const SUB_URL = '/dashboard/registration/cnt/bcm';
      const sendData = {
        biobank: coopBank,
        businessCode: businessCode,
        businessSeq: '',
        businessStartYear: year,
      };

      const response: ApiResponse<any> = await this.api.apisauce.post(SUB_URL, sendData);
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      return {
        kind: 'ok',
        resultList: response.data.responseData,
      };
    } catch (e) {
      return { kind: 'notOk' };
    }
  }

  /** 전체 은행별 수집 현황을 가져오는 API입니다.
   * @param year
   * @param businessCode
   * @param coopBank
   */
  async getBankCategory(
    year: any,
    businessCode: any,
    coopBank: any,
  ): Promise<GetDashboardDataResult> {
    try {
      const SUB_URL = '/dashboard/registration/cnt/total/bank';
      const sendData = {
        biobank: coopBank,
        businessCode: businessCode,
        businessSeq: '',
        businessStartYear: year,
      };

      const response: ApiResponse<any> = await this.api.apisauce.post(SUB_URL, sendData);
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      return {
        kind: 'ok',
        resultList: response.data.responseData,
      };
    } catch (e) {
      return { kind: 'notOk' };
    }
  }

  /** 연령 성별 수집 현황을 가져오는 API입니다.
   * @param year
   * @param businessCode
   * @param coopBank
   */
  async getAgeAndGender(
    year: any,
    businessCode: any,
    coopBank: any,
  ): Promise<GetDashboardDataResult> {
    try {
      const SUB_URL = '/dashboard/registration/cnt/age/gender';
      const sendData = {
        biobank: coopBank,
        businessCode: businessCode,
        businessSeq: '',
        businessStartYear: year,
      };

      const response: ApiResponse<any> = await this.api.apisauce.post(SUB_URL, sendData);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      return {
        kind: 'ok',
        resultList: response.data.responseData,
      };
    } catch (e) {
      return { kind: 'notOk' };
    }
  }

  /** 질환별 성별 현황을 가져오는 API입니다.
   * @param year
   * @param businessCode
   * @param coopBank
   */
  async getGnederDxCategory(
    year: any,
    businessCode: any,
    coopBank: any,
  ): Promise<GetDashboardDataResult> {
    try {
      const SUB_URL = '/dashboard/registration/cnt/dx/gender';
      const sendData = {
        biobank: coopBank,
        businessCode: businessCode,
        businessSeq: '',
        businessStartYear: year,
      };
      const response: ApiResponse<any> = await this.api.apisauce.post(SUB_URL, sendData);
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      return {
        kind: 'ok',
        resultList: response.data.responseData,
      };
    } catch (e) {
      return { kind: 'notOk' };
    }
  }
}
