import { ApiResponse } from 'apisauce';
import { createTopology, INode, ITopology, ITopologySnapshotOut } from '../../models';
import { PersistenceState } from '../../models/topology/types';
import { CaiApiOkResponseData, CaiApiResponseData, getGeneralApiProblem } from '../api/api-problem';
import { TDeleteResult, TGetResult, TGetsResult, TPostResult, TPutResult } from '../api/api-result';
import { IResponseSnapshot } from './../../models/response/Response';
import { Api } from './../api/api';
import { TGetTopologyResult, TGetTopologiesResult } from './TopologyTypes';

/**
 * # Topology Api
 *
 * 토폴로지 정보 CRUD
 *
 * ## 사용방법
 *
 * ```ts
 * const api = new TopologyApi();
 * api.gets();
 * api.get(1);
 * api.post({});
 * api.put({});
 * api.delete(1)
 * ```
 */
export class TopologyApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  async getTopologies(page: number, size: number): Promise<TGetTopologiesResult> {
    try {
      const url: string = `/topology`;
      const param = {
        size: size,
        page: page,
      };
      const response: ApiResponse<any> = await this.api.apisauce.get(url, param);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;

      if (responseInfo.resultCode === 'S') {
        let topologies: string[] = [];
        response.data.data.forEach((element: any) => {
          topologies.push(JSON.stringify(element));
        });
        return {
          kind: 'ok',
          responseInfo: responseInfo,
          topologies: topologies,
          pagination: response.data.pagination,
        };
      }

      return { kind: 'ok', responseInfo: responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async getChannelTopology(chSvcId: number): Promise<TGetTopologyResult> {
    try {
      const url: string = `/channel/${chSvcId}/topology`;
      const response: ApiResponse<any> = await this.api.apisauce.get(url);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;

      if (responseInfo.resultCode === 'S') {
        return {
          kind: 'ok',
          responseInfo: responseInfo,
          topology: JSON.stringify(response.data.data),
        };
      }
      return { kind: 'ok', responseInfo: responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async getTopology(id: string): Promise<TGetTopologyResult> {
    try {
      const url: string = `/topology/${id}`;
      const response: ApiResponse<any> = await this.api.apisauce.get(url);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;

      if (responseInfo.resultCode === 'S') {
        return {
          kind: 'ok',
          responseInfo: responseInfo,
          topology: JSON.stringify(response.data.data),
        };
      }
      return { kind: 'ok', responseInfo: responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async insertTopology(topology: string): Promise<TGetTopologyResult> {
    try {
      const url: string = `/topology`;
      const response: ApiResponse<any> = await this.api.apisauce.post(url, topology);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;

      if (responseInfo.resultCode === 'S') {
        return {
          kind: 'ok',
          responseInfo: responseInfo,
          topology: JSON.stringify(response.data.data),
        };
      }

      return { kind: 'ok', responseInfo: responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async updateTopology(topology: string): Promise<TGetTopologyResult> {
    try {
      const url: string = `/topology/${JSON.parse(topology).id}`;
      const response: ApiResponse<any> = await this.api.apisauce.put(url, topology);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;

      if (responseInfo.resultCode === 'S') {
        return {
          kind: 'ok',
          responseInfo: responseInfo,
          topology: JSON.stringify(response.data.data),
        };
      }
      return { kind: 'ok', responseInfo: responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async deleteTopology(id: string): Promise<TGetTopologyResult> {
    try {
      const url: string = `/topology/${id}`;
      const response: ApiResponse<any> = await this.api.apisauce.delete(url);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;

      return {
        kind: 'ok',
        responseInfo: responseInfo,
      };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  /**
   * 토폴로지 목록 조회
   *
   * @returns IGettopologyResult
   */
  async gets(): Promise<TGetsResult<ITopology>> {
    try {
      const url = '/topology';
      const params = {};
      const response: ApiResponse<CaiApiResponseData<ITopology>> = await this.api.apisauce.get(
        url,
        params,
      );

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) {
          return problem;
        }
      }

      const result = response.data as CaiApiOkResponseData<ITopology>;
      return {
        kind: 'ok',
        data: result.data as ITopology[],
        pagenation: result.pagenation,
      };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  /**
   * 토폴로지 상세 조회
   *
   * @param id Topology 테이블의 ID 컬럼값
   * @returns IGetTopologyResult
   */
  async get(id: string): Promise<TGetResult<ITopology>> {
    try {
      const url = `/topology/${id}`;
      const params = {};
      const response: ApiResponse<CaiApiResponseData<ITopology>> = await this.api.apisauce.get(
        url,
        params,
      );
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) {
          return problem;
        }
      }

      const data = (response.data as CaiApiOkResponseData<ITopology>).data as ITopology;

      // console.log('API SERVICE data', data);
      // 배열을 맵으로 변환
      // id를 사용하여 데이터에 접근하기 위해서
      // const nodes: { [key: string]: INode } = {};
      // data.nodes.forEach((node: INode) => {
      //   nodes[node.id] = node;
      // });

      const topology = createTopology({
        id: data.id,
        name: data.name,
        nodes: data.nodes as any,
        links: data.links as any[],
        userData: data.userData,
        state: PersistenceState.CLEAN,
        readonly: data.readonly,
        published: data.published,
      });

      // console.log('TOPOLOGY DETAIL', { ...topology });

      return { kind: 'ok', data: topology };
    } catch (e) {
      console.error(e);
      return { kind: 'bad-data' };
    }
  }

  /**
   * 새로운 토폴로지를 등록
   * 등록된 결과에는 Topology 테이블의 ID컬럼값이 포함되고 상태는 retrieve가 된다.
   * @param payload ITopologySnapshot
   * @returns IPostTopologyResult
   */
  async post(payload: ITopologySnapshotOut): Promise<TPostResult<ITopology>> {
    try {
      const url = '/topology';
      const response: ApiResponse<CaiApiResponseData<ITopology>> = await this.api.apisauce.post(
        url,
        payload,
      );

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) {
          return problem;
        }
      }

      const topology: ITopology = (response.data as CaiApiOkResponseData<ITopology>)
        ?.data as ITopology;
      return { kind: 'ok', data: topology };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  /**
   * 토폴로지 수정
   *
   * @param payload
   * @returns
   */
  async put(payload: ITopologySnapshotOut): Promise<TPutResult<ITopology>> {
    try {
      const url = `/topology/${payload.id}`;
      const response: ApiResponse<CaiApiResponseData<ITopology>> = await this.api.apisauce.put(
        url,
        payload,
      );

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) {
          return problem;
        }
      }

      const topology: ITopology = (response.data as CaiApiOkResponseData<ITopology>)
        ?.data as ITopology;
      return { kind: 'ok', data: topology };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async delete(id: string | string[]): Promise<TDeleteResult<ITopology>> {
    try {
      const url = `/topology` + (typeof id === 'string' ? `/${id}` : `?id=${id.join(',')}`);
      const response: ApiResponse<CaiApiResponseData<ITopology>> = await this.api.apisauce.delete(
        url,
      );

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) {
          return problem;
        }
      }

      return { kind: 'ok' };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  /**
   * 토폴로지 차트 데이터 조회
   *
   * @param type
   */
  async getChartData(type: string): Promise<any> {
    try {
      let url = '/oneview/';
      switch (type) {
        case 'equipment':
          url += 'equip/status';
          break;
        case 'porttraffic':
          url += 'porttraffic';
          break;
        case 'channel':
          url += 'channel/group';
          break;
      }
      const params = {};
      const response: ApiResponse<CaiApiResponseData<any>> = await this.api.apisauce.get(
        url,
        params,
      );
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) {
          return problem;
        }
      }

      // console.log('response.data', response.data);

      const data = response.data;

      return { kind: 'ok', data: data };
    } catch (e) {
      console.error(e);
      return { kind: 'bad-data' };
    }
  }
}
