import { Instance, SnapshotOut, types } from 'mobx-state-tree';

/**
 * # ChangeValue
 *
 * ChangeValue을 설명하세요.
 */
export const ChangeValue = types
  .model('ChangeValue')
  // --------------------------------------------------------------------------
  .props({
    name: types.optional(types.maybeNull(types.string), null),
    oldValue: types.optional(types.maybeNull(types.string), null),
    newValue: types.optional(types.maybeNull(types.string), null),
  })
  // --------------------------------------------------------------------------
  // MUTATEs ONLY! - 모델 상태를 변경
  // REQUESTs는 스토어 모델에 작성하세요.
  // ex) yarn gen model changeValue --store
  .actions((self) => ({
    /*
     * 여러 prop을 한번에 수정
     */
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as IChangeValue;
      (Object.keys(newProps) as TChangeValueKeys[]).forEach((key) => {
        self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type TChangeValue = Instance<typeof ChangeValue>;
type TChangeValueSnapshot = SnapshotOut<typeof ChangeValue>;

export interface IChangeValue extends TChangeValue {}
export type TChangeValueKeys = keyof TChangeValueSnapshot & string;
export interface IChangeValueSnapshot extends TChangeValueSnapshot {}
export const createChangeValue = () =>
  types.optional(ChangeValue, {
    name: null,
    oldValue: null,
    newValue: null,
  } as TChangeValue);
