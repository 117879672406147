import { Instance, SnapshotOut, types } from 'mobx-state-tree';

/**
 * # SvcSttus
 *
 * SvcSttus을 설명하세요.
 */
export const SvcSttus = types
  .model('SvcSttus')
  .props({
    code: types.optional(types.number, 0),
    value: types.optional(types.string, ''),
  })
  .actions((self) => ({
    resetSvcSttus: () => {
      self.code = 0;
      self.value = '';
    },
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as ISvcSttus;
      (Object.keys(newProps) as TSvcSttusKeys[]).forEach((key) => {
        //@ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

type TSvcSttus = Instance<typeof SvcSttus>;
type TSvcSttusSnapshot = SnapshotOut<typeof SvcSttus>;

export interface ISvcSttus extends TSvcSttus {}
export type TSvcSttusKeys = keyof TSvcSttusSnapshot & string;
export interface ISvcSttusSnapshot extends TSvcSttusSnapshot {}
export const createSvcSttus = () =>
  types.optional(SvcSttus, {
    code: 0,
    value: '',
  } as TSvcSttus);
