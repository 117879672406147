import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { createModelCode } from '../model-code/ModelCode';
import { createSimpleUser } from '../simple-user/SimpleUser';

export const Stb = types
  .model('Stb')
  .props({
    said: types.optional(types.string, ''),
    stbMo: types.optional(types.string, ''),
    helthySttus: createModelCode(),
    ip: types.optional(types.maybeNull(types.string),''),
    mac: types.optional(types.maybeNull(types.string), ''),
    ctg: createModelCode(),
    nttp: types.optional(types.maybeNull(types.string), ''),
    lo: createModelCode(),
    delYn: types.optional(types.boolean, false),
    opnDt: types.optional(types.string, ''),
    regDt: types.optional(types.string, ''),
    regUser: types.maybeNull(createSimpleUser()),
    updDt: types.optional(types.string, ''),
    updUser: types.maybeNull(createSimpleUser()),
    rmark: types.optional(types.maybeNull(types.string), ''),
    saidDuplicate: types.optional(types.maybeNull(types.boolean), false),
    simulatorName: types.optional(types.string, ''),
    errMsg: types.optional(types.string, ''),
    eventZapYn: types.optional(types.maybeNull(types.boolean), false),
  })
  .actions((self) => ({
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as IStb;
      (Object.keys(newProps) as TStbKeys[]).forEach((key) => {
        //@ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type TStb = Instance<typeof Stb>;
type TStbSnapshot = SnapshotOut<typeof Stb>;

export interface IStb extends TStb {}
export type TStbKeys = keyof TStbSnapshot & string;
export interface IStbSnapshot extends TStbSnapshot {}
export const createStb = () =>
  types.optional(Stb, {
    said: '',
    stbMo: '',
    mac: '',
    delYn: false,
  } as TStb);
