import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function ControlLabel(theme: Theme) {
  return {
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          ...theme.typography.body2,
        },
        root: {
          paddingTop: '0 !important',
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          // position: 'absolute',
          // right: 0,
          // bottom: -20,
          display: 'block',
          width: '100%',
          marginTop: theme.spacing(1),
          marginRight: 0,
          marginLeft: 0,
          '&.Mui-error': {
            fontSize: '0.8rem',
            textAlign: 'right',
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: theme.palette.text.secondary,
          fontSize: theme.typography.pxToRem(14)
        },
      },
    },
  };
}
