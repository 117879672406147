import { destroy, Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree';
import { NodeApi } from '../../services';
import { TGetsResult } from '../../services/api/api-result';
import { withEnvironment } from '../extensions/with-environment';
import { withRootStore } from '../extensions/with-root-store';
import { INode, INodeSnapshotIn, Node } from './Node';

/**
 * # NodeStore
 *
 * NodeStore을 설명하세요.
 */
export const NodeStore = types
  .model('NodeStore')
  // --------------------------------------------------------------------------
  .props({
    nodes: types.optional(types.map(Node), {}),
  })
  .extend(withRootStore)
  .extend(withEnvironment)
  // eslint-disable-line @typescript-eslint/no-unused-vars
  .views((self) => ({
    find: (id: string) => self.nodes.get(id),
  }))
  // --------------------------------------------------------------------------
  // MUTATEs - 모델 상태를 변경
  .actions((self) => ({
    /**
     * Nodes을 교체
     *
     * @param nodes 새로운 모델의 배열
     */
    replace: (nodes: (INode | INodeSnapshotIn)[]) => {
      self.nodes.clear();
      nodes.forEach((n) => {
        self.nodes.set(n.id, n);
      });
    },

    /**
     * 노드 추가
     *
     * @param node INodeSnapshotIn
     */
    add: (node: INodeSnapshotIn) => {
      self.nodes.put(node);
    },

    /**
     * 노드 수정
     *
     * @param node INodeSnapshotIn
     */
    set: (node: INodeSnapshotIn) => {
      self.nodes.set(node.id, node);
    },

    /**
     * 노드 삭제
     *
     * @param node INodeSnapshotIn
     */
    remove: (node: INode | INodeSnapshotIn | string) => {
      // self.nodes.delete(typeof node === 'string' ? node : node.id);
      if (typeof node === 'string') {
        destroy(self.nodes.get(node));
      } else {
        destroy(self.nodes.get(node.id));
      }
    },
  }))
  // --------------------------------------------------------------------------
  // REQUESTs - 서비스 요청 및 기타 인터페이스 요청
  .actions((self) => ({
    /**
     * 전체 목록을 Api를 통해 조회
     *
     * 조회한 결과로 Nodes를 교체한다. 실패시 에러 로그를 남긴다.
     */
    getNodes: async () => {
      const nodeApi: NodeApi = new NodeApi(self.environment.api);
      const result: TGetsResult<INode> = await nodeApi.gets();
      if (result.kind === 'ok') {
        self.replace(result.data);
      } else {
        console.error(result.kind);
      }
    },
  }));

// --------------------------------------------------------------------------
type TNodeStore = Instance<typeof NodeStore>;
type TNodeStoreSnapshotIn = SnapshotIn<typeof NodeStore>;
type TNodeStoreSnapshotOut = SnapshotOut<typeof NodeStore>;

export interface INodeStore extends TNodeStore {}
export type TNodeStoreKeys = keyof TNodeStoreSnapshotOut & string;
export interface INodeStoreSnapshotIn extends TNodeStoreSnapshotIn {}
export interface INodeStoreSnapshotOut extends TNodeStoreSnapshotOut {}
export const createNodeStore = (args?: INodeStoreSnapshotIn) =>
  NodeStore.create({
    nodes: {},
    ...args,
  });
