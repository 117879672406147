import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree';
import { mstCustomTypes, PersistenceState } from './types';
import { THREE, UUID } from 'network-diagram';

/**
 * # Node
 *
 * 네트워크 엘리먼트의 데이터 및 상태를 관리합니다.
 */
export const Node = types
  .model('Node')
  // --------------------------------------------------------------------------
  .props({
    id: types.identifier,
    name: types.string,
    /**
     * "Encoder" | "MUX" | "L3" | "Transcoder" | "Unknown" | "IPAcoMux" | "Transmission" | "MSPP" | "Server" | "Satellite" | "Rectangle" | "Nameplate" | "Text" | "Cloud" | "Typography" | "Ellipse" | "Plane"
     */
    type: types.optional(mstCustomTypes.nodeType(), 'Unknown'),
    /** Vector3Tuple */
    size: types.optional(mstCustomTypes.size(), [1, 1, 1]),
    position: types.optional(mstCustomTypes.position(), [0, 0.5, 0]),
    rotation: types.optional(mstCustomTypes.rotation(), [0, 0, 0]),
    color: types.optional(mstCustomTypes.color(), '#000000'),
    userData: mstCustomTypes.userData(),
    state: types.optional(mstCustomTypes.state(), PersistenceState.CLEAN),
    showLabel: types.maybe(types.boolean),
    group: types.optional(types.boolean, false),
  })
  // --------------------------------------------------------------------------
  // MUTATEs ONLY! - 모델 상태를 변경
  // REQUESTs는 스토어 모델에 작성하세요.
  // ex) yarn gen model Node --store
  .actions((self) => ({
    setId: (_id: string) => {
      throw new Error('ID는 수정할 수 없습니다.');
    },
    setName: (name: string) => {
      self.name = name;
      self.state = PersistenceState.DIRTY;
    },
    setType: (type: string) => {
      self.type = type;
      self.state = PersistenceState.DIRTY;
    },
    setSize: (size: THREE.Vector3Tuple) => {
      self.size = size;
      self.state = PersistenceState.DIRTY;
    },
    setPosition: (position: THREE.Vector3Tuple) => {
      self.position = position;
      self.state = PersistenceState.DIRTY;
    },
    setRotation: (rotation: THREE.Vector3Tuple) => {
      self.rotation = rotation;
      self.state = PersistenceState.DIRTY;
    },
    setColor: (color: THREE.ColorRepresentation) => {
      self.color = color;
      self.state = PersistenceState.DIRTY;
    },
    setUserData: (data: any) => {
      self.userData = data;
      self.state = PersistenceState.DIRTY;
    },
    setState: (state: PersistenceState) => {
      self.state = state;
      self.state = PersistenceState.DIRTY;
    },
  }));

// --------------------------------------------------------------------------
type TNode = Instance<typeof Node>;
type TNodeSnapshotOut = SnapshotOut<typeof Node>;
type TNodeSnapshotIn = SnapshotIn<typeof Node>;

export interface INode extends TNode {}
export type TNodeKeys = keyof TNodeSnapshotOut & string;
export interface INodeSnapshotOut extends TNodeSnapshotOut {}
export interface INodeSnapshotIn extends TNodeSnapshotIn {}
export const createNode = (args?: INodeSnapshotIn) =>
  Node.create({
    id: UUID.generate(),
    name: 'Undefined',
    state: 'NEW',
    ...args,
  });
