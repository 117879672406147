// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { Stack, TextField, TextFieldProps, Typography } from '@mui/material';
import { SearchLabelStyle, LabelStyle } from '../settings/SettingStyle';

// ----------------------------------------------------------------------

interface IProps {
  name: string;
  label?: string;
}

export default function RHFTextField({ name, label, ...other }: IProps & TextFieldProps) {
  const { control } = useFormContext();

  return (
    <Stack direction="column" justifyContent="flex-start" spacing={1}>
      {label && <Typography sx={LabelStyle}> {label} </Typography>}
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            fullWidth
            size="small"
            error={!!error}
            helperText={error?.message}
            {...other}
          />
        )}
      />
    </Stack>
  );
}
