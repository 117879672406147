import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { IUsrgrp } from '../../models';
import {
  TDeleteUsrgrpResult,
  TDeleteUsrgrpsResult,
  TDuplicateGroupNameResult,
  TGetUsrgrpResult,
  TGetUsrgrpsResult,
} from '../../services';
import { timestampToDate } from '../../utils/dateExchanger';
import { withEnvironment } from '../extensions/with-environment';
import { createPagination } from '../pagination/Pagination';
import { UsrgrpApi } from './../../services/usrgrp/Usrgrp';
import { TPostUsrgrpResult, TUpdateUsrgrpResult } from './../../services/usrgrp/UsrgrpTypes';
import { withRootStore } from './../extensions/with-root-store';
import { IPagination, IPaginationSnapshot } from './../pagination/Pagination';
import { createUsrgrp, IUsrgrpSnapshot } from './../usrgrp/Usrgrp';

export const UsrgrpStore = types
  .model('UsrgrpStore')
  .props({
    usrgrps: types.optional(types.array(createUsrgrp()), []),
    selectedUsrgrp: createUsrgrp(),
    addUsrgrp: createUsrgrp(),
    updateUsrgrp: createUsrgrp(),
    deleteUsrgrpList: types.optional(types.array(types.number), []),
    pagination: createPagination(),
    usrgrpSearchFilter: createUsrgrp(),
    checkDuplicateGroupName: types.optional(types.boolean, false),
  })
  .extend(withEnvironment)
  .extend(withRootStore)
  .views((self) => ({}))
  .actions((self) => ({
    setUsrgrps: (usrgrps: IUsrgrpSnapshot[]) => {
      self.usrgrps.replace(usrgrps as IUsrgrp[]);
    },
    setPagination: (pagination: IPaginationSnapshot) => {
      self.pagination = pagination as IPagination;
    },
    setAddUsrgrp: (usrgrp: IUsrgrpSnapshot) => {
      self.addUsrgrp = usrgrp as IUsrgrp;
    },
    setUpdateUsrgrp: (usrgrp: IUsrgrpSnapshot) => {
      self.updateUsrgrp = usrgrp as IUsrgrp;
    },
    setSelectedUsrgrp: (usrgrp: IUsrgrpSnapshot) => {
      self.selectedUsrgrp = usrgrp as IUsrgrp;
    },
    setDeleteUsrgrpList: (deleteUsrgrpList: number[]) => {
      self.deleteUsrgrpList.replace(deleteUsrgrpList);
    },
    appendDeleteUsrgrp: (usrgrpSid: number) => {
      self.deleteUsrgrpList.push(usrgrpSid);
    },
    setUsrgrpSearchFilter: (usrgrpFilter: TUsrgrpFilter) => {
      if (!usrgrpFilter.usrgrpNm) {
        usrgrpFilter.usrgrpNm = '';
      }

      if (!usrgrpFilter.usrgrpSid) {
        usrgrpFilter.usrgrpSid = 0;
      }

      self.usrgrpSearchFilter = usrgrpFilter as IUsrgrp;
    },
    setCheckDuplicateGroupName: (check: boolean) => {
      self.checkDuplicateGroupName = check;
    },
  }))
  .actions((self) => ({
    /**
     * INF_CAIMS_00170
     * 그룹의 전체 목록을 조회한다.
     * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=843258254
     * @param usrgrpFilter
     */
    gets: async (usrgrpFilter?: TUsrgrpFilter) => {
      self.setUsrgrps([]);
      try {
        const usrgrpApi: UsrgrpApi = new UsrgrpApi(self.environment.api);

        let result: TGetUsrgrpsResult;
        if (usrgrpFilter) {
          result = await usrgrpApi.gets(self.pagination, usrgrpFilter);
        } else {
          result = await usrgrpApi.gets(self.pagination);
        }

        if (
          self.rootStore.responseStore.getResponseResult(result) &&
          result.pagination &&
          result.pagination.page === self.pagination.page
        ) {
          if (result.usrgrps) {
            result.usrgrps.forEach((item: IUsrgrpSnapshot) => {
              item.regDt = timestampToDate(Number.parseInt(item.regDt));
              item.updDt = timestampToDate(Number.parseInt(item.updDt));
            });
            self.setUsrgrps(result.usrgrps);
          }
          if (result.pagination) {
            self.setPagination(result.pagination);
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },
    /**
     * INF_CAIMS_00171
     * 해당 그룹위 상세정보를 조회한다.
     * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=742363380
     * @param usrgrpSid
     */
    get: async (usrgrpSid: number) => {
      try {
        const usrgrpApi: UsrgrpApi = new UsrgrpApi(self.environment.api);
        const result: TGetUsrgrpResult = await usrgrpApi.get(usrgrpSid);

        if (self.rootStore.responseStore.getResponseResult(result)) {
          if (result.usrgrp) {
            result.usrgrp.regDt = timestampToDate(Number.parseInt(result.usrgrp.regDt));
            result.usrgrp.updDt = timestampToDate(Number.parseInt(result.usrgrp.updDt));
            self.setSelectedUsrgrp(result.usrgrp);
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },

    /**
     * INF_CAIMS_00172
     * 새로운 그룹을 등록한다.
     * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=409941969
     * @param usrgrp
     */
    post: async (usrgrp: IUsrgrpSnapshot) => {
      try {
        const usrgrpApi: UsrgrpApi = new UsrgrpApi(self.environment.api);
        const result: TPostUsrgrpResult = await usrgrpApi.post(usrgrp);

        if (self.rootStore.responseStore.getResponseResult(result)) {
          if (result.usrgrp) {
            if (result.usrgrp) {
              result.usrgrp.regDt = timestampToDate(Number.parseInt(result.usrgrp.regDt));
              result.usrgrp.updDt = timestampToDate(Number.parseInt(result.usrgrp.updDt));
            }

            self.setAddUsrgrp(result.usrgrp);
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },
    /**
     * INF_CAIMS_00173
     * 해당 그룹의 정보를 수정한다.
     * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=1372569872
     * @param usrgrp
     */
    put: async (usrgrp: IUsrgrpSnapshot) => {
      try {
        const usrgrpApi: UsrgrpApi = new UsrgrpApi(self.environment.api);
        const result: TUpdateUsrgrpResult = await usrgrpApi.put(usrgrp);

        if (self.rootStore.responseStore.getResponseResult(result)) {
          if (result.usrgrp) {
            if (result.usrgrp) {
              result.usrgrp.regDt = timestampToDate(Number.parseInt(result.usrgrp.regDt));
              result.usrgrp.updDt = timestampToDate(Number.parseInt(result.usrgrp.updDt));
            }

            self.setUpdateUsrgrp(result.usrgrp);
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },
    /**
     * INF_CAIMS_00174
     * 해당 그룹을 삭제한다
     * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=364314959
     * @param usrgrpSid
     */
    delete: async (usrgrpSid: number) => {
      try {
        const usrgrpApi: UsrgrpApi = new UsrgrpApi(self.environment.api);
        const result: TDeleteUsrgrpResult = await usrgrpApi.delete(usrgrpSid);
        self.rootStore.responseStore.getResponseResult(result);
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },
    /**
     * INF_CAIMS_00175
     * 여러개 그룹을 한번에 삭제한다.
     * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=924758216
     */
    deletes: async () => {
      try {
        const usrgrpApi: UsrgrpApi = new UsrgrpApi(self.environment.api);
        const result: TDeleteUsrgrpsResult = await usrgrpApi.deletes(self.deleteUsrgrpList);
        self.rootStore.responseStore.getResponseResult(result);
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },
    /**
     * INF_CAIMS_00231
     * 특정 그룹 이름이 데이터베이스에 있는지 확인한다.
     * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=755872056
     * @param usrgrpNm
     */
    checkDuplicateGroupNm: async (usrgrpNm: string) => {
      try {
        const usrgrpApi: UsrgrpApi = new UsrgrpApi(self.environment.api);
        const result: TDuplicateGroupNameResult = await usrgrpApi.checkDuplicateGroupNm(usrgrpNm);

        if (self.rootStore.responseStore.getResponseResult(result)) {
          if (result.result) {
            self.setCheckDuplicateGroupName(true);
          } else {
            self.setCheckDuplicateGroupName(false);
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },
  }));

// --------------------------------------------------------------------------
type TUsrgrpStore = Instance<typeof UsrgrpStore>;
type TUsrgrpStoreSnapshot = SnapshotOut<typeof UsrgrpStore>;

export interface IUsrgrpStore extends TUsrgrpStore {}
export type TUsrgrpStoreKeys = keyof TUsrgrpStoreSnapshot & string;
export interface IUsrgrpStoreSnapshot extends TUsrgrpStoreSnapshot {}
export const createUsrgrpStore = () => types.optional(UsrgrpStore, {} as TUsrgrpStore);

export interface IUsrgrpFilter {
  usrgrpSid?: number;
  usrgrpNm?: string;
}

export type TUsrgrpFilter = IUsrgrpFilter;
