import { createSimpleUser } from './../simple-user/SimpleUser';
import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { createModelCode, ModelCode } from '../model-code/ModelCode';
import { TrapSvrtStng } from '../trap-svrt-stng/TrapSvrtStng';
import { Trap } from '../trap/Trap';

export const SnmpRuleset = types
  .model('SnmpRuleset')
  .props({
    trapId: types.optional(types.string, ''),
    intrlkSysSid: types.optional(types.number, 0),
    trapNm: types.optional(types.string, ''),
    entprOid: types.optional(types.string, ''),
    mibNm: types.optional(types.string, ''),
    apdInfo: types.optional(types.string, ''),
    useYn: types.optional(types.boolean, false),
    eventType: types.maybeNull(createModelCode()),
    svrt: types.maybeNull(createModelCode()),
    problCas: types.optional(types.string, ''),
    sttusOid: types.optional(types.maybeNull(types.string), ''),
    spcfProb: types.optional(types.string, ''),
    regDt: types.optional(types.string, ''),
    updDt: types.optional(types.string, ''),
    msg: types.optional(types.maybeNull(types.string), ''),
    msgOid: types.optional(types.maybeNull(types.array(types.string)), []),
    //구버전
    regId: types.optional(types.number, 0),
    updId: types.optional(types.number, 0),
    //신버전
    regUser: types.maybeNull(createSimpleUser()),
    updUser: types.maybeNull(createSimpleUser()),
    sysNm: types.optional(types.string, ''),

    trapVarList: types.optional(types.maybeNull(types.array(Trap)), []),
    trapSvrtStngList: types.optional(types.maybeNull(types.array(TrapSvrtStng)), []),
    spcfProbList: types.optional(types.array(types.number), []),

    trapCount: types.optional(types.number, 0),
    trapVarCount: types.optional(types.number, 0),
    sttusVarCount: types.optional(types.number, 0),
    successFileList: types.optional(types.array(types.string), []),
    failFileList: types.optional(types.array(types.string), []),
    spcfProbVarOidList: types.optional(types.maybeNull(types.array(types.string)), []),
  })
  .actions((self) => ({
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as ISnmpRuleset;
      (Object.keys(newProps) as TSnmpRulesetKeys[]).forEach((key) => {
        //@ts-ignore
        self[key] = newProps[key];
      });
    },
    resetSnmpRuleset: () => {
      self.trapId = '';
      self.intrlkSysSid = 0;
      self.trapNm = '';
      self.entprOid = '';
      self.mibNm = '';
      self.apdInfo = '';
      self.useYn = false;
      self.problCas = '';
      self.sttusOid = '';
      self.spcfProb = '';
      self.regDt = '';
      self.regId = 0;
      self.updDt = '';
      self.updId = 0;
      self.sysNm = '';
      self.msg = '';
      self.msgOid?.replace([] as string[]);
      self.eventType = ModelCode.create({
        code: 0,
        pcode: 0,
        value: '',
      });
      self.svrt = ModelCode.create({
        code: 0,
        pcode: 0,
        value: '',
      });
      self.trapCount = 0;
      self.trapVarCount = 0;
      self.sttusVarCount = 0;
    },
  }));

type TSnmpRuleset = Instance<typeof SnmpRuleset>;
type TSnmpRulesetSnapshot = SnapshotOut<typeof SnmpRuleset>;

export interface ISnmpRuleset extends TSnmpRuleset {}
export type TSnmpRulesetKeys = keyof TSnmpRulesetSnapshot & string;
export interface ISnmpRulesetSnapshot extends TSnmpRulesetSnapshot {}
export const createSnmpRuleset = () =>
  types.optional(SnmpRuleset, {
    trapId: '',
    intrlkSysSid: 0,
    trapNm: '',
    entprOid: '',
    mibNm: '',
    apdInfo: '',
    useYn: false,
    problCas: '',
    sttusOid: '',
    spcfProb: '',
    regDt: '',
    regId: 0,
    updDt: '',
    updId: 0,
    sysNm: '',
    trapCount: 0,
    trapVarCount: 0,
    sttusVarCount: 0,
    msg: '',
    msgOid: [] as string [],
  } as TSnmpRuleset);
