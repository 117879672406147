import {Api, getGeneralApiProblem} from "../api";
import {ApiResponse} from "apisauce";
import {TGetOtp} from "./OtpTypes";

export class OtpApi{
    private api:Api;

    constructor(api: Api) {
        this.api = api;
    }

    async get(): Promise<boolean|string> {
        try{
            const url = "/otp/resend";
            const response: ApiResponse<any> = await this.api.apisauce.get(url);

            if(!response.ok)
            {
                const problem = getGeneralApiProblem(response);
                if(problem) return false;
            }

            if(response.data.resultCode ==='S'){
                return true;
            }
            else {
                return response.data.errorMessage;
            }

        }catch(e){
            console.error(e);
            return false;
        }
    }
}