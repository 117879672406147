import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
// hooks
import { useAuth } from '../hooks/useAuth';
// routes
import { PATH_CHANNEL_AI } from '../routes/paths';
import { observer } from 'mobx-react-lite';

// ----------------------------------------------------------------------

type GuestGuardProps = {
  children: ReactNode;
};

// TODO YJ : 사용하지 않는 것 같음. 확인후 제거 요청.
export const GuestGuard = observer(function GuestGuard({ children }: GuestGuardProps) {
  const { isAuthenticated } = useAuth();
  if (isAuthenticated) {
    return <Navigate to="/" />;
  }

  return <>{children}</>;
});
