import infoIcon from '@iconify/icons-eva/info-outline';
import errorIcon from '@iconify/icons-ic/error-outline';
import questionIcon from '@iconify/icons-ic/help-outline';
import successIcon from '@iconify/icons-ic/round-check-circle-outline';
import warningIcon from '@iconify/icons-ic/round-warning';
import { Icon, IconifyIcon } from '@iconify/react';
import { Box, Button, Card, Stack, Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { DialogAnimate } from './animate';
import CTextField from './forms/CTextField';
import { FormProvider } from './hook-form';

type CAlertProps = {
  /**
   * ** Alert 창 표시 여부 **
   */
  isAlertOpen: boolean;
  /**
   * ** Alert 창의 종류 **
   */
  alertCategory: 'success' | 'info' | 'warning' | 'error' | 'question';
  /**
   * ** Alert 창의 제목 **
   */
  alertTitle?: string;
  /**
   * ** Alert 창의 내용 **
   */
  alertContent?: string;
  /**
   * ** Alert 창의 취소버튼 표시 **
   */
  hasCancelButton?: boolean;
  /**
   * ** Alert 창 끄기 함수 **
   *
   * useState를 이용하여 콜백 함수를 구성 후 Props로 전달
   */
  handleAlertClose?: () => void;
  /**
   * ** Alert 창 Callback **
   *
   * 확인과 취소 버튼에 대한 결과를 콜백 함수로 반환
   */
  callBack?: Function;
  /**
   * ** Alert 창 삭제 물음표 **
   *
   * true 일 경우 삭제 물음표는 빨간색
   */
  deleteQ?: boolean;
  /**
   * ** 입력 TextArea key **
   */
  addTextArea?: boolean;
  textAreaLabel?: string;
  textAreaKey?: string;
};

/**
 * ## 기본 사용법
 * > 기본 Alert 창으로 경고 및 정보 등 여러 상황에 알맞은 Alert를 제공합니다.
 */
export default function CAlert({
  isAlertOpen,
  alertCategory,
  alertTitle,
  alertContent,
  hasCancelButton = false,
  handleAlertClose,
  callBack,
  deleteQ,
  addTextArea = false,
  textAreaLabel = '입력',
  textAreaKey = 'alertTextArea',
  ...others
}: CAlertProps) {
  const categoryColors = useMemo(()=>{
    return {
      error: 'rgb(255, 72, 66)',
      warning: 'rgb(255, 193, 7)',
      info: 'rgb(24, 144, 255)',
      success: 'rgb(84, 214, 44)',
      question: 'rgb(0, 171, 85)',
    }
  }, []);
  const methods = useForm({});

  const {
    reset,
    getValues,
  } = methods;

  const onSubmit = async (data: any) => {
    alert(JSON.stringify(data));
  };

  const [info, setInfo] = useState<{
    icon: IconifyIcon;
    name:
      | 'success'
      | 'info'
      | 'warning'
      | 'error'
      | 'inherit'
      | 'primary'
      | 'secondary'
      | undefined;
    color: string;
  }>({
    icon: infoIcon,
    name: 'info',
    color: categoryColors.info,
  });
  useEffect(() => {
    switch (alertCategory) {
      case 'success':
        setInfo({
          icon: successIcon,
          name: 'success',
          color: categoryColors.success,
        });
        break;
      case 'info':
        setInfo({
          icon: infoIcon,
          name: 'info',
          color: categoryColors.info,
        });
        break;
      case 'warning':
        setInfo({
          icon: warningIcon,
          name: 'warning',
          color: categoryColors.warning,
        });
        break;
      case 'error':
        setInfo({
          icon: errorIcon,
          name: 'error',
          color: categoryColors.error,
        });
        break;
      case 'question':
        if (deleteQ) {
          setInfo({
            icon: questionIcon,
            name: 'error',
            color: categoryColors.error,
          });
        } else {
          setInfo({
            icon: questionIcon,
            name: 'primary',
            color: categoryColors.question,
          });
        }
        break;
    }
  }, [alertCategory, categoryColors, deleteQ]);

  const cancelClose = () => {
    if (handleAlertClose) {
      handleAlertClose();
    }
  };

  const confirmClose = () => {
    if (handleAlertClose) {
      handleAlertClose();
    }
    // callBack && callBack();
    if(callBack)
    {
      if(addTextArea)
      {
        callBack(getValues());
        reset();
      }
      else
      {
        callBack();
      }
    }
  };
  return (
    <DialogAnimate
      open={isAlertOpen}
      onClose={handleAlertClose}
      maxWidth={'sm'}
      sx={{ minWidth: 400, maxHeight: '80%' }}
      {...others}
    >
      <Card>
        <Stack direction="row" sx={{ justifyContent: 'center', margin: '15px 10px' }}>
          <Box component={Icon} icon={info.icon} sx={{ fontSize: '4rem', color: info.color }} />
          {alertTitle && (
          <Stack direction="row" sx={{ justifyContent: 'center', margin: '0 10px' }}>
            <Typography variant="h4" sx={{ margin: 'auto', fontWeight: '600', opacity: 0.9 }}>
              {' '}
              {alertTitle}{' '}
            </Typography>
          </Stack>
        )}
        </Stack>
        
        {alertContent && (
          <Stack direction="row" sx={{ flexDirection: 'column',justifyContent: 'center', margin: '10px auto', overflow: 'auto' }}>
            {alertContent.split('<br/>').map((value, index) => {
              return (
                <Typography
                  variant="h4"
                  key={index}
                  sx={{
                    fontSize: '1.25rem !important',
                    fontWeight: '400',
                    margin: 'auto',
                    opacity: 0.7,
                    textAlign: 'center',
                  }}
                >
                  {value}
                  <br />
                </Typography>
              );
            })}
          </Stack>
        )}
        {/* TextArea */}
        {addTextArea &&  (
          <Stack direction="row" sx={{ flexDirection: 'column',justifyContent: 'center', overflow: 'auto' }}>
            <FormProvider methods={methods}>
                <CTextField
                  label={textAreaLabel}
                  name={textAreaKey}
                  multiline
                  variant={'outlined'}
                  multilineHeight={50}
                ></CTextField>
            </FormProvider>
          </Stack>
        )}
        {!hasCancelButton && (
          <Stack
            direction="row"
            sx={{ justifyContent: 'center', padding: '10px', marginBottom: '10px' }}
          >
            <Button
              variant="contained"
              color={info.name}
              onClick={confirmClose}
              sx={{ color: '#fff' }}
            >
              확인
            </Button>
          </Stack>
        )}
        {hasCancelButton && (
          <Stack
            direction="row"
            sx={{ justifyContent: 'center', padding: '10px', marginBottom: '10px' }}
          >
            <Button variant="contained" color="inherit" onClick={cancelClose} sx={{ mr: 1.5 }}>
              취소
            </Button>
            <Button
              variant="contained"
              color={info.name}
              onClick={confirmClose}
              sx={{ color: '#fff' }}
            >
              확인
            </Button>
          </Stack>
        )}
      </Card>
    </DialogAnimate>
  );
}
