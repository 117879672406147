import { observer } from 'mobx-react-lite';
import { Controller, ControllerRenderProps, FieldValues, useFormContext } from 'react-hook-form';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import TimePicker from '@mui/lab/TimePicker';
import DateTimePicker from '@mui/lab/DateTimePicker';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import { DatePickerStyle } from '../settings/SettingStyle';
import { DateTimePickerView } from '@mui/lab/DateTimePicker/shared';
import { isEmptyArray } from 'formik';
import { ko } from 'date-fns/esm/locale';

type CDateAndTimeProps = {
  /**
   * ** DateAndTimePicekr 이름 **
   */
  name: string;
  /**
   * ** DateAndTimePicekr 라벨**
   */
  label?: string;
  /**
   * ** DateAndTimePicekr 포맷 종류 **
   *
   * 선택한 포맷에 따른 형태로 시간 포맷이 변환됩니다.
   *
   */
  format:
    | 'yyyy-MM'
    | 'yyyy-MM-dd'
    | 'yyyy-MM-dd HH:mm'
    | 'dd/MM/yyyy HH:mm'
    | 'MM/dd/yyyy HH:mm'
    | 'yyyy.MM.dd HH:mm'
    | 'HH:mm';
  /**
   * ** DateAndTimePicekr  Pciker 종류 **
   *
   * 선택한 종류의 Picker로 변환됩니다.
   */
  type: 'Date' | 'Date-mobile' | 'Time' | 'DateAndTime';
  /**
   * ** 수정금지 여부 **
   */
  readonly?: boolean;
  /**
   * ** 끝 날짜 여부 **
   */
  isEnd?: boolean;

  disabled?: boolean;
  /**
   * **Enter 키 Callback 함수**
   */
  onEnterCallback?: Function;
  /**
   * Search할 때 DatePicker?
   */
  search?: boolean;
  /**
   * onChange callback 함수
   */
  onChangeCallback?: Function;
  /**
   * Views 보여주는 단계 (DateTimePicker에서만 사용)
   */
  viewDepth?: 'year' | 'month' | 'day' | 'hours' | 'minutes';
};
/**
 * ## 기본 사용법
 * > 기본 DateAndTimePicker로 월 및 시간을 Calendar을 통해 설정할 수 있습니다.
 */

export default observer(function CDateAndTimePicker({
  name,
  label,
  format,
  type,
  readonly,
  isEnd,
  onEnterCallback,
  search = false,
  onChangeCallback,
  viewDepth,
  disabled,
  ...other
}: CDateAndTimeProps) {
  const { control } = useFormContext();

  // 기본 속성 세팅
  // 1. 포맷
  if (format === undefined) {
    format = 'yyyy-MM-dd HH:mm';
  }

  // Picker Text Field React.Node.
  const pickerTextField = (params: JSX.IntrinsicAttributes & TextFieldProps, error: any) => {
    return (
      <TextField
        sx={DatePickerStyle}
        size="small"
        variant={'standard'}
        onKeyUp={(e) => {
          if (e.keyCode === 13) {
            onEnterCallback && onEnterCallback();
          }
        }}
        error={!!error}
        helperText={error?.message}
        {...params}
      />
    );
  };
  const onChangePicker = (field: ControllerRenderProps<FieldValues, string>, data: any) => {
    field.onChange(data);
    onChangeCallback && onChangeCallback();
  };

  const typeResult = (
    type: string,
    field: ControllerRenderProps<FieldValues, string>,
    error: any,
  ) => {
    if (type === 'Date') {
      return (
        <DesktopDatePicker
          label={label}
          disabled={disabled}
          inputFormat={search ? format.substring(0, 10) : 'yyyy-MM-dd HH:mm:ss'}
          InputAdornmentProps={{ sx: { pr: 1 } }}
          value={field.value ? field.value : null}
          onChange={(data) => {
            onChangePicker(field, data);
          }}
          readOnly={readonly}
          PopperProps={{ placement: 'bottom-end' }}
          mask={search ? '____-__-__' : '____-__-__ __:__:__'}
          renderInput={(params) => {
            params.error = !!error;
            return pickerTextField(params, error);
          }}
          {...other}
        />
      );
    } else if (type === 'Date-mobile') {
      return (
        <MobileDatePicker
          label={label}
          disabled={disabled}
          inputFormat={format.substring(0, format.length - 6)}
          {...field}
          value={field.value ? field.value : null}
          onChange={(data) => {
            onChangePicker(field, data);
          }}
          readOnly={readonly}
          mask="____-__-__"
          renderInput={(params) => pickerTextField(params, error)}
          {...other}
        />
      );
    } else if (type === 'Time') {
      return (
        <TimePicker
          label={label}
          disabled={disabled}
          inputFormat={format}
          {...field}
          value={field.value ? field.value : null}
          onChange={(data) => {
            onChangePicker(field, data);
          }}
          readOnly={readonly}
          PopperProps={{ placement: 'bottom-end' }}
          mask="____-__-__"
          renderInput={(params) => pickerTextField(params, error)}
          {...other}
        />
      );
    } else {
      // 날짜 선택 설정.
      let views: DateTimePickerView[] = [];
      switch (viewDepth) {
        // @ts-ignore
        case 'minutes':
          views.push('minutes');
        break;
        // @ts-ignore
        case 'hours':
          views.push('hours');
          break;
        // @ts-ignore
        case 'day':
          views.push('day');
          break;
        // @ts-ignore
        case 'month':
          views.push('month');
          break;
        // @ts-ignore
        case 'year':
          views.push('year');
          break;
        default:
          break;
      }

      if (isEmptyArray(views)) {
        views = ['year', 'month', 'day', 'hours', 'minutes'];
      } else {
        views = views.reverse();
      }

      return (
        <DateTimePicker
          label={label}
          disabled={disabled}
          inputFormat={format}
          {...field}
          value={field.value ? field.value : null}
          onChange={(data) => {
            onChangePicker(field, data);
          }}
          readOnly={readonly}
          PopperProps={{ placement: 'bottom-end' }}
          mask="____-__-__ __:__"
          renderInput={(params) => pickerTextField(params, error)}
          views={views}
          {...other}
        />
      );
    }
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={ko}>
          {typeResult(type, field, error)}
        </LocalizationProvider>
      )}
    />
  );
});
