import { ApiResponse } from 'apisauce';
import { ILink } from '../../models/topology/Link';
import { Api } from '../api/api';
import { CaiApiOkResponseData, CaiApiResponseData, getGeneralApiProblem } from '../api/api-problem';
import { TDeleteResult, TGetResult, TGetsResult, TPostResult, TPutResult } from '../api/api-result';

/**
 * # Link Api 서비스
 *
 * 토폴로지 상 노드의 연결정보
 *
 * ## 사용방법
 *
 * ```ts
 * const api = new LinkApi();
 * api.gets();
 * api.get(1);
 * api.post({id: '', name: '', ... });
 * api.put({id: '', name: '', ... });
 * api.delete(1);
 * ```
 */
export class LinkApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  async gets(params?: { [key: string]: any }): Promise<TGetsResult<ILink>> {
    try {
      const url = '/link';
      const response: ApiResponse<CaiApiResponseData<ILink>> = await this.api.apisauce.get(
        url,
        params,
      );

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) {
          return problem;
        }
      }

      const result = response.data as CaiApiOkResponseData<ILink>;
      return {
        kind: 'ok',
        data: result.data as ILink[],
        pagenation: result.pagenation,
      };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async get(id?: string): Promise<TGetResult<ILink>> {
    try {
      const url = `/link/${id}`;
      const response: ApiResponse<CaiApiResponseData<ILink>> = await this.api.apisauce.get(url);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) {
          return problem;
        }
      }

      const link: ILink = (response.data as CaiApiOkResponseData<ILink>)?.data as ILink;
      return { kind: 'ok', data: link };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async post(payload: ILink): Promise<TPostResult<ILink>> {
    try {
      const url = '/link';
      const response: ApiResponse<CaiApiResponseData<ILink>> = await this.api.apisauce.post(
        url,
        payload,
      );

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) {
          return problem;
        }
      }

      const link: ILink = (response.data as CaiApiOkResponseData<ILink>)?.data as ILink;
      return { kind: 'ok', data: link };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async put(payload: ILink): Promise<TPutResult<ILink>> {
    try {
      const url = `/link/${payload.id}`;
      const response: ApiResponse<CaiApiResponseData<ILink>> = await this.api.apisauce.put(
        url,
        payload,
      );

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) {
          return problem;
        }
      }

      const link: ILink = (response.data as CaiApiOkResponseData<ILink>)?.data as ILink;
      return { kind: 'ok', data: link };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async delete(id: string | string[]): Promise<TDeleteResult<ILink>> {
    try {
      const url = `/link` + (typeof id === 'string' ? `/${id}` : `?id=${id.join(',')}`);
      const response: ApiResponse<CaiApiResponseData<ILink>> = await this.api.apisauce.delete(url);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) {
          return problem;
        }
      }

      return { kind: 'ok' };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }
}
