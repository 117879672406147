import { ApiResponse } from "apisauce";
import { IOneviewGaugeSnapshot, IResponseSnapshot } from "../../models";
import { Api } from "../api/api";
import { getGeneralApiProblem } from "../api/api-problem";
import { 
  TGetOneviewGaugesResult,
  TGetOneviewGaugeResult,
 } from "./OneviewGaugeTypes";

  /**
  * # Gauge Api 서비스
  * 
  * 서비스의 설명을 작성하세요.
  * 
  * ## 사용방법
  * 
  * ```ts
  * const service = new GaugeApi();
  * 
  * ```
  */
 export class GaugeApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  async gets(): Promise<TGetOneviewGaugesResult> {
    try {

      const url = '/oneview/gauge';
      const params = {};
      const response: ApiResponse<any> = await this.api.apisauce.get( url, params );
  
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;

      return { 
        kind: 'ok', 
        gauges : response.data.data,
        responseInfo: responseInfo,
      };

    } catch (e) {
      return { kind: "bad-data" };
    }

  }

  async get(id:number): Promise<TGetOneviewGaugeResult> {
    try {
      const url = `/gauge/${id}`;
      const params = {};
      const response: ApiResponse<any> = await this.api.apisauce.get( url, params );
  
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;
      const gauge: IOneviewGaugeSnapshot = response.data.data;

      if (responseInfo.resultCode === 'S') {
        return { 
          kind: "ok", 
          gauge: gauge,
          responseInfo: responseInfo
        };
      }

      return {
        kind: 'ok',
        gauge: {} as IOneviewGaugeSnapshot,
        responseInfo: responseInfo
      };

    } catch (e) {
      return { kind: "bad-data" };
    }
  }

  
}