import { useStores } from '../models';
import { observer } from 'mobx-react-lite';
import { createContext, ReactNode, useEffect } from 'react';
import { SessionContextType } from '../types/authentication';

export const AuthContext = createContext<SessionContextType | null>(null);

export const AuthProvider = observer(({ children }: { children: ReactNode }) => {
  const { authStore } = useStores();

  useEffect(() => {
    const initialize = async () => {
      try {
        if (!window.localStorage.getItem('remember')) {
          // authStore.authStatus.setAuthStatus({
          //   isAuthenticated: false,
          //   isInitialized: false,
          // });
        }
        if (window.localStorage.getItem('remember') === 'true') {
          const id = window.localStorage.getItem('id');
          const pw = window.localStorage.getItem('pw');
          if (!id) {
            return;
          }
          if (!pw) {
            return;
          }

          // login(id, pw);
        }
      } catch (error) {
        console.error(error);
        // authStore.authStatus.setIsAuthenticated(false);
        // authStore.user.reset();
      }
    };
    // initialize();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated: authStore.authStatus.isAuthenticated,
        isInitialized: authStore.authStatus.isInitialized,
        user: authStore.getLoginUserStorageData(),
        method: 'session',
      }}
    >
      {children}
    </AuthContext.Provider>
  );
});
