import { lazy, Suspense } from 'react';
import { Navigate, useLocation, useRoutes } from 'react-router-dom';
// components
import LoadingScreen from '../components/LoadingScreen';
import { AuthGuard } from '../guards/AuthGuard';
// guards
import { GuestGuard } from '../guards/GuestGuard';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
import { PATH_CHANNEL_AI } from './paths';
import QuetoneAlarmScreen from '../screens/RealtimeMonitoring/QuetoneAlarm/QuetoneAlarmScreen';
import VocAlarmScreen from '../screens/RealtimeMonitoring/VocAlarm/VocAlarmScreen';
import MultiChannelAlarmScreen from '../screens/RealtimeMonitoring/MultiChannelAlarm/MultiChannelAlarmScreen';
import DisabledPersonAlarmScreen from '../screens/RealtimeMonitoring/DisabledPersonAlarm/DisabledPersonAlarmScreen';
// ----------------------------------------------------------------------

const Loadable = (Component: any) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes('/dashboard');

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed',
            }),
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    //Auth
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
      ],
    },
    // Channel AI Routes
    {
      path: 'channel-ai',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        // 대시보드
        {
          path: 'dashboard',
          children: [
            {
              path: 'dashboard',
              element: <PageCommingSoon />,
            },
            {
              path: 'baekseok-channel',
              element: <TopologyView />,
            },
            {
              path: 'system-status',
              element: <SystemStatus />,
            },

            // {
            //   path: 'yeouido-vod',
            //   element: <PageCommingSoon />,
            // },
          ],
        },
        // 통계/리포팅
        {
          path: 'statics-reporting',
          children: [
            {
              path: 'real-time-Alarm',
              element: <RealtimeAlarm />,
            },
            {
              path: 'alarm-history',
              element: <AlarmHistory />,
            },
            {
              path: 'all-alarm-history',
              element: <AllAlarmHistory/>,
            },
            {
              path: 'alarm-analyze',
              element: <PageCommingSoon />,
            },
            {
              path: 'voc-history',
              element: <Voc />,
            },
            {
              path: 'voc-statistics',
              element: <VocStatistics />,
            },
            {
              path: 'rating-statistics',
              element: <RatingStatistics />,
            },
            {
              path: 'stb-statistics',
              element: <StbStatistics />,
            },
            {
              path: 'reporting',
              element: <Report />,
            },
            {
              path: 'quality-reporting',
              element: <QualityReport />,
            },
          ],
        },
        //부가서비스 분석
        {
          path: 'supplementary-service',
          children: [
            {
              path: 'aquetone',
              element: <Aquetone />,
            },
            {
              path: 'bquetone',
              element: <Bquetone />,
            },
            {
              path: 'dquetone',
              element: <Dquetone/>,
            },
            {
              path: 'quetone-dpcc',
              element: <QuetoneDpcc />,
            },
            {
              path: 'misTransmission',
              element: <MisTransMissionScreen/>,
            }
          ]
        },
        // 알람 설정
        {
          path: 'alarm-management',
          children: [
            {
              path: 'ruleset',
              element: <Ruleset />,
            },
            {
              path: 'snmp-ruleset',
              element: <SnmpRuleset />,
            },
            {
              path: 'stb-simulator',
              element: <StbSimulator/>,
            },
          ],
        },
        // SOP 관리
        {
          path: 'sop-kdb-management',
          children: [
            {
              path: 'sop-register-mgmt',
              element: <SopMgmt />,
            },
            {
              path: 'kdb-mgmt',
              element: <KdbMgmt />,
            },
          ],
        },
        // 형상 관리
        {
          path: 'configuration-management',
          children: [
            {
              path: 'channel-mgmt',
              element: <ChannelMgmt />,
            },
            { path: 'interlocking-system-mgmt', element: <IntrlkSysMgmt /> },
            { path: 'equipment-mgmt', element: <EquipmentMgmt /> },
            { path: 'monitoring-stb-mgmt', element: <MonitoringSTBMgmt /> },
            { path: 'topology-mgmt', element: <TopologyMgmt /> },
            { path: 'pp-company-mgmt', element: <PpCompanyMgmt /> },
          ],
        },
        // 시스템 관리
        {
          path: 'system-management',
          children: [
            {
              path: 'user-mgmt',
              element: <UserMgmt />,
            },
            {
              path: 'user-login-history',
              element: <UserLoginHistory />,
            },
            {
              path: 'group-mgmt',
              element: <GroupMgmt />,
            },
            {
              path: 'group-menu-mgmt',
              element: <GroupMenuMgmt />,
            },
            {
              path: 'api-history',
              element: <ApiHistory />,
            },
            {
              path: 'pair-Alarm-setting',
              element: <PairAlarm />,
            },
            {
              path: 'channel-code-mgmt',
              element: <ChannelCodeMgmt />,
            },
            { path: 'connection-mgmt', element: <ConnectionInfoMgmt /> },
            {
              path: 'sms-management',
              element: <Sms />,
            },
            {
              path: 'email-management',
              element: <Email />
            },
            {
              path: 'notification-management',
              element: <NotificationMgmtScreen />
            },
            

          ],
        },
         // 실시간 아람
         {
          path: 'realtime-monitoring',
          children: [
            {
              path: 'channel-alarm',
              element: <ChannelAlarmScreen />,
            },
            {
              path: 'multi-channel-alarm',
              element: <MultiChannelAlarmScreen />,
            },
            {
              path: 'quetone-alarm',
              element: <QuetoneAlarmScreen />,
            },
            {
              path: 'voc-alarm',
              element: <VocAlarmScreen />,
            },
            {
              path: 'disabled-person-alarm',
              element: <DisabledPersonAlarmScreen/>,
            },
            // { path: 'interlocking-system-mgmt', element: <IntrlkSysMgmt /> },
            // { path: 'equipment-mgmt', element: <EquipmentMgmt /> },
         
          ],
        },
      ],
    },
    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '404', element: <NotFound /> },
        { path: '400', element: <BadRequest /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: 'channel-ai/dashboard/one-view',
      element: <OneView />,
    },
    {
      path: '/',
      element: (
        <AuthGuard>
          <DashboardLayout/>
        </AuthGuard>
      ),
      children: [
        {
          element: <TopologyView />,
          index: true,
        },
        // { element: <GroupMenuMgmt />, index: true },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// IMPORT COMPONENTS

// Dashboard
const SystemStatus = Loadable(
  lazy(() => import('../screens/SystemStatusDashboard/SystemStatusDashboardScreen'))
)
// const Dashboard = Loadable(lazy(() => import('../screens/dashboard/dashboard-screen')));
const NotFound = Loadable(lazy(() => import('../screens/Page404')));

const BadRequest = Loadable(lazy(() => import('../screens/Page400')));

// const BankList = Loadable(lazy(() => import('../screens/bank-list/bank-list-screen')));

// Channel AI
const GroupMgmt = Loadable(
  lazy(() => import('../screens/SystemManagement/GroupMgmt/GroupMgmtScreen')),
);
const UserMgmt = Loadable(
  lazy(() => import('../screens/SystemManagement/UserMgmt/UserMgmtScreen')),
);
const UserLoginHistory = Loadable(
  lazy(() => import('../screens/SystemManagement/UserLoginHistory/UserLoginHistoryScreen')),
);
const GroupMenuMgmt = Loadable(
  lazy(() => import('../screens/SystemManagement/GroupMenuMgmt/GroupMenuMgmtScreen')),
);
const ApiHistory = Loadable(
  lazy(() => import('../screens/SystemManagement/ApiHistorySearch/ApiHistorySearchScreen')),
);
const PpCompanyMgmt = Loadable(
  lazy(() => import('../screens/SystemManagement/PpCompanyMgmt/PpCompanyMgmtScreen')),
);
const ChannelMgmt = Loadable(
  lazy(() => import('../screens/ConfigurationManagement/ChannelMgmt/ChannelMgmtScreen')),
);
const EquipmentMgmt = Loadable(
  lazy(() => import('../screens/ConfigurationManagement/EquipmentMgmt/EquipmentMgmtScreen')),
);
const MonitoringSTBMgmt = Loadable(
  lazy(
    () => import('../screens/ConfigurationManagement/MonitoringSTBMgmt/MonitoringSTBMgmtScreen'),
  ),
);
const ChannelCodeMgmt = Loadable(
  lazy(() => import('../screens/ConfigurationManagement/ChannelCodeMgmt/ChannelCodeMgmtScreen')),
);
const IntrlkSysMgmt = Loadable(
  lazy(() => import('../screens/ConfigurationManagement/IntrlkSysMgmt/IntrlkSysMgmtScreen')),
);

const ConnectionInfoMgmt = Loadable(
  lazy(
    () => import('../screens/ConfigurationManagement/ConnectionInfoMgmt/ConnectionInfoMgmtScreen'),
  ),
);

const TopologyMgmt = Loadable(
  lazy(() => import('../screens/ConfigurationManagement/TopologyMgmt/TopologyMgmt')),
);

const SnmpRuleset = Loadable(
  lazy(() => import('../screens/AlarmManagement/SnmpRuleset/SnmpRulesetScreen')),
);
const Ruleset = Loadable(
  lazy(() => import('../screens/AlarmManagement/Ruleset/RulesetMgmtScreen')),
);
const StbSimulator = Loadable(
  lazy(() => import('../screens/AlarmManagement/Stb-Simulator/StbSimulatorScreen')),
);
const PairAlarm = Loadable(
  lazy(() => import('../screens/AlarmManagement/PairAlarmMgmt/PairAlarmMgmtScreen')),
);

const KdbMgmt = Loadable(lazy(() => import('../screens/SopKdbManagement/KdbMgmt/KdbMgmtScreen')));

const RealtimeAlarm = Loadable(
  lazy(() => import('../screens/AlarmManagement/Realtime/RealtimeAlarmScreen')),
);

const AlarmHistory = Loadable(
  lazy(() => import('../screens/Statistics-Reporting/AlarmHistory/AlarmHistoryScreen')),
);

const AllAlarmHistory = Loadable(
    lazy(() => import('../screens/Statistics-Reporting/AllAlarmHistory/AllAlarmHistoryScreen')),
);

const SopMgmt = Loadable(
  lazy(() => import('../screens/SopKdbManagement/SopRegisterMgmt/SopRegisterMgmtScreen')),
);

const StbStatistics = Loadable(
  lazy(() => import('../screens/Statistics-Reporting/StbStatistics/StbStatisticsScreen')),
);

const VocStatistics = Loadable(
  lazy(() => import('../screens/Statistics-Reporting/VocStatistics/VocStatisticsScreen')),
);

const Voc = Loadable(lazy(() => import('../screens/Statistics-Reporting/VocHistory/VocScreen')));

const RatingStatistics = Loadable(
  lazy(() => import('../screens/Statistics-Reporting/RatingStatistics/RatingStatisticsScreen')),
);

const Report = Loadable(
  lazy(() => import('../screens/Statistics-Reporting/report/ReportScreen')),
);

const QualityReport = Loadable(
  lazy(() => import('../screens/Statistics-Reporting/QualityReport/QualityReportScreen')),
);

const Aquetone = Loadable(
  lazy(()=> import('../screens/SupplementaryServcie/qutone/AquetoneScreen')),
)

const Bquetone = Loadable(
  lazy(()=> import('../screens/SupplementaryServcie/qutone/BquetoneScreen')),
)

const Dquetone = Loadable(
    lazy(() => import('../screens/SupplementaryServcie/qutone/DQuetoneScreen')),
)

const QuetoneDpcc = Loadable(
  lazy(()=> import('../screens/SupplementaryServcie/qutone/QuetoneDpccScreen')),
)

const Sms = Loadable(
    lazy(() => import('../screens/SystemManagement/SmsTransmission/SmsScreen')),
)

const Email = Loadable(
    lazy(() => import('../screens/SystemManagement/EmailTransmission/EmailScreen')),
)

const MisTransMissionScreen = Loadable(
    lazy(() => import('../screens/SupplementaryServcie/MisTransMission/MisTransMissionScreen')),
)


const NotificationMgmtScreen = Loadable(
  lazy(() => import('../screens/SystemManagement/NotificationMgmt/NotificationScreen')),
)

const PageCommingSoon = Loadable(lazy(() => import('../screens/PageCommingSoon')));

const ChannelAlarmScreen = Loadable(
  lazy(() => import('../screens/RealtimeMonitoring/ChannelAlarm/ChannelAlarmScreen')),
)

// Main
// const HomePage = Loadable(lazy(() => import('@screens/Home')));
// const LandingPage = Loadable(lazy(() => import('@screens/LandingPage')));

// Authentication
const Login = Loadable(lazy(() => import('../screens/login/login-screen')));
const Register = Loadable(lazy(() => import('../screens/register/register-screen')));
const OneView = Loadable(lazy(() => import('../screens/oneview/Oneview')));
const TopologyView = Loadable(lazy(() => import('../screens/topology/TopologyView')));
