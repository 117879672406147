import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { selectOptions } from '../../components/forms/CSelect';

export const VocStatistics = types
  .model('VocStatistics')
  .props({
    statDt: types.optional(types.string, ''),
    preStatDt: types.optional(types.maybeNull(types.string), null),
    region: types.optional(types.maybeNull(types.string), null),
    cnt: types.optional(types.maybeNull(types.number), null),
    preCnt: types.optional(types.maybeNull(types.number), null),
  })
  .actions((self) => ({
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as IVocStatistics;
      (Object.keys(newProps) as TVocStatisticsKeys[]).forEach((key) => {
        //@ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type TVocStatistics = Instance<typeof VocStatistics>;
type TVocStatisticsSnapshot = SnapshotOut<typeof VocStatistics>;

export interface IVocStatistics extends TVocStatistics {}
export type TVocStatisticsKeys = keyof TVocStatisticsSnapshot & string;
export interface IVocStatisticsSnapshot extends TVocStatisticsSnapshot {}
export const createVocStatistics = () =>
  types.optional(VocStatistics, {
    statDt: '',
    preStatDt: '',
    region: '',
    cnt: null,
    preCnt: null,
  } as TVocStatistics);

  export interface IVocStatisticsFilter {
    withPre?: boolean;
    statDtSt?: string;
    statDtEd?: string;
    statType?: number;
    statUnit?: number;
    useOneview?: boolean;
  }
  
  export type TVocStatisticsFilter = IVocStatisticsFilter;
