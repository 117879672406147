import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { createModelCode } from '../model-code/ModelCode';

export const EventRypeRel = types
  .model('EventRypeRel')
  .props({
    instType: types.maybeNull(createModelCode()),
    eventType: types.maybeNull(createModelCode()),
    problCause: types.optional(types.maybeNull(types.string), ''),
    spcfProbl: types.optional(types.maybeNull(types.string), ''),
  })
  .actions((self) => ({
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as IEventRypeRel;
      (Object.keys(newProps) as TEventRypeRelKeys[]).forEach((key) => {
        //@ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type TEventRypeRel = Instance<typeof EventRypeRel>;
type TEventRypeRelSnapshot = SnapshotOut<typeof EventRypeRel>;

export interface IEventRypeRel extends TEventRypeRel {}
export type TEventRypeRelKeys = keyof TEventRypeRelSnapshot & string;
export interface IEventRypeRelSnapshot extends TEventRypeRelSnapshot {}
export const createEventRypeRel = () =>
  types.optional(EventRypeRel, {
    instType: null,
    eventType: null,
    problCause: '',
    spcfProbl: '',
  } as TEventRypeRel);
