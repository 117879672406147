import { ApiResponse } from 'apisauce';
import { Api } from '../api/api';
import { getGeneralApiProblem } from '../api/api-problem';
import { IPaginationSnapshot, IResponseSnapshot, IVoc, IVocFilter, TVocFilter } from '../../models';
import { TGetVocsResult, TGetVocResult } from './VocTypes';

/**
 * # Voc Api 서비스
 *
 * 서비스의 설명을 작성하세요.
 *
 * ## 사용방법
 *
 * ```ts
 * const service = new VocApi();
 *
 * ```
 */
export class VocApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  async gets(pagination: IPaginationSnapshot, vocFilter?: TVocFilter): Promise<TGetVocsResult> {
    try {
      const url = '/voc';
      let params: any = {};
      if (vocFilter) {
        params = filterSendData(vocFilter);
      }

      params.size = pagination.size;
      params.page = pagination.page;

      const response: ApiResponse<any> = await this.api.apisauce.get(url, params);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;

      return {
        kind: 'ok',
        vocs: response.data.data,
        pagination: response.data.pagination,
        responseInfo: responseInfo,
      };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async get(vocSeq: string): Promise<TGetVocResult> {
    try {
      const url = `/voc/${vocSeq}`;
      const params = {};
      const response: ApiResponse<any> = await this.api.apisauce.get(url, params);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;

      if (responseInfo.resultCode === 'S') {
        return {
          kind: 'ok',
          voc: response.data.data,
          responseInfo: responseInfo,
        };
      }

      return { kind: 'ok', responseInfo: responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  // async postVoc(): Promise<TPostVocResult> {
  //   try {
  //     const url = '/voc';
  //     const payload = {};
  //     const response: ApiResponse<any> = await this.api.apisauce.post( url, payload );
  //
  //     if (!response.ok) {
  //       const problem = getGeneralApiProblem(response);
  //       if (problem) return problem;
  //     }
  //
  //     const voc: IVoc = response.data.results;
  //     return { kind: "ok", voc };
  //
  //   } catch (e) {
  //     return { kind: "bad-data" };
  //   }
  // }
  //
  // async updateVoc(id:number): Promise<TUpdateVocResult> {
  //   try {
  //     const url = `/voc/${id}`;
  //     const payload = {};
  //     const response: ApiResponse<any> = await this.api.apisauce.put( url, payload );
  //
  //     if (!response.ok) {
  //       const problem = getGeneralApiProblem(response);
  //       if (problem) return problem;
  //     }
  //
  //     const voc: IVoc = response.data.results;
  //     return { kind: "ok", voc };
  //
  //   } catch (e) {
  //     return { kind: "bad-data" };
  //   }
  // }
  //
  // async deleteVoc(id:number): Promise<TDeleteVocResult> {
  //   try {
  //     const url = `/voc/${id}`;
  //     const payload = {};
  //     const response: ApiResponse<any> = await this.api.apisauce.delete( url, payload );
  //
  //     if (!response.ok) {
  //       const problem = getGeneralApiProblem(response);
  //       if (problem) return problem;
  //     }
  //
  //     return { kind: "ok" };
  //
  //   } catch (e) {
  //     return { kind: "bad-data" };
  //   }
  // }
}

const filterSendData = (vocFilter: IVocFilter) => {
  let sendData: any = {};

  if (vocFilter.svcId) {
    sendData.svcId = vocFilter.svcId;
  }
  if (vocFilter.stsCode) {
    sendData.stsCode = vocFilter.stsCode;
  }
  if (vocFilter.regDtSt) {
    sendData.regDtSt = vocFilter.regDtSt;
  }
  if (vocFilter.regDtEd) {
    sendData.regDtEd = vocFilter.regDtEd;
  }
  if (vocFilter.sidoCode) {
    sendData.sidoCode = vocFilter.sidoCode;
  }
  if (vocFilter.prodSubCode) {
    sendData.prodSubCode = vocFilter.prodSubCode;
  }
  if (vocFilter.svcMainTypeCode) {
    sendData.svcMainTypeCode = vocFilter.svcMainTypeCode;
  }
  if (vocFilter.svcSubTypeCode) {
    sendData.svcSubTypeCode = vocFilter.svcSubTypeCode;
  }

  return sendData;
};
