import { HeadCell } from '../../../components/CTableHead';

export const headCells: readonly HeadCell[] = [
  {
    id: 'eventState',
    numeric: false,
    disablePadding: true,
    label: '알람상태',
  },
  {
    id: 'perceivedSeverity',
    numeric: false,
    disablePadding: true,
    label: '등급',
  },
  {
    id: 'instanceType',
    numeric: false,
    disablePadding: true,
    label: '유형',
  },
  {
    id: 'instanceName',
    numeric: true,
    disablePadding: true,
    label: '장비명',
  },
  {
    id: 'eventTime',
    numeric: true,
    disablePadding: true,
    label: '발생 시각',
  },
  {
    id: 'clearenceTime',
    numeric: true,
    disablePadding: true,
    label: '종료 시각',
  },
  {
    id: 'eventDuration',
    numeric: true,
    disablePadding: true,
    label: '지속 시간',
  },
  {
    id: 'targetInstanceId',
    numeric: false,
    disablePadding: true,
    label: '서비스ID',
  },
  {
    id: 'targetInstanceName',
    numeric: false,
    disablePadding: true,
    label: '채널명',
  },
  {
    id: 'message',
    numeric: false,
    disablePadding: true,
    label: '메시지',
    align: 'left',
  },
  {
    id: 'specificProblem',
    numeric: false,
    disablePadding: true,
    label: '상세원인',
    align: 'left',
  },
  {
    id: 'probableCause',
    numeric: false,
    disablePadding: true,
    label: '발생 원인',
    align: 'left',
  },
];

export const headCellsChannel: readonly HeadCell[] = [
  {
    id: 'eventState',
    numeric: false,
    disablePadding: true,
    label: '알람상태',
  },
  {
    id: 'perceivedSeverity',
    numeric: false,
    disablePadding: true,
    label: '등급',
  },
  {
    id: 'sourceEventTime',
    numeric: false,
    disablePadding: true,
    label: 'MV발생시각',
  },
  {
    id: 'instanceType',
    numeric: false,
    disablePadding: true,
    label: '유형',
  },
  {
    id: 'instanceName',
    numeric: true,
    disablePadding: true,
    label: '장비명',
  },
  {
    id: 'eventTime',
    numeric: true,
    disablePadding: true,
    label: '발생 시각',
  },
  {
    id: 'clearenceTime',
    numeric: true,
    disablePadding: true,
    label: '종료 시각',
  },
  {
    id: 'eventDuration',
    numeric: true,
    disablePadding: true,
    label: '지속 시간',
  },
  {
    id: 'targetInstanceId',
    numeric: false,
    disablePadding: true,
    label: '서비스ID',
  },
  {
    id: 'targetInstanceName',
    numeric: false,
    disablePadding: true,
    label: '채널명',
  },
  {
    id: 'message',
    numeric: false,
    disablePadding: true,
    label: '메시지',
    align: 'left',
  },
  {
    id: 'specificProblem',
    numeric: false,
    disablePadding: true,
    label: '상세원인',
    align: 'left',
  },
  {
    id: 'probableCause',
    numeric: false,
    disablePadding: true,
    label: '발생 원인',
    align: 'left',
  },
];

