import { dateToTimestamp } from "./dateExchanger";

export const createParamsWithType = (oldParams: any, type:any ) =>
{
  const params: any = {};

  Object.keys(type.properties).forEach((key) => {
    //@ts-ignore
    params[key] = oldParams[key];
  });

  if (params.regDt) {
    params.regDt = dateToTimestamp(params.regDt);
  }
  if (params.updDt) {
    params.updDt = dateToTimestamp(params.updDt);
  }
  return params;
}
