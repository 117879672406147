import { ApiResponse } from 'apisauce';
import { CC } from '../../commonCodes/commonCodes';
import {
  IAcoSnapshot,
  IEncodSnapshot,
  IHdMuxSnapshot,
  IInputSwSnapshot,
  IOutputSwSnapshot,
  IPaginationSnapshot,
  ISdMuxSnapshot,
  ISdSwSnapshot,
  ITrcodSnapshot,
} from '../../models';
import { TChannelFilter } from '../../models/channel-store/ChannelStore';
import { IChannelSnapshot } from '../../models/channel/Channel';
import { dateToTimestamp, timestampToDate } from '../../utils/dateExchanger';
import { Api } from '../api/api';
import { getGeneralApiProblem } from '../api/api-problem';
import {
  TDeleteChannelResult,
  TGetChannelResult,
  TGetChannelsResult,
  TLineupResult,
  TPostChannelResult,
  TUpdateChannelResult,
  TCheckIdResult,
  TGetChannelToCodeResult,
} from '../channel/ChannelTypes';
import { IResponseSnapshot } from './../../models/response/Response';

export class ChannelApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  /**
   * INF_CAIMS_00143
   * 채널 목록을 조회한다.
   * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=763085185
   * @param pagination
   * @param channelFilter
   * @returns
   */
  async gets(
    pagination: IPaginationSnapshot,
    channelFilter?: TChannelFilter,
  ): Promise<TGetChannelsResult> {
    try {
      const url = '/channel';
      let params: any = {};

      if (channelFilter) {
        params = { ...channelFilter };
      }

      params.size = pagination.size;
      params.page = pagination.page;

      const response: ApiResponse<any> = await this.api.apisauce.get(url, params);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;

      return {
        kind: 'ok',
        channels: response.data.data,
        pagination: response.data.pagination,
        responseInfo: responseInfo,
      };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  /**
   * INF_CAIMS_00144
   * 채널 상세정보를 조회한다.
   * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=728987105
   * @param chSvcId
   * @returns
   */
  async get(chSvcId: number): Promise<TGetChannelResult> {
    try {
      const url = '/channel/' + chSvcId;
      const response: ApiResponse<any> = await this.api.apisauce.get(url);
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;

      return {
        kind: 'ok',
        channel: response.data.data,
        responseInfo: responseInfo,
      };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  /**
   * INF_CAIMS_00146
   * 채널을 등록한다.
   * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=1002654055
   * @param channel
   * @returns
   */
  async post(channel: IChannelSnapshot): Promise<TPostChannelResult> {
    try {
      const url = '/channel';
      const params = createParams(channel);
      const response: ApiResponse<any> = await this.api.apisauce.post(url, params);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;

      return {
        kind: 'ok',
        channel: response.data.data,
        responseInfo: responseInfo,
      };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  /**
   * INF_CAIMS_00147
   * 채널을 수정한다.
   * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=1875736034
   * @param channel
   * @returns
   */
  async put(channel: IChannelSnapshot): Promise<TUpdateChannelResult> {
    try {
      const url = '/channel/' + channel.chSvcId;
      const params = createParams(channel);

      const response: ApiResponse<any> = await this.api.apisauce.put(url, params);
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;

      return {
        kind: 'ok',
        channel: response.data.data,
        responseInfo: responseInfo,
      };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  /**
   * INF_CAIMS_00148
   * 채널을 삭제한다.
   * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=967575460
   * @param chSvcId
   * @returns
   */
  async delete(chSvcId: number, params: IChannelSnapshot): Promise<TDeleteChannelResult> {
    try {
      let param = '';
      if(params.wrkMemo)
      {
        param += `wrkMemo=${params.wrkMemo.replace(/\n/g,'<br/>')}`;
      }
      const url = `/channel/${chSvcId}?${param}`;
      const response: ApiResponse<any> = await this.api.apisauce.delete(url);
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;

      return {
        kind: 'ok',
        responseInfo: responseInfo,
      };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  /**
   * INF_CAIMS_00149
   * 채널 목록을 선택하여 삭제한다.
   * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=684082287
   * @param deleteChannelList
   * @returns
   */
  async deletes(deleteChannelList: number[], params: IChannelSnapshot): Promise<TDeleteChannelResult> {
    try {
      let param = ``;
      deleteChannelList.forEach((item: number, index: number) => {
        param += `chSvcIdDelList=${item}`;
        if (index !== deleteChannelList.length - 1) {
          param += `&`;
        }
      });
      if(params.wrkMemo)
      {
        param += `&wrkMemo=${params.wrkMemo.replace(/\n/g,'<br/>')}`;
      }

      const SUB_URL = `/channel?${param}`;
      const response: ApiResponse<any> = await this.api.apisauce.delete(SUB_URL);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;

      return {
        kind: 'ok',
        responseInfo: responseInfo,
      };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  /**
   * INF_CAIMS_00145
   * 채널 선번장을 등록한다.
   * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=1698908185
   */
  async lineup(file: File): Promise<TLineupResult> {
    try {
      const url: string = `/channel/lineup`;
      const form = new FormData();
      form.append('insFile', file);
      await this.api.apisauce.setHeader('Content-Type', 'multipart/form-data');

      const response: ApiResponse<any> = await this.api.apisauce.post(url, form);
      await this.api.apisauce.setHeader('Content-Type', 'application/json');
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      let socketKey: string = ``;
      const responseInfo: IResponseSnapshot = response.data;

      if (responseInfo.resultCode === 'S') {
        if (response.data.data) {
          socketKey = response.data.data.key;
        }
        return { kind: 'ok', responseInfo: responseInfo, socketKey: socketKey };
      }

      return { kind: 'ok', responseInfo: responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  /**
   * INF_CAIMS_00234
   * 채널의 서비스 아이디를 중복 체크한다.
   * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=650294706
   * @param chSvcId
   */
  async checkChSvcId(chSvcId: number): Promise<TCheckIdResult> {
    try {
      const url: string = `/channel/service/id/check?chSvcId=${chSvcId}`;
      const response: ApiResponse<any> = await this.api.apisauce.get(url);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;
      return { kind: 'ok', responseInfo: responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  /**
   * INF_CAIMS_00235
   * 채널의 채널번호를 중복체크한다.
   * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=1789731756
   */
  async checkChNo(chNo: number): Promise<TCheckIdResult> {
    try {
      const url: string = `/channel/no/check?chNo=${chNo}`;
      const response: ApiResponse<any> = await this.api.apisauce.get(url);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;
      return { kind: 'ok', responseInfo: responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  /**
   * 채널 선번장을 다운로드한다.
   */
  async downloadLineup(isAll: boolean): Promise<TCheckIdResult> {
    const url: string = `/channel/lineup/download?isAll=${isAll}`;
    return this.api.download(url, 'channel_lineup.xlsx');
  }

  /**
   * 채널 선번장 템플릿을 다운로드한다.
   */
  async downloadTemplate(): Promise<TCheckIdResult> {
    const url: string = `/channel/lineup/download/template`;
    return this.api.download(url, 'lineup_template.xlsx');
  }

  /**
   * 전체 채널을 코드형태로 조회한다.
   * @returns
   */
  async getChannelToCode(subService?:string): Promise<TGetChannelToCodeResult> {
    try {
      const url = '/channel/to/code';
      let params = {

      };

      if(subService)
      {
        params ={
          subService: subService,
        }
      }

      const response: ApiResponse<any> = await this.api.apisauce.get(url,params);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;

      return {
        kind: 'ok',
        codes: response.data.data,
        responseInfo: responseInfo,
      };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }



  /**
   * 전체 채널을 코드형태로 조회한다.
   * @returns
   */
  async getOccurredEventChannelToCode(subService?:string, startDt?:string | null, endDt?:string | null): Promise<TGetChannelToCodeResult> {
    try {
      const url = '/channel/to/code';
      let params = {

      };

      if(subService)
      {
        params ={
          subService: subService,
          occurredEvent: true,
          startDt: dateToTimestamp(startDt),
          endDt: dateToTimestamp(endDt),
        }
      }

      const response: ApiResponse<any> = await this.api.apisauce.get(url,params);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;

      return {
        kind: 'ok',
        codes: response.data.data,
        responseInfo: responseInfo,
      };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }
}

const setAcoList = (list: IAcoSnapshot[], sorceInType: number) => {
  let result: any[] = [];
  list.forEach((item: IAcoSnapshot) => {
    let aco: any = {};
    if (item.equipSid) {
      aco.equipSid = item.equipSid;
    }else{
      return;
    }
    aco.srcInType = sorceInType;
    aco.mainYn = typeof item.mainYn === 'boolean' ? item.mainYn : false;
    if (item.psiPid !== undefined) {
      aco.psiPid = item.psiPid;
    }
    if (item.patPid !== undefined) {
      aco.patPid = item.patPid;
    }
    if (item.inPort) {
      aco.inPortSid = item.inPort.code;
    }

    if (item.inBPort) {
      aco.inBPortSid = item.inBPort.code;
    }

    if (item.outBPort) {
      aco.outBPortSid = item.outBPort.code;
    }
    if (item.skyAraInPort) {
      aco.skyAraInPortSid = item.skyAraInPort.code;
    }
    if (item.lgInPort) {
      aco.lgInPortSid = item.lgInPort.code;
    }
    if (item.psiInPort) {
      aco.psiInPortSid = item.psiInPort.code;
    }
    if (item.outPort) {
      aco.outPortSid = item.outPort.code;
    }
    if (item.skyOutPort) {
      aco.skyOutPortSid = item.skyOutPort.code;
    }
    if (item.seeznOutPort) {
      aco.seeznOutPortSid = item.seeznOutPort.code;
    }
    if (item.ktInMip !== undefined) {
      aco.ktInMip = item.ktInMip;
    }
    if (item.skyInMip !== undefined) {
      aco.skyInMip = item.skyInMip;
    }
    if (item.lgInMip !== undefined) {
      aco.lgInMip = item.lgInMip;
    }
    if (item.outMip !== undefined) {
      aco.outMip = item.outMip;
    }

    result.push(aco);
  });

  return result;
};

//createParams 에서 사용되는 ANY 타입은
//파라미터 변경으로 인한 ANY 로 사용 중
//정확한 규격이 Fix 되면 인터페이스로 변환할 것
const createParams = (channel: IChannelSnapshot) => {
  const params: any = {};
  params.wrkMemo = typeof channel.wrkMemo === 'string' ? channel.wrkMemo : null;

  params.seeznIntrlkYn = typeof channel.seeznIntrlkYn === 'boolean' ? channel.seeznIntrlkYn : false;
  params.hcnIntrlkYn = typeof channel.hcnIntrlkYn === 'boolean' ? channel.hcnIntrlkYn : false;
  params.skylifeIntrlkYn =
    typeof channel.skylifeIntrlkYn === 'boolean' ? channel.skylifeIntrlkYn : false;
  params.araIntrlkYn = typeof channel.araIntrlkYn === 'boolean' ? channel.araIntrlkYn : false;
  params.videoStchYn = typeof channel.videoStchYn === 'boolean' ? channel.videoStchYn : false;
  params.dpsonSubtitYn = typeof channel.dpsonSubtitYn === 'boolean' ? channel.dpsonSubtitYn : false;
  params.vcMultiYn = typeof channel.vcMultiYn === 'boolean' ? channel.vcMultiYn : false;
  params.dplxYn = typeof channel.dplxYn === 'boolean' ? channel.dplxYn : false;
  params.casEcodYn = typeof channel.casEcodYn === 'boolean' ? channel.casEcodYn : false;
  params.encodCcYn = typeof channel.encodCcYn === 'boolean' ? channel.encodCcYn : false;
  params.dedicatedChYn = channel.dedicatedChYn;

  if (channel.chSvcId !== undefined) {
    params.chSvcId = channel.chSvcId;
  }

  if (channel.chNo !== undefined) {
    params.chNo = channel.chNo;
  }

  if (channel.chNm !== undefined) {
    params.chNm = channel.chNm;
  }

  if (channel.chType) {
    params.chType = channel.chType;
  }

  if (channel.trtResol) {
    params.trtResol = channel.trtResol;
  }

  if (channel.sadInfo) {
    params.sadInfo = channel.sadInfo;
  }

  if (channel.advmMemo !== undefined) {
    params.advmMemo = channel.advmMemo;
  }

  if (channel.advm) {
    params.advm = channel.advm;
  }

  if (channel.hdMip !== undefined) {
    params.hdMip = channel.hdMip;
  }

  if (channel.sdMip !== undefined) {
    params.sdMip = channel.sdMip;
  }

  if (channel.adultYn !== undefined) {
    params.adultYn = channel.adultYn;
  }

  if (channel.psiIn) {
    params.psiIn = channel.psiIn;
  }

  if (channel.pat !== undefined) {
    params.pat = channel.pat;
  }

  if (channel.pmt !== undefined) {
    params.pmt = channel.pmt;
  }

  if (channel.videoPid !== undefined) {
    params.videoPid = channel.videoPid;
  }

  if (channel.audio1Pid !== undefined) {
    params.audio1Pid = channel.audio1Pid;
  }

  if (channel.audio2Pid !== undefined) {
    params.audio2Pid = channel.audio2Pid;
  }

  if (channel.acapAit !== undefined) {
    params.acapAit = channel.acapAit;
  }

  if (channel.webAit !== undefined) {
    params.webAit = channel.webAit;
  }

  if (channel.acapOc !== undefined) {
    params.acapOc = channel.acapOc;
  }

  if (channel.webOc !== undefined) {
    params.webOc = channel.webOc;
  }

  if (channel.encodHdMip !== undefined) {
    params.encodHdMip = channel.encodHdMip;
  }
  if (channel.encodSdMip !== undefined) {
    params.encodSdMip = channel.encodSdMip;
  }

  if (channel.ecmPid !== undefined) {
    params.ecmPid = channel.ecmPid;
  }

  if (channel.encodQtoneAPid !== undefined) {
    params.encodQtoneAPid = channel.encodQtoneAPid;
  }

  if (channel.encodQtoneBPid !== undefined) {
    params.encodQtoneBPid = channel.encodQtoneBPid;
  }

  if (channel.encodQtoneDPid !== undefined) {
    params.encodQtoneDPid = channel.encodQtoneDPid;
  }

  if (channel.encodPmtPid !== undefined) {
    params.encodPmtPid = channel.encodPmtPid;
  }

  if (channel.encodVideoPid !== undefined) {
    params.encodVideoPid = channel.encodVideoPid;
  }

  if (channel.encodAudioPid !== undefined) {
    params.encodAudioPid = channel.encodAudioPid;
  }

  if (channel.encodAudio2Pid !== undefined) {
    params.encodAudio2Pid = channel.encodAudio2Pid;
  }

  if (channel.rglsComotDate) {
    params.rglsComotDate = dateToTimestamp(channel.rglsComotDate);
  }

  if (channel.pp) {
    let pp: any = {};
    if (channel.pp.ppSid) {
      pp.ppSid = channel.pp.ppSid;
    }
    if (channel.pp.msppNm) {
      pp.msppNm = channel.pp.msppNm;
    }
    if (channel.pp.msppPort) {
      pp.msppPort = channel.pp.msppPort;
    }
    if (channel.pp.msppBNm) {
      pp.msppBNm = channel.pp.msppBNm;
    }
    if (channel.pp.niSid) {
      pp.niSid = channel.pp.niSid;
    }
    if(channel.pp.niNm) {
      pp.niNm = channel.pp.niNm;
    }
    if (channel.pp.msppBPort) {
      pp.msppBPort = channel.pp.msppBPort;
    }
    if (channel.pp.ktTrsmDept) {
      pp.ktTrsmDept = channel.pp.ktTrsmDept;
    }
    if (channel.pp.ppMemo) {
      pp.ppMemo = channel.pp.ppMemo;
    }
    // 전용회선 > kt
    if (channel.pp.ktTrmNetVendr) {
      pp.ktTrmNetVendr = channel.pp.ktTrmNetVendr;
    }
    if (channel.pp.ktCircuitNoMain) {
      pp.ktCircuitNoMain = channel.pp.ktCircuitNoMain;
    }
    if (channel.pp.ktCircuitNoBackup) {
      pp.ktCircuitNoBackup = channel.pp.ktCircuitNoBackup;
    }
    if (channel.pp.ktCircuitNoSpare) {
      pp.ktCircuitNoSpare = channel.pp.ktCircuitNoSpare;
    }
    // 전용회선 > skylife
    if (channel.pp.skylifeCircuitSpatnYn === true) {
      pp.skylifeCircuitSpatnYn = channel.pp.skylifeCircuitSpatnYn;
      if (channel.pp.skylifeTrmNetVendr) {
        pp.skylifeTrmNetVendr = channel.pp.skylifeTrmNetVendr;
      }
      if (channel.pp.skylifeCircuitNoMain) {
        pp.skylifeCircuitNoMain = channel.pp.skylifeCircuitNoMain;
      }
      if (channel.pp.skylifeCircuitNoBackup) {
        pp.skylifeCircuitNoBackup = channel.pp.skylifeCircuitNoBackup;
      }
      if (channel.pp.skylifeCircuitNoSpare) {
        pp.skylifeCircuitNoSpare = channel.pp.skylifeCircuitNoSpare;
      }
    }
    // 전용회선 > hcn
    if (channel.pp.hcnCircuitSpatnYn === true) {
      pp.hcnCircuitSpatnYn = channel.pp.hcnCircuitSpatnYn;
      if (channel.pp.hcnTrmNetVendr) {
        pp.hcnTrmNetVendr = channel.pp.hcnTrmNetVendr;
      }
      if (channel.pp.hcnCircuitNoMain) {
        pp.hcnCircuitNoMain = channel.pp.hcnCircuitNoMain;
      }
      if (channel.pp.hcnCircuitNoBackup) {
        pp.hcnCircuitNoBackup = channel.pp.hcnCircuitNoBackup;
      }
      if (channel.pp.hcnCircuitNoSpare) {
        pp.hcnCircuitNoSpare = channel.pp.hcnCircuitNoSpare;
      }
    }

    if (Object.keys(pp).length > 0) {
      params.pp = pp;
    }
  }

  if (channel.advm) {
    const advm: any = {};
    if (channel.advm.ktQtoneAPid !== undefined) {
      advm.ktQtoneAPid = channel.advm.ktQtoneAPid;
    }
    if (channel.advm.ktQtoneBPid !== undefined) {
      advm.ktQtoneBPid = channel.advm.ktQtoneBPid;
    }
    if (channel.advm.ktQtoneDPid !== undefined) {
      advm.ktQtoneDPid = channel.advm.ktQtoneDPid;
    }
    // 광고분리 > SkyLife
    advm.skylifeAdvmSpatnYn =
      typeof channel.advm.skylifeAdvmSpatnYn === 'boolean'
        ? channel.advm.skylifeAdvmSpatnYn
        : false;
    if (advm.skylifeAdvmSpatnYn === true) {
      if (channel.advm.skylifeQtoneAPid !== undefined) {
        advm.skylifeQtoneAPid = channel.advm.skylifeQtoneAPid;
      }
      if (channel.advm.skylifeQtoneBPid !== undefined) {
        advm.skylifeQtoneBPid = channel.advm.skylifeQtoneBPid;
      }
      if (channel.advm.skylifeQtoneDPid !== undefined) {
        advm.skylifeQtoneDPid = channel.advm.skylifeQtoneDPid;
      }
    }
    // 광고분리 > HCN
    advm.hcnAdvmSpatnYn =
      typeof channel.advm.hcnAdvmSpatnYn === 'boolean' ? channel.advm.hcnAdvmSpatnYn : false;
    if (advm.hcnAdvmSpatnYn === true) {
      if (channel.advm.hcnQtoneAPid !== undefined) {
        advm.hcnQtoneAPid = channel.advm.hcnQtoneAPid;
      }
      if (channel.advm.hcnQtoneBPid !== undefined) {
        advm.hcnQtoneBPid = channel.advm.hcnQtoneBPid;
      }
      if (channel.advm.hcnQtoneDPid !== undefined) {
        advm.hcnQtoneDPid = channel.advm.hcnQtoneDPid;
      }
    }

    if (Object.keys(advm).length > 0) {
      params.advm = advm;
    }
  }

  // if (channel.sdSwList && channel.sdSwList[0].equipSid) {
  //   let paramSdSwList: any = [];
  //   channel.sdSwList.forEach((item: ISdSwSnapshot) => {
  //     const sdSw: any = {};
  //     if (item.equipSid) {
  //       sdSw.equipSid = item.equipSid;
  //     }
  //     sdSw.mainYn = typeof item.mainYn === 'boolean' ? item.mainYn : false;
  //     if (item.inPort) {
  //       sdSw.inPortSid = item.inPort.code;
  //     }
  //     if (item.outPort) {
  //       sdSw.outPortSid = item.outPort.code;
  //     }
  //     if (item.inBPort) {
  //       sdSw.inBPortSid = item.inBPort.code;
  //     }
  //     if (item.outBPort) {
  //       sdSw.outBPortSid = item.outBPort.code;
  //     }
  //
  //     paramSdSwList.push(sdSw);
  //   });
  //
  //   params.sdSwList = paramSdSwList;
  // }

  // if (channel.trcodList && channel.trcodList[0].equipSid) {
  //   let paramTrcodList: any = [];
  //   channel.trcodList.forEach((item: ITrcodSnapshot) => {
  //     const trcod: any = {};
  //     if (item.equipSid) {
  //       trcod.equipSid = item.equipSid;
  //     }
  //     trcod.mainYn = typeof item.mainYn === 'boolean' ? item.mainYn : false;
  //     if (item.inPort) {
  //       trcod.inPortSid = item.inPort.code;
  //     }
  //     if (item.outPort) {
  //       trcod.outPortSid = item.outPort.code;
  //     }
  //     if (item.inBPort) {
  //       trcod.inBPortSid = item.inBPort.code;
  //     }
  //     if (item.outBPort) {
  //       trcod.outBPortSid = item.outBPort.code;
  //     }
  //
  //     paramTrcodList.push(trcod);
  //   });
  //
  //   params.trcodList = paramTrcodList;
  // }

  if (channel.encodList && channel.encodList[0].equipSid) {
    let paramEncodList: any = [];
    channel.encodList.forEach((item: IEncodSnapshot) => {
      const encod: any = {};
      if (item.equipSid) {
        encod.equipSid = item.equipSid;
      }else{
        return;
      }
      encod.mainYn = typeof item.mainYn === 'boolean' ? item.mainYn : false;
      if (item.no !== undefined) {
        encod.no = item.no;
      }
      if (item.sorcIp30 !== undefined) {
        encod.sorcIp30 = item.sorcIp30;
      }
      if (item.gw !== undefined) {
        encod.gw = item.gw;
      }

      paramEncodList.push(encod);
    });

    params.encodList = paramEncodList;
  }

  if (channel.inputSwList && channel.inputSwList[0].equipSid) {
    let paramInputSwList: any = [];
    channel.inputSwList.forEach((item: IInputSwSnapshot) => {
      const inputSw: any = {};
      if (item.equipSid) {
        inputSw.equipSid = item.equipSid;
      }else{
        return;
      }
      inputSw.mainYn = typeof item.mainYn === 'boolean' ? item.mainYn : false;
      if (item.inPort) {
        inputSw.inPortSid = item.inPort.code;
      }
      if (item.outPort) {
        inputSw.outPortSid = item.outPort.code;
      }
      if (item.emgOutPort) {
        inputSw.emgOutPortSid = item.emgOutPort.code;
      }
      if (item.outBPort) {
        inputSw.outBPortSid = item.outBPort.code;
      }

      paramInputSwList.push(inputSw);
    });

    params.inputSwList = paramInputSwList;
  }

  if (channel.acoList && channel.acoList[0].equipSid) {
    let list = setAcoList(channel.acoList, CC.SORCE_IN_TYPE.Kt);
    if (list && list.length > 0) {
      if (params.acoList === undefined) {
        params.acoList = list;
      } else {
        params.acoList = params.acoList.concat(list);
      }
    }
  }

  if (channel.acoAraList && channel.acoAraList[0].equipSid) {
    let list = setAcoList(channel.acoAraList, CC.SORCE_IN_TYPE.ARA);
    if (list && list.length > 0) {
      if (params.acoList === undefined) {
        params.acoList = list;
      } else {
        params.acoList = params.acoList.concat(list);
      }
    }
  }

  if (channel.hdMuxList && channel.hdMuxList[0].equipSid) {
    let paramHdMuxList: any = [];
    channel.hdMuxList.forEach((item: IHdMuxSnapshot) => {
      const hdMux: any = {};
      if (item.equipSid) {
        hdMux.equipSid = item.equipSid;
      }else{
        return;
      }
      hdMux.mainYn = typeof item.mainYn === 'boolean' ? item.mainYn : false;
      if (item.inPort) {
        hdMux.inPortSid = item.inPort.code;
      }
      if (item.outPort) {
        hdMux.outPortSid = item.outPort.code;
      }
      if (item.emgInPort) {
        hdMux.emgInPortSid = item.emgInPort.code;
      }
      if (item.inBPort) {
        hdMux.inBPortSid = item.inBPort.code;
      }
      if (item.outBPort) {
        hdMux.outBPortSid = item.outBPort.code;
      }
      if (item.scsPort) {
        hdMux.scsPortSid = item.scsPort.code;
      }
      if (item.ipout !== undefined) {
        hdMux.ipout = item.ipout;
      }

      paramHdMuxList.push(hdMux);
    });

    params.hdMuxList = paramHdMuxList;
  }

  if (channel.sdMuxList && channel.sdMuxList[0].equipSid) {
    let paramSdMuxList: any = [];
    channel.sdMuxList.forEach((item: ISdMuxSnapshot) => {
      const sdMux: any = {};
      if (item.equipSid) {
        sdMux.equipSid = item.equipSid;
      }else{
        return;
      }
      sdMux.mainYn = typeof item.mainYn === 'boolean' ? item.mainYn : false;
      if (item.inPort) {
        sdMux.inPortSid = item.inPort.code;
      }
      if (item.outPort) {
        sdMux.outPortSid = item.outPort.code;
      }
      if (item.scsPort) {
        sdMux.scsPortSid = item.scsPort.code;
      }
      if (item.ipout !== undefined) {
        sdMux.ipout = item.ipout;
      }
      if (item.emgInPort) {
        sdMux.emgInPortSid = item.emgInPort.code;
      }
      if (item.inBPort) {
        sdMux.inBPortSid = item.inBPort.code;
      }
      if (item.outBPort) {
        sdMux.outBPortSid = item.outBPort.code;
      }

      paramSdMuxList.push(sdMux);
    });

    params.sdMuxList = paramSdMuxList;
  }

  if (channel.outputSwList && channel.outputSwList[0].equipSid) {
    let paramOutPutSwList: any = [];
    channel.outputSwList.forEach((item: IOutputSwSnapshot) => {
      const outputSw: any = {};
      if (item.equipSid) {
        outputSw.equipSid = item.equipSid;
      }else{
        return;
      }
      outputSw.mainYn = typeof item.mainYn === 'boolean' ? item.mainYn : false;
      if (item.inPort) {
        outputSw.inPortSid = item.inPort.code;
      }
      if (item.outPort) {
        outputSw.outPortSid = item.outPort.code;
      }

      paramOutPutSwList.push(outputSw);
    });

    params.outputSwList = paramOutPutSwList;
  }

  return params;
};
