import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { createSimpleUser } from './../simple-user/SimpleUser';

/**
 * # Role
 *
 * Role을 설명하세요.
 */
export const Role = types
  .model('Role')
  // --------------------------------------------------------------------------
  .props({
    roleId: types.optional(types.string, ''),
    dscrpt: types.optional(types.string, ''),
    regDt: types.optional(types.string, ''),
    updDt: types.optional(types.string, ''),
    //구버전
    regId: types.optional(types.number, 0),
    updId: types.optional(types.number, 0),
    //신버전
    regUser: types.maybeNull(createSimpleUser()),
    updUser: types.maybeNull(createSimpleUser()),
  })
  // --------------------------------------------------------------------------
  // MUTATEs ONLY! - 모델 상태를 변경
  // REQUESTs는 스토어 모델에 작성하세요.
  // ex) yarn gen model role --store
  .actions((self) => ({
    /*
     * 여러 prop을 한번에 수정
     */
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as IRole;
      (Object.keys(newProps) as TRoleKeys[]).forEach((key) => {
        //@ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type TRole = Instance<typeof Role>;
type TRoleSnapshot = SnapshotOut<typeof Role>;

export interface IRole extends TRole {}
export type TRoleKeys = keyof TRoleSnapshot & string;
export interface IRoleSnapshot extends TRoleSnapshot {}
export const createRole = () =>
  types.optional(Role, {
    roleId: '',
    dscrpt: '',
    regDt: '',
    regId: 0,
    updDt: '',
    updId: 0,
  } as TRole);
