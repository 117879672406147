import { Instance, SnapshotOut, types } from "mobx-state-tree"
import {createMisTransMissionGraph} from "../mis-trans-mission-graph/MisTransMissionGraph";

/**
 * # ChPerfQltyRaw
 * 
 * ChPerfQltyRaw을 설명하세요.
 */
export const ChPerfQltyRaw = types
  .model("ChPerfQltyRaw")
  // --------------------------------------------------------------------------
  .props({
      statDtList: types.optional(types.maybeNull(types.array(types.string)),[]),
      misTransValueList: types.optional(types.maybeNull(types.array(types.number)),[]),
      misTransDateList: types.optional(types.maybeNull(types.array(types.string)),[]),
      misTransMissionGraphDataList: types.optional(types.maybeNull(types.array(createMisTransMissionGraph())),[]),
  })
  // --------------------------------------------------------------------------
  // MUTATEs ONLY! - 모델 상태를 변경
  // REQUESTs는 스토어 모델에 작성하세요.
  // ex) yarn gen model chPerfQltyRaw --store
  .actions((self) => ({
    /*
     * 여러 prop을 한번에 수정
     */
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as IChPerfQltyRaw;
      (Object.keys(newProps) as TChPerfQltyRawKeys[]).forEach((key) => {
        // @ts-ignore
          self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type TChPerfQltyRaw = Instance<typeof ChPerfQltyRaw>;
type TChPerfQltyRawSnapshot = SnapshotOut<typeof ChPerfQltyRaw>

export interface IChPerfQltyRaw extends TChPerfQltyRaw {}
export type TChPerfQltyRawKeys = keyof TChPerfQltyRawSnapshot & string;
export interface IChPerfQltyRawSnapshot extends TChPerfQltyRawSnapshot {}
export const createChPerfQltyRaw = () => 
  types.optional(ChPerfQltyRaw, {
  } as TChPerfQltyRaw);
