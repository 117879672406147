import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Box, Card, DialogContent, Divider, IconButton, Stack, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { memo, useEffect, useState } from 'react';
import Paper, { PaperProps } from '@mui/material/Paper';
import Draggable from 'react-draggable';
import { DialogAnimate } from './animate';
import { Scrollbar } from './Scrollbar';

interface CDialogProps {
  /** **열림 여부** */
  isDialogOpen: boolean;
  /** **제목** */
  dialogTitle: string;
  dialogSubTitle?: string;
  /** **내용** */
  children: React.ReactNode;
  /** **모달창 최대 크기** */
  maxWidth?: 'xm' | 'sm' | 'md' | 'lg' | 'xl';
  minHeight?: string;
  height?: string;
  hiddenModalBottom?: boolean;
  isOverflow?: boolean;
  /** 초기 모달 크기 */
  initialHeight?: number;
  /** **모달창 닫기 함수** */
  handleDialogClose: () => void;
  /** **스크롤 여부** */
  hasScroll?: boolean;
  /** **액션** */
  dialogActions?: React.ReactNode;
  /** full Width */
  fullWidth?: boolean;
  /** **모달 top 고정 ** */
  fixedTop?: boolean;
  /** **모달 KEY Number ** */
  keyId?: string;
  /** **body scrollbar 활성화 여부 ** */
  enableScrollbar?: boolean;
  isNotAnimate?: boolean;
}

/**
 * ## 기본 사용법
 *
 * > 모달창 컴포넌트로, 화면 위에 추가적인 창을 띄울 때 사용합니다.
 *
 * > 기본 구성은 모달 제목, 상단 닫기 버튼, 모달 내용 컴포넌트이며, 하위 버튼셋을 선택적으로 추가할 수 있습니다.
 *
 * > 상세보기, 등록, 수정 기능 구현 시 주로 사용합니다.
 *
 */

//  const styles = theme => ({
//   resizable: {
//       position: "relative",
//       "& .react-resizable-handle": {
//           position: "absolute",
//           width: 20,
//           height: 20,
//           bottom: 0,
//           right: 0,
//           background:
//               "url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ij48ZyBvcGFjaXR5PSIwLjMwMiI+PHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+')",
//           "background-position": "bottom right",
//           padding: "0 3px 3px 0",
//           "background-repeat": "no-repeat",
//           "background-origin": "content-box",
//           "box-sizing": "border-box",
//           cursor: "se-resize"
//       }
//   }
// });

const CDialog = memo(
  observer(function CDialogProps({
    isDialogOpen,
    dialogTitle,
    dialogSubTitle,
    maxWidth,
    minHeight,
    height,
    hiddenModalBottom = false,
    isOverflow = false, // enableScrollbar == false일 때 사용가능합니다.
    handleDialogClose,
    initialHeight,
    hasScroll = false,
    children,
    dialogActions,
    fullWidth = false,
    fixedTop = false,
    keyId = '',
    enableScrollbar = true,
    isNotAnimate = false,
    ...other
  }: CDialogProps) {
    const [fullScreen, setFullScreen] = useState(false);
    const [cWidth, setCWidth] = useState(0);
    const [initHeight, setInitHeight] = useState<string | undefined>(
      initialHeight ? initialHeight.toString() + 'px' : undefined,
    );
    const [minHeightValue, setMinHeight] = useState<string>(
      minHeight ? minHeight : '300px'
    );

    const PaperComponent = (props: PaperProps) => {
      useEffect(() => {
        const height = document.getElementById('Paper_' + keyId)?.clientHeight;
        !initHeight && height && setInitHeight((height + 120).toString() + 'px');
      }, []);
      return (
        <Draggable
          handle={'#draggable-dialog-title-' + keyId}
          cancel={'[class*="MuiDialogContent-root"]'}
        >
          <Paper {...props} />
        </Draggable>
      );
    };

    useEffect(() => {
      switch (maxWidth) {
        case 'xm':
          setCWidth(400);
          break;
        case 'sm':
          setCWidth(600);
          break;
        case 'md':
          setCWidth(900);
          break;
        case 'lg':
          setCWidth(1200);
          break;
        case 'xl':
          setCWidth(1500);
          break;
      }
    }, [maxWidth]);

    return (
      <DialogAnimate
        open={ isDialogOpen }
        onClose={ isNotAnimate ? undefined : handleDialogClose }
        PaperComponent={ PaperComponent }
        id="CDialog"
        PaperProps={{
          id: 'Paper_' + keyId,
          sx: {
            resize: 'both',
            minHeight: minHeightValue,
            minWidth: (cWidth * 1) / 3,
            maxWidth: '95%',
            maxHeight: fullScreen ? '95%' : '80vh',
            m: 0,
            width: fullScreen ? '95%' : cWidth,
            height: fullScreen ? '95%' : height ? height : initHeight ? initHeight : 'auto',
          },
        }}
        fixedTop={!fullScreen}
        className={keyId}
        sx={{ '.MuiDialog-container': { mt: 0 } }}
        {...other}
      >
        <Card sx={{ p: 2, pb: 2, height: '100%', minHeight: minHeightValue, minWidth: (cWidth * 1) / 3 }}>
          <Stack
            id={'draggable-dialog-title-' + keyId}
            onDoubleClick={() => setFullScreen(!fullScreen)}
            sx={{
              m: 0,
              cursor: 'pointer',
              width: '102%',
              ml: '-1%',
              pl: '1%',
              pr: '1%',
              pt: '16px',
              mt: '-16px',
            }}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >

            {/* 좌측 제목 */}
            <Typography variant="h4" color="#637381" sx={{ m: 1, opacity: 0.9 }}>
              {dialogTitle}
            </Typography>
            
            { dialogSubTitle && 
              <Box sx={{
                border:'1px solid #fff',
                padding: '5px 15px',
                borderRadius:'10px',
                backgroundColor:'#14202d',
              }}>
                <Typography variant="h6" color="#fff">
                  <pre>
                    {dialogSubTitle}
                  </pre>
                </Typography>
              </Box>
            }
            <IconButton onClick={handleDialogClose} sx={{ p: 0.5 }}>
              <CloseRoundedIcon />
            </IconButton>
          </Stack>
          <Divider sx={{ marginBottom: 1 }}></Divider>
          <DialogContent
            sx={{
              p: 1,
              overflow: (enableScrollbar == false ? (isOverflow ? `auto` : 'hidden') : 'hidden'),
              height: hiddenModalBottom ? '100%' : 'calc(100% - 120px)',
              '.MuiDialogContent-root > .MuiBox-root': {
                height: '100%',
                overflow: 'auto',
              },
              '.MuiDialogContent-root > form > .MuiBox-root': {
                height: '100%',
                overflow: 'auto',
              },
              '.MuiBox-root.modalButton': {
                width: '100%',
                position: 'fixed',
                bottom: 0,
                //bottom: '10px',
                left: 0,
                padding: '20px',
                zIndex: 10,
                mt: 0,
                pt: 0,
              },
            }}
          >
            {
              enableScrollbar ? 
                <Scrollbar
                  sx={{
                    height: '100%',
                    '& .simplebar-content': { height: '100%', display: 'flex', flexDirection: 'column' },
                  }}
                >
                  {children}
                </Scrollbar>
              : children
            }
          
          </DialogContent>
          {/* <DialogActions sx={{ paddingTop: 3.5 }}>{dialogActions}</DialogActions> */}
        </Card>
      </DialogAnimate>
    );
  }),
);

export default CDialog;
