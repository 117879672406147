import { EChartsOption, TooltipComponentOption } from 'echarts';
import { FORMSET_ITEM_LABEL } from '../components/settings/SettingConstEnum';
import { randomHexColor } from './commonUtil';

/**
 * dark theme
 * @returns 
 */
export function getDarkThemeJson() {
  return {
    "color": [
        "#dd6b66",
        "#759aa0",
        "#e69d87",
        "#8dc1a9",
        "#ea7e53",
        "#eedd78",
        "#73a373",
        "#73b9bc",
        "#7289ab",
        "#91ca8c",
        "#f49f42"
    ],
    "title": {
        "textStyle": {
            "color": "#eeeeee"
        },
        "subtextStyle": {
            "color": "#aaaaaa"
        }
    }
    ,"categoryAxis": {
        "axisLabel": {
            "show": true,
            "color": "#eeeeee"
        },
    }
    ,"valueAxis": {
        
        "axisLabel": {
            "show": true,
            "color": "#eeeeee"
        },
    }
    ,"toolbox": {
        "iconStyle": {
            "borderColor": "#eeeeee"
        },
        "emphasis": {
            "iconStyle": {
                "borderColor": "#eeeeee"
            }
        }
    }
    ,"legend": {
        "textStyle": {
            "color": "#eeeeee"
        }
    }
    ,"markPoint": {
        "label": {
            "color": "#eeeeee"
        },
        "emphasis": {
            "label": {
                "color": "#eeeeee"
            }
        }
    }
};
}

/**
 * makeTooltipDataHtmlWithPre 함수 파라미터 인터페이스
 */
export interface TooltipDataHtmlProps {
  marker?: string | undefined; // 범례 아이콘
  withPre: boolean; // 4주 평균 유무
  name: string; // 범례 이름
  thisValue: string | number; // 현재 데이터
  preValue?: string | number | undefined; // 4주 데이터
}

/**
 * 차트 툴팁 데이터 html 생성
 * @param name
 * @param thisValue
 * @param preValue
 * @returns
 */
export function makeTooltipDataHtmlWithPre(param: TooltipDataHtmlProps) {
  let html: string = '<br/>';
  if (param.marker) {
    html += param.marker;
  }
  html += ` ${param.name}`;

  if (param.withPre) {
    html += `
            <span style="margin-left:2px;font-size:10px;color:#666;">
                (${FORMSET_ITEM_LABEL.statisticsReporting.ratingStatistics.withPre} : ${
      param.preValue || '-'
    })
            </span>
        `;
  }

  html += `
        <span style="float:right;margin-left:20px;font-size:14px;color:#666;font-weight:900">
            ${param.thisValue || '-'}
        </span>
    `;
  return html;
}

/**
 * 차트 툴팁 기본 옵션
 * @returns
 */
export function getDefaultTooltip() {
  return {
    trigger: 'axis',
    axisPointer: {
      type: 'shadow',
      label: {
        show: true,
      },
    },
  };
}

/**
 * 차트 범례 기본 옵션
 * @returns
 */
export function getDefaultLegend() {
  return {
    // orient:'vertical',
    top: '40',
    left: 'right',
    type: 'scroll',
  };
}

/**
 * 차트 툴박스 기본 옵션
 * @returns
 */
export function getDefaultToolbox() {
  return {
    top: 'top',
    feature: {
      saveAsImage: {
          backgroundColor: 'black'
      },
      dataZoom: {},
    },
  };
}

/**
 * 차트 데이터 줌기능 기본 옵션
 * @returns
 */
export function getDefaultDataZoom() {
  return [
    {
      type: 'inside',
      xAxisIndex: [0],
      start: 1,
      end: 100,
      filterMode: 'none',
    },
    {
      type: 'inside',
      yAxisIndex: [0],
      start: 1,
      end: 100,
      filterMode: 'none',
    },
  ];
}

/**
 * 차트 기본 전체 옵션
 * @param title
 * @param xAxisData
 * @param series
 * @returns
 */
export function getDefaultOption(
  title: string | undefined,
  xAxisData: string[],
  series: any[] | [],
  tooltip?: any,
  noData?: any,
  xAxisType?: string,
) {
  // x축 회전율
  let rotate: number = xAxisData.length;
  if (rotate <= 20) {
    rotate = 0;
  } else if (rotate >= 60) {
    rotate = 60;
  }

  // 범례 색상
  let colors: string[] = ['#04d1e3','#0987c8','#0e81ff','#0daeea','#63db95','#05ffff','#0de8aa','#713fff','#9381ff','#aaa1c8','#e7c6ff','#f2a8ff','#f08080','#f8ad9d','#f49f42','#ffdab9','#eedd78','#eddea4','#daddd8','#b9baa3'];
  for (let i = colors.length; i < xAxisData.length; i++) {
    colors.push(randomHexColor());
  }

  return {
    color: colors,
    title: {
      text: title || '',
    },
    tooltip: tooltip || getDefaultTooltip(),
    legend: getDefaultLegend(),
    grid: {
      left: '1%',
      right: '1%',
      bottom: '8%',
      top: '80px',
      containLabel: true,
    },
    toolbox: getDefaultToolbox(),
    dataZoom: getDefaultDataZoom(),
    xAxis: 
    {
      type: xAxisType || 'category',
      boundaryGap: true,
      data: xAxisData,
      axisLine: {
        onZero: false
      },
      axisLabel: {
        rotate: rotate,
      },
      splitNumber: (xAxisData.length > 60 ? 60 : xAxisData.length),
    },
    yAxis: [
      {
        type: 'value',
      },
    ],
    graphic: noData,
    series: series,
  } as EChartsOption;
}

export function getNodata(){
    return {
        type: 'text',
        left: 'center',
        top: 'middle',
        style: { text: "데이터가 존재하지 않습니다.", fontSize: "24", fill: '#999999' }
    };
}
