import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { createModelCode } from '../model-code/ModelCode';

export const InputSw = types
  .model('InputSw')
  .props({
    equipSid: types.optional(types.number, 0),
    equipRole: createModelCode(),
    mainYn: types.optional(types.boolean, false),
    hostNm: types.optional(types.string, ''),
    mgmtIp: types.optional(types.string, ''),
    modelNm: types.optional(types.maybeNull(types.string), ''),
    frmwrVer: types.optional(types.maybeNull(types.string), ''),
    equipLo: types.optional(types.maybeNull(types.string), ''),
    equipMngr:  types.optional(types.maybeNull(types.string), ''),
    inPortSid: types.optional(types.maybeNull(types.number), 0),
    inPortNm: types.optional(types.maybeNull(types.string), ''),
    outPortSid: types.optional(types.maybeNull(types.number), 0),
    outPortNm: types.optional(types.maybeNull(types.string), ''),
    outBPortSid: types.optional(types.maybeNull(types.number), 0),
    outBPortNm: types.optional(types.maybeNull(types.string), ''),
    emgOutPortSid: types.optional(types.maybeNull(types.number), 0),
    emgOutPortNm: types.optional(types.maybeNull(types.string), ''),


    inPort: createModelCode(),
    outPort: createModelCode(),
    outBPort: createModelCode(),
    emgOutPort: createModelCode(),
  })
  .actions((self) => ({
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as IInputSw;
      (Object.keys(newProps) as TInputSwKeys[]).forEach((key) => {
        //@ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type TInputSw = Instance<typeof InputSw>;
type TInputSwSnapshot = SnapshotOut<typeof InputSw>;

export interface IInputSw extends TInputSw {}
export type TInputSwKeys = keyof TInputSwSnapshot & string;
export interface IInputSwSnapshot extends TInputSwSnapshot {}
export const createInputSw = () =>
  types.optional(InputSw, {
    equipSid: 0,
    mainYn: false,
    hostNm: '',
    mgmtIp: '',
    modelNm: null,
    frmwrVer: null,
    inPortSid: null,
    inPortNm: null,
    outPortSid: null,
    outPortNm: null,
    outBPortSid: null,
    outBPortNm: null,
    emgOutPortSid: null,
    emgOutPortNm: null,
  } as TInputSw);
