import { Pagination } from './../pagination/Pagination';
import { destroy, Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree';
import { UUID, ViewportData } from 'network-diagram';
import { ILink, ILinkSnapshotIn, Link } from './Link';
import { INode, INodeSnapshotIn, Node } from './Node';
import { mstCustomTypes, PersistenceState } from './types';

/**
 * # Topology Model
 *
 * 데이터센터, 클라우드, 장비, 라인, 라벨 등 네트워크 구성 데이터를 관리한다.
 */
export const Topology = types
  .model('Topology')
  // --------------------------------------------------------------------------
  .props({
    /** UUID v4 토폴로지 ID */
    id: types.identifier,

    /** 토폴로지 이름 */
    name: types.string,

    /** 토폴로지 구성 요소 */
    nodes: types.optional(types.map(Node), {}),

    /** 토폴로지 상 요소들의 연결 */
    links: types.optional(types.array(Link), []),

    /** 토폴로지 속성 */
    userData: types.maybe(mstCustomTypes.userData()),

    readonly: types.optional(types.boolean, false),

    published: types.optional(types.boolean, false),

    /** 토폴로지의 상태 */
    state: types.optional(mstCustomTypes.state(), PersistenceState.CLEAN),
  })
  // --------------------------------------------------------------------------
  .views((self) => ({
    /**
     * 상세 데이터 여부
     */
    get hasDetail() {
      return !!(self.nodes.size | self.links.length);
    },

    get json() {
      return JSON.stringify(self);
    },
    // get parent(): INetworkDiagramStore | null {
    //   try {
    //     return getParent<INetworkDiagramStore>(self);
    //   } catch (e) {
    //     return null;
    //   }
    // },
    get viewportData(): ViewportData {
      return JSON.stringify(self) as ViewportData;
    },
  }))
  // --------------------------------------------------------------------------
  // MUTATEs ONLY! - 모델 상태를 변경
  // REQUESTs는 스토어 모델에 작성하세요.
  // ex) yarn gen model topology --store
  .actions((self) => ({
    setName: (name: string) => {
      if (self.name !== name) {
        self.name = name;
        self.state = PersistenceState.DIRTY;
      }
    },
    addNode: (node: INode | INodeSnapshotIn) => {
      self.nodes.set(node.id, node);
      self.state = PersistenceState.DIRTY;
    },

    removeNode: (node: INode | INodeSnapshotIn) => {
      destroy(node);
      self.state = PersistenceState.DIRTY;
    },

    addLink: (link: ILink | ILinkSnapshotIn) => {
      self.links.push(link);
      self.state = PersistenceState.DIRTY;
    },

    removeLink: (link: ILink | ILinkSnapshotIn) => {
      destroy(link);
      self.state = PersistenceState.DIRTY;
    },

    /*
     * 여러 prop을 한번에 수정
     */
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as ITopology;
      (Object.keys(newProps) as TTopologyKeys[]).forEach((key) => {
        // @ts-ignore
        self[key] = newProps[key];
      });
    },
  }));
// .actions((self) => ({
//   afterCreate: () => {
//   },
// }));

// --------------------------------------------------------------------------
type TTopology = Instance<typeof Topology>;
type TTopologySnapshotIn = SnapshotIn<typeof Topology>;
type TTopologySnapshotOut = SnapshotOut<typeof Topology>;

/** 토폴로지 모델 인터페이스 */
export interface ITopology extends TTopology {}

/** 토폴로지 모델의 속성 키 */
export type TTopologyKeys = keyof TTopologySnapshotOut & string;

/** 토폴로지 모델의 스냅샷 */
export interface ITopologySnapshotOut extends TTopologySnapshotOut {}
export interface ITopologySnapshotIn extends TTopologySnapshotIn {}

/** 새로운 토폴로지 모델 생성 */
export const createTopology = (args?: ITopologySnapshotIn) => {
  return Topology.create({
    id: UUID.generate(),
    name: 'Undefined',
    nodes: {},
    links: [],
    userData: {},
    state: 'NEW',
    ...args,
  });
};
