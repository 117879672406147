import { ApiResponse } from 'apisauce';
import { Api } from '../api/api';
import { getGeneralApiProblem } from '../api/api-problem';
import { IResponseSnapshot, TRatingStatisticsFilter } from '../../models';
import { TGetRatingStatisticssResult } from './RatingStatisticsTypes';
import { dateToTimestamp } from '../../utils/dateExchanger';
import { selectOptions } from '../../components/forms/CSelect';

const makeParam = (filter: TRatingStatisticsFilter) => {
  /**
   * 조회조건
   */
  let params: any = filter;

  //@ts-ignore
  delete params.dataType;
  //@ts-ignore
  delete params.regionList;
  //@ts-ignore
  delete params.chSvcIdList;

  if (params.statDtSt) {
    params.statDtSt = dateToTimestamp(params.statDtSt);
    params.statDtEd = dateToTimestamp(params.statDtEd);
  }
  return params;
};
/**
 * # RatingStatistics Api 서비스
 *
 * 서비스의 설명을 작성하세요.
 *
 * ## 사용방법
 *
 * ```ts
 * const service = new RatingStatisticsApi();
 *
 * ```
 */
export class RatingStatisticsApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  /**
   * 시청률 통계 데이터를 제공한다.
   * @param filter
   * @returns
   */
  async getRatingsStat(filter: TRatingStatisticsFilter): Promise<TGetRatingStatisticssResult> {
    try {
      /**
       * 조회조건
       */
      let chSvcIdList: string = '';

      // 채널서비스아이디 리스트
      if (filter.chSvcIdList) {
        filter.chSvcIdList.forEach((item: selectOptions, index: number) => {
          chSvcIdList += `chSvcIdList=${Number(item.code)}&`;
        });
        chSvcIdList = chSvcIdList.substring(0, chSvcIdList.length - 1);
      }
      let params: any = makeParam(filter);

      /**
       * Request
       */
      let url = `/stat/mbs/ratings/${params.statUnit}?${chSvcIdList}`;
      const response: ApiResponse<any> = await this.api.apisauce.get(url, params);

      /**
       * Reesponse
       */
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      return {
        kind: 'ok',
        data: response.data.data,
        responseInfo: response.data as IResponseSnapshot,
      };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  /**
   * 점유율 통계 데이터를 제공한다.
   * @param filter
   * @returns
   */
  async getOccupyStat(filter: TRatingStatisticsFilter): Promise<TGetRatingStatisticssResult> {
    try {
      /**
       * 조회조건
       */
      let chSvcIdList: string = '';

      // 채널서비스아이디 리스트
      if (filter.chSvcIdList) {
        filter.chSvcIdList.forEach((item: selectOptions, index: number) => {
          chSvcIdList += `chSvcIdList=${Number(item.code)}&`;
        });
        chSvcIdList = chSvcIdList.substring(0, chSvcIdList.length - 1);
      }
      let params: any = makeParam(filter);

      /**
       * Request
       */
      let url = `/stat/mbs/occupy/${params.statUnit}?${chSvcIdList}`;
      const response: ApiResponse<any> = await this.api.apisauce.get(url, params);
      
      /**
       * Reesponse
       */
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      return {
        kind: 'ok',
        data: response.data.data,
        responseInfo: response.data as IResponseSnapshot,
      };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  /**
   * 시청가구 통계 데이터를 제공한다.
   * @param filter
   * @returns
   */
  async getViewStat(filter: TRatingStatisticsFilter): Promise<TGetRatingStatisticssResult> {
    try {
      /**
       * 조회조건
       */
      let regionList: string = '';

      // 지역코드 리스트
      if (filter.regionList) {
        filter.regionList.forEach((item: selectOptions, index: number) => {
          regionList += `regionList=${item.code}&`;
        });
        regionList = regionList.substring(0, regionList.length - 1);
      }
      let params: any = makeParam(filter);

      /**
       * Request
       */
      let url = `/stat/mbs/views/${params.statUnit}?${regionList}`;
      const response: ApiResponse<any> = await this.api.apisauce.get(url, params);

      /**
       * Reesponse
       */
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      return {
        kind: 'ok',
        data: response.data.data,
        responseInfo: response.data as IResponseSnapshot,
      };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }
}
