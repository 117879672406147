import { array } from 'yup';
import { ApiResponse } from 'apisauce';
import {  IPaginationSnapshot, IRealtimeMonitoringAlarmFilter, IResponseSnapshot } from '../../models';
import { dateToTimestamp, timestampToDate } from '../../utils/dateExchanger';
import { Api } from '../api/api';
import { getGeneralApiProblem } from '../api/api-problem';
import {
  IRealTimeAlarm,
  IRealTimeAlarmFilter,
  IRealTimeAlarmSnapshot,
} from '../../models/alarm/RealTimeAlarm';
import {
  TGetAlarmSetupResult,
  TGetMonitoringAlarmsResult,
  TGetRealTimeAlarmResult,
  TGetRealTimeAlarmsResult,
  TUpdateRealTimeAlarmResult,
} from './AlarmRealTimeTypes';
import moment from 'moment';
import { IAlarmSetupSnapshot } from '../../models/alarm-store/AlarmSetup';

/**
 * # realtime Alarm Api 서비스
 *
 * 서비스의 설명을 작성하세요.
 *
 * ## 사용방법
 *
 * ```ts
 * const service = new RealTimeAlarmApi();
 *
 * ```
 */

export class RealTimeAlarmApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  async gets(alarmFilter?: IRealTimeAlarmFilter): Promise<TGetRealTimeAlarmsResult> {
    try {
      const url = '/event';
      let params: any = { ...alarmFilter };

      if (params.perceivedSeverity && typeof params.perceivedSeverity === 'object') {
        params.perceivedSeverity = params.value;
      }

      (params.eventTimeSt) && (params.eventTimeSt = moment(params.eventTimeSt).format('YYYY-MM-DD 00:00:00'));
      (params.eventTimeEd) && (params.eventTimeEd = moment(params.eventTimeEd).format('YYYY-MM-DD 23:59:59'));

      let res = {};
      // setTimeout(() => {
      const response: ApiResponse<any> = await this.api.apisauce.get(url, params)
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseIfno: IResponseSnapshot = response.data;
      res = {
        kind: 'ok',
        alarms: response.data.data,
        responseInfo: responseIfno,
      };
      // }, 10000);

      return {
        kind: 'ok',
        alarms: response.data.data,
        responseInfo: responseIfno,
      };

    } catch (e) {
      return { kind: 'bad-data' };
    }
  }


  async download(alarmFilter?: IRealTimeAlarmFilter): Promise<any> {
    // Request URI		/v1/excel/service/{service}/target/{target}
    let params: string = '';
    Object.entries({ ...alarmFilter }).map(([key, value]) => {
      if (value) {
        if (key === 'eventTimeSt') {
          value = moment(value.toString()).format('YYYY-MM-DD 00:00:00');
        } else if (key === 'eventTimeEd') {
          value = moment(value.toString()).format('YYYY-MM-DD 23:59:59');
        }
        params += key + '=' + value + '&';
      }
    });

    const service = 'event';
    const target = 'active';
    const time = moment().format('YYYYMMDD-HHmmss');

    const url: string = `/excel/service/${service}/target/${target}?` + params.substring(0, params.length - 1);
    return this.api.download(url, '');//, 'ChannelAI-Alarm-Active.xls');
  }

  async downloadExcel(alarmFilter?: IRealtimeMonitoringAlarmFilter): Promise<any> {
    // Request URI		/v1/excel/service/{service}/target/{target}
    let params: string = '';
    Object.entries({ ...alarmFilter }).map(([key, value]) => {
      if (value) {
        if (key === 'eventTimeSt' || key === 'eventTimeEd' || key === 'clearanceTimeSt'|| key === 'clearanceTimeEd') {
          value = moment(value).format('YYYY-MM-DD HH:mm:ss');
          params += key + '=' + value + '&';
        } else if( Array.isArray(value) && value.length > 0){
          value.forEach((el) => params += key + '=' + el + '&')
        }else if (!Array.isArray(value)){
          params += key + '=' + value + '&';
        }
      }
    }); 
    const service = 'event';
    const target = 'history';

    const url: string = `/excel/service/${service}/target/${target}?` + params.substring(0, params.length - 1);
    return this.api.download(url, '');//, 'ChannelAI-Alarm-Active.xls');
  }

  async getRelateds(eventId: string): Promise<TGetRealTimeAlarmsResult> {
    try {
      const url = `/event/${eventId}/correlation`;
      const response: ApiResponse<any> = await this.api.apisauce.get(url);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseIfno: IResponseSnapshot = response.data;

      return {
        kind: 'ok',
        alarms: response.data.data,
        responseInfo: responseIfno,
      };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async get(eventId: string): Promise<TGetRealTimeAlarmResult> {
    try {
      const url: string = `/event/${eventId}`;
      const response: ApiResponse<any> = await this.api.apisauce.get(url);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseIfno: IResponseSnapshot = response.data;

      const alarm: IRealTimeAlarmSnapshot = response.data.data;

      if (responseIfno.resultCode === 'S') {
        return {
          kind: 'ok',
          alarm: alarm,
          responseInfo: responseIfno,
        };
      }

      return {
        kind: 'ok',
        alarm: {} as IRealTimeAlarmSnapshot,
        responseInfo: responseIfno,
      };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async update(eventId: string, type: string): Promise<TGetRealTimeAlarmResult> {
    try {
      const url: string = `/event/${eventId}/${type}`;
      const response: ApiResponse<any> = await this.api.apisauce.get(url);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseIfno: IResponseSnapshot = response.data;

      const alarm: IRealTimeAlarmSnapshot = response.data.data;

      if (responseIfno.resultCode === 'S') {
        return {
          kind: 'ok',
          alarm: alarm,
          responseInfo: responseIfno,
        };
      }

      return {
        kind: 'ok',
        alarm: {} as IRealTimeAlarmSnapshot,
        responseInfo: responseIfno,
      };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async multiProc(eventId: string[], type: string): Promise<TGetRealTimeAlarmsResult> {

    try {

      // let ids: string = '?';
      // eventId.forEach((id: string) => {
      //   ids += `eventIdList=${id}&`;
      // });
      // ids = ids.substring(0, ids.length - 1);

      const url: string = `/event/${type}`;//${ids}`;

      const params = {
        eventIdList: eventId.concat()
      };
      const response: ApiResponse<any> = await this.api.apisauce.put(url, params);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseIfno: IResponseSnapshot = response.data;

      if (responseIfno.resultCode === 'S') {
        return {
          kind: 'ok',
          responseInfo: responseIfno,
        };
      }

      return {
        kind: 'ok',
        responseInfo: responseIfno,
      };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  /**
   * INF_CAIMS_00019
   * 해당 발생 알람에 대한 운영 노트를 수정한다.
   * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=2018996941
   * @param alarm
   * @returns
   */
  async put(alarm: IRealTimeAlarm): Promise<TUpdateRealTimeAlarmResult> {
    const row = alarm;

    try {
      const url: string = '/event/' + row.eventId + '/note';
      const params = createSendData(row);
      const response: ApiResponse<any> = await this.api.apisauce.put(url, params);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseIfno: IResponseSnapshot = response.data;

      const alarm: IRealTimeAlarmSnapshot = response.data.data;

      if (responseIfno.resultCode === 'S') {
        return {
          kind: 'ok',
          alarm: alarm,
          responseInfo: responseIfno,
        };
      }

      return {
        kind: 'ok',
        alarm: {} as IRealTimeAlarmSnapshot,
        responseInfo: responseIfno,
      };
    } catch (e) {
      return {
        kind: 'bad-data',
      };
    }
  }

  async getRealtimeMonitoringAlarm(
    alarmFilter?: IRealtimeMonitoringAlarmFilter,
    pagination?: IPaginationSnapshot,
  ): Promise<TGetMonitoringAlarmsResult> {
    try {
      const url = '/event/history';
      let params: any = { ...alarmFilter };

      // if (params.perceivedSeverity && typeof params.perceivedSeverity === 'object') {
      //   params.perceivedSeverity = params.value;
      // }

      (params.eventTimeSt) && (params.eventTimeSt = moment(params.eventTimeSt).format('YYYY-MM-DD HH:mm:ss'));
      (params.eventTimeEd) && (params.eventTimeEd = moment(params.eventTimeEd).format('YYYY-MM-DD HH:mm:ss')); 

      (params.clearanceTimeSt) && (params.clearanceTimeSt = moment(params.clearanceTimeSt).format('YYYY-MM-DD HH:mm:ss'));
      (params.clearanceTimeEd) && (params.clearanceTimeEd = moment(params.clearanceTimeEd).format('YYYY-MM-DD HH:mm:ss'));


      if(params.eventStateList && params.eventStateList.length>0){
        params.eventStateList = params.eventStateList.join(',')
      }

      if(params.perceivedSeverityList && params.perceivedSeverityList.length>0){
        params.perceivedSeverityList = params.perceivedSeverityList.join(',')
      }

      if(params.probableCauseList && params.probableCauseList.length>0){
        params.probableCauseList = params.probableCauseList.join(',')
      }

      params.size= 10000

      let res = {};

      const response: ApiResponse<any> = await this.api.apisauce.get(url, params)
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseIfno: IResponseSnapshot = response.data;
      res = {
        kind: 'ok',
        alarms: response.data.data,
        responseInfo: responseIfno,
      };

      return {
        kind: 'ok',
        alarms: response.data.data,
        responseInfo: responseIfno,
        pagination: response.data.pagination,
      };

    } catch (e) {
      console.log(e)
      return { kind: 'bad-data' };
    }
  }

  async getAlarmSetup(monitorType: String): Promise<TGetAlarmSetupResult>{
    try {
      const url = '/event/sound/config/monitorType/' + monitorType

      let res = {};

      const response: ApiResponse<any> = await this.api.apisauce.get(url)
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;
      res = {
        kind: 'ok',
        alarms: response.data.data,
        responseInfo: responseInfo,
      };

      return {
        kind: 'ok',
        setup: response.data.data,
        responseInfo: responseInfo,
        // pagination: response.data.pagination,
      };
    } catch (e) {
      console.log(e);
      return { kind: 'bad-data' };
    }
  }

  async updateSetupData(data: IAlarmSetupSnapshot[], files: File[]): Promise<any> {
    try {
      const url: string = '/event/sound/config';
      const form = new FormData();
      data.map((item) => {
        item.fileConts = '';
      });
      form.append('params', JSON.stringify(data));
      // form.append('uploadFiles', files)
      files.forEach((value) => form.append('uploadFiles', value));
      await this.api.apisauce.setHeader('Content-Type', 'multipart/form-data');
      const response: ApiResponse<any> = await this.api.apisauce.post(url, form);
      await this.api.apisauce.setHeader('Content-Type', 'application/json');

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseIfno: IResponseSnapshot = response.data;

      if (responseIfno.resultCode === 'S') {
        return {
          kind: 'ok',
          responseInfo: responseIfno,
        };
      }

      return {
        kind: 'ok',
        responseInfo: responseIfno,
      };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async getAlarmSound(monitorType: String, svrt: number): Promise<any> {
    try {
      const url = `/event/sound/monitorType/${monitorType}/svrt/${svrt}`;

      let res = {};
      const response: ApiResponse<any> = await this.api.downloadFileToURL(url, 'file123.wav');

      if (response.ok) {
        res = {
          kind: 'ok',
          responseInfo: response,
        };
      } else {
        res = {
          kind: 'error',
          responseInfo: response,
        };
      }

      return res;
    } catch (e) {
      console.log(e);
      return { kind: 'bad-data' };
    }
  }
}


const createSendData = (alarm: IRealTimeAlarmSnapshot) => {
  let result: any = {};
  if (alarm.oprtrNote !== undefined) {
    result.oprtrNote = alarm.oprtrNote;
  }

  if (alarm.oprtrNoteCtg !== undefined) {
    result.oprtrNoteCtg = alarm.oprtrNoteCtg;
  }

  if (alarm.noteProblCause !== undefined) {
    result.noteProblCause = alarm.noteProblCause;
  }

  return result;
};
