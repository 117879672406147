import { ApiResponse } from "apisauce";
import { Api } from "../api/api";
import { getGeneralApiProblem } from "../api/api-problem";
import {IChPerfQltyRaw, IMisTransMissionFilter} from '../../models';
import { 
  TGetChPerfQltyRawsResult,
  TGetChPerfQltyRawResult,
  TPostChPerfQltyRawResult,
  TUpdateChPerfQltyRawResult,
  TDeleteChPerfQltyRawResult
 } from "./ChPerfQltyRawTypes";
import {dateToTimestamp} from "../../utils/dateExchanger";

  /**
  * # ChPerfQltyRaw Api 서비스
  * 
  * 서비스의 설명을 작성하세요.
  * 
  * ## 사용방법
  * 
  * ```ts
  * const service = new ChPerfQltyRawApi();
  * 
  * ```
  */
 export class ChPerfQltyRawApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  async get(filter?:IMisTransMissionFilter): Promise<TGetChPerfQltyRawResult> {
    try {
      let url = `/misTransmission`;
      const chSvcId = filter?.chSvcId;
      let chSvcIdList:string = '';
      let params: any = {};

      if(chSvcId)
      {
        params = filter;
        chSvcIdList += `chSvcIdList=${Number(chSvcId?.code)}`
        url += `?${chSvcIdList}`;
      }
      params.statDtSt = dateToTimestamp(params.statDtSt);
      params.statDtEd = dateToTimestamp(params.statDtEd);
      delete params.chSvcId;

      const response: ApiResponse<any> = await this.api.apisauce.get( url, params );
  
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const chPerfQltyRaw: IChPerfQltyRaw = response.data.data;
      return { kind: "ok", chPerfQltyRaw };

    } catch (e) {
      return { kind: "bad-data" };
    }
  }
}