import { IResponseSnapshot } from './../../models/response/Response';
import { ApiResponse } from 'apisauce';
import { Api } from '../api/api';
import { getGeneralApiProblem } from '../api/api-problem';
import { IPaginationSnapshot } from './../../models/pagination/Pagination';
import { IRoleSnapshot } from './../../models/role/Role';
import { timestampToDate } from './../../utils/dateExchanger';
import { TGetRolesResult } from './RoleTypes';

/**
 * # Role Api 서비스
 *
 * 서비스의 설명을 작성하세요.
 *
 * ## 사용방법
 *
 * ```ts
 * const service = new RoleApi();
 *
 * ```
 */
export class RoleApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  /**
   * INF_CAIMS_00228
   * 전체 권한을 조회한다.
   * @param pagination
   * @returns
   */
  async gets(pagination: IPaginationSnapshot): Promise<TGetRolesResult> {
    try {
      const url: string = `/role`;
      const params = {
        page: pagination.page,
        size: pagination.size,
      };

      const response: ApiResponse<any> = await this.api.apisauce.get(url, params);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;

      if (responseInfo.resultCode === 'S') {
        if (response.data.data) {
          response.data.data.forEach((item: IRoleSnapshot) => {
            item.regDt = timestampToDate(Number.parseInt(item.regDt));
            item.updDt = timestampToDate(Number.parseInt(item.updDt));
          });
          const roles: IRoleSnapshot[] = response.data.data;
          return { kind: `ok`, responseInfo: responseInfo, roles: roles };
        }
      }

      return { kind: 'ok', responseInfo: responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }
}
