import { createChangeValue } from './../change-value/ChangeValue';
import { Instance, SnapshotOut, types } from 'mobx-state-tree';

/**
 * # AcoHistoryContent
 *
 * AcoHistoryContent을 설명하세요.
 */
export const AcoHistoryContent = types
  .model('AcoHistoryContent')
  // --------------------------------------------------------------------------
  .props({
    operation: types.optional(types.maybeNull(types.string), null),
    name: types.optional(types.maybeNull(types.string), null),
    equipSid: types.maybeNull(createChangeValue()),
    srcInType: types.maybeNull(createChangeValue()),
    mainYn: types.maybeNull(createChangeValue()),
    hostNm: types.maybeNull(createChangeValue()),
    psiPid: types.maybeNull(createChangeValue()),
    patPid: types.maybeNull(createChangeValue()),
    inPortSid: types.maybeNull(createChangeValue()),
    inPortNm: types.maybeNull(createChangeValue()),
    inBPortSid: types.maybeNull(createChangeValue()),
    inBPortNm: types.maybeNull(createChangeValue()),
    skyAraInPortSid: types.maybeNull(createChangeValue()),
    skyAraInPortNm: types.maybeNull(createChangeValue()),
    lgInPortSid: types.maybeNull(createChangeValue()),
    lgInPortNm: types.maybeNull(createChangeValue()),
    psiInPortSid: types.maybeNull(createChangeValue()),
    psiInPortNm: types.maybeNull(createChangeValue()),
    outPortSid: types.maybeNull(createChangeValue()),
    outPortNm: types.maybeNull(createChangeValue()),
    outBPortSid: types.maybeNull(createChangeValue()),
    outBPortNm: types.maybeNull(createChangeValue()),
    skyOutPortSid: types.maybeNull(createChangeValue()),
    skyOutPortNm: types.maybeNull(createChangeValue()),
    seeznOutPortSid: types.maybeNull(createChangeValue()),
    seeznOutPortNm: types.maybeNull(createChangeValue()),
    ktInMip: types.maybeNull(createChangeValue()),
    skyInMip: types.maybeNull(createChangeValue()),
    lgInMip: types.maybeNull(createChangeValue()),
    outMip: types.maybeNull(createChangeValue()),
  })
  // --------------------------------------------------------------------------
  // MUTATEs ONLY! - 모델 상태를 변경
  // REQUESTs는 스토어 모델에 작성하세요.
  // ex) yarn gen model acoHistoryContent --store
  .actions((self) => ({
    /*
     * 여러 prop을 한번에 수정
     */
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as IAcoHistoryContent;
      (Object.keys(newProps) as TAcoHistoryContentKeys[]).forEach((key) => {
        //@ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type TAcoHistoryContent = Instance<typeof AcoHistoryContent>;
type TAcoHistoryContentSnapshot = SnapshotOut<typeof AcoHistoryContent>;

export interface IAcoHistoryContent extends TAcoHistoryContent {}
export type TAcoHistoryContentKeys = keyof TAcoHistoryContentSnapshot & string;
export interface IAcoHistoryContentSnapshot extends TAcoHistoryContentSnapshot {}
export const createAcoHistoryContent = () =>
  types.optional(AcoHistoryContent, {} as TAcoHistoryContent);
