import { withRootStore } from './../extensions/with-root-store';
import { createResponse, IResponse, IResponseSnapshot } from './../response/Response';
import { IRoleSnapshot, Role, IRole } from './../role/Role';
import { createPagination, IPaginationSnapshot, IPagination } from './../pagination/Pagination';
import { Instance, SnapshotOut, types } from 'mobx-state-tree';

import { withEnvironment } from '../extensions/with-environment';
import { RoleApi, TGetRolesResult } from '../../services';

/**
 * # RoleStore
 *
 * RoleStore을 설명하세요.
 */
export const RoleStore = types
  .model('RoleStore')
  // --------------------------------------------------------------------------
  .props({
    roles: types.optional(types.array(Role), []),
    pagination: createPagination(),
  })
  .extend(withEnvironment)
  .extend(withRootStore)
  // eslint-disable-line @typescript-eslint/no-unused-vars
  .views((self) => ({}))
  // --------------------------------------------------------------------------
  // MUTATEs - 모델 상태를 변경
  .actions((self) => ({
    /**
     * roles을 교체
     *
     * @param `roles` 새로운 모델의 배열
     */
    setRoles: (roles: IRoleSnapshot[]) => {
      self.roles.replace(roles as IRole[]);
    },
    setPagination: (pagination: IPaginationSnapshot) => {
      self.pagination = pagination as IPagination;
    },
  }))
  // --------------------------------------------------------------------------
  // REQUESTs - 서비스 요청 및 기타 인터페이스 요청
  .actions((self) => ({
    /**
     * 전체 목록을 Api를 통해 조회
     *
     * 조회한 결과로 Roles를 교체한다. 실패시 에러 로그를 남긴다.
     */
    gets: async () => {
      try {
        const roleApi: RoleApi = new RoleApi(self.environment.api);
        const result: TGetRolesResult = await roleApi.gets(self.pagination);

        if (self.rootStore.responseStore.getResponseResult(result)) {
          if (result.roles) {
            self.setRoles(result.roles);
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },
  }));

// --------------------------------------------------------------------------
type TRoleStore = Instance<typeof RoleStore>;
type TRoleStoreSnapshot = SnapshotOut<typeof RoleStore>;

export interface IRoleStore extends TRoleStore {}
export type TRoleStoreKeys = keyof TRoleStoreSnapshot & string;
export interface IRoleStoreSnapshot extends TRoleStoreSnapshot {}
export const createRoleStore = () => types.optional(RoleStore, {} as TRoleStore);
