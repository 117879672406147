import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import {
  StbStatisticsApi,
  TGetRegionResult,
  TGetStbStatisticsPacketLossResult,
  TGetStbStatisticsAlarmResult,
} from '../../services';
import { withEnvironment } from '../extensions/with-environment';
import { withRootStore } from '../extensions/with-root-store';
import { createModelCode, IModelCode, IModelCodeSnapshot } from '../model-code/ModelCode';
import { createResponse } from '../response/Response';
import {
  createStbStatistics,
  IStbStatistics,
  IStbStatisticsSnapshot,
  StbStatistics,
  TStbStatisticsFilter,
} from '../stb-statistics/StbStatistics';

export const StbStatisticsStore = types
  .model('StbStatisticsStore')
  .props({
    stbStatisticss: types.optional(types.array(StbStatistics), []),
    selectedStbStatistics: createStbStatistics(),
    responseInfo: createResponse(),
    regionList: types.array(createModelCode()),
    stbStatisticssForOneViewRTP: types.optional(types.array(StbStatistics), []),
    stbStatisticssForOneViewAlarm: types.optional(types.array(StbStatistics), []),
  })
  .extend(withEnvironment)
  .extend(withRootStore)
  .views((self) => ({}))
  .actions((self) => ({
    setStbStatisticss: (stbStatisticss: IStbStatisticsSnapshot[]) => {
      self.stbStatisticss.replace(stbStatisticss as IStbStatistics[]);
    },
    setRegions: (regionList: IModelCodeSnapshot[]) => {
      self.regionList.replace(regionList as IModelCode[]);
    },
    setStbStatisticssForOneViewRTP: (stbStatisticss: IStbStatisticsSnapshot[]) => {
      self.stbStatisticssForOneViewRTP.replace(stbStatisticss as IStbStatistics[]);
    },
    resetStbStatisticssForOneViewRTP: () => {
      self.stbStatisticssForOneViewRTP.replace([]);
    },
    setStbStatisticssForOneViewAlarm: (stbStatisticss: IStbStatisticsSnapshot[]) => {
      self.stbStatisticssForOneViewAlarm.replace(stbStatisticss as IStbStatistics[]);
    },
    resetStbStatisticssForOneViewAlarm: () => {
      self.stbStatisticssForOneViewAlarm.replace([]);
    },
  }))
  .actions((self) => ({
    /**
     * INF_CAIMS_00300
     * UCEMS 패킷로스 통계 데이터를 제공한다.
     * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=1207726427
     * @param stbStatisticsFilter
     * @returns
     */
    packetLossGets: async (stbStatisticsFilter?: TStbStatisticsFilter) => {
      self.setStbStatisticss([]);
      try {
        const stbStatisticsApi: StbStatisticsApi = new StbStatisticsApi(self.environment.api);
        let result: TGetStbStatisticsPacketLossResult;

        result = await stbStatisticsApi.packetLossGets(stbStatisticsFilter);

        if (self.rootStore.responseStore.getResponseResult(result)) {
          if (result.packetloss) {
            self.setStbStatisticss(result.packetloss);
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },

    /**
     * INF_CAIMS_00301
     * UCEMS 장애 알람 통계 데이터를 제공한다.
     * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=1089610836
     * @param stbStatisticsFilter
     * @returns
     */
    alarmGets: async (stbStatisticsFilter?: TStbStatisticsFilter) => {
      self.setStbStatisticss([]);
      try {
        const stbStatisticsApi: StbStatisticsApi = new StbStatisticsApi(self.environment.api);
        let result: TGetStbStatisticsAlarmResult;

        result = await stbStatisticsApi.alarmGets(stbStatisticsFilter);

        if (self.rootStore.responseStore.getResponseResult(result)) {
          if (result.alarm) {
            self.setStbStatisticss(result.alarm);
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },
  }));

type TStbStatisticsStore = Instance<typeof StbStatisticsStore>;
type TStbStatisticsStoreSnapshot = SnapshotOut<typeof StbStatisticsStore>;

export interface IStbStatisticsStore extends TStbStatisticsStore {}
export type TStbStatisticsStoreKeys = keyof TStbStatisticsStoreSnapshot & string;
export interface IStbStatisticsStoreSnapshot extends TStbStatisticsStoreSnapshot {}
export const createStbStatisticsStore = () =>
  types.optional(StbStatisticsStore, {} as TStbStatisticsStore);
