// @mui
import { styled } from '@mui/material/styles';
// components
import { Page } from '../components/Page';
// sections
import {
  HomeHero,
  HomeMinimal,
  HomeDarkMode,
  HomeLookingFor,
  HomeColorPresets,
  HomePricingPlans,
  HomeAdvertisement,
  HomeCleanInterfaces,
  HomeHugePackElements,
} from '../components/home';
import { useStores } from '../models';
import { ILoadingStore } from '../models/loading-store/LoadingStore';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  height: '100%',
}));

const ContentStyle = styled('div')(({ theme }) => ({
  overflow: 'hidden',
  position: 'relative',
  backgroundColor: theme.palette.background.default,
}));

export const CallApiToStore = async (
  storeFunction: Promise<void>,
  type: 'page' | 'api' ,
  loadingStore: ILoadingStore,
) => {
  // const rootStore = useStores();
  // const {loadingStore} = rootStore;
  // if(type === 'page'){
  //   loadingStore.tableLoadingNoMsg();
  // }
  // else{
  //   loadingStore.allLoadingNoMsg();
  // }
    loadingStore.allLoading();
  await storeFunction.then(() => {
    // if(type === 'page'){
    //   loadingStore.tableCloseLoadingNoMsg();
    // }
    // else{
    //   loadingStore.closeLoadingNoMsg();
    // }
    loadingStore.closeLoadingNoMsg();
  });
};

// ----------------------------------------------------------------------

export default function HomePage() {
  return (
    <Page title="The starting point for your next project">
      <RootStyle>
        <HomeHero />
        <ContentStyle>
          <HomeMinimal />

          <HomeHugePackElements />

          <HomeDarkMode />

          <HomeColorPresets />

          <HomeCleanInterfaces />

          <HomePricingPlans />

          <HomeLookingFor />

          <HomeAdvertisement />
        </ContentStyle>
      </RootStyle>
    </Page>
  );
}
