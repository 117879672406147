import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { type } from 'os';
import { createModelCode } from '../model-code/ModelCode';
import { createSimpleUser } from '../simple-user/SimpleUser';

/**
 * # PpCompany
 *
 * PpCompany을 설명하세요.
 */
export const PpCompany = types
  .model('PpCompany')
  .props({
    ppSid: types.optional(types.number, 0),
    ppNm: types.optional(types.string, ''),
    ppLo: types.optional(types.maybeNull(types.string), ''),
    ppMngr: types.optional(types.maybeNull(types.string),''),
    encodFwdYn: types.optional(types.boolean, false),
    ppMemo: types.optional(types.maybeNull(types.string), null),
    regDt: types.optional(types.string, ''),
    updDt: types.optional(types.string, ''),
    affiliate: types.optional(types.maybeNull(types.string), ''),
    monitorCircuitNoM: types.optional(types.maybeNull(types.string), ''),
    monitorCircuitNoB: types.optional(types.maybeNull(types.string), ''),

    //신버전
    regUser: types.maybeNull(createSimpleUser()),
    updUser: types.maybeNull(createSimpleUser()),

    //채널에서 사용되는 파라미터
    msppNm: types.optional(types.maybeNull(types.string), ''),
    msppPort: types.optional(types.maybeNull(types.string), ''),
    msppBNm: types.optional(types.maybeNull(types.string), ''),
    msppBPort: types.optional(types.maybeNull(types.string), ''),
    niSid: types.optional(types.maybeNull(types.number),null),
    niNm: types.optional(types.maybeNull(types.string),''),
    skylifeCircuitSpatnYn: types.optional(types.maybeNull(types.boolean), false),
    hcnCircuitSpatnYn: types.optional(types.maybeNull(types.boolean), false),
    ktTrsmDept: types.optional(types.maybeNull(types.string), ''),
    ktTrmNetVendr: types.maybeNull(createModelCode()),
    ktCircuitNoMain: types.optional(types.maybeNull(types.string), ''),
    ktCircuitNoBackup: types.optional(types.maybeNull(types.string), ''),
    ktCircuitNoSpare: types.optional(types.maybeNull(types.string), ''),
    skylifeTrmNetVendr: types.maybeNull(createModelCode()),
    skylifeCircuitNoMain: types.optional(types.maybeNull(types.string), ''),
    skylifeCircuitNoBackup: types.optional(types.maybeNull(types.string), ''),
    skylifeCircuitNoSpare: types.optional(types.maybeNull(types.string), ''),
    hcnTrmNetVendr: types.maybeNull(createModelCode()),
    hcnCircuitNoMain: types.optional(types.maybeNull(types.string), ''),
    hcnCircuitNoBackup: types.optional(types.maybeNull(types.string), ''),
    hcnCircuitNoSpare: types.optional(types.maybeNull(types.string), ''),
  })
  .actions((self) => ({
    resetPpCompany: () => {
      self.ppSid = 0;
      self.ppNm = '';
      self.ppLo = '';
      self.ppMngr = '';
      self.encodFwdYn = false;
      self.regDt = '';
      self.updDt = '';
    },
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as IPpCompany;
      (Object.keys(newProps) as TPpCompanyKeys[]).forEach((key) => {
        //@ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type TPpCompany = Instance<typeof PpCompany>;
type TPpCompanySnapshot = SnapshotOut<typeof PpCompany>;

export interface IPpCompany extends TPpCompany {}
export type TPpCompanyKeys = keyof TPpCompanySnapshot & string;
export interface IPpCompanySnapshot extends TPpCompanySnapshot {}
export const createPpCompany = () =>
  types.optional(PpCompany, {
    ppSid: 0,
    ppNm: '',
    ppLo: '',
    ppMngr: '',
    encodFwdYn: false,
    regDt: '',
    updDt: '',
  } as TPpCompany);

/**
 * Form 등록을 위한 초기값 모델
 */
type TDefaultPpCompany = typeof defaultPpCompany;
export interface IDefalutPpCompany extends TDefaultPpCompany {}
export const defaultPpCompany = {
  ppSid: '',
  ppNm: '',
  ppLo: '',
  ppMngr: '',
  ppMemo: '',
  encodFwdYn: true,
  regDt: '',
  regId: '',
  updDt: '',
  updId: '',
};
