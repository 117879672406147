import { Instance, SnapshotOut, types } from "mobx-state-tree"
import { createModelCode } from "../model-code/ModelCode";
import { SimpleChannel } from "./SimpleChannel";

/**
 * # DbsInfo
 * 
 * DbsInfo을 설명하세요.
 */
export const DbsInfo = types
  .model("DbsInfo")
  // --------------------------------------------------------------------------
  .props({
    // DBS에서 연동하는 정보와 동일하게 파라미터 작성하여 camelcase 형식이 아닐 수 있음.
    serviceID: types.optional(types.number, 0),
    channelNum: types.optional(types.maybeNull(types.number), 0),
    channelName: types.optional(types.maybeNull(types.string), ''),
    multicastIP: types.optional(types.maybeNull(types.string), ''),
    port: types.optional(types.maybeNull(types.number), 0),
    tsID: types.optional(types.maybeNull(types.number), 0),
    scrambleYN: types.optional(types.maybeNull(types.boolean), false),
    genre: types.optional(types.maybeNull(types.string), ''),
    serviceType: types.optional(types.maybeNull(types.number), 0),
    videoType: types.optional(types.maybeNull(types.string), ''),
    audioType: types.optional(types.maybeNull(types.string), ''),
    audioType2: types.optional(types.maybeNull(types.string), ''),
    appType: types.optional(types.maybeNull(types.boolean), null),
    dataCount: types.optional(types.maybeNull(types.number), 0),

    chWrkType: types.maybeNull(createModelCode()),
    dedicatedChYn: types.optional(types.maybeNull(types.boolean), false),

    tbCh: types.optional(types.maybeNull(SimpleChannel), null),
  })
  // --------------------------------------------------------------------------
  // MUTATEs ONLY! - 모델 상태를 변경
  // REQUESTs는 스토어 모델에 작성하세요.
  // ex) yarn gen model dbsInfo --store
  .actions((self) => ({
    /*
     * 여러 prop을 한번에 수정
     */
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as IDbsInfo;
      (Object.keys(newProps) as TDbsInfoKeys[]).forEach((key) => {
        //@ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type TDbsInfo = Instance<typeof DbsInfo>;
type TDbsInfoSnapshot = SnapshotOut<typeof DbsInfo>

export interface IDbsInfo extends TDbsInfo {}
export type TDbsInfoKeys = keyof TDbsInfoSnapshot & string;
export interface IDbsInfoSnapshot extends TDbsInfoSnapshot {}
export const createDbsInfo = () => 
  types.optional(DbsInfo, {
    serviceID: 0,
    channelNum: 0,
    channelName: null,
    multicastIP: null,
    port: 0,
    tsID: 0,
    scrambleYN: false,
    genre: null,
    serviceType: 0,
    videoType: null,
    audioType: null,
    audioType2: null,
    appType: null,
    dataCount: 0, 
    chWrkType: null,
    dedicatedChYn: false,
    tbCh: null
  } as TDbsInfo);
