import { Instance, SnapshotOut, types } from 'mobx-state-tree';

import { withEnvironment } from '../extensions/with-environment';
import { createVoc, IVoc, Voc } from '../../models/voc/Voc';
import { createPagination, IPagination, IPaginationSnapshot } from '../pagination/Pagination';
import { createResponse } from '../response/Response';
import { IVocSnapshot, TVocFilter } from '../voc/Voc';
import { VocApi, TGetVocsResult, TGetVocResult } from '../../services';
import { withRootStore } from '../extensions/with-root-store';
import { IStbSnapshot } from '../stb/Stb';
import { dateToTimestamp, timestampToDate } from '../../utils/dateExchanger';
import { HistoryStore } from '../history-store/HistoryStore';

/**
 * # VocStore
 *
 * VocStore을 설명하세요.
 */
export const VocStore = types
  .model('VocStore')
  // --------------------------------------------------------------------------
  .props({
    vocs: types.optional(types.array(Voc), []),
    selectedVoc: createVoc(),
    pagination: createPagination(),
    responseInfo: createResponse(),
  })
  .extend(withEnvironment)
  .extend(withRootStore)
  // eslint-disable-line @typescript-eslint/no-unused-vars
  .views((self) => ({}))
  // --------------------------------------------------------------------------
  // MUTATEs - 모델 상태를 변경
  .actions((self) => ({
    /**
     * vocs을 교체
     *
     * @param `vocs` 새로운 모델의 배열
     */
    setVocs: (vocs: IVocSnapshot[]) => {
      self.vocs.replace(vocs as IVoc[]);
    },
    setSelectedVoc: (voc: IVocSnapshot) => {
      self.selectedVoc = voc as IVoc;
    },
    setPagination: (pagination: IPaginationSnapshot) => {
      self.pagination = pagination as IPagination;
    },
  }))
  // --------------------------------------------------------------------------
  // REQUESTs - 서비스 요청 및 기타 인터페이스 요청
  .actions((self) => ({
    /**
     * 전체 목록을 Api를 통해 조회
     *
     * 조회한 결과로 Vocs를 교체한다. 실패시 에러 로그를 남긴다.
     */
    gets: async (vocFilter?: TVocFilter) => {
      try {
        const vocApi: VocApi = new VocApi(self.environment.api);
        let result: TGetVocsResult;

        if (vocFilter) {
          if (vocFilter.regDtSt) {
            vocFilter.regDtSt = dateToTimestamp(vocFilter.regDtSt);
          }
          if (vocFilter.regDtEd) {
            vocFilter.regDtEd = dateToTimestamp(vocFilter.regDtEd);
          }
          result = await vocApi.gets(self.pagination, vocFilter);
        } else {
          result = await vocApi.gets(self.pagination);
        }
        if (
          self.rootStore.responseStore.getResponseResult(result) &&
          result.pagination &&
          result.pagination.page === self.pagination.page
        ) {
          if (result.vocs) {
            result.vocs.forEach((item: IVocSnapshot) => {
              item.receiptDt = item.receiptDt
                ? timestampToDate(Number.parseInt(item.receiptDt))
                : null;
              item.openDt = item.openDt ? timestampToDate(Number.parseInt(item.openDt)) : null;
            });
            self.setVocs(result.vocs);
            self.setPagination(result.pagination);
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },
    get: async (vocSeq: string) => {
      try {
        const vocApi: VocApi = new VocApi(self.environment.api);
        let result: TGetVocResult = await vocApi.get(vocSeq);

        if (self.rootStore.responseStore.getResponseResult(result)) {
          if (result.voc) {
            result.voc.receiptDt = result.voc.receiptDt
              ? timestampToDate(Number.parseInt(result.voc.receiptDt))
              : null;
            result.voc.openDt = result.voc.openDt
              ? timestampToDate(Number.parseInt(result.voc.openDt))
              : null;
            self.setSelectedVoc(result.voc);
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },
  }));

// --------------------------------------------------------------------------
type TVocStore = Instance<typeof VocStore>;
type TVocStoreSnapshot = SnapshotOut<typeof VocStore>;

export interface IVocStore extends TVocStore {}

export type TVocStoreKeys = keyof TVocStoreSnapshot & string;

export interface IVocStoreSnapshot extends TVocStoreSnapshot {}
// export const createVocStore = () => types.optional(VocStore, {} as TVocStore);
