import ReportIcon from '@iconify/icons-ic/report';
import { Icon } from '@iconify/react';
import { Box, Card, Stack, TextField, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { DialogAnimate } from '../components/animate';
import CAlert from '../components/CAlert';
import CButtonSet from '../components/CButtonSet';
import CButtonSetItem from '../components/CButtonSetItem';
import CTextField from '../components/forms/CTextField';
import { BUTTON_LABEL } from '../components/settings/SettingConstEnum';
import { useStores } from '../models';
// ----------------------------------------------------------------------

export default observer(function ErrorAlert()
{
  const { errorAlertStore } = useStores();

  return (
    <CAlert
          key={'downloadAlert'}
          isAlertOpen={errorAlertStore.errorAlert.open}
          alertCategory={'error'}
          alertContent={errorAlertStore.errorAlert.content}
          alertTitle={''}
          handleAlertClose={() => {
            if(errorAlertStore.errorAlert.autoLogout)
            {
              errorAlertStore.rootStore.authStore.logout();
            }
            errorAlertStore.resetErrorAlert();
          }}
      />

    // <DialogAnimate
    //   open={errorAlertStore.errorAlert.open}
    //   onClose={() => {}}
    //   maxWidth={'md'}
    //   sx={{ minWidth: 900 }}
    // >
    //   <Card>
    //     <Stack direction="row" sx={{ justifyContent: 'center', margin: '20px', mb: 0 }}>
    //       <Box
    //         component={Icon}
    //         icon={ReportIcon}
    //         sx={{ fontSize: '6rem', color: 'rgb(255, 72, 66)' }}
    //       />
    //     </Stack>
    //     <Stack direction="row" sx={{ justifyContent: 'center', mb: '10px' }}>
    //       <Typography variant="h4" sx={{ marginTop: 1, fontWeight: '600', opacity: 0.9 }}>
    //         ERROR
    //       </Typography>
    //     </Stack>

    //     <TextField
    //       defaultValue={errorAlertStore.errorAlert.content}
    //       label="오류 내용"
    //       color="error"
    //       inputProps={{
    //         style: {
    //           minHeight: '100px',
    //           fontSize: '20px',
    //           fontWeight: 700,
    //           lineHeight: '25px',
    //           maxHeight: '200px',
    //         },
    //         readOnly: true,
    //       }}
    //       sx={{ width: '96%', ml: '2%', mt: 1 }}
    //       multiline
    //     />
    //     {/* {errorAlertStore.errorAlert.contentDetail.length > 0 && (
    //       <TextField
    //         defaultValue={errorAlertStore.errorAlert.contentDetail.toString()}
    //         label="오류 상세 내용"
    //         color="error"
    //         inputProps={{
    //           style: {
    //             minHeight: '100px',
    //             fontSize: '20px',
    //             fontWeight: 700,
    //             lineHeight: '25px',
    //             maxHeight: '400px',
    //             overflowY: 'auto',
    //           },
    //           readOnly: true,
    //         }}
    //         sx={{ width: '96%', ml: '2%', mt: 1 }}
    //         multiline
    //       />
    //     )} */}
    //     <Box sx={{ mt: 2, mb: 2, pr: 2, width: '100%' }}>
    //       <CButtonSet sx={{ marginTop: 2 }} justifyContent={'center'}>
    //         <CButtonSetItem
    //           onClick={() => {
    //             errorAlertStore.resetErrorAlert();
    //           }}
    //         >
    //           {BUTTON_LABEL.CLOSE}
    //         </CButtonSetItem>
    //       </CButtonSet>
    //     </Box>
    //   </Card>
    // </DialogAnimate>
  );
});
