import * as React from 'react';
import { ReactNode } from 'react';
import { Box, Typography, Link } from '@mui/material';
import { MBreadcrumbs } from './material-extend';
import { MBreadcrumbsProps } from './material-extend/MBreadcrumbs';
import { isString } from 'lodash';

interface IHeaderBreadcrumbsProps extends MBreadcrumbsProps {
  action?: ReactNode;
  heading: string;
  subheading?: string;
  moreLink?: string | string[];
}

/**
 *
 * ## 기본 사용법
 *
 * > 페이지의 제목과 breadcrumb입니다.
 *
 */

export default function HeaderBreadcrumbs({
  sx,
  links,
  action,
  heading,
  subheading,
  moreLink = '' || [],
  ...other
}: IHeaderBreadcrumbsProps) {
  return (
    <Box sx={{ m: 2, ...sx }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ flexGrow: 1, }}>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline'}}>
            <Typography variant="h4" gutterBottom>
              {heading}
            </Typography>
            <Typography variant="h6" color={'#DDDDDD'} sx={{ml: 1}}>{subheading}</Typography>
          </Box>
          <MBreadcrumbs links={links} {...other} />
        </Box>

        {action && <Box sx={{ flexShrink: 0 }}>{action}</Box>}
      </Box>

      <Box sx={{ mt: 2 }}>
        {isString(moreLink) ? (
          <Link href={moreLink} target="_blank" variant="body2">
            {moreLink}
          </Link>
        ) : (
          moreLink.map((href) => (
            <Link
              noWrap
              key={href}
              href={href}
              variant="body2"
              target="_blank"
              sx={{ display: 'table' }}
            >
              {href}
            </Link>
          ))
        )}
      </Box>
    </Box>
  );
}

/* #문서 작성법 예시
 * ## 1. 기본 사용법
 *
 * > `#` 최상위 타이틀은 컴포넌트 명이 차지하고 있으니,
 * `##` 두번째 타이틀 부터 사용합니다.
 *
 * > 주의: 문장 및 문단을 강조할 때는 꺽쇠로 시작합니다.
 *
 * > 빈 줄은 문장을 나누는 기능을 합니다.
 *
 * 마크다운 문법으로 작성하면 스토리북의 Docs에 나타납니다.
 *
 * 소스코드를 적을 때는 다음과 같이 작성합니다.
 *
 * ```tsx
 *
 * <Node/>
 *
 * ```
 *
 *
 * ## 2. 제목
 * ### 2-1. 사항 2-1
 * #### 2-1-1. 사항 2-1-1
 * ##### 2-1-1-1. 사항 2-1-1-1
 * #### 2-1-2. 사항 1-2
 * ### 2-2. 사항 2
 * #### 2-2-1. 사항 2-1
 *
 *
 *
 * ## 3. 모델, 서비스 및 스토어 관련사항
 *
 * 컴포넌트가 사용하는 모델, 서비스 및 스토어 관련 사항을 적어주세요.
 *
 */
