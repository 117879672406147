import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { createSimpleUser } from '../simple-user/SimpleUser';

export const EventInterlockStatus = types
  .model('EventInterlockStatus')
  .props({
    eventData: types.optional(types.maybeNull(types.string), ''),
    eventDt: types.optional(types.maybeNull(types.string), null),
    intrlkSysSid: types.optional(types.maybeNull(types.number), 0),
    sysNm: types.optional(types.maybeNull(types.string), ''),
    sucesYn: types.optional(types.maybeNull(types.boolean), true),
    updUser: types.maybeNull(createSimpleUser()),
    regUser: types.maybeNull(createSimpleUser()),
    updDt: types.optional(types.maybeNull(types.string), null),
    regDt: types.optional(types.maybeNull(types.string), null),
  })
  .actions((self) => ({
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as IEventInterlockStatus;
      (Object.keys(newProps) as TEventInterlockStatusKeys[]).forEach((key) => {
        //@ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

type TEventInterlockStatus = Instance<typeof EventInterlockStatus>;
type TEventInterlockStatusSnapshot = SnapshotOut<typeof EventInterlockStatus>;

export interface IEventInterlockStatus extends TEventInterlockStatus {}
export type TEventInterlockStatusKeys = keyof TEventInterlockStatus & string;
export interface IEventInterlockStatusSnapshot extends TEventInterlockStatusSnapshot {}
export const createEventInterlockStatus = () =>
  types.optional(EventInterlockStatus, {
    eventData: null,
    eventDt: null,
    intrlkSysSid: null,
    sysNm: null,
    sucesYn: null, 
    updUser: null,
    regUser: null,
    updDt: null,
    regDt: null,
  } as TEventInterlockStatus);
