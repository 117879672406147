import { Instance, SnapshotOut, types } from 'mobx-state-tree';

export const ModelUser = types
  .model('ModelUser')
  .props({
    userSid: types.optional(types.number, 0),
    loginId: types.optional(types.number, 0),
    userNm: types.optional(types.string, ''),
  })
  .actions((self) => ({
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as IModelUser;
      (Object.keys(newProps) as TModelUserKeys[]).forEach((key) => {
        //@ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type TModelUser = Instance<typeof ModelUser>;
type TModelUserSnapshot = SnapshotOut<typeof ModelUser>;

export interface IModelUser extends TModelUser {}
export type TModelUserKeys = keyof TModelUserSnapshot & string;
export interface IModelUserSnapshot extends TModelUserSnapshot {}
export const createModelUser = () =>
  types.optional(ModelUser, {
    userSid: 0,
    loginId: 0,
    userNm: '',
  } as TModelUser);
