import { Instance, SnapshotOut, types } from "mobx-state-tree"
import {createSmsEmail, ISmsEmail, ISmsEmailSnapshot, SmsEmail} from "../sms-email/SmsEmail";
import {withEnvironment} from "../extensions/with-environment";
import {withRootStore} from "../extensions/with-root-store";
import {SmsEmailApi, TGetSmsEmailResult, TGetSmsEmailsResult} from "../../services";

/**
 * # SmsEmailStore
 * 
 * SmsEmailStore을 설명하세요.
 */
export const SmsEmailStore = types
  .model("SmsEmailStore")
  // --------------------------------------------------------------------------
  .props({
    smsEmails : types.optional(types.array(SmsEmail), []),
    selectedSmsEmail : createSmsEmail(),
  })
  .extend(withEnvironment)
    .extend(withRootStore)
  // eslint-disable-line @typescript-eslint/no-unused-vars
  .views((self) => ({}))
  // --------------------------------------------------------------------------
  // MUTATEs - 모델 상태를 변경
  .actions((self) => ({
    /**
     * smsEmails을 교체
     *
     * @param `smsEmails` 새로운 모델의 배열
     */
    setSmsEmails: (smsEmails: ISmsEmail[]) => {
      self.smsEmails.replace(smsEmails);
    },
    setSmsEmail: (smsEmail: ISmsEmail) => {
      self.selectedSmsEmail = smsEmail;
    }
  }))
  // --------------------------------------------------------------------------
  // REQUESTs - 서비스 요청 및 기타 인터페이스 요청 
  .actions((self) => ({
    /**
     * 전체 목록을 Api를 통해 조회
     * 
     * 조회한 결과로 SmsEmails를 교체한다. 실패시 에러 로그를 남긴다.
     */
    getSmsEmails: async () => {
      const smsEmailApi : SmsEmailApi = new SmsEmailApi(self.environment.api);
      const result: TGetSmsEmailsResult = await smsEmailApi.getSmsEmails();
      if (result.kind === 'ok') {
        self.setSmsEmails(result.smsEmails);
      } else {
        console.error(result.kind);
      }
    },

    get: async (id:number) => {
      const smsEmailApi : SmsEmailApi = new SmsEmailApi(self.environment.api);
      const result: TGetSmsEmailResult = await smsEmailApi.getSmsEmail(id);
      if(result.kind === 'ok')
      {
        const smsEmail = result.smsEmail;
        self.setSmsEmail(smsEmail);
      }
    },

    put: async (data:ISmsEmailSnapshot) => {
      const smsEmailApi : SmsEmailApi = new SmsEmailApi(self.environment.api);
      const result: TGetSmsEmailResult = await smsEmailApi.updateSmsEmail(data.usrgrpSid, data);
      if (result.kind === 'ok') {
        const smsEmail = result.smsEmail;
        self.setSmsEmail(smsEmail);
      }
    }



  }))

// --------------------------------------------------------------------------
type TSmsEmailStore = Instance<typeof SmsEmailStore>;
type TSmsEmailStoreSnapshot = SnapshotOut<typeof SmsEmailStore>

export interface ISmsEmailStore extends TSmsEmailStore {}
export type TSmsEmailStoreKeys = keyof TSmsEmailStoreSnapshot & string;
export interface ISmsEmailStoreSnapshot extends TSmsEmailStoreSnapshot {}
export const createSmsEmailStore = () => 
  types.optional(SmsEmailStore, {} as TSmsEmailStore);
