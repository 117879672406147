import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { selectOptions } from '../../components/forms/CSelect';
import { withEnvironment } from '../../models/extensions/with-environment';
import { createUserDefaultModel, IUser, UserModel } from '../../models/user/user';
import { UserApi } from '../../services';
import { timestampToDate } from '../../utils/dateExchanger';
import { IUsrgrpSnapshot } from '../usrgrp/Usrgrp';
import {
  GetDeleteUserResult,
  GetInactiveClearResult,
  GetLoginIDCheckResult,
  GetPostUserResult,
  GetPutUserResult,
  GetUserApprovalResult,
  GetUserRejectResult,
  GetUserRestoreResult,
  GetUserResult,
  GetUserSuspendResult,
  GetUsersResult,
} from './../../services/user/user-result-types';
import { withRootStore } from './../extensions/with-root-store';
import { createPagination, IPagination, IPaginationSnapshot } from './../pagination/Pagination';
import { IUserSnapshot } from './../user/user';

export const UserStoreModel = types
  .model('UserStore')
  .props({
    users: types.optional(types.array(UserModel), []),
    selectedUser: createUserDefaultModel(),
    selectedMyInfo: createUserDefaultModel(),
    addUser: createUserDefaultModel(),
    updateUser: createUserDefaultModel(),
    deleteUserList: types.optional(types.array(types.number), []),
    pagination: createPagination(),
    userSearchFilter: createUserDefaultModel(),
    checkDuplicateId: types.optional(types.boolean, false),
  })
  .extend(withEnvironment)
  .extend(withRootStore)
  .views((self) => ({}))
  .actions((self) => ({
    setSelectedUser: (user: IUserSnapshot) => {
      self.selectedUser = user as IUser;
    },
    setSelectedMyInfo: (user: IUserSnapshot) => {
      self.selectedMyInfo = user as IUser;
    },
    setAddUser: (user: IUserSnapshot) => {
      self.addUser = user as IUser;
    },
    setUpdateUser: (user: IUserSnapshot) => {
      self.updateUser = user as IUser;
    },
    setDeleteUserList: (deleteUserSid: number[]) => {
      self.deleteUserList.replace(deleteUserSid);
    },
    appendDeleteUserList: (deleteUserSid: number) => {
      self.deleteUserList.push(deleteUserSid);
    },
    setUsers: (userList: IUserSnapshot[]) => {
      if (!userList) {
        return;
      }
      self.users.replace(userList as IUser[]);
    },
    setPagination: (pagination: IPaginationSnapshot) => {
      self.pagination = pagination as IPagination;
    },
    setUserGroupSidList: () => {
      if (self.selectedUser) {
        self.selectedUser.userGroupList.replace([]);
        self.selectedUser.userGroupNmList = '';

        for (var i = 0; i < self.selectedUser.usrgrpList.length; i++) {
          self.selectedUser.userGroupList.push(self.selectedUser.usrgrpList[i].usrgrpSid);
          self.selectedUser.userGroupNmList += self.selectedUser.usrgrpList[i].usrgrpNm + ', ';
        }

        if (self.selectedUser.userGroupNmList) {
          self.selectedUser.userGroupNmList = self.selectedUser.userGroupNmList.slice(0, -2);
        }
      }
    },
    setUserSearchFliter: (userFilter: TUserFilter) => {
      if (!userFilter.userSid) {
        userFilter.userSid = 0;
      }

      if (!userFilter.regSdt) {
        userFilter.regSdt = 0;
      }

      if (!userFilter.regEdt) {
        userFilter.regEdt = 0;
      }

      self.userSearchFilter = userFilter as IUser;
    },
    setCheckDuplicateId: (checkDuplicateId: boolean) => {
      self.checkDuplicateId = checkDuplicateId;
    },
  }))
  .actions((self) => ({
    /**
     * INF_CAIMS_00162
     * 해당 사용자의 상세정보를 조회한다.
     * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=464696832
     * @param userSid
     */
    get: async (userSid: number) => {
      self.selectedUser.resetUser();
      try {
        const userApi: UserApi = new UserApi(self.environment.api);
        const result: GetUserResult = await userApi.get(userSid);

        if (self.rootStore.responseStore.getResponseResult(result)) {
          if (result.user) {
            if(result.user.lastLoginDt)
            {
              result.user.lastLoginDt = timestampToDate(Number.parseInt(result.user.lastLoginDt));
            }
            result.user.regDt = timestampToDate(Number.parseInt(result.user.regDt));
            result.user.updDt = timestampToDate(Number.parseInt(result.user.updDt));
            if (result.user.sessn === 0) {
              result.user.sessionYn = true;
            } else {
              result.user.sessionYn = false;
            }
            if (result.user.usrgrpList) {
              result.user.usrgrpList.forEach((usrgrp: IUsrgrpSnapshot) => {
                usrgrp.regDt = timestampToDate(Number.parseInt(usrgrp.regDt));
                usrgrp.updDt = timestampToDate(Number.parseInt(usrgrp.updDt));
              });
            }
            self.setSelectedUser(result.user);
            self.setUserGroupSidList();
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },

    /**
     * INF_CAIMS_00162
     * 현재 로그인 유저의 상세정보를 조회한다.
     * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=464696832
     * @param userSid
     */
    getMyself: async () => {
      self.selectedUser.resetUser();
      try {
        const user = self.rootStore.authStore.getLoginUserStorageData();
        const loginUserSid = user?.userSid;
        if (loginUserSid) {
          const userApi: UserApi = new UserApi(self.environment.api);
          const result: GetUserResult = await userApi.get(loginUserSid);

          if (self.rootStore.responseStore.getResponseResult(result)) {
            if (result.user) {
              if(result.user.lastLoginDt)
              {
                result.user.lastLoginDt = timestampToDate(Number.parseInt(result.user.lastLoginDt));
              }
              result.user.regDt = timestampToDate(Number.parseInt(result.user.regDt));
              result.user.updDt = timestampToDate(Number.parseInt(result.user.updDt));
              if (result.user.usrgrpList) {
                result.user.usrgrpList.forEach((usrgrp: IUsrgrpSnapshot) => {
                  usrgrp.regDt = timestampToDate(Number.parseInt(usrgrp.regDt));
                  usrgrp.updDt = timestampToDate(Number.parseInt(usrgrp.updDt));
                });
              }
              self.setSelectedMyInfo(result.user);
            }
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },

    /**
     * INF_CAIMS_00161
     * 시스템 사용자 전체 목록을 조회한다.
     * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=536042080
     * @param userFilter
     */
    gets: async (userFilter?: TUserFilter) => {
      self.setUsers([]);
      try {
        const userApi: UserApi = new UserApi(self.environment.api);
        let result: GetUsersResult;
        if (userFilter) {
          result = await userApi.gets(self.pagination, userFilter);
        } else {
          result = await userApi.gets(self.pagination);
        }

        if (
          self.rootStore.responseStore.getResponseResult(result) &&
          result.pagination &&
          result.pagination.page === self.pagination.page
        ) {
          if (result.users) {
            if (result.users.length > 0) {
              result.users.forEach((item: IUserSnapshot) => {
                if(item.lastLoginDt)
                {
                  item.lastLoginDt = timestampToDate(Number.parseInt(item.lastLoginDt));
                }
                item.regDt = timestampToDate(Number.parseInt(item.regDt));
                item.updDt = timestampToDate(Number.parseInt(item.updDt));
                if (item.usrgrpList) {
                  item.usrgrpList.forEach((usrgrp: IUsrgrpSnapshot) => {
                    usrgrp.regDt = timestampToDate(Number.parseInt(usrgrp.regDt));
                    usrgrp.updDt = timestampToDate(Number.parseInt(usrgrp.updDt));
                  });
                }
              });
            }
            self.setUsers(result.users);
            result.pagination && self.setPagination(result.pagination);
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },

    /**
     * INF_CAIMS_00163
     * 새로운 사용자를 등록한다.
     * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=1608359065
     * @param user
     */
    post: async (user: IUserSnapshot) => {
      try {
        const userApi: UserApi = new UserApi(self.environment.api);
        const result: GetPostUserResult = await userApi.post(user);

        if (self.rootStore.responseStore.getResponseResult(result)) {
          self.addUser.resetUser();
          if (result.user) {
            result.user.regDt = timestampToDate(Number.parseInt(result.user.regDt));
            result.user.updDt = timestampToDate(Number.parseInt(result.user.updDt));
            result.user.lastLoginDt = result.user.lastLoginDt ? timestampToDate(Number.parseInt(result.user.lastLoginDt)) : null;
            if (result.user.sessn === 0) {
              result.user.sessionYn = true;
            } else {
              result.user.sessionYn = false;
            }
            if (result.user.usrgrpList) {
              result.user.usrgrpList.forEach((usrgrp: IUsrgrpSnapshot) => {
                usrgrp.regDt = timestampToDate(Number.parseInt(usrgrp.regDt));
                usrgrp.updDt = timestampToDate(Number.parseInt(usrgrp.updDt));
              });
            } else {
              result.user.usrgrpSidList = [];
            }
            self.setAddUser(result.user);
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },

    /**
     * INF_CAIMS_00164
     * 해당 사용자의 정보를 수정한다.
     * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=2140743085
     * @param user
     */
    put: async (user: IUserSnapshot) => {
      try {
        if (!user.usrgrpSidList) {
          if (user.usrgrpList) {
            let usrgrpSidList: any = [];
            user.usrgrpList.forEach((item: IUsrgrpSnapshot) => {
              usrgrpSidList.push(item.usrgrpSid);
            });
            user.usrgrpSidList = usrgrpSidList;
          }
        }

        const userApi: UserApi = new UserApi(self.environment.api);
        const result: GetPutUserResult = await userApi.put(user);

        if (self.rootStore.responseStore.getResponseResult(result)) {
          self.updateUser.resetUser();
          if (result.user) {
            result.user.regDt = timestampToDate(Number.parseInt(result.user.regDt));
            result.user.updDt = timestampToDate(Number.parseInt(result.user.updDt));
            result.user.lastLoginDt = result.user.lastLoginDt ? timestampToDate(Number.parseInt(result.user.lastLoginDt)) : null;
            if (result.user.sessn === 0) {
              result.user.sessionYn = true;
            } else {
              result.user.sessionYn = false;
            }
            if (result.user.usrgrpList) {
              result.user.usrgrpList.forEach((usrgrp: IUsrgrpSnapshot) => {
                usrgrp.regDt = timestampToDate(Number.parseInt(usrgrp.regDt));
                usrgrp.updDt = timestampToDate(Number.parseInt(usrgrp.updDt));
              });
            } else {
              result.user.usrgrpList = [];
            }

            self.setUpdateUser(result.user);
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },

    /**
     * INF_CAIMS_00165
     * 해당 사용자의 정보를 삭제한다.
     * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=1001467282
     * @param userSid
     */
    delete: async (userSid: number) => {
      try {
        const userApi: UserApi = new UserApi(self.environment.api);
        const result: GetDeleteUserResult = await userApi.delete(userSid);
        self.rootStore.responseStore.getResponseResult(result);
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },

    /**
     * INF_CAIMS_00166
     * 여러개의 사용자를 한번에 삭제한다.
     * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=1937156406
     */
    deletes: async () => {
      try {
        const userApi: UserApi = new UserApi(self.environment.api);
        const result: GetDeleteUserResult = await userApi.deletes(self.deleteUserList);
        self.rootStore.responseStore.getResponseResult(result);
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },

    /**
     * INF_CAIMS_00167
     * 해당 사용자를 승인한다.
     * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=1754362887
     * @param userSid
     */
    approval: async (userSid: number) => {
      try {
        const userApi: UserApi = new UserApi(self.environment.api);
        const result: GetUserApprovalResult = await userApi.approval(userSid);
        self.rootStore.responseStore.getResponseResult(result);
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },

    /**
     * INF_CAIMS_00168
     * 해당 사용자를 승인을 거절한다.
     * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=1651626323
     * @param userSid
     */
    reject: async (userSid: number) => {
      try {
        const userApi: UserApi = new UserApi(self.environment.api);
        const result: GetUserRejectResult = await userApi.reject(userSid);
        self.rootStore.responseStore.getResponseResult(result);
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },

    suspend: async(userSid: number) => {
      try {
        const userApi: UserApi = new UserApi(self.environment.api);
        const result: GetUserSuspendResult = await userApi.suspend(userSid);
        self.rootStore.responseStore.getResponseResult(result);
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },

    restore: async(userSid: number) => {
      try {
        const userApi: UserApi = new UserApi(self.environment.api);
        const result: GetUserRestoreResult = await userApi.restore(userSid);
        self.rootStore.responseStore.getResponseResult(result);
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },

    /**
     * INF_CAIMS_00214
     * 해당 사용자 계정 휴면을 해제한다.
     * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=324973781
     * @param userSid
     */
    inactiveClear: async (userSid: number) => {
      try {
        const userApi: UserApi = new UserApi(self.environment.api);
        const result: GetInactiveClearResult = await userApi.incativeClear(userSid);
        self.rootStore.responseStore.getResponseResult(result);
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },

    /**
     * INF_CAIMS_00169
     * 특정 아이디가 데이터베이스에 이미 존재하는지 확인한다.
     * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=1666174010
     * @param loginId
     */
    loginIdCheck: async (loginId: string) => {
      try {
        const userApi: UserApi = new UserApi(self.environment.api);
        const result: GetLoginIDCheckResult = await userApi.checkLoginId(loginId);
        if (self.rootStore.responseStore.getResponseResult(result)) {
          if (result.result) {
            self.setCheckDuplicateId(true);
          } else {
            self.setCheckDuplicateId(false);
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },
  }));
type UserStoreType = Instance<typeof UserStoreModel>;
export interface UserStore extends UserStoreType {}
type UserStoreSnapshotType = SnapshotOut<typeof UserStoreModel>;
export interface UserStoreSnapshot extends UserStoreSnapshotType {}

export interface IUserFilter {
  userSid?: number | null;
  loginId?: string;
  userNm?: string;
  usrgrpSid?: number | string;
  acntSttsList?: selectOptions[];
  regSdt?: string | number;
  regEdt?: string | number;
}

export type TUserFilter = IUserFilter;
