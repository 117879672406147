import { ApiResponse } from 'apisauce';
import { IHistoryFilter, IResponseSnapshot } from '../../models';
import { dateToTimestamp, timestampToDate } from '../../utils/dateExchanger';
import { Api } from '../api/api';
import { getGeneralApiProblem } from '../api/api-problem';
import { IHistorySnapshot } from './../../models/history/History';
import { IPaginationSnapshot } from './../../models/pagination/Pagination';
import { TGetHistoryResult, TGetHistorysResult } from './HistoryTypes';

/**
 * # History Api 서비스
 *
 * 서비스의 설명을 작성하세요.
 *
 * ## 사용방법
 *
 * ```ts
 * const service = new HistoryApi();
 *
 * ```
 */
type TGetsParams = {
  size: number;
  page: number;
} & IHistoryFilter;

export class HistoryApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  async gets(
    pagination: IPaginationSnapshot,
    historyFilter?: IHistoryFilter,
  ): Promise<TGetHistorysResult> {
    try {
      const url = '/api/history';
      let params: TGetsParams = {
        size: pagination.size,
        page: pagination.page,
      };

      if (historyFilter) {
        if (historyFilter.recvDtSt) {
          historyFilter.recvDtSt = dateToTimestamp(historyFilter.recvDtSt);
        }

        if (historyFilter.recvDtEt) {
          historyFilter.recvDtEt = dateToTimestamp(historyFilter.recvDtEt);
        }
      }

      params = { ...params, ...historyFilter };

      const response: ApiResponse<any> = await this.api.apisauce.get(url, params);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseIfno: IResponseSnapshot = response.data;

      return {
        kind: 'ok',
        historys: response.data.data,
        pagination: response.data.pagination,
        responseInfo: responseIfno,
      };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async get(transacId: string): Promise<TGetHistoryResult> {
    try {
      const url: string = `/api/history/${transacId}`;
      const response: ApiResponse<any> = await this.api.apisauce.get(url);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseIfno: IResponseSnapshot = response.data;

      if (response.data.data) {
        response.data.data.regDt = timestampToDate(Number.parseInt(response.data.data.regDt));
        response.data.data.recvDt = timestampToDate(Number.parseInt(response.data.data.recvDt));
      }

      if (responseIfno.resultCode === 'S') {
        return {
          kind: 'ok',
          history: response.data.data,
          responseInfo: responseIfno,
        };
      }

      return {
        kind: 'ok',
        history: {} as IHistorySnapshot,
        responseInfo: responseIfno,
      };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }
}
