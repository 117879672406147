import { createChangeValue } from './../change-value/ChangeValue';
import { Instance, SnapshotOut, types } from 'mobx-state-tree';

/**
 * # TrcodHistoryContent
 *
 * TrcodHistoryContent을 설명하세요.
 */
export const TrcodHistoryContent = types
  .model('TrcodHistoryContent')
  // --------------------------------------------------------------------------
  .props({
    operation: types.optional(types.maybeNull(types.string), null),
    name: types.optional(types.maybeNull(types.string), null),
    equipSid: types.maybeNull(createChangeValue()),
    mainYn: types.maybeNull(createChangeValue()),
    hostNm: types.maybeNull(createChangeValue()),
    inPortSid: types.maybeNull(createChangeValue()),
    inPortNm: types.maybeNull(createChangeValue()),
    inBPortSid: types.maybeNull(createChangeValue()),
    inBPortNm: types.maybeNull(createChangeValue()),
    outPortSid: types.maybeNull(createChangeValue()),
    outPortNm: types.maybeNull(createChangeValue()),
    outBPortSid: types.maybeNull(createChangeValue()),
    outBPortNm: types.maybeNull(createChangeValue()),
  })
  // --------------------------------------------------------------------------
  // MUTATEs ONLY! - 모델 상태를 변경
  // REQUESTs는 스토어 모델에 작성하세요.
  // ex) yarn gen model trcodHistoryContent --store
  .actions((self) => ({
    /*
     * 여러 prop을 한번에 수정
     */
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as ITrcodHistoryContent;
      (Object.keys(newProps) as TTrcodHistoryContentKeys[]).forEach((key) => {
        //@ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type TTrcodHistoryContent = Instance<typeof TrcodHistoryContent>;
type TTrcodHistoryContentSnapshot = SnapshotOut<typeof TrcodHistoryContent>;

export interface ITrcodHistoryContent extends TTrcodHistoryContent {}
export type TTrcodHistoryContentKeys = keyof TTrcodHistoryContentSnapshot & string;
export interface ITrcodHistoryContentSnapshot extends TTrcodHistoryContentSnapshot {}
export const createTrcodHistoryContent = () =>
  types.optional(TrcodHistoryContent, {} as TTrcodHistoryContent);
