import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { ChannelChildCode } from './../channel-child-code/ChannelChildCode';
import { createSimpleUser } from './../simple-user/SimpleUser';
/**
 * # ChannelCode
 *
 * ChannelCode을 설명하세요.
 */
export const ChannelCode = types
  .model('ChannelCode')
  // --------------------------------------------------------------------------
  .props({
    cdId: types.optional(types.maybeNull(types.number), 0),
    cdPid: types.optional(types.maybeNull(types.number), 0),
    cdNm: types.optional(types.string, ''),
    srcNm: types.optional(types.maybeNull(types.string), ''),
    cdDispNm: types.optional(types.maybeNull(types.string), ''),
    cdDispNmEng: types.optional(types.maybeNull(types.string), ''),
    cdOrder: types.optional(types.maybeNull(types.number), 0),
    refId: types.optional(types.maybeNull(types.string), ''),
    useYn: types.optional(types.boolean, false),
    dscrpt: types.optional(types.maybeNull(types.string), ''),
    regDt: types.optional(types.string, ''),
    updDt: types.optional(types.string, ''),
    //구버전
    // regId: types.optional(types.maybeNull(types.number), 0),
    // updId: types.optional(types.maybeNull(types.number), 0),
    cdItemList: types.optional(types.maybeNull(types.array(ChannelChildCode)), []),
    //----------------------
    regUser: createSimpleUser(),
    updUser: createSimpleUser(),
  })
  // --------------------------------------------------------------------------
  // MUTATEs ONLY! - 모델 상태를 변경
  // REQUESTs는 스토어 모델에 작성하세요.
  // ex) yarn gen model channelCode --store
  .actions((self) => ({
    /*
     * 여러 prop을 한번에 수정
     */
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as IChannelCode;
      (Object.keys(newProps) as TChannelCodeKeys[]).forEach((key) => {
        //@ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type TChannelCode = Instance<typeof ChannelCode>;
type TChannelCodeSnapshot = SnapshotOut<typeof ChannelCode>;

export interface IChannelCode extends TChannelCode {}
export type TChannelCodeKeys = keyof TChannelCodeSnapshot & string;
export interface IChannelCodeSnapshot extends TChannelCodeSnapshot {}
export const createChannelCode = () =>
  types.optional(ChannelCode, {
    cdId: 0,
    cdPid: 0,
    cdNm: '',
    srcNm: '',
    cdDispNm: '',
    cdDispNmEng: '',
    cdOrder: 0,
    refId: '',
    useYn: false,
    dscrpt: '',
    regDt: '',
    // regId: 0,
    updDt: '',
    // updId: 0,
  } as TChannelCode);
