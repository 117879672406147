import { Instance, SnapshotOut, types } from "mobx-state-tree"

/**
 * # BubbleChart
 * 
 * BubbleChart을 설명하세요.
 */
export const BubbleChart = types
  .model("BubbleChart")
  // --------------------------------------------------------------------------
  .props({
    keyword: types.string,
    value: types.optional(types.maybeNull(types.number), 0),
    weight: types.optional(types.maybeNull(types.number), 0),
    diff: types.optional(types.number, 0),
    currentAverage: types.optional(types.maybeNull(types.number), 0),
    pastAverage: types.optional(types.maybeNull(types.number), 0),
    currentCount: types.optional(types.maybeNull(types.number), 0),
    pastCount: types.optional(types.maybeNull(types.number), 0),
    rate: types.optional(types.maybeNull(types.number), 0),
  })
  // --------------------------------------------------------------------------
  // MUTATEs ONLY! - 모델 상태를 변경
  // REQUESTs는 스토어 모델에 작성하세요.
  // ex) yarn gen model bubbleChart --store
  .actions((self) => ({
    /*
     * 여러 prop을 한번에 수정
     */
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as IBubbleChart;
      (Object.keys(newProps) as TBubbleChartKeys[]).forEach((key) => {
        // @ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type TBubbleChart = Instance<typeof BubbleChart>;
type TBubbleChartSnapshot = SnapshotOut<typeof BubbleChart>

export interface IBubbleChart extends TBubbleChart {}
export type TBubbleChartKeys = keyof TBubbleChartSnapshot & string;
export interface IBubbleChartSnapshot extends TBubbleChartSnapshot {}
export const createBubbleChart = () => 
  types.optional(BubbleChart, {
    keyword: '',
    value: 0,
    weight: 0,
    diff: 0,
  } as TBubbleChart);
