import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { createModelCode } from '../model-code/ModelCode';

export const Aco = types
  .model('Aco')
  .props({
    equipSid: types.optional(types.number, 0),
    srcInType: createModelCode(),
    mainYn: types.optional(types.boolean, false),
    hostNm: types.optional(types.string, ''),
    mgmtIp: types.optional(types.string, ''),
    modelNm: types.optional(types.maybeNull(types.string), ''),
    frmwrVer: types.optional(types.maybeNull(types.string), ''),
    equipLo: types.optional(types.maybeNull(types.string), ''),
    equipMngr:  types.optional(types.maybeNull(types.string), ''),
    psiPid: types.optional(types.maybeNull(types.number), 0),
    patPid: types.optional(types.maybeNull(types.number), 0),
    inPortSid: types.optional(types.maybeNull(types.number), 0),
    inPortNm: types.optional(types.maybeNull(types.string), ''),
    skyAraInPortSid: types.optional(types.maybeNull(types.number), 0),
    skyAraInPortNm: types.optional(types.maybeNull(types.string), ''),
    lgInPortSid: types.optional(types.maybeNull(types.number), 0),
    lgInPortNm: types.optional(types.maybeNull(types.string), ''),
    psiInPortSid: types.optional(types.maybeNull(types.number), 0),
    psiInPortNm: types.optional(types.maybeNull(types.string), ''),
    outPortSid: types.optional(types.maybeNull(types.number), 0),
    outPortNm: types.optional(types.maybeNull(types.string), ''),
    skyOutPortSid: types.optional(types.maybeNull(types.number), 0),
    skyOutPortNm: types.optional(types.maybeNull(types.string), ''),
    seeznOutPortSid: types.optional(types.maybeNull(types.number), 0),
    seeznOutPortNm: types.optional(types.maybeNull(types.string), ''),
    ktInMip: types.optional(types.maybeNull(types.string), null),
    skyInMip: types.optional(types.maybeNull(types.string), null),
    lgInMip: types.optional(types.maybeNull(types.string), null),
    outMip: types.optional(types.maybeNull(types.string), null),
    inBPortSid: types.optional(types.maybeNull(types.number), 0),
    inBPortNm: types.optional(types.maybeNull(types.string), ''),
    outBPortSid: types.optional(types.maybeNull(types.number), 0),
    outBPortNm: types.optional(types.maybeNull(types.string), ''),


    inPort: createModelCode(),
    outPort: createModelCode(),
    inBPort: createModelCode(),
    outBPort: createModelCode(),
    skyOutPort: createModelCode(),
    seeznOutPort: createModelCode(),
    skyAraInPort: createModelCode(),
    lgInPort: createModelCode(),
    psiInPort: createModelCode(),
  })
  .actions((self) => ({
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as IAco;
      (Object.keys(newProps) as TAcoKeys[]).forEach((key) => {
        //@ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

type TAco = Instance<typeof Aco>;
type TAcoSnapshot = SnapshotOut<typeof Aco>;

export interface IAco extends TAco {}
export type TAcoKeys = keyof TAcoSnapshot & string;
export interface IAcoSnapshot extends TAcoSnapshot {}
export const createAco = () =>
  types.optional(Aco, {
    equipSid: 0,
    mainYn: false,
    hostNm: '',
    mgmtIp: '',
    modelNm: null,
    frmwrVer: null,
    inPortSid: null,
    inPortNm: null,
    skyAraInPortSid: null,
    skyAraInPortNm: null,
    lgInPortSid: null,
    lgInPortNm: null,
    psiInPortSid: null,
    psiInPortNm: null,
    outPortSid: null,
    outPortNm: null,
    skyOutPortSid: null,
    skyOutPortNm: null,
    seeznOutPortSid: null,
    seeznOutPortNm: null,
    ktInMip: null,
    skyInMip: null,
    lgInMip: null,
    outMip: null,
    inBPortSid: null,
    inBPortNm: null,
    outBPortSid: null,
    outBPortNm: null,
  } as TAco);
