import { Box, Divider } from '@mui/material';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useForm } from 'react-hook-form';
import CButtonSet from '../../../components/CButtonSet';
import CButtonSetItem from '../../../components/CButtonSetItem';
import CFormSet from '../../../components/CFormSet';
import CFormSetItem from '../../../components/CFormSetItem';
import CTextField from '../../../components/forms/CTextField';
import { FormProvider } from '../../../components/hook-form';
import RegUpdInfoForm from '../../../components/RegUpdInfoForm';
import { BUTTON_LABEL, FORMSET_ITEM_LABEL } from '../../../components/settings/SettingConstEnum';
import { ScrollStyle } from '../../../components/settings/SettingStyle';
import { IUserSnapshot, useStores } from '../../../models';
import { useEffect, useState } from 'react';

interface MyPageModalProps {
  isMyPageOpen: boolean;
  handleMypageClose: () => void;
}
const USER_LABEL = FORMSET_ITEM_LABEL.systemManagement.userMgmt;
export const MyPageModal = observer(function MyPageModal({
  isMyPageOpen,
  handleMypageClose,
}: MyPageModalProps) {
  const rootStore = useStores();
  const { userStore } = rootStore;
  const [defaultValues, setDefaultValues] = useState<IUserSnapshot>(userStore.selectedMyInfo);

  const methods = useForm<IUserSnapshot>({
    defaultValues,
  });

  const { getValues } = methods;

  // useEffect(() => {
  //   setDefaultValues(userStore.selectedMyInfo);
  // }, []);

  return (
    <>
      <FormProvider methods={methods}>
        <Box sx={ScrollStyle}>
          <CFormSet
            title={FORMSET_ITEM_LABEL.systemManagement.myPage.myInfo}
            column={1}
            seqId={getValues('userSid')}
          >
            {/* 로그인 아이디 */}
            <CFormSetItem>
              <CTextField label={USER_LABEL.loginId} name={'loginId'} readonly></CTextField>
            </CFormSetItem>
            {/* 이름 */}
            <CFormSetItem>
              <CTextField label={USER_LABEL.userNm} name={'userNm'} readonly></CTextField>
            </CFormSetItem>
            {/* 계정 상태 */}
            <CFormSetItem>
              <CTextField
                label={USER_LABEL.acntSttsCd}
                name={`acntSttsCd.value`}
                readonly
              ></CTextField>
            </CFormSetItem>
            {/* 최근 로그인일시 */}
            <CFormSetItem>
              <CTextField
                label={USER_LABEL.lastLoginDt}
                name={`lastLoginDt`}
                readonly
              ></CTextField>
            </CFormSetItem>
            {/* 등록일시 */}
            <RegUpdInfoForm
              regUser={getValues('regUser.userNm')}
              regDt={getValues('regDt')}
            />
          </CFormSet>
        </Box>
        <Box className="modalButton" sx={{ marginTop: 2, width: '100%' }}>
          <Divider></Divider>
          <CButtonSet sx={{ marginTop: 2 }} justifyContent={'flex-end'}>
            <CButtonSetItem onClick={() => handleMypageClose()}>
              {BUTTON_LABEL.CONFIRM}
            </CButtonSetItem>
          </CButtonSet>
        </Box>
      </FormProvider>
    </>
  );
});
