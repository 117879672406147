import { Instance, SnapshotOut, types } from "mobx-state-tree"
import { createModelCode } from "../model-code/ModelCode";

/**
 * # QualityReport
 * 
 * QualityReport을 설명하세요.
 */
export const QualityReport = types
  .model("QualityReport")
  .props({
    rprtCycl: types.optional(types.maybeNull(createModelCode()), null),
    chkDt: types.optional(types.string, ''),
    excelFileNm: types.optional(types.maybeNull(types.string), null),
    pdfFileNm: types.optional(types.maybeNull(types.string), null),
    regDt: types.optional(types.string, ''),

    chkDtSt: types.optional(types.maybeNull(types.string), null),
    chkDtEd: types.optional(types.maybeNull(types.string), null),
  })
  .actions((self) => ({
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as IQualityReport;
      (Object.keys(newProps) as TQualityReportKeys[]).forEach((key) => {
        //@ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

type TQualityReport = Instance<typeof QualityReport>;
type TQualityReportSnapshot = SnapshotOut<typeof QualityReport>

export interface IQualityReport extends TQualityReport {}
export type TQualityReportKeys = keyof TQualityReportSnapshot & string;
export interface IQualityReportSnapshot extends TQualityReportSnapshot {}
export const createQualityReport = () => 
  types.optional(QualityReport, {
    
  } as TQualityReport);

  