import { ApiResponse } from 'apisauce';
import { Api } from '../api/api';
import { getGeneralApiProblem } from '../api/api-problem';
import {
  IPaginationSnapshot,
  IResponseSnapshot,
  IStbFilter,
  TStbFilter,
} from '../../models';
import {
  TGetStbSimulatorResult,
  TPostStbSimulatorResult,
  TGetStbsResult,
} from './StbSimulatorTypes';


export class StbSimulatorApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  async gets(pagination: IPaginationSnapshot, stbFilter?: TStbFilter): Promise<TGetStbsResult> {
    try {
      const url = '/stb';
      let params: any = {};
      if (stbFilter) {
        params = filterSendDatas(stbFilter);
      }

      params.size = pagination.size;
      params.page = pagination.page;

      const response: ApiResponse<any> = await this.api.apisauce.get(url, params);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;

      return {
        kind: 'ok',
        stbs: response.data.data,
        pagination: response.data.pagination,
        responseInfo: responseInfo,
      };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async getCurrentState(saidList: string[]): Promise<TGetStbSimulatorResult> {
    try {
      const url = '/stb/control/stbstate/getCurrentState';
      let params = '?';
      saidList.map((item) => {
        params += 'saidList=' + item + '&';
      });

      const response: ApiResponse<any> = await this.api.apisauce.get(url + params);
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;

      return {
        kind: 'ok',
        stbSimulators: response.data.data,
        responseInfo: responseInfo,
      };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async getRemoteSignal(params: any): Promise<TPostStbSimulatorResult> {
    try {
      const url = '/stb/control/remocon/inputButton';

      const response: ApiResponse<any> = await this.api.apisauce.post(url,params);
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;

      return {
        kind: 'ok',
        stbSimulators: response.data.data,
        responseInfo: responseInfo,
      };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }
}

const filterSendDatas = (stbFilter: IStbFilter) => {
  let sendData: any = {};

  if (stbFilter.said) {
    sendData.said = stbFilter.said;
  }

  if (stbFilter.stbMo) {
    sendData.stbMo = stbFilter.stbMo;
  }

  if (stbFilter.helthySttus) {
    sendData.helthySttus = stbFilter.helthySttus.code;
  }

  return sendData;
};
