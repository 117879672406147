// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';
const ROOTS_CHANNEL_AI = '/channel-ai';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path('', '/'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  register: path(ROOTS_AUTH, '/register'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  verify: path(ROOTS_AUTH, '/verify'),
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page404: '/404',
  page500: '/500',
  components: '/components',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    dashboard: path(ROOTS_DASHBOARD, '/dashboard'),
  },
  bank: {
    root: path(ROOTS_DASHBOARD, '/bank'),
    bankList: path(ROOTS_DASHBOARD, '/bank/bank-list'),
  },
  app: {
    root: path(ROOTS_DASHBOARD, '/app'),
  },
};

export const PATH_CHANNEL_AI = {
  dashboard: {
    dashboard: path(ROOTS_CHANNEL_AI, '/dashboard/dashboard'),
    baekseokChannel: path(ROOTS_CHANNEL_AI, '/dashboard/baekseok-channel'),
    // yeouidoVod: path(ROOTS_CHANNEL_AI, '/dashboard/yeouido-vod'),
    oneView: path(ROOTS_CHANNEL_AI, '/dashboard/one-view'),
    systemStatus: path(ROOTS_CHANNEL_AI, '/dashboard/system-status'),
  },
  staticsReporting: {
    realTimeAlarm: path(ROOTS_CHANNEL_AI, '/statics-reporting/real-time-alarm'),
    alarmHistory: path(ROOTS_CHANNEL_AI, '/statics-reporting/alarm-history'),
    alarmAnalyze: path(ROOTS_CHANNEL_AI, '/statics-reporting/alarm-analyze'),
    vocHistory: path(ROOTS_CHANNEL_AI, '/statics-reporting/voc-history'),
    vocStatistics: path(ROOTS_CHANNEL_AI, '/statics-reporting/voc-statistics'),
    ratingStatistics: path(ROOTS_CHANNEL_AI, '/statics-reporting/rating-statistics'),
    stbStatistics: path(ROOTS_CHANNEL_AI, '/statics-reporting/stb-statistics'),
    reporting: path(ROOTS_CHANNEL_AI, '/statics-reporting/reporting'),
    qualityReporting: path(ROOTS_CHANNEL_AI, '/statics-reporting/quality-reporting'),
    
  },
  supplementaryService: {
    aQuetone: path(ROOTS_CHANNEL_AI, '/supplementary-service/aquetone'),
    bQuetone: path(ROOTS_CHANNEL_AI, '/supplementary-service/bquetone'),
    dQuetone: path(ROOTS_CHANNEL_AI, '/supplementary-service/dquetone'),
    quetoneDpcc: path(ROOTS_CHANNEL_AI, '/supplementary-service/quetone-dpcc'),
    misTransMission: path(ROOTS_CHANNEL_AI, '/supplementary-service/misTransMission'),
  },
  alarmManagement: {
    // alarmMgmt: path(ROOTS_CHANNEL_AI, '/alarm-management/alarm-mgmt'),
    ruleset: path(ROOTS_CHANNEL_AI, '/alarm-management/ruleset'),
    snmpRuleset: path(ROOTS_CHANNEL_AI, '/alarm-management/snmp-ruleset'),
    // rcaTemplateMgmt: path(ROOTS_CHANNEL_AI, '/alarm-management/rca-template-mgmt'),
    stbSimulator: path(ROOTS_CHANNEL_AI, '/alarm-management/stb-simulator'),
  },
  sopKdbManagement: {
    sopRegisterMgmt: path(ROOTS_CHANNEL_AI, '/sop-kdb-management/sop-register-mgmt'),
    kdbMgmt: path(ROOTS_CHANNEL_AI, '/sop-kdb-management/kdb-mgmt'),
  },
  configurationManagement: {
    channelMgmt: path(ROOTS_CHANNEL_AI, '/configuration-management/channel-mgmt'),
    intrlkSysMgmt: path(ROOTS_CHANNEL_AI, '/configuration-management/interlocking-system-mgmt'),
    equipmentMgmt: path(ROOTS_CHANNEL_AI, '/configuration-management/equipment-mgmt'),
    // rackMgmt: path(ROOTS_CHANNEL_AI, '/configuration-management/rack-mgmt'),
    monitoringStbMgmt: path(ROOTS_CHANNEL_AI, '/configuration-management/monitoring-stb-mgmt'),
    topologyMgmt: path(ROOTS_CHANNEL_AI, '/configuration-management/topology-mgmt'),
    ppCompanyMgmt: path(ROOTS_CHANNEL_AI, '/configuration-management/pp-company-mgmt'),
  },
  systemManagement: {
    userMgmt: path(ROOTS_CHANNEL_AI, '/system-management/user-mgmt'),
    userLoginHistory: path(ROOTS_CHANNEL_AI, '/system-management/user-login-history'),
    groupMgmt: path(ROOTS_CHANNEL_AI, '/system-management/group-mgmt'),
    groupMenuMgmt: path(ROOTS_CHANNEL_AI, '/system-management/group-menu-mgmt'),
    apiHistory: path(ROOTS_CHANNEL_AI, '/system-management/api-history'),
    connectionMgmt: path(ROOTS_CHANNEL_AI, '/system-management/connection-mgmt'),
    pairAlarmSetting: path(ROOTS_CHANNEL_AI, '/system-management/pair-alarm-setting'),
    channelCodeMgmt: path(ROOTS_CHANNEL_AI, '/system-management/channel-code-mgmt'),
    smsManagement: path(ROOTS_CHANNEL_AI, '/system-management/sms-management'),
    emailManagement: path(ROOTS_CHANNEL_AI, '/system-management/email-management'),
    notificationManagement: path(ROOTS_CHANNEL_AI, '/system-management/notification-management'),
  },
  realtimeMonitoring: {
    channelAlarm: path(ROOTS_CHANNEL_AI, '/realtime-monitoring/channel-alarm'),
    quetoneAlarm: path(ROOTS_CHANNEL_AI, '/realtime-monitoring/quetone-alarm'),
    vocAlarm: path(ROOTS_CHANNEL_AI, '/realtime-monitoring/voc-alarm'),
    disabledPersonAlarm: path(ROOTS_CHANNEL_AI, '/realtime-monitoring/disabled-person-alarm'),
    multiChannelAlarm: path(ROOTS_CHANNEL_AI, '/realtime-monitoring/multi-channel-alarm'),
  }
};
