import { Instance, SnapshotOut, types } from "mobx-state-tree"

import { withEnvironment } from "../extensions/with-environment";
import { withRootStore } from '../extensions/with-root-store';
import {
  IQuetone,
} from "../../models";
import { createQuetone, IQuetoneSnapshot, IQuetoneFilter } from '../quetone/Quetone';
import {
  QuetoneApi,
  TGetQuetonesResult
} from "../../services";

/**
 * # QuetoneStore
 * 
 * QuetoneStore을 설명하세요.
 */
export const QuetoneStore = types
  .model("QuetoneStore")
  // --------------------------------------------------------------------------
  .props({
    quetones: types.optional(types.array(createQuetone()), []),
  })
  .extend(withEnvironment)
  .extend(withRootStore)
  .views((self) => ({}))
  .actions((self) => ({
    /**
     * quetones을 교체
     *
     * @param `quetones` 새로운 모델의 배열
     */
    setQuetones: (quetones: IQuetoneSnapshot[]) => {
      self.quetones.replace(quetones as IQuetone[]);
    },
    // setSelectedQuetone: (quetone: IQuetoneGraphSnapshot) => {
    //   self.selectedQuetone = quetone as IQuetoneGraph;
    // },
  }))
  // --------------------------------------------------------------------------
  // REQUESTs - 서비스 요청 및 기타 인터페이스 요청 
  .actions((self) => ({
    /**
     * 전체 목록을 Api를 통해 조회
     * 
     * 조회한 결과로 Quetones를 교체한다. 실패시 에러 로그를 남긴다.
     */
  getQuetones: async (filter: IQuetoneFilter) => {
      try {
        const quetoneApi: QuetoneApi = new QuetoneApi(self.environment.api);
        const result: TGetQuetonesResult = await quetoneApi.getQuetones(filter);
        if (result.data) {
          self.setQuetones(result.data);
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },
  getQuetone: async (chSid: string) => {
    try {
      const quetoneApi: QuetoneApi = new QuetoneApi(self.environment.api);
    } catch (e){
      self.rootStore.responseStore.errorProcessing(e);
    }
  } ,
  }));

// --------------------------------------------------------------------------
type TQuetoneStore = Instance<typeof QuetoneStore>;
type TQuetoneStoreSnapshot = SnapshotOut<typeof QuetoneStore>

export interface IQuetoneStore extends TQuetoneStore { }
export type TQuetoneStoreKeys = keyof TQuetoneStoreSnapshot & string;
export interface IQuetoneStoreSnapshot extends TQuetoneStoreSnapshot { }
export const createQuetoneStore = () =>
  types.optional(QuetoneStore, {} as TQuetoneStore);
