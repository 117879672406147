import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { createModelCode } from '../model-code/ModelCode';

export const SdSw = types
  .model('SdSw')
  .props({
    equipSid: types.optional(types.number, 0),
    equipRole: createModelCode(),
    mainYn: types.optional(types.boolean, true),
    hostNm: types.optional(types.string, ''),
    mgmtIp: types.optional(types.string, ''),
    modelNm: types.optional(types.maybeNull(types.string), ''),
    frmwrVer: types.optional(types.maybeNull(types.string), ''),
    equipLo: types.optional(types.maybeNull(types.string), ''),
    equipMngr:  types.optional(types.maybeNull(types.string), ''),
    inPortSid: types.optional(types.maybeNull(types.number), 0),
    inPortNm: types.optional(types.maybeNull(types.string), ''),
    outPortSid: types.optional(types.maybeNull(types.number), 0),
    outPortNm: types.optional(types.maybeNull(types.string), ''),
    inBPortSid: types.optional(types.maybeNull(types.number), 0),
    inBPortNm: types.optional(types.maybeNull(types.string), ''),
    outBPortSid: types.optional(types.maybeNull(types.number), 0),
    outBPortNm: types.optional(types.maybeNull(types.string), ''),

    //화면용 inPort / outPort
    inPort: createModelCode(),
    outPort: createModelCode(),
    inBPort: createModelCode(),
    outBPort: createModelCode(),
  })
  .actions((self) => ({
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as ISdSw;
      (Object.keys(newProps) as TSdSwKeys[]).forEach((key) => {
        //@ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

type TSdSw = Instance<typeof SdSw>;
type TSdSwSnapshot = SnapshotOut<typeof SdSw>;

export interface ISdSw extends TSdSw {}
export type TSdSwKeys = keyof TSdSwSnapshot & string;
export interface ISdSwSnapshot extends TSdSwSnapshot {}
export const createSdSw = () =>
  types.optional(SdSw, {
    equipSid: 0,
    mainYn: true,
    hostNm: '',
    mgmtIp: '',
    modelNm: null,
    frmwrVer: null,
    inPortSid: null,
    inPortNm: null,
    outPortSid: null,
    outPortNm: null,
    inBPortSid: null,
    inBPortNm: null,
    outBPortSid: null,
    outBPortNm: null,
  } as TSdSw);
