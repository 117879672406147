import { Checkbox, TableCell, TableRow, TableSortLabel } from '@mui/material';
import TableHead from '@mui/material/TableHead';
import { Box, SxProps } from '@mui/system';
import { visuallyHidden } from '@mui/utils';
import { observer } from 'mobx-react-lite';
import { Order } from './CTableToolbar';
import { withStyles } from '@mui/styles';
import { useEffect } from 'react';
import parse from 'html-react-parser';

export interface HeadCell {
  disablePadding: boolean;
  id: string;
  label: string;
  numeric?: boolean;
  align?: 'left' | 'right' | 'center';
  sx?: SxProps;
  checkBox?: boolean;
  checkBoxOnChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}
const StyledTableCell = withStyles((theme) => ({
  root: {
    whiteSpace: 'nowrap',
    '&:first-of-type': {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      boxShadow: 'none!important',
    },
    '&:last-of-type': {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      boxShadow: 'none!important',
    },
  },
}))(TableCell);

interface CTableHeadProps {
  numSelected?: number;
  onRequestSort?: (event: React.MouseEvent<unknown>, property: string) => void;
  onSelectAllClick?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order?: Order;
  orderBy?: string;
  rowCount?: number;
  headCells: readonly HeadCell[];
  checkBox?: boolean;
  removeCheckBox?: boolean;
  showSorted?: boolean;
  bg?: string;
  useRectangle?: boolean;
}

export const CTableHead = observer(function CTableHead({
  onSelectAllClick,
  order,
  orderBy,
  numSelected,
  rowCount,
  onRequestSort,
  headCells,
  checkBox = true,
  removeCheckBox = true,
  showSorted = false,
  bg = '',
  useRectangle = false,
}: CTableHeadProps) {
  // const createSortHandler = (property: any) => (event: React.MouseEvent<unknown>) => {
  //   onRequestSort(event, property);
  // };
  // const [cWidth, setCWidth] = useState<string>('');

  useEffect(() => {
    //setCWidth((100/headCells.length).toString() + 'vw !important')
  }, []);
  return (
    <TableHead>
      <TableRow>
        {removeCheckBox && (
          <StyledTableCell sx={{ paddingRight: '0 !important', width: '1vw', background: bg, }} padding="checkbox">
            <Checkbox
              color="primary"
              indeterminate={numSelected != undefined && rowCount != undefined && numSelected > 0 && numSelected < rowCount}
              checked={rowCount != undefined && rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              sx={{ display: checkBox ? 'auto' : 'none' }}
              inputProps={{
                'aria-label': 'select all desserts',
              }}
            />
          </StyledTableCell>
        )}
        {headCells.map((headCell, index) => (
          <StyledTableCell
            key={headCell.id}
            align={headCell.align ? headCell.align : 'center'}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{ background: bg,}}
            sx={{...headCell.sx}}
            padding="none"
          >
            {headCell.checkBox && (
                <Checkbox
                  color="primary"
                  onChange={headCell.checkBoxOnChange}
                />
            )}
            <TableSortLabel
              hideSortIcon={!showSorted}
              sx={{
                pl: headCell.align === 'left' ? '16px' : '0 !important',
                pr: headCell.align === 'right' ? '16px' : '0 !important',
                pointerEvents: 'none',
              }}
              >
              {parse(headCell.label)}
              {orderBy === headCell.id && showSorted ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead >
  );
});
