import { ApiResponse } from 'apisauce';
import { IPaginationSnapshot, IResponseSnapshot, ISopSnapshot, TSopFilter } from '../../models';
import { fileToText, getFileType, textToFile } from '../../utils/fileExchange';
import { Api } from '../api/api';
import { getGeneralApiProblem } from '../api/api-problem';
import {
  TDeleteSopResult,
  TFileSopResult,
  TGetSopResult,
  TGetSopsResult,
  TPostSopResult, TSpcfResult, TStringResult,
  TUpdateSopResult,
} from './SopTypes';
import {selectOptions} from "../../components/forms/CSelect";

/**
 * # Sop Api 서비스
 *
 * 서비스의 설명을 작성하세요.
 *
 * ## 사용방법
 *
 * ```ts
 * const service = new SopApi();
 *
 * ```
 */
export class SopApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  /**
   * INF_CAIMS_00118
   * 전체 SOP 목록을 조회한다.
   * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=1754789027
   * @param pagination
   * @param sopFilter
   * @returns
   */
  async gets(pagination: IPaginationSnapshot, sopFilter?: TSopFilter): Promise<TGetSopsResult> {
    try {
      const url = '/sop';
      let params: any = {};
      if (sopFilter) {
        params = sopFilter;
      }

      params.size = pagination.size;
      params.page = pagination.page;

      const response: ApiResponse<any> = await this.api.apisauce.get(url, params);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;

      response.data.data.map((item: any) => {
        if (item.sopFileList === null) {
          item.sopFileList = [];
        }
      });

      return {
        kind: 'ok',
        sops: response.data.data,
        pagination: response.data.pagination,
        responseInfo: responseInfo,
      };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  /**
   * INF_CAIMS_00119
   * 해당 SOP의 상세정보를 조회한다.
   * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=486134938
   * @param sopSid
   * @returns
   */
  async get(sopSid: Number): Promise<TGetSopResult> {
    try {
      const url = `/sop/${sopSid.toString()}`;
      const response: ApiResponse<any> = await this.api.apisauce.get(url);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;

      if (responseInfo.resultCode === 'S') {
        const result = response.data.data;
        return {
          kind: 'ok',
          sop: result,
          responseInfo: responseInfo,
        };
      }

      return {
        kind: 'ok',
        responseInfo: responseInfo,
      };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  /**
   * INF_CAIMS_00120
   * 새로운 SOP를 등록한다.
   * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=166393514
   * @param sop
   * @returns
   */
  async post(sop: ISopSnapshot): Promise<TPostSopResult> {
    try {
      const url = '/sop';
      const params = createSendData(sop);
      await this.api.apisauce.setHeader('Content-Type', 'multipart/form-data');
      const response: ApiResponse<any> = await this.api.apisauce.post(url, params);
      await this.api.apisauce.setHeader('Content-Type', 'application/json');

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const resposneInfo: IResponseSnapshot = response.data;

      if (resposneInfo.resultCode === 'S') {
        const result = response.data.data;
        return {
          kind: 'ok',
          sop: result,
          responseInfo: resposneInfo,
        };
      }

      return {
        kind: 'ok',
        responseInfo: resposneInfo,
      };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  /**
   * INF_CAIMS_00121
   * 해당 SOP를 수정한다.
   * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=540156253
   * @param sop
   * @returns
   */
  async put(sop: ISopSnapshot): Promise<TUpdateSopResult> {
    try {
      const url = `/sop/${sop.sopSid}`;
      const params = createSendData(sop);
      await this.api.apisauce.setHeader('Content-Type', 'multipart/form-data');
      const response: ApiResponse<any> = await this.api.apisauce.put(url, params);
      await this.api.apisauce.setHeader('Content-Type', 'application/json');

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;

      if (responseInfo.resultCode === 'S') {
        const result = response.data.data;
        return {
          kind: 'ok',
          sop: result,
          responseInfo: responseInfo,
        };
      }

      return {
        kind: 'ok',
        responseInfo: responseInfo,
      };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  /**
   * INF_CAIMS_00122
   * 해당 SOP를 삭제한다.
   * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=458840650
   * @param id
   * @returns
   */
  async delete(id: number): Promise<TDeleteSopResult> {
    try {
      const url = `/sop/${id}`;
      const response: ApiResponse<any> = await this.api.apisauce.delete(url);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;

      return {
        kind: 'ok',
        responseInfo: responseInfo,
      };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  /**
   * INF_CAIMS_00123
   * 여러개 SOP를 한번에 삭제한다.
   * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=1905178102
   * @param sopSidList
   * @returns
   */
  async deletes(sopSidList: number[]): Promise<TDeleteSopResult> {
    try {
      let param: string = ``;
      sopSidList.forEach((item: number, index: number) => {
        param += `sopSidList=${item.toString()}`;
        if (index !== sopSidList.length - 1) {
          param += `&`;
        }
      });

      const url = `/sop?${param}`;

      const response: ApiResponse<any> = await this.api.apisauce.delete(url);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;

      return {
        kind: 'ok',
        responseInfo: responseInfo,
      };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  /**
   * INF_CAIMS_00313
   * 해당 SOP File을 가져온다
   * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=2051487926
   * @param sopSid, @param fileOrder @param fileName
   * @returns
   */
  async getFile(sopFileSid:number): Promise<TFileSopResult> {
    const url: string = `/sop/download/${sopFileSid}`;
    return this.api.download(url, "download");
  }

  async getProblCause(intrlkSysSid:number, eventType:number): Promise<TStringResult> {
    try {
      const url: string = '/sop/probl';
      let params: any = {};
      params.intrlkSysSid = intrlkSysSid;
      params.eventType = eventType;

      const response: ApiResponse<any> = await this.api.apisauce.get(url, params);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;

      if (responseInfo.resultCode === 'S') {
        const result = response.data.data;
        return {
          kind: 'ok',
          result: result,
          responseInfo: responseInfo,
        };
      }

      return {
        kind: 'ok',
        responseInfo: responseInfo,
      };
    } catch (e) {
      return {kind: 'bad-data'}
    }
  }

  async getspcfProbl(intrlkSysSid:number, eventType:number, problCause:string): Promise<TSpcfResult> {
    try {
      const url: string = '/sop/spcf';
      let params: any = {};
      params.intrlkSysSid = intrlkSysSid;
      params.eventType = eventType;
      params.problCause = problCause;
      const response: ApiResponse<any> = await this.api.apisauce.get(url, params);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;

      if (responseInfo.resultCode === 'S') {
        const result = response.data.data;
        return {
          kind: 'ok',
          result: result,
          responseInfo: responseInfo,
        };
      }

      return {
        kind: 'ok',
        responseInfo: responseInfo,
      };
    } catch (e) {
      return {kind: 'bad-data'}
    }
  }
}

const createSendData = (sop: ISopSnapshot) => {
  const formData = new FormData();

  if (sop.sopSid !== undefined) {
    formData.append('sopSid', sop.sopSid.toString());
  }

  if (sop.sopNm) {
    formData.append('sopNm', sop.sopNm);
  }

  if (sop.equipType?.code) {
    formData.append('equipType', sop.equipType?.code.toString());
  }


  if (sop.trtSchm) {
    formData.append('trtSchm', sop.trtSchm);
  }

  if (sop.tkcgr) {
    formData.append('tkcgr', sop.tkcgr);
  }

  if (sop.sopFileList) {
    sop.sopFileList.map((item: any, index: number) => {
      if (sop.sopSid !== undefined) {
        formData.append(`sopFileList[${index}].sopSid`, sop.sopSid.toString());
      }
      if(item.sopFileSid !== null)
      {
        formData.append(`sopFileList[${index}].sopFileSid`, item.sopFileSid);
      }
      formData.append(`sopFileList[${index}].fileName`, item.fileName);
      formData.append(
        `sopFileList[${index}].sopFile`,
        textToFile(item.sopFile, item.fileName, item.fileType),
      );
    });
  }

  return formData;
};
