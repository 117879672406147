import { Instance, SnapshotOut, types } from "mobx-state-tree"
import {selectOptions} from "../../components/forms/CSelect";

/**
 * # MisTransMissionGraph
 * 
 * MisTransMissionGraph을 설명하세요.
 */
export const MisTransMissionGraph = types
  .model("MisTransMissionGraph")
  // --------------------------------------------------------------------------
  .props({
      chSvcId: types.optional(types.maybeNull(types.number), 0),
      chNm: types.optional(types.maybeNull(types.string), ""),
      pvqAvgList: types.optional(types.maybeNull(types.array(types.array(types.string))), []),
      loudnessAvgList: types.optional(types.maybeNull(types.array(types.array(types.string))), []),
      misTransMissionGraphDateList: types.optional(types.maybeNull(types.array(types.string)), []),

  })
  // --------------------------------------------------------------------------
  // MUTATEs ONLY! - 모델 상태를 변경
  // REQUESTs는 스토어 모델에 작성하세요.
  // ex) yarn gen model misTransMissionGraph --store
  .actions((self) => ({
    /*
     * 여러 prop을 한번에 수정
     */
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as IMisTransMissionGraph;
      (Object.keys(newProps) as TMisTransMissionGraphKeys[]).forEach((key) => {
        // @ts-ignore
          self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type TMisTransMissionGraph = Instance<typeof MisTransMissionGraph>;
type TMisTransMissionGraphSnapshot = SnapshotOut<typeof MisTransMissionGraph>

export interface IMisTransMissionGraph extends TMisTransMissionGraph {}
export type TMisTransMissionGraphKeys = keyof TMisTransMissionGraphSnapshot & string;
export interface IMisTransMissionGraphSnapshot extends TMisTransMissionGraphSnapshot {}
export const createMisTransMissionGraph = () => 
  types.optional(MisTransMissionGraph, {
  } as TMisTransMissionGraph);

export interface IMisTransMissionFilter{
    statDtSt: string,
    statDtEd: string,
    chSvcIdList?: number[],
    chSvcId?: selectOptions;

    occurredEvent?: boolean,
}