import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { selectOptions } from '../../components/forms/CSelect';
import { IModelCode, IVendor } from '../../models';
import { IModelCodeSnapshot, ModelCode } from '../model-code/ModelCode';
import { TGetVendorsResult } from '../../services';
import { withEnvironment } from '../extensions/with-environment';
import { VendorApi } from './../../services/vendor/Vendor';
import { withRootStore } from './../extensions/with-root-store';
import { createVendor, IVendorSnapshot } from './../vendor/Vendor';

/**
 * # VendorStore
 *
 * VendorStore을 설명하세요.
 */
export const VendorStore = types
  .model('VendorStore')
  // --------------------------------------------------------------------------
  .props({
    vendors: types.optional(types.maybeNull(types.array(createVendor())), []),
    vendorsCodeList: types.optional(types.array(ModelCode), []),
    makers: types.optional(types.maybeNull(types.array(createVendor())), []),
    makersCodeList: types.optional(types.array(ModelCode), []),
  })
  .extend(withEnvironment)
  .extend(withRootStore)
  // eslint-disable-line @typescript-eslint/no-unused-vars
  .views((self) => ({}))
  // --------------------------------------------------------------------------
  // MUTATEs - 모델 상태를 변경
  .actions((self) => ({
    setVendors: (vendors: IVendorSnapshot[]) => {
      self.vendors?.replace(vendors as IVendor[]);
    },
    setVendorsCodeList: (vendors: IModelCodeSnapshot[]) => {
      self.vendorsCodeList.replace(vendors as IModelCode[]);
    },
    setMakers: (makers: IVendorSnapshot[]) => {
      self.makers?.replace(makers as IVendor[]);
    },
    setMakersCodeList: (makers: IModelCodeSnapshot[]) => {
      self.makersCodeList.replace(makers as IModelCode[]);
    },
  }))
  // --------------------------------------------------------------------------
  // REQUESTs - 서비스 요청 및 기타 인터페이스 요청
  .actions((self) => ({
    /**
     * INF_CAIMS_00216
     * 현재 저장된 벤더사의 아이디와 업체명을 조회한다.
     * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=2079446246
     * @param vendorType
     */
    getVendors: async () => {
      try {
        const vendorApi: VendorApi = new VendorApi(self.environment.api);
        const result: TGetVendorsResult = await vendorApi.getVendors();

        if (self.rootStore.responseStore.getResponseResult(result)) {
          if (result.vendors) {
            self.setVendors(result.vendors);
            let vendorList: IModelCodeSnapshot[] = [];
            result.vendors.map((item) => {
              item.vendorSid &&
                item.vendorNm &&
                vendorList.push({
                  code: item.vendorSid,
                  value: item.vendorNm,
                  pcode: null,
                });
            });
            self.setVendorsCodeList(vendorList);
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },
    /**
     * INF_CAIMS_00239
     * 현재 저장된 제조사의 아이디와 업체명을 조회한다.
     * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=965284125
     */
    getMakers: async () => {
      try {
        const vendorApi: VendorApi = new VendorApi(self.environment.api);
        const result: TGetVendorsResult = await vendorApi.getMakers();

        if (self.rootStore.responseStore.getResponseResult(result)) {
          if (result.vendors) {
            self.setMakers(result.vendors);
            let mnfcList: IModelCodeSnapshot[] = [];
            result.vendors.map((item) => {
              item.vendorSid &&
                item.vendorNm &&
                mnfcList.push({
                  code: item.vendorSid,
                  value: item.vendorNm,
                  pcode: null,
                });
            });
            self.setMakersCodeList(mnfcList);
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },
  }));

// --------------------------------------------------------------------------
type TVendorStore = Instance<typeof VendorStore>;
type TVendorStoreSnapshot = SnapshotOut<typeof VendorStore>;

export interface IVendorStore extends TVendorStore {}
export type TVendorStoreKeys = keyof TVendorStoreSnapshot & string;
export interface IVendorStoreSnapshot extends TVendorStoreSnapshot {}
export const createVendorStore = () => types.optional(VendorStore, {} as TVendorStore);
