import { timestampToDate } from './../../utils/dateExchanger';
import { ApiResponse } from 'apisauce';
import { IPaginationSnapshot, IResponseSnapshot } from '../../models';
import { IPpCompanySnapshot } from '../../models/pp-company/PpCompany';
import { Api } from '../api/api';
import { getGeneralApiProblem } from '../api/api-problem';
import { IPpCompanyFliter } from './../../models/pp-company-store/PpCompanyStore';
import {
  TDeletePpCompanyResult,
  TDuplicatePpCompanyNmResult,
  TGetPpCompanysResult,
  TDeletesPpCompnayResult,
  TPostPpCompanyResult,
  TUpdatePpCompanyResult,
  TGetPpCompanyResult,
} from './PpCompanyTypes';

export class PpCompanyApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  /**
   * INF_CAIMS_00224
   * PP사 목록을 조회한다.
   * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=1939453652
   * @param pagination
   * @param ppCompanyFilter
   * @returns
   */
  async gets(
    pagination: IPaginationSnapshot,
    ppCompanyFilter?: IPpCompanyFliter,
  ): Promise<TGetPpCompanysResult> {
    try {
      const url = '/pp';
      interface ppFilter extends IPpCompanyFliter {
        page: number;
        size: number;
      }
      let params: ppFilter = {
        size: pagination.size,
        page: pagination.page,
      };

      if (ppCompanyFilter) {
        params = { ...params, ...ppCompanyFilter };
      }

      const response: ApiResponse<any> = await this.api.apisauce.get(url, params);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;

      if (responseInfo.resultCode === 'S') {
        if (response.data.data) {
          const ppCompanys: IPpCompanySnapshot[] = response.data.data;
          const pagination: IPaginationSnapshot = response.data.pagination;
          return {
            kind: `ok`,
            responseInfo: responseInfo,
            ppCompanys: ppCompanys,
            pagination: pagination,
          };
        }
      }
      return { kind: 'ok', responseInfo: responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  /**
   * INF_CAIMS_00225
   * PP사를 등록한다.
   * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=1106095053
   * @param ppCompany
   * @returns
   */
  async post(ppCompany: IPpCompanySnapshot): Promise<TPostPpCompanyResult> {
    try {
      const url = '/pp';
      const params: ISendData = createSendData(ppCompany);

      const response: ApiResponse<any> = await this.api.apisauce.post(url, params);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }
      const responseInfo: IResponseSnapshot = response.data;

      if (responseInfo.resultCode === `S`) {
        if (response.data.data) {
          const ppCompany: IPpCompanySnapshot = response.data.data;
          return { kind: `ok`, responseInfo: responseInfo, ppCompany: ppCompany };
        }
      }

      return {
        kind: 'ok',
        responseInfo: responseInfo,
      };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  /**
   * INF_CAIMS_00226
   * PP사를 수정한다.
   * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=392829932
   * @param ppCompany
   * @returns
   */
  async put(ppCompany: IPpCompanySnapshot): Promise<TUpdatePpCompanyResult> {
    try {
      const url = '/pp/' + ppCompany.ppSid;
      const param: ISendData = createSendData(ppCompany);

      const response: ApiResponse<any> = await this.api.apisauce.put(url, param);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;

      if (responseInfo.resultCode === 'S') {
        if (response.data.data) {
          const ppCompany: IPpCompanySnapshot = response.data.data;
          return { kind: `ok`, responseInfo: responseInfo, ppCompany: ppCompany };
        }
      }

      return {
        kind: 'ok',
        responseInfo: responseInfo,
      };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  /**
   * INF_CAIMS_00227
   * PP사를 삭제한다.
   * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=131297418
   * @param ppSid
   * @returns
   */
  async delete(ppSid: number): Promise<TDeletePpCompanyResult> {
    try {
      const url = '/pp/' + ppSid;

      const response: ApiResponse<any> = await this.api.apisauce.delete(url);
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;

      return {
        kind: 'ok',
        responseInfo: responseInfo,
      };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  /**
   * INF_CAIMS_00236
   * PP사의 이름을 중복체크한다.
   * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=708747388
   * @param ppNm
   * @returns
   */
  async checkDuplicatePpCompanyNm(
    ppNm: string,
    ppSid?: number | null,
  ): Promise<TDuplicatePpCompanyNmResult> {
    try {
      const url: string = '/pp/name/check';
      const params = {
        ppSid: ppSid,
        ppNm: ppNm,
      };

      const response: ApiResponse<any> = await this.api.apisauce.get(url, params);
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;

      return {
        kind: 'ok',
        responseInfo: responseInfo,
      };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  /**
   * INF_CAIMS_00237
   * PP사 목록 삭제
   * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=2045831926
   * @param ppSidIdDelList
   * @returns
   */
  async deletes(ppSidIdDelList: number[]): Promise<TDeletesPpCompnayResult> {
    try {
      let param = ``;
      ppSidIdDelList.forEach((item: number, index: number) => {
        param += `ppSidDelList=${item}`;
        if (index !== ppSidIdDelList.length - 1) {
          param += `&`;
        }
      });
      const url: string = `/pp?${param}`;

      const response: ApiResponse<any> = await this.api.apisauce.delete(url);
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;

      return {
        kind: 'ok',
        responseInfo: responseInfo,
      };
    } catch (e) {
      return { kind: `bad-data` };
    }
  }

  /**
   * INF_CAIMS_00238
   * 해당 PP사 상세조회
   * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=856365855
   * @param ppSid
   * @returns
   */
  async get(ppSid: number): Promise<TGetPpCompanyResult> {
    try {
      const url: string = `/pp/${ppSid}`;
      const response: ApiResponse<any> = await this.api.apisauce.get(url);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;

      if (responseInfo.resultCode === 'S') {
        if (response.data.data) {
          response.data.data.regDt = timestampToDate(response.data.data.regDt);
          response.data.data.updDt = timestampToDate(response.data.data.updDt);
          const ppCompnay: IPpCompanySnapshot = response.data.data;

          return { kind: `ok`, responseInfo: responseInfo, ppCompnay: ppCompnay };
        }
      }
      return { kind: `ok`, responseInfo: responseInfo };
    } catch (e) {
      return { kind: `bad-data` };
    }
  }
}
interface ISendData {
  ppSid?: number;
  ppNm?: string;
  ppLo?: string | null;
  ppMngr?: string | null;
  encodFwdYn: boolean;
  ppMemo?: string | null;
  affiliate?: string | null;
  monitorCircuitNoM?: string | null;
  monitorCircuitNoB?: string | null;
}

const createSendData = (ppCompnay: IPpCompanySnapshot): ISendData => {
  let param: ISendData = {} as ISendData;

  if (ppCompnay.ppSid) {
    param.ppSid = ppCompnay.ppSid;
  }

  if (ppCompnay.ppNm !== undefined) {
    param.ppNm = ppCompnay.ppNm;
  }

  if (ppCompnay.ppLo !== undefined) {
    param.ppLo = ppCompnay.ppLo;
  }
  
  if(ppCompnay.ppMngr !== undefined) {
    param.ppMngr = ppCompnay.ppMngr;
  }

  if (ppCompnay.encodFwdYn) {
    param.encodFwdYn = ppCompnay.encodFwdYn;
  }

  if (ppCompnay.ppMemo !== undefined) {
    param.ppMemo = ppCompnay.ppMemo;
  }

  if (ppCompnay.affiliate !== undefined) {
    param.affiliate = ppCompnay.affiliate;
  }

  if (ppCompnay.monitorCircuitNoM !== undefined) {
    param.monitorCircuitNoM = ppCompnay.monitorCircuitNoM;
  }

  if (ppCompnay.monitorCircuitNoB !== undefined) {
    param.monitorCircuitNoB = ppCompnay.monitorCircuitNoB;
  }

  return param;
};
