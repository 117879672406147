import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { IntrlkSysApi } from '../../services/intrlk-sys/IntrlkSys';
import {
  TDeleteIntrlkSysResult,
  TGetIntrlkSysResult,
  TGetIntrlkSysServerResult,
  TGetIntrlkSysServersResult,
  TGetIntrlkSyssResult,
  TPostIntrlkSysResult,
  TUpdateIntrlkSysResult,
  TCheckDeleteIntrlkSysResult,
} from '../../services/intrlk-sys/IntrlkSysTypes';
import { timestampToDate } from '../../utils/dateExchanger';
import { withEnvironment } from '../extensions/with-environment';
import {
  createIntrlkSysSrvr,
  IIntrlkSysSrvr,
  IIntrlkSysSrvrFilter,
  IIntrlkSysSrvrSnapshot,
} from '../intrlk-sys-srvr/IntrlkSysSrvr';
import {
  createIntrlkSys,
  IIntrlkSys,
  IIntrlkSysSnapshot,
  IntrlkSys,
} from '../intrlk-sys/IntrlkSys';
import { createPagination, IPagination, IPaginationSnapshot } from '../pagination/Pagination';
import { withRootStore } from './../extensions/with-root-store';

export const IntrlkSysStore = types
  .model('IntrlkSysStore')
  .props({
    intrlkSyss: types.optional(types.array(IntrlkSys), []),
    modalIntrlkSyss: types.optional(types.array(IntrlkSys), []),
    selectedIntrlkSysFM: types.optional(types.maybeNull(IntrlkSys), null),
    selectedIntrlkSys: createIntrlkSys(),
    modalSelectedIntrlkSys: createIntrlkSys(),
    addIntrlkSys: createIntrlkSys(),
    updateIntrlkSys: createIntrlkSys(),
    deleteIntrlSysList: types.optional(types.array(types.number), []),
    pagination: createPagination(),
    modalPagination: createPagination(),
    selectedIntrlkSrvrSysSid: types.maybeNull(types.number),

    intrlkSysSrvrs: types.optional(types.array(createIntrlkSysSrvr()), []),
    intrlkSysSrvrPagination: createPagination(),
    selectedIntrlkSysSrvr: createIntrlkSysSrvr(),
    checkDeleteIntrlkSysSidList: types.optional(types.array(types.number), []),
    checkResult: types.optional(types.array(types.string), []),
  })
  .extend(withEnvironment)
  .extend(withRootStore)
  .views((self) => ({}))
  .actions((self) => ({
    setSelectedIntrlkSys: (intrlkSys: IIntrlkSysSnapshot) => {
      self.selectedIntrlkSys = intrlkSys as IIntrlkSys;
    },
    setSelectedIntrlkSysFM: (intrlkSys: IIntrlkSysSnapshot | null) => {
      self.selectedIntrlkSysFM = intrlkSys as IIntrlkSys | null;
    },
    setAddIntrlkSys: (intrlkSys: IIntrlkSysSnapshot) => {
      self.selectedIntrlkSys = intrlkSys as IIntrlkSys;
    },
    setUpdateIntrlkSys: (intrlkSys: IIntrlkSysSnapshot) => {
      self.selectedIntrlkSys = intrlkSys as IIntrlkSys;
    },
    setIntrlkSyss: (intrlkSyss: IIntrlkSysSnapshot[]) => {
      self.intrlkSyss.replace(intrlkSyss as IIntrlkSys[]);
    },
    setModalSelectedIntrlkSys: (intrlkSys: IIntrlkSysSnapshot) => {
      self.modalSelectedIntrlkSys = intrlkSys as IIntrlkSys;
    },
    setModalIntrlkSyss: (intrlkSyss: IIntrlkSysSnapshot[]) => {
      self.modalIntrlkSyss.replace(intrlkSyss as IIntrlkSys[]);
    },
    setPagination: (pagination: IPaginationSnapshot) => {
      self.pagination = pagination as IPagination;
    },
    setModalPagination: (pagination: IPaginationSnapshot) => {
      self.modalPagination = pagination as IPagination;
    },

    setIntrlSysList: (deleteSid: number[]) => {
      self.deleteIntrlSysList.replace(deleteSid);
    },
    appendIntrlSysList: (deleteSid: number) => {
      self.deleteIntrlSysList.push(deleteSid);
    },
    setSelectedIntrlksSrvrSysSid: (intrlkSrvrSysSid: number) => {
      self.selectedIntrlkSrvrSysSid = intrlkSrvrSysSid;
    },
    setSelectedIntrlkSysSrvr: (intrlkSysSrvr: IIntrlkSysSrvrSnapshot) => {
      self.selectedIntrlkSysSrvr = intrlkSysSrvr as IIntrlkSysSrvr;
    },
    setIntrlkSysSrvrPagination: (pagination: IPaginationSnapshot) => {
      self.intrlkSysSrvrPagination = pagination as IPagination;
    },
    setIntrlkSysSrvrs: (intrlkSysSrvrs: IIntrlkSysSrvrSnapshot[]) => {
      self.intrlkSysSrvrs?.replace(intrlkSysSrvrs as IIntrlkSysSrvr[]);
    },
    appendCheckDeleteIntrlkSysSidList: (intrlkSysSid: number) => {
      self.checkDeleteIntrlkSysSidList.push(intrlkSysSid);
    },
    replaceCheckDeleteIntrlkSysSidList: (intrlkSysSidList: number[]) => {
      self.checkDeleteIntrlkSysSidList.replace(intrlkSysSidList);
    },
    setCheckResult: (checkResult: string[]) => {
      self.checkResult.replace(checkResult);
    },
  }))
  .actions((self) => ({
    /**
     * INF_CAIMS_00124
     * 연동 시스템 목록을 조회한다.
     * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=1988415981
     * @param intrlkSysFliter
     */
    gets: async (intrlkSysFliter?: TIntrlkSysFilter) => {
      self.setIntrlkSyss([]);
      try {
        const intrlkSysApi: IntrlkSysApi = new IntrlkSysApi(self.environment.api);
        let result: TGetIntrlkSyssResult;
        if (intrlkSysFliter) {
          result = await intrlkSysApi.gets(self.pagination, intrlkSysFliter);
        } else {
          result = await intrlkSysApi.gets(self.pagination);
        }

        if (
          self.rootStore.responseStore.getResponseResult(result) &&
          result.pagination &&
          result.pagination.page === self.pagination.page
        ) {
          if (result.intrlkSyss) {
            result.intrlkSyss.forEach((item: any) => {
              item.regDt = timestampToDate(Number.parseInt(item.regDt));
              item.updDt = timestampToDate(Number.parseInt(item.updDt));
              if (item.intrlkSysSrvrList) {
                item.intrlkSysSrvrList.forEach((intrlkSysSrvr: IIntrlkSysSrvrSnapshot) => {
                  intrlkSysSrvr.regDt = timestampToDate(Number.parseInt(intrlkSysSrvr.regDt));
                  intrlkSysSrvr.updDt = timestampToDate(Number.parseInt(intrlkSysSrvr.updDt));
                });
              }
            });

            self.setIntrlkSyss(result.intrlkSyss);
            if (result.pagination) {
              self.setPagination(result.pagination);
            }
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },

    /**
     * INF_CAIMS_00124
     * 연동 시스템 목록을 조회한다.
     * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=1988415981
     * @param intrlkSysFliter
     */
    modalGets: async (intrlkSysFliter?: TIntrlkSysFilter) => {
      self.setModalIntrlkSyss([]);
      try {
        const intrlkSysApi: IntrlkSysApi = new IntrlkSysApi(self.environment.api);
        let result: TGetIntrlkSyssResult;
        if (intrlkSysFliter) {
          result = await intrlkSysApi.gets(self.pagination, intrlkSysFliter);
        } else {
          result = await intrlkSysApi.gets(self.pagination);
        }

        if (self.rootStore.responseStore.getResponseResult(result)) {
          if (result.intrlkSyss) {
            result.intrlkSyss.forEach((item: any) => {
              item.regDt = timestampToDate(Number.parseInt(item.regDt));
              item.updDt = timestampToDate(Number.parseInt(item.updDt));
              if (item.intrlkSysSrvrList) {
                item.intrlkSysSrvrList.forEach((intrlkSysSrvr: IIntrlkSysSrvrSnapshot) => {
                  intrlkSysSrvr.regDt = timestampToDate(Number.parseInt(intrlkSysSrvr.regDt));
                  intrlkSysSrvr.updDt = timestampToDate(Number.parseInt(intrlkSysSrvr.updDt));
                });
              }
            });

            self.setModalIntrlkSyss(result.intrlkSyss);
            if (result.pagination) {
              self.setModalPagination(result.pagination);
            }
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },

    /**
     * INF_CAIMS_00125
     * 해당 연동시스템의 상세정보를 조회한다.
     * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=351035836
     * @param intrlkSysSid
     */
    get: async (intrlkSysSid: number) => {
      try {
        const intrlkSysApi: IntrlkSysApi = new IntrlkSysApi(self.environment.api);
        const result: TGetIntrlkSysResult = await intrlkSysApi.get(intrlkSysSid);

        if (self.rootStore.responseStore.getResponseResult(result)) {
          if (result.intrlkSys) {
            result.intrlkSys.regDt = timestampToDate(Number.parseInt(result.intrlkSys.regDt));
            result.intrlkSys.updDt = timestampToDate(Number.parseInt(result.intrlkSys.updDt));
            if (result.intrlkSys.intrlkSysSrvrList) {
              result.intrlkSys.intrlkSysSrvrList.forEach(
                (linkSystemServer: IIntrlkSysSrvrSnapshot) => {
                  linkSystemServer.regDt = timestampToDate(Number.parseInt(linkSystemServer.regDt));
                  linkSystemServer.updDt = timestampToDate(Number.parseInt(linkSystemServer.updDt));
                },
              );
            }

            self.selectedIntrlkSys.resetIntrlkSys();
            self.setSelectedIntrlkSys(result.intrlkSys);
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },
    /**
     * INF_CAIMS_00125
     * 해당 연동시스템의 상세정보를 조회한다.
     * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=351035836
     * @param intrlkSysSid
     */
    modalGet: async (intrlkSysSid: number) => {
      try {
        const intrlkSysApi: IntrlkSysApi = new IntrlkSysApi(self.environment.api);
        const result: TGetIntrlkSysResult = await intrlkSysApi.get(intrlkSysSid);

        if (self.rootStore.responseStore.getResponseResult(result)) {
          if (result.intrlkSys) {
            result.intrlkSys.regDt = timestampToDate(Number.parseInt(result.intrlkSys.regDt));
            result.intrlkSys.updDt = timestampToDate(Number.parseInt(result.intrlkSys.updDt));
            if (result.intrlkSys.intrlkSysSrvrList) {
              result.intrlkSys.intrlkSysSrvrList.forEach(
                (linkSystemServer: IIntrlkSysSrvrSnapshot) => {
                  linkSystemServer.regDt = timestampToDate(Number.parseInt(linkSystemServer.regDt));
                  linkSystemServer.updDt = timestampToDate(Number.parseInt(linkSystemServer.updDt));
                },
              );
            }

            self.modalSelectedIntrlkSys.resetIntrlkSys();
            self.setModalSelectedIntrlkSys(result.intrlkSys);
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },
    /**
     * INF_CAIMS_00126
     * 새로운 연동시스템을 등록한다.
     * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=1849530440
     * @param intrlkSys
     */
    post: async (intrlkSys: IIntrlkSysSnapshot) => {
      try {
        const intrlkSysApi: IntrlkSysApi = new IntrlkSysApi(self.environment.api);
        const result: TPostIntrlkSysResult = await intrlkSysApi.post(intrlkSys);
        if (self.rootStore.responseStore.getResponseResult(result)) {
          self.addIntrlkSys.resetIntrlkSys();
          if (result.intrlkSys) {
            result.intrlkSys.regDt = timestampToDate(Number.parseInt(result.intrlkSys.regDt));
            result.intrlkSys.updDt = timestampToDate(Number.parseInt(result.intrlkSys.updDt));
            if (result.intrlkSys.intrlkSysSrvrList) {
              result.intrlkSys.intrlkSysSrvrList.forEach((item: IIntrlkSysSrvrSnapshot) => {
                item.regDt = timestampToDate(Number.parseInt(item.regDt));
                item.updDt = timestampToDate(Number.parseInt(item.updDt));
              });
            }

            self.setAddIntrlkSys(result.intrlkSys);
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },

    /**
     * INF_CAIMS_00127
     * 해당 연동시스템 정보를 수정한다.
     * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=1404753331
     * @param intrlkSys
     */
    put: async (intrlkSys: IIntrlkSysSnapshot) => {
      try {
        const intrlkSysApi: IntrlkSysApi = new IntrlkSysApi(self.environment.api);
        const result: TUpdateIntrlkSysResult = await intrlkSysApi.put(intrlkSys);

        if (self.rootStore.responseStore.getResponseResult(result)) {
          self.updateIntrlkSys.resetIntrlkSys();
          if (result.intrlkSys) {
            result.intrlkSys.regDt = timestampToDate(Number.parseInt(result.intrlkSys.regDt));
            result.intrlkSys.updDt = timestampToDate(Number.parseInt(result.intrlkSys.updDt));
            if (result.intrlkSys.intrlkSysSrvrList) {
              result.intrlkSys.intrlkSysSrvrList.forEach((item: IIntrlkSysSrvrSnapshot) => {
                item.regDt = timestampToDate(Number.parseInt(item.regDt));
                item.updDt = timestampToDate(Number.parseInt(item.updDt));
              });
            }

            self.setUpdateIntrlkSys(result.intrlkSys);
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },

    /**
     * INF_CAIMS_00128
     * 해당 연동시스템을 삭제한다.
     * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=1401748613
     * @param intrlkSysSid
     */
    delete: async (intrlkSysSid: number) => {
      try {
        const intrlkSysApi: IntrlkSysApi = new IntrlkSysApi(self.environment.api);
        const result: TDeleteIntrlkSysResult = await intrlkSysApi.delete(intrlkSysSid);
        self.rootStore.responseStore.getResponseResult(result);
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },

    /**
     * INF_CAIMS_00129
     * 여러개 연동시스템을 한번에 삭제한다.
     * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=1428605051
     */
    deletes: async () => {
      try {
        const intrlkSysApi: IntrlkSysApi = new IntrlkSysApi(self.environment.api);
        const result: TDeleteIntrlkSysResult = await intrlkSysApi.deletes(self.deleteIntrlSysList);
        self.rootStore.responseStore.getResponseResult(result);
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },

    /**
     * INF_CAIMS_00242
     * 연동시스템 전체 목록을 조회한다.
     * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=891519732
     */
    getAll: async () => {
      self.setIntrlkSyss([]);
      try {
        const intrlkSysApi: IntrlkSysApi = new IntrlkSysApi(self.environment.api);
        const result: TGetIntrlkSyssResult = await intrlkSysApi.getAll();

        if (self.rootStore.responseStore.getResponseResult(result)) {
          if (result.intrlkSyss) {
            result.intrlkSyss.forEach((linkSystem: any) => {
              linkSystem.regDt = timestampToDate(Number.parseInt(linkSystem.regDt));
              linkSystem.updDt = timestampToDate(Number.parseInt(linkSystem.updDt));
              linkSystem.intrlkSysSrvrList = [];
            });
            self.setIntrlkSyss(result.intrlkSyss);
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },

    /**
     * INF_CAIMS_00201
     * 전체 연동 시스템 서버 목록을 조회한다.
     * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=198928292
     */
    getServers: async (intrlkSysSrvrFilter?: IIntrlkSysSrvrFilter) => {
      self.setIntrlkSysSrvrs([]);
      try {
        const intrlkSysApi: IntrlkSysApi = new IntrlkSysApi(self.environment.api);
        let result: TGetIntrlkSysServersResult;
        if (intrlkSysSrvrFilter) {
          result = await intrlkSysApi.getServers(self.pagination, intrlkSysSrvrFilter);
        } else {
          result = await intrlkSysApi.getServers(self.pagination);
        }

        if (self.rootStore.responseStore.getResponseResult(result)) {
          if (result.intrlkSysSrvrs) {
            self.setIntrlkSysSrvrs(result.intrlkSysSrvrs);
          }
          if (result.pagination) {
            self.setIntrlkSysSrvrPagination(result.pagination);
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },
    /**
     * INF_CAIMS_00202
     * 해당 연동 시스템 서버 상세정보를 조회한다.
     * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=452324458
     */
    getServer: async (intrlkSysSrvrSid: number) => {
      try {
        const intrlkSysApi: IntrlkSysApi = new IntrlkSysApi(self.environment.api);
        const result: TGetIntrlkSysServerResult = await intrlkSysApi.getServer(intrlkSysSrvrSid);

        if (self.rootStore.responseStore.getResponseResult(result)) {
          if (result.intrlkSysSrvr) {
            self.setSelectedIntrlkSysSrvr(result.intrlkSysSrvr);
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },

    /**
     * INF_CAIMS_00246
     * 특정 연동시스템을 삭제 할 수 있는지 확인한다.
     * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=1022464599
     */
    checkDeleteIntrlkSys: async () => {
      try {
        const intrlkSysApi: IntrlkSysApi = new IntrlkSysApi(self.environment.api);
        const result: TCheckDeleteIntrlkSysResult = await intrlkSysApi.checkDeleteIntrlkSys(
          self.checkDeleteIntrlkSysSidList,
        );

        if (self.rootStore.responseStore.getResponseResult(result)) {
          if (result.checkResult) {
            self.setCheckResult(result.checkResult);
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },
  }));

type TIntrlkSysStore = Instance<typeof IntrlkSysStore>;
type TIntrlkSysStoreSnapshot = SnapshotOut<typeof IntrlkSysStore>;

export interface IIntrlkSysStore extends TIntrlkSysStore {}
export type TIntrlkSysStoreKeys = keyof TIntrlkSysStoreSnapshot & string;
export interface IIntrlkSysStoreSnapshot extends TIntrlkSysStoreSnapshot {}
export const createIntrlkSysStore = () => types.optional(IntrlkSysStore, {} as TIntrlkSysStore);

export interface IIntrlkSysFilter {
  sysNm?: string;
}

export type TIntrlkSysFilter = IIntrlkSysFilter;
