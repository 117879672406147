import { createModelCode } from '../model-code/ModelCode';
import { Instance, SnapshotOut, types } from 'mobx-state-tree';

/**
 * # RulesetMappgSvrtCond
 *
 * RulesetMappgSvrtCond을 설명하세요.
 */
export const RulesetMappgSvrtCond = types
  .model('RulesetMappgSvrtCond')
  // --------------------------------------------------------------------------
  .props({
    ruleSetSid: types.optional(types.maybeNull(types.number), null),
    ruleSetMappgSid: types.optional(types.maybeNull(types.number), null),
    svrtCondSid: types.optional(types.maybeNull(types.number), null),
    extrtKey: types.optional(types.maybeNull(types.string), null),
    typeCond: types.maybeNull(createModelCode()),
    comprVal: types.optional(types.maybeNull(types.string), null),
    svrt: types.maybeNull(createModelCode()),
  })
  // --------------------------------------------------------------------------
  // MUTATEs ONLY! - 모델 상태를 변경
  // REQUESTs는 스토어 모델에 작성하세요.
  // ex) yarn gen model rulesetMappgSvrtCond --store
  .actions((self) => ({
    /*
     * 여러 prop을 한번에 수정
     */
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as IRulesetMappgSvrtCond;
      (Object.keys(newProps) as TRulesetMappgSvrtCondKeys[]).forEach((key) => {
        //@ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type TRulesetMappgSvrtCond = Instance<typeof RulesetMappgSvrtCond>;
type TRulesetMappgSvrtCondSnapshot = SnapshotOut<typeof RulesetMappgSvrtCond>;

export interface IRulesetMappgSvrtCond extends TRulesetMappgSvrtCond {}
export type TRulesetMappgSvrtCondKeys = keyof TRulesetMappgSvrtCondSnapshot & string;
export interface IRulesetMappgSvrtCondSnapshot extends TRulesetMappgSvrtCondSnapshot {}
export const createRulesetMappgSvrtCond = () =>
  types.optional(RulesetMappgSvrtCond, {
    ruleSetSid: null,
    ruleSetMappgSid: null,
    svrtCondSid: null,
    extrtKey: null,
    typeCond: null,
    comprVal: null,
    svrt: null,
  } as TRulesetMappgSvrtCond);
