import { Instance, ReferenceIdentifier, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree';
import { INode, Node } from './Node';
import { LinkTypes, mstCustomTypes, PersistenceState } from './types';
import { THREE, UUID } from 'network-diagram';

/**
 * # Link
 *
 * 네트워크 다이어그램 상에 엘리먼트 간의 연결을 표시하기 위한 데이터 모델입니다.
 */
export const Link = types
  .model('Link')
  // --------------------------------------------------------------------------
  .props({
    id: types.identifier,
    name: types.string,
    from: types.maybe(types.reference(types.late(() => Node))),
    to: types.maybe(types.reference(types.late(() => Node))),
    points: types.optional(types.array(types.number), []),
    color: types.optional(mstCustomTypes.color(), '#000000'),
    userData: mstCustomTypes.userData(),
    type: types.optional(mstCustomTypes.link(), LinkTypes.SOLID),
    width: types.optional(types.number, 0.1),
    state: types.optional(mstCustomTypes.state(), PersistenceState.CLEAN),
  })
  // --------------------------------------------------------------------------
  // GETTERs
  .views((self) => ({}))
  // --------------------------------------------------------------------------
  // SETTERs ONLY! - 모델 상태를 변경
  // REQUESTs는 스토어 모델에 작성하세요.
  // ex) yarn gen model link --store
  .actions((self) => ({
    setId: (id: string) => {
      throw new Error('ID는 수정할 수 없습니다.');
    },
    setName: (name: string) => {
      self.name = name;
      self.state = PersistenceState.DIRTY;
    },
    setFrom: (node: INode | ReferenceIdentifier) => {
      // @ts-ignore : MST 에서 참조 값은 ID이지만 객체로 노출된다. TS에서 미리 객체 타입으로 설정되는 오류이다.
      self.from = node;
      self.state = PersistenceState.DIRTY;
    },
    setTo: (node: INode | ReferenceIdentifier) => {
      // @ts-ignore : MST 에서 참조 값은 ID이지만 객체로 노출된다.
      // 설정할 때는 string | number 타입이고, 사용할 때는 참조하는 객체의 타입이어야 한다.
      // 컴파일러에서는 그것을 구분하지 않고, 사용할 때의 타입으로 검증하려고 한다.
      self.to = node;
      self.state = PersistenceState.DIRTY;
    },
    setPoints: (points: number[]) => {
      if (points.length % 3 > 0) {
        throw new Error('points의 요소의 갯수는 3의 배수여야 한다.');
      }
      // @ts-ignore
      self.points = points;
      self.state = PersistenceState.DIRTY;
    },
    setType: (type: LinkTypes) => {
      self.type = type;
      self.state = PersistenceState.DIRTY;
    },
    setWidth: (width: number) => {
      self.width = width;
      self.state = PersistenceState.DIRTY;
    },
    setColor: (color: THREE.ColorRepresentation) => {
      self.color = color;
      self.state = PersistenceState.DIRTY;
    },
    setUserData: (data: any) => {
      self.userData = data;
      self.state = PersistenceState.DIRTY;
    },
    setState: (state: PersistenceState) => {
      self.state = state;
      self.state = PersistenceState.DIRTY;
    },
    // life-cycle hooks
  }));
// --------------------------------------------------------------------------
type TLink = Instance<typeof Link>;
type TLinkSnapshotIn = SnapshotIn<typeof Link>;
type TLinkSnapshotOut = SnapshotOut<typeof Link>;
export interface ILink extends TLink {}
export type TLinkKeys = keyof TLinkSnapshotOut & string;
export interface ILinkSnapshotIn extends TLinkSnapshotIn {}
export interface ILinkSnapshotOut extends TLinkSnapshotOut {}
export const createLink = (args?: ILinkSnapshotIn) =>
  Link.create({
    id: UUID.generate(),
    name: 'Undefined',
    from: '',
    to: '',
    state: PersistenceState.NEW,
    width: 0.1,
    ...args,
  });
