import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ReplayIcon from '@mui/icons-material/Replay';
import SearchIcon from '@mui/icons-material/Search';
import {
  Accordion,
  AccordionSummary,
  Box,
  Button,
  ButtonGroup,
  Card,
  Checkbox,
  Collapse,
  FormControlLabel,
  Grid,
  InputLabel,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import * as _ from 'lodash';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { CC } from '../../../commonCodes/commonCodes';
import CAlert from '../../../components/CAlert';
import CDateAndTimePicker from '../../../components/forms/CDateAndTimePicker';
import CTextField from '../../../components/forms/CTextField';
import { FormProvider } from '../../../components/hook-form';
import { FORMSET_ITEM_LABEL } from '../../../components/settings/SettingConstEnum';
import { IRealtimeMonitoringAlarmFilter } from '../../../models';

interface RealtimeMonitoringSearchAreaProps {
  onSearch: (data: IRealtimeMonitoringAlarmFilter) => void;
  openSearchArea: boolean;
  onDownload?: (data: IRealtimeMonitoringAlarmFilter) => void;
}

const RealtimeMonitoringSearchArea = observer(
  ({ onSearch, openSearchArea, onDownload }: RealtimeMonitoringSearchAreaProps) => {
    const [isAlertOpen, setIsAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const handleAlertClose = () => setIsAlertOpen(false);
    const handleAlertOpen = (message: string) => {
      if (message) {
        setAlertMessage(message);
        setIsAlertOpen(true);
      }
    };

    const [checkData, setCheckData] = useState<{
      eventState: number[];
      perceivedSeverity: number[];
    }>({
      eventState: [],
      perceivedSeverity: [],
    });

    const methods = useForm<IRealtimeMonitoringAlarmFilter>({});

    const {
      handleSubmit,
      formState: { errors },
      reset,
      setValue,
      getValues,
    } = methods;

    const onSubmit = async (data: IRealtimeMonitoringAlarmFilter) => {
      if (_.isEmpty(errors)) {
        onSearch(data as IRealtimeMonitoringAlarmFilter);
      }
    };

    useEffect(() => {
      setValue('eventTimeSt', moment().format('YYYY-MM-DD 00:00:00'));
      setValue('eventTimeEd', moment().format('YYYY-MM-DD 23:59:59'));
    }, []);

    const handleCheckbox = (e: any) => {
      const value = e.target.value;
      const obj = JSON.parse(value);
      if (obj.field) {
        let index = checkData[obj.field as keyof typeof checkData].indexOf(obj.value);
        if (index > -1) {
          let fieldValue = [...checkData[obj.field as keyof typeof checkData]];
          fieldValue.splice(index, 1);
          let data = { ...checkData, [obj.field as keyof typeof checkData]: fieldValue };

          setCheckData(data);
        } else {
          let data = {
            ...checkData,
            [obj.field as keyof typeof checkData]: Array.from(
              new Set([...checkData[obj.field as keyof typeof checkData], obj.value]),
            ),
          };
          setCheckData(data);
        }
      }
    };

    useEffect(() => {
      setValue('eventStateList', checkData.eventState);
      setValue('perceivedSeverityList', checkData.perceivedSeverity);
      onSearch(getValues());
    }, [checkData]);

    const download = async () => {
      if (onDownload !== undefined) {
        const startDt = getValues('eventTimeSt');
        const endDt = getValues('eventTimeEd');

        if (!startDt || !endDt) {
          handleAlertOpen("'조회기간(시작일)'을 선택해 주세요.");
          return;
        }

        const data: IRealtimeMonitoringAlarmFilter = getValues();
        onDownload(data as IRealtimeMonitoringAlarmFilter);
      }
    };

    return (
      <>
        {' '}
        <Collapse in={openSearchArea} timeout="auto" unmountOnExit>
          <FormProvider methods={methods}>
            <Card className={'search-area'}>
              <Accordion expanded={false} className={'search-area'}>
                <AccordionSummary>
                  <Stack
                    width={'100%'}
                    direction={'row'}
                    sx={{
                      pointerEvents: 'auto',
                      display: 'grid',
                      gridGap: '8px',
                      gridTemplateColumns: '1fr 120px',
                      marginBottom: '6px',
                    }}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={4}>
                        <Box sx={{ display: 'flex' }}>
                          <InputLabel
                            style={{
                              display: 'flex',
                              border: 'none',
                              // alignItems: 'center',
                              marginLeft: '8px',
                              minWidth: '40px',
                            }}
                          >
                            상태
                          </InputLabel>

                          <FormControlLabel
                            sx={{ display: 'flex' }}
                            labelPlacement="start"
                            label={FORMSET_ITEM_LABEL.realtimeMonitoring.outstanding}
                            control={
                              <Checkbox
                                checked={checkData.eventState.some(
                                  (item) => item == CC.EVENT_STTUS.Outstanding,
                                )}
                                value={`{"field": "eventState", "value": ${CC.EVENT_STTUS.Outstanding}}`}
                                onChange={handleCheckbox}
                              />
                            }
                          />
                          <FormControlLabel
                            labelPlacement="start"
                            label={FORMSET_ITEM_LABEL.realtimeMonitoring.acknowledged}
                            control={
                              <Checkbox
                                checked={checkData.eventState.some(
                                  (item) => item == CC.EVENT_STTUS.Acknowledged,
                                )}
                                value={`{"field": "eventState", "value": ${CC.EVENT_STTUS.Acknowledged}}`}
                                onChange={handleCheckbox}
                              />
                            }
                          />
                          <FormControlLabel
                            labelPlacement="start"
                            label={FORMSET_ITEM_LABEL.realtimeMonitoring.terminated}
                            control={
                              <Checkbox
                                checked={checkData.eventState.some(
                                  (item) => item == CC.EVENT_STTUS.Terminated,
                                )}
                                value={`{"field": "eventState", "value": ${CC.EVENT_STTUS.Terminated}}`}
                                onChange={handleCheckbox}
                              />
                            }
                          />
                        </Box>
                      </Grid>

                      <Grid item xs={4}>
                        <Box sx={{ display: 'flex' }}>
                          <InputLabel
                            style={{
                              display: 'flex',
                              border: 'none',
                              // alignItems: 'center',
                              marginLeft: '8px',
                              minWidth: '40px',
                            }}
                          >
                            등급
                          </InputLabel>

                          <FormControlLabel
                            labelPlacement="start"
                            label={FORMSET_ITEM_LABEL.realtimeMonitoring.critical}
                            control={
                              <Checkbox
                                checked={checkData.perceivedSeverity.some(
                                  (item) => item == CC.SVRT.Critical,
                                )}
                                value={`{"field": "perceivedSeverity", "value": ${CC.SVRT.Critical}}`}
                                onChange={handleCheckbox}
                              />
                            }
                          />
                          <FormControlLabel
                            labelPlacement="start"
                            label={FORMSET_ITEM_LABEL.realtimeMonitoring.major}
                            control={
                              <Checkbox
                                checked={checkData.perceivedSeverity.some(
                                  (item) => item == CC.SVRT.Major,
                                )}
                                value={`{"field": "perceivedSeverity", "value": ${CC.SVRT.Major}}`}
                                onChange={handleCheckbox}
                              />
                            }
                          />
                          <FormControlLabel
                            labelPlacement="start"
                            label={FORMSET_ITEM_LABEL.realtimeMonitoring.minor}
                            control={
                              <Checkbox
                                checked={checkData.perceivedSeverity.some(
                                  (item) => item == CC.SVRT.Minor,
                                )}
                                value={`{"field": "perceivedSeverity", "value": ${CC.SVRT.Minor}}`}
                                onChange={handleCheckbox}
                              />
                            }
                          />
                          <FormControlLabel
                            labelPlacement="start"
                            label={FORMSET_ITEM_LABEL.realtimeMonitoring.warning}
                            control={
                              <Checkbox
                                checked={checkData.perceivedSeverity.some(
                                  (item) => item == CC.SVRT.Warning,
                                )}
                                value={`{"field": "perceivedSeverity", "value": ${CC.SVRT.Warning}}`}
                                onChange={handleCheckbox}
                              />
                            }
                          />
                        </Box>
                      </Grid>

                      <Grid item xs={2}>
                        <CTextField
                          label={FORMSET_ITEM_LABEL.realtimeMonitoring.targetInstanceName}
                          name={'targetInstanceName'}
                          onEnterCallback={handleSubmit(onSubmit)}
                          onChangeCallback={() => onSearch(getValues())}
                          help={false}
                        ></CTextField>
                      </Grid>

                      <Grid item xs={2}>
                        <CTextField
                          label={FORMSET_ITEM_LABEL.realtimeMonitoring.targetInstanceId}
                          name={'targetInstanceId'}
                          onEnterCallback={handleSubmit(onSubmit)}
                          onChangeCallback={() => onSearch(getValues())}
                          help={false}
                        ></CTextField>
                      </Grid>

                      <Grid item xs={2}>
                        <CTextField
                          label={FORMSET_ITEM_LABEL.realtimeMonitoring.specificProblem}
                          name={'specificProblem'}
                          onEnterCallback={handleSubmit(onSubmit)}
                          onChangeCallback={() => onSearch(getValues())}
                          help={false}
                        ></CTextField>
                      </Grid>

                      <Grid item xs={2}>
                        <CTextField
                          label={FORMSET_ITEM_LABEL.realtimeMonitoring.message}
                          name={'message'}
                          onEnterCallback={handleSubmit(onSubmit)}
                          onChangeCallback={() => onSearch(getValues())}
                          help={false}
                        ></CTextField>
                      </Grid>

                      <Grid item xs={4} sx={{ display: 'flex', alignItems: 'center' }}>
                        <InputLabel
                          style={{
                            display: 'flex',
                            border: 'none',
                            marginLeft: '8px',
                            minWidth: '60px',
                            alignItems: 'center',
                          }}
                        >
                          {FORMSET_ITEM_LABEL.realtimeMonitoring.eventTimeSt}
                        </InputLabel>
                        <Box sx={{ mr: 1 }}>
                          <CDateAndTimePicker
                            // label={FORMSET_ITEM_LABEL.realtimeMonitoring.eventTimeSt}
                            name={'eventTimeSt'}
                            format={'HH:mm'}
                            type={'Time'}
                            onEnterCallback={handleSubmit(onSubmit)}
                            onChangeCallback={() => onSearch(getValues())}
                            search
                          ></CDateAndTimePicker>
                        </Box>
                        <Typography sx={{ marginLeft: '10px' }}>~ </Typography>
                        <Box sx={{ mr: 1 }}>
                          <CDateAndTimePicker
                            // label={FORMSET_ITEM_LABEL.realtimeMonitoring.eventTimeEd}
                            name={'eventTimeEd'}
                            format={'HH:mm'}
                            type={'Time'}
                            onEnterCallback={handleSubmit(onSubmit)}
                            onChangeCallback={() => onSearch(getValues())}
                            search
                          ></CDateAndTimePicker>
                        </Box>
                      </Grid>

                      <Grid item xs={4} sx={{ display: 'flex', alignItems: 'center' }}>
                        <InputLabel
                          style={{
                            display: 'flex',
                            border: 'none',
                            marginLeft: '8px',
                            minWidth: '60px',
                            alignItems: 'center',
                          }}
                        >
                          {FORMSET_ITEM_LABEL.realtimeMonitoring.eventTimeEd}
                        </InputLabel>
                        <Box sx={{ mr: 1 }}>
                          <CDateAndTimePicker
                            label={FORMSET_ITEM_LABEL.realtimeMonitoring.eventTimeSt}
                            name={'clearanceTimeSt'}
                            format={'HH:mm'}
                            type={'Time'}
                            onEnterCallback={handleSubmit(onSubmit)}
                            onChangeCallback={() => onSearch(getValues())}
                            search
                          ></CDateAndTimePicker>
                        </Box>
                        <Typography sx={{ marginLeft: '10px' }}>~ </Typography>
                        <Box sx={{ mr: 1 }}>
                          <CDateAndTimePicker
                            label={FORMSET_ITEM_LABEL.realtimeMonitoring.eventTimeEd}
                            name={'clearanceTimeEd'}
                            format={'HH:mm'}
                            type={'Time'}
                            onEnterCallback={handleSubmit(onSubmit)}
                            onChangeCallback={() => onSearch(getValues())}
                            search
                          ></CDateAndTimePicker>
                        </Box>
                      </Grid>
                    </Grid>

                    <Box sx={{ mt: 0.4, ml: 1 }}>
                      <ButtonGroup
                        variant="contained"
                        aria-label="검색, 취소, 다운로드 버튼"
                        color={'secondary'}
                      >
                        <Tooltip title="Reset" arrow={true}>
                          <Button
                            aria-label="reset"
                            onClick={() => {
                              reset();
                              setTimeout(() => {
                                setValue('eventTimeSt', moment().format('YYYY-MM-DD 00:00:00'));
                                setValue('eventTimeEd', moment().format('YYYY-MM-DD 23:59:59'));
                                setCheckData({
                                  eventState: [],
                                  perceivedSeverity: [],
                                });
                              }, 100);
                              onSearch(getValues());
                            }}
                          >
                            <ReplayIcon />
                          </Button>
                        </Tooltip>
                        <Tooltip title="Download" arrow={true}>
                          <Button aria-label="download" onClick={download}>
                            <FileDownloadIcon />
                          </Button>
                        </Tooltip>
                      </ButtonGroup>
                    </Box>
                  </Stack>
                </AccordionSummary>
              </Accordion>

              <CAlert
                key={'downloadAlert'}
                isAlertOpen={isAlertOpen}
                alertCategory={'warning'}
                alertContent={alertMessage}
                alertTitle={''}
                handleAlertClose={handleAlertClose}
              />
            </Card>
          </FormProvider>
        </Collapse>
      </>
    );
  },
);

export default RealtimeMonitoringSearchArea;
