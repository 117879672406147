import { Instance, SnapshotOut, types } from "mobx-state-tree"
import { createSimpleUser } from "../simple-user/SimpleUser";

export const Notification = types
  .model("Notification")
  .props({
    ntfSid: types.optional(types.number, -1),
    ntfTitle: types.optional(types.maybeNull(types.string), null),
    ntfConts: types.optional(types.maybeNull(types.string), ''),
    ntfOrder: types.optional(types.maybeNull(types.number), null),
    showStartDt: types.optional(types.maybeNull(types.string), null),
    showEndDt: types.optional(types.maybeNull(types.string), null),
    delYn: types.optional(types.maybeNull(types.boolean), false),


    regUser: types.maybeNull(createSimpleUser()),
    regDt: types.optional(types.maybeNull(types.string), null),
    updUser: types.maybeNull(createSimpleUser()),
    updDt: types.optional(types.maybeNull(types.string), null),
  })
  .actions((self) => ({
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as INotification;
      (Object.keys(newProps) as TNotificationKeys[]).forEach((key) => {
        //@ts-ignore
        self[key] = newProps[key];
      });
    },
  }));


export const NoticeCloseInfo = types
  .model("INoticeCloseInfo")
  .props({
    ntfSid: types.number,
    dateTimestamp: types.optional(types.maybeNull(types.number), null),
  });

// --------------------------------------------------------------------------
type TNotification = Instance<typeof Notification>;
type TNotificationSnapshot = SnapshotOut<typeof Notification>

export interface INotification extends TNotification {}
export type TNotificationKeys = keyof TNotificationSnapshot & string;
export interface INotificationSnapshot extends TNotificationSnapshot {}
export const createNotification = () => 
  types.optional(Notification, {

  } as TNotification);


type TNoticeCloseInfo = Instance<typeof NoticeCloseInfo>;
type TNoticeCloseInfoSnapshot = SnapshotOut<typeof NoticeCloseInfo>;

export interface INoticeCloseInfo extends TNoticeCloseInfo {};
export interface INoticeCloseInfoSnapshot extends TNoticeCloseInfoSnapshot {};
