import { createChangeValue } from './../change-value/ChangeValue';
import { Instance, SnapshotOut, types } from 'mobx-state-tree';

/**
 * # HdMuxHistoryContent
 *
 * HdMuxHistoryContent을 설명하세요.
 */
export const HdMuxHistoryContent = types
  .model('HdMuxHistoryContent')
  // --------------------------------------------------------------------------
  .props({
    operation: types.optional(types.maybeNull(types.string), null),
    name: types.optional(types.maybeNull(types.string), null),
    equipSid: types.maybeNull(createChangeValue()),
    mainYn: types.maybeNull(createChangeValue()),
    hostNm: types.maybeNull(createChangeValue()),
    inPortSid: types.maybeNull(createChangeValue()),
    inPortNm: types.maybeNull(createChangeValue()),
    inBPortSid: types.maybeNull(createChangeValue()),
    inBPortNm: types.maybeNull(createChangeValue()),
    outPortSid: types.maybeNull(createChangeValue()),
    outPortNm: types.maybeNull(createChangeValue()),
    outBPortSid: types.maybeNull(createChangeValue()),
    outBPortNm: types.maybeNull(createChangeValue()),
    scsPortSid: types.maybeNull(createChangeValue()),
    scsPortNm: types.maybeNull(createChangeValue()),
    emgInPortSid: types.maybeNull(createChangeValue()),
    emgInPortNm: types.maybeNull(createChangeValue()),
    ipout: types.maybeNull(createChangeValue()),
  })
  // --------------------------------------------------------------------------
  // MUTATEs ONLY! - 모델 상태를 변경
  // REQUESTs는 스토어 모델에 작성하세요.
  // ex) yarn gen model hdMuxHistoryContent --store
  .actions((self) => ({
    /*
     * 여러 prop을 한번에 수정
     */
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as IHdMuxHistoryContent;
      (Object.keys(newProps) as THdMuxHistoryContentKeys[]).forEach((key) => {
        //@ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type THdMuxHistoryContent = Instance<typeof HdMuxHistoryContent>;
type THdMuxHistoryContentSnapshot = SnapshotOut<typeof HdMuxHistoryContent>;

export interface IHdMuxHistoryContent extends THdMuxHistoryContent {}
export type THdMuxHistoryContentKeys = keyof THdMuxHistoryContentSnapshot & string;
export interface IHdMuxHistoryContentSnapshot extends THdMuxHistoryContentSnapshot {}
export const createHdMuxHistoryContent = () =>
  types.optional(HdMuxHistoryContent, {} as THdMuxHistoryContent);
