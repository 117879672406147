import { RESULT_CODE } from './../../components/settings/SettingConstEnum';
import { ApiResponse } from 'apisauce';
import { IResponseSnapshot, IUsrgrpFilter, TUsrgrpFilter } from '../../models';
import { Api } from '../api/api';
import { getGeneralApiProblem } from '../api/api-problem';
import { IPaginationSnapshot } from './../../models/pagination/Pagination';
import { IUsrgrpSnapshot } from './../../models/usrgrp/Usrgrp';
import {
  TDeleteUsrgrpResult,
  TDeleteUsrgrpsResult,
  TDuplicateGroupNameResult,
  TGetUsrgrpResult,
  TGetUsrgrpsResult,
  TPostUsrgrpResult,
  TUpdateUsrgrpResult,
} from './UsrgrpTypes';

export class UsrgrpApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  /**
   * INF_CAIMS_00170
   * 그룹의 전체 목록을 조회한다.
   * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=843258254
   * @param pagination
   * @param usrgrpFilter
   * @returns
   */
  async gets(
    pagination: IPaginationSnapshot,
    usrgrpFilter?: TUsrgrpFilter,
  ): Promise<TGetUsrgrpsResult> {
    try {
      const url: string = '/operation/usrgrp';
      let sendData: any = {};

      if (usrgrpFilter) {
        sendData = filterSendData(usrgrpFilter);
      }

      sendData.size = pagination.size;
      sendData.page = pagination.page;

      const response: ApiResponse<any> = await this.api.apisauce.get(url, sendData);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const usrgrps: IUsrgrpSnapshot[] = response.data.data;
      const responseInfo: IResponseSnapshot = response.data;

      pagination = response.data.pagination;
      return { kind: 'ok', usrgrps, pagination, responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  /**
   * INF_CAIMS_00171
   * 해당 그룹위 상세정보를 조회한다.
   * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=742363380
   * @param usrgrpSid
   * @returns
   */
  async get(usrgrpSid: number): Promise<TGetUsrgrpResult> {
    try {
      const url: string = `/operation/usrgrp/${usrgrpSid}`;
      const response: ApiResponse<any> = await this.api.apisauce.get(url);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      if (!response.data.data) {
        return { kind: 'bad-data' };
      }
      const usrgrp: IUsrgrpSnapshot = response.data.data;
      const responseInfo: IResponseSnapshot = response.data;

      return { kind: 'ok', usrgrp, responseInfo: responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  /**
   * INF_CAIMS_00174
   * 해당 그룹을 삭제한다
   * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=364314959
   * @param usrgrpSid
   * @returns
   */
  async delete(usrgrpSid: number): Promise<TDeleteUsrgrpResult> {
    try {
      const url: string = `/operation/usrgrp/${usrgrpSid}`;
      const response: ApiResponse<any> = await this.api.apisauce.delete(url);
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;
      return { kind: 'ok', responseInfo: responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  /**
   * INF_CAIMS_00175
   * 여러개 그룹을 한번에 삭제한다.
   * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=924758216
   * @param usrgrpSidList
   * @returns
   */
  async deletes(usrgrpSidList: number[]): Promise<TDeleteUsrgrpsResult> {
    try {
      let param: string = ``;
      usrgrpSidList.forEach((item: number, index: number) => {
        param += `usrgrpSidList=${item}`;
        if (index !== usrgrpSidList.length - 1) {
          param += `&`;
        }
      });

      const url: string = `/operation/usrgrp?` + param;

      const response: ApiResponse<any> = await this.api.apisauce.delete(url);
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;
      return { kind: 'ok', responseInfo: responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  /**
   * INF_CAIMS_00172
   * 새로운 그룹을 등록한다.
   * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=409941969
   * @param usrgrp
   * @returns
   */
  async post(usrgrp: IUsrgrpSnapshot): Promise<TPostUsrgrpResult> {
    try {
      const url: string = `/operation/usrgrp`;

      const param = {
        usrgrpNm: usrgrp.usrgrpNm,
        dscrpt: usrgrp.dscrpt
      };

      const response: ApiResponse<any> = await this.api.apisauce.post(url, param);
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;

      if (responseInfo.resultCode === 'S') {
        return { kind: 'ok', responseInfo: responseInfo, usrgrp: response.data.data };
      }

      return { kind: 'ok', responseInfo: responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  /**
   * INF_CAIMS_00173
   * 해당 그룹의 정보를 수정한다.
   * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=1372569872
   * @param usrgrp
   * @returns
   */
  async put(usrgrp: IUsrgrpSnapshot): Promise<TUpdateUsrgrpResult> {
    try {
      const url = `/operation/usrgrp/${usrgrp.usrgrpSid}`;
      interface ISendData {
        usrgrpNm?: string;
        dscrpt?: string;
      }

      let param: ISendData = {};

      if (usrgrp.usrgrpNm !== undefined) {
        param.usrgrpNm = usrgrp.usrgrpNm;
      }

      if (usrgrp.dscrpt !== undefined) {
        param.dscrpt = usrgrp.dscrpt;
      }

      const response: ApiResponse<any> = await this.api.apisauce.put(url, param);
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;

      if (responseInfo.resultCode === 'S') {
        return { kind: 'ok', responseInfo: responseInfo, usrgrp: response.data.data };
      }

      return { kind: 'ok', responseInfo: responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  /**
   * INF_CAIMS_00231
   * 특정 그룹 이름이 데이터베이스에 있는지 확인한다.
   * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=755872056
   * @param usrgrpNm
   * @returns
   */
  async checkDuplicateGroupNm(usrgrpNm: string): Promise<TDuplicateGroupNameResult> {
    const SUB_URL: string = 'operation/usrgrp/usrgrpNm/check';
    const parmas = {
      usrgrpNm: usrgrpNm,
    };
    const response: ApiResponse<any> = await this.api.apisauce.get(SUB_URL, parmas);

    const responseInfo: IResponseSnapshot = response.data;

    if (responseInfo.resultCode === RESULT_CODE.SUCCESS) {
      return { kind: 'ok', responseInfo: responseInfo, result: response.data.data.result };
    }
    return { kind: 'ok', responseInfo: responseInfo };
  }
}

const filterSendData = (usrgrpFilter: IUsrgrpFilter) => {
  let sendData: any = {};

  if (usrgrpFilter.usrgrpSid) {
    sendData.usrgrpSid = usrgrpFilter.usrgrpSid;
  }

  if (usrgrpFilter.usrgrpNm) {
    sendData.usrgrpNm = usrgrpFilter.usrgrpNm;
  }

  return sendData;
};
