import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { IHistory, IHistoryFilter } from '../../models';
import { HistoryApi, TGetHistorysResult } from '../../services';
import { timestampToDate } from '../../utils/dateExchanger';
import { withEnvironment } from '../extensions/with-environment';
import { TGetHistoryResult } from './../../services/history/HistoryTypes';
import { withRootStore } from './../extensions/with-root-store';
import { createHistory, IHistorySnapshot } from './../history/History';
import { createPagination, IPagination, IPaginationSnapshot } from './../pagination/Pagination';

export const HistoryStore = types
  .model('HistoryStore')
  .props({
    historys: types.optional(types.array(createHistory()), []),
    pagination: createPagination(),
    selectedHistory: createHistory(),
  })
  .extend(withEnvironment)
  .extend(withRootStore)
  .views((self) => ({}))
  .actions((self) => ({
    setHistorys: (historys: IHistorySnapshot[]) => {
      self.historys.replace(historys as IHistory[]);
    },
    setPagination: (pagination: IPaginationSnapshot) => {
      self.pagination = pagination as IPagination;
    },
    setSelectedHistory: (history: IHistorySnapshot) => {
      self.selectedHistory = history as IHistory;
    },
  }))
  .actions((self) => ({
    /**
     * INF_CAIMS_00180
     * API 이력 전체 목록을 조회한다.
     * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=1066434072
     * @param historyFilter
     */
    gets: async (historyFilter?: IHistoryFilter) => {
      try {
        const historyApi: HistoryApi = new HistoryApi(self.environment.api);
        const result: TGetHistorysResult = await historyApi.gets(self.pagination, historyFilter);

        if (
          self.rootStore.responseStore.getResponseResult(result) &&
          result.pagination &&
          result.pagination.page === self.pagination.page
        ) {
          if (result.historys) {
            result.historys.forEach((item: IHistorySnapshot) => {
              item.sucesYnKr = item.sucesYn ? '성공' : '실패';
              item.recvDt = timestampToDate(Number.parseInt(item.recvDt));
              if (item.regDt) {
                item.regDt = timestampToDate(Number.parseInt(item.regDt));
              }
            });
            self.setHistorys(result.historys);
            if (result.pagination) {
              self.setPagination(result.pagination);
            }
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },

    /**
     * INF_CAIMS_00181
     * 해당 API 이력 상세정보를 조회한다.
     * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=434293799
     * @param transacId
     */
    get: async (transacId: string) => {
      try {
        const historyApi: HistoryApi = new HistoryApi(self.environment.api);
        const result: TGetHistoryResult = await historyApi.get(transacId);

        if (self.rootStore.responseStore.getResponseResult(result)) {
          if (result.history) {
            if (result.history.transacId) {
              result.history.sucesYnKr = result.history.sucesYn ? '성공' : '실패';
              self.setSelectedHistory(result.history);
            }
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },
  }));

type THistoryStore = Instance<typeof HistoryStore>;
type THistoryStoreSnapshot = SnapshotOut<typeof HistoryStore>;

export interface IHistoryStore extends THistoryStore {}
export type THistoryStoreKeys = keyof THistoryStoreSnapshot & string;
export interface IHistoryStoreSnapshot extends THistoryStoreSnapshot {}
export const createHistoryStore = () => types.optional(HistoryStore, {} as THistoryStore);
