import { createRulesetMappgCond } from './../ruleset-mappg-cond/RulesetMappgCond';
import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { createModelCode } from './../model-code/ModelCode';
import { createSimpleUser } from './../simple-user/SimpleUser';
import { createRulesetMappgSvrtCond } from '../ruleset-mappg-svrt-cond/RulesetMappgSvrtCond';

/**
 * # RulesetMappg
 *
 * RulesetMappg을 설명하세요.
 */
export const RulesetMappg = types
  .model('RulesetMappg')
  // --------------------------------------------------------------------------
  .props({
    ruleSetSid: types.optional(types.number, 0),
    ruleSetMappgSid: types.optional(types.number, 0),
    eventType: createModelCode(),
    problCause: types.optional(types.maybeNull(types.string), null),
    spcfProbl: types.optional(types.maybeNull(types.string), null),
    svrt: createModelCode(),
    adtnInfo: types.optional(types.maybeNull(types.string), null),
    andOr: createModelCode(),
    mappgOrdr: types.optional(types.maybeNull(types.number), null),
    portExtrtKey: types.optional(types.maybeNull(types.string), null),
    hostExtrtKey: types.optional(types.maybeNull(types.string), null),
    useYn: types.optional(types.maybeNull(types.boolean), null),
    delYn: types.optional(types.maybeNull(types.boolean), null),
    regDt: types.optional(types.maybeNull(types.string), null),
    updDt: types.optional(types.maybeNull(types.string), null),
    ruleSetMappgCondList: types.optional(
      types.maybeNull(types.array(createRulesetMappgCond())),
      null,
    ),
    ruleSetMappgSvrtCondList: types.optional(
      types.maybeNull(types.array(createRulesetMappgSvrtCond())),
      null,
    ),

    regUser: types.maybeNull(createSimpleUser()),
    updUser: types.maybeNull(createSimpleUser()),
  })
  // --------------------------------------------------------------------------
  // MUTATEs ONLY! - 모델 상태를 변경
  // REQUESTs는 스토어 모델에 작성하세요.
  // ex) yarn gen model rulesetMappg --store
  .actions((self) => ({
    /*
     * 여러 prop을 한번에 수정
     */
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as IRulesetMappg;
      (Object.keys(newProps) as TRulesetMappgKeys[]).forEach((key) => {
        //@ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type TRulesetMappg = Instance<typeof RulesetMappg>;
type TRulesetMappgSnapshot = SnapshotOut<typeof RulesetMappg>;

export interface IRulesetMappg extends TRulesetMappg {}
export type TRulesetMappgKeys = keyof TRulesetMappgSnapshot & string;
export interface IRulesetMappgSnapshot extends TRulesetMappgSnapshot {}
export const createRulesetMappg = () => types.optional(RulesetMappg, {} as TRulesetMappg);

export interface IRulesetMappgFilter {
  ruleSetMappgSid?: number;
  ruleSetSid?: number;
  eventType?: number;
  problCause?: string;
  spcfProbl?: string;
  svrt?: number;
  andOr?: number;
  useYn?: boolean;
  regDtSt?: number | string;
  regDtEd?: number | string;
}
