import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { createModelCode } from '../model-code/ModelCode';

export const Trcod = types
  .model('Trcod')
  .props({
    equipSid: types.optional(types.number, 0),
    mainYn: types.optional(types.boolean, false),
    hostNm: types.optional(types.string, ''),
    mgmtIp: types.optional(types.string, ''),
    modelNm: types.optional(types.maybeNull(types.string), ''),
    frmwrVer: types.optional(types.maybeNull(types.string), ''),
    equipLo: types.optional(types.maybeNull(types.string), ''),
    equipMngr:  types.optional(types.maybeNull(types.string), ''),
    inPortSid: types.optional(types.maybeNull(types.number), 0),
    inPortNm: types.optional(types.maybeNull(types.string), ''),
    outPortSid: types.optional(types.maybeNull(types.number), 0),
    outPortNm: types.optional(types.maybeNull(types.string), ''),
    inBPortSid: types.optional(types.maybeNull(types.number), 0),
    inBPortNm: types.optional(types.maybeNull(types.string), ''),
    outBPortSid: types.optional(types.maybeNull(types.number), 0),
    outBPortNm: types.optional(types.maybeNull(types.string), ''),

    inPort: createModelCode(),
    outPort: createModelCode(),
    inBPort: createModelCode(),
    outBPort: createModelCode(),
  })
  .actions((self) => ({
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as ITrcod;
      (Object.keys(newProps) as TTrcodKeys[]).forEach((key) => {
        //@ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

type TTrcod = Instance<typeof Trcod>;
type TTrcodSnapshot = SnapshotOut<typeof Trcod>;

export interface ITrcod extends TTrcod {}
export type TTrcodKeys = keyof TTrcodSnapshot & string;
export interface ITrcodSnapshot extends TTrcodSnapshot {}
export const createTrcod = () =>
  types.optional(Trcod, {
    equipSid: 0,
    mainYn: false,
    hostNm: '',
    mgmtIp: '',
    modelNm: null,
    frmwrVer: null,
    inPortSid: null,
    inPortNm: null,
    outPortSid: null,
    outPortNm: null,
    inBPortSid: null,
    inBPortNm: null,
    outBPortSid: null,
    outBPortNm: null,
  } as TTrcod);
