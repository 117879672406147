import { Instance, SnapshotOut, types } from "mobx-state-tree"
import {createModelCode} from "../model-code/ModelCode";

/**
 * # SopEventType
 * 
 * SopEventType을 설명하세요.
 */
export const SopEventType = types
  .model("SopEventType")
  // --------------------------------------------------------------------------
  .props({
      id:types.maybeNull(types.string),
      sopEventTypeSid: types.maybeNull(types.number),
      intrlkSysSid: types.maybeNull(types.number),
      sysNm: types.string,
      eventType: types.maybeNull(createModelCode()),
      problCause: types.string,
      spcfProbl: types.string,
  })
  // --------------------------------------------------------------------------
  // MUTATEs ONLY! - 모델 상태를 변경
  // REQUESTs는 스토어 모델에 작성하세요.
  // ex) yarn gen model sopEventType --store
  .actions((self) => ({
    /*
     * 여러 prop을 한번에 수정
     */
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as ISopEventType;
      (Object.keys(newProps) as TSopEventTypeKeys[]).forEach((key) => {
          //@ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type TSopEventType = Instance<typeof SopEventType>;
type TSopEventTypeSnapshot = SnapshotOut<typeof SopEventType>

export interface ISopEventType extends TSopEventType {}
export type TSopEventTypeKeys = keyof TSopEventTypeSnapshot & string;
export interface ISopEventTypeSnapshot extends TSopEventTypeSnapshot {}
export const createSopEventType = () => 
  types.optional(SopEventType, {
      id: '',
      sopEventTypeSid: 0,
      intrlkSysSid: 0,
      sysNm: '',
      eventType: null,
      problCause: '',
      spcfProbl: '',
  } as TSopEventType);
