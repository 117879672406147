import { ApiResponse } from "apisauce";
import { IOneviewGaugeSnapshot, IResponseSnapshot } from "../../models";
import { Api } from "../api/api";
import { getGeneralApiProblem } from "../api/api-problem";
import { GetDashboardChCountResult } from "./OneviewDashboardTypes";



 export class OneviewDashboardApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  async getOneviewDashboardChCount(): Promise<GetDashboardChCountResult> {
    try{
        const url = '/channel/info'
        const params = {}
        const response : ApiResponse<any> = await this.api.apisauce.get(url , params)

        if (!response.ok) {
            const problem = getGeneralApiProblem(response);
            if (problem) return problem;
          }
    

        const responseInfo: IResponseSnapshot = response.data;
        return {
            kind: "ok",
            chCount: response.data.data,
            responseInfo: responseInfo
        }
    }catch (e){
        return { kind: "bad-data" };
    }
  }

}