import { createChangeValue } from './../change-value/ChangeValue';
import { Instance, SnapshotOut, types } from 'mobx-state-tree';

/**
 * # OutputSwHistoryContent
 *
 * OutputSwHistoryContent을 설명하세요.
 */
export const OutputSwHistoryContent = types
  .model('OutputSwHistoryContent')
  // --------------------------------------------------------------------------
  .props({
    operation: types.optional(types.maybeNull(types.string), null),
    name: types.optional(types.maybeNull(types.string), null),
    equipSid: types.maybeNull(createChangeValue()),
    mainYn: types.maybeNull(createChangeValue()),
    hostNm: types.maybeNull(createChangeValue()),
    inPortSid: types.maybeNull(createChangeValue()),
    inPortNm: types.maybeNull(createChangeValue()),
    // outPortSid: types.maybeNull(createChangeValue()),
    // outPortNm: types.maybeNull(createChangeValue()),
  })
  // --------------------------------------------------------------------------
  // MUTATEs ONLY! - 모델 상태를 변경
  // REQUESTs는 스토어 모델에 작성하세요.
  // ex) yarn gen model outputSwHistoryContent --store
  .actions((self) => ({
    /*
     * 여러 prop을 한번에 수정
     */
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as IOutputSwHistoryContent;
      (Object.keys(newProps) as TOutputSwHistoryContentKeys[]).forEach((key) => {
        //@ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type TOutputSwHistoryContent = Instance<typeof OutputSwHistoryContent>;
type TOutputSwHistoryContentSnapshot = SnapshotOut<typeof OutputSwHistoryContent>;

export interface IOutputSwHistoryContent extends TOutputSwHistoryContent {}
export type TOutputSwHistoryContentKeys = keyof TOutputSwHistoryContentSnapshot & string;
export interface IOutputSwHistoryContentSnapshot extends TOutputSwHistoryContentSnapshot {}
export const createOutputSwHistoryContent = () =>
  types.optional(OutputSwHistoryContent, {} as TOutputSwHistoryContent);
