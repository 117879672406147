import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { type } from 'os';
import { Aco } from '../aco/Aco';
import { createAdvm } from '../advm/Advm';
import { Encod } from '../encod/Encod';
import { HdMux } from '../hd-mux/HdMux';
import { InputSw } from '../input-sw/InputSw';
import { createModelCode, ModelCode } from '../model-code/ModelCode';
import { OutputSw } from '../output-sw/OutputSw';
import { createPpCompany } from '../pp-company/PpCompany';
import { SdMux } from '../sd-mux/SdMux';
import { SdSw } from '../sd-sw/SdSw';
import { Trcod } from '../trcod/Trcod';
import { createSimpleUser } from './../simple-user/SimpleUser';

export const SimpleChannel = types
  .model('SimpleChannel')
  .props({
    // sdSwList: types.optional(types.maybeNull(types.array(SdSw)), []),
    // trcodList: types.optional(types.maybeNull(types.array(Trcod)), []),
    // encodList: types.optional(types.maybeNull(types.array(Encod)), []),
    // inputSwList: types.optional(types.maybeNull(types.array(InputSw)), []),
    // acoList: types.optional(types.maybeNull(types.array(Aco)), []),
    // hdMuxList: types.optional(types.maybeNull(types.array(HdMux)), []),
    // sdMuxList: types.optional(types.maybeNull(types.array(SdMux)), []),
    // outputSwList: types.optional(types.maybeNull(types.array(OutputSw)), []),

    chSvcId: types.optional(types.number, 0),
    chNo: types.optional(types.maybeNull(types.number), 0),
    chNm: types.optional(types.maybeNull(types.string), ''),
    dbsChNm: types.optional(types.maybeNull(types.string), ''),
    // chType: types.maybeNull(createModelCode()),
    // trtResol: createModelCode(),
    // duplSglType: createModelCode(),
    // adultYn: types.optional(types.boolean, false),
    // seeznIntrlkYn: types.optional(types.boolean, false),
    // hcnIntrlkYn: types.optional(types.boolean, false),
    // skylifeIntrlkYn: types.optional(types.boolean, false),
    // sadInfo: types.maybeNull(createModelCode()),
    // araIntrlkYn: types.optional(types.boolean, false),
    // advm: types.maybeNull(createAdvm()),
    // advmMemo: types.optional(types.maybeNull(types.string), ''),
    hdMip: types.optional(types.string, ''),
    // sdMip: types.optional(types.maybeNull(types.string), ''),
    // psiIn: createModelCode(),
    // pat: types.optional(types.maybeNull(types.number), 0),
    // pmt: types.optional(types.maybeNull(types.number), 0),
    // videoPid: types.optional(types.maybeNull(types.number), 0),
    // audio1Pid: types.optional(types.maybeNull(types.number), 0),
    // audio2Pid: types.optional(types.maybeNull(types.number), 0),
    // acapAit: types.optional(types.maybeNull(types.number), 0),
    // webAit: types.optional(types.maybeNull(types.number), 0),
    // acapOc: types.optional(types.maybeNull(types.number), 0),
    // webOc: types.optional(types.maybeNull(types.number), 0),
    // videoStchYn: types.optional(types.boolean, false),
    // dpsonSubtitYn: types.optional(types.boolean, false),
    // vcMultiYn: types.optional(types.boolean, false),
    dplxYn: types.optional(types.maybeNull(types.boolean), null),
    // casEcodYn: types.optional(types.boolean, false),
    // ecmPid: types.optional(types.maybeNull(types.number), 0),
    // pp: types.maybeNull(createPpCompany()),
    // encodCcYn: types.optional(types.boolean, false),
    // encodHdMip: types.optional(types.maybeNull(types.string), ''),
    // encodSdMip: types.optional(types.maybeNull(types.string), ''),
    // encodQtoneAPid: types.optional(types.maybeNull(types.number), 0),
    // encodQtoneBPid: types.optional(types.maybeNull(types.number), 0),
    // encodQtoneDPid: types.optional(types.maybeNull(types.number), 0),
    // encodPmtPid: types.optional(types.maybeNull(types.number), 0),
    // encodVideoPid: types.optional(types.maybeNull(types.number), 0),
    // encodAudioPid: types.optional(types.maybeNull(types.number), 0),
    // encodAudio2Pid: types.optional(types.maybeNull(types.number), 0),
    // helthySttus: types.maybeNull(createModelCode()),
    // rglsSttus: types.maybeNull(createModelCode()),
    // rglsComotDate: types.optional(types.maybeNull(types.string), ''),
    // regDt: types.optional(types.maybeNull(types.string), ''),
    // regUser: types.maybeNull(createSimpleUser()),
    // updDt: types.optional(types.maybeNull(types.string), ''),
    // updUser: types.maybeNull(createSimpleUser()),

    // ktTrmNetVendr: types.maybeNull(createModelCode()),
    // ktCircuitNoMain: types.optional(types.maybeNull(types.string), ''),
    // ktCircuitNoBackup: types.optional(types.maybeNull(types.string), ''),
    // ktTrsmDept: types.optional(types.maybeNull(types.string), ''),
    // ppNm: types.optional(types.maybeNull(types.string),''),
    // ppSid: types.optional(types.maybeNull(types.number),0),
    // msppNm: types.optional(types.maybeNull(types.string),''),

    // wrkMemo: types.optional(types.maybeNull(types.string),null),
    /*
     * 화면에서만 사용
     */

    // acoAraList: types.optional(types.maybeNull(types.array(Aco)), []),
  })
  .actions((self) => ({
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as ISimpleChannel;
      (Object.keys(newProps) as TSimpleChannelKeys[]).forEach((key) => {
        //@ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

type TSimpleChannelType = Instance<typeof SimpleChannel>;
type TSimpleChannelSnapshot = SnapshotOut<typeof SimpleChannel>;

export interface ISimpleChannel extends TSimpleChannelType {}
export type TSimpleChannelKeys = keyof TSimpleChannelSnapshot & string;
export interface ISimpleChannelSnapshot extends TSimpleChannelSnapshot {}
export const createSimpleChannel = () =>
  types.optional(SimpleChannel, {
    // sdSwList: null,
    // trcodList: null,
    // encodList: null,
    // inputSwList: null,
    // acoList: null,
    // hdMuxList: null,
    // sdMuxList: null,
    // outputSwList: null,

    // pp: null,
    chSvcId: 0,
    chNo: 0,
    chNm: '',
    dbsChNm: '',
    // advmMemo: '',
    hdMip: '',
    // sdMip: null,
    // pat: 0,
    // pmt: 0,
    // videoPid: 0,
    // audio1Pid: 0,
    // audio2Pid: 0,
    // acapAit: 0,
    // webAit: 0,
    // acapOc: 0,
    // webOc: 0,
    // adultYn: false,
    // videoStchYn: false,
    // dpsonSubtitYn: false,
    // vcMultiYn: false,
    dplxYn: null,
    // casEcodYn: false,
    // ecmPid: 0,
    // rglsComotDate: '',

    // wrkMemo: null,
  });