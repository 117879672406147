import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { IConnection } from '../../models/connection/Connection';
import {
  TDeleteConnectionResult,
  TGeneralApiResponse,
  TGetConnectionResult,
  TGetConnectionsResult,
  TGetTestResult,
  TPostConnectionResult,
  TUpdateConnectionResult,
} from '../../services';
import { createConnection } from '../connection/Connection';
import { withEnvironment } from '../extensions/with-environment';
import { ConnectionApi } from './../../services/connection/Connection';
import { IConnectionFilter, IConnectionSnapshot } from './../connection/Connection';
import { withRootStore } from './../extensions/with-root-store';
import { createPagination, IPagination, IPaginationSnapshot } from './../pagination/Pagination';

/**
 * # ConnectionStore
 *
 * ConnectionStore을 설명하세요.
 */
export const ConnectionStore = types
  .model('ConnectionStore')
  // --------------------------------------------------------------------------
  .props({
    connections: types.optional(types.array(createConnection()), []),
    selectedConnection: types.maybeNull(createConnection()),
    pagination: createPagination(),
    deleteConnSids: types.optional(types.array(types.number), []),
    needMlsc: types.optional(types.maybeNull(types.number), null),
    returnData: types.optional(types.maybeNull(types.string), null),
  })
  .extend(withEnvironment)
  .extend(withRootStore)
  // eslint-disable-line @typescript-eslint/no-unused-vars
  .views((self) => ({}))
  // --------------------------------------------------------------------------
  // MUTATEs - 모델 상태를 변경
  .actions((self) => ({
    /**
     * connections을 교체
     *
     * @param `connections` 새로운 모델의 배열
     */
    setConnections: (connections: IConnectionSnapshot[]) => {
      self.connections.replace(connections as IConnection[]);
    },
    setSelectedConnection: (connection: IConnectionSnapshot) => {
      self.selectedConnection = connection as IConnection;
    },
    appendDeleteSids: (connSid: number) => {
      self.deleteConnSids.push(connSid);
    },
    replaceDeleteSids: (connSids: number[]) => {
      self.deleteConnSids.replace(connSids);
    },
    setPagination: (pagination: IPaginationSnapshot) => {
      self.pagination = pagination as IPagination;
    },
    setNeedMlsc: (needMlsc: number | null) => {
      self.needMlsc = needMlsc;
    },
    setReturnData: (returnData: string | null) => {
      self.returnData = returnData;
    },
  }))
  // --------------------------------------------------------------------------
  // REQUESTs - 서비스 요청 및 기타 인터페이스 요청
  .actions((self) => ({
    /**
     *INF_CAIMS_00136
     * 접속정보 전체 목록을 조회한다.
     * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=1257320080
     */
    gets: async (connectionFilter?: IConnectionFilter) => {
      self.setConnections([]);
      try {
        const connectionApi: ConnectionApi = new ConnectionApi(self.environment.api);
        let result: TGetConnectionsResult;
        if (connectionFilter) {
          result = await connectionApi.gets(self.pagination, connectionFilter);
        } else {
          result = await connectionApi.gets(self.pagination);
        }

        if (
          self.rootStore.responseStore.getResponseResult(result) &&
          result.pagination &&
          result.pagination.page === self.pagination.page
        ) {
          if (result.connections) {
            self.setConnections(result.connections);
            if (result.pagination) {
              self.setPagination(result.pagination);
            }
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },

    /**
     * INF_CAIMS_00137
     * 해당 접속정보의 상세 정보를 조회한다.
     * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=547545887
     */
    get: async (connSid: number) => {
      self.selectedConnection = null;
      try {
        const connectionApi: ConnectionApi = new ConnectionApi(self.environment.api);
        const result: TGetConnectionResult = await connectionApi.get(connSid);

        if (self.rootStore.responseStore.getResponseResult(result)) {
          if (result.connection) {
            self.setSelectedConnection(result.connection);
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },

    /**
     * INF_CAIMS_00138
     * 새로운 접속정보을 등록한다.
     * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=1395265010
     */
    post: async (connection: IConnectionSnapshot) => {
      self.selectedConnection = null;
      try {
        const connectionApi: ConnectionApi = new ConnectionApi(self.environment.api);
        const result: TPostConnectionResult = await connectionApi.post(connection);

        if (self.rootStore.responseStore.getResponseResult(result)) {
          if (result.connection) {
            self.setSelectedConnection(result.connection);
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },
    /**
     *INF_CAIMS_00139
     *해당 접속정보 정보를 수정한다.
     *https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=2066720546
     */
    put: async (connection: IConnectionSnapshot) => {
      self.selectedConnection = null;
      try {
        const connectionApi: ConnectionApi = new ConnectionApi(self.environment.api);
        const result: TUpdateConnectionResult = await connectionApi.put(connection);

        if (self.rootStore.responseStore.getResponseResult(result)) {
          if (result.connection) {
            self.setSelectedConnection(result.connection);
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },

    /**
     * INF_CAIMS_00140
     * 해당 접속정보을 삭제한다.
     * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=1183123755
     */
    delete: async (connSid: number) => {
      try {
        const connectionApi: ConnectionApi = new ConnectionApi(self.environment.api);
        const result: TDeleteConnectionResult = await connectionApi.delete(connSid);
        self.rootStore.responseStore.getResponseResult(result);
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },

    /**
     * INF_CAIMS_00141
     * 여러개 접속정보을 한번에 삭제한다.
     * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=1442592928
     */
    deletes: async () => {
      try {
        const connectionApi: ConnectionApi = new ConnectionApi(self.environment.api);
        const result: TDeleteConnectionResult = await connectionApi.deletes(self.deleteConnSids);
        self.rootStore.responseStore.getResponseResult(result);
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },

    /**
     * INF_CAIMS_00142
     * 해당 접속정보을 테스트한다.
     * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=1266383929
     * @param connSid
     */
    test: async (connSid: number) => {
      try {
        const connectionApi: ConnectionApi = new ConnectionApi(self.environment.api);
        const result: TGetTestResult = await connectionApi.test(connSid);
        self.setNeedMlsc(null);
        self.setReturnData(null);
        if (self.rootStore.responseStore.getResponseResultWithOutSnackBar(result)) {
          if (result.needMlsc) {
            self.setNeedMlsc(result.needMlsc);
          }
          if (result.returnData) {
            self.setReturnData(result.returnData);
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },

    /**
     * INF_CAIMS_00248
     * 데이터 생성 전에 접속정보 테스트를 한다.
     * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=640030227
     * @param connection
     */
    testBeforeCreate: async (connection: IConnectionSnapshot) => {
      try {
        const connectionApi: ConnectionApi = new ConnectionApi(self.environment.api);
        const result: TGetTestResult = await connectionApi.testBeforeCreate(connection);
        self.setNeedMlsc(null);
        self.setReturnData(null);
        if (self.rootStore.responseStore.getResponseResultWithOutSnackBar(result)) {
          if (result.needMlsc) {
            self.setNeedMlsc(result.needMlsc);
          }
          if (result.returnData) {
            self.setReturnData(result.returnData);
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },
  }));

// --------------------------------------------------------------------------
type TConnectionStore = Instance<typeof ConnectionStore>;
type TConnectionStoreSnapshot = SnapshotOut<typeof ConnectionStore>;

export interface IConnectionStore extends TConnectionStore {}
export type TConnectionStoreKeys = keyof TConnectionStoreSnapshot & string;
export interface IConnectionStoreSnapshot extends TConnectionStoreSnapshot {}
export const createConnectionStore = () => types.optional(ConnectionStore, {} as TConnectionStore);
