import { Instance, SnapshotOut, types } from 'mobx-state-tree';

/**
 * # LoginPrvntStts
 *
 * LoginPrvntStts을 설명하세요.
 */
export const LoginPrvntStts = types
  .model('LoginPrvntStts')
  .props({
    code: types.optional(types.number, 0),
    value: types.optional(types.string, ''),
  })
  .actions((self) => ({
    resetAcntStts: () => {
      self.code = 0;
      self.value = '';
    },
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as ILoginPrvntStts;
      (Object.keys(newProps) as TLoginPrvntSttsKeys[]).forEach((key) => {
        //@ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type TLoginPrvntStts = Instance<typeof LoginPrvntStts>;
type TLoginPrvntSttsSnapshot = SnapshotOut<typeof LoginPrvntStts>;

export interface ILoginPrvntStts extends TLoginPrvntStts {}
export type TLoginPrvntSttsKeys = keyof TLoginPrvntSttsSnapshot & string;
export interface ILoginPrvntSttsSnapshot extends TLoginPrvntSttsSnapshot {}
export const createLoginPrvntStts = () =>
  types.optional(LoginPrvntStts, {
    code: 0,
    value: '',
  } as TLoginPrvntStts);
