import { observer } from 'mobx-react-lite';
import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import { useStores } from '../models';
import SoketSnackBarContent from './SoketSnackBarContent';
// ----------------------------------------------------------------------

export default observer(function SoketSnackBar() {
  const { commonStore } = useStores();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    (commonStore.soketSnackBar.id && commonStore.soketSnackBar.message) &&
      enqueueSnackbar(commonStore.soketSnackBar.message, {
        content: (key, message) => {
          return (
            <SoketSnackBarContent
              id={key}
              message={message}
              type={commonStore.soketSnackBar.type}
            />
          );
        },
      });
  }, [commonStore.soketSnackBar.id]);

  return <></>;
});
