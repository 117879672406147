// routes
// components
import { MENU_PAGE_TITLE } from '../../components/settings/SettingConstEnum';
import SvgIconStyle from '../../components/SvgIconStyle';
import { PATH_CHANNEL_AI } from '../../routes/paths';
import TrafficIcon from '@mui/icons-material/Traffic';
import AccessAlarmsIcon from '@mui/icons-material/AccessAlarms';
import GroupIcon from '@mui/icons-material/Group';
import ConstructionIcon from '@mui/icons-material/Construction';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import PermDataSettingIcon from '@mui/icons-material/PermDataSetting';
import SettingsIcon from '@mui/icons-material/Settings';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import MonitorIcon from '@mui/icons-material/Monitor';
// ----------------------------------------------------------------------

const getIcon = (name: string) => (
  <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
);
export const ICONS = {
  auth: getIcon('ic_user'),
  dashboard: getIcon('ic_dashboard'),
  fault: <TrafficIcon />,
  statistic: getIcon('ic_analytics'),
  alarmManagement: <AccessAlarmsIcon />,
  preRuleMgmt: <PermDataSettingIcon />,
  rpaManagement: <HomeRepairServiceIcon />,
  rpaRunSetting: <SettingsIcon />,
  sopManagement: <AccountTreeIcon />,
  configurationMgmt: <ConstructionIcon />,
  user: <GroupIcon />,
  supplementaryService: <QueryStatsIcon />,
  realtimeMonitoring: <MonitorIcon />, 
};
export interface IIconObject {
  title: string;
  icon: JSX.Element;
}

export const iconArray: IIconObject[] = [
  { title: MENU_PAGE_TITLE.dashboard.title, icon: ICONS.dashboard },
  { title: MENU_PAGE_TITLE.staticsReporting.title, icon: ICONS.fault },
  { title: MENU_PAGE_TITLE.alarmManagement.title, icon: ICONS.alarmManagement },
  { title: MENU_PAGE_TITLE.sopKdbManagement.title, icon: ICONS.sopManagement },
  { title: MENU_PAGE_TITLE.configurationManagement.title, icon: ICONS.configurationMgmt },
  { title: MENU_PAGE_TITLE.systemManagement.title, icon: ICONS.user },
  { title: MENU_PAGE_TITLE.supplementaryService.title, icon: ICONS.supplementaryService },
  { title: MENU_PAGE_TITLE.realtimeMonitoring.title, icon: ICONS.realtimeMonitoring },
];

const sidebarConfig = () => [
  // CHANNEL_AI
  // ----------------------------------------------------------------------

  //대시보드
  {
    items: [
      {
        title: MENU_PAGE_TITLE.dashboard.title,
        icon: ICONS.dashboard,
        children: [
          {
            title: MENU_PAGE_TITLE.dashboard.dashboard,
            path: PATH_CHANNEL_AI.dashboard.dashboard,
          },
          {
            title: MENU_PAGE_TITLE.dashboard.baekseokChannel,
            path: PATH_CHANNEL_AI.dashboard.baekseokChannel,
          },
          {
            title: MENU_PAGE_TITLE.dashboard.systemStatusDashboard,
            path: PATH_CHANNEL_AI.dashboard.systemStatus,
          },
          // {
          //   title: MENU_PAGE_TITLE.dashboard.yeouidoVod,
          //   path: PATH_CHANNEL_AI.dashboard.yeouidoVod,
          // },
        ],
      },
    ],
  },

  //통계/리포팅
  {
    items: [
      {
        title: MENU_PAGE_TITLE.staticsReporting.title,
        icon: ICONS.fault,
        children: [
          {
            title: MENU_PAGE_TITLE.staticsReporting.realTimeAlarm,
            path: PATH_CHANNEL_AI.staticsReporting.realTimeAlarm,
          },
          {
            title: MENU_PAGE_TITLE.staticsReporting.alarmHistory,
            path: PATH_CHANNEL_AI.staticsReporting.alarmHistory,
          },
          {
            title: MENU_PAGE_TITLE.staticsReporting.alarmAnalyze,
            path: PATH_CHANNEL_AI.staticsReporting.alarmAnalyze,
          },
          {
            title: MENU_PAGE_TITLE.staticsReporting.vocHistory,
            path: PATH_CHANNEL_AI.staticsReporting.vocHistory,
          },
          {
            title: MENU_PAGE_TITLE.staticsReporting.vocStatistics,
            path: PATH_CHANNEL_AI.staticsReporting.vocStatistics,
          },
          {
            title: MENU_PAGE_TITLE.staticsReporting.ratingStatistics,
            path: PATH_CHANNEL_AI.staticsReporting.ratingStatistics,
          },
          {
            title: MENU_PAGE_TITLE.staticsReporting.stbStatistics,
            path: PATH_CHANNEL_AI.staticsReporting.stbStatistics,
          },
          // {
          //   title: MENU_PAGE_TITLE.staticsReporting.reportingStatistics,
          //   path: PATH_CHANNEL_AI.staticsReporting.reportingStatistics,
          // },
        ],
      },
    ],
  },

  //부가서비스 분석
  {
    items: [
      {
        title: MENU_PAGE_TITLE.supplementaryService.title,
        icon: ICONS.supplementaryService,
        children: [
          {
            title: MENU_PAGE_TITLE.supplementaryService.aQuetone,
            path: PATH_CHANNEL_AI.supplementaryService.aQuetone,
          },
          {
            title: MENU_PAGE_TITLE.supplementaryService.bQuetone,
            path: PATH_CHANNEL_AI.supplementaryService.bQuetone,
          },
          {
            title: MENU_PAGE_TITLE.supplementaryService.quetoneDpcc,
            path: PATH_CHANNEL_AI.supplementaryService.quetoneDpcc,
          },
          {
            title: MENU_PAGE_TITLE.supplementaryService.misTransMission,
            path: PATH_CHANNEL_AI.supplementaryService.misTransMission,
          },
        ],
      },
    ],
  },

  //알람 설정
  {
    items: [
      {
        title: MENU_PAGE_TITLE.alarmManagement.title,
        icon: ICONS.alarmManagement,
        children: [
          {
            title: MENU_PAGE_TITLE.alarmManagement.ruleset,
            path: PATH_CHANNEL_AI.alarmManagement.ruleset,
          },
          {
            title: MENU_PAGE_TITLE.alarmManagement.snmpRuleset,
            path: PATH_CHANNEL_AI.alarmManagement.snmpRuleset,
          },
          {
            title: MENU_PAGE_TITLE.alarmManagement.stbSimulator,
            path: PATH_CHANNEL_AI.alarmManagement.stbSimulator,
          },
        ],
      },
    ],
  },

  //SOP 관리
  {
    items: [
      {
        title: MENU_PAGE_TITLE.sopKdbManagement.title,
        icon: ICONS.sopManagement,
        children: [
          {
            title: MENU_PAGE_TITLE.sopKdbManagement.sopRegisterMgmt,
            path: PATH_CHANNEL_AI.sopKdbManagement.sopRegisterMgmt,
          },
          {
            title: MENU_PAGE_TITLE.sopKdbManagement.kdbMgmt,
            path: PATH_CHANNEL_AI.sopKdbManagement.kdbMgmt,
          },
        ],
      },
    ],
  },

  //형상 관리
  {
    items: [
      {
        title: MENU_PAGE_TITLE.configurationManagement.title,
        icon: ICONS.configurationMgmt,
        children: [
          {
            title: MENU_PAGE_TITLE.configurationManagement.channelMgmt,
            path: PATH_CHANNEL_AI.configurationManagement.channelMgmt,
          },
          {
            title: MENU_PAGE_TITLE.configurationManagement.intrlkSysMgmt,
            path: PATH_CHANNEL_AI.configurationManagement.intrlkSysMgmt,
          },
          {
            title: MENU_PAGE_TITLE.configurationManagement.equipmentMgmt,
            path: PATH_CHANNEL_AI.configurationManagement.equipmentMgmt,
          },
          // {
          //   title: MENU_PAGE_TITLE.configurationManagement.rackMgmt,
          //   path: PATH_CHANNEL_AI.configurationManagement.rackMgmt,
          // },
          {
            title: MENU_PAGE_TITLE.configurationManagement.topologyMgmt,
            path: PATH_CHANNEL_AI.configurationManagement.topologyMgmt,
          },
          {
            title: MENU_PAGE_TITLE.configurationManagement.monitoringStbMgmt,
            path: PATH_CHANNEL_AI.configurationManagement.monitoringStbMgmt,
          },
          {
            title: MENU_PAGE_TITLE.configurationManagement.ppCompanyMgmt,
            path: PATH_CHANNEL_AI.configurationManagement.ppCompanyMgmt,
          },
        ],
      },
    ],
  },

  //시스템 관리
  {
    items: [
      {
        title: MENU_PAGE_TITLE.systemManagement.title,
        icon: ICONS.user,
        children: [
          {
            title: MENU_PAGE_TITLE.systemManagement.userMgmt,
            path: PATH_CHANNEL_AI.systemManagement.userMgmt,
          },
          {
            title: MENU_PAGE_TITLE.systemManagement.userLoginHistory,
            path: PATH_CHANNEL_AI.systemManagement.userLoginHistory,
          },
          {
            title: MENU_PAGE_TITLE.systemManagement.groupMgmt,
            path: PATH_CHANNEL_AI.systemManagement.groupMgmt,
          },
          {
            title: MENU_PAGE_TITLE.systemManagement.groupMenuMgmt,
            path: PATH_CHANNEL_AI.systemManagement.groupMenuMgmt,
          },
          {
            title: MENU_PAGE_TITLE.systemManagement.pairAlarmSetting,
            path: PATH_CHANNEL_AI.systemManagement.pairAlarmSetting,
          },
          {
            title: MENU_PAGE_TITLE.systemManagement.connectionMgmt,
            path: PATH_CHANNEL_AI.systemManagement.connectionMgmt,
          },

          {
            title: MENU_PAGE_TITLE.systemManagement.channelCodeMgmt,
            path: PATH_CHANNEL_AI.systemManagement.channelCodeMgmt,
          },
          {
            title: MENU_PAGE_TITLE.systemManagement.apiHistory,
            path: PATH_CHANNEL_AI.systemManagement.apiHistory,
          },
          {
            title: MENU_PAGE_TITLE.systemManagement.smsTransmission,
            path: PATH_CHANNEL_AI.systemManagement.smsManagement,
          },
          {
            title: MENU_PAGE_TITLE.systemManagement.emailTransmission,
            path: PATH_CHANNEL_AI.systemManagement.emailManagement,
          },
        ],
      },
    ],
  },

  {
    items: [
      {
        title: MENU_PAGE_TITLE.realtimeMonitoring.title,
        icon: ICONS.realtimeMonitoring,
        children: [
          {
            title: MENU_PAGE_TITLE.realtimeMonitoring.channelAlarm,
            path: PATH_CHANNEL_AI.realtimeMonitoring.channelAlarm,
          },
          {
            title: MENU_PAGE_TITLE.realtimeMonitoring.quetoneAlarm,
            path: PATH_CHANNEL_AI.realtimeMonitoring.quetoneAlarm,
          },
          {
            title: MENU_PAGE_TITLE.realtimeMonitoring.vocAlarm,
            path: PATH_CHANNEL_AI.realtimeMonitoring.vocAlarm,
          },
          {
            title: MENU_PAGE_TITLE.realtimeMonitoring.multiChannelAlarm,
            path: PATH_CHANNEL_AI.realtimeMonitoring.multiChannelAlarm,
          },
          {
            title: MENU_PAGE_TITLE.realtimeMonitoring.disabledPersonAlarm,
            path: PATH_CHANNEL_AI.realtimeMonitoring.disabledPersonAlarm,
          },
        ],
      },
    ],
  },
];

export default sidebarConfig;
