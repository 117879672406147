import { ApiResponse } from "apisauce";
import { Api } from "../api/api";
import { getGeneralApiProblem } from "../api/api-problem";
import {  INotificationFilter, INotificationSnapshot, IPaginationSnapshot, IResponseSnapshot } from '../../models';
import { 
  TGetNotificationsResult,
  TGetNotificationResult,
  TPostNotificationResult,
  TUpdateNotificationResult,
  TDeleteNotificationResult
 } from "./NotificationTypes";
import { dateToTimestamp, timestampToDate } from "../../utils/dateExchanger";

  
const makeParams = (pagination?: IPaginationSnapshot, notificationFilter?: INotificationFilter): any =>
{
    let showStartDt = null;
    let showEndDt = null;
    if(notificationFilter?.showStartDt)
    {
      showStartDt = dateToTimestamp(notificationFilter.showStartDt);
    }
    if(notificationFilter?.showEndDt)
    {
      showEndDt = dateToTimestamp(notificationFilter.showEndDt);
    }
    return {
      size: pagination?.size,
      page: pagination?.page,
      showStartDt: showStartDt,
      showEndDt: showEndDt,
      isNotice: notificationFilter?.isNotice,
      ntfTitle: notificationFilter?.ntfTitle,
    }
}

const makeResponseData = (data: INotificationSnapshot): INotificationSnapshot =>
{
  if(data?.regDt)
  {
    data.regDt = timestampToDate(Number.parseInt(data.regDt));
  }
  if(data?.updDt)
  {
    data.updDt = timestampToDate(Number.parseInt(data.updDt));
  }
  if(data?.showStartDt)
  {
    data.showStartDt = timestampToDate(Number.parseInt(data.showStartDt));
  }
  if(data?.showEndDt)
  {
    data.showEndDt = timestampToDate(Number.parseInt(data.showEndDt));
  }
  return data;
}

const makeResponseDataList = (response: ApiResponse<any> ): INotificationSnapshot[] =>
{
  let notifications: INotificationSnapshot[] = response.data.data;
  notifications.forEach((item: INotificationSnapshot) => {
    makeResponseData(item);
  });
  return notifications;
}


const makeSendData = (notification: INotificationSnapshot): any => {
  let sendData: any = notification;
  sendData.delYn = false;
  if(notification.showStartDt)
  {
    sendData.showStartDt = dateToTimestamp(notification.showStartDt);
  }
  if(notification.showEndDt)
  {
    sendData.showEndDt = dateToTimestamp(notification.showEndDt);
  }
  return sendData;
}

export class NotificationApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  /**
   * 노출 목록 조회
   * @returns 
   */
  async getShows(): Promise<TGetNotificationsResult> {
    try {
      const url = '/notice/show';
      const response: ApiResponse<any> = await this.api.apisauce.get( url );
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      let result: TGetNotificationsResult;
      const responseInfo: IResponseSnapshot = response.data;
      if (responseInfo.resultCode === 'S' && response.data.data) {
        result = {
          kind: `ok`,
          responseInfo: responseInfo,
          notifications: makeResponseDataList(response),
          pagination: response.data.pagination,
        };
      } else {
        result = { kind: 'ok', responseInfo: responseInfo };
      }
      return result;
    } catch (e) {
      return { kind: "bad-data" };
    }
  }

  /**
   * 목록 조회
   * @param pagination 
   * @param notificationFilter 
   * @returns 
   */
  async getList(
    pagination: IPaginationSnapshot,
    notificationFilter?: INotificationFilter,
  ): Promise<TGetNotificationsResult> {
    try {
      const url = '/notice';
      const params: any = makeParams(pagination, notificationFilter);
      const response: ApiResponse<any> = await this.api.apisauce.get( url, params );
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      let result: TGetNotificationsResult;
      const responseInfo: IResponseSnapshot = response.data;
      if (responseInfo.resultCode === 'S' && response.data.data) {
        result = {
          kind: `ok`,
          responseInfo: responseInfo,
          notifications: makeResponseDataList(response),
          pagination: response.data.pagination,
        };
      } else {
        result = { kind: 'ok', responseInfo: responseInfo };
      }

      return result;
    } catch (e) {
      return { kind: "bad-data" };
    }
  }

  /**
   * 공지사항 상세조회
   * @param ntfSid 
   * @returns 
   */
  async get (ntfSid: number): Promise<TGetNotificationResult> 
  {
    try {
      const url = `/notice/${ntfSid}`;
      const response: ApiResponse<any> = await this.api.apisauce.get( url);
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      let result: TGetNotificationResult;
      const responseInfo: IResponseSnapshot = response.data;
      if (responseInfo.resultCode === 'S' && response.data.data)  {
        result = {
          kind: `ok`,
          responseInfo: responseInfo,
          notification: makeResponseData(response.data.data),
        };
      } else {
        result = {kind: 'ok', responseInfo: responseInfo};
      }

      return result;
    } catch (e) {
      return { kind: "bad-data" };
    }
  }

  /**
   * 공지사항 등록
   * @param notification 
   * @returns 
   */
  async post(notification: INotificationSnapshot): Promise<TPostNotificationResult> {
    try {
      const url = '/notice';
      const response: ApiResponse<any> = await this.api.apisauce.post( url, makeSendData(notification) );
  
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      return { 
        kind: "ok", 
        notification: makeResponseData(response.data.data),
        responseInfo: response.data
      };

    } catch (e) {
      return { kind: "bad-data" };
    }
  }

  /**
   * 공지사항 수정
   * @param ntfSid
   * @param notification 
   * @returns 
   */
  async put(ntfSid: number, notification: INotificationSnapshot): Promise<TUpdateNotificationResult> {
    try {
      const url = `/notice/${ntfSid}`;
      const response: ApiResponse<any> = await this.api.apisauce.put( url, makeSendData(notification) );
  
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      return { 
        kind: "ok", 
        notification: makeResponseData(response.data.data),
        responseInfo: response.data
      };
    } catch (e) {
      return { kind: "bad-data" };
    }
  }

  /**
   * 공지사항 삭제
   * @param ntfSid 
   * @returns 
   */
  async delete(ntfSid: number): Promise<TDeleteNotificationResult> {
    try {
      const url = `/notice/${ntfSid}`;
      const response: ApiResponse<any> = await this.api.apisauce.delete( url );
  
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      return { kind: "ok", responseInfo: response.data };
    } catch (e) {
      return { kind: "bad-data" };
    }
  }

  /**
   * 공지사항 목록 삭제
   * @param ntfSid 
   * @returns 
   */
  async deleteList(ntfSidList: number[]): Promise<TDeleteNotificationResult> {
    try {
      let queryParam: string = 'ntfSidList=';
      ntfSidList.forEach((item: number, index: number) => {
        queryParam += (index != 0 ? ',' : '') + item;
      });
      const url = `/notice?${queryParam}`;
      const response: ApiResponse<any> = await this.api.apisauce.delete( url );
  
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      return { kind: "ok", responseInfo: response.data };
    } catch (e) {
      return { kind: "bad-data" };
    }
  }
  
}