import { ApiResponse } from 'apisauce';
import { selectOptions } from '../../components/forms/CSelect';
import {
  IResponseSnapshot,
  IStbStatisticsSnapshot,
  IVocStatisticsSnapshot,
  TVocStatisticsFilter,
} from '../../models';
import { dateToTimestamp, timestampToDate } from '../../utils/dateExchanger';
import { Api } from '../api/api';
import { getGeneralApiProblem } from '../api/api-problem';
import { TGetVocBubbleChart, TGetVocStatisticsResult, TGetVocStatisticsResultForOneview } from './VocStatisticsTypes';

/**
 * # VocStatistics Api 서비스
 *
 * 서비스의 설명을 작성하세요.
 *
 * ## 사용방법
 *
 * ```ts
 * const service = new VocStatisticsApi();
 *
 * ```
 */
export class VocStatisticsApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  /**
   * INF_CAIMS_00303,304,305
   * VOC 지역별, 시간별, 일별 통계 데이터 조회
   * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=1207726427
   * @param vocStatisticsFilter
   * @returns
   */
  async gets(vocStatisticsFilter?: TVocStatisticsFilter): Promise<TGetVocStatisticsResult> {
    try {
      let regionList: string = ``;
      let params: any = {};
      let statType: number=0;
      let statUnit: number = 0;
      const filter = { ...vocStatisticsFilter };
      if (filter.statType) {
        statType = filter.statType;

        //@ts-ignore
        delete filter.statType;

        params = filter;
      }

      if (filter.statUnit) {
        statUnit = filter.statUnit;

        //@ts-ignore
        delete filter.statUnit;

        params = filter;

        // if (filter.regionList) {
        //   filter.regionList.forEach((item: selectOptions, index: number) => {
        //     regionList += `regionList=${item.code}`;
        //     if (index + 1 !== filter.regionList?.length) {
        //       regionList += `&`;
        //     }
        //   });
        // }
      }
      if (params) {
        params.statDtSt = dateToTimestamp(params.statDtSt);
        if(params?.statDtEd)
        {
          params.statDtEd = dateToTimestamp(params.statDtEd);
        }
      }
        
      let url = `/stat/voc/unit/${statUnit}`;

      // oneview 용
      filter.useOneview && (url = `/oneview/voc/${statType}`);

      //@ts-ignore
      delete params.regionList;

      const response: ApiResponse<any> = await this.api.apisauce.get(url, params);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      if (response.data.data) {
        response.data.data.forEach((item: IStbStatisticsSnapshot, index: number) => {
          item.statDt = timestampToDate(Number.parseInt(item.statDt));
          item.preStatDt = item.preStatDt ? timestampToDate(Number.parseInt(item.preStatDt)) : '';
        });
      }

      const responseInfo: IResponseSnapshot = response.data;

      return {
        kind: 'ok',
        data: response.data.data,
        responseInfo: responseInfo,
      };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async getsForOneview(vocStatisticsFilter?: TVocStatisticsFilter,): Promise<TGetVocStatisticsResultForOneview> {
    try {
      let regionList: string = ``;
      let params: any = {};
      let statType: number = 0;
      const filter = { ...vocStatisticsFilter };
      if (filter.statType) {
        statType = filter.statType;

        //@ts-ignore
        delete filter.statType;

        params = filter;

      }
      if (params) {
        params.statDtSt = dateToTimestamp(params.statDtSt);
        params.statDtEd = dateToTimestamp(params.statDtEd);
      }

      let url = `/stat/voc/${statType}`;

      // oneview 전용
      filter.useOneview && (url = `/oneview/voc/${statType}`);

      //@ts-ignore
      delete params.regionList;

      const response: ApiResponse<any> = await this.api.apisauce.get(url, params);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;

      return {
        kind: 'ok',
        data: response.data.data,
        responseInfo: responseInfo,
      };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async getBubbleChartData(): Promise<TGetVocBubbleChart> {
    try {
      let params: any = {};

      const url = `/oneview/voc/keyword`;

      const response: ApiResponse<any> = await this.api.apisauce.get(url, params);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;

      return {
        kind: 'ok',
        data: response.data.data,
        responseInfo: responseInfo,
      };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }
}
