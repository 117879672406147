import { ApiResponse } from 'apisauce';
import { selectOptions } from '../../components/forms/CSelect';
import { IPaginationSnapshot, IResponseSnapshot } from '../../models';
import {
  IUserLoginHistoryFilter,
  TUserLoginHistoryFilter,
} from '../../models/user-login-history-store/user-login-history-store';
import { dateToTimestamp } from '../../utils/dateExchanger';
import { Api, getGeneralApiProblem } from '../api';
import { GetUserLoginHistorysResult } from './user-login-history-result-types';

export class UserLoginHistoryApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  async gets(
    pagination: IPaginationSnapshot,
    userLoginHistoryFilter?: TUserLoginHistoryFilter,
  ): Promise<GetUserLoginHistorysResult> {
    try {
      let sendData: any = {};
      let acntSttsList: string = ``;

      if (userLoginHistoryFilter) {
        sendData = filterSendData(userLoginHistoryFilter);
      }
      sendData.size = pagination.size;
      sendData.page = pagination.page;

      const queryString = Object.keys(sendData)
        .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(sendData[key])}`)
        .join('&');

      const SUB_URL = `/auth/log?${queryString}`;

      const response: ApiResponse<any> = await this.api.apisauce.get(SUB_URL);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;

      if (responseInfo.resultCode === 'S') {
        return {
          kind: 'ok',
          responseInfo: responseInfo,
          userLoginHistorys: response.data.data,
          pagination: response.data.pagination,
        };
      }

      return { kind: 'ok', responseInfo: responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }
}

const filterSendData = (userLoginHistoryFilter: IUserLoginHistoryFilter) => {
  let sendData: any = {};

  if (userLoginHistoryFilter.loginId) {
    sendData.loginId = userLoginHistoryFilter.loginId;
  }

  if (userLoginHistoryFilter.clntAddr) {
    sendData.clntAddr = userLoginHistoryFilter.clntAddr;
  }

  if (userLoginHistoryFilter.userNm) {
    sendData.userNm = userLoginHistoryFilter.userNm;
  }

  if (userLoginHistoryFilter.sucesYn == true || userLoginHistoryFilter.sucesYn == false) {
    sendData.sucesYn = userLoginHistoryFilter.sucesYn;
  }

  // if (userLoginFilter.userSid) {
  //   sendData.userSid = userLoginFilter.userSid;
  // }

  // if (userLoginFilter.usrgrpSid) {
  //   sendData.usrgrpSid = userLoginFilter.usrgrpSid;
  // }

  if (userLoginHistoryFilter.authDtSt) {
    sendData.authDtSt = dateToTimestamp(userLoginHistoryFilter.authDtSt);
  }

  if (userLoginHistoryFilter.authDtEd) {
    sendData.authDtEd = dateToTimestamp(userLoginHistoryFilter.authDtEd);
  }

  return sendData;
};
