/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import { TabContext, TabPanel } from '@mui/lab';
import { toJS } from 'mobx';
import { Box, Divider, Tabs, Tab } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import CButtonSet from '../../../components/CButtonSet';
import CButtonSetItem from '../../../components/CButtonSetItem';
import { BUTTON_LABEL, FORMSET_TITLE } from '../../../components/settings/SettingConstEnum';
import { IRealTimeAlarmSnapshot, useStores } from '../../../models';
import { AiAlarmDetailRelated } from './AiAlarmDetailRelated';
import { AiAlarmsDetailTopology } from './AiAlarmsDetailTopology';
import { CallApiToStore } from '../../Home';
import AiAlarmsDetailBasic from './AiAlarmsDetailBasic';
import { Scrollbar } from '../../../components/Scrollbar';
import AialarmDetailQuetone from './AialarmDetailQuetone';

interface RealTimeAlarmDetailFormSetProps {
  handleCloseDialog: () => void;
  showButton?: boolean;
  type?: string | null;
  removeOnTerminate?: boolean;
}

export const AiAlarmsDetail = observer(({
  handleCloseDialog,
  showButton = true,
  type = null,
  removeOnTerminate = true, 
}: RealTimeAlarmDetailFormSetProps) => {

  const rootStore = useStores();

  const { realtimeAlarmStore, loadingStore } = rootStore;

  const [tabValue, setTabValue] = useState((type === 'quetone' ? 'quetone' : 'basic'));

  const eventId = realtimeAlarmStore.selectedAlarm?.eventId || '';
  const eventState = realtimeAlarmStore.selectedAlarm?.eventState?.value || '';
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  const getBasicData = async () => {
    eventId && await CallApiToStore(realtimeAlarmStore.get(eventId), 'api', loadingStore);
  };

  const getTableData = async () => {
    eventId && await CallApiToStore(
      realtimeAlarmStore.getRelateds(eventId),
      'api',
      loadingStore,
    );
  };

  const updateAlarm = async (type: string) => {
    // eventId && await CallApiToStore(realtimeAlarmStore.update(eventId, type), 'api', loadingStore);

    if(eventId){
      if(removeOnTerminate){
        await CallApiToStore(realtimeAlarmStore.update(eventId, type), 'api', loadingStore);
      }else{
        await CallApiToStore(realtimeAlarmStore.updateAlarm(eventId, type), 'api', loadingStore); // action which does not remove event when event is terminated
      }
    }
  }

  useEffect(() => {

    realtimeAlarmStore.resetRelatedAlarms();

    if (tabValue == 'basic') {
      // getBasicData();
    } else if (tabValue == 'related') {
      getTableData();
    } else {
    }

  }, [tabValue]);

  return (
    <>
      <TabContext value={tabValue}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            sx={{ width: '100%' }}
            value={tabValue}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="inherit"
            variant="scrollable"
            scrollButtons="auto"
            selectionFollowsFocus
          >
            {(type === 'quetone' && <Tab sx={{ fontSize: '20px' }} value='quetone' label='큐톤 신호' />)}
            <Tab sx={{ fontSize: '20px' }} value="basic" label="기본 정보" />
            <Tab sx={{ fontSize: '20px' }} value="related" label="연관 알람 정보" />
            {/* <Tab sx={{ fontSize: '20px' }} value="topology" label="채널 토폴로지" /> */}
          </Tabs>
        </Box>

        {/* <Box height="520px" sx={{ overflowY: 'scroll' }}> */}
        <Scrollbar
          sx={{
            height: 'calc(100% - 50px)',
            '& .simplebar-content': { height: '100%', display: 'flex', flexDirection: 'column' },
          }}>

          {(type === 'quetone' && <TabPanel value='quetone'><AialarmDetailQuetone /></TabPanel>)}
          <TabPanel value="basic">
            <AiAlarmsDetailBasic />
          </TabPanel>
          <TabPanel value="related">
            <AiAlarmDetailRelated getTableData={getTableData} />
          </TabPanel>
          <TabPanel value="topology">
            <AiAlarmsDetailTopology />
          </TabPanel>
        </Scrollbar>
        {/* </Box> */}

      </TabContext>

      <Box className="modalButton" sx={{ marginTop: 0, width: '100%' }}>
        <Divider></Divider>
        <CButtonSet sx={{ marginTop: 2 }} justifyContent={'flex-end'}>
          <CButtonSetItem
            onClick={() => {
              updateAlarm('ack');
            }}
            disabled={eventState == 'Acknowledged' || eventState == 'Terminated'}
            color={'success'}
          >
            {BUTTON_LABEL.ACK}
          </CButtonSetItem>
          <CButtonSetItem
            onClick={() => {
              updateAlarm('terminate');
            }}
            disabled={eventState == 'Terminated'}
            color={'error'}
          >
            {BUTTON_LABEL.TERMINATE}
          </CButtonSetItem>
          <CButtonSetItem onClick={handleCloseDialog}>{BUTTON_LABEL.CLOSE}</CButtonSetItem>
        </CButtonSet>
      </Box>
    </>
  );
},
);

export default AiAlarmsDetail;
