import { Instance, SnapshotOut, types } from 'mobx-state-tree';

export const SopFile = types
  .model('SopFile')
  .props({
    sopFileSid: types.optional(types.maybeNull(types.number), 0),
    sopSid: types.optional(types.number, 0),
    fileType: types.optional(types.maybeNull(types.string), ''),
    fileName: types.optional(types.maybeNull(types.string), ''),
    sopFile: types.optional(types.maybeNull(types.string), ''),
  })
  .actions((self) => ({
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as ISopFile;
      (Object.keys(newProps) as TSopFileKeys[]).forEach((key) => {
        //@ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type TSopFile = Instance<typeof SopFile>;
type TSopFileSnapshot = SnapshotOut<typeof SopFile>;

export interface ISopFile extends TSopFile {}
export type TSopFileKeys = keyof TSopFileSnapshot & string;
export interface ISopFileSnapshot extends TSopFileSnapshot {}
export const createSopFile = () =>
  types.optional(SopFile, {
    sopFileSid: 0,
    sopSid: 0,
    fileType: '',
    sopFile: '',
  } as TSopFile);
