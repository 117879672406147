import { ApiResponse } from 'apisauce';
import { Api } from '../api/api';
import { getGeneralApiProblem } from '../api/api-problem';
import { IPaginationSnapshot } from './../../models/pagination/Pagination';
import {
  IPairAlarmFilter,
  IPairAlarmSnapshot,
  TPairAlarmKeys,
} from './../../models/pair-alarm/PairAlarm';
import { IResponseSnapshot } from './../../models/response/Response';
import { timestampToDate } from './../../utils/dateExchanger';
import {
  TDeletePairAlarmResult,
  TGetPairAlarmResult,
  TGetPairAlarmsResult,
  TPostPairAlarmResult,
  TUpdatePairAlarmResult,
} from './PairAlarmTypes';

/**
 * # PairAlarm Api 서비스
 *
 * 서비스의 설명을 작성하세요.
 *
 * ## 사용방법
 *
 * ```ts
 * const service = new PairAlarmApi();
 *
 * ```
 */
export class PairAlarmApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  /**
   * INF_CAIMS_00055
   * 설정된 알람 목록을 조회한다.
   * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=1695154716
   * @returns
   */
  async gets(
    pagination: IPaginationSnapshot,
    pairAlarmFilter?: IPairAlarmFilter,
  ): Promise<TGetPairAlarmsResult> {
    try {
      const url: string = `/alarm/pair`;
      interface ISendData extends IPairAlarmFilter {
        page: number;
        size: number;
      }

      const sendData: ISendData = {
        page: pagination.page,
        size: pagination.size,
      };

      if (pairAlarmFilter) {
        pairAlarmFilter = deleteAttribute(pairAlarmFilter);
      }

      let params: ISendData = { ...sendData, ...pairAlarmFilter };

      const response: ApiResponse<any> = await this.api.apisauce.get(url, params);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }
      const responseInfo: IResponseSnapshot = response.data;
      if (responseInfo.resultCode === 'S') {
        if (response.data.data) {
          response.data.data.forEach((item: IPairAlarmSnapshot) => {
            if (item.updDt && item.regDt) {
              item.updDt = timestampToDate(Number.parseInt(item.updDt));
              item.regDt = timestampToDate(Number.parseInt(item.regDt));
            }
          });
          const pairAlarms: IPairAlarmSnapshot[] = response.data.data;
          const pagination: IPaginationSnapshot = response.data.pagination;
          return {
            kind: `ok`,
            responseInfo: responseInfo,
            pairAlarms: pairAlarms,
            pagination: pagination,
          };
        }
      }
      return { kind: 'ok', responseInfo: responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  /**
   * INF_CAIMS_00056
   * 해당 Pair 알람의 상세정보를 조회한다.
   * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=935231493
   * @param id
   * @returns
   */
  async get(pairAlarmSid: number): Promise<TGetPairAlarmResult> {
    try {
      const url: string = `/alarm/pair/${pairAlarmSid}`;
      const response: ApiResponse<any> = await this.api.apisauce.get(url);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;

      if (responseInfo.resultCode === 'S') {
        if (response.data.data) {
          response.data.data.updDt = timestampToDate(response.data.data.updDt);
          response.data.data.regDt = timestampToDate(response.data.data.regDt);
          const pairAlarm: IPairAlarmSnapshot = response.data.data;

          return { kind: `ok`, responseInfo: responseInfo, pairAlarm: pairAlarm };
        }
      }
      return { kind: `ok`, responseInfo: responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  /**
   * INF_CAIMS_00057
   * 새로운 Pair 알람을 등록한다.
   * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=1472439387
   * @returns
   */
  async post(pairAlarm: IPairAlarmSnapshot): Promise<TPostPairAlarmResult> {
    try {
      const url: string = `/alarm/pair`;

      const response: ApiResponse<any> = await this.api.apisauce.post(
        url,
        createSendData(pairAlarm),
      );

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;
      if (responseInfo.resultCode === 'S') {
        if (response.data.data) {
          response.data.data.updDt = timestampToDate(response.data.data.updDt);
          response.data.data.regDt = timestampToDate(response.data.data.regDt);
          const pairAlarm: IPairAlarmSnapshot = response.data.data;
          return { kind: `ok`, responseInfo: responseInfo, pairAlarm: pairAlarm };
        }
      }
      return { kind: 'ok', responseInfo: responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  /**
   * INF_CAIMS_00058
   * 해당 Pair 알람의 정보를 수정한다.
   * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=273969609
   * @param pairAlarm
   * @returns
   */
  async put(pairAlarm: IPairAlarmSnapshot): Promise<TUpdatePairAlarmResult> {
    try {
      const url: string = `/alarm/pair/${pairAlarm.pairAlarmSid}`;
      const response: ApiResponse<any> = await this.api.apisauce.put(
        url,
        createSendData(pairAlarm),
      );

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }
      const responseInfo: IResponseSnapshot = response.data;
      if (responseInfo.resultCode === 'S') {
        if (response.data.data) {
          response.data.data.regDt = timestampToDate(response.data.data.regDt);
          response.data.data.updDt = timestampToDate(response.data.data.updDt);
          const pairAlarm: IPairAlarmSnapshot = response.data.data;
          return { kind: `ok`, responseInfo: responseInfo, pairAlarm: pairAlarm };
        }
      }

      return { kind: 'ok', responseInfo: responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  /**
   * INF_CAIMS_00059
   * 해당 Pair 알람을 삭제한다.
   * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=588343690
   * @param pairAlarmSid
   * @returns
   */
  async delete(pairAlarmSid: number): Promise<TDeletePairAlarmResult> {
    try {
      const url: string = `/alarm/pair/${pairAlarmSid}`;
      const response: ApiResponse<any> = await this.api.apisauce.delete(url);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;

      return { kind: 'ok', responseInfo: responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  /**
   * INF_CAIMS_00060
   * 여러개 Pair 알람을 한번에 삭제한다.
   * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=151683040
   * @param pairAlarmSidList
   * @returns
   */
  async deletes(pairAlarmSidList: number[]): Promise<TDeletePairAlarmResult> {
    try {
      let param = ``;
      pairAlarmSidList.forEach((item: number, index: number) => {
        param += `pairAlarmSidList=${item}`;
        if (index !== pairAlarmSidList.length - 1) {
          param += `&`;
        }
      });
      const url: string = `/alarm/pair?${param}`;
      const response: ApiResponse<any> = await this.api.apisauce.delete(url);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;

      return { kind: 'ok', responseInfo: responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }
}

const deleteAttribute = (pairAlarmFilter: IPairAlarmFilter) => {
  (Object.keys(pairAlarmFilter) as TPairAlarmKeys[]).forEach((key: string) => {
    //@ts-ignore
    if (!pairAlarmFilter[key]) {
      //@ts-ignore
      delete pairAlarmFilter[key];
    }
  });
  return pairAlarmFilter;
};

const createSendData = (pairAlarm: IPairAlarmSnapshot) => {
  let sendData: ISendData = {
    occEventTypeSid: pairAlarm.occEventTypeSid,
    occSpcfProbl: pairAlarm.occSpcfProbl,
    trmnEventTypeSid: pairAlarm.trmnEventTypeSid,
    trmnSpcfProbl: pairAlarm.trmnSpcfProbl,
  };

  if (pairAlarm.descr !== undefined) {
    sendData.descr = pairAlarm.descr;
  }

  return sendData;
};

interface ISendData {
  occEventTypeSid: number | null;
  occSpcfProbl: string | null;
  trmnEventTypeSid: number | null;
  trmnSpcfProbl: string | null;
  descr?: string | null;
}
