import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { createModelCode, ModelCode } from '../model-code/ModelCode';
import { createPort } from './../port/Port';
import { createSimpleUser } from './../simple-user/SimpleUser';

export const PairEquipment = types
  .model('PairEquipment')
  .props({
    equipSid: types.optional(types.number, 0),
    hostNm: types.optional(types.string, ''),
    modelNm: types.optional(types.maybeNull(types.string), ''),
    equipType: createModelCode(),
    equipRole: types.maybeNull(createModelCode()),
    trtResol: createModelCode(),
    mnfcSid: types.optional(types.number, 0),
    vendorSid: types.optional(types.number, 0),
    buyDate: types.optional(types.maybeNull(types.string), ''),
    installDate: types.optional(types.maybeNull(types.string), ''),
    mgmtIp: types.optional(types.string, ''),
    frmwrVer: types.optional(types.maybeNull(types.string), ''),
    // modulInclYn: types.optional(types.boolean, false),
    haType: createModelCode(),
    haSttus: createModelCode(),
    repYn: types.optional(types.boolean, false),
    equipOprtSttus: createModelCode(),
    helthySttus: types.maybeNull(createModelCode()),
    repEquipSid: types.optional(types.maybeNull(types.number), 0),
    repEquipNm: types.optional(types.maybeNull(types.string), ''),


    upTime: types.optional(types.maybeNull(types.number), null),
    upTimeStr: types.optional(types.maybeNull(types.string),''),

    rack: createModelCode(),
    rackSid: types.optional(types.maybeNull(types.number), null),
    slotNo: types.optional(types.maybeNull(types.number), null),
    rackInfo: types.optional(types.maybeNull(types.string),''),
    rackNm: types.optional(types.maybeNull(types.string),''),
    rackLocX: types.optional(types.maybeNull(types.number), null),
    rackLocY: types.optional(types.maybeNull(types.number), null),
    cbtpaIdM: types.optional(types.maybeNull(types.number), null),
    cbtpaIdB: types.optional(types.maybeNull(types.number), null),
    cbtpaNmM: types.optional(types.maybeNull(types.string),''),
    cbtpaNmB: types.optional(types.maybeNull(types.string),''),
    equipRoomSid: types.optional(types.maybeNull(types.number), null),
    equipRoomNm: types.optional(types.maybeNull(types.string),''),
    equipRoomLo: types.optional(types.maybeNull(types.string),''),    

    etnlEquipId: types.optional(types.maybeNull(types.string), ''),
    intrlkSysSid: types.optional(types.number, 0),
    sysNm: types.optional(types.string, ''),
    intrlkSysSrvrSid: types.optional(types.maybeNull(types.number), 0),
    sysSrvrNm: types.optional(types.maybeNull(types.string), ''),
    regDt: types.optional(types.maybeNull(types.string), null),
    regUser: types.maybeNull(createSimpleUser()),
    updDt: types.optional(types.maybeNull(types.string), null),
    updUser: types.maybeNull(createSimpleUser()),
    portList: types.optional(types.maybeNull(types.array(createPort())), []),
    eqPairHostNmDuplicate: types.optional(types.maybeNull(types.boolean), false),
  })
  .actions((self) => ({
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as IPairEquipment;
      (Object.keys(newProps) as TPairEquipmentKeys[]).forEach((key) => {
        //@ts-ignore
        self[key] = newProps[key];
      });
    },
    resetPairEquipment: () => {
      self.equipSid = 0;
      self.hostNm = '';
      self.modelNm = '';
      self.mnfcSid = 0;
      self.vendorSid = 0;
      self.buyDate = '';
      self.installDate = '';
      self.mgmtIp = '';
      self.frmwrVer = '';
      // self.modulInclYn = false;
      self.repYn = false;
      self.repEquipNm = '';
      self.repEquipSid = 0;
      self.etnlEquipId = '';
      self.intrlkSysSid = 0;
      self.sysNm = '';
      self.intrlkSysSrvrSid = 0;
      self.sysSrvrNm = '';
      self.equipType = ModelCode.create({
        code: 0,
        pcode: 0,
        value: '',
      });
      self.equipRole = ModelCode.create({
        code: 0,
        pcode: 0,
        value: '',
      });
      self.trtResol = ModelCode.create({
        code: 0,
        pcode: 0,
        value: '',
      });
      self.haType = ModelCode.create({
        code: 0,
        pcode: 0,
        value: '',
      });
      self.haSttus = ModelCode.create({
        code: 0,
        pcode: 0,
        value: '',
      });
      self.equipOprtSttus = ModelCode.create({
        code: 0,
        pcode: 0,
        value: '',
      });
    },
  }));
type TPairEquipment = Instance<typeof PairEquipment>;
type TPairEquipmentSnapshot = SnapshotOut<typeof PairEquipment>;

export interface IPairEquipment extends TPairEquipment {}
export type TPairEquipmentKeys = keyof TPairEquipmentSnapshot & string;
export interface IPairEquipmentSnapshot extends TPairEquipmentSnapshot {}
export const createPairEquipment = () =>
  types.optional(PairEquipment, {
    equipSid: 0,
    hostNm: '',
    modelNm: '',
    mnfcSid: 0,
    vendorSid: 0,
    buyDate: '',
    installDate: '',
    repEquipNm: '',
    repEquipSid: 0,
    mgmtIp: '',
    frmwrVer: '',
    // modulInclYn: false,
    repYn: false,
    etnlEquipId: '',
    intrlkSysSid: 0,
    sysNm: '',
    intrlkSysSrvrSid: 0,
    sysSrvrNm: '',
  } as TPairEquipment);
