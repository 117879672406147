import { IPortSnapshot } from './../../models/port/Port';
import { IResponseSnapshot } from './../../models/response/Response';
import { ApiResponse } from 'apisauce';
import { IPaginationSnapshot, IPort, TPortFilter } from '../../models';
import { Api } from '../api/api';
import { getGeneralApiProblem } from '../api/api-problem';
import { TGetPortsResult, TPostPortResult } from './PortTypes';
import { timestampToDate } from '../../utils/dateExchanger';

/**
 * # Port Api 서비스
 *
 * 서비스의 설명을 작성하세요.
 *
 * ## 사용방법
 *
 * ```ts
 * const service = new PortApi();
 *
 * ```
 */
export class PortApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  /**
   * INF_CAIMS_00222
   * 해당 장비의 포트 목록을 조회한다.
   * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=1422075397
   * @param pagination
   * @param portFilter
   * @returns
   */
  async gets(pagination: IPaginationSnapshot, portFilter: TPortFilter): Promise<TGetPortsResult> {
    try {
      const url = '/equipment/' + portFilter.equipSid + '/port';
      const params = {
        portNm: portFilter.portNm,
        portType: portFilter.portType,
        portRole: portFilter.portRole,
      };
      const response: ApiResponse<any> = await this.api.apisauce.get(url, params);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;
      if (responseInfo.resultCode === 'S') {
        if (response.data.data) {
          const ports: IPortSnapshot[] = response.data.data;
          const pagination: IPaginationSnapshot = response.data.pagination;
          ports.forEach((port: IPortSnapshot) => {
            port.regDt = timestampToDate(Number.parseInt(port.regDt));
            port.updDt = timestampToDate(Number.parseInt(port.updDt));
          });
          return { kind: `ok`, responseInfo: responseInfo, ports: ports, pagination: pagination };
        }
      }

      return {
        kind: 'ok',
        responseInfo: responseInfo,
      };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  /**
   * INF_CAIMS_00223
   * 해당 장비의 포트를 등록한다.
   * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=1445380629
   * @param port
   * @returns
   */
  async post(port: IPort): Promise<TPostPortResult> {
    try {
      const url = '/equipment/' + port.equipSid + '/port';
      const params = {
        portNm: port.portNm,
        portIp: port.portIp,
        idx: port.idx,
        portType: port.portType?.code,
        portSttus: port.portSttus?.code,
        portDescr: port.portDescr,
        portRole: port.portRole?.code,
      };
      const response: ApiResponse<any> = await this.api.apisauce.post(url, params);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;
      if (responseInfo.resultCode === 'S') {
        if (response.data.data) {
          const port: IPortSnapshot = response.data.data;
          return { kind: `ok`, responseInfo: responseInfo, port: port };
        }
      }

      return {
        kind: 'ok',
        responseInfo: responseInfo,
      };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }
}
