import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { createModelCode } from '../model-code/ModelCode';
import { createSimpleUser } from '../simple-user/SimpleUser';

export const Kdb = types
  .model('Kdb')
  .props({
    kdbSid: types.optional(types.number, 0),
    instType: types.maybeNull(createModelCode()),
    eventType: types.maybeNull(createModelCode()),
    problCause: types.optional(types.maybeNull(types.string), ''),
    spcfProbl: types.optional(types.maybeNull(types.string), ''),
    trtSchm: types.optional(types.maybeNull(types.string), ''),
    delYn: types.optional(types.boolean, false),
    regDt: types.optional(types.string, ''),
    regUser: types.maybeNull(createSimpleUser()),
    updDt: types.optional(types.string, ''),
    updUser: types.maybeNull(createSimpleUser()),
  })
  .actions((self) => ({
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as IKdb;
      (Object.keys(newProps) as TKdbKeys[]).forEach((key) => {
        //@ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type TKdb = Instance<typeof Kdb>;
type TKdbSnapshot = SnapshotOut<typeof Kdb>;

export interface IKdb extends TKdb {}
export type TKdbKeys = keyof TKdbSnapshot & string;
export interface IKdbSnapshot extends TKdbSnapshot {}
export const createKdb = () =>
  types.optional(Kdb, {
    kdbSid: 0,
    instType: null,
    eventType: null,
    problCause: '',
    spcfProbl: '',
    trtSchm: '',
    delYn: false,
  } as TKdb);
