import { toJS } from "mobx";
import {
  IOneviewStatisticsChartSnapshot, IStbStatisticsDataSnapshot,
  IStbStatisticsSnapshot,
  IVocStatisticsSnapshot
} from '../models';
import {
  IOneViewStatisticsGraphSnapshot,
} from '../models/oneview-statistics-graph/OneViewStatisticsGraph';
import { IOneViewStatisticsMap, IOneViewStatisticsMapSnapshot } from '../models/oneview-statistics-map/OneViewStatisticsMap';

export const defaultData: IOneViewStatisticsMapSnapshot[] = [
  { name: '경기', x: 142, y: 84, value: 0, preValue: 0, compareValue: 0, state: 'none', fullName: '경기도' },
  { name: '인천', x: 118, y: 136, value: 0, preValue: 0, compareValue: 0, state: 'none', fullName: '인천광역시' },
  { name: '서울', x: 179, y: 128, value: 0, preValue: 0, compareValue: 0, state: 'none', fullName: '서울특별시' },
  { name: '강원', x: 302, y: 90, value: 0, preValue: 0, compareValue: 0, state: 'none', fullName: '강원특별자치도' },
  { name: '충북', x: 230, y: 180, value: 0, preValue: 0, compareValue: 0, state: 'none', fullName: '충청북도' },
  { name: '세종', x: 192, y: 217, value: 0, preValue: 0, compareValue: 0, state: 'none', fullName: '세종특별자치시' },
  { name: '충남', x: 127, y: 235, value: 0, preValue: 0, compareValue: 0, state: 'none', fullName: '충청남도' },
  { name: '경북', x: 300, y: 235, value: 0, preValue: 0, compareValue: 0, state: 'none', fullName: '경상북도' },
  { name: '대전', x: 192, y: 255, value: 0, preValue: 0, compareValue: 0, state: 'none', fullName: '대전광역시' },
  { name: '대구', x: 282, y: 290, value: 0, preValue: 0, compareValue: 0, state: 'none', fullName: '대구광역시' },
  { name: '전북', x: 168, y: 298, value: 0, preValue: 0, compareValue: 0, state: 'none', fullName: '전라북도' },
  { name: '울산', x: 342, y: 319, value: 0, preValue: 0, compareValue: 0, state: 'none', fullName: '울산광역시' },
  { name: '경남', x: 255, y: 350, value: 0, preValue: 0, compareValue: 0, state: 'none', fullName: '경상남도' },
  { name: '광주', x: 170, y: 356, value: 0, preValue: 0, compareValue: 0, state: 'none', fullName: '광주광역시' },
  { name: '부산', x: 312, y: 365, value: 0, preValue: 0, compareValue: 0, state: 'none', fullName: '부산광역시' },
  { name: '전남', x: 170, y: 398, value: 0, preValue: 0, compareValue: 0, state: 'none', fullName: '전라남도' },
  { name: '제주', x: 126, y: 485, value: 0, preValue: 0, compareValue: 0, state: 'none', fullName: '제주특별자치도' },
];

const defaultChartData: IStbStatisticsDataSnapshot[] = [
  {
    model: "NXS006",
    region: '',
    cnt: 0,
    preCnt: null,
    rateCnt: 0,
  },
  {
    model: "CVS332",
    region: '',
    cnt: 0,
    preCnt: null,
    rateCnt: 0,
  },
  {
    model: "SCD022",
    region: '',
    cnt: 0,
    preCnt: null,
    rateCnt: 0,
  },
  {
    model: "YJK321",
    region: '',
    cnt: 0,
    preCnt: null,
    rateCnt: 0,
  },
  {
    model: "KWS332",
    region: '',
    cnt: 0,
    preCnt: null,
    rateCnt: 0,
  },
  {
    model: "CH0321",
    region: '',
    cnt: 0,
    preCnt: null,
    rateCnt: 0,
  },
  {
    model: "XWD521",
    region: '',
    cnt: 0,
    preCnt: null,
    rateCnt: 0,
  }
];

export const SidebarforMapNew = (data: IOneViewStatisticsMap[]) => {
  if (data.length >= 1) {
    let newData: IOneViewStatisticsMapSnapshot[] = JSON.parse(JSON.stringify(defaultData));
    data.map((item: any) => {
      newData.map((i) => {
        if (i.fullName === item.region) {
          const cnt = item.cnt;
          const preCnt = item.preCnt;
          // const cnt = Math.floor(Math.random() * 100); // item.cnt;
          // const preCnt = Math.floor(Math.random() * 100); // item.preCnt;
          i.value = cnt;
          i.preValue = preCnt;
          i.compareValue = (((cnt - preCnt) / preCnt) * 100); // 증감율;
          if(isNaN(i.compareValue) || i.compareValue === Infinity) i.compareValue = 0;
          i.state = '';
        }
      })
    })
    return { 'data': newData }
  }
}

export const SidebarforMap = (data: IStbStatisticsSnapshot[], existMapData: IOneViewStatisticsMapSnapshot[], time: Date) => {
  let newDate = time;
  if (data.length >= 1) {
    let newData: IOneViewStatisticsMapSnapshot[] = [...existMapData];
    data.map((item) => {
      const itemDate = new Date(item.statDt);
      //날이 같으면 더하기
      if (itemDate.getDay() === newDate.getDay()) {
        const newPlusData = [...newData];
        newPlusData.map((i) => {
          const ValueList = item.dataList.filter((k) => (k.region === i.fullName));
          if (ValueList.length >= 1) {
            const value = ValueList[0].cnt;
            i.value += value ? value : 0;
          }
        })
      }
      //날이 다르면 초기화해서 더하기
      else {
        let resetData: IOneViewStatisticsMapSnapshot[] = [...defaultData];
        resetData.map((i) => {
          const ValueList = item.dataList.filter((k) => (k.region === i.fullName));
          if (ValueList.length >= 1) {
            const value = ValueList[0].cnt;
            i.value += value ? value : 0;
          }
        })
        newData = resetData;
      }
      newDate = item.statDt ? new Date(item.statDt) : time;
    })

    // console.log('SidebarforMap newData', newData)
    return { 'data': newData, 'time': newDate }
  }
}

export const SidebarforGraph = (
  data: IVocStatisticsSnapshot[],
  existMapData: IOneViewStatisticsGraphSnapshot,
  time: Date,
) => {
  try {
    let newDate = time;
    if (data.length >= 1) {
      let total = 0;
      let totalLine = 0;
      let newData: IOneViewStatisticsGraphSnapshot = { ...existMapData };
      data.map((item) => {
        const itemDate = new Date(item.statDt);
        const hour = itemDate.getHours().toString();
        let add = item.cnt ? item.cnt : 0;
        const line = item.preCnt ? item.preCnt : 0;

        /*
        같은시간이 있으면 대체 없으면 추가
        */
        let isAdded: Boolean = true;
        newData.dateList.map((t, i) => {
          if (t == hour) {
            newData.addCnt[i] = add;
            newData.lineData[i] = i > 0 ? (newData.lineData[(i - 1)] + line) : line;
            isAdded = false;
          }
        });

        if (isAdded) {
          newData.totalCnt.push(total);
          total += add;
          newData.dateList.push(itemDate.getHours().toString());
          newData.addCnt.push(add);
          newData.lineData.push(totalLine + line);
          newDate = item.statDt ? new Date(item.statDt) : time;
          totalLine += line;
        }
      });

      total = 0;
      newData.totalCnt.map((row, i) => {
        newData.totalCnt[i] = total;
        total += newData.addCnt[i];
      });
      // console.log('SidebarforGraph newData', newData)
      return { 'data': newData, 'time': newDate }
    }
  } catch (e) {
    console.log(e);
  }
};

export const SidebarforSTBChart = (res: IStbStatisticsDataSnapshot[]) => {
  let data = [...res];
  data.map((row) => {
    const cnt = row.cnt || 0;
    const preCnt = row.preCnt || 0;
    row.rateCnt = (((cnt - preCnt) / preCnt) * 100); // 증감율;
    if(isNaN(row.rateCnt) || row.rateCnt === Infinity) row.rateCnt = 0;
  });

  // const checkMacAddress = (addr: string) => {
  //   const regx = /^([0-9A-F]{2}[:-]){5}([0-9A-F]{2})$/;
  //   return regx.test(addr);
  // }

  data.sort((a: IStbStatisticsDataSnapshot, b: IStbStatisticsDataSnapshot) => (a.rateCnt > b.rateCnt) ? -1: 1);

  return {
    'data': data
  }
}
