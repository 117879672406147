import { RESULT_CODE } from './../../components/settings/SettingConstEnum';
import { ApiResponse } from 'apisauce';
import { selectOptions } from '../../components/forms/CSelect';
import { IPaginationSnapshot, IResponseSnapshot, TUserFilter } from '../../models';
import { getGeneralApiProblem } from '../../services/api';
import {
  GetDeletesUserResult,
  GetDeleteUserResult,
  GetInactiveClearResult,
  GetLoginIDCheckResult,
  GetPostUserResult,
  GetPutUserResult,
  GetUserApprovalResult,
  GetUserRejectResult,
  GetUserRestoreResult,
  GetUserResult,
  GetUsersResult,
  GetUserSuspendResult,
} from '../../services/user/user-result-types';
import { dateToTimestamp } from '../../utils/dateExchanger';
import { Api } from '../api/api';
import { IUserFilter } from './../../models/user-store/user-store';
import { IUserSnapshot } from './../../models/user/user';

export class UserApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  /**
   * INF_CAIMS_00162
   * 해당 사용자의 상세정보를 조회한다.
   * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=464696832
   * @param userSid
   * @returns
   */
  async get(userSid: number): Promise<GetUserResult> {
    try {
      const SUB_URL = '/operation/user/' + userSid;
      const response: ApiResponse<any> = await this.api.apisauce.get(SUB_URL);
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;

      if (responseInfo.resultCode === 'S') {
        return { kind: 'ok', responseInfo: responseInfo, user: response.data.data };
      }

      return { kind: 'ok', responseInfo: responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  /**
   * INF_CAIMS_00161
   * 시스템 사용자 전체 목록을 조회한다.
   * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=536042080
   * @param pagination
   * @param userFilter
   * @returns
   */
  async gets(pagination: IPaginationSnapshot, userFilter?: TUserFilter): Promise<GetUsersResult> {
    try {
      let sendData: any = {};
      let acntSttsList: string = ``;

      if (userFilter) {
        sendData = filterSendData(userFilter);

        if (userFilter.acntSttsList) {
          userFilter.acntSttsList.forEach((item: selectOptions, index: number) => {
            acntSttsList += `acntSttsList=${item.code}`;
            if (index !== userFilter.acntSttsList?.length) {
              acntSttsList += `&`;
            }
          });
        }
      }
      sendData.size = pagination.size;
      sendData.page = pagination.page;

      const SUB_URL = `/operation/user?${acntSttsList}`;

      const response: ApiResponse<any> = await this.api.apisauce.get(SUB_URL, sendData);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;

      if (responseInfo.resultCode === 'S') {
        return {
          kind: 'ok',
          responseInfo: responseInfo,
          users: response.data.data,
          pagination: response.data.pagination,
        };
      }

      return { kind: 'ok', responseInfo: responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  /**
   * INF_CAIMS_00163
   * 새로운 사용자를 등록한다.
   * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=1608359065
   * @param user
   * @returns
   */
  async post(user: IUserSnapshot): Promise<GetPostUserResult> {
    try {
      const SUB_URL = '/operation/user';
      const sendData = createSendData(user);

      const response: ApiResponse<any> = await this.api.apisauce.post(SUB_URL, sendData);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;

      if (responseInfo.resultCode === 'S') {
        return { kind: 'ok', responseInfo: responseInfo, user: response.data.data };
      }

      return { kind: 'ok', responseInfo: responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  /**
   * INF_CAIMS_00164
   * 해당 사용자의 정보를 수정한다.
   * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=2140743085
   * @param user
   * @returns
   */
  async put(user: IUserSnapshot): Promise<GetPutUserResult> {
    try {
      const SUB_URL = '/operation/user/' + user.userSid;
      const sendData = createSendData(user);
      const response: ApiResponse<any> = await this.api.apisauce.put(SUB_URL, sendData);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;

      if (responseInfo.resultCode === 'S') {
        return { kind: 'ok', responseInfo: responseInfo, user: response.data.data };
      }

      return { kind: 'ok', responseInfo: responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  /**
   * INF_CAIMS_00165
   * 해당 사용자의 정보를 삭제한다.
   * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=1001467282
   * @param userSid
   * @returns
   */
  async delete(userSid: number): Promise<GetDeleteUserResult> {
    try {
      const SUB_URL = '/operation/user/' + userSid;

      const response: ApiResponse<any> = await this.api.apisauce.delete(SUB_URL);
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;
      return { kind: 'ok', responseInfo: responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  /**
   * INF_CAIMS_00166
   * 여러개의 사용자를 한번에 삭제한다.
   * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=1937156406
   * @param deleteUserList
   * @returns
   */
  async deletes(deleteUserList: number[]): Promise<GetDeletesUserResult> {
    try {
      let param: string = ``;
      deleteUserList.forEach((item: number, index: number) => {
        param += `userSidList=${item}`;
        if (index !== deleteUserList.length - 1) {
          param += `&`;
        }
      });

      const SUB_URL = `/operation/user?${param}`;

      const response: ApiResponse<any> = await this.api.apisauce.delete(SUB_URL);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;
      return { kind: 'ok', responseInfo: responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  /**
   * INF_CAIMS_00167
   * 해당 사용자를 승인한다.
   * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=1754362887
   * @param userSid
   * @returns
   */
  async approval(userSid: number): Promise<GetUserApprovalResult> {
    try {
      const SUB_URL: string = `/operation/user/${userSid}/approval`;
      const response: ApiResponse<any> = await this.api.apisauce.put(SUB_URL);
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;
      return { kind: 'ok', responseInfo: responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  /**
   * INF_CAIMS_00168
   * 해당 사용자를 승인을 거절한다.
   * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=1651626323
   * @param userSid
   * @returns
   */
  async reject(userSid: number): Promise<GetUserRejectResult> {
    try {
      const SUB_URL: string = `/operation/user/${userSid}/reject`;
      const response: ApiResponse<any> = await this.api.apisauce.put(SUB_URL);
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;
      return { kind: 'ok', responseInfo: responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  /**
   * suspend user.
   * @param userSid
   * @returns
   */
    async suspend(userSid: number): Promise<GetUserSuspendResult> {
      try {
        const SUB_URL: string = `/operation/user/${userSid}/suspend`;
        const response: ApiResponse<any> = await this.api.apisauce.put(SUB_URL);
        if (!response.ok) {
          const problem = getGeneralApiProblem(response);
          if (problem) return problem;
        }
  
        const responseInfo: IResponseSnapshot = response.data;
        return { kind: 'ok', responseInfo: responseInfo };
      } catch (e) {
        return { kind: 'bad-data' };
      }
    }

  /**
   * restore user.
   * @param userSid
   * @returns
   */
      async restore(userSid: number): Promise<GetUserRestoreResult> {
        try {
          const SUB_URL: string = `/operation/user/${userSid}/restore`;
          const response: ApiResponse<any> = await this.api.apisauce.put(SUB_URL);
          if (!response.ok) {
            const problem = getGeneralApiProblem(response);
            if (problem) return problem;
          }
    
          const responseInfo: IResponseSnapshot = response.data;
          return { kind: 'ok', responseInfo: responseInfo };
        } catch (e) {
          return { kind: 'bad-data' };
        }
      }

  /**
   * INF_CAIMS_00214
   * 해당 사용자 계정 휴면을 해제한다.
   * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=324973781
   * @param userSid
   * @returns
   */
  async incativeClear(userSid: number): Promise<GetInactiveClearResult> {
    try {
      const SUB_URL: string = '/operation/user/' + userSid + '/inactive/clear';
      const response: ApiResponse<any> = await this.api.apisauce.put(SUB_URL);
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;
      return { kind: 'ok', responseInfo: responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  /**
   * INF_CAIMS_00169
   * 특정 아이디가 데이터베이스에 이미 존재하는지 확인한다.
   * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=1666174010
   * @param loginId
   * @returns
   */
  async checkLoginId(loginId: string): Promise<GetLoginIDCheckResult> {
    try {
      const SUB_URL: string = `/operation/user/loginId/check?loginId=${loginId}`;
      const response: ApiResponse<any> = await this.api.apisauce.get(SUB_URL);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;

      if (responseInfo.resultCode === RESULT_CODE.SUCCESS) {
        return { kind: 'ok', responseInfo: responseInfo, result: response.data.data.result };
      }

      return { kind: 'ok', responseInfo: responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }
}

type requestBodyType = {
  userSid?: number;
  loginId?: string;
  userNm?: string;
  usrgrpSidList?: number[];
  pwd?: string;
  pwdCorrect?: string;
  sessn: number;
};

const createSendData = (user: IUserSnapshot) => {
  let sendData: requestBodyType = { sessn: user.sessn };

  if (user.userSid) {
    sendData.userSid = user.userSid;
  }

  if (user.loginId !== undefined) {
    sendData.loginId = user.loginId;
  }

  if (user.userNm !== undefined) {
    sendData.userNm = user.userNm;
  }

  if (user.pwd) {
    sendData.pwd = btoa(user.pwd);
  }

  if (user.pwdCorrect) {
    sendData.pwdCorrect = btoa(user.pwdCorrect);
  }

  if (user.usrgrpSidList) {
    let usrgrpSidList: number[] = [];
    user.usrgrpSidList.map((item) => {
      if (item.code) {
        usrgrpSidList.push(item.code);
      }
    });
    sendData.usrgrpSidList = usrgrpSidList;
  }

  return sendData;
};

const filterSendData = (userFilter: IUserFilter) => {
  let sendData: any = {};

  if (userFilter.loginId) {
    sendData.loginId = userFilter.loginId;
  }

  if (userFilter.userNm) {
    sendData.userNm = userFilter.userNm;
  }

  if (userFilter.userSid) {
    sendData.userSid = userFilter.userSid;
  }

  if (userFilter.usrgrpSid) {
    sendData.usrgrpSid = userFilter.usrgrpSid;
  }

  if (userFilter.regSdt) {
    sendData.regSdt = dateToTimestamp(userFilter.regSdt);
  }

  if (userFilter.regEdt) {
    sendData.regEdt = dateToTimestamp(userFilter.regEdt);
  }

  return sendData;
};
