import { observer } from 'mobx-react-lite';
import { makeStyles } from '@mui/styles';
import { useSnackbar, SnackbarContent, SnackbarKey, SnackbarMessage } from 'notistack';
import Collapse from '@mui/material/Collapse';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { forwardRef, Ref, useCallback, useEffect, useState } from 'react';
import { useStores } from '../models';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import DeviceHub from '@mui/icons-material/DeviceHub';
import UpdateIcon from '@mui/icons-material/Update';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import ListAltIcon from '@mui/icons-material/ListAlt';

const useStyles = makeStyles(() => ({
  root: {
    '@media (min-width:600px)': {
      minWidth: '344px !important',
    },
  },
  typography: {
    fontWeight: 'bold',
  },
  actionRoot: {
    padding: '8px 8px 8px 16px',
    justifyContent: 'space-between',
  },
  icons: {
    marginLeft: 'auto',
  },
  expand: {
    padding: '8px 8px',
    transform: 'rotate(0deg)',
    transition: 'all .2s',
  },
  collapse: {
    borderRadius: '4px !important',
    padding: 16,
  },
  button: {
    padding: 0,
    textTransform: 'none',
  },
}));

interface SoketSnackBarContentProps {
  id: SnackbarKey;
  type: string;
  message: SnackbarMessage;
}
// ----------------------------------------------------------------------

export default forwardRef(function SoketSnackBarContent(
  { id, type, message }: SoketSnackBarContentProps,
  ref,
) {
  const classes = useStyles();
  const { commonStore } = useStores();
  const [contentStyle, setContentStyle] = useState<React.CSSProperties>({});
  const [icon, setIcon] = useState<any>();
  const { closeSnackbar } = useSnackbar();
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = useCallback(() => {
    setExpanded((oldExpanded) => !oldExpanded);
  }, []);

  const handleDismiss = useCallback(() => {
    id && closeSnackbar(id);
    commonStore.resetSoketSnackBar();
  }, [id, closeSnackbar]);

  useEffect(() => {
    switch (type) {
      case 'CHANNEL_LINEUP':
        setContentStyle({ background: '#fddc6c', color: 'black' });
        setIcon(<ListAltIcon />);
        break;
      case 'TOPOLOGY':
        setContentStyle({ background: '#fddc6c', color: 'black' });
        setIcon(<DeviceHub />);
        break;
      case 'EQUIPMENT':
        setContentStyle({ background: '#fddc6c', color: 'black' });
        setIcon(<HomeRepairServiceIcon />);
        break;
      case 'JOB':
        setContentStyle({ background: '#fddc6c', color: 'black' });
        setIcon(<UpdateIcon />);
        break;
      case 'EVENT':
        setContentStyle({ background: '#fddc6c', color: 'black' });
        setIcon(<AccessAlarmIcon />);
        break;
    }
  }, [id]);

  return (
    <SnackbarContent ref={ref as unknown as Ref<HTMLDivElement>} className={classes.root}>
      <Card sx={contentStyle}>
        <CardActions classes={{ root: classes.actionRoot }}>
          {icon}
          <Typography variant="subtitle2" className={classes.typography} sx={{ ml: 1 }}>
            {message}
          </Typography>
          <div className={classes.icons}>
            <IconButton
              aria-label="Show more"
              className={classes.expand}
              style={{ transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)' }}
              onClick={handleExpandClick}
            >
              <ExpandMoreIcon />
            </IconButton>
            <IconButton className={classes.expand} onClick={handleDismiss}>
              <CloseIcon />
            </IconButton>
          </div>
        </CardActions>
        {/* <Collapse in={expanded} timeout="auto" unmountOnExit>
          <Paper className={classes.collapse}>
            <Typography variant="subtitle2" className={classes.typography} gutterBottom>
              상세 내용
            </Typography>
            <Typography variant="subtitle2" fontWeight={500} fontSize={'0.8rem'}>
              상세 내용입니다.
            </Typography>
          </Paper>
        </Collapse> */}
      </Card>
    </SnackbarContent>
  );
});
