import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { createKdb, IKdb, Kdb } from '../kdb/Kdb';
import {
  KdbApi,
  TCheckDuplicateSaid,
  TDeleteKdbResult,
  TGetKdbResult,
  TGetKdbsResult,
  TPostKdbResult,
  TUpdateKdbResult,
} from '../../services';
import { timestampToDate } from '../../utils/dateExchanger';
import { withEnvironment } from '../extensions/with-environment';
import { createPagination, IPagination, IPaginationSnapshot } from '../pagination/Pagination';
import { createResponse } from '../response/Response';
import { IKdbSnapshot } from '../kdb/Kdb';
import { withRootStore } from '../extensions/with-root-store';
import { selectOptions } from '../../components/forms/CSelect';

export const KdbStore = types
  .model('KdbStore')
  .props({
    kdbs: types.optional(types.array(Kdb), []),
    selectedKdb: createKdb(),
    kbdSidList: types.optional(types.array(types.number), []),
    pagination: createPagination(),
    responseInfo: createResponse(),
    checkDuplicatedSaid: types.optional(types.boolean, false),
  })
  .extend(withEnvironment)
  .extend(withRootStore)
  .views((self) => ({}))
  .actions((self) => ({
    setKdbs: (kdbs: IKdbSnapshot[]) => {
      self.kdbs.replace(kdbs as IKdb[]);
    },
    setPagination: (pagination: IPaginationSnapshot) => {
      self.pagination = pagination as IPagination;
    },
    setSelectedKdb: (kdb: IKdbSnapshot) => {
      self.selectedKdb = kdb as IKdb;
    },
    setDeleteKdbList: (deleteSid: number[]) => {
      self.kbdSidList.replace(deleteSid);
    },
    appendDeleteKdbList: (deleteSid: number) => {
      self.kbdSidList.push(deleteSid);
    },
    setCheckDuplicatedSaid: (checkDuplicatedSaid: boolean) => {
      self.checkDuplicatedSaid = checkDuplicatedSaid;
    },
  }))
  .actions((self) => ({
    /**
     * INF_CAIMS_00112
     * 전체 KDB 목록을 조회한다.
     * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=1031196181
     * @param kdbFilter
     */
    gets: async (kdbFilter?: TKdbFilter) => {
      self.setKdbs([]);
      try {
        const kdbApi: KdbApi = new KdbApi(self.environment.api);
        let result: TGetKdbsResult;

        if (kdbFilter) {
          result = await kdbApi.gets(self.pagination, kdbFilter);
        } else {
          result = await kdbApi.gets(self.pagination);
        }

        if (
          self.rootStore.responseStore.getResponseResult(result) &&
          result.pagination &&
          result.pagination.page === self.pagination.page
        ) {
          if (result.kdbs) {
            result.kdbs.forEach((item: IKdbSnapshot, index: number) => {
              item.regDt = timestampToDate(Number.parseInt(item.regDt));
              item.updDt = timestampToDate(Number.parseInt(item.updDt));
            });

            self.setKdbs(result.kdbs);

            if (result.pagination) {
              self.setPagination(result.pagination);
            }
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },

    /**
     * INF_CAIMS_00113
     * 해당 KDB의 상세정보를 조회한다.
     * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=810774455
     * @param kdbSid
     */
    get: async (kdbSid: number) => {
      try {
        const kdbApi: KdbApi = new KdbApi(self.environment.api);
        const result: TGetKdbResult = await kdbApi.get(kdbSid);

        if (self.rootStore.responseStore.getResponseResult(result)) {
          if (result.kdb) {
            result.kdb.regDt = timestampToDate(Number.parseInt(result.kdb.regDt));
            result.kdb.updDt = timestampToDate(Number.parseInt(result.kdb.updDt));

            self.setSelectedKdb(result.kdb);
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },

    /**
     * INF_CAIMS_00114
     * 새로운 KDB를 등록한다.
     * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=1655752089
     * @param kdb
     */
    post: async (kdb: IKdbSnapshot) => {
      try {
        const kdbApi: KdbApi = new KdbApi(self.environment.api);
        const result: TPostKdbResult = await kdbApi.post(kdb);

        if (self.rootStore.responseStore.getResponseResult(result)) {
          if (result.kdb) {
            result.kdb.regDt = timestampToDate(Number.parseInt(result.kdb.regDt));
            result.kdb.updDt = timestampToDate(Number.parseInt(result.kdb.updDt));

            self.setSelectedKdb(result.kdb);
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },

    /**
     * INF_CAIMS_00115
     * 해당 KDB를 수정한다.
     * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=720957831
     * @param kdb
     */
    put: async (kdb: IKdbSnapshot) => {
      try {
        const kdbApi: KdbApi = new KdbApi(self.environment.api);
        const result: TUpdateKdbResult = await kdbApi.put(kdb);

        if (self.rootStore.responseStore.getResponseResult(result)) {
          if (result.kdb) {
            result.kdb.regDt = timestampToDate(Number.parseInt(result.kdb.regDt));
            result.kdb.updDt = timestampToDate(Number.parseInt(result.kdb.updDt));

            self.setSelectedKdb(result.kdb);
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },

    /**
     * INF_CAIMS_00116
     * 해당 KDB를 삭제한다.
     * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=721135938
     * @param kdbSid
     */
    delete: async (kdbSid: number) => {
      try {
        const kdbApi: KdbApi = new KdbApi(self.environment.api);
        const result: TDeleteKdbResult = await kdbApi.delete(kdbSid);
        self.rootStore.responseStore.getResponseResult(result);
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },

    /**
     * INF_CAIMS_00117
     * 여러개 KDB를 한번에 삭제한다.
     * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=956395104
     */
    deletes: async () => {
      try {
        const kdbApi: KdbApi = new KdbApi(self.environment.api);
        const result: TDeleteKdbResult = await kdbApi.deletes(self.kbdSidList);
        self.rootStore.responseStore.getResponseResult(result);
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },
  }));

type TKdbStore = Instance<typeof KdbStore>;
type TKdbStoreSnapshot = SnapshotOut<typeof KdbStore>;

export interface IKdbStore extends TKdbStore {}
export type TKdbStoreKeys = keyof TKdbStoreSnapshot & string;
export interface IKdbStoreSnapshot extends TKdbStoreSnapshot {}
export const createKdbStore = () => types.optional(KdbStore, {} as TKdbStore);

export interface IKdbFilter {
  kdbSid?: number;
  eventType?: selectOptions;
  instType?: selectOptions;
}

export type TKdbFilter = IKdbFilter;
