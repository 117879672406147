import { useEffect, useState } from 'react';
import Router from './routes';
import ThemeConfig from './theme';
import GlobalStyles from './theme/globalStyles';
import { ProgressBarStyle } from './components/LoadingScreen';
import { RtlLayout } from './components/RtlLayout';
import ScrollToTop from './components/ScrollToTop';
import { Settings } from './components/settings';
import ThemePrimaryColor from './components/ThemePrimaryColor';
import { makeStyles } from '@mui/styles';
import { SnackbarProvider } from 'notistack';
import LoadingAPI from './components/LoadingAPI';
import { AuthProvider } from './contexts/SessionContext';
import { RootStore, RootStoreProvider, setupRootStore } from './models';
import ErrorAlert from './screens/ErrorAlert';
import SnackBarCloseButton from './components/SnackBarCloseButton';
import SoketSnackbar from './screens/SoketSnackBar';
import { GlobalDebug } from './utils/globalDebug';

export function App() {
  //
  const [rootStore, setRootStore] = useState<RootStore | undefined>(undefined);
  const classes = useStyles();
  // Kick off initial async loading actions, like loading fonts and RootStore
  useEffect(() => {
    (async () => {
      setupRootStore().then(setRootStore);
    })();
    if (window.localStorage.getItem('useDebug') !== 'true') {
      (process.env.NODE_ENV === 'production') && GlobalDebug(false, false);
    }
  }, []);
  if (!rootStore) {
    return null;
  } else {
    // rootStore.userStore.login();
    return (
      <RootStoreProvider value={rootStore}>
        <AuthProvider>
          <ThemeConfig>
            <ThemePrimaryColor>
              <RtlLayout>
                <SnackbarProvider
                  autoHideDuration={10000}
                  anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                  classes={{ containerRoot: classes.root }}
                  action={(key) => <SnackBarCloseButton id={key} />}
                >
                  <GlobalStyles />
                  <ProgressBarStyle />
                  {/* <Settings /> */}
                  <ScrollToTop />
                  <Router />
                  <ErrorAlert />
                  <SoketSnackbar />
                  <LoadingAPI />
                </SnackbarProvider>
              </RtlLayout>
            </ThemePrimaryColor>
          </ThemeConfig>
        </AuthProvider>
      </RootStoreProvider>
    );
  }
}

const useStyles = makeStyles(() => ({
  root: {
    '& .SnackbarContent-root': {
      borderRadius: '8px !important',
      color: '#000 !important',
    },
  },
}));
