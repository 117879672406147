import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import {
  ChangeEvent,
  Dispatch,
  MouseEvent,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import CDialog from '../../../components/CDialog';
import { CTableHead } from '../../../components/CTableHead';
import TableRow from '../../../components/CTableRowBodyStyle';
import { Order } from '../../../components/CTableToolbar';
import {
  ALERT_MSG,
  BUTTON_LABEL,
  DIALOG_TYPE,
} from '../../../components/settings/SettingConstEnum';
import { IRealTimeAlarmSnapshot, IRealtimeMonitoringAlarmFilter, useStores } from '../../../models';
import { CallApiToStore } from '../../Home';
import { Button, Checkbox, Chip, Toolbar, Tooltip, Typography, useTheme } from '@mui/material';
import moment from 'moment';
import CAlert from '../../../components/CAlert';
import CButtonSet from '../../../components/CButtonSet';
import CButtonSetItem from '../../../components/CButtonSetItem';
import { formatEventTimeBasic, getEventState } from '../../../utils/commonUtil';
import { AiAlarmsDetail } from '../../oneview/ai_alarms/AiAlarmsDetail';
import { headCells , headCellsChannel} from './RealtimeMonitoringData';
import MenuIcon from '@mui/icons-material/Menu';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';

interface ChannelAlarmTableProps {
  searchData: IRealtimeMonitoringAlarmFilter | undefined;
  isChannelAlarm: boolean
  changeDialogDetail: () => void;
}

export const RealtimeMonitoringTable = observer(function RealtimeMonitoringTable({
  searchData, isChannelAlarm, changeDialogDetail
}: ChannelAlarmTableProps) {
  const rootStore = useStores();
  const theme = useTheme();
  const { realtimeAlarmStore, loadingStore } = rootStore;
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<string>('eventId');
  const [selected, setSelected] = useState<string[]>([]);
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [action, setAction] = useState<string>('');

  // 테이블 핸들링 함수
  // ----------------------------------------------------------------------

  const handleRequestSort = (event: MouseEvent<unknown>, property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: ChangeEvent<HTMLInputElement>) => {
    /**
     * After adding the filter, the selection is not released,
     */
    if (selected.length > 0) {
      setSelected([]);
      return;
    }
    if (event.target.checked) {
      const newSelecteds = realtimeAlarmStore.alarms
        .filter(alarmFilter)
        .filter((item) => item.eventState?.code != 200204)
        .map((n) => n.eventId);
      setSelected(newSelecteds);
      // return;
    }
  };

  const handleClick = async (event: any, row: IRealTimeAlarmSnapshot) => {
    if (event.target.id === 'checkbox') {
      const selectedIndex = selected.indexOf(row.eventId);
      let newSelected: string[] = [];
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, row.eventId);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1),
        );
      }
      setSelected(newSelected);
    } else if (event.detail === 2) {
      await CallApiToStore(realtimeAlarmStore.get(row.eventId), 'api', loadingStore).then(() => {
        changeDialogDetail();
      });
    }
  };

  const isSelected = (id: string) => selected.indexOf(id) !== -1;

  const isItemTerminated = (data: IRealTimeAlarmSnapshot) => {
    if (data.eventState?.code === 200204) {
      return true;
    } else {
      return false;
    }
  };

  const handleAlertClose = () => setIsAlertOpen(false);

  const ackTermi = async (type: string = 'ack') => {
    setAction(type);
    setIsAlertOpen(true);
  };

  const onAckTermi = async () => {
    await CallApiToStore(realtimeAlarmStore.multiProc(selected, action), 'api', loadingStore).then(
      () => {
        setAction('');
        setIsAlertOpen(false);
        setSelected([]);
      },
    );
  };

  const severityChip = (severity: string | null | undefined) => {
    const bg =
      severity === 'Critical'
        ? '#FF0000'
        : severity === 'Major'
        ? '#FFA912'
        : severity === 'Minor'
        ? '#E4FF12'
        : '#12ff4d';

    return (
      <Chip
        size={'small'}
        variant={'outlined'}
        sx={{ borderColor: bg }}
        label={severity?.toUpperCase().substring(0, 2)}
      />
    );
  };

  const tableBox = useRef<HTMLElement>();
  const [boxHeight, setBoxHeight] = useState(0);
  useEffect(() => {
    const timer = setInterval(() => {
      if (tableBox.current) {
        setBoxHeight(tableBox.current.offsetTop + 150);
      }
    }, 500);
    return () => {
      clearInterval(timer);
    };
  }, [tableBox.current?.offsetTop]);

  const alarmFilter = (row: IRealTimeAlarmSnapshot) => {
    if (searchData === undefined) {
      return row;
    }

    const eventType = JSON.stringify(row.eventType?.code);
    const instanceType = JSON.stringify(row.instanceType?.code);

    const perceivedSeverity = row.perceivedSeverity?.code;
    const eventState = row.eventState?.code;
    const probableCause = row.probableCause;

    let res: boolean = true;
    if (searchData.eventTimeSt) {
      const st = moment(searchData.eventTimeSt).format('YYYY-MM-DD HH:mm:ss');
      res = row.eventTime && row.eventTime >= st ? res : false;
    }
    if (searchData.eventTimeEd) {
      const ed = moment(searchData.eventTimeEd).format('YYYY-MM-DD HH:mm:ss');
      res = row.eventTime && row.eventTime <= ed ? res : false;
    }

    if (searchData.clearanceTimeSt) {
      const st = moment(searchData.clearanceTimeSt).format('YYYY-MM-DD HH:mm:ss');
      res = row.clearanceTime && row.clearanceTime >= st ? res : false;
    }
    if (searchData.clearanceTimeEd) {
      const ed = moment(searchData.clearanceTimeEd).format('YYYY-MM-DD HH:mm:ss');
      res = row.clearanceTime && row.clearanceTime <= ed ? res : false;
    }

    if (searchData.mvTimeSt) {
      const st = moment(searchData.mvTimeSt).format('YYYY-MM-DD HH:mm:ss');
      res = row.sourceEventTime && row.sourceEventTime >= st ? res : false;
    }
    if (searchData.mvTimeEd) {
      const ed = moment(searchData.mvTimeEd).format('YYYY-MM-DD HH:mm:ss');
      res = row.sourceEventTime && row.sourceEventTime <= ed ? res : false;
    }

    if (searchData.targetInstanceName && searchData.targetInstanceName.length > 0) {
      res = row.targetInstanceName?.toLowerCase().includes(searchData.targetInstanceName)
        ? res
        : false;
    }
    if (searchData.targetInstanceId && searchData.targetInstanceId.length > 0) {
      res =
        row.targetInstanceId &&
        row.targetInstanceId.toLowerCase().includes(searchData.targetInstanceId)
          ? res
          : false;
    }
    if (searchData.specificProblem) {
      res = row.specificProblem?.toLowerCase().includes(searchData.specificProblem.toLowerCase())
        ? res
        : false;
    }
    if (searchData.message) {
      res = row.message?.toLowerCase().includes(searchData.message.toLowerCase()) ? res : false;
    }
    if (
      perceivedSeverity &&
      searchData.perceivedSeverityList &&
      searchData.perceivedSeverityList.length > 0
    ) {
      res = searchData.perceivedSeverityList.includes(perceivedSeverity) ? res : false;
    }
    if (eventState && searchData.eventStateList && searchData.eventStateList.length > 0) {
      res = searchData.eventStateList.includes(eventState) ? res : false;
    }

    return res;
  };

  const getEventDurationFormat = (durationInSeconds: number | null) => {
    const duration = moment.duration(durationInSeconds, 'seconds');

    // Format the duration as HH:mm:ss
    const formattedDuration = moment.utc(duration.asMilliseconds()).format('HH:mm:ss');

    return formattedDuration;
  };

  return (
    <>
      <Box sx={{ width: '100%' }} ref={tableBox} id="test">
        {tableBox.current && (
          <Paper sx={{ width: '100%', mt: 2, mb: 1 }}>
            {/* <TableContainer sx={{ maxHeight: 'calc(100vh - 300px)' }}> */}

            <TableContainer
              sx={{
                maxHeight: 'calc(100vh - ' + boxHeight + 'px)',
                height: 'calc(100% - ' + boxHeight + 'px)',
              }}
            >
              {selected.length > 0 && (
                <CTableToolbar
                  numSelected={selected.length}
                  actionAck={() => {
                    ackTermi('ack');
                  }}
                  actionTermi={() => {
                    ackTermi('terminate');
                  }}
                />
              )}
              <Table stickyHeader aria-labelledby="tableTitle" size={'medium'}>
                <CTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={realtimeAlarmStore.alarms.length}
                  headCells={isChannelAlarm ? headCellsChannel.filter((x) => x.id !== 'clearanceTime') : headCells.filter((x) => x.id !== 'clearanceTime')}
                  // headCells={headCells}
                  // removeCheckBox={false}
                />
                <TableBody>
                  {realtimeAlarmStore.alarms.filter(alarmFilter).map((row, index) => {
                    const item = toJS(row);
                    const isItemSelected = isSelected(item.eventId);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        onClick={(event) => handleClick(event, item)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        // key={item.eventId}
                        key={`event-${index}`}
                        selected={isItemSelected}
                        sx={{ cursor: 'pointer', userSelect: 'none' }}
                        id={labelId}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            id="checkbox"
                            checked={isItemSelected}
                            disabled={isItemTerminated(item)}
                          />
                        </TableCell>
                        <TableCell align="center">
                          {getEventState(item.eventState?.value)}
                        </TableCell>
                        <TableCell align="center">
                          {severityChip(item.perceivedSeverity?.value)}
                        </TableCell>
                        {isChannelAlarm && <TableCell align="center">{formatEventTimeBasic(item.sourceEventTime)}</TableCell>}

                        <TableCell align="center">{item.instanceType?.value}</TableCell>
                        <TableCell align="center">
                          {item.instanceName}
                          {item.instancePortName && ','} <br />
                          {item.instancePortName}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ paddingTop: '10px', paddingBottom: '10px' }}
                        >
                          {formatEventTimeBasic(item.eventTime)}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ paddingTop: '10px', paddingBottom: '10px' }}
                        >
                          {formatEventTimeBasic(item.clearanceTime)}
                        </TableCell>
                        <TableCell align="center">
                          {getEventDurationFormat(item.eventDuration)}
                        </TableCell>
                        <TableCell align="center">{item.targetInstanceId}</TableCell>
                        <TableCell align="center">{item.targetInstanceName}</TableCell>
                        <TableCell
                          align="left"
                          sx={{
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            maxWidth: '250px',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          {item.message}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            maxWidth: '250px',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          {item.specificProblem}
                        </TableCell>
                        <TableCell align="left">{item.probableCause}</TableCell>
                        {/* <TableCell align="center">{getEventState(item.eventState?.value)}</TableCell> */}
                      </TableRow>
                    );
                  })}
                  {realtimeAlarmStore.alarms.length === 0 && (
                    <TableRow
                      style={{
                        height: 53,
                      }}
                    >
                      <TableCell align="center" sx={{ fontWeight: 600 }} colSpan={14}>
                        알람 내역이 없습니다.
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        )}
      </Box>
      <CAlert
        key={'RealtimeAlarmTable'}
        isAlertOpen={isAlertOpen}
        alertCategory={'question'}
        alertContent={ALERT_MSG.common.update}
        // alertTitle={'PAGE_TITLE'}
        callBack={onAckTermi}
        handleAlertClose={handleAlertClose}
        hasCancelButton
      />
    </>
  );
});

interface CTableToolbarProps {
  numSelected: number;
  tableTitle?: string;
  buttonSet?: React.ReactNode;
  actionAck: () => void;
  actionTermi: () => void;
}

export const CTableToolbar = (props: CTableToolbarProps) => {
  const { numSelected, tableTitle, buttonSet, actionAck, actionTermi } = props;

  return (
    <Toolbar
      sx={{
        mt: 0,
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0
          ? {
              // bgcolor: (theme) =>
              //   alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
            }
          : {
              bgcolor: (theme) => theme.palette.background.default,
            }),
      }}
    >
      {numSelected > 0 ? (
        <Typography sx={{ flex: '1 1 100%' }} color="inherit" variant="subtitle1" component="div">
          {numSelected} selected
        </Typography>
      ) : (
        <Typography sx={{ flex: '1 1 100%' }} variant="h6" id="tableTitle" component="div">
          {tableTitle}
        </Typography>
      )}
      {numSelected > 0 ? (
        <CButtonSet>
          <CButtonSetItem onClick={actionAck}>{BUTTON_LABEL.ACK}</CButtonSetItem>
          <CButtonSetItem onClick={actionTermi} color={'error'}>
            {BUTTON_LABEL.TERMINATE}
          </CButtonSetItem>
        </CButtonSet>
      ) : (
        <>{buttonSet}</>
      )}
    </Toolbar>
  );
};
