import { Grid, GridSize } from '@mui/material';
import { observer } from 'mobx-react-lite';
import {SxProps} from "@mui/system";
import {Theme} from "@mui/material/styles";

interface CFormSetItemProps {
  children?: React.ReactNode;
  grid?: boolean | GridSize | undefined;
  sx?: SxProps<Theme>;
}

/**
 * ## 기본 사용법
 *
 * > CFormSetItem 컴포넌트입니다.
 * > 여러 개의 입력 폼 세트가 필요한 경우 코드를 복사하여 새로운 컴포넌트를 생성합니다.
 * >
 * > 컴포넌트 네이밍은 해당 서비스명 뒤에 "FormSet"을 붙여서 사용합니다
 * > 예) VOCFormSet, AlarmFormSet
 *>
 * > ### Common Style
 * > * **formSetLabelStyle** : Form의 Label에 사용합니다.
 * > * **searchRowStyle**: tr 태그에 사용합니다.
 *
 */
const CFormSetItem = observer(function CFormSetItemProps({
  grid,
  children,
  sx,
  ...other
}: CFormSetItemProps) {
  return (
    <Grid
      item
      xs={grid ? grid : 1}
      sm={grid ? grid : 1}
      md={grid ? grid : 1}
      lg={grid ? grid : 1}
      sx={sx ? sx : { paddingTop: '0 !important' }}
    >
      {children}
    </Grid>
  );
});

export default CFormSetItem;
