import { ApiResponse } from 'apisauce';
import { Api } from '../api/api';
import { getGeneralApiProblem } from '../api/api-problem';
import {
  IResponseSnapshot,
  IWatchStatistics,
  IWatchStatisticsSnapshot,
  TWatchStatisticsFilter,
} from '../../models';
import { TGetWatchStatisticsResultForOneview, TGetWatchStatisticssDataResult, TGetWatchStatisticssResult } from './WatchStatisticsTypes';
import { dateToTimestamp, timestampToDate } from '../../utils/dateExchanger';

/**
 * # WatchStatistics Api 서비스
 *
 * 서비스의 설명을 작성하세요.
 *
 * ## 사용방법
 *
 * ```ts
 * const service = new WatchStatisticsApi();
 *
 * ```
 */
export class WatchStatisticsApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  async getWatchStatisticss(filters?: TWatchStatisticsFilter): Promise<TGetWatchStatisticsResultForOneview> {
    try {
      let regionList: string = ``;
      let params: any = {};
      let statType: number = 0;
      const filter = { ...filters };

      if (filter.statType) {
        statType = filter.statType;
        //@ts-ignore
        delete filter.statType;
        params = filter;
      }

      if (params) {
        params.statDtSt = dateToTimestamp(params.statDtSt);
        params.statDtEd = dateToTimestamp(params.statDtEd);
      }

      const url = `/oneview/mbs/views`;

      //@ts-ignore
      delete params.regionList;

      const response: ApiResponse<any> = await this.api.apisauce.get(url, params);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;

      return {
        kind: 'ok',
        data: response.data.data,
        responseInfo: responseInfo,
      };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async getRealtimeRating(): Promise<TGetWatchStatisticssDataResult> {
    try {
      const url = `/oneview/mbs/ratings`;

      const response: ApiResponse<any> = await this.api.apisauce.get(url);

      // console.log('response',response)

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;

      return {
        kind: 'ok',
        data: response.data.data,
        responseInfo: responseInfo,
      };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }
}
