import { Instance, SnapshotOut, types } from 'mobx-state-tree';

export const Advm = types
  .model('Advm')
  .props({
    skylifeAdvmSpatnYn: types.optional(types.boolean, false),
    hcnAdvmSpatnYn: types.optional(types.boolean, false),
    ktQtoneAPid: types.optional(types.maybeNull(types.number), 0),
    ktQtoneBPid: types.optional(types.maybeNull(types.number), 0),
    ktQtoneDPid: types.optional(types.maybeNull(types.number), 0),
    skylifeQtoneBPid: types.optional(types.maybeNull(types.number), 0),
    skylifeQtoneDPid: types.optional(types.maybeNull(types.number), 0),
    skylifeQtoneAPid: types.optional(types.maybeNull(types.number), 0),
    hcnQtoneDPid: types.optional(types.maybeNull(types.number), 0),
    hcnQtoneAPid: types.optional(types.maybeNull(types.number), 0),
    hcnQtoneBPid: types.optional(types.maybeNull(types.number), 0),
  })
  .actions((self) => ({
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as IAdvm;
      (Object.keys(newProps) as TAdvmKeys[]).forEach((key) => {
        //@ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

type TAdvm = Instance<typeof Advm>;
type TAdvmSnapshot = SnapshotOut<typeof Advm>;

export interface IAdvm extends TAdvm {}
export type TAdvmKeys = keyof TAdvmSnapshot & string;
export interface IAdvmSnapshot extends TAdvmSnapshot {}
export const createAdvm = () =>
  types.optional(Advm, {
    skylifeAdvmSpatnYn: false,
    hcnAdvmSpatnYn: false,
    ktQtoneAPid: null,
    ktQtoneBPid: null,
    ktQtoneDPid: null,
    skylifeQtoneBPid: null,
    skylifeQtoneDPid: null,
    skylifeQtoneAPid: null,
    hcnQtoneDPid: null,
    hcnQtoneAPid: null,
    hcnQtoneBPid: null,
  } as TAdvm);
