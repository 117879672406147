import { Stack, StackProps } from '@mui/material';
import { observer } from 'mobx-react-lite';

interface ICButtonSetProps extends StackProps {
  children: React.ReactNode;
}

/**
 * ## 기본 사용법
 *
 * > 여러 개의 버튼을 생성해야하는 경우 사용합니다.
 *
 */

const CButtonSet = observer(function ICButtonSetProps({ children, ...other }: ICButtonSetProps) {
  return (
    <Stack direction="row" spacing={2} {...other}>
      {children}
    </Stack>
  );
});

export default CButtonSet;
