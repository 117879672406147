import { createModelCode } from './../model-code/ModelCode';
import { createSimpleUser } from './../simple-user/SimpleUser';
import { Instance, SnapshotOut, types } from 'mobx-state-tree';

/**
 * # PairAlarm
 *
 * PairAlarm을 설명하세요.
 */
export const PairAlarm = types
  .model('PairAlarm')
  // --------------------------------------------------------------------------
  .props({
    pairAlarmSid: types.optional(types.number, 0),
    occEventTypeSid: types.optional(types.number, 0),
    occEventType: types.maybeNull(createModelCode()),
    occProblCause: types.optional(types.maybeNull(types.string), null),
    occSpcfProbl: types.optional(types.maybeNull(types.string), null),
    trmnEventTypeSid: types.optional(types.maybeNull(types.number), null),
    trmnEventType: types.maybeNull(createModelCode()),
    trmnProblCause: types.optional(types.maybeNull(types.string), null),
    trmnSpcfProbl: types.optional(types.maybeNull(types.string), null),
    descr: types.optional(types.maybeNull(types.string), null),
    delYn: types.optional(types.maybeNull(types.boolean), null),

    regDt: types.optional(types.maybeNull(types.string), null),
    regUser: types.maybeNull(createSimpleUser()),
    updDt: types.optional(types.maybeNull(types.string), null),
    updUser: types.maybeNull(createSimpleUser()),
  })
  // --------------------------------------------------------------------------
  // MUTATEs ONLY! - 모델 상태를 변경
  // REQUESTs는 스토어 모델에 작성하세요.
  // ex) yarn gen model pairAlarm --store
  .actions((self) => ({
    /*
     * 여러 prop을 한번에 수정
     */
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as IPairAlarm;
      (Object.keys(newProps) as TPairAlarmKeys[]).forEach((key) => {
        //@ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type TPairAlarm = Instance<typeof PairAlarm>;
type TPairAlarmSnapshot = SnapshotOut<typeof PairAlarm>;

export interface IPairAlarm extends TPairAlarm {}
export type TPairAlarmKeys = keyof TPairAlarmSnapshot & string;
export interface IPairAlarmSnapshot extends TPairAlarmSnapshot {}
export const createPairAlarm = () =>
  types.optional(PairAlarm, {
    // occEventTypeSid: types.optional(types.maybeNull(types.number), null),
    // occSpcfProbl: types.optional(types.maybeNull(types.string), null),
    // trmnEventTypeSid: types.optional(types.maybeNull(types.number), null),
    // trmnSpcfProbl: types.optional(types.maybeNull(types.string), null),
    // descr: types.optional(types.maybeNull(types.string), null),
    // delYn: types.optional(types.maybeNull(types.number), null),
    // regDt: types.optional(types.maybeNull(types.string), null),
    // regUser: types.maybeNull(createSimpleUser()),
    // updDt: types.optional(types.maybeNull(types.string), null),
    // updUser: types.maybeNull(createSimpleUser()),
    pairAlarmSid: 0,
    occEventTypeSid: 0,
    occSpcfProbl: '',
    trmnEventTypeSid: 0,
    trmnSpcfProbl: '',
    descr: '',
    delYn: false,
    regDt: '',
    regUser: null,
    updDt: '',
    updUser: null,
  } as TPairAlarm);

export interface IPairAlarmFilter {
  //     occEventTypeSid		발생ID		Number
  // occSpcfProbl		발생원인		String
  // trmnEventTypeSid		해지ID		Number
  // trmnSpcfProbl		해지원인		String
  // page		조회 페이지 번호		Number
  // size		조회 개수		Number
  occEventType?: number;
  occProblCause?: string;
  occSpcfProbl?: string;
  trmnEventType?: number;
  trmnProblCause?: string;
  trmnSpcfProbl?: string;
}
