import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { createModelCode, ModelCode } from '../model-code/ModelCode';
import { createSimpleUser } from '../simple-user/SimpleUser';

export const Port = types
  .model('Port')
  .props({
    category: types.optional(types.maybeNull(types.string), null),
    equipSid: types.optional(types.number, 0),
    portSid: types.optional(types.maybeNull(types.number), null),
    portNm: types.optional(types.string, ''),
    portIp: types.optional(types.maybeNull(types.string), ''),
    idx: types.optional(types.maybeNull(types.number), 0),
    portType: types.maybeNull(createModelCode()),
    portRole: types.maybeNull(createModelCode()),
    portSttus: types.maybeNull(createModelCode()),
    portDescr: types.maybeNull(types.optional(types.string, '')),

    regDt: types.optional(types.string, ''),
    updDt: types.optional(types.string, ''),

    regUser: types.maybeNull(createSimpleUser()),
    updUser: types.maybeNull(createSimpleUser()),

    hostNm: types.optional(types.maybeNull(types.string), null),

    dstPortSid: types.optional(types.maybeNull(types.number), null),
    dstPortNm: types.optional(types.maybeNull(types.string), null),
    dstEquipSid: types.optional(types.maybeNull(types.number), null),
    dstEquipNm: types.optional(types.maybeNull(types.string), null),
  })
  .actions((self) => ({
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as IPort;
      (Object.keys(newProps) as TPortKeys[]).forEach((key) => {
        //@ts-ignore
        self[key] = newProps[key];
      });
    },
    resetPort: () => {
      self.portSid = null;
      self.portNm = '';
      self.portIp = '';
      self.idx = 0;
      self.portDescr = '';
      self.portType = ModelCode.create({}, createModelCode());
      self.portRole = ModelCode.create({}, createModelCode());
      self.portSttus = ModelCode.create({}, createModelCode());
    },
  }));

type TPort = Instance<typeof Port>;
type TPortSnapshot = SnapshotOut<typeof Port>;

export interface IPort extends TPort {}
export type TPortKeys = keyof TPortSnapshot & string;
export interface IPortSnapshot extends TPortSnapshot {}
export const createPort = () =>
  types.optional(Port, {
    portSid: null,
    portNm: '',
    portIp: '',
    idx: 0,
    portDescr: '',
  } as TPort);
