import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { createSimpleUser } from '../simple-user/SimpleUser';

/**
 * # History
 *
 * History을 설명하세요.
 */
export const History = types
  .model('History')
  // --------------------------------------------------------------------------
  // recvDt	요청된일시	Number
  // transacId	트랜젝션ID	UUID
  // host	호스트명	String
  // clntAddr	클라이언트 주소	String
  // method	HTTP메소드	String
  // uri	URI	String
  // sucesYn	성공여부	Boolean
  // reqBody	요청BODY	String
  // httpStatus	HTTP상태 숫자	Number
  // errCd	에러코드	String
  // errMsg	에러메시지	String
  // respLen	응답컨텐츠길이	Number
  // elapsMs	소요시간	Number
  .props({
    recvDt: types.optional(types.string, ''),
    transacId: types.optional(types.string, ''),
    host: types.optional(types.string, ''),
    clntAddr: types.optional(types.string, ''),
    method: types.optional(types.string, ''),
    uri: types.optional(types.string, ''),
    sucesYn: types.optional(types.boolean, false),
    sucesYnKr: types.optional(types.string, ''),
    reqBody: types.optional(types.maybeNull(types.string), ''),
    httpStatus: types.optional(types.number, 0),
    errCd: types.optional(types.maybeNull(types.string), ''),
    errMsg: types.optional(types.maybeNull(types.string), ''),
    respLen: types.optional(types.number, 0),
    elapsMs: types.optional(types.number, 0),
    regDt: types.optional(types.maybeNull(types.string), ''),
    regUser: types.maybeNull(createSimpleUser()),
  })
  // --------------------------------------------------------------------------
  // MUTATEs ONLY! - 모델 상태를 변경
  // REQUESTs는 스토어 모델에 작성하세요.
  // ex) yarn gen model history --store
  .actions((self) => ({
    /*
     * 여러 prop을 한번에 수정
     */
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as IHistory;
      (Object.keys(newProps) as THistoryKeys[]).forEach((key) => {
        //@ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type THistory = Instance<typeof History>;
type THistorySnapshot = SnapshotOut<typeof History>;

export interface IHistory extends THistory {}
export type THistoryKeys = keyof THistorySnapshot & string;
export interface IHistorySnapshot extends THistorySnapshot {}
export const createHistory = () =>
  types.optional(History, {
    recvDt: '',
    transacId: '',
    host: '',
    clntAddr: '',
    method: '',
    uri: '',
    sucesYn: false,
    sucesYnKr: '',
    reqBody: '',
    httpStatus: 0,
    errCd: '',
    errMsg: '',
    respLen: 0,
    elapsMs: 0,
    regDt: '',
  } as THistory);

export interface IHistoryFilter {
  transacId?: string;
  uri?: string;
  method?: number;
  sucesYn?: boolean;
  host?: string;
  errCd?: string;
  recvDtSt?: number | string;
  recvDtEt?: number | string;
  //   transacId		트랜젝션ID		UUID
  // uri		URI		String
  // httpStatus		HTTP상태 숫자		Number
  // sucesYn		성공여부		Boolean
  // errCd		에러코드		String
  // recvDtSt		요청된 일시 - 시작일시		Number
  // recvDtEt		요청된 일시 - 종료일시		Number
}
