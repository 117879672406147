import { ApiResponse } from "apisauce";
import { Api } from "../api/api";
import { getGeneralApiProblem } from "../api/api-problem";
import { IQuetoneGraph } from '../../models';
import { 
  TGetQuetoneGraphsResult,
  TGetQuetoneGraphResult,
  TPostQuetoneGraphResult,
  TUpdateQuetoneGraphResult,
  TDeleteQuetoneGraphResult
 } from "./QuetoneGraphTypes";

  /**
  * # QuetoneGraph Api 서비스
  * 
  * 서비스의 설명을 작성하세요.
  * 
  * ## 사용방법
  * 
  * ```ts
  * const service = new QuetoneGraphApi();
  * 
  * ```
  */
 export class QuetoneGraphApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  async getQuetoneGraphs(): Promise<TGetQuetoneGraphsResult> {
    try {
      const url = '/quetone_graph';
      const params = {};
      const response: ApiResponse<any> = await this.api.apisauce.get( url, params );
  
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const quetoneGraphs: IQuetoneGraph[] = response.data.results;
      return { kind: "ok", quetoneGraphs };

    } catch (e) {
      return { kind: "bad-data" };
    }
  }

  async getQuetoneGraph(chSid:string, chNm:string, quetoneType:string): Promise<TGetQuetoneGraphResult> {
    try {
      const url = `/quetone/graph/${quetoneType}`;
      const params = {
          chSid: Number(chSid),
          chNm: chNm
      };
      const response: ApiResponse<any> = await this.api.apisauce.get( url, params );
  
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const quetoneGraph: IQuetoneGraph = response.data.data;
      return { kind: "ok", quetoneGraph };

    } catch (e) {
      return { kind: "bad-data" };
    }
  }

  // async postQuetoneGraph(): Promise<TPostQuetoneGraphResult> {
  //   try {
  //     const url = '/quetone_graph';
  //     const payload = {};
  //     const response: ApiResponse<any> = await this.api.apisauce.post( url, payload );
  //
  //     if (!response.ok) {
  //       const problem = getGeneralApiProblem(response);
  //       if (problem) return problem;
  //     }
  //
  //     const quetoneGraph: IQuetoneGraph = response.data.results;
  //     return { kind: "ok", quetoneGraph };
  //
  //   } catch (e) {
  //     return { kind: "bad-data" };
  //   }
  // }
  //
  // async updateQuetoneGraph(id:number): Promise<TUpdateQuetoneGraphResult> {
  //   try {
  //     const url = `/quetone_graph/${id}`;
  //     const payload = {};
  //     const response: ApiResponse<any> = await this.api.apisauce.put( url, payload );
  //
  //     if (!response.ok) {
  //       const problem = getGeneralApiProblem(response);
  //       if (problem) return problem;
  //     }
  //
  //     const quetoneGraph: IQuetoneGraph = response.data.results;
  //     return { kind: "ok", quetoneGraph };
  //
  //   } catch (e) {
  //     return { kind: "bad-data" };
  //   }
  // }
  //
  // async deleteQuetoneGraph(id:number): Promise<TDeleteQuetoneGraphResult> {
  //   try {
  //     const url = `/quetone_graph/${id}`;
  //     const payload = {};
  //     const response: ApiResponse<any> = await this.api.apisauce.delete( url, payload );
  //
  //     if (!response.ok) {
  //       const problem = getGeneralApiProblem(response);
  //       if (problem) return problem;
  //     }
  //
  //     return { kind: "ok" };
  //
  //   } catch (e) {
  //     return { kind: "bad-data" };
  //   }
  // }
}