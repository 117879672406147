import { Instance, SnapshotOut, types } from 'mobx-state-tree';

export const Encod = types
  .model('Encod')
  .props({
    equipSid: types.optional(types.number, 0),
    mainYn: types.optional(types.boolean, false),
    hostNm: types.optional(types.maybeNull(types.string), null),
    mgmtIp: types.optional(types.maybeNull(types.string), null),
    modelNm: types.optional(types.maybeNull(types.string), null),
    frmwrVer: types.optional(types.maybeNull(types.string), null),
    equipLo: types.optional(types.maybeNull(types.string), null),
    equipMngr:  types.optional(types.maybeNull(types.string), null),
    no: types.optional(types.maybeNull(types.string), null),
    ccYn: types.optional(types.boolean, false),
    sorcIp30: types.optional(types.maybeNull(types.string), null),
    gw: types.optional(types.maybeNull(types.string), null),
  })

  .actions((self) => ({
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as IEncod;
      (Object.keys(newProps) as TEncodKeys[]).forEach((key) => {
        //@ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

type TEncod = Instance<typeof Encod>;
type TEncodSnapshot = SnapshotOut<typeof Encod>;

export interface IEncod extends TEncod {}
export type TEncodKeys = keyof TEncodSnapshot & string;
export interface IEncodSnapshot extends TEncodSnapshot {}
export const createEncod = () =>
  types.optional(Encod, {
    equipSid: 0,
    mainYn: false,
    hostNm: '',
    mgmtIp: '',
    no: null,
    ccYn: false,
    sorcIp30: null,
    gw: null,
    modelNm: null,
    frmwrVer: null,
  } as TEncod);
