import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { withRootStore } from './../extensions/with-root-store';
import { withEnvironment } from '../extensions/with-environment';
import { QuetoneApi } from '../../services';
import { OneviewDashboardApi } from '../../services/oneview-dashboard/OneviewDashboard';
import { GetDashboardChCountResult } from '../../services/oneview-dashboard/OneviewDashboardTypes';

/**
 * Model description here for TypeScript hints.
 */

export const OneviewDashboardStoreModel = types
  .model('OneviewDashboardStore')
  .props({
    uhd: types.optional(types.maybeNull(types.number), 0),
    hd: types.optional(types.maybeNull(types.number), 0),
    audio: types.optional(types.maybeNull(types.number), 0),
    qtoneA: types.optional(types.maybeNull(types.number), 0),
    qtoneB: types.optional(types.maybeNull(types.number), 0),
    qtoneD: types.optional(types.maybeNull(types.number), 0),
    qtoneTotal: types.optional(types.maybeNull(types.number), 0),
    thumbnail: types.optional(types.maybeNull(types.number), 0),
    stiching: types.optional(types.maybeNull(types.number), 0),
  })
  .extend(withEnvironment)
  .extend(withRootStore)
  .views((self) => ({}))
  .actions((self)=>({
    setQtoneA: (qtoneA: number | null) =>{ 
        self.qtoneA = qtoneA
    },
    setQtoneB: (qtoneB: number | null) =>{
        self.qtoneB = qtoneB
    },
    setQtoneD: (qtoneD: number | null) =>{
        self.qtoneD = qtoneD
    },
    setQtoneTotal: (qtoneTotal: number | null) =>{
        self.qtoneTotal = qtoneTotal
    },
    setUhd: (uhd: number | null) =>{
        self.uhd = uhd
    },
    setHd: (hd: number | null) =>{
        self.hd = hd
    },
    setAudio: (audio: number | null) =>{
        self.audio = audio
    },
    setStiching: (stiching: number | null) =>{
        self.stiching = stiching
    },
  }))
  .actions((self) => ({
    getQtone: async()=>{
        try{
            const oneviewDashboardApi: OneviewDashboardApi = new OneviewDashboardApi(self.environment.api)
            const result: GetDashboardChCountResult = await oneviewDashboardApi.getOneviewDashboardChCount();

            if(self.rootStore.responseStore.getResponseResult(result)){               
                if(!result.chCount){
                    return
                }
                if(result.chCount){
                    let resultCount: OneviewDashboardStoreSnapshot;
                    resultCount = result.chCount
                    
                    self.setQtoneA(resultCount.qtoneA)
                    self.setQtoneB(resultCount.qtoneB)
                    self.setQtoneD(resultCount.qtoneD)
                    self.setQtoneTotal(resultCount.qtoneTotal)
                    
                    self.setUhd(resultCount.uhd)
                    self.setHd(resultCount.hd)
                    self.setAudio(resultCount.audio)
                    self.setStiching(resultCount.stiching)
                }
            }

        }catch (e){
            self.rootStore.responseStore.errorProcessing(e);
        }
    }
  }));


type OneviewDashboardStoreType = Instance<typeof OneviewDashboardStoreModel>
export interface OneviewDashboard extends OneviewDashboardStoreType {}
type OneviewDashboardStoreSnapshotType = SnapshotOut<typeof OneviewDashboardStoreModel>
export interface OneviewDashboardStoreSnapshot extends OneviewDashboardStoreSnapshotType {} 


