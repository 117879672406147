import { FormControl, FormControlLabel, FormHelperText, Switch, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { SwitchStyle } from '../settings/SettingStyle';

interface CSwitchProps {
  /** **라벨** */
  label: string;
  /** ** 리액트 훅 폼 필드명** */
  name: string;
  /** **비활성화 여부** */
  disabled?: boolean;
  /** **수정금지 여부** */
  readonly?: boolean;
  /** **변경 함수** */
  onChangeCallback?: (e: any) => void;
  /** **수정금지 여부** */
  justifyContent?: string;

  defaultValue?: boolean | any;

  sx?: any;
}

/**checkSwitch
 * ## 기본 사용법
 *
 * > 스위치
 *
 */
const CSwitch = observer(function CSwitchProps({
  label,
  name,
  disabled = false,
  readonly = false,
  onChangeCallback,
  justifyContent = 'flex-end',
  defaultValue= false,
  sx = null
}: CSwitchProps) {
  const { control, setValue } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => {
        if (value === undefined) {
          setValue(name, false); 
          // value = false;
        }
        return (
          <FormControl sx={ sx ? { ...SwitchStyle, ...sx} : SwitchStyle}>
            <FormControlLabel
              disabled={disabled}
              sx={{ justifyContent: justifyContent, ml: 0, pt: 1 }}
              labelPlacement="start"
              control={
                <Switch
                  readOnly={readonly}
                  onBlur={onBlur}
                  checked={value}
                  onChange={(e) => {
                    onChange({ target: { name, value: readonly ? value : !value } });
                    onChangeCallback && onChangeCallback({ target: { name, value: readonly ? value : !value }});
                  }}
                  color={'secondary'}
                />
              }
              label={<Typography sx={{ fontSize: '1rem' }}>{label}</Typography>}
            />
            <FormHelperText error>{error?.message}</FormHelperText>
          </FormControl>
        );
      }}
    />
  );
});

export default CSwitch;
