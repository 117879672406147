import {
  Paper,
  Divider,
  Typography,
  styled,
  Stack,
  IconButton,
  TableBody,
  TableRow,
} from '@mui/material';
import Box from '@mui/material/Box';
import _ from 'lodash';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { DropzoneOptions, useDropzone } from 'react-dropzone';
import { useForm } from 'react-hook-form';

import CButtonSet from '../../../components/CButtonSet';
import CButtonSetItem from '../../../components/CButtonSetItem';
import CSwitch from '../../../components/forms/CSwitch';
import { FormProvider } from '../../../components/hook-form';
import { BUTTON_LABEL } from '../../../components/settings/SettingConstEnum';
import { RealTimeAlarmStore, useStores } from '../../../models';
import AudioFileIcon from '@mui/icons-material/AudioFile';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { IAlarmSetupSnapshot } from '../../../models/alarm-store/AlarmSetup';
import { Table } from '@mui/material';
import { CTableHead } from '../../../components/CTableHead';
import { headCellsAlarmSetup } from './AlarmSetupData';
import { TableCell } from '@mui/material';
import { CallApiToStore } from '../../Home';

interface DialogProps {
  handleCloseDialog: () => void;
  monitorType: string;
}

export const AlarmSetup = observer(({ handleCloseDialog, monitorType }: DialogProps) => {
  const rootStore = useStores();
  // const theme = useTheme();
  const { realtimeAlarmStore, loadingStore } = rootStore;
  //   const { alarmStore, loadingStore } = rootStore;

  const { REACT_APP_API_URL } = process.env;

  const methods = useForm<IAlarmSetupSnapshot>({});

  const {
    getValues,
    setValue,
    formState: { errors },
    handleSubmit,
    control,
  } = methods;

  const [files, setFiles] = useState<File[]>([]);

  const [list, setList] = useState<IAlarmSetupSnapshot[]>([]);

  const getAlarmSetup = () => {
    CallApiToStore(realtimeAlarmStore.getAlarmSetup(monitorType), 'api', loadingStore).then(() => {
      setList(realtimeAlarmStore.alarmSetup as IAlarmSetupSnapshot[]);
    });
  }

  useEffect(() => {
    getAlarmSetup()
  }, []);

  const onSubmit = () => {
    if (_.isEmpty(errors)) {
      CallApiToStore(realtimeAlarmStore.updateAlarmSetup(list, files), 'api', loadingStore).then(
        () => {
          handleCloseDialog();
          // const newList = JSON.parse(JSON.stringify(list));
          // realtimeAlarmStore.setAlarmSetup(newList);
          getAlarmSetup()
          realtimeAlarmStore.getAllAlarmSounds(monitorType)
          //   realtimeAlarmStore.setUpdated();
        },
      );
    }
  };

  const handleDrop = (file: any, svrtCode: number | null | undefined) => {
    let newFiles: File[] = [...files, file];
    const newList = [...list];

    newList.forEach((item: IAlarmSetupSnapshot, i: number) => {
      if (item.svrt?.code == svrtCode) {
        newList.splice(i, 1, {
          ...item,
          // fileName: file.name,
          uploadFile: URL.createObjectURL(file),
        } as IAlarmSetupSnapshot);
      }
    });

    setList(newList);
    setFiles(newFiles);
  };

  const selectCancel = (svrtCode: number | null | undefined) => {
    const newList = [...list];
    newList.forEach((item: IAlarmSetupSnapshot, i: number) => {
      if (item.svrt?.code == svrtCode) {
        newList.splice(i, 1, {
          ...item,
          uploadFile: null,
        } as IAlarmSetupSnapshot);
      }
    });
    setList(newList);
  };

  return (
    <Box sx={{ height: '100%' }}>
      <FormProvider
        methods={methods}
        sx={{ flex: '1', height: '100%' }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Paper sx={{ flex: '1', height: '100%' }}>
          <Table stickyHeader aria-labelledby="tableTitle" size={'medium'}>
            <CTableHead
              headCells={headCellsAlarmSetup}
              removeCheckBox={false}
            />
            <TableBody>
              {list.map((row, index) => {
                const item = toJS(row);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    tabIndex={-1}
                    // key={item.eventId}
                    key={`event-${index}`}
                    // selected={isItemSelected}
                    sx={{ cursor: 'pointer', userSelect: 'none' }}
                    id={labelId}
                  >
                    <TableCell
                      align="center"
                      sx={{
                        paddingTop: '10px',
                        paddingBottom: '10px',
                        paddingLeft: '24px !important',
                        paddingRight: '0px',
                      }}
                    >
                      {item.svrt?.value}
                    </TableCell>
                    <TableCell align="center" sx={{ maxWidth: '200px' }}>
                      <audio
                        src={
                          REACT_APP_API_URL +
                          '/event/sound/monitorType/' +
                          monitorType +
                          '/svrt/' +
                          item.svrt?.code
                        }
                        autoPlay={false}
                        controls
                        controlsList="nodownload noplaybackrate"
                        style={{
                          height: '28px',
                          width: '200px',
                          display: 'table-cell',
                          verticalAlign: 'middle',
                        }}
                      />
                    </TableCell>
                    <TableCell align="center">{item.fileName}</TableCell>
                    <TableCell align="center">
                      <CSwitch
                        // key={'useYn-' + params.row.id}
                        label={''}
                        name={'useYn-' + item.svrt?.code}
                        // sx={{ ml: -3 }}
                        defaultValue={item.useYn || undefined}
                        onChangeCallback={(e: any) => {
                          const newList = [...list];
                          newList.forEach((el: IAlarmSetupSnapshot, i: number) => {
                            if (el.svrt?.code == item.svrt?.code) {
                              newList.splice(i, 1, {
                                ...el,
                                useYn: e.target.value,
                              } as IAlarmSetupSnapshot);
                            }
                          });
                          setList(toJS(newList));
                        }}
                      ></CSwitch>
                    </TableCell>
                    <TableCell align="center" sx={{ maxWidth: '200px' }}>
                      <Box
                        sx={{
                          width: '100%',
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                        }}
                      >
                        {item.uploadFile !== null ? (
                          <>
                            <audio
                              src={item.uploadFile}
                              autoPlay={true}
                              controls
                              style={{ height: '24px' }}
                            />
                            <IconButton
                              onClick={(e) => {
                                selectCancel(item?.svrt?.code);
                              }}
                            >
                              <DeleteForeverIcon />
                            </IconButton>
                          </>
                        ) : (
                          <AlarmFileUpload
                            onDrop={(file: File[]) => {
                              handleDrop(file[0], item.svrt?.code as number);
                            }}
                          />
                        )}
                      </Box>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Paper>
      </FormProvider>
      <Box className="modalButton" sx={{ marginTop: 2, width: '100%' }}>
        <Divider></Divider>
        <CButtonSet justifyContent={'flex-end'} sx={{ pt: 2 }}>
          <CButtonSetItem onClick={onSubmit}>{BUTTON_LABEL.SAVE}</CButtonSetItem>
        </CButtonSet>
      </Box>
    </Box>
  );
});

export default AlarmSetup;

const DropZoneStyle = styled('div')(({ theme }) => ({
  outline: 'none',
  display: 'flex',
  textAlign: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(1, 3),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.neutral,
  border: `1px dashed ${theme.palette.grey[500_32]}`,
  opacity: 0.9,
  '&:hover': { opacity: 1, cursor: 'pointer' },
  [theme.breakpoints.up('md')]: { textAlign: 'left', flexDirection: 'row' },
}));

interface Props extends DropzoneOptions {
  error?: boolean;
  onRemove?: (file: File) => void;
}

const AlarmFileUpload = ({ error, onRemove, ...other }: Props) => {
  const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
    ...other,

    multiple: false,
    maxFiles: 1,
    noDrag: true,
    noKeyboard: true,
    accept: 'audio/*',
  });

  return (
    <DropZoneStyle {...getRootProps()}>
      <input {...getInputProps()} />
      <Stack direction={'row'} spacing={1} alignContent={'center'} alignItems={'center'}>
        <AudioFileIcon sx={{ width: 18 }} />
        <Typography variant="subtitle2">파일 선택해주세요</Typography>
      </Stack>
    </DropZoneStyle>
  );
};
