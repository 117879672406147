import { Instance, SnapshotOut, types } from "mobx-state-tree"

/**
 * # Mobile
 * 
 * Mobile을 설명하세요.
 */
export const Mobile = types
  .model("Mobile")
  // --------------------------------------------------------------------------
  .props({
      mobileSid: types.optional(types.number, 0),
      usrgrpSid: types.optional(types.number, 0),
      mobileNo: types.optional(types.maybeNull(types.string), ""),
      name: types.optional(types.maybeNull(types.string), ""),
      etc: types.optional(types.maybeNull(types.string), ""),
  })
  // --------------------------------------------------------------------------
  // MUTATEs ONLY! - 모델 상태를 변경
  // REQUESTs는 스토어 모델에 작성하세요.
  // ex) yarn gen model mobile --store
  .actions((self) => ({
    /*
     * 여러 prop을 한번에 수정
     */
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as IMobile;
      (Object.keys(newProps) as TMobileKeys[]).forEach((key) => {
        // @ts-ignore
          self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type TMobile = Instance<typeof Mobile>;
type TMobileSnapshot = SnapshotOut<typeof Mobile>

export interface IMobile extends TMobile {}
export type TMobileKeys = keyof TMobileSnapshot & string;
export interface IMobileSnapshot extends TMobileSnapshot {}
export const createMobile = () => 
  types.optional(Mobile, {
  } as TMobile);
