import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { selectOptions } from '../../components/forms/CSelect';

// --------------------------------------------------------------------------
/**
 * dataList items 규격
 */
export const RatingStatisticsData = types
  .model('RatingStatisticsData')
  .props({
    region: types.optional(types.maybeNull(types.string), ''),
    cnt: types.optional(types.maybeNull(types.number), null),
    preCnt: types.optional(types.maybeNull(types.number), null),

    channel: types.optional(types.maybeNull(types.string), ''),
    rating: types.optional(types.maybeNull(types.number), null),
  })
  .actions((self) => ({
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as IRatingStatisticsData;
      (Object.keys(newProps) as TRatingStatisticsDataKeys[]).forEach((key) => {
        //@ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

type TRatingStatisticsData = Instance<typeof RatingStatisticsData>;
type TRatingStatisticsDataSnapshot = SnapshotOut<typeof RatingStatisticsData>;

export interface IRatingStatisticsData extends TRatingStatisticsData {}
export type TRatingStatisticsDataKeys = keyof TRatingStatisticsDataSnapshot & string;
export interface IRatingStatisticsDataSnapshot extends TRatingStatisticsDataSnapshot {}
export const createRatingStatisticsData = () =>
  types.optional(RatingStatisticsData, {
    region: '',
    cnt: null,
    preCnt: null,
    channel: '',
    rating: null,
  } as TRatingStatisticsData);

// --------------------------------------------------------------------------
/**
 * 데이터 전체 규격
 */
export const RatingStatistics = types
  .model('RatingStatistics')
  .props({
    statDt: types.optional(types.string, ''),
    preStatDt: types.optional(types.maybeNull(types.string), null),
    dataList: types.optional(types.array(createRatingStatisticsData()), []),
  })
  .actions((self) => ({
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as IRatingStatistics;
      (Object.keys(newProps) as TRatingStatisticsKeys[]).forEach((key) => {
        //@ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

type TRatingStatistics = Instance<typeof RatingStatistics>;
type TRatingStatisticsSnapshot = SnapshotOut<typeof RatingStatistics>;

export interface IRatingStatistics extends TRatingStatistics {}
export type TRatingStatisticsKeys = keyof TRatingStatisticsSnapshot & string;
export interface IRatingStatisticsSnapshot extends TRatingStatisticsSnapshot {}
export const createRatingStatistics = () =>
  types.optional(RatingStatistics, {
    statDt: '',
    preStatDt: '',
    dataList: [] as TRatingStatisticsData[],
  } as TRatingStatistics);

// --------------------------------------------------------------------------
/**
 * 조회조건 규격
 */
export interface IRatingStatisticsFilter {
  withPre?: boolean;
  statDtSt?: string;
  statDtEd?: string;
  statType?: number;
  statUnit?:number;
  dataType: 'ratings' | 'occupy' | 'views';
  regionList?: selectOptions[];
  chSvcIdList?: selectOptions[];
}
export type TRatingStatisticsFilter = IRatingStatisticsFilter;
