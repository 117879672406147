import { createSimpleUser } from './../simple-user/SimpleUser';
import { createModelCode } from './../model-code/ModelCode';
import { Instance, SnapshotOut, types } from 'mobx-state-tree';

/**
 * # EventType
 *
 * EventType을 설명하세요.
 */
export const EventType = types
  .model('EventType')
  // --------------------------------------------------------------------------
  .props({
    eventTypeSid: types.optional(types.number, 0),
    eventType: types.maybeNull(createModelCode()),
    problCause: types.optional(types.maybeNull(types.string), null),
    delYn: types.optional(types.maybeNull(types.boolean), null),
    regDt: types.optional(types.maybeNull(types.string), null),
    regUser: types.maybeNull(createSimpleUser()),
    updDt: types.optional(types.maybeNull(types.string), null),
    updUser: types.maybeNull(createSimpleUser()),
  })
  // --------------------------------------------------------------------------
  // MUTATEs ONLY! - 모델 상태를 변경
  // REQUESTs는 스토어 모델에 작성하세요.
  // ex) yarn gen model eventType --store
  .actions((self) => ({
    /*
     * 여러 prop을 한번에 수정
     */
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as IEventType;
      (Object.keys(newProps) as TEventTypeKeys[]).forEach((key) => {
        //@ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type TEventType = Instance<typeof EventType>;
type TEventTypeSnapshot = SnapshotOut<typeof EventType>;

export interface IEventType extends TEventType {}
export type TEventTypeKeys = keyof TEventTypeSnapshot & string;
export interface IEventTypeSnapshot extends TEventTypeSnapshot {}
export const createEventType = () =>
  types.optional(EventType, {
    eventTypeSid: 0,
    eventType: null,
    problCause: null,
    regDt: null,
    regUser: null,
    delYn: null,
    updDt: null,
    updUser: null,
  } as TEventType);

export interface IEventTypeFilter {
  evnetTypeSid?: number;
  evnetType?: number;
  problCause?: string;
}
