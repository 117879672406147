import { Instance, SnapshotOut, types } from 'mobx-state-tree';

export const BusinessModel = types.model('Business').props({
  businessSeq: types.maybe(types.number),
  businessCode: types.maybeNull(types.string),
  businessName: types.maybeNull(types.string),
  startYear: types.maybeNull(types.string),
  endYear: types.maybeNull(types.string),
  baseBank: types.maybeNull(types.string),
  useYn: types.maybeNull(types.string),
  diseaseNm: types.maybeNull(types.string),
  baseBankName: types.maybeNull(types.string),
  coopBank: types.maybeNull(types.string),
  coopBankName: types.maybeNull(types.string),
});

type BusinessType = Instance<typeof BusinessModel>;
export interface Business extends BusinessType {}
type BusinessSnapshotType = SnapshotOut<typeof BusinessModel>;
export type BusinessTypeKey = keyof BusinessSnapshotType;
export interface BusinessSnapshot extends BusinessSnapshotType {}
export const createBusinessDefaultModel = () =>
  types.optional(BusinessModel, {
    businessSeq: 0,
    businessCode: '',
    businessName: '',
    startYear: '',
    endYear: '',
    baseBank: '',
    useYn: '',
    diseaseNm: '',
    baseBankName: '',
    coopBank: '',
    coopBankName: '',
  });
