//
// components
// hooks
import { INotification, useStores } from '../../models';
// material
import {
  Backdrop,
  Card,
  CardContent,
  CardHeader,
  Link
} from '@mui/material';
import { Viewer } from '@toast-ui/react-editor';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import '../../components/forms/CEditor-Main.css';
import '../../components/forms/CEditor-dark.css';
import { CallApiToStore } from '../../screens/Home';
// ----------------------------------------------------------------------
export default observer(function Notice() {
  const { loadingStore, notificationStore } = useStores();
  // 공지사항 관련
  const [showNoticeList, setShowNoticeList] = useState<INotification[]>([]);
  const [closedNtfSidList, setClosedNtfSidList] = useState<number[]>([]);
 
  // -------------------------------------------
  // use Effect
  // -------------------------------------------
  useEffect(() => {
    getShowNotices();
  }, []);

  // 공지사항 목록 조회
  const getShowNotices = async () => {
    await CallApiToStore(notificationStore.getShowNotices(), 'page', loadingStore);
    // ntfOrder로 정렬
    const listTemp: INotification[] = notificationStore.filterCloseTodayNotice().sort((a,b) => {
      let aOrder: number = a?.ntfOrder ? a.ntfOrder : -1;
      let bOrder: number = b?.ntfOrder ? b.ntfOrder : -1;
      if(aOrder == bOrder)
      {
        return a.ntfSid > b.ntfSid ? -1 : 1;
      }
      else
      {
        return aOrder < bOrder ? -1 : 1;
      }
    });
    setShowNoticeList(listTemp);
  };

  // 공지사항 닫기
  const handleCloseNotice = (closeToday: boolean, ntfSid: number) => 
  {
    // 오늘 하루 안보기
    if(closeToday)
    {
      notificationStore.addCloseTodaySidStorageData(ntfSid);
    }

    // 닫기
    let closedListTemp: number[] = closedNtfSidList;
    closedListTemp.push(ntfSid);
    setClosedNtfSidList(closedListTemp);

    // 세팅
    const showNoticeListTemp: INotification[] = notificationStore.filterCloseTodayNotice().filter((item) => {
      return !closedListTemp.includes(item.ntfSid);
    });
    setShowNoticeList(showNoticeListTemp);
  }

  return (
        <Backdrop
          sx={{ 
            color: '#fff',
            zIndex: (theme) => theme.zIndex.drawer + 1, 
            alignItems:'flex-start',
            alignContent: 'baseline',
            flexWrap:'wrap',
            justifyContent:'flex-start !important',
            overflow: 'auto',
          }}
          open={showNoticeList && showNoticeList.length > 0}
        >
          { showNoticeList.map((notice: any) => (
                <Card
                  key={notice.ntfSid}
                  sx={{
                    display: 'grid',
                    margin:'10px',minWidth:'250px', border: '1px solid #777777', alignContent:'center'}} 
                >
                    {/* 제목*/}
                    <CardHeader
                      sx={{margin:'0px !important', padding:'8px 15px !important'}}
                      title={notice?.ntfTitle || '공지사항'} 
                    ></CardHeader>
                    {/* 내용 */}
                    <CardContent
                      sx={{margin:'0px !important', padding:'15px !important', 
                        maxHeight:'300px', overflow:'auto',
                        backgroundColor:'#1e262f',
                        borderTop: '1px solid #777',
                        borderBottom: '1px solid #777',
                      }}
                    >
                      <Viewer initialValue={notice?.ntfConts || '내용이 없습니다.'} theme={'dark'}/>
                    </CardContent>
                    {/* 하단 버튼 */}
                    <CardContent sx={{
                        margin:'0px !important', padding:'8px 15px !important',
                        fontSize: 'small',
                        display: 'flex', justifyItems: 'end', alignItems: 'center', flexDirection: 'row-reverse'
                      }}
                    >
                      <Link underline='hover' color={'#999'} sx={{cursor:'pointer'}} onClick={() => {handleCloseNotice(false, notice.ntfSid);}}>닫기</Link>
                      <Link underline='hover' color={'#dd6b66'} sx={{marginRight:'20px', cursor:'pointer'}} onClick={() => {handleCloseNotice(true, notice.ntfSid);}}>오늘 하루 안보기</Link>
                    </CardContent>
                </Card>
            ))
          }
        </Backdrop>
   
  );
});

