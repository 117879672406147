import { IModelCodeSnapshot } from './../../models/model-code/ModelCode';
import { IEquipGroupSnapshot } from './../../models/equip-group/EquipGroup';
import { ApiResponse } from 'apisauce';
import { getGeneralApiProblem } from '../api';
import { Api } from '../api/api';
import { IResponseSnapshot } from './../../models/response/Response';
import {
  TGetEquipGroupsResult,
  TGetEquipGroupResult,
  TUpdateEquipGroupResult,
  TPostEquipGroupResult,
} from './EquipGroupTypes';
import { IEquipmentSnapshot } from '../../models';

/**
 * # EquipGroup Api 서비스
 *
 * 서비스의 설명을 작성하세요.
 *
 * ## 사용방법
 *
 * ```ts
 * const service = new EquipGroupApi();
 *
 * ```
 */
export class EquipGroupApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  async gets(
    page: number,
    size: number,
    equipType: IModelCodeSnapshot,
  ): Promise<TGetEquipGroupsResult> {
    try {
      const url: string = `/equipment/group`;
      const param = {
        page: page,
        size: size,
        equipType: equipType.code,
      };

      const response: ApiResponse<any> = await this.api.apisauce.get(url, param);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;

      if (responseInfo.resultCode === 'S') {
        return {
          kind: 'ok',
          responseInfo: responseInfo,
          equipGroups: response.data.data,
        };
      }

      return { kind: 'ok', responseInfo: responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async get(equipGroupSid: number): Promise<TGetEquipGroupResult> {
    try {
      const url: string = `/equipment/group/${equipGroupSid}`;
      const response: ApiResponse<any> = await this.api.apisauce.get(url);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;

      if (responseInfo.resultCode === 'S') {
        let equipGroupTemp: IEquipGroupSnapshot = response.data.data;

        equipGroupTemp.equipList?.forEach((item: IEquipmentSnapshot, index: number) => {
          item.updDt = item.updDt.toString();
          item.regDt = item.regDt.toString();
          item.buyDate = '';
          item.installDate = '';
        });

        return {
          kind: 'ok',
          responseInfo: responseInfo,
          equipGroup: equipGroupTemp,
        };
      }

      return { kind: 'ok', responseInfo: responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async put(equipGroup: IEquipGroupSnapshot): Promise<TUpdateEquipGroupResult> {
    try {
      const url: string = `/equipment/group/${equipGroup.equipGroupSid}`;

      const param = {
        equipGroupSid: equipGroup.equipGroupSid,
        equipGroupNm: equipGroup.equipGroupNm,
        equipList: equipGroup.equipList,
        equipType: equipGroup.equipType,
        descr: equipGroup.descr,
      };
      const response: ApiResponse<any> = await this.api.apisauce.put(url, param);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const reseponseInfo: IResponseSnapshot = response.data;

      if (reseponseInfo.resultCode === 'S') {
        let equipGroupTemp: IEquipGroupSnapshot = response.data.data;
        equipGroupTemp.equipList?.forEach((item: IEquipmentSnapshot, index: number) => {
          item.updDt = item.updDt.toString();
          item.regDt = item.regDt.toString();
          item.buyDate = '';
          item.installDate = '';
        });
        return {
          kind: 'ok',
          responseInfo: reseponseInfo,
          equipGroup: equipGroupTemp,
        };
      }
      return { kind: 'ok', responseInfo: reseponseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async post(equipGroup: IEquipGroupSnapshot): Promise<TPostEquipGroupResult> {
    try {
      const url: string = `/equipment/group`;

      const param = {
        equipGroupNm: equipGroup.equipGroupNm,
        equipList: equipGroup.equipList,
        equipType: equipGroup.equipType,
        descr: equipGroup.descr,
      };
      const response: ApiResponse<any> = await this.api.apisauce.post(url, param);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;

      if (responseInfo.resultCode === 'S') {
        let equipGroupTemp: IEquipGroupSnapshot = response.data.data;

        equipGroupTemp.equipList?.forEach((item: IEquipmentSnapshot, index: number) => {
          item.updDt = item.updDt.toString();
          item.regDt = item.regDt.toString();
          item.buyDate = '';
          item.installDate = '';
        });

        return {
          kind: 'ok',
          responseInfo: responseInfo,
          equipGroup: equipGroupTemp,
        };
      }

      return { kind: 'ok', responseInfo: responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async delete(equipGroupSid: number) {
    try {
      const url: string = `/equipment/group/${equipGroupSid}`;
      const response: ApiResponse<any> = await this.api.apisauce.delete(url);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;
      return { kind: 'ok', responseInfo: responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }
}
