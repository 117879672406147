import { MOCK_API_CONFIG, JEST_API_CONFIG } from './../services/api/api-config';
import { Api } from '../services/api';

/**
 * The environment is a place where services and shared dependencies between
 * models live.  They are made available to every model via dependency injection.
 */
export class Environment {
  constructor(envType?: string) {
    // if (isMockServer) {
    //   this.api = new Api(MOCK_API_CONFIG);
    // } else {
    //   this.api = new Api();
    // }

    if (envType === 'MOCK') {
      this.api = new Api(MOCK_API_CONFIG);
    } else if (envType === 'JEST') {
      console.log('Target Server ::: JEST');
      this.api = new Api(JEST_API_CONFIG);
    } else {
      this.api = new Api();
    }
    // create each service
  }

  async setup() {
    // allow each service to setup
    await this.api.setup();
  }

  // async storeTestSetup() {
  //   // allow each service to setup
  //   await this.api.storeTestSetup();
  // }

  /**
   * Our api.
   */
  api: Api;
}
