import { FormControl, FormHelperText, IconButton, InputAdornment, TextField, Tooltip } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useState, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormStyle, SelectStyle } from '../../components/settings/SettingStyle';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import RefreshIcon from '@mui/icons-material/Refresh';
import * as React from "react";

export interface selectOptions {
  code: string | number | boolean | null;
  value: string;
  pcode: string | number | null;
}

export const defaultOption: selectOptions = {
  code: '',
  value: '',
  pcode: '',
};

interface CTextFieldProps {
  id?: string;
  /** **라벨** */
  label: string;
  /** ** 리액트 훅 폼 필드명** */
  name: string;
  /** **입력 도움말** */
  placeholder?: string;
  /** **비활성화 여부** */
  disabled?: boolean;
  /** **수정금지 여부** */
  readonly?: boolean;
  /** **여러줄 입력 여부** */
  multiline?: boolean;
  /** **여러줄 입력 여부** */
  multilineHeight?: number;
  /** **타입**
    *
    비밀번호 형식이나 숫자 형식이 필요한 경우 입력합니다.
   */
  type?: 'password' | 'number' | '';
  /** **자동완성 ** */
  autoComplete?: string;
  /** **체인지 콜백** */
  onChangeCallback?: Function;
  /** **엔터 클릭 시 Callback** */
  onEnterCallback?: Function;
  /** 셀렉트 할 때 값 비우기 아이콘 */
  selectClearFunction?: Function;

  /** 단위 값 입력 */
  unitString?: string;

  code?: boolean;

  options?: selectOptions[];

  raw?: string | null;

  color?: 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';

  variant?: "standard" | "filled" | "outlined" | undefined;

  required?: boolean;

  help?: boolean;
}

/**
 * ## 기본 사용법
 *
 * > 단일 값을 입력하는 셀렉트 박스입니다.기본 텍스트 필드입니다.
 *
 */
const CTextField = observer(function CTextFieldProps({
  id = Math.random().toString(),
  name,
  label,
  placeholder = '',
  disabled = false,
  readonly = false,
  multiline = false,
  multilineHeight = 150,
  type = '',
  autoComplete = 'on',
  onChangeCallback,
  onEnterCallback,
  selectClearFunction,
  unitString,
  code,
  options,
  raw,
  color,
  variant = 'standard',
  required = false,
  help = true,
  ...other
}: CTextFieldProps) {
  const { control, setValue, clearErrors,register } = useFormContext();
  const height = multiline ? multilineHeight : 'auto';

  const [pwdVisible, setPwdVisible] = useState<boolean>(false);

  const [inputType, setInputType] = useState<'password' | 'number' | ''>(type);

  useEffect(() => {
    if (type === 'password') {
      if (pwdVisible) {
        setInputType('');
      } else {
        setInputType('password');
      }
    }
  }, [pwdVisible]);

  function checkNumber(event: any) {
    if (event.target.value >= 0 && event.target.value <= 9) {
      return true;
    }
    return false;
  }

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => {
        return (
          <FormControl sx={{ SelectStyle, flexDirection: 'column' }}>
            <TextField
              {...register(name)}
              id={id}
              label={label}
              variant={variant}
              sx={FormStyle}
              size="small"
              type={inputType}
              placeholder={placeholder}
              disabled={disabled}
              color={color}
              required={required}
              InputProps={{
                endAdornment: (
                  <>
                    {type === 'password' && (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="password visibility"
                          onClick={() => setPwdVisible(!pwdVisible)}
                        >
                          {pwdVisible ? <VisibilityIcon /> : <VisibilityOffIcon />}
                        </IconButton>
                      </InputAdornment>
                    )}
                    {selectClearFunction && (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="selected Refresh"
                          onClick={() => {
                            selectClearFunction();
                            clearErrors(name);
                          }}
                        >
                          <RefreshIcon />
                        </IconButton>
                      </InputAdornment>
                    )}
                  </>
                ),
              }}
              inputProps={{
                'aria-label': label,
                readOnly: readonly,
                style: { resize:'vertical', height: height, overflow: 'auto' },
                autoComplete: { autoComplete },
              }}
              value={
                raw ? raw :
                  (value !== null
                    ? options
                      ? code
                        ? options.filter((values) => values.code === value.code)[0]
                          ? options.filter((values) => values.code === value.code)[0].value
                          : defaultOption.value
                        : options.filter((values) => values.code === value)[0]
                          ? options.filter((values) => values.code === value)[0].value
                          : defaultOption.value
                      : unitString
                        ? value + ' ' + unitString
                        : value
                    : ''
                  )}
              onBlur={onBlur}
              onChange={(e) => {
                if (type === 'number' && e.target.value === '') {
                  onChange({ target: { name, value: null } });
                  onChangeCallback && onChangeCallback();
                } else {
                  onChange({ target: { name, value: e.target.value } });
                  onChangeCallback && onChangeCallback();
                }
              }}
              onKeyDown={(e: any) => {
                if (e.keyCode === 13) {
                  onEnterCallback && onEnterCallback();
                }
              }}
              multiline={multiline}
              error={!!error}
              // helperText={error?.message}
              // helperText={() => {
              // }}
              {...other}
            />
            {
              help === true && <FormHelperText error variant="filled">{error?.message}</FormHelperText>
            }
          </FormControl>
        );
      }}
    />
  );
});

export default CTextField;
