import { ApiResponse } from 'apisauce';
import { IResponseSnapshot } from '../../models';
import { Api } from '../api/api';
import { getGeneralApiProblem } from '../api/api-problem';
import { IPaginationSnapshot } from './../../models/pagination/Pagination';
import { IUsrgrpMenuSnapshot } from './../../models/usrgrp-menu/UsrgrpMenu';
import {
  TGetUsrgrpMenuListByUsrgrpSidResult,
  TGetUsrgrpMenusResult,
  TPutUsrgrpMenuResult,
} from './UsrgrpMenuTypes';

/**
 * # UsrgrpMenu Api 서비스
 *
 * 서비스의 설명을 작성하세요.
 *
 * ## 사용방법
 *
 * ```ts
 * const service = new UsrgrpMenuApi();
 *
 * ```
 */
export class UsrgrpMenuApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  /**
   * INF_CAIMS_00176
   * 메뉴 전체 목록을 조회한다.
   * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=1139000140
   * @param pagination
   * @returns
   */
  async gets(): Promise<TGetUsrgrpMenusResult> {
    try {
      const url = '/operation/menu';
      const response: ApiResponse<any> = await this.api.apisauce.get(url);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;

      return {
        kind: 'ok',
        usrgrpMenus: response.data.data,
        responseInfo: responseInfo,
        pagination: response.data.pagination,
      };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  /**
   * INF_CAIMS_00177
   * 그룹 메뉴 전체 목록을 조회한다.
   * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=1287212511
   * @param usrgrpSid
   * @returns
   */
  async getUsrgrpMenuListByUsrgrpSid(
    usrgrpSid: number,
  ): Promise<TGetUsrgrpMenuListByUsrgrpSidResult> {
    try {
      const url: string = `/operation/usrgrpMenuRel/usrgrp/${usrgrpSid}`;
      const response: ApiResponse<any> = await this.api.apisauce.get(url);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;

      return {
        kind: 'ok',
        usrgrpMenus: response.data.data,
        responseInfo: responseInfo,
      };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  /**
   * INF_CAIMS_00178
   * 해당 그룹이 접근할 수 있는 메뉴 목록을 수정한다.
   * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=361199811
   * @param usrgrpMenu
   * @returns
   */
  async putUsrgrpMenu(usrgrpMenu: IUsrgrpMenuSnapshot): Promise<TPutUsrgrpMenuResult> {
    try {
      const url: string = `/operation/usrgrp/${usrgrpMenu.usrgrpSid}/menu`;
      const param = usrgrpMenu.menuSidList;
      const response: ApiResponse<any> = await this.api.apisauce.put(url, param);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;

      return {
        kind: 'ok',
        usrgrpMenus: response.data.data,
        responseInfo: responseInfo,
      };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }
}
