import { createSimpleUser } from './../simple-user/SimpleUser';
import { Instance, SnapshotOut, types } from 'mobx-state-tree';

/**
 * # Usrgrp
 *
 * Usrgrp을 설명하세요.
 */
export const Usrgrp = types
  .model('Usrgrp')
  // --------------------------------------------------------------------------
  .props({
    // usrgrpSid	사용자 그룹 일련 ID	Number
    // usrgrpNm	사용자 그룹 명	String
    // usrgrpPsid	사용자 그룹 부모 일련 ID	Number
    // useYn	사용여부	Boolean
    // dscrpt	그룹설명	String
    // regDt	등록일시	Number
    // regId	등록ID	Number
    // updDt	수정일시	Number
    // updId	수정ID	Number
    // roleList	권한 리스트	List<Role>

    usrgrpSid: types.optional(types.number, 0),
    usrgrpNm: types.optional(types.string, ''),
    usrgrpPsid: types.optional(types.maybeNull(types.number), 0),
    useYn: types.optional(types.boolean, false),
    dscrpt: types.optional(types.string, ''),
    adminRoleYn: types.optional(types.boolean, false),
    regDt: types.optional(types.string, ''),

    updDt: types.optional(types.string, ''),
    //신버전
    regUser: types.maybeNull(createSimpleUser()),
    updUser: types.maybeNull(createSimpleUser()),

    //구버전
    regId: types.optional(types.number, 0),
    updId: types.optional(types.number, 0),

    grpNmDuplicate: types.optional(types.boolean, false),
  })
  // --------------------------------------------------------------------------
  // MUTATEs ONLY! - 모델 상태를 변경
  // REQUESTs는 스토어 모델에 작성하세요.
  // ex) yarn gen model usrgrp --store
  .actions((self) => ({
    /*
     * 여러 prop을 한번에 수정
     */
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as IUsrgrp;
      (Object.keys(newProps) as TUsrgrpKeys[]).forEach((key) => {
        //@ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type TUsrgrp = Instance<typeof Usrgrp>;
type TUsrgrpSnapshot = SnapshotOut<typeof Usrgrp>;

export interface IUsrgrp extends TUsrgrp {}
export type TUsrgrpKeys = keyof TUsrgrpSnapshot & string;
export interface IUsrgrpSnapshot extends TUsrgrpSnapshot {}
export const createUsrgrp = () =>
  types.optional(Usrgrp, {
    usrgrpSid: 0,
    usrgrpNm: '',
    usrgrpPsid: 0,
    useYn: false,
    dscrpt: '',
    adminRoleYn: false,
    regDt: '',
    regId: 0,
    updDt: '',
    updId: 0,
    grpNmDuplicate: false,
  } as TUsrgrp);
