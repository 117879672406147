import { withRootStore } from './../extensions/with-root-store';
import { BusinessModel, BusinessSnapshot } from '../../models/business/business';
import { BusinessApi, GetBusinessListResult, GetBankByBusinessResult } from '../../services';
import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { withEnvironment } from '../extensions/with-environment';
import { toJS } from 'mobx';

export const BusinessStoreModel = types
  .model('BusinessStore')
  .props({
    businessList: types.optional(types.array(BusinessModel), []),
    banksByBusiness: types.optional(types.array(BusinessModel), []),
    selectedBusiness: types.maybe(BusinessModel),
  })
  .extend(withEnvironment)
  .extend(withRootStore)
  .views((self) => ({
    getBanks: () => {
      return toJS(self.banksByBusiness);
    },
  }))
  .actions((self) => ({
    resetBanksByBusiness: () => {
      self.banksByBusiness.replace([]);
    },
    setBusinessList: (businessList: BusinessSnapshot[]) => {
      self.businessList.replace(businessList);
    },
    setBanksByBusiness: (bizs: BusinessSnapshot[]) => {
      self.banksByBusiness.replace(bizs);
    },
    setSelectedBusinessBySeq: (seq: number) => {
      self.selectedBusiness = toJS(self.businessList.find((d) => d.businessSeq === seq));
    },
  }))
  .actions((self) => ({
    /** 사업 목록을 가져오는 API를 호출합니다.
     * @param startYear
     */
    getBusinessList: async (startYear?: string) => {
      if (startYear === undefined || startYear === 'all') {
        startYear = '';
      }

      const businessApi: BusinessApi = new BusinessApi(self.environment.api);
      const result: GetBusinessListResult = await businessApi.getBusinessList(startYear);

      if (result.kind === 'ok') {
        if (result.resultList) {
          self.setBusinessList(result.resultList);
        }
      } else {
      }
    },

    /** 사업별 은행 목록을 가져오는 API를 호출합니다.
     * @param businessSeq
     */
    getBanksByBusiness: async (businessSeq?: number | string) => {
      if (businessSeq === undefined || businessSeq === 'all') {
        businessSeq = 0;
      }

      const businessApi: BusinessApi = new BusinessApi(self.environment.api);
      const result: GetBankByBusinessResult = await businessApi.getBankByBusiness(businessSeq);

      if (result.kind === 'ok') {
        if (result.resultList) {
          self.setBanksByBusiness(result.resultList);
        } else {
          self.resetBanksByBusiness();
        }
      } else {
      }
    },
  }));

type BusinessStoreType = Instance<typeof BusinessStoreModel>;
export interface BusinessStore extends BusinessStoreType {}
type BusinessStoreSnapshotType = SnapshotOut<typeof BusinessStoreModel>;
export interface BusinessStoreSnapshot extends BusinessStoreSnapshotType {}
export const createBusinessStoreDefaultModel = () => {};
