import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { selectOptions } from '../../components/forms/CSelect';
import { createWatchStatisticsData } from '../watch-statistics-data/WatchStatisticsData';

/**
 * # WatchStatistics
 *
 * WatchStatistics을 설명하세요.
 */
export const WatchStatistics = types
  .model('WatchStatistics')
  // --------------------------------------------------------------------------
  .props({
    statDt: types.optional(types.string, ''),
    preStatDt: types.optional(types.maybeNull(types.string), null),
    region: types.optional(types.maybeNull(types.string), null),
    cnt: types.optional(types.maybeNull(types.number), null),
    preCnt: types.optional(types.maybeNull(types.number), null),
  })
  // --------------------------------------------------------------------------
  // MUTATEs ONLY! - 모델 상태를 변경
  // REQUESTs는 스토어 모델에 작성하세요.
  // ex) yarn gen model watchStatistics --store
  .actions((self) => ({
    /*
     * 여러 prop을 한번에 수정
     */
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as IWatchStatistics;
      (Object.keys(newProps) as TWatchStatisticsKeys[]).forEach((key) => {
        // @ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type TWatchStatistics = Instance<typeof WatchStatistics>;
type TWatchStatisticsSnapshot = SnapshotOut<typeof WatchStatistics>;

export interface IWatchStatistics extends TWatchStatistics {}
export type TWatchStatisticsKeys = keyof TWatchStatisticsSnapshot & string;
export interface IWatchStatisticsSnapshot extends TWatchStatisticsSnapshot {}
export const createWatchStatistics = () =>
  types.optional(WatchStatistics, {
    statDt: '',
    preStatDt: '',
    region: '',
    cnt: null,
    preCnt: null,
  } as TWatchStatistics);

export interface IWatchStatisticsFilter {
  withPre?: boolean;
  regionList?: selectOptions[];
  statDtSt?: string;
  statDtEd?: string;
  statType: number;
}

export type TWatchStatisticsFilter = IWatchStatisticsFilter;
