import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { UserLoginHistoryApi } from '../../services/user-login-history/user-login-history-api';
import { GetUserLoginHistorysResult } from '../../services/user-login-history/user-login-history-result-types';
import { timestampToDate } from '../../utils/dateExchanger';
import { withEnvironment } from '../extensions/with-environment';
import { withRootStore } from '../extensions/with-root-store';
import { IPagination, IPaginationSnapshot, createPagination } from '../pagination/Pagination';
import {
  IUserLoginHistory,
  IUserLoginHistorySnapshot,
  UserLoginHistoryModel,
} from '../user-login-history/user-login-history';

export const UserLoginHistoryStoreModel = types
  .model('UserLoginHistoryStore')
  .props({
    userLoginHistorys: types.optional(types.array(UserLoginHistoryModel), []),
    isSuccess: types.optional(types.boolean, false),
    pagination: createPagination(),
  })
  .extend(withEnvironment)
  .extend(withRootStore)
  .views((self) => ({}))
  .actions((self) => ({
    setUserLoginHistorys: (userLoginHistorys: IUserLoginHistorySnapshot[]) => {
      if (!userLoginHistorys) {
        return;
      }
      self.userLoginHistorys.replace(userLoginHistorys as IUserLoginHistory[]);
    },
    setPagination: (pagination: IPaginationSnapshot) => {
      self.pagination = pagination as IPagination;
    },
  }))
  .actions((self) => ({
    gets: async (userLoginHistoryFilter?: TUserLoginHistoryFilter) => {
      self.setUserLoginHistorys([]);
      try {
        const userLoginHistoryApi: UserLoginHistoryApi = new UserLoginHistoryApi(self.environment.api);
        let result: GetUserLoginHistorysResult;
        if (userLoginHistoryFilter) {
          result = await userLoginHistoryApi.gets(self.pagination, userLoginHistoryFilter);
        } else {
          result = await userLoginHistoryApi.gets(self.pagination);
        }

        if (result.userLoginHistorys) {
          if (result.userLoginHistorys.length > 0) {
            result.userLoginHistorys.forEach((item: IUserLoginHistorySnapshot) => {
              item.loginReqTime = timestampToDate(Number.parseInt(item.loginReqTime));
            });
          }
          self.setUserLoginHistorys(result.userLoginHistorys);
          result.pagination && self.setPagination(result.pagination);
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },
  }));
type UserLoginHistoryStoreType = Instance<typeof UserLoginHistoryStoreModel>;
export interface UserLoginHistoryStore extends UserLoginHistoryStoreType {}
type UserLoginHistoryStoreSnapshotType = SnapshotOut<typeof UserLoginHistoryStoreModel>;
export interface UserLoginHistoryStoreSnapshot extends UserLoginHistoryStoreSnapshotType {}

export interface IUserLoginHistoryFilter {
  loginId?: string;
  userNm?: string;
  clntAddr?: string;
  sucesYn?: boolean;
  loginReqTime?: string;
  authDtSt?: string | number;
  authDtEd?: string | number;
}

export type TUserLoginHistoryFilter = IUserLoginHistoryFilter;
