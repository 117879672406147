import { channel } from 'diagnostics_channel';
import { IResponseSnapshot } from './../../models/response/Response';
import { IPaginationSnapshot } from './../../models/pagination/Pagination';
import { IChannelCodeSnapshot } from './../../models/channel-code/ChannelCode';
import { ApiResponse } from 'apisauce';
import { TChannelCodeFilter } from '../../models/channel-code-store/ChannelCodeStore';
import { Api } from '../api/api';
import { getGeneralApiProblem } from '../api/api-problem';
import { ICommonCode, ICommonCodeSnapshot } from './../../models/common-code/CommonCode';
import { timestampToDate } from './../../utils/dateExchanger';
import {
  TGetChannelCodesResult,
  TGetChannelCodeResult,
  TPostChannelCodeResult,
  TUpdateChannelCodeResult,
  TGetChildCodesResult,
  TGetChannelCodeItems,
} from './ChannelCodeTypes';
import { IChannelChildCodeSnapshot } from '../../models';

/**
 * # ChannelCode Api 서비스
 *
 * 서비스의 설명을 작성하세요.
 *
 * ## 사용방법
 *
 * ```ts
 * const service = new ChannelCodeApi();
 *
 * ```
 */
export class ChannelCodeApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  /**
   * INF_CAIMS_00203
   * 채널코드 전체 목록을 조회한다. (채널코드만 조회된다. = 코드항목은 조회안함.)
   * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=1523906553
   * @param channelCodeFilter
   * @returns
   */
  async gets(
    pagination: IPaginationSnapshot,
    channelCodeFilter?: TChannelCodeFilter,
  ): Promise<TGetChannelCodesResult> {
    try {
      const url: string = `/channel/code`;
      const param = { ...channelCodeFilter, page: pagination.page, size: pagination.size };
      const response: ApiResponse<any> = await this.api.apisauce.get(url, param);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;
      if (responseInfo.resultCode === 'S') {
        if (response.data.data) {
          response.data.data.forEach((item: ICommonCode) => {
            item.regDt = timestampToDate(Number.parseInt(item.regDt));
            item.updDt = timestampToDate(Number.parseInt(item.updDt));
          });
        }
        return { kind: 'ok', responseInfo: responseInfo, channelCodes: response.data.data };
      }
      return { kind: 'ok', responseInfo: responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  /**
   * INF_CAIMS_00204
   * 해당 채널코드의 상세정보를 조회한다. (해당 코드의 하위항목들이 함께 조회됨)
   * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=835915838
   * @param cdId
   */
  async get(cdId: number): Promise<TGetChannelCodeResult> {
    try {
      const url: string = `/channel/code/${cdId}`;
      const response: ApiResponse<any> = await this.api.apisauce.get(url);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;
      if (responseInfo.resultCode === 'S') {
        if (response.data.data) {
          response.data.data.regDt = timestampToDate(Number.parseInt(response.data.data.regDt));
          response.data.data.updDt = timestampToDate(Number.parseInt(response.data.data.updDt));
        }

        if (response.data.data.cdItemList) {
          response.data.data.cdItemList.forEach((item: ICommonCodeSnapshot) => {
            item.regDt = timestampToDate(Number.parseInt(item.regDt));
            item.updDt = timestampToDate(Number.parseInt(item.updDt));
          });
        }
        return { kind: 'ok', responseInfo: responseInfo, channelCode: response.data.data };
      }
      return { kind: 'ok', responseInfo: responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  /**
   * INF_CAIMS_00205
   * 해당 채널코드에 코드항목을 등록한다.
   * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=406741055
   * @param channelCode
   */
  async itemPost(channelCode: IChannelCodeSnapshot): Promise<TPostChannelCodeResult> {
    try {
      const url: string = `/channel/code/${channelCode.cdPid}/item`;

      type TParams = {
        cdNm: string;
      };

      const params: TParams = { cdNm: channelCode.cdNm };
      const response: ApiResponse<any> = await this.api.apisauce.post(url, params);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;

      if (responseInfo.resultCode === 'S') {
        if (response.data.data) {
          response.data.data.regDt = timestampToDate(Number.parseInt(response.data.data.regDt));
          response.data.data.updDt = timestampToDate(Number.parseInt(response.data.data.updDt));
        }
        return { kind: 'ok', responseInfo: responseInfo, channelCode: response.data.data };
      }
      return { kind: 'ok', responseInfo: responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  /**
   * INF_CAIMS_00206
   * 해당 채널코드의 정보를 수정한다.
   * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=707501025
   * @param channelCode
   */
  async put(channelCode: IChannelCodeSnapshot): Promise<TUpdateChannelCodeResult> {
    try {
      let param = createUpdateData(channelCode);
      const url: string = `/channel/code/${param.cdId}`;

      const response: ApiResponse<any> = await this.api.apisauce.put(url, param);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;

      if (responseInfo.resultCode === 'S') {
        if (response.data.data) {
          response.data.data.regDt = timestampToDate(Number.parseInt(response.data.data.regDt));
          response.data.data.updDt = timestampToDate(Number.parseInt(response.data.data.updDt));

          if (response.data.data.cdItemList) {
            response.data.data.cdItemList.forEach((item: IChannelCodeSnapshot) => {
              item.regDt = timestampToDate(Number.parseInt(item.regDt));
              item.updDt = timestampToDate(Number.parseInt(item.updDt));
            });
          }
        }
        return { kind: 'ok', responseInfo: responseInfo, channelCode: response.data.data };
      }

      return { kind: 'ok', responseInfo: responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  /**
   * INF_CAIMS_00209
   * 해당 채널코드의 사용여부가 True인 하위항목(자식코드) 목록을 조회한다.
   * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=1062607803
   * @param cdId
   */
  async getChildCodes(cdId: number): Promise<TGetChildCodesResult> {
    try {
      const url: string = `/channel/code/${cdId}/item`;
      const response: ApiResponse<any> = await this.api.apisauce.get(url);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;

      if (responseInfo.resultCode === 'S') {
        if (response.data.data) {
          return { kind: 'ok', responseInfo: responseInfo, childList: response.data.data };
        }
      }

      return { kind: 'ok', responseInfo: responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  /**
   * INF_CAIMS_00229
   * 전체 채널코드의 코드항목을 조회한다.
   * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=574023193
   */
  async getChannelCodes(): Promise<TGetChannelCodeItems> {
    try {
      const url: string = `/channel/code/item`;
      const response: ApiResponse<any> = await this.api.apisauce.get(url);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;

      if (responseInfo.resultCode === 'S') {
        if (response.data.data) {
          return { kind: 'ok', responseInfo: responseInfo, codeList: response.data.data };
        }
      }

      return { kind: 'ok', responseInfo: responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }
}

const createUpdateData = (channelCode: IChannelCodeSnapshot) => {
  interface IPutParam {
    cdId: number | null;
    cdNm?: string | null;
    cdDispNm?: string | null;
    cdDispNmEng?: string | null;
    cdOrder?: number | null;
    refId?: string | null;
    useYn: boolean;
    dscrpt?: string | null;
    cdItemList?: IChannelChildCodeSnapshot[] | null;
  }

  let param: IPutParam = {
    cdId: channelCode.cdId,
    useYn: channelCode.useYn,
  };

  if (channelCode.cdNm !== undefined) {
    param.cdNm = channelCode.cdNm;
  }

  if (channelCode.cdDispNm !== undefined) {
    param.cdDispNm = channelCode.cdDispNm;
  }

  if (channelCode.cdDispNmEng !== undefined) {
    param.cdDispNmEng = channelCode.cdDispNmEng;
  }

  if (channelCode.cdOrder !== undefined) {
    param.cdOrder = channelCode.cdOrder;
  }

  if (channelCode.refId !== undefined) {
    param.refId = channelCode.refId;
  }

  if (channelCode.dscrpt !== undefined) {
    param.dscrpt = channelCode.dscrpt;
  }

  if (channelCode.cdItemList) {
    param.cdItemList = channelCode.cdItemList;
    param.cdItemList.forEach((item: IChannelChildCodeSnapshot) => {
      if (item.cdOrder) {
        item.cdOrder = Number.parseInt(item.cdOrder.toString());
      }
    });
  }

  return param;
};
