import { Box, CircularProgress, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { string } from 'yup';
import { useStores } from '../models';
import AiSvgComponent from '../screens/oneview/ai/Ai';
import { DialogAnimate } from './animate';

/**
 * ## 기본 사용법
 *
 * > LoadingPage 컴포넌트입니다.
 * > 여러 개의 입력 폼 세트가 필요한 경우 코드를 복사하여 새로운 컴포넌트를 생성합니다.
 * >
 * > 컴포넌트 네이밍은 해당 서비스명 뒤에 "FormSet"을 붙여서 사용합니다
 * > 예) VOCFormSet, AlarmFormSet
 *>
 * > ### Common Style
 * > * **formSetLabelStyle** : Form의 Label에 사용합니다.
 * > * **searchRowStyle**: tr 태그에 사용합니다.
 *
 */
const LoadingAPI = observer(function LoadingPageProps() {
  const rootStore = useStores();
  const { loadingStore } = rootStore;

  return (
    <DialogAnimate
      open={loadingStore.loading}
      PaperProps={{
        style: {
          boxShadow: 'none',
          backgroundColor: 'transparent',
          overflow: 'hidden',
          textAlign: 'center',
        },
      }}
    >
      <Box sx={{
        width: '140px', height: '140px',
        ml: 'calc(50% - 70px)', //mt: '5%',
        padding: '26px'
      }} onClick={() => {
        loadingStore.closeLoadingNoMsg();
      }}>
        {/* <AiSvgComponent /> */}
        <LoadingSvgComponent />
      </Box>
      {/* <CircularProgress
        disableShrink
        size={'10%'}
        sx={{ ml: '45%', mt: '5%' }}
        onClick={() => {
          loadingStore.closeLoadingNoMsg();
        }}
      /> */}
      <>
        {/* <Typography fontWeight={700} variant="h6" color={'#FFFFFF9a'}>
          {loadingStore.message ? loadingStore.message : 'Loading...'}
        </Typography>
        <br /> */}
      </>
    </DialogAnimate>
  );
});

export default LoadingAPI;

const LoadingSvgComponent = () => {
  const loadingText: React.CSSProperties = {
    fill: '#FFFFFF',
    opacity: 1,
    fontFamily: 'KT font',
    textAlign: 'center',
    fontSize: '30px',
    fontWeight: 'bolder'
  };

  return (
    <svg
      viewBox="0 0 200 200"
      color="#298ED6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <linearGradient id="spinner-secondHalf">
          <stop offset="0%" stopOpacity="0" stopColor="currentColor" />
          <stop offset="100%" stopOpacity="0.5" stopColor="currentColor" />
        </linearGradient>
        <linearGradient id="spinner-firstHalf">
          <stop offset="0%" stopOpacity="1" stopColor="currentColor" />
          <stop offset="100%" stopOpacity="0.5" stopColor="currentColor" />
        </linearGradient>
      </defs>

      <g transform-origin="100 100" >
        <animateTransform
          from="0 0 0"
          to="360 0 0"
          attributeName="transform"
          type="rotate"
          repeatCount="indefinite"
          dur="1300ms"
        />
        <g strokeWidth="10">
          <path stroke="url(#spinner-secondHalf)" d="M 4 100 A 96 96 0 0 1 196 100" />
          <path stroke="url(#spinner-firstHalf)" d="M 196 100 A 96 96 0 0 1 4 100" />
          <path
            stroke="currentColor"
            strokeLinecap="round"
            d="M 4 100 A 96 96 0 0 1 4 98"
          />
        </g>
      </g>
      <g>
        <text style={loadingText} x={40} y={110}>LOADING...</text>
      </g>

    </svg>
  );
};
