import { ApiResponse } from "apisauce";
import { Api } from "../api/api";
import { getGeneralApiProblem } from "../api/api-problem";
import {ISmsEmail, ISmsEmailSnapshot} from '../../models';
import { 
  TGetSmsEmailsResult,
  TGetSmsEmailResult,
  TPostSmsEmailResult,
  TUpdateSmsEmailResult,
  TDeleteSmsEmailResult
 } from "./SmsEmailTypes";

  /**
  * # SmsEmail Api 서비스
  * 
  * 서비스의 설명을 작성하세요.
  * 
  * ## 사용방법
  * 
  * ```ts
  * const service = new SmsEmailApi();
  * 
  * ```
  */
 export class SmsEmailApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  async getSmsEmails(): Promise<TGetSmsEmailsResult> {
    try {
      const url = '/sms_email';
      const params = {};
      const response: ApiResponse<any> = await this.api.apisauce.get( url, params );
  
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const smsEmails: ISmsEmail[] = response.data.results;
      return { kind: "ok", smsEmails };

    } catch (e) {
      return { kind: "bad-data" };
    }
  }

  async getSmsEmail(id:number): Promise<TGetSmsEmailResult> {
    try {
      const url = `/smsEmail/${id}`;
      const response: ApiResponse<any> = await this.api.apisauce.get( url);
  
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const smsEmail: ISmsEmail = response.data.data;
      return { kind: "ok", smsEmail };

    } catch (e) {
      return { kind: "bad-data" };
    }
  }

  async updateSmsEmail(id:number, smsEmailData:ISmsEmailSnapshot): Promise<TUpdateSmsEmailResult> {
    try {
      const url = `/smsEmail/${id}`;
      const sendData = smsEmailData;
      const response: ApiResponse<any> = await this.api.apisauce.put( url, sendData );

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const smsEmail: ISmsEmail = response.data.data;
      return { kind: "ok", smsEmail };

    } catch (e) {
      return { kind: "bad-data" };
    }
  }
}

// export const createSendData = (smsEmailData:ISmsEmailSnapshot) => {
//    let returnData = smsEmailData;
//   let mobileNoList:string = "";
//
//   if(returnData.mobileNos)
//   {
//     returnData.mobileNos.map(v => mobileNoList+= v + "|");
//     mobileNoList = mobileNoList.substring(0, mobileNoList.length - 1);
//     returnData.mobileNoList = mobileNoList;
//   }
//
//   return returnData;
//
// }