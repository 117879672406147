import { Instance, SnapshotOut, types } from "mobx-state-tree"
import {withRootStore} from "../extensions/with-root-store";
import { withEnvironment } from "../extensions/with-environment"; 
import { createPagination, IPagination, IPaginationSnapshot } from '../pagination/Pagination';
import { IReport, IReportSnapshot, Report } from "../report/Report";
import { 
  ReportApi, 
  TGetReportResult, 
  TGetReportsResult 
} from "../../services"; 

export const ReportStore = types
  .model("ReportStore")
  // --------------------------------------------------------------------------
  .props({
    reports : types.optional(types.array(Report), []),
    report: types.optional(types.maybeNull(Report), null),
    pagination: createPagination(),
  })
  .extend(withEnvironment)
  .extend(withRootStore)
  .views((self) => ({}))
  .actions((self) => ({
    setSelectedReport: (report: IReportSnapshot) => {
      self.report = report as IReport;
    },
    setReports: (reports: IReportSnapshot[]) => {
      if (!reports) {
        return;
      }
      self.reports.replace(reports as IReport[]);
    },
    setPagination: (pagination: IPaginationSnapshot) => {
      self.pagination = pagination as IPagination;
    },

  }))
  .actions((self) => ({
    
    gets: async (reportFilter?: IReportFilter) => {
      self.setReports([]);
      try {
        const reportApi: ReportApi = new ReportApi(self.environment.api);
        let result: TGetReportsResult;

        if (reportFilter) {
          result = await reportApi.gets(self.pagination, reportFilter);
        } else {
          result = await reportApi.gets(self.pagination);
        }

        if (
          self.rootStore.responseStore.getResponseResult(result) &&
          result.pagination &&
          result.pagination.page === self.pagination.page
        ) {
          self.setReports(result.reports ? result.reports : [] );
          self.setPagination(result.pagination);
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },


    get: async (chkDt: string) => {
      try {
        const reportApi: ReportApi = new ReportApi(self.environment.api);
        const result: TGetReportResult = await reportApi.get(chkDt);

        if (self.rootStore.responseStore.getResponseResult(result)) {
          if (result.report) {
            self.setSelectedReport(result.report);
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },
  }));

// --------------------------------------------------------------------------
type TReportStore = Instance<typeof ReportStore>;
type TReportStoreSnapshot = SnapshotOut<typeof ReportStore>

export interface IReportStore extends TReportStore {}
export type TReportStoreKeys = keyof TReportStoreSnapshot & string;
export interface IReportStoreSnapshot extends TReportStoreSnapshot {}
export const createReportStore = () => types.optional(ReportStore, {} as TReportStore);

export interface IReportFilter{
  chkDtSt?: string;
  chkDtEd?: string;
}

export type TReportFilter = IReportFilter | IReport;