import { toJS } from 'mobx';
import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { ChannelHistoryApi } from '../../services/channel-history/ChannelHistory';
import {
  TGetChannelHistoryResult,
  TGetChannelHistorysResult,
} from '../../services/channel-history/ChannelHistoryTypes';
import { timestampToDate } from '../../utils/dateExchanger';
import {
  ChannelChangeDetail,
  IChannelChangeDetail,
} from '../channel-change-detail/ChannelChangeDetail';
import {
  ChannelHistory,
  createChannelHistory,
  IChannelHistory,
  IChannelHistorySnapshot,
} from '../channel-history/ChannelHistory';
import { withEnvironment } from '../extensions/with-environment';
import { ModelCode } from '../model-code/ModelCode';
import { createPagination, IPagination, IPaginationSnapshot } from '../pagination/Pagination';
import { withRootStore } from './../extensions/with-root-store';

export const ChannelHistoryStore = types
  .model('ChannelHistoryStore')
  .props({
    channelHistorys: types.optional(types.array(ChannelHistory), []),
    selectedChannelHistory: types.maybeNull(createChannelHistory()),
    pagination: createPagination(),
    selectedListTbChChgWrkDtl: types.optional(types.array(ChannelChangeDetail), []),
    selectedChSvcIds: types.optional(types.array(types.string), []),
    selectedChSvcIdCode: types.optional(types.array(ModelCode), []),
    historyContent: types.maybeNull(createChannelHistory()),
  })
  .extend(withEnvironment)
  .extend(withRootStore)
  .views((self) => ({}))
  .actions((self) => ({
    setChannelHistorys: (channelHistorys: IChannelHistorySnapshot[]) => {
      self.channelHistorys.replace(channelHistorys as IChannelHistory[]);
    },
    setPagination: (pagination: IPaginationSnapshot) => {
      self.pagination = pagination as IPagination;
    },
    setSelectedChannelHistory: (channelHistory: IChannelHistorySnapshot) => {
      self.selectedChannelHistory = channelHistory as IChannelHistory;
    },
    setSelectedListTbChChgWrkDtl: (listTbChChgWrkDtl: IChannelChangeDetail[]) => {
      self.selectedListTbChChgWrkDtl.replace(listTbChChgWrkDtl);
      if (listTbChChgWrkDtl) {
        listTbChChgWrkDtl.forEach((item) => {
          self.selectedChSvcIdCode.push({
            code: item.chSvcId,
            pcode: item.chSvcId,
            value: item.chSvcId?.toString(),
          });
        });
      }
    },
    setSelectedChannelHistoryByUUID: (id: string) => {
      var result: any = self.channelHistorys.find((d) => d.chChgWrkUid === id);
      if (result) {
        self.selectedChannelHistory = toJS(result) as IChannelHistory;
      }
    },
    setResetSelectedChSvcId: () => {
      self.selectedChSvcIdCode.replace([]);
    },
    setSelectedChSvcIds: (item: IChannelChangeDetail) => {
      if (item.chSvcId) {
        self.selectedChSvcIds.push(item.chSvcId.toString());
      }
    },
    setHistoryContent: (historyContent: IChannelHistorySnapshot) => {
      self.historyContent = historyContent as IChannelHistory;
    },
    setResetAll: () => {
      self.historyContent = null;
      self.selectedChannelHistory = null;
    },
  }))
  .actions((self) => ({
    /**
     * INF_CAIMS_00210
     * 채널 변경 이력 전체 목록을 조회한다.
     * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=1734640291
     * @param channelHistoryFilter
     */
    gets: async (channelHistoryFilter?: TChannelHistoryFilter) => {
      self.setChannelHistorys([]);
      self.rootStore.responseStore.responseInfo.reset();
      try {
        const channelHistoryApi: ChannelHistoryApi = new ChannelHistoryApi(self.environment.api);

        let result: TGetChannelHistorysResult;
        if (channelHistoryFilter) {
          result = await channelHistoryApi.gets(self.pagination, channelHistoryFilter);
        } else {
          result = await channelHistoryApi.gets(self.pagination);
        }

        if (
          self.rootStore.responseStore.getResponseResult(result) &&
          result.pagination &&
          result.pagination.page === self.pagination.page
        ) {
          if (result.channelHistorys) {
            result.channelHistorys.forEach((item: IChannelHistorySnapshot) => {
              item.regDt = timestampToDate(Number.parseInt(item.regDt));
              item.updDt = timestampToDate(Number.parseInt(item.updDt));
              item.wrkDt = timestampToDate(Number.parseInt(item.wrkDt));
              // if (item.chSvcIdList) {
              //   item.chSvcIdList.forEach((item) => {
              //     self.setSelectedChSvcIds(item as IChannelChangeDetail);
              //   });
              // }
            });

            self.setChannelHistorys(result.channelHistorys);

            if (result.pagination) {
              self.setPagination(result.pagination);
            }
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },

    /**
     * INF_CAIMS_00211
     * 해당 채널 변경이력의 상세이력를 조회한다.
     * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=1146358989
     * @param chChgWrkUid
     */
    get: async (chChgWrkUid: string, chSvcId: number) => {
      try {
        const channelHistoryApi: ChannelHistoryApi = new ChannelHistoryApi(self.environment.api);
        const result: TGetChannelHistoryResult = await channelHistoryApi.get(chChgWrkUid, chSvcId);

        if (self.rootStore.responseStore.getResponseResult(result)) {
          if (result.channelHistory) {
            self.setHistoryContent(result.channelHistory);
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },

    /**
     *
     * 해당 채널 변경이력의 상세이력를 조회한다.
     *
     * @param chChgWrkUid
     */
    downloadFile: async (chChgWrkUid: string) => {
      const channelHistoryApi: ChannelHistoryApi = new ChannelHistoryApi(self.environment.api);
      channelHistoryApi.downloadFile(chChgWrkUid);
    },
  }));

// --------------------------------------------------------------------------
type TChannelHistoryStore = Instance<typeof ChannelHistoryStore>;
type TChannelHistoryStoreSnapshot = SnapshotOut<typeof ChannelHistoryStore>;

export interface IChannelHistoryStore extends TChannelHistoryStore {}
export type TChannelHistoryStoreKeys = keyof TChannelHistoryStoreSnapshot & string;
export interface IChannelHistoryStoreSnapshot extends TChannelHistoryStoreSnapshot {}
export const createChannelHistoryStore = () =>
  types.optional(ChannelHistoryStore, {} as TChannelHistoryStore);

export interface IChannelHistoryFilter {
  chWrkType?: number;
  chWrkSttus?: number;
  wrkrNm?: string;
  chSvcId?: number;
  wrkDtSt?: string | number;
  wrkDtEn?: string | number;
}

export type TChannelHistoryFilter = IChannelHistoryFilter;
