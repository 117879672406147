import { ApiResponse } from 'apisauce';
import { Api } from '../api/api';
import { getGeneralApiProblem } from '../api/api-problem';
import { IPaginationSnapshot } from './../../models/pagination/Pagination';
import { IResponseSnapshot } from './../../models/response/Response';
import {
  IRulesetMappgFilter,
  IRulesetMappgSnapshot,
} from './../../models/ruleset-mappg/RulesetMappg';
import { dateToTimestamp, timestampToDate } from './../../utils/dateExchanger';
import {
  TGetRulesetMappgResult,
  TGetRulesetMappgsResult,
  TPostRulesetMappgResult,
  TUpdateRulesetMappgResult,
  TDeleteRulesetMappgResult,
} from './RulesetMappgTypes';
import { IRulesetMappgCondSnapshot } from '../../models/ruleset-mappg-cond/RulesetMappgCond';
import { IRulesetMappgSvrtCondSnapshot } from '../../models/ruleset-mappg-svrt-cond/RulesetMappgSvrtCond';

/**
 * # RulesetMappg Api 서비스
 *
 * 서비스의 설명을 작성하세요.
 *
 * ## 사용방법
 *
 * ```ts
 * const service = new RulesetMappgApi();
 *
 * ```
 */
export class RulesetMappgApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  /**
   * INF_CAIMS_00217
   * 룰셋 매핑 목록을 조회한다.
   * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=1524179962
   * @param rulesetMappgFilter
   */
  async gets(
    rulesetMappgFilter: IRulesetMappgFilter,
    pagination: IPaginationSnapshot,
  ): Promise<TGetRulesetMappgsResult> {
    try {
      interface ISendData extends IRulesetMappgFilter {
        page: number;
        size: number;
      }

      const sendData: ISendData = {
        page: pagination.page,
        size: pagination.size,
      };

      let params: ISendData = { ...sendData, ...rulesetMappgFilter };
      const url: string = `/ruleset/${params.ruleSetSid}/mappg`;

      if (params.ruleSetMappgSid) {
        delete params.ruleSetMappgSid;
      }
      delete params.ruleSetSid;
      if (params) {
        if (params.regDtSt) {
          params.regDtSt = dateToTimestamp(params.regDtSt);
        }

        if (params.regDtEd) {
          params.regDtEd = dateToTimestamp(params.regDtEd);
        }
      }
      const response: ApiResponse<any> = await this.api.apisauce.get(url, params);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }
      const responseInfo: IResponseSnapshot = response.data;
      if (responseInfo.resultCode === 'S') {
        const rulesetMappgs: IRulesetMappgSnapshot[] = response.data.data;
        if (rulesetMappgs) {
          rulesetMappgs.forEach((item: IRulesetMappgSnapshot) => {
            const reg = item.regDt;
            const upd = item.updDt;
            if (reg) {
              item.regDt = timestampToDate(Number.parseInt(reg));
            }
            if (upd) {
              item.updDt = timestampToDate(Number.parseInt(upd));
            }
          });
        }
        const pagination: IPaginationSnapshot = response.data.pagination;
        return {
          kind: `ok`,
          responseInfo: responseInfo,
          rulesetMappgs: rulesetMappgs,
          pagination: pagination,
        };
      }

      return { kind: 'ok', responseInfo: responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  /**
   * INF_CAIMS_00218
   * 해당 룰셋 매핑의 상세정보를 조회한다.
   * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=1852772833
   */
  async get(rulsetMappgFilter: IRulesetMappgFilter): Promise<TGetRulesetMappgResult> {
    try {
      const url: string = `/ruleset/${rulsetMappgFilter.ruleSetSid}/mappg/${rulsetMappgFilter.ruleSetMappgSid}`;
      const response: ApiResponse<any> = await this.api.apisauce.get(url);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }
      const responseInfo: IResponseSnapshot = response.data;

      if (responseInfo.resultCode === 'S') {
        if (response.data.data) {
          response.data.data.regDt = timestampToDate(Number.parseInt(response.data.data.regDt));
          response.data.data.updDt = timestampToDate(Number.parseInt(response.data.data.updDt));
          const rulesetMappg: IRulesetMappgSnapshot = response.data.data;

          return { kind: `ok`, responseInfo: responseInfo, rulesetMappg: rulesetMappg };
        }
      }

      return { kind: `ok`, responseInfo: responseInfo };
    } catch (e) {
      return { kind: `bad-data` };
    }
  }

  /**
   * INF_CAIMS_00219
   * 새로운 룰셋 매핑을 등록한다.
   * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=819674977
   */
  async post(rulesetMappg: IRulesetMappgSnapshot): Promise<TPostRulesetMappgResult> {
    try {
      let params = createSendData(rulesetMappg);
      const url: string = `/ruleset/${rulesetMappg.ruleSetSid}/mappg`;
      const response: ApiResponse<any> = await this.api.apisauce.post(url, params);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;

      if (responseInfo.resultCode === `S`) {
        if (response.data.data) {
          response.data.data.regDt = timestampToDate(Number.parseInt(response.data.data.regDt));
          response.data.data.updDt = timestampToDate(Number.parseInt(response.data.data.updDt));
          const rulsetMappg: IRulesetMappgSnapshot = response.data.data;
          return { kind: `ok`, responseInfo: responseInfo, rulesetMappg: rulsetMappg };
        }
      }

      return { kind: `ok`, responseInfo: responseInfo };
    } catch (e) {
      return { kind: `bad-data` };
    }
  }

  /**
   * INF_CAIMS_00220
   * 해당 룰셋 매핑의 정보를 수정한다.
   * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=155195551
   */
  async put(rulesetMappg: IRulesetMappgSnapshot): Promise<TUpdateRulesetMappgResult> {
    try {
      const params = createSendData(rulesetMappg);
      const url: string = `/ruleset/${rulesetMappg.ruleSetSid}/mappg/${rulesetMappg.ruleSetMappgSid}`;
      const response: ApiResponse<any> = await this.api.apisauce.put(url, params);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;

      if (responseInfo.resultCode === `S`) {
        if (response.data.data) {
          response.data.data.regDt = timestampToDate(Number.parseInt(response.data.data.regDt));
          response.data.data.updDt = timestampToDate(Number.parseInt(response.data.data.updDt));
          const rulsetMappg: IRulesetMappgSnapshot = response.data.data;
          return { kind: `ok`, responseInfo: responseInfo, rulesetMappg: rulsetMappg };
        }
      }
      return { kind: `ok`, responseInfo: responseInfo };
    } catch (e) {
      return { kind: `bad-data` };
    }
  }

  /**
   * INF_CAIMS_00241
   * 해당 룰셋 매핑 정보를 삭제한다.
   * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=913005681
   * @param ruleSetSid
   * @param ruleSetMappgSid
   * @returns
   */
  async delete(ruleSetSid: number, ruleSetMappgSid: number): Promise<TDeleteRulesetMappgResult> {
    try {
      const url: string = `/ruleset/${ruleSetSid}/mappg/${ruleSetMappgSid}`;
      const response: ApiResponse<any> = await this.api.apisauce.delete(url);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;

      return { kind: `ok`, responseInfo: responseInfo };
    } catch (e) {
      return { kind: `bad-data` };
    }
  }

  /**
   * INF_CAIMS_00217
   * 여러개 룰셋 매핑을 한번에 삭제한다.
   * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=617037567
   * @param ruleSetSid
   * @param deleteRulsetMappgList
   * @returns
   */
  async deletes(
    ruleSetSid: number,
    deleteRulsetMappgList: number[],
  ): Promise<TDeleteRulesetMappgResult> {
    try {
      let param: string = ``;
      deleteRulsetMappgList.forEach((item: number, index: number) => {
        param += `ruleSetMappgSidList=${item}`;
        if (index !== deleteRulsetMappgList.length - 1) {
          param += `&`;
        }
      });

      const url: string = `/ruleset/${ruleSetSid}/mappg?${param}`;
      const response: ApiResponse<any> = await this.api.apisauce.delete(url);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;

      return { kind: `ok`, responseInfo: responseInfo };
    } catch (e) {
      return { kind: `bad-data` };
    }
  }
}

const createSendData = (rulesetMappg: IRulesetMappgSnapshot) => {
  interface IRulesetMappgCond {
    condSid?: number | null;
    ruleSetSid?: number | null;
    ruleSetMappgSid?: number | null;
    extrtKey?: string | null;
    typeCond?: number | null;
    comprVal?: string | null;
  }

  interface IRulesetMappgSvrtCond {
    svrtCondSid?: number | null;
    ruleSetSid?: number | null;
    ruleSetMappgSid?: number | null;
    extrtKey?: string | null;
    typeCond?: number | null;
    comprVal?: string | null;
    svrt?: number | null;
  }

  interface ISendData {
    eventType: number | null;
    problCause: string | null;
    spcfProbl?: string | null;
    svrt: number | null;
    adtnInfo?: string | null;
    andOr: number | null;
    mappgOrdr?: number;
    portExtrtKey?: string;
    hostExtrtKey?: string;
    useYn: boolean | null;
    ruleSetMappgCondList: IRulesetMappgCond[];
    ruleSetMappgSvrtCondList: IRulesetMappgSvrtCond[];
  }

  let sendData: ISendData = {
    eventType: rulesetMappg.eventType?.code,
    problCause: rulesetMappg.problCause,
    svrt: rulesetMappg.svrt?.code,
    andOr: rulesetMappg.andOr?.code,
    useYn: rulesetMappg?.useYn,
    ruleSetMappgCondList: [],
    ruleSetMappgSvrtCondList: [],
  };

  if (rulesetMappg.spcfProbl !== undefined) {
    sendData.spcfProbl = rulesetMappg.spcfProbl;
  }

  if (rulesetMappg.mappgOrdr) {
    sendData.mappgOrdr = rulesetMappg.mappgOrdr;
  }

  if (rulesetMappg.portExtrtKey) {
    sendData.portExtrtKey = rulesetMappg.portExtrtKey;
  }

  if(rulesetMappg.hostExtrtKey) {
    sendData.hostExtrtKey = rulesetMappg.hostExtrtKey;
  }

  if (rulesetMappg.adtnInfo !== undefined) {
    sendData.adtnInfo = rulesetMappg.adtnInfo;
  }

  if (rulesetMappg.ruleSetMappgCondList) {
    rulesetMappg.ruleSetMappgCondList.forEach((item: IRulesetMappgCondSnapshot) => {
      if (item) {
        let mappgCond: IRulesetMappgCond = {
          condSid: item.condSid,
          ruleSetSid: item.ruleSetSid,
          ruleSetMappgSid: item.ruleSetMappgSid,
          extrtKey: ``,
          comprVal: ``,
        };

        if (item.extrtKey !== undefined) {
          mappgCond.extrtKey = item.extrtKey;
        } else {
          delete mappgCond.extrtKey;
        }

        if (item.comprVal !== undefined) {
          mappgCond.comprVal = item.comprVal;
        } else {
          delete mappgCond.comprVal;
        }

        if (item.typeCond) {
          mappgCond.typeCond = item.typeCond?.code;
        }
        sendData.ruleSetMappgCondList.push(mappgCond);
      }
    });
  }

  if (rulesetMappg.ruleSetMappgSvrtCondList) {
    rulesetMappg.ruleSetMappgSvrtCondList.forEach((item: IRulesetMappgSvrtCondSnapshot) => {
      if (item) {
        let mappgSvrtCond: IRulesetMappgSvrtCond = {
          svrtCondSid: item.svrtCondSid,
          ruleSetSid: item.ruleSetSid,
          ruleSetMappgSid: item.ruleSetMappgSid,
          extrtKey: ``,
          comprVal: ``,
        };

        if (item.extrtKey !== undefined) {
          mappgSvrtCond.extrtKey = item.extrtKey;
        } else {
          delete mappgSvrtCond.extrtKey;
        }

        if (item.comprVal !== undefined) {
          mappgSvrtCond.comprVal = item.comprVal;
        } else {
          delete mappgSvrtCond.comprVal;
        }

        if (item.typeCond) {
          mappgSvrtCond.typeCond = item.typeCond?.code;
        }
        if (item.svrt) {
          mappgSvrtCond.svrt = item.svrt?.code;
        }
        sendData.ruleSetMappgSvrtCondList.push(mappgSvrtCond);
      }
    });
  }

  return sendData;
};
