import { Box, Divider, Grid, GridProps, Stack, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React from 'react';

interface CFormSetProps extends GridProps {
  icon?: any;
  title?: string;
  subTitle?: string;
  subInfo?: string;
  children: React.ReactNode;
  column?: number;
  viewH?: number;
  viewHB?: boolean;
  seqId?: number | string | null;
}

/**
 * ## 기본 사용법
 *
 * > FormSet 컴포넌트 예시입니다.
 * > 여러 개의 입력 폼 세트가 필요한 경우 코드를 복사하여 새로운 컴포넌트를 생성합니다.
 * >
 * > 컴포넌트 네이밍은 해당 서비스명 뒤에 "FormSet"을 붙여서 사용합니다
 * > 예) VOCFormSet, AlarmFormSet
 *>
 * > ### Common Style
 * > * **formSetLabelStyle** : Form의 Label에 사용합니다.
 * > * **searchRowStyle**: tr 태그에 사용합니다.
 *
 */
const CFormSet = observer(function CFormSetProps({
  icon,
  title,
  subTitle,
  subInfo,
  children,
  column = 2,
  viewH = 50,
  viewHB = false,
  seqId,
  ...other
}: CFormSetProps) {
  return (
    <>
      <Box height={'auto'}>
        {title && (
          <>
            <Stack direction={'row'} alignItems={'center'} sx={{ ml: 0 }}>
              {icon && icon}
              <Typography margin={0} fontWeight={800} variant="h5" sx={{ ml: 1 }}>
                {seqId ? title + ' (ID : ' + (seqId ? seqId.toString() : '') + ')' : title}
              </Typography>
            </Stack>
            <Divider sx={{ m: 1 }} />
          </>
        )}
        {subTitle && !subInfo && (
          <Typography variant="h6" color="primary" sx={{ ml: 1 }}>
            {subTitle}
          </Typography>
        )}
        {subTitle && subInfo && (
          <Box sx={{display:'flex'}}>
            <Typography variant="h6" color="primary" sx={{ ml: 1, width: 'auto'}}>
              {subTitle}
            </Typography>
            <Typography color="primary" fontWeight={600} sx={{ ml: 5, width: 'auto', float:'right' }}>
              {subInfo}
            </Typography>
          </Box>
        )}

        <Box sx={{ flexGrow: 1 }}>
          {column === 4 ? (
            <Grid
              {...other}
              container
              spacing={{ xs: 2, sm: 2, md: 2, lg: 2 }}
              columns={{ xs: 4, sm: 4, md: 4, lg: 4 }}
              sx={{ pt: 2, pb: 1, pl: 1, pr: 2 }}
            >
              {children}
            </Grid>
          ) : column === 3 ? (
            <Grid
              {...other}
              container
              spacing={{ xs: 2, sm: 2, md: 2, lg: 2 }}
              columns={{ xs: 3, sm: 3, md: 3, lg: 3 }}
              sx={{ pt: 2, pb: 1, pl: 1, pr: 2 }}
            >
              {children}
            </Grid>
          ) : column === 2 ? (
            <Grid
              {...other}
              container
              spacing={{ xs: 2, sm: 2, md: 2, lg: 2 }}
              columns={{ xs: 2, sm: 2, md: 2, lg: 2 }}
              sx={{ pt: 2, pb: 1, pl: 1, pr: 2 }}
            >
              {children}
            </Grid>
          ) : (
            column === 1 && (
              <Grid
                {...other}
                container
                spacing={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                columns={{ xs: 1, sm: 1, md: 1, lg: 1 }}
                sx={{ pt: 2, pb: 1, pl: 1, pr: 2 }}
              >
                {children}
              </Grid>
            )
          )}
        </Box>
      </Box>
    </>
  );
});

export default CFormSet;
