import { Instance, SnapshotOut, types } from "mobx-state-tree"
import { createStbStatisticsData } from "../stb-statistics-data/StbStatisticsData";

/**
 * # OneviewStatisticsChart
 * 
 * OneviewStatisticsChart을 설명하세요.
 */
export const OneviewStatisticsChart = types
  .model("OneviewStatisticsChart")
  // --------------------------------------------------------------------------
  .props({
    statDt: types.string,
    preStatDt: types.string,
    dataList: types.maybeNull(types.array(createStbStatisticsData()))
  })
  // --------------------------------------------------------------------------
  // MUTATEs ONLY! - 모델 상태를 변경
  // REQUESTs는 스토어 모델에 작성하세요.
  // ex) yarn gen model oneviewStatisticsChart --store
  .actions((self) => ({
    /*
     * 여러 prop을 한번에 수정
     */
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as IOneviewStatisticsChart;
      (Object.keys(newProps) as TOneviewStatisticsChartKeys[]).forEach((key) => {
        //@ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type TOneviewStatisticsChart = Instance<typeof OneviewStatisticsChart>;
type TOneviewStatisticsChartSnapshot = SnapshotOut<typeof OneviewStatisticsChart>

export interface IOneviewStatisticsChart extends TOneviewStatisticsChart {}
export type TOneviewStatisticsChartKeys = keyof TOneviewStatisticsChartSnapshot & string;
export interface IOneviewStatisticsChartSnapshot extends TOneviewStatisticsChartSnapshot {}
export const createOneviewStatisticsChart = () => 
  types.optional(OneviewStatisticsChart, {} as TOneviewStatisticsChart);
