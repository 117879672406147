import { createModelCode } from './../model-code/ModelCode';
import { Instance, SnapshotOut, types } from 'mobx-state-tree';

/**
 * # AuthInfo
 *
 * AuthInfo을 설명하세요.
 */
export const AuthInfo = types
  .model('AuthInfo')
  // --------------------------------------------------------------------------
  .props({
    connSid: types.optional(types.maybeNull(types.number), null),
    authMethod: types.maybeNull(createModelCode()),
    authId: types.optional(types.maybeNull(types.string), null),
    authPwd: types.optional(types.maybeNull(types.string), null),
    psphrs: types.optional(types.maybeNull(types.string), null),
    prvtKey: types.optional(types.maybeNull(types.string), null),
    fingerprint: types.optional(types.maybeNull(types.string), null),
  })
  // --------------------------------------------------------------------------
  // MUTATEs ONLY! - 모델 상태를 변경
  // REQUESTs는 스토어 모델에 작성하세요.
  // ex) yarn gen model authInfo --store
  .actions((self) => ({
    /*
     * 여러 prop을 한번에 수정
     */
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as IAuthInfo;
      (Object.keys(newProps) as TAuthInfoKeys[]).forEach((key) => {
        //@ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type TAuthInfo = Instance<typeof AuthInfo>;
type TAuthInfoSnapshot = SnapshotOut<typeof AuthInfo>;

export interface IAuthInfo extends TAuthInfo {}
export type TAuthInfoKeys = keyof TAuthInfoSnapshot & string;
export interface IAuthInfoSnapshot extends TAuthInfoSnapshot {}
export const createAuthInfo = () =>
  types.optional(AuthInfo, {
    authId: null,
    authPwd: null,
    connSid: null,
    fingerprint: null,
    authMethod: null,
    prvtKey: null,
    psphrs: null,
  } as TAuthInfo);
