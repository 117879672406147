import { Instance, SnapshotOut, types } from 'mobx-state-tree';

/**
 * # Vendor
 *
 * Vendor을 설명하세요.
 */
export const Vendor = types
  .model('Vendor')
  // --------------------------------------------------------------------------
  .props({
    vendorSid: types.optional(types.maybeNull(types.number), null),
    vendorNm: types.optional(types.maybeNull(types.string), null),
    nmsNm: types.optional(types.maybeNull(types.string), null),
  })
  // --------------------------------------------------------------------------
  // MUTATEs ONLY! - 모델 상태를 변경
  // REQUESTs는 스토어 모델에 작성하세요.
  // ex) yarn gen model vendor --store
  .actions((self) => ({
    /*
     * 여러 prop을 한번에 수정
     */
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as IVendor;
      (Object.keys(newProps) as TVendorKeys[]).forEach((key) => {
        //@ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type TVendor = Instance<typeof Vendor>;
type TVendorSnapshot = SnapshotOut<typeof Vendor>;

export interface IVendor extends TVendor {}
export type TVendorKeys = keyof TVendorSnapshot & string;
export interface IVendorSnapshot extends TVendorSnapshot {}
export const createVendor = () =>
  types.optional(Vendor, {
    vendorSid: null,
    vendorNm: null,
    nmsNm: null,
  } as TVendor);
