import { createChangeValue } from './../change-value/ChangeValue';
import { Instance, SnapshotOut, types } from 'mobx-state-tree';

/**
 * # AdvmHistoryContent
 *
 * AdvmHistoryContent을 설명하세요.
 */
export const AdvmHistoryContent = types
  .model('AdvmHistoryContent')
  // --------------------------------------------------------------------------
  .props({
    skylifeAdvmSpatnYn: types.maybeNull(createChangeValue()),
    hcnAdvmSpatnYn: types.maybeNull(createChangeValue()),
    ktQtoneAPid: types.maybeNull(createChangeValue()),
    ktQtoneBPid: types.maybeNull(createChangeValue()),
    ktQtoneDPid: types.maybeNull(createChangeValue()),
    skylifeQtoneAPid: types.maybeNull(createChangeValue()),
    skylifeQtoneBPid: types.maybeNull(createChangeValue()),
    skylifeQtoneDPid: types.maybeNull(createChangeValue()),
    hcnQtoneAPid: types.maybeNull(createChangeValue()),
    hcnQtoneBPid: types.maybeNull(createChangeValue()),
    hcnQtoneDPid: types.maybeNull(createChangeValue()),
  })
  // --------------------------------------------------------------------------
  // MUTATEs ONLY! - 모델 상태를 변경
  // REQUESTs는 스토어 모델에 작성하세요.
  // ex) yarn gen model advmHistoryContent --store
  .actions((self) => ({
    /*
     * 여러 prop을 한번에 수정
     */
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as IAdvmHistoryContent;
      (Object.keys(newProps) as TAdvmHistoryContentKeys[]).forEach((key) => {
        self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type TAdvmHistoryContent = Instance<typeof AdvmHistoryContent>;
type TAdvmHistoryContentSnapshot = SnapshotOut<typeof AdvmHistoryContent>;

export interface IAdvmHistoryContent extends TAdvmHistoryContent {}
export type TAdvmHistoryContentKeys = keyof TAdvmHistoryContentSnapshot & string;
export interface IAdvmHistoryContentSnapshot extends TAdvmHistoryContentSnapshot {}
export const createAdvmHistoryContent = () =>
  types.optional(AdvmHistoryContent, {} as TAdvmHistoryContent);
