import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { CC } from '../../commonCodes/commonCodes';
import { selectOptions } from '../../components/forms/CSelect';
import { createPort, IPort, Port } from '../../models/port/Port';
import { TGetPortsResult, TPostPortResult } from '../../services';
import { PortApi } from '../../services/port/Port';
import { withEnvironment } from '../extensions/with-environment';
import { IModelCode, ModelCode } from '../model-code/ModelCode';
import { createPagination, IPagination, IPaginationSnapshot } from '../pagination/Pagination';
import { withRootStore } from './../extensions/with-root-store';
import { IPortSnapshot } from './../port/Port';

export const PortStore = types
  .model('PortStore')
  .props({
    ports: types.optional(types.array(Port), []),
    addPort: createPort(),

    //SD SWITCH
    sdSwInPortCodes: types.optional(types.maybeNull(types.array(ModelCode)), []),
    sdSwOutPortCodes: types.optional(types.maybeNull(types.array(ModelCode)), []),

    //TRANSCODER
    trcodInPortCodes: types.optional(types.maybeNull(types.array(ModelCode)), []),
    trcodOutPortCodes: types.optional(types.maybeNull(types.array(ModelCode)), []),

    //INPUT SWITCH
    inputSwInPortCodes: types.optional(types.maybeNull(types.array(ModelCode)), []),
    inputSwOutPortCodes: types.optional(types.maybeNull(types.array(ModelCode)), []),

    //IP ACO
    acoInPortCodes: types.optional(types.maybeNull(types.array(ModelCode)), []),
    acoOutPortCodes: types.optional(types.maybeNull(types.array(ModelCode)), []),
    acoAraInPortCodes: types.optional(types.maybeNull(types.array(ModelCode)), []),
    acoAraOutPortCodes: types.optional(types.maybeNull(types.array(ModelCode)), []),

    //HD MUX
    hdMuxInPortCodes: types.optional(types.maybeNull(types.array(ModelCode)), []),
    hdMuxOutPortCodes: types.optional(types.maybeNull(types.array(ModelCode)), []),
    hdMuxScsPortCodes: types.optional(types.maybeNull(types.array(ModelCode)), []),

    //SD MUX
    sdMuxInPortCodes: types.optional(types.maybeNull(types.array(ModelCode)), []),
    sdMuxOutPortCodes: types.optional(types.maybeNull(types.array(ModelCode)), []),
    sdMuxScsPortCodes: types.optional(types.maybeNull(types.array(ModelCode)), []),

    //OUTPUT SWITCH
    outputSwInPortCodes: types.optional(types.maybeNull(types.array(ModelCode)), []),
    outputSwOutPortCodes: types.optional(types.maybeNull(types.array(ModelCode)), []),

    pagination: createPagination(),
  })
  .extend(withEnvironment)
  .extend(withRootStore)
  .views((self) => ({}))
  .actions((self) => ({
    setPorts: (ports: IPortSnapshot[]) => {
      if (!ports) {
        return;
      }
      self.ports.replace(ports as IPort[]);
    },
    setAddPort: (port: IPort) => {
      self.addPort = port;
    },
    setResetPortCodes: () => {
      self.sdSwInPortCodes?.replace([]);
      self.sdSwOutPortCodes?.replace([]);
      self.trcodInPortCodes?.replace([]);
      self.trcodOutPortCodes?.replace([]);
      self.inputSwInPortCodes?.replace([]);
      self.inputSwOutPortCodes?.replace([]);
      self.acoInPortCodes?.replace([]);
      self.acoOutPortCodes?.replace([]);
      self.acoAraInPortCodes?.replace([]);
      self.acoAraOutPortCodes?.replace([]);
      self.hdMuxInPortCodes?.replace([]);
      self.hdMuxOutPortCodes?.replace([]);
      self.hdMuxScsPortCodes?.replace([]);
      self.sdMuxInPortCodes?.replace([]);
      self.sdMuxOutPortCodes?.replace([]);
      self.sdMuxScsPortCodes?.replace([]);
      self.outputSwInPortCodes?.replace([]);
      self.outputSwOutPortCodes?.replace([]);
    },
    //SD SWITCH
    setSdSwInPortCodes: () => {
      self.sdSwInPortCodes?.replace(getPortOptions(self.ports, CC.PORT_ROLE.In) as IModelCode[]);
    },
    setSdSwOutPortCodes: () => {
      self.sdSwOutPortCodes?.replace(getPortOptions(self.ports, CC.PORT_ROLE.Out) as IModelCode[]);
    },
    //TRANSCODER
    setTrcodInPortCodes: () => {
      self.trcodInPortCodes?.replace(getPortOptions(self.ports, CC.PORT_ROLE.In) as IModelCode[]);
    },
    setTrcodOutPortCodes: () => {
      self.trcodOutPortCodes?.replace(getPortOptions(self.ports, CC.PORT_ROLE.Out) as IModelCode[]);
    },
    //INPUT SWITCH
    setInputSwInPortCodes: () => {
      self.inputSwInPortCodes?.replace(getPortOptions(self.ports, CC.PORT_ROLE.In) as IModelCode[]);
    },
    setInputSwOutPortCodes: () => {
      self.inputSwOutPortCodes?.replace(
        getPortOptions(self.ports, CC.PORT_ROLE.Out) as IModelCode[],
      );
    },
    //IP ACO
    setAcoInPortCodes: () => {
      self.acoInPortCodes?.replace(getPortOptions(self.ports, CC.PORT_ROLE.In) as IModelCode[]);
    },
    setAcoOutPortCodes: () => {
      self.acoOutPortCodes?.replace(getPortOptions(self.ports, CC.PORT_ROLE.Out) as IModelCode[]);
    },
    setAcoAraInPortCodes: () => {
      self.acoAraInPortCodes?.replace(getPortOptions(self.ports, CC.PORT_ROLE.In) as IModelCode[]);
    },
    setAcoAraOutPortCodes: () => {
      self.acoAraOutPortCodes?.replace(
        getPortOptions(self.ports, CC.PORT_ROLE.Out) as IModelCode[],
      );
    },
    //HD MUX
    setHdMuxInPortCodes: () => {
      self.hdMuxInPortCodes?.replace(getPortOptions(self.ports, CC.PORT_ROLE.In) as IModelCode[]);
    },
    setHdMuxOutPortCodes: () => {
      self.hdMuxOutPortCodes?.replace(getPortOptions(self.ports, CC.PORT_ROLE.Out) as IModelCode[]);
    },
    setHdMuxScSPortCodes: () => {
      self.hdMuxScsPortCodes?.replace(
        getPortOptions(self.ports, CC.PORT_ROLE.CasLink) as IModelCode[],
      );
    },
    //SD MUX
    setSdMuxInPortCodes: () => {
      self.sdMuxInPortCodes?.replace(getPortOptions(self.ports, CC.PORT_ROLE.In) as IModelCode[]);
    },
    setSdMuxOutPortCodes: () => {
      self.sdMuxOutPortCodes?.replace(getPortOptions(self.ports, CC.PORT_ROLE.Out) as IModelCode[]);
    },
    setSdMuxScSPortCodes: () => {
      self.sdMuxScsPortCodes?.replace(
        getPortOptions(self.ports, CC.PORT_ROLE.CasLink) as IModelCode[],
      );
    },
    //OUTPUT SWITCH
    setOutputSwInPortCodes: () => {
      self.outputSwInPortCodes?.replace(
        getPortOptions(self.ports, CC.PORT_ROLE.In) as IModelCode[],
      );
    },
    setOutputSwOutPortCodes: () => {
      self.outputSwOutPortCodes?.replace(
        getPortOptions(self.ports, CC.PORT_ROLE.Out) as IModelCode[],
      );
    },
    setPagination: (pagination: IPaginationSnapshot) => {
      self.pagination = pagination as IPagination;
    },
  }))
  .actions((self) => ({
    /**
     * INF_CAIMS_00222
     * 해당 장비의 포트 목록을 조회한다.
     * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=1422075397
     * @param portFilter
     * @returns
     */
    gets: async (portFilter: TPortFilter) => {
      try {
        const portApi: PortApi = new PortApi(self.environment.api);
        let result: TGetPortsResult;

        result = await portApi.gets(self.pagination, portFilter);
        if (self.rootStore.responseStore.getResponseResult(result)) {
          if (!result.ports) {
            return;
          }
          // 여기 제발 주석 풀지 마세요....!!!!!!! pagination 안쓰면 삭제 부탁드립니다.
          // if (!result.pagination) {
          //   return;
          // }
          self.setPorts(result.ports);
          // self.setPagination(result.pagination);
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },

    /**
     * INF_CAIMS_00223
     * 해당 장비의 포트를 등록한다.
     * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=1445380629
     * @param port
     */
    post: async (port: IPort) => {
      try {
        const portApi: PortApi = new PortApi(self.environment.api);
        const result: TPostPortResult = await portApi.post(port);

        if (self.rootStore.responseStore.getResponseResult(result)) {
          //self.addPort.resetPort();
          port.portSid = result.port?.portSid || null;
          self.setAddPort(port);
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },
    /**
     * GET ALL PORT AS PORT OPTION
     */
    getPortCode: async ( portRole: number) => {
      try {
        return getPortOptions(self.ports, portRole)
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    }
  }));

// --------------------------------------------------------------------------
type TPortStore = Instance<typeof PortStore>;
type TPortStoreSnapshot = SnapshotOut<typeof PortStore>;

export interface IPortStore extends TPortStore {}
export type TPortStoreKeys = keyof TPortStoreSnapshot & string;
export interface IPortStoreSnapshot extends TPortStoreSnapshot {}
export const createPortStore = () => types.optional(PortStore, {} as TPortStore);
export const createOption = () => {};

export interface IPortFilter {
  equipSid: number;
  portNm?: string;
  portType?: number;
  portRole?: number;
}

export type TPortFilter = IPortFilter;

const getPortOptions = (ports: IPort[], portRole: number) => {
  const portOptions: selectOptions[] = [];
  ports
    .filter((p) => p.portRole?.code === portRole || p.portRole?.code === CC.PORT_ROLE.General)
    .map((el) => {
      portOptions.push({ code: el.portSid, pcode: el.portSid, value: el.portNm });
    });
  return portOptions;
};
