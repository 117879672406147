import MenuIcon from '@mui/icons-material/Menu';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import TuneIcon from '@mui/icons-material/Tune';
import { Button, Chip, Tooltip, Typography, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import {
    Dispatch,
    SetStateAction,
    useEffect,
    useRef,
    useState
} from 'react';
import { IRealTimeAlarmSnapshot, IRealtimeMonitoringAlarmFilter, useStores } from '../../../models';

interface SearchAreaButtonAndSeverityChipProps {
  searchData: IRealtimeMonitoringAlarmFilter | undefined;
  setOpenSearchArea: Dispatch<SetStateAction<boolean>>;
  setOpenAlarmSetup: Dispatch<SetStateAction<boolean>>;
}

export const SearchAreaButtonAndSeverityChip = observer(function SearchAreaButtonAndSeverityChip({
  searchData,
  setOpenSearchArea,
  setOpenAlarmSetup
}: SearchAreaButtonAndSeverityChipProps) {
  const rootStore = useStores();
  const theme = useTheme();
  const { realtimeAlarmStore, loadingStore } = rootStore;

  const [isMute, setIsMute] = useState(false)

  useEffect(()=>{
    realtimeAlarmStore.setAlarmSoundMute(isMute)
  }, [isMute])

  const severityChip = (severity: string, count: number) => {
    const bg =
      severity === 'Critical'
        ? '#FF0000'
        : severity === 'Major'
        ? '#FFA912'
        : severity === 'Minor'
        ? '#E4FF12'
        : '#12ff4d';

    return (
      <Chip
        size={'small'}
        variant={'outlined'}
        sx={{
          borderWidth: 2,
          borderColor: bg,
          color: '#FFF',
          ml: 1,
          fontSize: '1rem',
          fontWeight: '900',
        }}
        label={count}
      />
    );
  };

  const tableBox = useRef<HTMLElement>();
  const [boxHeight, setBoxHeight] = useState(0);
  useEffect(() => {
    const timer = setInterval(() => {
      if (tableBox.current) {
        setBoxHeight(tableBox.current.offsetTop + 150);
      }
    }, 500);
    return () => {
      clearInterval(timer);
    };
  }, [tableBox.current?.offsetTop]);

  const alarmFilter = (row: IRealTimeAlarmSnapshot) => {
    if (searchData === undefined) {
      return row;
    }

    const eventType = JSON.stringify(row.eventType?.code);
    const instanceType = JSON.stringify(row.instanceType?.code);

    const perceivedSeverity = row.perceivedSeverity?.code;
    const eventState = row.eventState?.code;
    const probableCause = row.probableCause;

    let res: boolean = true;
    if (searchData.eventTimeSt) {
      const st = moment(searchData.eventTimeSt).format('YYYY-MM-DD HH:mm:ss');
      res = row.eventTime && row.eventTime >= st ? res : false;
    }
    if (searchData.eventTimeEd) {
      const ed = moment(searchData.eventTimeEd).format('YYYY-MM-DD HH:mm:ss');
      res = row.eventTime && row.eventTime <= ed ? res : false;
    }

    if (searchData.clearanceTimeSt) {
      const st = moment(searchData.clearanceTimeSt).format('YYYY-MM-DD HH:mm:ss');
      res = row.clearanceTime && row.clearanceTime >= st ? res : false;
    }
    if (searchData.clearanceTimeEd) {
      const ed = moment(searchData.clearanceTimeEd).format('YYYY-MM-DD HH:mm:ss');
      res = row.clearanceTime && row.clearanceTime <= ed ? res : false;
    }

    if (searchData.targetInstanceName && searchData.targetInstanceName.length > 0) {
      res = row.targetInstanceName?.toLowerCase().includes(searchData.targetInstanceName)
        ? res
        : false;
    }
    if (searchData.targetInstanceId && searchData.targetInstanceId.length > 0) {
      res =
        row.targetInstanceId &&
        row.targetInstanceId.toLowerCase().includes(searchData.targetInstanceId)
          ? res
          : false;
    }
    if (searchData.specificProblem) {
      res = row.specificProblem?.toLowerCase().includes(searchData.specificProblem.toLowerCase())
        ? res
        : false;
    }
    if (searchData.message) {
      res = row.message?.toLowerCase().includes(searchData.message.toLowerCase()) ? res : false;
    }
    if (
      perceivedSeverity &&
      searchData.perceivedSeverityList &&
      searchData.perceivedSeverityList.length > 0
    ) {
      res = searchData.perceivedSeverityList.includes(perceivedSeverity) ? res : false;
    }
    if (eventState && searchData.eventStateList && searchData.eventStateList.length > 0) {
      res = searchData.eventStateList.includes(eventState) ? res : false;
    }

    return res;
  };

  const openSearchArea = () => {
    setOpenSearchArea((prev) => !prev);
  };

  const backgroundColor = isMute ? '#3366FF': 'none'

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'flex-end',
          background: theme.palette.background.default,
          p: 1,
          gap: '8px',
        }}
      >

        <Tooltip title="Alarm Setup" arrow={true}>
          <Button
            sx={{
              color: 'white',
              borderColor: 'white',
              '&:hover': {
                borderColor: 'gray',
              },
            }}
            onClick={()=>setOpenAlarmSetup(true)}
            variant="outlined"
          >
            <TuneIcon />{' '}
          </Button>
        </Tooltip>
        <Tooltip title={isMute ? "Unmute alarm" : "Mute alarm"} arrow={true}>
          <Button
            sx={{
              backgroundColor,
              color: 'white',
              borderColor: 'white',
              '&:hover': {
                borderColor: 'gray',
              },
            }}
            variant="outlined"
            onClick={()=>setIsMute((prev) => !prev)}
          >
           {isMute ? <VolumeOffIcon />: <VolumeUpIcon />} 
          </Button>
        </Tooltip>

        

        <Tooltip title="Search area" arrow={true}>
          <Button
            sx={{
              color: 'white',
              borderColor: 'white',
              '&:hover': {
                borderColor: 'gray',
              },
            }}
            onClick={openSearchArea}
            variant="outlined"
          >
            <MenuIcon />{' '}
          </Button>
        </Tooltip>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            background: theme.palette.background.default,
            p: 2,
          }}
        >
          <Typography variant="h6" sx={{ fontSize: '0.9rem', mr: 1 }}>
            전체 {realtimeAlarmStore.alarms.filter(alarmFilter).length}건
          </Typography>
          {severityChip(
            'Critical',
            realtimeAlarmStore.alarms
              .filter(alarmFilter)
              .filter((alarm) => alarm.perceivedSeverity?.value === 'Critical').length,
          )}
          {severityChip(
            'Major',
            realtimeAlarmStore.alarms
              .filter(alarmFilter)
              .filter((alarm) => alarm.perceivedSeverity?.value === 'Major').length,
          )}
          {severityChip(
            'Minor',
            realtimeAlarmStore.alarms
              .filter(alarmFilter)
              .filter((alarm) => alarm.perceivedSeverity?.value === 'Minor').length,
          )}
          {severityChip(
            'Etc',
            realtimeAlarmStore.alarms
              .filter(alarmFilter)
              .filter(
                (alarm) =>
                  alarm.perceivedSeverity?.value !== null &&
                  alarm.perceivedSeverity?.value !== undefined &&
                  !['Minor', 'Major', 'Critical'].includes(alarm.perceivedSeverity.value),
              ).length,
          )}
        </Box>
      </Box>
    </>
  );
});

export default SearchAreaButtonAndSeverityChip;
