import { Instance, SnapshotOut, types } from "mobx-state-tree"
import {createMobile} from "../mobile/Mobile";

/**
 * # SmsEmail
 * 
 * SmsEmail을 설명하세요.
 */
export const SmsEmail = types
  .model("SmsEmail")
  // --------------------------------------------------------------------------
  .props({
      usrgrpSid: types.optional(types.number, 0),
      minor: types.optional(types.maybeNull(types.boolean), false),
      major: types.optional(types.maybeNull(types.boolean), false),
      critical: types.optional(types.maybeNull(types.boolean), false),
      sentry: types.optional(types.maybeNull(types.boolean), false),
      encoder: types.optional(types.maybeNull(types.boolean), false),
      l3: types.optional(types.maybeNull(types.boolean), false),
      ipAco: types.optional(types.maybeNull(types.boolean), false),
      mux: types.optional(types.maybeNull(types.boolean), false),
      ni: types.optional(types.maybeNull(types.boolean), false),
      mspp: types.optional(types.maybeNull(types.boolean), false),
      channel: types.optional(types.maybeNull(types.boolean), false),
      quetone: types.optional(types.maybeNull(types.boolean), false),
      misTrans: types.optional(types.maybeNull(types.boolean), false),
      dpBroadCast: types.optional(types.maybeNull(types.boolean), false),
      equip: types.optional(types.maybeNull(types.boolean), false),
      tsNet: types.optional(types.maybeNull(types.boolean), false),
      sysLink: types.optional(types.maybeNull(types.boolean), false),
      business: types.optional(types.maybeNull(types.boolean), false),
      admin: types.optional(types.maybeNull(types.boolean), false),
      mobileList: types.optional(types.maybeNull(types.array(createMobile())), []),
  })
  // --------------------------------------------------------------------------
  // MUTATEs ONLY! - 모델 상태를 변경
  // REQUESTs는 스토어 모델에 작성하세요.
  // ex) yarn gen model smsEmail --store
  .actions((self) => ({
    /*
     * 여러 prop을 한번에 수정
     */
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as ISmsEmail;
      (Object.keys(newProps) as TSmsEmailKeys[]).forEach((key) => {
        // @ts-ignore
          self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type TSmsEmail = Instance<typeof SmsEmail>;
type TSmsEmailSnapshot = SnapshotOut<typeof SmsEmail>

export interface ISmsEmail extends TSmsEmail {}
export type TSmsEmailKeys = keyof TSmsEmailSnapshot & string;
export interface ISmsEmailSnapshot extends TSmsEmailSnapshot {}
export const createSmsEmail = () => 
  types.optional(SmsEmail, { } as TSmsEmail);
