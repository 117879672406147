import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { createSimpleUser } from '../simple-user/SimpleUser';
import { createModelCode } from '../model-code/ModelCode';

export const BatchHistory = types
  .model('BatchHistory')
  .props({
    // eventData: types.optional(types.maybeNull(types.string), ''),
    // eventDt: types.optional(types.maybeNull(types.string), null),
    // intrlkSysSid: types.optional(types.maybeNull(types.number), 0),
    // sysNm: types.optional(types.maybeNull(types.string), ''),
    // updUser: types.maybeNull(createSimpleUser()),
    // regUser: types.maybeNull(createSimpleUser()),
    // updDt: types.optional(types.maybeNull(types.string), null),
    // regDt: types.optional(types.maybeNull(types.string), null),

    batchHistorySid: types.optional(types.maybeNull(types.number), 0),
    batchNm: types.optional(types.maybeNull(types.string), ''),
    dispBatchNm:  types.optional(types.maybeNull(types.string), ''),
    batchCycl:  types.optional(types.maybeNull(types.string), ''),
    endDt: types.optional(types.maybeNull(types.string), null),
    errMsg: types.optional(types.maybeNull(types.string), ''),
    exeSttus: types.maybeNull(createModelCode()),
    intrlkFailCnt: types.optional(types.maybeNull(types.number), 0),
    intrlkSysSid: types.optional(types.maybeNull(types.number), 0),
    
    startDt:  types.optional(types.maybeNull(types.string), null),
    sucesYn: types.optional(types.boolean, false),
    sysNm: types.optional(types.maybeNull(types.string), ''),
    transacId: types.optional(types.maybeNull(types.string), ''),
    updDt:  types.optional(types.maybeNull(types.string), null),
    regDt:  types.optional(types.maybeNull(types.string), null),

    
    updUser: types.maybeNull(createSimpleUser()),
    regUser: types.maybeNull(createSimpleUser()),
  })
  .actions((self) => ({
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as IBatchHistory;
      (Object.keys(newProps) as TBatchHistoryKeys[]).forEach((key) => {
        //@ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

type TBatchHistory = Instance<typeof BatchHistory>;
type TBatchHistorySnapshot = SnapshotOut<typeof BatchHistory>;

export interface IBatchHistory extends TBatchHistory {}
export type TBatchHistoryKeys = keyof TBatchHistory & string;
export interface IBatchHistorySnapshot extends TBatchHistorySnapshot {}
export const createBatchHistory = () =>
  types.optional(BatchHistory, {
    batchHistorySid: null,
    batchNm:  null,
    endDt:  null,
    errMsg:  null,
    exeSttus:  null,
    intrlkFailCnt: 0,
    intrlkSysSid:  null,
    
    startDt:   null,
    sucesYn: false,
    sysNm:  null,
    transacId:  null,
    updDt:   null,
    regDt:   null,
    
    updUser:  null,
    regUser:  null,
  } as TBatchHistory);
