import { ApiResponse } from 'apisauce';
import moment from 'moment';
import { Api } from '../api/api';
import { getGeneralApiProblem } from '../api/api-problem';
import { IPaginationSnapshot } from './../../models/pagination/Pagination';
import { IResponseSnapshot } from './../../models/response/Response';
import { IRulesetFilter, IRulesetSnapshot } from './../../models/ruleset/Ruleset';
import { IUserFilter } from './../../models/user-store/user-store';
import { dateToTimestamp, timestampToDate } from './../../utils/dateExchanger';
import {
  TClass,
  TDeleteRulesetResult,
  TGetRulesetResult,
  TGetRulesetsResult,
  TPostRulesetResult,
  TTestRulesetResult,
  TUpdateRulesetResult,
} from './RulesetTypes';

/**
 * # Ruleset Api 서비스
 *
 *
 * ## 사용방법
 *
 * ```ts
 * const service = new RulesetApi();
 *
 * ```
 */
export class RulesetApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  /**
   * INF_CAIMS_00042
   * 룰셋 목록을 조회한다.
   * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=1838651141
   * @returns
   */
  async gets(
    pagination: IPaginationSnapshot,
    rulesetFilter?: IRulesetFilter,
  ): Promise<TGetRulesetsResult> {
    try {
      interface IGetParam extends IUserFilter {
        page: number;
        size: number;
      }

      const url: string = '/ruleset';
      let param: IGetParam = { page: pagination.page, size: pagination.size };
      if (rulesetFilter) {
        if(rulesetFilter.regDtSt){
          rulesetFilter.regDtSt = moment(rulesetFilter.regDtSt).format('YYYY-MM-DD 00:00:00');
        }
        if(rulesetFilter.regDtEd){
          rulesetFilter.regDtEd = moment(rulesetFilter.regDtEd).format('YYYY-MM-DD 23:59:59');
        }
        if(rulesetFilter.updDtSt){
          rulesetFilter.updDtSt = moment(rulesetFilter.updDtSt).format('YYYY-MM-DD 00:00:00');
        }
        if(rulesetFilter.updDtEd){
          rulesetFilter.updDtEd = moment(rulesetFilter.updDtEd).format('YYYY-MM-DD 23:59:59');
        }
      }
      param = { ...param, ...rulesetFilter };

      const response: ApiResponse<any> = await this.api.apisauce.get(url, param);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data as IResponseSnapshot;
      if (responseInfo.resultCode === 'S') {
        const pagination: IPaginationSnapshot = response.data.pagination as IPaginationSnapshot;
        const rulesets: IRulesetSnapshot[] = response.data.data as IRulesetSnapshot[];
        rulesets.forEach((ruleset: IRulesetSnapshot) => {
          ruleset.regDt = timestampToDate(Number.parseInt(ruleset.regDt));
          ruleset.updDt = timestampToDate(Number.parseInt(ruleset.updDt));
        });
        return {
          kind: 'ok',
          responseInfo: responseInfo,
          rulesets: rulesets,
          pagination: pagination,
        };
      }

      return { kind: 'ok', responseInfo: responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  /**
   * INF_CAIMS_00043
   * 해당 룰셋의 상세정보를 조회한다.
   * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=1005912353
   */
  async get(ruleSetSid: number): Promise<TGetRulesetResult> {
    try {
      const url: string = `/ruleset/${ruleSetSid}`;
      const response: ApiResponse<any> = await this.api.apisauce.get(url);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data as IResponseSnapshot;
      if (responseInfo.resultCode === 'S') {
        if (response.data.data) {
          const ruleset: IRulesetSnapshot = response.data.data as IRulesetSnapshot;
          ruleset.regDt = timestampToDate(Number.parseInt(ruleset.regDt));
          ruleset.updDt = timestampToDate(Number.parseInt(ruleset.updDt));
          return { kind: `ok`, responseInfo: responseInfo, ruleset: ruleset };
        }
      }
      return { kind: `ok`, responseInfo: responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  /**
   * INF_CAIMS_00044
   * 새로운 룰셋을 등록한다.
   * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=1123699119
   */
  async post(ruleset: IRulesetSnapshot): Promise<TPostRulesetResult> {
    try {
      const url: string = '/ruleset';
      const params = createSendData(ruleset);
      const response: ApiResponse<any> = await this.api.apisauce.post(url, params);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data as IResponseSnapshot;
      if (responseInfo.resultCode === `S`) {
        if (response.data.data) {
          const ruleset: IRulesetSnapshot = response.data.data;
          ruleset.regDt = timestampToDate(Number.parseInt(ruleset.regDt));
          ruleset.updDt = timestampToDate(Number.parseInt(ruleset.updDt));
          return { kind: `ok`, responseInfo: responseInfo, ruleset: ruleset };
        }
      }

      return { kind: `ok`, responseInfo: responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  /**
   * INF_CAIMS_00045
   * 룰셋 정보를 수정한다.
   * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=1096816438
   */
  async put(ruleset: IRulesetSnapshot): Promise<TUpdateRulesetResult> {
    try {
      const url: string = `/ruleset/${ruleset.ruleSetSid}`;
      const params = createSendData(ruleset);
      const response: ApiResponse<any> = await this.api.apisauce.put(url, params);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data as IResponseSnapshot;
      if (responseInfo.resultCode === `S`) {
        if (response.data.data) {
          const ruleset: IRulesetSnapshot = response.data.data;
          ruleset.regDt = timestampToDate(Number.parseInt(ruleset.regDt));
          ruleset.updDt = timestampToDate(Number.parseInt(ruleset.updDt));
          return { kind: `ok`, responseInfo: responseInfo, ruleset: ruleset };
        }
      }
      return { kind: `ok`, responseInfo: responseInfo };
    } catch (e) {
      return { kind: `bad-data` };
    }
  }

  /**
   * INF_CAIMS_00046
   * 룰셋 정보를 삭제한다.
   * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=1510356721
   */
  async delete(ruleSetSid: number): Promise<TDeleteRulesetResult> {
    try {
      const url: string = `/ruleset/${ruleSetSid}`;
      const response: ApiResponse<any> = await this.api.apisauce.delete(url);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data as IResponseSnapshot;
      return { kind: `ok`, responseInfo: responseInfo };
    } catch (e) {
      return { kind: `bad-data` };
    }
  }

  /**
   * INF_CAIMS_00047
   * 여러개 룰셋을 한번에 삭제한다.
   * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=395346139
   */
  async deleteList(deleteRulesetList: number[]): Promise<TDeleteRulesetResult> {
    try {
      let param: string = ``;
      deleteRulesetList.forEach((item: number, index: number) => {
        param += `ruleSetSidList=${item}`;
        if (index !== deleteRulesetList.length - 1) {
          param += `&`;
        }
      });

      const url: string = `/ruleset?${param}`;
      const response: ApiResponse<any> = await this.api.apisauce.delete(url);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data as IResponseSnapshot;
      return { kind: `ok`, responseInfo: responseInfo };
    } catch (e) {
      return { kind: `bad-data` };
    }
  }

  /**
   * INF_CAIMS_00048
   * 해당 룰셋을 테스트 한다.
   * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=1178202965
   * @returns
   */
  async rulesetTest(ruleSetSid: number, testStirng: string): Promise<TTestRulesetResult> {
    try {
      const url: string = `/ruleset/${ruleSetSid}/test?testString=${testStirng}`;
      const response: ApiResponse<any> = await this.api.apisauce.get(url);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;

      if (responseInfo.resultCode === `S`) {
        if (response.data.data) {
          const testResult: string = JSON.stringify(response.data.data);
          return { kind: `ok`, responseInfo: responseInfo, testResult: testResult };
        }
      }
      return { kind: `ok`, responseInfo: responseInfo };
    } catch (e) {
      return { kind: `bad-data` };
    }
  }

  async getClassList(): Promise<TClass> {
    try {
      const url: string = '/ruleset/extrtClass';
      const response: ApiResponse<any> = await this.api.apisauce.get(url);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) {
          return problem;
        }
      }

      const responseInfo: IResponseSnapshot = response.data;

      if (responseInfo.resultCode === 'S') {
        if (response.data.data) {
          const classList: string[] = response.data.data as string[];
          return {kind: 'ok', responseInfo: responseInfo, classList: classList}
        }
      }
      return {kind: `ok`, responseInfo: responseInfo};
    } catch (e) {
      return {kind: 'bad-data'};
    }
  }
}

const createSendData = (ruleset: IRulesetSnapshot) => {
  let result: any = {};

  if (ruleset.ruleSetNm !== undefined) {
    result.ruleSetNm = ruleset.ruleSetNm;
  }
  if (ruleset.equipType) {
    result.equipType = ruleset.equipType.code;
  }
  if (ruleset.ruleSetType) {
    result.ruleSetType = ruleset.ruleSetType.code;
  }
  if (ruleset.analsType) {
    result.analsType = ruleset.analsType.code;
  }
  if (ruleset.useYn !== undefined) {
    result.useYn = ruleset.useYn;
  }
  if (ruleset.ruleSetNm !== undefined) {
    result.ruleSetNm = ruleset.ruleSetNm;
  }
  if (ruleset.intrlkSysSid) {
    result.intrlkSysSid = ruleset.intrlkSysSid;
  }
  if (ruleset.valExtrtClassNm !== undefined) {
    result.valExtrtClassNm = ruleset.valExtrtClassNm;
  }
  if (ruleset.analsRule !== undefined) {
    result.analsRule = ruleset.analsRule;
  }
  return result;
};
