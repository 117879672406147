import { toJS } from 'mobx';
import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { CC } from '../../commonCodes/commonCodes';
import {
  StbStatisticsApi,
  TGetOneviewGaugesResult,
  TGetStbStatisticsAlarmResult as TGetStbStatisticsAlarmResult,
  TGetVocStatisticsResultForOneview,
  TGetWatchStatisticssDataResult,
  VocStatisticsApi,
  WatchStatisticsApi,
  GaugeApi,
  TGetVocBubbleChart,
  TGetStbStatisticsOneviewResult
} from '../../services';
import { SidebarforMap, SidebarforMapNew, SidebarforSTBChart } from '../../utils/oneViewFunc';
import { withEnvironment } from '../extensions/with-environment';
import { withRootStore } from '../extensions/with-root-store';
import { OneviewStatisticsChart } from '../oneview-statistics-chart/OneviewStatisticsChart';
import {
  IOneViewStatisticsGraphSnapshot,
  createOneViewStatisticsGraph,
} from '../oneview-statistics-graph/OneViewStatisticsGraph';
import {
  IOneViewStatisticsMap,
  IOneViewStatisticsMapSnapshot,
  OneViewStatisticsMap,
} from '../oneview-statistics-map/OneViewStatisticsMap';
import {
  IStbStatisticsData,
  IStbStatisticsDataSnapshot,
  StbStatisticsData,
} from '../stb-statistics-data/StbStatisticsData';
import {
  IWatchStatisticsData,
  IWatchStatisticsDataSnapshot,
  WatchStatisticsData,
} from '../watch-statistics-data/WatchStatisticsData';
import { OneviewGauge, IOneviewGauge, IOneviewGaugeSnapshot } from '../oneview-gauge/OneviewGauge';
import { BubbleChart, IBubbleChart, IBubbleChartSnapshot } from '../bubble-chart/BubbleChart';


const defaultData: IOneViewStatisticsMapSnapshot[] = [
  { name: '경기', x: 142, y: 84, value: 0, preValue: 0, compareValue: 0, state: 'none', fullName: '경기도' },
  { name: '인천', x: 118, y: 136, value: 0, preValue: 0, compareValue: 0, state: 'none', fullName: '인천광역시' },
  { name: '서울', x: 179, y: 128, value: 0, preValue: 0, compareValue: 0, state: 'none', fullName: '서울특별시' },
  { name: '강원', x: 302, y: 90, value: 0, preValue: 0, compareValue: 0, state: 'none', fullName: '강원특별자치도' },
  { name: '충북', x: 230, y: 180, value: 0, preValue: 0, compareValue: 0, state: 'none', fullName: '충청북도' },
  { name: '세종', x: 192, y: 217, value: 0, preValue: 0, compareValue: 0, state: 'none', fullName: '세종특별자치시' },
  { name: '충남', x: 127, y: 235, value: 0, preValue: 0, compareValue: 0, state: 'none', fullName: '충청남도' },
  { name: '경북', x: 300, y: 235, value: 0, preValue: 0, compareValue: 0, state: 'none', fullName: '경상북도' },
  { name: '대전', x: 192, y: 255, value: 0, preValue: 0, compareValue: 0, state: 'none', fullName: '대전광역시' },
  { name: '대구', x: 282, y: 290, value: 0, preValue: 0, compareValue: 0, state: 'none', fullName: '대구광역시' },
  { name: '전북', x: 168, y: 298, value: 0, preValue: 0, compareValue: 0, state: 'none', fullName: '전라북도' },
  { name: '울산', x: 342, y: 319, value: 0, preValue: 0, compareValue: 0, state: 'none', fullName: '울산광역시' },
  { name: '경남', x: 255, y: 350, value: 0, preValue: 0, compareValue: 0, state: 'none', fullName: '경상남도' },
  { name: '광주', x: 170, y: 356, value: 0, preValue: 0, compareValue: 0, state: 'none', fullName: '광주광역시' },
  { name: '부산', x: 312, y: 365, value: 0, preValue: 0, compareValue: 0, state: 'none', fullName: '부산광역시' },
  { name: '전남', x: 170, y: 398, value: 0, preValue: 0, compareValue: 0, state: 'none', fullName: '전라남도' },
  { name: '제주', x: 126, y: 485, value: 0, preValue: 0, compareValue: 0, state: 'none', fullName: '제주특별자치도' },
];

const defaultChartData: IStbStatisticsDataSnapshot[] = [
  {
    model: 'NXS006',
    region: '',
    cnt: 0,
    preCnt: 0,
    rateCnt: 0,
  },
  {
    model: 'CVS332',
    region: '',
    cnt: 0,
    preCnt: 0,
    rateCnt: 0,
  },
  {
    model: 'SCD022',
    region: '',
    cnt: 0,
    preCnt: 0,
    rateCnt: 0,
  },
  {
    model: 'YJK321',
    region: '',
    cnt: 0,
    preCnt: 0,
    rateCnt: 0,
  },
  {
    model: 'KWS332',
    region: '',
    cnt: 0,
    preCnt: 0,
    rateCnt: 0,
  },
  {
    model: 'CH0321',
    region: '',
    cnt: 0,
    preCnt: 0,
    rateCnt: 0,
  },
  {
    model: 'XWD521',
    region: '',
    cnt: 0,
    preCnt: 0,
    rateCnt: 0,
  },
];


export const OneViewStatisticsStore = types
  .model('OneViewStatisticsStore')
  .props({
    updated: types.optional(types.number, 0),
    oneViewTime: types.optional(types.Date, new Date()),
    recivedTimeVocMap: types.optional(types.Date, new Date()),
    recivedTimeRatingMap: types.optional(types.Date, new Date()),
    recivedTimeStbMap: types.optional(types.Date, new Date()),
    oneViewVocMapData: types.optional(types.array(OneViewStatisticsMap), []),
    oneViewVocMapDataOrigin: types.optional(types.array(OneviewStatisticsChart), []),
    oneViewWatchMapData: types.optional(types.array(OneViewStatisticsMap), []),
    oneViewWatchMapDataOrigin: types.optional(types.array(OneviewStatisticsChart), []),
    oneViewStbMapData: types.optional(types.array(OneViewStatisticsMap), []),
    oneViewStbMapDataOrigin: types.optional(types.array(OneviewStatisticsChart), []),

    oneViewBubbleChartData: types.optional(types.array(BubbleChart), []),
    oneViewVocGraphData: createOneViewStatisticsGraph(),
    oneViewWatchChartData: types.optional(types.array(WatchStatisticsData), []),
    oneViewStbChartData: types.optional(types.array(StbStatisticsData), []),
    oneViewStbChartDataOrigin: types.optional(types.array(OneviewStatisticsChart), []),

    oneViewGauge: types.optional(types.array(OneviewGauge), []),
  })
  .extend(withEnvironment)
  .extend(withRootStore)
  .views((self) => ({}))
  .actions((self) => ({
    setUpdated: () => {
      self.updated++;
    },
    setOneViewTime: (date: Date) => {
      self.oneViewTime = date;
    },
    setRecivedTimeVocMap: (date: Date) => {
      self.recivedTimeVocMap = date;
    },
    setRecivedTimeRatingMap: (date: Date) => {
      self.recivedTimeRatingMap = date;
    },
    setRecivedTimeStbMap: (date: Date) => {
      self.recivedTimeStbMap = date;
    },
    setOneViewVocMapData: (data: IOneViewStatisticsMapSnapshot[]) => {
      self.oneViewVocMapData.replace(data as IOneViewStatisticsMap[]);
    },
    setOneViewVocMapDataOrigin: (data: any) => {
      self.oneViewVocMapDataOrigin.replace(data);
    },
    setOneViewWatchMapData: (data: IOneViewStatisticsMapSnapshot[]) => {
      self.oneViewWatchMapData.replace(data as IOneViewStatisticsMap[]);
    },
    setOneViewWatchMapDataOrigin: (data: any) => {
      self.oneViewWatchMapDataOrigin.replace(data);
    },
    setOneViewStbMapData: (data: IOneViewStatisticsMapSnapshot[]) => {
      self.oneViewStbMapData.replace(data as IOneViewStatisticsMap[]);
    },
    setOneViewStbMapDataOrigin: (data: any) => {
      self.oneViewStbMapDataOrigin.replace(data);
    },
    setOneViewVocGraphData: (data: IOneViewStatisticsGraphSnapshot) => {
      self.oneViewVocGraphData.dateList.replace(data.dateList);
      self.oneViewVocGraphData.totalCnt.replace(data.totalCnt);
      self.oneViewVocGraphData.addCnt.replace(data.addCnt);
      self.oneViewVocGraphData.lineData.replace(data.lineData);
    },
    setOneViewBubbleChartData: (data: IBubbleChartSnapshot[]) => {
      self.oneViewBubbleChartData.replace(data as IBubbleChart[]);
    },
    setOneViewWatchChartData: (data: IWatchStatisticsDataSnapshot[]) => {
      self.oneViewWatchChartData.replace(data as IWatchStatisticsData[]);
    },
    setOneViewStbChartData: (data: IStbStatisticsDataSnapshot[]) => {
      self.oneViewStbChartData.replace(data as IStbStatisticsData[]);
    },
    setOneViewStbChartDataOrigin: (data: any) => {
      self.oneViewStbChartDataOrigin.replace(data);
    },
    resetOneViewVocMapData: () => {
      self.oneViewVocMapData.replace(defaultData as IOneViewStatisticsMap[]);
    },
    resetOneViewBubbleChartData: () => {
      self.oneViewBubbleChartData.replace([]);
    },
    resetOneViewWatchMapData: () => {
      self.oneViewWatchMapData.replace(defaultData as IOneViewStatisticsMap[]);
    },
    resetOneViewStbMapData: () => {
      self.oneViewStbMapData.replace(defaultData as IOneViewStatisticsMap[]);
    },
    resetOneViewVocGraphData: () => {
      self.oneViewVocGraphData.dateList.replace([]);
      self.oneViewVocGraphData.totalCnt.replace([]);
      self.oneViewVocGraphData.addCnt.replace([]);
      self.oneViewVocGraphData.lineData.replace([]);
    },
    resetOneViewWatchChartData: () => {
      self.oneViewWatchChartData.replace([]);
    },
    resetOneViewStbChartData: () => {
      self.oneViewStbChartData.replace([]);
    },
    resetOneViewTime: () => {
      self.oneViewTime = new Date();
    },

    setOneviewGauge: (data: IOneviewGaugeSnapshot[]) => {
      self.oneViewGauge.replace(data as IOneviewGauge[])
    },
    resetOneviewGauge: () => {
      self.oneViewGauge.replace([]);
    },
    updateOneviewGauge: (name: string, value: number) => {
      self.oneViewGauge.map((row) => {
        if (row.type == name) {
          row.value = value;//value < 0 ? 0 : value;
        }
      });
    },

    setDummyData: () => {
      self.oneViewVocMapData.map((row, i) => {
        row.value = Math.round(Math.random() * 50);
      });

      self.oneViewWatchMapData.map((row, i) => {
        row.value = Math.round(Math.random() * 50);
      });

      self.oneViewStbMapData.map((row, i) => {
        row.value = Math.round(Math.random() * 50);
      });

      let total = 0;
      let totalCnt: number[] = [0];
      let lineCnt: number[] = [];
      const addCnt = self.oneViewVocGraphData.addCnt.map((row, i) => {
        const add = Math.random() * 100;
        totalCnt.push(total + add);
        total += add;
        lineCnt.push(
          Math.random() * 10 < 5 ? total + Math.random() * 100 : total - Math.random() * 100,
        );
        return add;
      });
      self.oneViewVocGraphData.addCnt.replace(addCnt);
      self.oneViewVocGraphData.totalCnt.replace(totalCnt);
      self.oneViewVocGraphData.lineData.replace(lineCnt);
      // self.oneViewVocGraphData.totalCnt.replace(self.oneViewVocGraphData.totalCnt.map(row => (100+(Math.random() * 100))));
    },
    setDummyVocGraph: () => {
      self.oneViewVocGraphData.dateList.replace(self.oneViewVocGraphData.dateList);

      let total = 0;
      let totalCnt: number[] = [0];
      let lineCnt: number[] = [];
      const addCnt = self.oneViewVocGraphData.addCnt.map((row, i) => {
        const add = Math.random() * 100;
        totalCnt.push(total + add);
        total += add;
        lineCnt.push(
          Math.random() * 10 < 5 ? total + Math.random() * 100 : total - Math.random() * 100,
        );
        return add;
      });
      self.oneViewVocGraphData.addCnt.replace(addCnt);
      self.oneViewVocGraphData.totalCnt.replace(totalCnt);
      self.oneViewVocGraphData.lineData.replace(lineCnt);
    },
  }))
  .actions((self) => ({


    getsAllData: async () => {
      const now0 = new Date((new Date()).setHours(0, 0, 0)).toString();
      const now = (new Date()).toString();

      //Voc Map
      try {
        self.resetOneViewVocMapData();
        const vocStatisticsApi: VocStatisticsApi = new VocStatisticsApi(self.environment.api);
        let result: TGetVocStatisticsResultForOneview;

        result = await vocStatisticsApi.getsForOneview({ statDtSt: now0, statType: CC.STAT_TYPE.Region, statDtEd: now, useOneview: true });

        if (self.rootStore.responseStore.getResponseResult(result)) {
          if (result.data) {
            const resultO = SidebarforMapNew(result.data);
            resultO?.data && self.setOneViewVocMapData(resultO?.data);
            self.setRecivedTimeVocMap(new Date());
          }

        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }


      //Watch Map
      try {
        self.resetOneViewWatchMapData();
        const watchStatisticsApi: WatchStatisticsApi = new WatchStatisticsApi(self.environment.api);
        let result: TGetVocStatisticsResultForOneview;

        result = await watchStatisticsApi.getWatchStatisticss({
          statDtSt: now0,
          statType: CC.STAT_TYPE.Hourly,
          statDtEd: now,
        });

        if (self.rootStore.responseStore.getResponseResult(result)) {
          if (result.data) {
            // console.log('watch map',result.data);
            const resultO = SidebarforMapNew(result.data);
            resultO?.data && self.setOneViewWatchMapData(resultO?.data);
            self.setRecivedTimeRatingMap(new Date());

            // self.setOneViewWatchMapDataOrigin(toJS(result.data)); // ws 수신시 원시데이타로 재계산 필요해서 origin 추가
            // const resultO = SidebarforMap(result.data, toJS(self.oneViewWatchMapData), self.oneViewTime);
            // resultO?.data && self.setOneViewWatchMapData(resultO?.data);
            // resultO?.time && self.setOneViewTime(resultO?.time);
            // resultO?.time && self.setRecivedTimeRatingMap(resultO?.time);
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
      
      //Stb Map
      try {
        self.resetOneViewStbMapData();
        const stbStatisticsApi: StbStatisticsApi = new StbStatisticsApi(self.environment.api);
        let result: TGetVocStatisticsResultForOneview;

        result = await stbStatisticsApi.stbMapGets({
          statDtSt: now0,
          statType: CC.STAT_TYPE.Region,
          statDtEd: now,
          useOneview: true
        });

        if (self.rootStore.responseStore.getResponseResult(result)) {
          if (result.data) {
            // console.log('stb map',result.data);
            const resultO = SidebarforMapNew(result.data);
            resultO?.data && self.setOneViewStbMapData(resultO?.data);
            self.setRecivedTimeStbMap(new Date());
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }

      // Voc Bubble Chart
      try {
        self.resetOneViewBubbleChartData();
        const vocStatisticsApi: VocStatisticsApi = new VocStatisticsApi(self.environment.api);
        let result: TGetVocBubbleChart = await vocStatisticsApi.getBubbleChartData();
        if (self.rootStore.responseStore.getResponseResult(result)) {
          if (result.data) {
            self.setOneViewBubbleChartData(result.data);
            self.setUpdated();
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }

      //Watch Chart
      try {
        self.resetOneViewWatchChartData();
        const watchStatisticsApi: WatchStatisticsApi = new WatchStatisticsApi(self.environment.api);
        let result: TGetWatchStatisticssDataResult;

        result = await watchStatisticsApi.getRealtimeRating();

        if (self.rootStore.responseStore.getResponseResult(result)) {
          if (result.data) {
            self.setOneViewWatchChartData(result.data);
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }

      //Stb Chart
      try {

        self.resetOneViewStbChartData();
        const stbStatisticsApi: StbStatisticsApi = new StbStatisticsApi(self.environment.api);
        let result: TGetStbStatisticsOneviewResult;

        result = await stbStatisticsApi.modelGets({ statDtSt: now0, statType: CC.STAT_TYPE.Model, statDtEd: now, withPre: true, useOneview: true });

        if (self.rootStore.responseStore.getResponseResult(result)) {
          // console.log('stb chart ', result.alarm);
          if (result.data) {
            const res = SidebarforSTBChart(result.data);
            res.data && self.setOneViewStbChartData(res.data);
            // self.setOneViewStbChartData(result.data);
            self.setOneViewTime(self.oneViewTime);
          }
        }

      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }

      // gauge
      try {
        self.resetOneviewGauge();
        const gaugeApi: GaugeApi = new GaugeApi(self.environment.api);
        let result: TGetOneviewGaugesResult;
        result = await gaugeApi.gets();
        if (self.rootStore.responseStore.getResponseResult(result)) {
          if (result.gauges) {
            // console.log('oneview gauges', result.gauges);
            self.setOneviewGauge(result.gauges);
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }

      // 임시 데이터
      // self.setDummyData();
    },

  }));

type TOneViewStatisticsStore = Instance<typeof OneViewStatisticsStore>;
type TOneViewStatisticsStoreSnapshot = SnapshotOut<typeof OneViewStatisticsStore>;

export interface IOneViewStatisticsStore extends TOneViewStatisticsStore { }
export type TOneViewStatisticsStoreKeys = keyof TOneViewStatisticsStoreSnapshot & string;
export interface IOneViewStatisticsStoreSnapshot extends TOneViewStatisticsStoreSnapshot { }
export const createOneViewStatisticsStore = () =>
  types.optional(OneViewStatisticsStore, {} as TOneViewStatisticsStore);
