import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { IUsrgrpMenu } from '../../models';
import {
  TGetUsrgrpMenuListByUsrgrpSidResult,
  TGetUsrgrpMenusResult,
  TPutUsrgrpMenuResult,
} from '../../services';
import { timestampToDate } from '../../utils/dateExchanger';
import { withEnvironment } from '../extensions/with-environment';
import { UsrgrpMenuApi } from './../../services/usrgrp-menu/UsrgrpMenu';
import { withRootStore } from './../extensions/with-root-store';
import { createPagination, IPagination, IPaginationSnapshot } from './../pagination/Pagination';
import { createUsrgrpMenu, IUsrgrpMenuSnapshot } from './../usrgrp-menu/UsrgrpMenu';

export const UsrgrpMenuStore = types
  .model('UsrgrpMenuStore')
  // --------------------------------------------------------------------------
  .props({
    usrgrpMenus: types.optional(types.array(createUsrgrpMenu()), []),
    selectedUsrgrpMenus: types.optional(types.array(createUsrgrpMenu()), []),
    updateUsrgrpMenus: types.optional(types.array(createUsrgrpMenu()), []),
    pagination: createPagination(),
  })
  .extend(withEnvironment)
  .extend(withRootStore)
  .views((self) => ({}))
  .actions((self) => ({
    setUsrgrpMenus: (usrgrpMenus: IUsrgrpMenuSnapshot[]) => {
      self.usrgrpMenus.replace(usrgrpMenus as IUsrgrpMenu[]);
    },
    setSelectedUsrgrpMenus: (usrgrpMenu: IUsrgrpMenuSnapshot[]) => {
      self.selectedUsrgrpMenus.replace(usrgrpMenu as IUsrgrpMenu[]);
    },

    setPagination: (pagination: IPaginationSnapshot) => {
      self.pagination = pagination as IPagination;
    },
    setUpdateUsrgrpMenus: (usrgrpMenus: IUsrgrpMenuSnapshot[]) => {
      self.updateUsrgrpMenus.replace(usrgrpMenus as IUsrgrpMenu[]);
    },
  }))
  .actions((self) => ({
    /**
     * INF_CAIMS_00176
     * 메뉴 전체 목록을 조회한다.
     * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=1139000140
     */
    gets: async () => {
      try {
        const usrgrpMenuApi: UsrgrpMenuApi = new UsrgrpMenuApi(self.environment.api);
        const result: TGetUsrgrpMenusResult = await usrgrpMenuApi.gets();

        if (self.rootStore.responseStore.getResponseResult(result)) {
          if (result.usrgrpMenus) {
            result.usrgrpMenus.forEach((item: IUsrgrpMenuSnapshot) => {
              if (item.regDt) {
                item.regDt = timestampToDate(Number.parseInt(item.regDt));
              }
              if (item.updDt) {
                item.updDt = timestampToDate(Number.parseInt(item.updDt));
              }
            });
            self.setUsrgrpMenus(result.usrgrpMenus);
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },

    /**
     * INF_CAIMS_00177
     * 그룹 메뉴 전체 목록을 조회한다.
     * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=1287212511
     * @param usrgrpSid
     */
    getUsrgrpMenuListByUsrgrpSid: async (usrgrpSid: number) => {
      try {
        const usrgrpMenuApi: UsrgrpMenuApi = new UsrgrpMenuApi(self.environment.api);
        const result: TGetUsrgrpMenuListByUsrgrpSidResult =
          await usrgrpMenuApi.getUsrgrpMenuListByUsrgrpSid(usrgrpSid);

        if (self.rootStore.responseStore.getResponseResult(result)) {
          if (result.usrgrpMenus) {
            result.usrgrpMenus.forEach((item: IUsrgrpMenuSnapshot) => {
              if (item.regDt) {
                item.regDt = timestampToDate(Number.parseInt(item.regDt));
              }
              if (item.updDt) {
                item.updDt = timestampToDate(Number.parseInt(item.updDt));
              }
            });

            self.setSelectedUsrgrpMenus(result.usrgrpMenus);
          } else {
            self.setSelectedUsrgrpMenus([]);
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },

    /**
     * INF_CAIMS_00178
     * 해당 그룹이 접근할 수 있는 메뉴 목록을 수정한다.
     * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=361199811
     * @param usrgrpMenu
     */
    putUsrgrpMenu: async (usrgrpMenu: IUsrgrpMenuSnapshot) => {
      try {
        const usrgrpMenuApi: UsrgrpMenuApi = new UsrgrpMenuApi(self.environment.api);
        const result: TPutUsrgrpMenuResult = await usrgrpMenuApi.putUsrgrpMenu(usrgrpMenu);

        if (self.rootStore.responseStore.getResponseResult(result)) {
          if (!result.usrgrpMenus) {
            return;
          }
          result.usrgrpMenus.forEach((item: IUsrgrpMenuSnapshot) => {
            if(item)
            {
              if (item.regDt) {
                item.regDt = timestampToDate(Number.parseInt(item.regDt));
              }
              if (item.updDt) {
                item.updDt = timestampToDate(Number.parseInt(item.updDt));
              }
            }
          });

          result.usrgrpMenus && self.setUpdateUsrgrpMenus(result.usrgrpMenus);
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },
  }));

// --------------------------------------------------------------------------
type TUsrgrpMenuStore = Instance<typeof UsrgrpMenuStore>;
type TUsrgrpMenuStoreSnapshot = SnapshotOut<typeof UsrgrpMenuStore>;

export interface IUsrgrpMenuStore extends TUsrgrpMenuStore {}
export type TUsrgrpMenuStoreKeys = keyof TUsrgrpMenuStoreSnapshot & string;
export interface IUsrgrpMenuStoreSnapshot extends TUsrgrpMenuStoreSnapshot {}
export const createUsrgrpMenuStore = () => types.optional(UsrgrpMenuStore, {} as TUsrgrpMenuStore);
