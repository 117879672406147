import { Instance, SnapshotOut, types } from 'mobx-state-tree';

export const OneViewStatisticsMap = types
  .model('OneViewStatisticsMap')
  .props({
    name: types.optional(types.string, ''),
    x: types.optional(types.number, 0),
    y: types.optional(types.number, 0),
    value: types.optional(types.number, 0),
    compareValue: types.optional(types.number, 0),
    state: types.optional(types.string, ''),
    fullName: types.optional(types.string, ''),
    preValue: types.optional(types.number, 0), // preCnt
  })
  .actions((self) => ({
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as IOneViewStatisticsMap;
      (Object.keys(newProps) as TOneViewStatisticsMapKeys[]).forEach((key) => {
        //@ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type TOneViewStatisticsMap = Instance<typeof OneViewStatisticsMap>;
type TOneViewStatisticsMapSnapshot = SnapshotOut<typeof OneViewStatisticsMap>;

export interface IOneViewStatisticsMap extends TOneViewStatisticsMap {}
export type TOneViewStatisticsMapKeys = keyof TOneViewStatisticsMapSnapshot & string;
export interface IOneViewStatisticsMapSnapshot extends TOneViewStatisticsMapSnapshot {}
export const createOneViewStatisticsMap = () =>
  types.optional(OneViewStatisticsMap, {
    name: '',
    x: 0,
    y: 0,
    value: 0,
    compareValue: 0,
    state: '',
    fullName: '',
    preValue: 0,
  } as TOneViewStatisticsMap);
