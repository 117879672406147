import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { createModelCode, ModelCode } from '../model-code/ModelCode';

export const ChannelChangeDetail = types
  .model('ChannelChangeDetail')
  .props({
    chChgWrkUid: types.optional(types.maybeNull(types.string), ''),
    chSvcId: types.optional(types.maybeNull(types.number), 0),
    wrkDt: types.optional(types.maybeNull(types.string), ''),
    chWrkType: types.maybeNull(createModelCode()),
    chgVal: types.optional(types.maybeNull(types.string), ''),
  })
  .actions((self) => ({
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as IChannelChangeDetail;
      (Object.keys(newProps) as TChannelChangeDetailKeys[]).forEach((key) => {
        //@ts-ignore
        self[key] = newProps[key];
      });
    },
    resetChannelChangeDetail: () => {
      self.chChgWrkUid = '';
      self.chSvcId = 0;
      self.wrkDt = '';
      self.chgVal = '';
      self.chWrkType = ModelCode.create({
        pcode: 0,
        code: 0,
        value: '',
      });
    },
  }));

type TChannelChangeDetail = Instance<typeof ChannelChangeDetail>;
type TChannelChangeDetailSnapshot = SnapshotOut<typeof ChannelChangeDetail>;

export interface IChannelChangeDetail extends TChannelChangeDetail {}
export type TChannelChangeDetailKeys = keyof TChannelChangeDetailSnapshot & string;
export interface IChannelChangeDetailSnapshot extends TChannelChangeDetailSnapshot {}
export const createChannelChangeDetail = () =>
  types.optional(ChannelChangeDetail, {
    chChgWrkUid: '',
    chSvcId: 0,
    wrkDt: '',
    chgVal: '',
  } as TChannelChangeDetail);
