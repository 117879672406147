import { Instance, SnapshotOut, types } from "mobx-state-tree"
import { createModelCode } from "../model-code/ModelCode";
import { createSimpleUser } from "../simple-user/SimpleUser";

/**
 * # AlarmSetup
 *
 * AlarmSetup을 설명하세요.
 */
export const AlarmSetup = types
  .model("AlarmSetup")
  // --------------------------------------------------------------------------
  .props({
    monitorType: types.optional(types.string, ''),
    svrt: types.maybeNull(createModelCode()),
    regDt: types.optional(types.maybeNull(types.number), null),
    updDt: types.optional(types.maybeNull(types.number), null),
    
    fileName: types.optional(types.maybeNull(types.string), null),
    fileConts: types.maybeNull(types.string),
    useYn: types.optional(types.maybeNull(types.boolean), false),
    uploadFile: types.maybeNull(types.string),
    regUser: types.maybeNull(createSimpleUser()),
    updUser: types.maybeNull(createSimpleUser()),
  })
  // --------------------------------------------------------------------------
  // MUTATEs ONLY! - 모델 상태를 변경
  // REQUESTs는 스토어 모델에 작성하세요.
  // ex) yarn gen model alarmSetup --store
  .actions((self) => ({
    /*
     * 여러 prop을 한번에 수정
     */
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as IAlarmSetup;
      (Object.keys(newProps) as TAlarmSetupKeys[]).forEach((key) => {
        // @ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type TAlarmSetup = Instance<typeof AlarmSetup>;
type TAlarmSetupSnapshot = SnapshotOut<typeof AlarmSetup>

export interface IAlarmSetup extends TAlarmSetup { }
export type TAlarmSetupKeys = keyof TAlarmSetupSnapshot & string;
export interface IAlarmSetupSnapshot extends TAlarmSetupSnapshot { }
export const createAlarmSetup = () =>
  types.optional(AlarmSetup, {
  } as TAlarmSetup);
