import { Button, ButtonProps } from '@mui/material';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { ButtonColorMap } from './settings/SettingButtonColor';
import { BUTTON_LABEL } from './settings/SettingConstEnum';

export interface IButtonColorMap {
  inherit: string[];
  error: string[];
  primary: string[];
  info: string[];
  secondary: string[];
  warning: string[];
}

interface ICButtonSetItemProps extends ButtonProps {
  children: BUTTON_LABEL;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  disabled?: boolean;
  type?: ButtonProps['type'];
  size?: 'small' | 'medium' | 'large';
}

function getColorByLabel(text: BUTTON_LABEL) {
  let color: ICButtonSetItemProps['color'];

  color = Object.keys(ButtonColorMap as IButtonColorMap).filter((k) => {
    // @ts-ignore
    return !!ButtonColorMap[k].includes(text);
  })[0] as ICButtonSetItemProps['color'];
  return color;
}

const CButtonSetItem = observer(function IButtonSetItemProps({
  type = 'button',
  color,
  children,
  disabled,
  onClick,
  size = 'medium'
}: ICButtonSetItemProps) {
  const [_color, setColor] = useState<ICButtonSetItemProps['color']>(color);

  useEffect(() => {
    setColor(getColorByLabel(children));
  }, [children]);

  return (
    <Button
      type={type}
      color={(color??_color)}
      variant={'contained'}
      onClick={onClick}
      sx={{ whiteSpace: 'nowrap' }}
      disabled={disabled}
      size={size}
    >
      {children}
    </Button>
  );
});

export default CButtonSetItem;
