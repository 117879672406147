import { withRootStore } from './../extensions/with-root-store';
import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { withEnvironment } from '../../models/extensions/with-environment';

export const LanguageStoreModel = types
  .model('LanguageStore')
  .props({
    language: types.optional(types.string, 'ko'),
    index: types.optional(types.number, 0),
  })
  .extend(withEnvironment)
  .extend(withRootStore)
  .views((self) => ({}))
  .actions((self) => ({
    setLanguage: (selectedLanguage: string) => {
      self.language = selectedLanguage;
    },
    setIndex: (selectedIndex: number) => {
      self.index = selectedIndex;
    },
  }))
  .actions((self) => ({}));

type LanguageStoreType = Instance<typeof LanguageStoreModel>;
export interface LanguageStore extends LanguageStoreType {}
type LanguageStoreSnapshotType = SnapshotOut<typeof LanguageStoreModel>;
export interface LanguageStoreSnapshot extends LanguageStoreSnapshotType {}
export const createLanguageStoreDefaultModel = () =>
  types.optional(LanguageStoreModel, {
    language: '',
    index: 0,
  });
