import { useState, ReactNode, forwardRef } from 'react';
import { Icon } from '@iconify/react';
import { NavLink as RouterLink, matchPath, useLocation, NavLinkProps } from 'react-router-dom';
import arrowIosForwardFill from '@iconify/icons-eva/arrow-ios-forward-fill';
import arrowIosDownwardFill from '@iconify/icons-eva/arrow-ios-downward-fill';
// material
import { alpha, useTheme, styled } from '@mui/material/styles';
import {
  Box,
  List,
  BoxProps,
  Collapse,
  ListItemText,
  ListItemIcon,
  ListSubheader,
  ListItemButton,
  ListItemButtonProps,
} from '@mui/material';
import { PATH_CHANNEL_AI } from '../routes/paths';
import { useStores } from '../models';

// ----------------------------------------------------------------------

const ListSubheaderStyle = styled((props) => (
  <ListSubheader disableSticky disableGutters {...props} />
))(({ theme }) => ({
  ...theme.typography.overline,
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(2),
  paddingLeft: theme.spacing(5),
  color: theme.palette.text.primary,
}));

interface ListItemStyleProps extends ListItemButtonProps {
  component?: ReactNode;
  to?: string;
}

const ListItemStyle = styled(ListItemButton)<ListItemStyleProps>(({ theme }) => ({
  ...theme.typography.body2,
  height: 48,
  position: 'relative',
  textTransform: 'capitalize',
  paddingLeft: theme.spacing(5),
  paddingRight: theme.spacing(2.5),
  color: theme.palette.text.secondary,
  borderBottom: '1px solid #1e2730',
  '&:before': {
    top: 0,
    right: 0,
    width: 3,
    bottom: 0,
    content: "''",
    display: 'none',
    position: 'absolute',
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    backgroundColor: theme.palette.primary.main,
  },
}));

const ListItemIconStyle = styled(ListItemIcon)({
  display: 'flex',
  width: '20px',
  height: '20px',
  alignItems: 'center',
  justifyContent: 'center',
});

// ----------------------------------------------------------------------

type NavItemProps = {
  title: string;
  path: string;
  icon?: any;
  info?: JSX.Element;
  children?: NavItemProps[];
};

function NavItem({ item, isShow}: { item: NavItemProps, isShow?: boolean | undefined }) {
  const { authStore } = useStores();
  const theme = useTheme();
  const { pathname } = useLocation();
  const { title, path, icon, info, children } = item;
  const isActiveRoot = path ? !!matchPath({ path, end: false }, pathname) : false;
  const [open, setOpen] = useState(false);
  const [clicked, setClicked] = useState(false);

  const handleOpen = () => {
    setOpen(!open);
    setClicked(true);
  };

  const activeRootStyle = {
    color: 'primary.main',
    fontWeight: 'fontWeightMedium',
    bgcolor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
    '&:before': { display: 'block' },
    borderBottom: '1px solid #1e2730',
  };

  const activeSubStyle = {
    color: 'text.primary',
    fontWeight: 'fontWeightMedium',
  };

  const customRouteLink = forwardRef((props: NavLinkProps, ref: any) => {
    return (
      <RouterLink
        ref={ref}
        {...props}
        target={props.to === PATH_CHANNEL_AI.dashboard.oneView ? '_blank' : '_self'}
      />
    );
  });
  
  if (children) {
    const opened = children.filter((item)=>matchPath(item.path, pathname)).length > 0;

    return (
      <>
        {/* 1뎁스 메뉴 (상위메뉴) */}
        <ListItemStyle
          onClick={handleOpen}
          sx={{
            ...(isActiveRoot && activeRootStyle),
          }}
        >
          <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
          {isShow && (
            <>
              <ListItemText primary={title} sx={{ fontWeight: 'bold', fontSize: '16px', color: '#FFFFFF' }} />
              {info && info}
              <Box
                component={Icon}
                icon={(open || (!clicked && opened)) ? arrowIosDownwardFill : arrowIosForwardFill}
                sx={{ width: 14, height: 14, ml: 1 }}
              />
            </>
          )}
        </ListItemStyle>

        {/* 2뎁스 메뉴 (하위메뉴) */}
        {isShow && (
          <Collapse in={open || (!clicked && opened)} timeout="auto" unmountOnExit sx={{ paddingLeft: "30px" }}>
            <List component="div" disablePadding>
              {children.map((item, index) => {
                const { title, path } = item;
                const isActiveSub = path ? !!matchPath({ path, end: false }, pathname) : false;
                if (Array.isArray(item.children)) {
                  const customNavConfig = [
                    {
                      items: [item],
                    },
                  ];
                  return (
                    <NavSection
                      key={title + index.toString()}
                      navConfig={customNavConfig}
                      isShow={isShow}
                    />
                  );
                } else {
                  return (
                    <ListItemStyle
                      key={title + index.toString()}
                      component={customRouteLink}
                      to={path}
                      sx={{
                        borderBottom: 'none',
                        ...(isActiveSub && activeSubStyle),
                      }}
                      onClick={() => {
                        authStore.setLastViewPathStorageData(path);
                      }}
                    >
                      <ListItemIconStyle>
                        <Box
                          component="span"
                          sx={{
                            width: 4,
                            height: 4,
                            display: 'flex',
                            borderRadius: '50%',
                            alignItems: 'center',
                            justifyContent: 'center',
                            bgcolor: 'text.disabled',
                            transition: (theme) => theme.transitions.create('transform'),
                            ...(isActiveSub && {
                              transform: 'scale(2)',
                              bgcolor: '#0048fe',//'primary.main',
                            }),
                          }}
                        />
                      </ListItemIconStyle>
                      <ListItemText disableTypography primary={title} />
                    </ListItemStyle>
                  );
                }
              })}
            </List>
          </Collapse>
        )}
      </>
    );
  }

  return (
    <ListItemStyle
      component={customRouteLink}
      to={path}
      sx={{
        ...(isActiveRoot && activeRootStyle),
      }}
    >
      <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
      {isShow && (
        <>
          <ListItemText disableTypography primary={title} />
          {info && info}
        </>
      )}
    </ListItemStyle>
  );
}

interface NavSectionProps extends BoxProps {
  isShow?: boolean | undefined;
  navConfig: {
    subheader?: string;
    items: NavItemProps[];
  }[];
}

export default function NavSection({ navConfig, isShow = true, ...other }: NavSectionProps) {
  return (
    <Box {...other}>
      {navConfig.map((list, index) => {
        const { subheader, items } = list;
        return (
          <List key={'List' + index.toString()} disablePadding>
            {isShow && subheader && <ListSubheaderStyle>{subheader}</ListSubheaderStyle>}
            {items.map((item: NavItemProps, indexT) => (
              <NavItem
                key={'NavItem-' + index.toString() + indexT.toString()}
                item={item}
                isShow={isShow}
              />
            ))}
          </List>
        );
      })}
    </Box>
  );
}
