import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { createModelCode, ModelCode } from '../model-code/ModelCode';
import { createPairEquipment } from '../pair-equipment/PairEquipment';
import { IPortSnapshot, Port } from '../port/Port';
import { createSimpleUser } from './../simple-user/SimpleUser';

export const Equipment = types
  .model('Equipment')
  .props({
    equipSid: types.optional(types.number, 0),
    hostNm: types.optional(types.string, ''),
    modelNm: types.optional(types.maybeNull(types.string), ''),
    equipType: createModelCode(),
    equipRole: types.maybeNull(createModelCode()),
    equipMngr: types.optional(types.maybeNull(types.string), ''),
    equipLo: types.optional(types.maybeNull(types.string), ''),
    upTime: types.optional(types.maybeNull(types.number), null),
    upTimeStr: types.optional(types.maybeNull(types.string),''),

    rack: createModelCode(),
    rackSid: types.optional(types.maybeNull(types.number), null),
    slotNo: types.optional(types.maybeNull(types.number), null),
    rackInfo: types.optional(types.maybeNull(types.string),''),
    rackNm: types.optional(types.maybeNull(types.string),''),
    rackLocX: types.optional(types.maybeNull(types.number), null),
    rackLocY: types.optional(types.maybeNull(types.number), null),
    cbtpaIdM: types.optional(types.maybeNull(types.number), null),
    cbtpaIdB: types.optional(types.maybeNull(types.number), null),
    cbtpaNmM: types.optional(types.maybeNull(types.string),''),
    cbtpaNmB: types.optional(types.maybeNull(types.string),''),
    equipRoomSid: types.optional(types.maybeNull(types.number), null),
    equipRoomNm: types.optional(types.maybeNull(types.string),''),
    equipRoomLo: types.optional(types.maybeNull(types.string),''),    

    trtResol: createModelCode(),
    mnfcSid: types.optional(types.number, 0),
    mnfcNm: types.optional(types.maybeNull(types.string), ''),
    vendorSid: types.optional(types.number, 0),
    vendorNm: types.optional(types.maybeNull(types.string), ''),

    buyDate: types.optional(types.maybeNull(types.string), null),
    installDate: types.optional(types.maybeNull(types.string), null),
    mgmtIp: types.optional(types.string, ''),
    frmwrVer: types.optional(types.maybeNull(types.string), ''),
    // modulInclYn: types.optional(types.maybeNull(types.boolean), false),

    haType: createModelCode(),
    haSttus: createModelCode(),
    repYn: types.optional(types.maybeNull(types.boolean), null),
    equipOprtSttus: types.maybeNull(createModelCode()),
    helthySttus: createModelCode(),
    etnlEquipId: types.optional(types.maybeNull(types.string), ''),

    repEquipSid: types.optional(types.maybeNull(types.number), null),
    repEquipNm: types.optional(types.maybeNull(types.string), null),
    intrlkSysSid: types.optional(types.maybeNull(types.number), 0),
    intrlkSysSrvrSid: types.optional(types.maybeNull(types.number), 0),

    regDt: types.optional(types.string, ''),
    updDt: types.optional(types.string, ''),
    regUser: types.maybeNull(createSimpleUser()),
    updUser: types.maybeNull(createSimpleUser()),
    portList: types.optional(types.maybeNull(types.array(Port)), [] as IPortSnapshot[]),

    memEquipList: types.optional(types.maybeNull(types.array(createPairEquipment())), []),
    eqHostNmDuplicate: types.optional(types.maybeNull(types.boolean), false),

    //따로 쓰는곳 있다고함
    mgmtIpMain: types.optional(types.maybeNull(types.string), ''),
    mgmtIpBack: types.optional(types.maybeNull(types.string), ''),
    sysSrvrNm: types.optional(types.maybeNull(types.string), ''),
    sysNm: types.optional(types.maybeNull(types.string), ''),

    equipCtg: types.maybeNull(createModelCode()),
  })
  .actions((self) => ({
    resetEquipment: () => {
      self.equipSid = 0;
      self.hostNm = '';
      self.modelNm = '';
      self.mnfcSid = 0;
      self.mnfcNm = '';
      self.vendorSid = 0;
      self.vendorNm = '';
      self.buyDate = null;
      self.installDate = null;
      self.mgmtIp = '';
      self.equipLo = '';
      self.equipMngr = '';
      self.upTime = null;
      self.upTimeStr = '';

      self.rackInfo = '';
      self.slotNo = null;
      self.rackSid = null;

      self.frmwrVer = '';
      // self.modulInclYn = false;
      self.repYn = false;
      self.etnlEquipId = '';
      self.intrlkSysSid = 0;

      self.intrlkSysSrvrSid = 0;
      self.regDt = '';
      self.updDt = '';
      self.equipType = ModelCode.create({
        code: 0,
        pcode: 0,
        value: '',
      });
      self.equipRole = ModelCode.create({
        code: 0,
        pcode: 0,
        value: '',
      });
      self.trtResol = ModelCode.create({
        code: 0,
        pcode: 0,
        value: '',
      });
      self.haType = ModelCode.create({
        code: 0,
        pcode: 0,
        value: '',
      });
      self.haSttus = ModelCode.create({
        code: 0,
        pcode: 0,
        value: '',
      });
      self.equipOprtSttus = ModelCode.create({
        code: 0,
        pcode: 0,
        value: '',
      });
      self.helthySttus = ModelCode.create({
        code: 0,
        pcode: 0,
        value: '',
      });
      self.repEquipNm = '';
      self.equipCtg = ModelCode.create({
        code: 0,
        pcode: 0,
        value: '',
      });
    },
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as IEquipment;
      (Object.keys(newProps) as TEquipmentKeys[]).forEach((key) => {
        //@ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

type TEquipment = Instance<typeof Equipment>;
type TEquipmentSnapshot = SnapshotOut<typeof Equipment>;

export interface IEquipment extends TEquipment {}
export type TEquipmentKeys = keyof TEquipmentSnapshot & string;
export interface IEquipmentSnapshot extends TEquipmentSnapshot {}
export const createEquipment = () => types.optional(Equipment, {} as TEquipment);

type TDefaultEquipment = typeof defaultEquipment;
export interface IDefaultEquipment extends TDefaultEquipment {}
export const defaultEquipment = {
  equipSid: 0,
  equipType: undefined,
  equipRole: undefined,
  trtResol: undefined,
  hostNm: '',
  modelNm: '',
  venderNm: '',
  mgmtIp: '',
  equipLo: '',
  equipMngr: '',
};
