import { ISimpleType, types } from 'mobx-state-tree';
import { NodeTypes, THREE, TNodeTypes } from 'network-diagram';
// import { NodeTypes, TNodeTypes } from './NodeTypes';

/**
 * Enum 을 MST props type으로 매핑
 *
 * @param name
 * @param enumeration
 * @returns enumeration의 키의 타입
 */
function enumToMstEnumType<T extends {}>(name: string, enumeration: T): ISimpleType<keyof T | string> {
  return types.enumeration(
    name,
    Object.keys(enumeration).map((t) => t as keyof T & string),
  );
}

/**
 * Three.Vector3Tuple 타입을 MST props 타입으로 래핑
 * @returns type Vector3Tuple = [x:number,y:number,z:number]
 */
function vector3TupleToMstType() {
  return types.frozen<THREE.Vector3Tuple | number[]>();
}

/**
 * 모델의 지속성 상태
 */
export enum PersistenceState {
  NEW = 'NEW',
  CLEAN = 'CLEAN',
  DIRTY = 'DIRTY',
  DELETED = 'DELETED',
  LIST_ONLY = 'LIST_ONLY',
}

/**
 * 링크의 선 종류
 */
export enum LinkTypes {
  NONE = 'NONE',
  SOLID = 'SOLID',
  DASHED = 'DASHED',
}

/**
 * 모델의 지속성 상태
 *
 * @returns One of enums key -> 'NEW' | 'CLEAN' | 'DIRTY' | 'DELETED' | 'LIST_ONLY'
 */
function state() {
  return enumToMstEnumType<typeof PersistenceState>('State of persistence', PersistenceState);
}

/**
 * 링크의 선 종류
 *
 * @returns One of enums key -> 'NONE' | 'SOLID' | 'DASHED' | 'DOTTED'
 */
function link() {
  return enumToMstEnumType<typeof LinkTypes>('Type of link', LinkTypes);
}

function objectToMstEnumType<T>(name: string, object: any): ISimpleType<keyof T | string> {
  return types.enumeration(
    name,
    Object.keys(object).map((t) => t as keyof T & string),
  );
}

/**
 *
 * @returns "Encoder" | "MUX" | "L3" | "Transcoder" | "Unknown" | "IPAcoMux" | "Transmission" | "MSPP" | "Server" | "Satellite" | "Rectangle" | "Nameplate" | "Text" | "Cloud" | "Typography" | "Ellipse" | "Plane"
 */
function nodeType() {
  return objectToMstEnumType<TNodeTypes>('Type of node', NodeTypes);
}

/**
 * 3D 좌표
 * @returns [x, y, z]  THREE.Vector3Tuple
 */
function point() {
  return vector3TupleToMstType();
}

/**
 * 3D 좌표
 * @returns [x, y, z]
 */
function position() {
  return vector3TupleToMstType();
}

/**
 * x,y,z 축별 크기
 * @returns [x, y, z]
 */
function size() {
  return vector3TupleToMstType();
}

/**
 * x,y,z 축별 스케일
 * @returns [x, y, z]
 */
function scale() {
  return vector3TupleToMstType();
}

/**
 * x,y,z 축별 회전
 * @returns [x, y, z]
 */
function rotation() {
  return vector3TupleToMstType();
}

/**
 * 객체에 링크된 사용자 데이터
 *
 * @returns {[key:string]: any}
 */
function userData() {
  return types.frozen({});
}

/**
 * 색상 지정 데이터 포맷
 * '#000000' | 0x000000 | THREE.Color 3가지 형식을 사용할 수 있다.
 *
 * @returns string | number | Color
 */
function color() {
  return types.frozen<THREE.ColorRepresentation>();
}

export const mstCustomTypes = {
  state,
  link,
  nodeType,
  point,
  position,
  size,
  scale,
  rotation,
  userData,
  color,
};
