import { Client } from '@stomp/stompjs';
import { isEmptyArray } from 'formik';
import { isArray } from 'lodash';
import { toJS } from 'mobx';
import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import moment from 'moment';
import { v4 as randomUUID } from 'uuid';
import {
  ChannelApi,
  EquipmentApi,
  StbStatisticsApi,
  TGetChannelToCodeResult,
  TGetRackToCodeResult,
  TGetRegionResult,
  TGetTop7ModelResult,
} from '../../services';
import { SidebarforGraph, SidebarforMap, SidebarforMapNew, SidebarforSTBChart } from '../../utils/oneViewFunc';
import { withEnvironment } from '../extensions/with-environment';
import { withRootStore } from '../extensions/with-root-store';
import { createModelCode, IModelCode, IModelCodeSnapshot } from '../model-code/ModelCode';
import { createSoketSnackbar, ISoketSnackbar, ISoketSnackbarSnapshot } from '../sokect-snackbar/soketSnackbar';

let webSocket: WebSocket;
const initFavoritesChannelList: IModelCode[] = [
  {code:307,pcode:null,value:"3 (tvN)"}
  ,{code:713,pcode:null,value:"0 (MBC 에브리원)"}
  ,{code:120,pcode:null,value:"9 (KBS1)"}
  ,{code:363,pcode:null,value:"18 (채널A)"}
  ,{code:365,pcode:null,value:"19 (TV조선)"}
  ,{code:366,pcode:null,value:"16 (MBN)"}
  ,{code:367,pcode:null,value:"23 (연합뉴스TV)"}
] as IModelCode[];
const initFavoritesChannelListSubService: IModelCode[] = [
  {code:356,pcode:null,value:"29 (ENA DRAMA)"}
  ,{code:302,pcode:null,value:"33 (OCN)"}
  ,{code:381,pcode:null,value:"35 (KBS drama)"}
] as IModelCode[];
const initFavoritesChannelListDQuetone: IModelCode[] = [
  {code:496,pcode:null,value:"13 (EBS)"}
  ,{code:155,pcode:null,value:"11 (MBC)"}
] as IModelCode[];
const initFavoritesModelList: string[] = ['NA1100','NA2200','M770','CT1102','OTV-SMT-E5015','KI1100','CT1101'];

export const CommonStore = types
    .model('CommonStore')
    .props({
      windowLocationOrigin: window.location.origin,
      regionList: types.array(createModelCode()),
      modelList: types.array(types.string),
      channelList: types.array(createModelCode()),
      favoritesChannelList: types.array(createModelCode()),
      channelListSubService: types.array(createModelCode()),
      favoritesChannelListAquetone: types.array(createModelCode()),
      favoritesChannelListBquetone: types.array(createModelCode()),
      favoritesChannelListDquetone: types.array(createModelCode()),
      favoritesChannelListDpcc: types.array(createModelCode()),
      favoritesChannelListMisTrans: types.array(createModelCode()),
      favoritesModelList: types.array(types.string),
      soketSnackBar: createSoketSnackbar(),
      serverTime: types.optional(types.string, ''),
      rackCodeList: types.array(createModelCode()),

      /**
       * TODO
       * oneview time 이 연동될때마다 사이드탭에 모두 똑같이 적용됨..-_-
       * voc, rating, 단말 모두 시간을 별도로 관래해야할듯~
       */
    })
    .extend(withEnvironment)
    .extend(withRootStore)
    .views(() => ({}))
    .actions((self) => ({
      // 로컬스토리지에서 채널 서비스 아이디 리스트 가져오기 (최근 검색한 것)
      getLocalChSvcIdList: () => {
        const value = window.localStorage.getItem('chSvcIdList');
        if(value)
        {
          return JSON.parse(value);
        }
        return self.favoritesChannelList;
      },
      // 로컬스토리지에서 채널 서비스 아이디 리스트 가져오기 (최근 검색한 것)
      getSubServiceLocalChSvcIdList: (type?:string) => {
        let value;
        if(type === null || type === undefined)
        {
          value = window.localStorage.getItem('chSvcIdListMisTrans')
        }
        else{
          if(type === 'A')
          {
            value = window.localStorage.getItem('chSvcIdListAQuetone');
          }
          else if(type === 'B')
          {
            value = window.localStorage.getItem('chSvcIdListBQuetone');
          }
          else if(type === 'dpcc')
          {
            value = window.localStorage.getItem('chSvcIdListDpcc');
          }
          else if(type === 'D')
          {
            value = window.localStorage.getItem('chSvcIdListDQuetone')
          }
        }

        if(value)
        {
          return JSON.parse(value);
        }

        return type === 'D' ? initFavoritesChannelListDQuetone:initFavoritesChannelListSubService;
      },
      // 로컬스토리지에 채널서비스 아이디 세팅하기
      setLocalChSvcIdList: (chSvcIdList: IModelCode[]) => {
        window.localStorage.setItem('chSvcIdList',JSON.stringify(chSvcIdList));
      },
      // 로컬스토리지에서 모델 리스트 가져오기 (최근 검색한 것)
      getLocalModelList: () => {
        const value = window.localStorage.getItem('modelList');
        if(value)
        {
          return JSON.parse(value);
        }
        return isEmptyArray(self.favoritesModelList) ? initFavoritesModelList : self.favoritesModelList;
      },
      // 로컬스토리지에 모델리스트 세팅하기 (최근 검색한 것)
      setLocalModelList: (modelList: string[]) => {
        window.localStorage.setItem('modelList',JSON.stringify(modelList));
      },
    }) )
    .actions((self) => ({
      setRegions: (regionList: IModelCodeSnapshot[]) => {
        self.regionList.replace(regionList as IModelCode[]);
      },
      setChannelList: (channelList: IModelCodeSnapshot[],type:string) => {
        self.channelList.replace(channelList as IModelCode[]);
        const chSvcIdList: IModelCode[] = self.getLocalChSvcIdList();
        if (isEmptyArray(channelList) || isEmptyArray(chSvcIdList)) {
          self.favoritesChannelList.replace(initFavoritesChannelList);
        }
        else {
          self.favoritesChannelList.replace(chSvcIdList);
        }
      },
      setChannelListSubService: (channelList: IModelCodeSnapshot[], type?:string) => {
        self.channelListSubService.replace(channelList as IModelCode[]);
        const chSvcIdList: IModelCode[] = self.getSubServiceLocalChSvcIdList(type);

        if (isEmptyArray(channelList) || isEmptyArray(chSvcIdList)) {
          self.favoritesChannelList.replace(initFavoritesChannelListSubService);
        }
        else {
          if(type === null || type === undefined)
          {
            self.favoritesChannelListMisTrans.replace(chSvcIdList);
          }
          else
          {
            if(type === 'A')
            {
              self.favoritesChannelListAquetone.replace(chSvcIdList);
            }
            else if(type === 'B')
            {
              self.favoritesChannelListBquetone.replace(chSvcIdList);
            }
            else if(type === 'dpcc')
            {
              self.favoritesChannelListDpcc.replace(chSvcIdList);
            }
            else if(type === 'D')
            {
              self.favoritesChannelListDquetone.replace(chSvcIdList);
            }
          }
        }
      },
      setModelList: (modelList: string[]) => {
        if (isEmptyArray(modelList)) {
          const list = self.getLocalModelList();
          self.favoritesModelList.replace(isEmptyArray(list) ? initFavoritesModelList : list);
        }
        else {
          self.favoritesModelList.replace(modelList);
          self.setLocalModelList(modelList);
        }
      },
      setRackCodeList: (rackCodeList: IModelCodeSnapshot[]) =>{
        self.rackCodeList.replace(rackCodeList as IModelCode[]);
      },
      setSoketSnackBar: (soketSnackBar: ISoketSnackbarSnapshot) => {
        self.soketSnackBar = soketSnackBar as ISoketSnackbar;
      },
      resetSoketSnackBar: () => {
        self.soketSnackBar = {
          id: null,
          message: '',
          type: '',
        } as ISoketSnackbarSnapshot as ISoketSnackbar;
      },
      setServerTime: (time: string) => {
        self.serverTime = time;
      }
    }))
    .actions((self) => ({
      /**
       * INF_CAIMS_00301
       * ''광역시', '도'에 해당하는 지역코드 항목을 조회한다.
       * @see https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=379400282
       * @returns
       */
      regionGets: async () => {
        self.setRegions([]);
        try {
          const stbStatisticsApi: StbStatisticsApi = new StbStatisticsApi(self.environment.api);
          let result: TGetRegionResult;

          result = await stbStatisticsApi.regionGets();

          if (self.rootStore.responseStore.getResponseResult(result)) {
            if (result.regions) {
              self.setRegions(result.regions);
            }
          }
        } catch (e) {
          self.rootStore.responseStore.errorProcessing(e);
        }
      },
      /**
       * 전제 채널을 코드 형식으로 조회
       */
      channelGets: async (type:string) => {
        self.setChannelList([],type);
        try {
          const channelApi: ChannelApi = new ChannelApi(self.environment.api);
          let result: TGetChannelToCodeResult;

          result = await channelApi.getChannelToCode();

          if (self.rootStore.responseStore.getResponseResult(result)) {
            if (result.codes) {
              self.setChannelList(result.codes,type);
            }
          }
        } catch (e) {
          self.rootStore.responseStore.errorProcessing(e);
        }
      },

      /**
       * 부가서비스 관련 채널을 코드 형식으로 조회
       */
      channelGetsSubService: async (subService?:string, occurredEvent?:boolean, startDt?:string | null, endDt?: string | null) => {
        try {
          const channelApi: ChannelApi = new ChannelApi(self.environment.api);
          let result: TGetChannelToCodeResult;

          if(occurredEvent)
          {
            result = await channelApi.getOccurredEventChannelToCode(subService, startDt, endDt);
          } else
          {
            result = await channelApi.getChannelToCode(subService);
          }
          

          if (self.rootStore.responseStore.getResponseResult(result)) {
            if (result.codes) {
              self.setChannelListSubService(result.codes, subService);
            }
          }
        } catch (e) {
          self.rootStore.responseStore.errorProcessing(e);
        }
      },
      /**
       * 전제 rack 정보를 코드 형식으로 조회
       */
      rackCodeGets: async () => {
        self.setRackCodeList([]);
        try {
          const equipApi: EquipmentApi = new EquipmentApi(self.environment.api);
          let result: TGetRackToCodeResult;

          result = await equipApi.getRackToCode();

          if (self.rootStore.responseStore.getResponseResult(result)) {
            if (result.codes) {
              self.setRackCodeList(result.codes);
            }
          }
        } catch (e) {
          self.rootStore.responseStore.errorProcessing(e);
        }
      },
      /**
       * 최근 장애알람 top7 모델명 조회
       */
      alarmTop7modelGets: async () => {
        self.setModelList([]);
        try {
          const stbStatisticsApi: StbStatisticsApi = new StbStatisticsApi(self.environment.api);
          let result: TGetTop7ModelResult;

          result = await stbStatisticsApi.top7ModelGets();

          if (self.rootStore.responseStore.getResponseResult(result)) {
            if (result.models) {
              self.setModelList(result.models);
            }
          }
        } catch (e) {
          self.rootStore.responseStore.errorProcessing(e);
        }
      },
      socketCommunication: async () => {
        const { REACT_APP_WS_PREFIX, REACT_APP_WS_URL, REACT_APP_API_CONTEXT_PATH } = process.env;
        const baseUrl = REACT_APP_WS_URL || self.rootStore.commonStore.windowLocationOrigin.split('//')[1];
        var ws = new Client({
          brokerURL: `${REACT_APP_WS_PREFIX}${baseUrl}/${REACT_APP_API_CONTEXT_PATH}/ws/caims`,
          debug: (msg) => {
          },
        });

        ws.onConnect = (frame) => {

          ws.subscribe('/common', (data) => {
            const msgData = JSON.parse(data.body);
            if (msgData.type === 'STATISTIC') {
              const name = msgData.action.split('.')[1];
              const type = msgData.action.split('.')[2];
              var result: any;
              let temp;
              const recivedTime = new Date();

              console.log('ws subscribe : ', name, ' : ', type, ' .data : ', msgData.data)
              try {

                switch (name) {
                  case 'voc':
                    switch (type) {
                      case 'region':

                        result = SidebarforMapNew(msgData.data);
                        self.rootStore.oneviewStatisticsStore.resetOneViewVocMapData();
                        result?.data && self.rootStore.oneviewStatisticsStore.setOneViewVocMapData(result?.data);
                        self.rootStore.oneviewStatisticsStore.setRecivedTimeVocMap(recivedTime);
                        self.rootStore.oneviewStatisticsStore.setUpdated();
                        break;

                      case 'daily':
                        result = SidebarforMapNew(msgData.data);
                        self.rootStore.oneviewStatisticsStore.resetOneViewVocMapData();
                        result?.data && self.rootStore.oneviewStatisticsStore.setOneViewVocMapData(result?.data);
                        self.rootStore.oneviewStatisticsStore.setRecivedTimeVocMap(recivedTime);
                        break;

                      case 'hourly':
                        try {
                          const statDt = new Date(msgData.data[0]?.statDt);
                          if (statDt.getHours().toString() == '0' || statDt.getHours().toString() == '00') {
                            console.log('voc graph reset cause midnight');
                            self.rootStore.oneviewStatisticsStore.resetOneViewVocGraphData();
                          }
                        } catch (e) {
                          console.log(e)
                        }

                        result = SidebarforGraph(msgData.data, toJS(self.rootStore.oneviewStatisticsStore.oneViewVocGraphData), self.rootStore.oneviewStatisticsStore.oneViewTime);
                        self.rootStore.oneviewStatisticsStore.resetOneViewVocGraphData();
                        result?.data && self.rootStore.oneviewStatisticsStore.setOneViewVocGraphData(result?.data);
                        result?.time && self.rootStore.oneviewStatisticsStore.setRecivedTimeVocMap(recivedTime);
                        break;

                      case 'keyword':
                        try {
                          self.rootStore.oneviewStatisticsStore.setOneViewBubbleChartData(msgData.data);
                          self.rootStore.oneviewStatisticsStore.setRecivedTimeVocMap(recivedTime);
                          self.rootStore.oneviewStatisticsStore.setUpdated();
                        } catch (e) {
                          self.rootStore.responseStore.errorProcessing(e);
                        }
                        break;
                    }
                    break;
                  case 'views':
                    switch (type) {
                      case 'region': // oneview - 시청률 탭 - 맵 데이터
                        result = SidebarforMapNew(msgData.data);
                        self.rootStore.oneviewStatisticsStore.resetOneViewWatchMapData();
                        result?.data && self.rootStore.oneviewStatisticsStore.setOneViewWatchMapData(result?.data);
                        self.rootStore.oneviewStatisticsStore.setRecivedTimeRatingMap(recivedTime);
                        self.rootStore.oneviewStatisticsStore.setUpdated();
                        break;
                    }
                    break;

                  case 'ratings': // 실시간 채널 시청 순위

                    switch (type) {
                      case 'ignore':
                        try {
                          self.rootStore.oneviewStatisticsStore.resetOneViewWatchChartData();
                          msgData.data.map((row: any) => {
                            const d = new Date(row.statDt);
                            row.statDt = d.getTime();
                          });
                          self.rootStore.oneviewStatisticsStore.setOneViewWatchChartData(msgData.data);
                        } catch (e) {
                          console.log(e)
                        }
                        break;
                    }
                    break;

                  case 'alarm': // oneview - 단말 탭 - 단말 기종별 에러 추이
                    switch (type) {
                      case 'region':
                        result = SidebarforMapNew(msgData.data);
                        self.rootStore.oneviewStatisticsStore.resetOneViewStbMapData();
                        result?.data && self.rootStore.oneviewStatisticsStore.setOneViewStbMapData(result?.data);
                        self.rootStore.oneviewStatisticsStore.setRecivedTimeStbMap(recivedTime);
                        break;
                      case 'hourly':
                        const resultO = SidebarforSTBChart(msgData.data);
                        resultO?.data && self.rootStore.oneviewStatisticsStore.setOneViewStbChartData(resultO?.data);
                        self.rootStore.oneviewStatisticsStore.setRecivedTimeStbMap(recivedTime);
                        self.rootStore.oneviewStatisticsStore.setUpdated();
                        break;
                    }

                    break;

                }
                // self.rootStore.oneviewStatisticsStore.setUpdated();
              } catch (e) {
                // TODO : 웹소켓 메시지 수신 실패
              }

              // oneview side tab - gauge needle
            } else if (msgData.type == 'GAUGE') {

              const name = msgData.action.split('.')[1];
              const type = msgData.action.split('.')[2];
              var result: any;

              try {
                console.log('ws subscribe GAUGE : ', name, ' : ', type, ' .data : ', msgData.data);

                self.rootStore.oneviewStatisticsStore.updateOneviewGauge(name, msgData.data.value);

              } catch (e) {
                console.log(e);
              }

              // Dashboard 알람 (for topology chart)
            } else if (msgData.type == 'DASHBOARD') {

              const name = msgData.action.split('.')[1];
              var result: any;

              // console.log('DASHBOARD WS :: ', name, msgData.data)
              try {

                switch (name) {
                    // 장비상태
                  case 'equipstatus':
                    self.rootStore.topologyStore.setChartEquipments(JSON.stringify(msgData.data));
                    break;
                    // 포트트래픽
                  case 'porttraffic':
                    self.rootStore.topologyStore.setChartPortTraffic(JSON.stringify(msgData.data));
                    break;
                    // oneview time
                  case 'servertime':
                    self.rootStore.commonStore.setServerTime(moment(msgData.data).format('YYYY-MM-DD HH:mm:ss'));
                    break;
                    // channel group
                  case 'channelgroup':
                    self.rootStore.topologyStore.setChartChannels(JSON.stringify(msgData.data));
                    break;
                }
              } catch (e) {
                console.log(e);
              }

            } else {
              if (msgData.type != 'JOB') {
                self.setSoketSnackBar({
                  id: randomUUID(),
                  message: msgData.message,
                  type: msgData.type
                } as ISoketSnackbarSnapshot);
              }
            }

          });

        }



        ws.onStompError = (frame) => {
          console.log('WebSocket Error: ' + toJS(frame));
        }

        ws.activate();
      },
      socketMessageEnd: async () => {
        if (!webSocket) {
          return;
        }
        webSocket.onclose = (event: CloseEvent) => { };
      },
    }));

type TCommonStore = Instance<typeof CommonStore>;
type TCommonStoreSnapshot = SnapshotOut<typeof CommonStore>;

export interface ICommonStore extends TCommonStore {
  responseInfo: any;
}
export type TCommonStoreKeys = keyof TCommonStoreSnapshot & string;
export interface ICommonStoreSnapshot extends TCommonStoreSnapshot { }
export const createCommonStore = () => types.optional(CommonStore, {} as TCommonStore);
