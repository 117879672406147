import { IEventTypeSnapshot, IEventTypeFilter, createEventType } from './../event-type/EventType';
import { withRootStore } from './../extensions/with-root-store';
import { TGetAllEventTypeResult } from './../../services/event-type/EventTypeTypes';
import { Instance, SnapshotOut, types } from 'mobx-state-tree';

import { withEnvironment } from '../extensions/with-environment';
import { IEventType } from '../../models';
import { EventTypeApi } from '../../services';

/**
 * # EventTypeStore
 *
 * EventTypeStore을 설명하세요.
 */
export const EventTypeStore = types
  .model('EventTypeStore')
  // --------------------------------------------------------------------------
  .props({
    eventTypes: types.optional(types.array(createEventType()), []),
  })
  .extend(withEnvironment)
  .extend(withRootStore)
  // eslint-disable-line @typescript-eslint/no-unused-vars
  .views((self) => ({}))
  // --------------------------------------------------------------------------
  // MUTATEs - 모델 상태를 변경
  .actions((self) => ({
    /**
     * eventTypes을 교체
     *
     * @param `eventTypes` 새로운 모델의 배열
     */
    setEventTypes: (eventTypes: IEventTypeSnapshot[]) => {
      self.eventTypes.replace(eventTypes as IEventType[]);
    },
  }))
  // --------------------------------------------------------------------------
  // REQUESTs - 서비스 요청 및 기타 인터페이스 요청
  .actions((self) => ({
    /**
     * INF_CAIMS_00247
     * 이벤트 유형 데이터 항목을 전체 조회한다.
     * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=116308996
     * @param eventTypeFilter
     */
    gets: async (eventTypeFilter: IEventTypeFilter) => {
      self.setEventTypes([]);
      try {
        const eventTypeApi: EventTypeApi = new EventTypeApi(self.environment.api);
        const result: TGetAllEventTypeResult = await eventTypeApi.gets(eventTypeFilter);

        if (self.rootStore.responseStore.getResponseResult(result)) {
          if (result.eventTypes) {
            self.setEventTypes(result.eventTypes);
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },
  }));

// --------------------------------------------------------------------------
type TEventTypeStore = Instance<typeof EventTypeStore>;
type TEventTypeStoreSnapshot = SnapshotOut<typeof EventTypeStore>;

export interface IEventTypeStore extends TEventTypeStore {}
export type TEventTypeStoreKeys = keyof TEventTypeStoreSnapshot & string;
export interface IEventTypeStoreSnapshot extends TEventTypeStoreSnapshot {}
export const createEventTypeStore = () => types.optional(EventTypeStore, {} as TEventTypeStore);
