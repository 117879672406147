import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { createModelCode } from '../model-code/ModelCode';

export const SdMux = types
  .model('SdMux')
  .props({
    equipSid: types.optional(types.number, 0),
    trtResol: createModelCode(),
    mainYn: types.optional(types.boolean, false),
    hostNm: types.optional(types.string, ''),
    mgmtIp: types.optional(types.string, ''),
    modelNm: types.optional(types.maybeNull(types.string), ''),
    frmwrVer: types.optional(types.maybeNull(types.string), ''),
    equipLo: types.optional(types.maybeNull(types.string), ''),
    equipMngr:  types.optional(types.maybeNull(types.string), ''),
    inPortSid: types.optional(types.maybeNull(types.number), 0),
    inPortNm: types.optional(types.maybeNull(types.string), ''),
    outPortSid: types.optional(types.maybeNull(types.number), 0),
    outPortNm: types.optional(types.maybeNull(types.string), ''),
    scsPortSid: types.optional(types.maybeNull(types.number), 0),
    scsPortNm: types.optional(types.maybeNull(types.string), ''),
    ipout: types.optional(types.maybeNull(types.string), null),
    inBPortSid: types.optional(types.maybeNull(types.number), 0),
    inBPortNm: types.optional(types.maybeNull(types.string), ''),
    outBPortSid: types.optional(types.maybeNull(types.number), 0),
    outBPortNm: types.optional(types.maybeNull(types.string), ''),
    emgInPortSid: types.optional(types.maybeNull(types.number), 0),
    emgInPortNm: types.optional(types.maybeNull(types.string), ''),

    inPort: createModelCode(),
    outPort: createModelCode(),
    scsPort: createModelCode(),
    inBPort: createModelCode(),
    outBPort: createModelCode(),
    emgInPort: createModelCode(),
  })
  .actions((self) => ({
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as ISdMux;
      (Object.keys(newProps) as TSdMuxKeys[]).forEach((key) => {
        //@ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

type TSdMux = Instance<typeof SdMux>;
type TSdMuxSnapshot = SnapshotOut<typeof SdMux>;

export interface ISdMux extends TSdMux {}
export type TSdMuxKeys = keyof TSdMuxSnapshot & string;
export interface ISdMuxSnapshot extends TSdMuxSnapshot {}
export const createSdMux = () =>
  types.optional(SdMux, {
    equipSid: 0,
    mainYn: false,
    hostNm: '',
    mgmtIp: '',
    modelNm: null,
    frmwrVer: null,
    inPortSid: null,
    inPortNm: null,
    outPortSid: null,
    outPortNm: null,
    scsPortSid: null,
    scsPortNm: null,
    ipout: null,
    inBPortSid: null,
    inBPortNm: null,
    outBPortSid: null,
    outBPortNm: null,
    emgInPortSid: null,
    emgInPortNm: null,
  } as TSdMux);
