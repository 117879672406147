import {
  GetBankDeleteResult,
  GetBankDuplicateResult,
  GetBankInsertResult,
  GetBankListResult,
  GetBankUpdatedResult,
} from './../../services/bank/bank-result-types';
import { BankSnapshot } from './../bank/bank';
import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { Bank, BankModel, createBankDefaultModel } from '../../models/bank/bank';
import { BankApi } from '../../services';
import { withEnvironment } from '../extensions/with-environment';
import { withRootStore } from './../extensions/with-root-store';

export const BankStoreModel = types
  .model('BankStore')
  .props({
    searchBankName: types.optional(types.string, ''),
    currentPage: types.optional(types.number, 1),
    count: types.optional(types.number, 100),
    bankList: types.optional(types.array(BankModel), []),
    bankObjectOnModal: createBankDefaultModel(),
    isUpdated: types.optional(types.boolean, false),
    isDeleted: types.optional(types.boolean, false),
    isInserted: types.optional(types.boolean, false),
    isDuplicated: types.optional(types.boolean, false),
    deleteList: types.optional(types.array(types.number), []),
  })
  .extend(withEnvironment)
  .extend(withRootStore)
  .actions((self) => ({
    setBankSearchName: (bankSearchName: string) => {
      self.searchBankName = bankSearchName;
    },
    setCurrentPage: (page: number) => {
      self.currentPage = page;
    },
    setCount: (count: number) => {
      self.count = count;
    },
    setBankList: (bankList: BankSnapshot[]) => {
      if (!bankList) {
        return;
      }
      self.bankList.replace(bankList as Bank[]);
    },
    setIsUpdated: (isUpdated: boolean) => {
      self.isUpdated = isUpdated;
    },
    setIsDeleted: (isDeleted: boolean) => {
      self.isDeleted = isDeleted;
    },
    setIsInserted: (isInserted: boolean) => {
      self.isInserted = isInserted;
    },
    setIsDuplicated: (isDuplicated: boolean) => {
      self.isDuplicated = isDuplicated;
    },

    resetDeleteList: () => {
      self.deleteList.clear();
    },
    appendDeleteList: (bankSeq: number) => {
      self.deleteList.push(bankSeq);
    },
  }))
  //Call API Actions
  .actions((self) => ({
    /**
     * Bank List를 가져오는 API를 호출, currentPage, count, bankName에 따라 결과가 달라진다.
     * @returns
     */
    getBankList: async () => {
      const bankApi: BankApi = new BankApi(self.environment.api);
      const result: GetBankListResult = await bankApi.getBankList(
        self.currentPage,
        self.count,
        self.searchBankName,
      );

      if (result.kind === 'ok') {
        if (!result.resultList) {
          return;
        }
        self.setBankList(result.resultList);
      } else {
      }
    },
    /**
     * Bank 데이터를 Update하는 API를 호출하고 결과에 따라 Props의 isUpdated를 Set한다.
     */
    updateBank: async () => {
      const bankApi: BankApi = new BankApi(self.environment.api);
      const result: GetBankUpdatedResult = await bankApi.updateBank(self.bankObjectOnModal);

      if (result.kind === 'ok') {
        if (result.result !== undefined) {
          self.setIsUpdated(result.result);
        }
      } else {
      }
    },
    /**
     * Bank 데이터를 Delete하는 API를 호출하고 결과에 따라 Props의 isDeleted를 Set한다.
     */
    deleteBank: async () => {
      const bankApi: BankApi = new BankApi(self.environment.api);
      const result: GetBankDeleteResult = await bankApi.deleteBank(self.deleteList);

      if (result.kind === 'ok') {
        if (result.result !== undefined) {
          self.setIsDeleted(result.result);
        }
      } else {
      }
    },
    /**
     * Bank 데이터를 Insert하는 API를 호출하고 결과에 따라 Props의 isInserted를 Set한다.
     */
    insertBank: async () => {
      const bankApi: BankApi = new BankApi(self.environment.api);
      const result: GetBankInsertResult = await bankApi.insertBank(self.bankObjectOnModal);

      if (result.kind === 'ok') {
        if (result.result !== undefined) {
          self.setIsInserted(result.result);
        }
      } else {
      }
    },
    /**
     * Bank Code 중복여부 검사후 Props 의 isDuplicated를 Set한다.
     * @returns
     */
    duplicateCheckBankCd: async () => {
      const bankApi: BankApi = new BankApi(self.environment.api);
      if (!self.bankObjectOnModal.bankCd) {
        return;
      }
      const result: GetBankDuplicateResult = await bankApi.bankDuplicateCheck(
        self.bankObjectOnModal.bankCd,
      );

      if (result.kind === 'ok') {
        if (result.result !== undefined) {
          self.setIsDuplicated(result.result);
        }
      } else {
      }
    },
  }));

type BankStoreType = Instance<typeof BankStoreModel>;
export interface BankStore extends BankStoreType {}
type BankStoreSnapshotType = SnapshotOut<typeof BankStoreModel>;
export interface BankStoreSnapshot extends BankStoreSnapshotType {}
export const createBankStoreDefaultModel = () => {};
