import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { IRulesetMappg } from '../../models';
import { RulesetMappgApi, TGetRulesetMappgResult, TGetRulesetMappgsResult } from '../../services';
import { withEnvironment } from '../extensions/with-environment';
import {
  TDeleteRulesetMappgResult,
  TPostRulesetMappgResult,
  TUpdateRulesetMappgResult,
} from './../../services/ruleset-mappg/RulesetMappgTypes';
import { withRootStore } from './../extensions/with-root-store';
import { createPagination, IPagination, IPaginationSnapshot } from './../pagination/Pagination';
import {
  createRulesetMappg,
  IRulesetMappgFilter,
  IRulesetMappgSnapshot,
} from './../ruleset-mappg/RulesetMappg';

/**
 * # RulesetMappgStore
 *
 * RulesetMappgStore을 설명하세요.
 */
export const RulesetMappgStore = types
  .model('RulesetMappgStore')
  // --------------------------------------------------------------------------
  .props({
    rulesetMappgs: types.optional(types.array(createRulesetMappg()), []),
    selectedRulesetMappg: types.maybeNull(createRulesetMappg()),
    deleteRulesetMappgList: types.optional(types.array(types.number), []),
    pagination: createPagination(),
  })
  .extend(withEnvironment)
  .extend(withRootStore)
  // eslint-disable-line @typescript-eslint/no-unused-vars
  .views((self) => ({}))
  // --------------------------------------------------------------------------
  // MUTATEs - 모델 상태를 변경
  .actions((self) => ({
    /**
     * rulesetMappgs을 교체
     *
     * @param `rulesetMappgs` 새로운 모델의 배열
     */
    setRulesetMappgs: (rulesetMappgs: IRulesetMappgSnapshot[]) => {
      self.rulesetMappgs.replace(rulesetMappgs as IRulesetMappg[]);
    },
    setPagination: (pagination: IPaginationSnapshot) => {
      self.pagination = pagination as IPagination;
    },
    setSelectedRulsetMappg: (rulsetMappg: IRulesetMappgSnapshot) => {
      self.selectedRulesetMappg = rulsetMappg as IRulesetMappg;
    },
    appendDeleteList: (ruleSetMappgSid: number) => {
      self.deleteRulesetMappgList.push(ruleSetMappgSid);
    },
    setDeleteList: (ruleSetMappgSidList: number[]) => {
      self.deleteRulesetMappgList.replace(ruleSetMappgSidList);
    },
  }))
  // --------------------------------------------------------------------------
  // REQUESTs - 서비스 요청 및 기타 인터페이스 요청
  .actions((self) => ({
    /**
     * INF_CAIMS_00217
     * 룰셋 매핑 목록을 조회한다.
     * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=1524179962
     * @param rulesetMappgFilter
     */
    gets: async (rulesetMappgFilter: IRulesetMappgFilter) => {
      self.setRulesetMappgs([]);
      try {
        const rulesetMappgApi: RulesetMappgApi = new RulesetMappgApi(self.environment.api);
        const result: TGetRulesetMappgsResult = await rulesetMappgApi.gets(
          rulesetMappgFilter,
          self.pagination,
        );

        if (
          self.rootStore.responseStore.getResponseResult(result) &&
          result.pagination &&
          result.pagination.page === self.pagination.page
        ) {
          if (result.rulesetMappgs) {
            self.setRulesetMappgs(result.rulesetMappgs);
          }
          if (result.pagination) {
            self.setPagination(result.pagination);
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },
    /**
     * INF_CAIMS_00218
     * 해당 룰셋 매핑의 상세정보를 조회한다.
     * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=1852772833
     */
    get: async (rulsetMappgFilter: IRulesetMappgFilter) => {
      self.selectedRulesetMappg = null;
      try {
        const rulesetMappgApi: RulesetMappgApi = new RulesetMappgApi(self.environment.api);
        const result: TGetRulesetMappgResult = await rulesetMappgApi.get(rulsetMappgFilter);

        if (self.rootStore.responseStore.getResponseResult(result)) {
          if (result.rulesetMappg) {
            self.setSelectedRulsetMappg(result.rulesetMappg);
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },
    /**
     * INF_CAIMS_00219
     * 새로운 룰셋 매핑을 등록한다.
     * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=819674977
     */
    post: async (rulesetMappg: IRulesetMappgSnapshot) => {
      self.selectedRulesetMappg = null;
      try {
        const rulesetMappgApi: RulesetMappgApi = new RulesetMappgApi(self.environment.api);
        const result: TPostRulesetMappgResult = await rulesetMappgApi.post(rulesetMappg);

        if (self.rootStore.responseStore.getResponseResult(result)) {
          if (result.rulesetMappg) {
            self.setSelectedRulsetMappg(result.rulesetMappg);
          }
        }

        //self.rootStore.LoadingStore.closeLoadingNoMsg();
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },
    /**
     * INF_CAIMS_00220
     * 해당 룰셋 매핑의 정보를 수정한다.
     * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=155195551
     */
    put: async (rulesetMappg: IRulesetMappgSnapshot) => {
      self.selectedRulesetMappg = null;
      try {
        const rulesetMappgApi: RulesetMappgApi = new RulesetMappgApi(self.environment.api);
        const result: TUpdateRulesetMappgResult = await rulesetMappgApi.put(rulesetMappg);

        if (self.rootStore.responseStore.getResponseResult(result)) {
          if (result.rulesetMappg) {
            self.setSelectedRulsetMappg(result.rulesetMappg);
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },
    /**
     * INF_CAIMS_00241
     * 해당 룰셋 매핑 정보를 삭제한다.
     * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=913005681
     */
    delete: async (ruleSetSid: number, ruleSetMappgSid: number) => {
      self.selectedRulesetMappg = null;
      try {
        const rulesetMappgApi: RulesetMappgApi = new RulesetMappgApi(self.environment.api);
        const result: TDeleteRulesetMappgResult = await rulesetMappgApi.delete(
          ruleSetSid,
          ruleSetMappgSid,
        );

        self.rootStore.responseStore.getResponseResult(result);
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },
    /**
     * INF_CAIMS_00217
     * 여러개 룰셋 매핑을 한번에 삭제한다.
     * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=617037567
     */
    deletes: async (rulSetSid: number) => {
      try {
        const rulesetMappgApi: RulesetMappgApi = new RulesetMappgApi(self.environment.api);
        const result: TDeleteRulesetMappgResult = await rulesetMappgApi.deletes(
          rulSetSid,
          self.deleteRulesetMappgList,
        );

        self.rootStore.responseStore.getResponseResult(result);
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },
  }));

// --------------------------------------------------------------------------
type TRulesetMappgStore = Instance<typeof RulesetMappgStore>;
type TRulesetMappgStoreSnapshot = SnapshotOut<typeof RulesetMappgStore>;

export interface IRulesetMappgStore extends TRulesetMappgStore {}
export type TRulesetMappgStoreKeys = keyof TRulesetMappgStoreSnapshot & string;
export interface IRulesetMappgStoreSnapshot extends TRulesetMappgStoreSnapshot {}
export const createRulesetMappgStore = () =>
  types.optional(RulesetMappgStore, {} as TRulesetMappgStore);
