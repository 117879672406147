import { Instance, SnapshotOut, types } from 'mobx-state-tree';

/**
 * # SimpleUser
 *
 * SimpleUser을 설명하세요.
 */
export const SimpleUser = types
  .model('SimpleUser')
  // --------------------------------------------------------------------------
  .props({
    userSid: types.optional(types.maybeNull(types.number), null),
    loginId: types.optional(types.maybeNull(types.string), null),
    userNm: types.optional(types.maybeNull(types.string), null),
  })
  // --------------------------------------------------------------------------
  // MUTATEs ONLY! - 모델 상태를 변경
  // REQUESTs는 스토어 모델에 작성하세요.
  // ex) yarn gen model simpleUser --store
  .actions((self) => ({
    /*
     * 여러 prop을 한번에 수정
     */

    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as ISimpleUser;
      (Object.keys(newProps) as TSimpleUserKeys[]).forEach((key) => {
        ///@ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type TSimpleUser = Instance<typeof SimpleUser>;
type TSimpleUserSnapshot = SnapshotOut<typeof SimpleUser>;

export interface ISimpleUser extends TSimpleUser {}
export type TSimpleUserKeys = keyof TSimpleUserSnapshot & string;
export interface ISimpleUserSnapshot extends TSimpleUserSnapshot {}
export const createSimpleUser = () =>
  types.optional(SimpleUser, {
    loginId: null,
    userNm: null,
    userSid: null,
  } as TSimpleUser);
