import { SxProps } from '@mui/system';
import { CSSProperties, ReactNode } from 'react';
import { Theme } from '@mui/material/styles';
// form
import { FormProvider as Form, UseFormReturn } from 'react-hook-form';

// ----------------------------------------------------------------------

type Props = {
  children: ReactNode;
  methods: UseFormReturn<any>;
  onSubmit?: VoidFunction;
  isLoginForm?: boolean;
  sx?: CSSProperties;
};

export default function FormProvider({ children, onSubmit, methods, isLoginForm, sx }: Props) {
  const { REACT_APP_API_URL } = process.env;
  return (
    <Form {...methods}>
      {isLoginForm ? (
        <form action={`${REACT_APP_API_URL}/loginProcess`} method="POST">
          {children}
        </form>
      ) : (
        <form onSubmit={onSubmit} style={sx}>{children}</form>
      )}
    </Form>
  );
}
