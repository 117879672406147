import { fontSize, minWidth, SxProps, Theme } from '@mui/system';
export const withButtonMargin = 2;
const FormMargin = 1;
export const FormStyle = {
  m: FormMargin,
  width: '100%',
};
export const SwitchStyle = {
  m: FormMargin,
  height: '37px',
  minWidth: 'max-content',
};

export const SelectStyle = {
  m: FormMargin,
  p: 0,
  width: '100%',
};

export const ScrollStyle = {
  overflowX: 'hidden',
  maxHeight: window.innerHeight - 500,
} as SxProps<Theme>;

export const DatePickerStyle = {
  m: FormMargin,
  width: '100%',
};

export const LabelStyle = {
  color: '#212B36',
  width: 'auto',
  wordBreak: 'keep-all',
  fontWeight: 500,
  fontSize: '14px',
} as SxProps<Theme>;

export const SearchLabelStyle = {
  color: '#212B36',
  // width: '100%',
  // backgroundColor: '#dfe3e8',
  // borderRadius: '8px',
  // paddingY: '7px',
  // paddingX: '14px',
  wordBreak: 'keep-all',
  fontWeight: 500,
  fontSize: '14px',
} as SxProps<Theme>;

export const SearchRowStyle = {
  borderBottom: '1px solid #cbcbcb',
};

export const FormSetLabelStyle = {
  fontWeight: 400,
  padding: '7px 7px 7px 15px',
  width: '100%',
};

export const FormSetSubTitleStyle = {
  fontWeight: 800,
  width: '100%',
};

export const FormSetTableStyle = {
  width: '100%',
};

export const FormSetMidMarginStyle = {
  marginBottom: 30,
};

export const TabStyle = {
  paddingLeft: 3,
  paddingRight: 3,
  fontSize: '1.2rem',
  background: '#EEEFF0',
  marginRight: '15px !important',
};

export const SubTabStyle = {
  paddingLeft: 2,
  paddingRight: 2,
  fontSize: '1rem',
  background: '#EEEFF0',
  marginRight: '12px !important',
};

export const TabPanelStyle = {
  padding: 2,
};

export const SubTabPanelStyle = {
  padding: 2,
  border: 'rgba(145, 158, 171, 0.24) solid 1px',
  borderRadius: 1,
};
