import {ReactNode} from 'react';
// hooks
import {useAuth} from '../hooks/useAuth';
// pages
import {isNull} from 'lodash';
import {observer} from 'mobx-react-lite';
import Login from '../screens/login/login-screen';
import {useStores} from '../models';
import {Navigate, useLocation} from "react-router-dom";

// ----------------------------------------------------------------------

type AuthGuardProps = {
  children: ReactNode;
};

export const AuthGuard = observer(function AuthGuard({ children }: AuthGuardProps) {
  const { user } = useAuth();
  const {authStore} = useStores();
  const { pathname } = useLocation();


  if(isNull(user))
  {
    return <Login />;
  }
  else if(isNull(authStore.getLoginUserStorageData())) // SessionContext user 업데이트 안쳐졌을 경우를 대비해서.
  {
    return <Login />;
  }
  if (pathname === '/')
  {
    const length = user.menuList.filter((v: any) => v.menuSid === 11).length;
    if(length === 0)
    {
      window.location.href = user.menuList.filter((v: any) => v.menuUri !== null && v.menuSid !== 41)[0].menuUri;
    }
  }
  else
  {
    const length = user.menuList.filter((v: any) => v.menuUri !== null && v.menuUri === pathname).length;
    if(length === 0)
    {
      return <><Navigate to="/404" replace /></>;
    }
  }

  return <>{children}</>;
});
