import { createModelCode, IModelCodeSnapshot } from './../model-code/ModelCode';
import { Instance, SnapshotOut, types } from 'mobx-state-tree';

/**
 * # SnmpAuthInfo
 *
 * SnmpAuthInfo을 설명하세요.
 */
export const SnmpAuthInfo = types
  .model('SnmpAuthInfo')
  // --------------------------------------------------------------------------
  .props({
    snmpAuthMethod: createModelCode(),
    cmnty: types.optional(types.maybeNull(types.string), null),
    securNm: types.optional(types.maybeNull(types.string), null),
    authPrtl: types.maybeNull(createModelCode()),
    authPwd: types.optional(types.maybeNull(types.string), null),
    privPrtl: types.maybeNull(createModelCode()),
    privPwd: types.optional(types.maybeNull(types.string), null),
  })
  // --------------------------------------------------------------------------
  // MUTATEs ONLY! - 모델 상태를 변경
  // REQUESTs는 스토어 모델에 작성하세요.
  // ex) yarn gen model snmpAuthInfo --store
  .actions((self) => ({
    /*
     * 여러 prop을 한번에 수정
     */
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as ISnmpAuthInfo;
      (Object.keys(newProps) as TSnmpAuthInfoKeys[]).forEach((key) => {
        //@ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type TSnmpAuthInfo = Instance<typeof SnmpAuthInfo>;
type TSnmpAuthInfoSnapshot = SnapshotOut<typeof SnmpAuthInfo>;

export interface ISnmpAuthInfo extends TSnmpAuthInfo {}
export type TSnmpAuthInfoKeys = keyof TSnmpAuthInfoSnapshot & string;
export interface ISnmpAuthInfoSnapshot extends TSnmpAuthInfoSnapshot {}
export const createSnmpAuthInfo = () =>
  types.optional(SnmpAuthInfo, {
    authPrtl: null,
    authPwd: null,
    cmnty: null,
    privPrtl: null,
    privPwd: null,
    securNm: null,
    snmpAuthMethod: {} as IModelCodeSnapshot,
  } as TSnmpAuthInfo);
