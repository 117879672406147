import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { createSop, ISop, Sop, TSopFilter } from '../sop/Sop';
import {
  SopApi,
  TDeleteSopResult,
  TFileSopResult,
  TGetSopResult,
  TGetSopsResult,
  TPostSopResult, TSpcfResult, TStringResult,
  TUpdateSopResult,
} from '../../services';
import { timestampToDate } from '../../utils/dateExchanger';
import { withEnvironment } from '../extensions/with-environment';
import { createPagination, IPagination, IPaginationSnapshot } from '../pagination/Pagination';
import { createResponse } from '../response/Response';
import { ISopSnapshot } from '../sop/Sop';
import { withRootStore } from '../extensions/with-root-store';
import { createSopFile, ISopFile, ISopFileSnapshot } from '../sop-file/SopFile';
import {selectOptions} from "../../components/forms/CSelect";
import {createModelCode, IModelCode, IModelCodeSnapshot, ModelCode} from "../model-code/ModelCode";

// @ts-ignore
export const SopStore = types
  .model('SopStore')
  .props({
    sops: types.optional(types.array(Sop), []),
    selectedSop: createSop(),
    kbdSidList: types.optional(types.array(types.number), []),
    pagination: createPagination(),
    responseInfo: createResponse(),
    checkDuplicatedSaid: types.optional(types.boolean, false),
    problCause: types.optional(types.maybeNull(types.array(types.string)),[]),
    spcfProbl: types.optional(types.maybeNull(types.array(types.string)),[]),
    sopEventTypeSidList: types.optional(types.maybeNull(types.array(types.number)),[]),
    spcfProblOption: types.optional(types.maybeNull(types.array(ModelCode)),[]),
  })
  .extend(withEnvironment)
  .extend(withRootStore)
  .views((self) => ({}))
  .actions((self) => ({
    setSops: (sops: ISopSnapshot[]) => {
      self.sops.replace(sops as ISop[]);
    },
    setPagination: (pagination: IPaginationSnapshot) => {
      self.pagination = pagination as IPagination;
    },
    setSelectedSop: (sop: ISopSnapshot) => {
      self.selectedSop = sop as ISop;
    },
    setDeleteSopList: (deleteSid: number[]) => {
      self.kbdSidList.replace(deleteSid);
    },
    appendDeleteSopList: (deleteSid: number) => {
      self.kbdSidList.push(deleteSid);
    },
    setCheckDuplicatedSaid: (checkDuplicatedSaid: boolean) => {
      self.checkDuplicatedSaid = checkDuplicatedSaid;
    },
    setProblCause: (problCause: string[]) => {
      self.problCause?.replace(problCause);
    },
    setSpcfProbl: (spcfProbl:string[]) => {
      self.spcfProbl?.replace(spcfProbl);
    },
    setSopEventTypeSidList: (sopEventTypeSidList: number[]) => {
      self.sopEventTypeSidList?.replace(sopEventTypeSidList);
    },
    setspcfProblOption: (spcfProblOption: IModelCode[]) => {
      self.spcfProblOption?.replace(spcfProblOption);
    },

  }))
  .actions((self) => ({
    /**
     * INF_CAIMS_00118
     * 전체 SOP 목록을 조회한다.
     * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=1754789027
     * @param sopFilter
     */
    gets: async (sopFilter?: TSopFilter) => {
      self.setSops([]);
      try {
        const sopApi: SopApi = new SopApi(self.environment.api);
        let result: TGetSopsResult;

        if (sopFilter) {
          result = await sopApi.gets(self.pagination, sopFilter);
        } else {
          result = await sopApi.gets(self.pagination);
        }

        if (
          self.rootStore.responseStore.getResponseResult(result) &&
          result.pagination &&
          result.pagination.page === self.pagination.page
        ) {
          if (result.sops) {
            result.sops.forEach((item: ISopSnapshot, index: number) => {
              item.regDt = timestampToDate(Number.parseInt(item.regDt));
              item.updDt = timestampToDate(Number.parseInt(item.updDt));
            });

            self.setSops(result.sops);

            if (result.pagination) {
              self.setPagination(result.pagination);
            }
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },

    /**
     * INF_CAIMS_00119
     * 해당 SOP의 상세정보를 조회한다.
     * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=486134938
     * @param sopSid
     */
    get: async (sopSid: number) => {
      try {
        const sopApi: SopApi = new SopApi(self.environment.api);
        const result: TGetSopResult = await sopApi.get(sopSid);

        if (self.rootStore.responseStore.getResponseResult(result)) {
          if (result.sop) {
            result.sop.regDt = timestampToDate(Number.parseInt(result.sop.regDt));
            result.sop.updDt = timestampToDate(Number.parseInt(result.sop.updDt));
            self.setSelectedSop(result.sop);
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },

    /**
     * INF_CAIMS_00120
     * 새로운 SOP를 등록한다.
     * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=166393514
     * @param sop
     */
    post: async (sop: ISopSnapshot) => {
      try {
        const sopApi: SopApi = new SopApi(self.environment.api);
        const result: TPostSopResult = await sopApi.post(sop);

        if (self.rootStore.responseStore.getResponseResult(result)) {
          if (result.sop) {
            result.sop.regDt = timestampToDate(Number.parseInt(result.sop.regDt));
            result.sop.updDt = timestampToDate(Number.parseInt(result.sop.updDt));

            self.setSelectedSop(result.sop);
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },

    /**
     * INF_CAIMS_00121
     * 해당 SOP를 수정한다.
     * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=540156253
     * @param sop
     */
    put: async (sop: ISopSnapshot) => {
      try {
        const sopApi: SopApi = new SopApi(self.environment.api);
        const result: TUpdateSopResult = await sopApi.put(sop);

        if (self.rootStore.responseStore.getResponseResult(result)) {
          if (result.sop) {
            result.sop.regDt = timestampToDate(Number.parseInt(result.sop.regDt));
            result.sop.updDt = timestampToDate(Number.parseInt(result.sop.updDt));

            self.setSelectedSop(result.sop);
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },

    /**
     * INF_CAIMS_00122
     * 해당 SOP를 삭제한다.
     * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=458840650
     * @param sopSid
     */
    delete: async (sopSid: number) => {
      try {
        const sopApi: SopApi = new SopApi(self.environment.api);
        const result: TDeleteSopResult = await sopApi.delete(sopSid);
        self.rootStore.responseStore.getResponseResult(result);
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },

    /**
     * INF_CAIMS_00123
     * 여러개 SOP를 한번에 삭제한다.
     * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=1905178102
     */
    deletes: async () => {
      try {
        const sopApi: SopApi = new SopApi(self.environment.api);
        const result: TDeleteSopResult = await sopApi.deletes(self.kbdSidList);
        self.rootStore.responseStore.getResponseResult(result);
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },

    /**
     * INF_CAIMS_00313
     * 해당 SOP File을 가져온다
     * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=2051487926
     * @param sopSid, @param fileOrder @param fileName
     * @returns
     */
    getFile: async (sopFileSid:number) => {
      try {
        const sopApi: SopApi = new SopApi(self.environment.api);
        const result: TFileSopResult = await sopApi.getFile(sopFileSid);

        if (self.rootStore.responseStore.getResponseResult(result)) {
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },
    getProblCause: async (intrlkSysSid:number, eventType:number) => {
      try{
        const sopApi: SopApi = new SopApi(self.environment.api);
        const result: TStringResult = await sopApi.getProblCause(intrlkSysSid,eventType);
        if (self.rootStore.responseStore.getResponseResult(result)) {
          if(result.result)
          {
            self.setProblCause(result.result);
          }
        }
      } catch(e){
        self.rootStore.responseStore.errorProcessing(e);
      }
    },
    getspcfProbl: async (intrlkSysSid:number, eventType:number, problCause:string) => {
      try{
        const sopApi: SopApi = new SopApi(self.environment.api);
        const result: TSpcfResult = await sopApi.getspcfProbl(intrlkSysSid,eventType,problCause);
        if (self.rootStore.responseStore.getResponseResult(result)) {
          if(result.result)
          {
            const spcfProblOption:any = [];
            result.result.map((item) => {
              const option = {
                code : Number(item.sopEventTypeSid),
                value : item.spcfProbl,
                pcode : 0,
              };
              spcfProblOption.push(option);
            })
            self.setspcfProblOption(spcfProblOption);
          }

        }
      } catch(e){
        self.rootStore.responseStore.errorProcessing(e);
      }
    }
  }));

type TSopStore = Instance<typeof SopStore>;
type TSopStoreSnapshot = SnapshotOut<typeof SopStore>;

export interface ISopStore extends TSopStore {}
export type TSopStoreKeys = keyof TSopStoreSnapshot & string;
export interface ISopStoreSnapshot extends TSopStoreSnapshot {}
export const createSopStore = () => types.optional(SopStore, {} as TSopStore);
