import { Instance, SnapshotOut, types } from 'mobx-state-tree'
import { createSimpleUser } from '../simple-user/SimpleUser';

/**
 * # EpgData
 * 
 * EpgData을 설명하세요.
 */
export const EpgData = types
  .model('EpgData')
  // --------------------------------------------------------------------------
  .props({
    regDt: types.optional(types.maybeNull(types.number), null),
    updDt: types.optional(types.maybeNull(types.number), null),
    chSid: types.optional(types.number, 0),
    bradDate: types.optional(types.maybeNull(types.number), null),
    bradStTime: types.optional(types.string, ''),
    bradEdTime: types.optional(types.string, ''),
    chNm: types.optional(types.string, ''),
    parentGenre: types.optional(types.string, ''),
    childGenre: types.optional(types.string, ''),
    title: types.optional(types.string, ''),
    subTitle: types.optional(types.string, ''),
    episode: types.optional(types.maybeNull(types.number), null),
    casts: types.optional(types.string, ''),
    crew: types.optional(types.string, ''),
    masterId: types.optional(types.string, ''),
    programId: types.optional(types.string, ''),
    story: types.optional(types.string, ''),
    liveYn: types.optional(types.boolean, false),
    reBroadcast: types.optional(types.boolean, false),
    hdYn: types.optional(types.boolean, false),
    audioInfo: types.optional(types.string, ''),
    epgSvrt: types.maybeNull(types.optional(types.number, 0)),
    imageInfo: types.optional(types.string, ''),
    subTitleYn: types.optional(types.boolean, false), // 자방방송 여부
    commentaryYn: types.optional(types.boolean, false), // 해설방송 여부
    chNo: types.maybeNull(types.optional(types.number, 0)),
    sighLanguage: types.optional(types.boolean, false), // 수화방송 여부
    subTitleRealYn: types.optional(types.boolean, true), // 자막 방송인데 자막이 없으면 false
    commentaryRealMainYn: types.optional(types.boolean, true), // 해설 방송인데 메인 오디오가 없으면 false
    commentaryRealBackupYn: types.optional(types.boolean, true), // 해설 방송인데 백업 오디오가 없으면 false
    bradDtSt: types.optional(types.number, 0),
    bradDtEd: types.optional(types.number, 0),
    chSvcIdList: types.optional(types.maybeNull(types.array(types.string)), []),
    regUser: types.maybeNull(createSimpleUser()),
    updUser: types.maybeNull(createSimpleUser())
  })
  // --------------------------------------------------------------------------
  // MUTATEs ONLY! - 모델 상태를 변경
  // REQUESTs는 스토어 모델에 작성하세요.
  // ex) yarn gen model epgData --store
  .actions((self) => ({
    /*
     * 여러 prop을 한번에 수정
     */
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as IEpgData;
      (Object.keys(newProps) as TEpgDataKeys[]).forEach((key) => {
        // @ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type TEpgData = Instance<typeof EpgData>;
type TEpgDataSnapshot = SnapshotOut<typeof EpgData>

export interface IEpgData extends TEpgData {}
export type TEpgDataKeys = keyof TEpgDataSnapshot & string;
export interface IEpgDataSnapshot extends TEpgDataSnapshot {}
export const createEpgData = () => 
  types.optional(EpgData, {
    chSid: 0,
    bradStTime: '',
    bradEdTime: '',
    chNm: '',
    parentGenre: '',
    childGenre: '',
    title: '',
    subTitle: '',
    casts: '',
    crew: '',
    masterId: '',
    programId: '',
    story: '',
    liveYn: false,
    reBroadcast: false,
    hdYn: false,
    audioInfo: '',
    imageInfo: '',
    subTitleYn: false,
    commentaryYn: false,
    sighLanguage: false,
  } as TEpgData);
