import { ApiResponse } from 'apisauce';
import { getGeneralApiProblem } from '../../services/api';
import {
  GetDuplicateRegisterResult,
  GetLoginTypeResult,
  GetLoginUserInfoResult,
  GetRegisterResult, LoginResponseTypeResult,
  LoginResultType
} from '../../services/auth/auth-result-types';
import { strStampToDate } from '../../utils/dateExchanger';
import { Api } from '../api/api';
import { IResponseSnapshot } from './../../models/response/Response';
import { IUserSnapshot } from './../../models/user/user';
import { IUsrgrpMenuSnapshot } from './../../models/usrgrp-menu/UsrgrpMenu';
import {ILoginResponse, ILoginResponseSnapshot} from "../../models";

export class AuthApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  // async login(id: string, pw: string): Promise<any> {
  //   try {
  //     const url: string = `/loginProcess`;
  //     const formData = new FormData();
  //     formData.append('userId', id);
  //     formData.append('userPwd', pw);
  //     await this.api.apisauce.setHeader('Content-Type', 'application/x-www-form-urlencoded');
  //     // const response: ApiResponse<any> = await this.api.apisauce.post(url, formData);

  //     // if (!response.ok) {
  //     //   const problem = getGeneralApiProblem(response);
  //     //   if (problem) return problem;
  //     // }

  //     return { kind: 'ok' };
  //   } catch (e) {
  //     return { kind: 'bad-data' };
  //   }
  // }

  async logout(): Promise<any> {
    try {
      const URL: string = `/logout`;

      const response: ApiResponse<any> = await this.api.apisauce.get(URL);
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;
      if (responseInfo.resultCode === 'S') {
        return {
          kind: 'ok',
          responseInfo: responseInfo,
          user: response.data.data,
        };
      }

      return { kind: 'ok', responseInfo: responseInfo };
    } catch (e) {
      return {
        kind: 'bad-data',
      };
    }
  }

  async getLoginUserInfo(): Promise<GetLoginUserInfoResult> {
    try {
      const URL: string = `/login/user`;

      const response: ApiResponse<any> = await this.api.apisauce.get(URL);
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;
      if (responseInfo.resultCode === 'S') {
        if (response.data.data) {
          if (response.data.data.menuList) {
            response.data.data.menuList.forEach((item: IUsrgrpMenuSnapshot) => {
              item.regDt = strStampToDate(item.regDt);
              item.updDt = strStampToDate(item.updDt);
            });
          }
          return {
            kind: 'ok',
            responseInfo: responseInfo,
            user: response.data.data,
          };
        }
      }

      return { kind: 'ok', responseInfo: responseInfo };
    } catch (e) {
      return {
        kind: 'bad-data',
      };
    }
  }

  /**
   * 회원 가입 API
   * @param user
   * @returns
   */
  async register(user: IUserSnapshot): Promise<GetRegisterResult> {
    try {
      const SUB_URL = `/user`;

      //아이디 , 비밀번호, 이름, 이메일을 제외한 나머지 데이터는 테스트 유저와 동일한 데이터로 하드코딩 되어있음.
      const SEND_DATA = {
        usrAuthTpCd: 'BASE_BANK',
        businessSeq: '76',
        bank: 'TSA',
        regId: 'ADMIN',
        acceptYn: 'N',
        usrId: user.loginId,
        // usrPwd: user.usrPwd,
        usrNm: user.userNm,
        // usrEmailAddr: user.usrEmailAddr,
      };

      const response: ApiResponse<any> = await this.api.apisauce.post(SUB_URL, SEND_DATA);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      let result = response.data.responseData.resultCode;

      return { kind: 'ok', result: result === 'OK' };
    } catch (e) {
      return {
        kind: 'notOk',
      };
    }
  }

  /**
   * 사용자 ID 중복검사 API
   * @param userId
   * @returns
   */
  async checkDuplicate(userId: string): Promise<GetDuplicateRegisterResult> {
    try {
      const USER_ID = userId;
      const SUB_URL = `/user/isDuplicatedUserId?usrId=${USER_ID}`;
      const response: ApiResponse<any> = await this.api.apisauce.get(SUB_URL);

      let result = response.data.responseData.resultCode;

      //아이디 중복일 경우 FAIL / 중복이 아닐 경우 OK
      return { kind: 'ok', result: result === 'FAIL' };
    } catch (e) {
      return {
        kind: 'notOk',
      };
    }
  }

  async getLoginType(id: string, pw: string): Promise<GetLoginTypeResult> {
    try {
      const url: string = `/login/user`;
      let form = new FormData();
      form.append('userId',id);
      form.append('userPwd',pw);
      //x-www-urlencoded
      await this.api.apisauce.setHeader('Content-Type', 'application/x-www-form-urlencoded');
      const respopnse: ApiResponse<any> = await this.api.apisauce.post(url, form);

      const responseInfo: IResponseSnapshot = respopnse.data;
      if (responseInfo.resultCode === 'S') {
        const result: boolean = respopnse.data.data;
        return { kind: 'ok', result: result, responseInfo: responseInfo };
      }

      return { kind: 'ok', responseInfo: responseInfo };
    } catch (e) {
      return {
        kind: 'bad-data',
      };
    }
  }

  async loginProcess(id:string, password:string, type:string):Promise<LoginResponseTypeResult>{
    try{
      const url : string = `/loginProcess`;
      const formData = new FormData();
      formData.append("userId", id);
      formData.append('userPwd', password);
      formData.append("type", type);
      const data = {
        userId: id,
        userPwd: password,
        type : type
      };
      await this.api.apisauce.setHeader('Content-Type', 'application/x-www-form-urlencoded');
      const response:ApiResponse<any> = await this.api.apisauce.post(url,formData);
      await this.api.apisauce.setHeader('Content-Type', 'application/json');

      const responseInfo : IResponseSnapshot = response.data;
      if(responseInfo.resultCode === 'S'){
        const result: ILoginResponse = response.data.data;
        return { kind: 'ok', loginResponse : result , responseInfo:responseInfo};
      }

      return { kind: 'ok', responseInfo: responseInfo };

    }catch(e){
      return {kind:'bad-data'}
    }
  };

  async otpProcess(otpNumber:string):Promise<LoginResponseTypeResult>{
    try{
      // `/user/isDuplicatedUserId?usrId=${USER_ID}`;
      const url:string = `/loginProcess`;
      const formData = new FormData();
      formData.append("otp", otpNumber);
      formData.append("type", "p2");
      await this.api.apisauce.setHeader('Content-Type', 'application/x-www-form-urlencoded');
      const response:ApiResponse<any> = await this.api.apisauce.post(url, formData);
      await this.api.apisauce.setHeader('Content-Type', 'application/json');
      const responseInfo: IResponseSnapshot = response.data;
      if(responseInfo.resultCode === 'S')
      {
        const result:ILoginResponse = response.data.data;
        return {kind: 'ok', loginResponse : result, responseInfo: responseInfo};
      }
      return {kind: 'ok', responseInfo: responseInfo}
    }
    catch (e)
    {
      return {kind: 'bad-data'}
    }
  }

}
