import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { LinkPortApi } from './../../services/link-port/LinkPort';
import { withRootStore } from './../extensions/with-root-store';
import { createLinkPort, ILinkPortSnapshot } from './../link-port/LinkPort';
import { IPortSnapshot } from './../port/Port';

import { ILinkPort } from '../../models';
import { TLinksResult, TSelectableResult } from '../../services';
import { withEnvironment } from '../extensions/with-environment';

/**
 * # LinkPortStore
 *
 * LinkPortStore을 설명하세요.
 */
export const LinkPortStore = types
  .model('LinkPortStore')
  // --------------------------------------------------------------------------
  .props({
    linkPorts: types.optional(types.array(createLinkPort()), []),
    realPorts: types.optional(types.array(createLinkPort()), []),
    linkPort: createLinkPort(),
  })
  .extend(withEnvironment)
  .extend(withRootStore)
  // eslint-disable-line @typescript-eslint/no-unused-vars
  .views((self) => ({}))
  // --------------------------------------------------------------------------
  // MUTATEs - 모델 상태를 변경
  .actions((self) => ({
    /**
     * linkPorts을 교체
     *
     * @param `linkPorts` 새로운 모델의 배열
     */
    setLinkPorts: (linkPorts: ILinkPortSnapshot[]) => {
      self.linkPorts.replace(linkPorts as ILinkPort[]);
    },
    appendLinkPorts: (linkPort: ILinkPortSnapshot) => {
      self.linkPorts.push(linkPort as ILinkPort);
    },
    removeElementOfLinkPorts: (linkPort: ILinkPortSnapshot) => {
      self.linkPorts.forEach((item, index) => {
        if (item.srcPortSid === linkPort.srcPortSid && item.dstPortSid === linkPort.dstPortSid) {
          self.linkPorts.remove(item);
        }
      });

      // for(let i = 0 ; i < self.linkPorts.length ; i++){

      // }
    },
    setLinkPort: (linkPort: ILinkPortSnapshot) => {
      self.linkPort = linkPort as ILinkPort;
    },
    setRealPorts: (linkPorts: ILinkPortSnapshot[]) => {
      self.realPorts.replace(linkPorts as ILinkPort[]);
    },
    deleteExistPortData: (realPorts: ILinkPortSnapshot[]) => {
      self.realPorts.forEach((realItem) => {
        self.linkPorts.forEach((item, index) => {
          if (realItem.dstPortSid === item.dstPortSid && realItem.srcPortSid === item.srcPortSid) {
            self.realPorts.remove(realItem);
          }
        });
      });
    },
  }))
  // --------------------------------------------------------------------------
  // REQUESTs - 서비스 요청 및 기타 인터페이스 요청
  .actions((self) => ({
    getSelectablePorts: async (
      srcEquipGroupSid: number,
      srcGroupYn: boolean,
      dstEquipGroupSid: number,
      dstGroupYn: boolean,
    ): Promise<TReturnSelectableResult> => {
      try {
        const linkPortApi: LinkPortApi = new LinkPortApi(self.environment.api);
        const result: TSelectableResult = await linkPortApi.getSelectablePorts(
          srcEquipGroupSid,
          srcGroupYn,
          dstEquipGroupSid,
          dstGroupYn,
        );

        let srcPortList: IPortSnapshot[] = [];
        let dstProtList: IPortSnapshot[] = [];

        if (self.rootStore.responseStore.getResponseResult(result)) {
          if (result.dstProtList) {
            dstProtList = result.dstProtList;
          }

          if (result.srcPortList) {
            srcPortList = result.srcPortList;
          }
        }

        return {
          srcPortList: srcPortList,
          dstProtList: dstProtList,
        } as TReturnSelectableResult;
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
        return {
          srcPortList: [],
          dstProtList: [],
        };
      }
    },

    getLinksFromTopology: async (topolId: string, topolLinkId: string) => {
      try {
        const linkPortApi: LinkPortApi = new LinkPortApi(self.environment.api);
        const result: TLinksResult = await linkPortApi.getLinksFromTopology(topolId, topolLinkId);

        if (self.rootStore.responseStore.getResponseResult(result)) {
          if (result.linkPorts) {
            self.setLinkPorts(result.linkPorts);
          } else {
            self.setLinkPorts([]);
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },

    putLinkOfTopology: async (linkPorts: ILinkPortSnapshot[], topolId: string, linkId: string) => {
      try {
        const linkPortApi: LinkPortApi = new LinkPortApi(self.environment.api);
        const result: TLinksResult = await linkPortApi.putLinkOfTopology(
          linkPorts,
          topolId,
          linkId,
        );

        if (self.rootStore.responseStore.getResponseResult(result)) {
          if (result.linkPorts) {
            self.setLinkPorts(result.linkPorts);
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },

    getRealPortOfLink: async (
      srcEquipGroupSid: number,
      srcGroupYn: boolean,
      dstEquipGroupSid: number,
      dstGroupYn: boolean,
    ): Promise<TLinksResult> => {
      try {
        const linkPortApi: LinkPortApi = new LinkPortApi(self.environment.api);
        const result: TLinksResult = await linkPortApi.getRealPortOfLink(
          srcEquipGroupSid,
          srcGroupYn,
          dstEquipGroupSid,
          dstGroupYn,
        );
        self.rootStore.responseStore.getResponseResult(result);
        return result;
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
        return { kind: `bad-data` };
      }
    },
  }));

// --------------------------------------------------------------------------
type TLinkPortStore = Instance<typeof LinkPortStore>;
type TLinkPortStoreSnapshot = SnapshotOut<typeof LinkPortStore>;

export interface ILinkPortStore extends TLinkPortStore {}
export type TLinkPortStoreKeys = keyof TLinkPortStoreSnapshot & string;
export interface ILinkPortStoreSnapshot extends TLinkPortStoreSnapshot {}
export const createLinkPortStore = () => types.optional(LinkPortStore, {} as TLinkPortStore);

type TReturnSelectableResult = {
  srcPortList: IPortSnapshot[];
  dstProtList: IPortSnapshot[];
};
