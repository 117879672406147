import { Box, Card, Table, TableBody, TableCell, TableContainer, TableRow, Tooltip } from '@mui/material';
import * as _ from 'lodash';
import { useSnackbar } from 'notistack';
import { useEffect, useState, MouseEvent } from 'react';
import { useForm } from 'react-hook-form';
import { CC } from '../../../commonCodes/commonCodes';
import CAlert from '../../../components/CAlert';
import CButtonSet from '../../../components/CButtonSet';
import CButtonSetItem from '../../../components/CButtonSetItem';
import CFormSet from '../../../components/CFormSet';
import CFormSetItem from '../../../components/CFormSetItem';
import { CTableHead } from '../../../components/CTableHead';
import { Order } from '../../../components/CTableToolbar';
import CSelect, { selectOptions } from '../../../components/forms/CSelect';
import CSwitch from '../../../components/forms/CSwitch';
import CTextField from '../../../components/forms/CTextField';
import { FormProvider } from '../../../components/hook-form';
import {
  BUTTON_LABEL,
  SNACKBAR_MSG,
  RESULT_CODE,
} from '../../../components/settings/SettingConstEnum';
import { IRealTimeAlarm, IRealTimeAlarmSnapshot, useStores } from '../../../models';
import { CallApiToStore } from '../../Home';
import { headCells } from './AiAlarmDetailBasicData';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import { isNull } from 'lodash';

export const AiAlarmsDetailBasic = observer(() => {

  const { enqueueSnackbar } = useSnackbar();

  const rootStore = useStores();

  const { realtimeAlarmStore, responseStore, loadingStore } = rootStore;

  const [defaultValues, setDefaultValues] = useState<IRealTimeAlarmSnapshot>(realtimeAlarmStore.selectedAlarm);
  const [sendData, setSendData] = useState<IRealTimeAlarmSnapshot>();
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [isValidAlertOpen, setIsValidAlertOpen] = useState(false);
  const [validAlertMsg, setValidAlertMsg] = useState('');
  const handleValidAlertClose = () => setIsValidAlertOpen(false);
 
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<string>('reg_date');
  const handleRequestSort = (event: MouseEvent<unknown>, property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const methods = useForm<IRealTimeAlarmSnapshot>({ defaultValues: realtimeAlarmStore.selectedAlarm as IRealTimeAlarmSnapshot });

  const {
    getValues,
    setValue,
    handleSubmit,
    control,
    formState: { errors },
  } = methods;
  
  let operatorNote: any = realtimeAlarmStore.selectedAlarm.operatorNote ? JSON.parse(realtimeAlarmStore.selectedAlarm.operatorNote) : null;
  setValue('oprtrNote', '');
  setValue('oprtrNoteCtg', operatorNote?.oprtrNoteCtg);
  setValue('noteProblCause', operatorNote?.noteProblCause);
  
  useEffect(() => {
    sendData && onPut();
  }, [sendData, realtimeAlarmStore.selectedAlarm]);

  const onSubmit = async (data: IRealTimeAlarmSnapshot) => {
    // console.log("data : ", data);
    // if (data.oprtrNote == '') {
    //   setIsValidAlertOpen(true);
    //   setValidAlertMsg('내용을 입력하세요.');
    //   return false;
    // }
    if (_.isEmpty(errors)) {
      setSendData(data);
    }
  };

  const onPut = async () => {
    await CallApiToStore(realtimeAlarmStore.put(sendData as IRealTimeAlarm), 'api', loadingStore).then(
      () => {
        if (responseStore.responseInfo.resultCode === RESULT_CODE.SUCCESS) {
          enqueueSnackbar(SNACKBAR_MSG.common.save, { variant: 'success' });
        }
      },
    );
  }

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>

      <Box sx={{ mt: 1.5 }}>

        <Card sx={{ p: 1.5, m: 1.5 }}>
          <CFormSet column={4} subTitle={'영향 채널(서비스) 정보'}>
            <CFormSetItem>
              <CSelect
                label={'유형'}
                name={'targetInstanceType'}
                options={CC.INST_TYPE.__list__ as selectOptions[]}
                readonly
                code
              ></CSelect>
            </CFormSetItem>
            <CFormSetItem>
              <CTextField label={'서비스 ID'} name={'targetInstanceId'} readonly />
            </CFormSetItem>
            <CFormSetItem>
              <CTextField label={'채널 번호'} name={'affectChannel'} readonly />
            </CFormSetItem>
            <CFormSetItem>
              <CTextField label={'이름'} name={'targetInstanceName'} readonly />
            </CFormSetItem>
            <CFormSetItem grid={4}>
              <CTextField
                label={'알람 메시지'}
                name={'message'}
                variant={'outlined'}
                color={'info'}
                readonly
                multiline
                multilineHeight={70}
              />
            </CFormSetItem>
          </CFormSet>
        </Card>

        <Card sx={{ p: 1.5, m: 1.5 }}>
          <CFormSet column={4} subTitle={'발생장비'}>
            <CFormSetItem>
              <CSelect
                label={'유형'}
                name={'instanceType'}
                options={CC.INST_TYPE.__list__ as selectOptions[]}
                readonly
                code
              ></CSelect>
            </CFormSetItem>
            <CFormSetItem>
              <CTextField label={'이름'} name={'instanceName'} readonly />
            </CFormSetItem>
            <CFormSetItem>
              <CTextField label={'전체 이름'} name={'instanceFullName'} readonly />
            </CFormSetItem>
            <CFormSetItem>
              <CTextField label={'포트 이름'} name={'instancePortName'} readonly />
            </CFormSetItem>
          </CFormSet>
        </Card>

        {!isNull(defaultValues.instanceNamez) && (
          <Card sx={{ p: 1.5, m: 1.5 }}>
            <CFormSet column={4} subTitle={'종단 장비'}>
              <CFormSetItem>
                <CSelect
                  label={'유형'}
                  name={'instanceTypez'}
                  options={CC.INST_TYPE.__list__ as selectOptions[]}
                  readonly
                  code
                ></CSelect>
              </CFormSetItem>
              <CFormSetItem>
                <CTextField label={'이름'} name={'instanceNamez'} readonly />
              </CFormSetItem>
              <CFormSetItem>
                <CTextField label={'전체 이름'} name={'instanceFullNamez'} readonly />
              </CFormSetItem>
              <CFormSetItem>
                <CTextField label={'포트 이름'} name={'instancePortNamez'} readonly />
              </CFormSetItem>
            </CFormSet>
          </Card>
        )}

        <Card sx={{ p: 1.5, m: 1.5 }}>
          <CFormSet column={3} subTitle={'기본정보'}>
            <CFormSetItem>
              <CTextField
                label={'시스템이름'}
                name={'systemName'}
                readonly
              />
            </CFormSetItem>
            <CFormSetItem>
              <CSelect
                label={'등급'}
                name={'perceivedSeverity'}
                options={CC.SVRT.__list__ as selectOptions[]}
                readonly
                code
              ></CSelect>
            </CFormSetItem>
            <CFormSetItem>
              <CSelect
                label={'상태'}
                name={'eventState'}
                options={CC.EVENT_STTUS.__list__ as selectOptions[]}
                readonly
                code
              ></CSelect>
            </CFormSetItem>
            <CFormSetItem grid={1}>
              <CTextField
                label={'발생일시'}
                name={'eventTime'}
                readonly
              />
            </CFormSetItem>
            <CFormSetItem grid={1}>
              <CTextField
                label={'해지일시'}
                name={'clearanceTime'}
                raw={(realtimeAlarmStore?.selectedAlarm?.clearanceTime) 
                    ? realtimeAlarmStore.selectedAlarm.clearanceTime  + (realtimeAlarmStore.selectedAlarm.clearanceAccount?.userNm ? ' (' + realtimeAlarmStore.selectedAlarm.clearanceAccount?.userNm + ')'  : '') 
                    : ''
                  }
                readonly
              />
            </CFormSetItem>
            <CFormSetItem grid={1}>
              <CTextField
                label={'인지일시'}
                name={'acknowledgedTime'}
                raw={(realtimeAlarmStore?.selectedAlarm?.acknowledgedTime) 
                    ? realtimeAlarmStore.selectedAlarm.acknowledgedTime  + (realtimeAlarmStore.selectedAlarm.acknowledgedAccount?.userNm ? ' (' + realtimeAlarmStore.selectedAlarm.acknowledgedAccount?.userNm + ')'  : '') 
                    : ''
                  }
                readonly
              />
            </CFormSetItem>
          </CFormSet>

          <CFormSet column={2}>
            <CFormSetItem>
              <CSelect
                label={'유형'}
                name={'eventType'}
                options={CC.EVENT_TYPE.__list__ as selectOptions[]}
                readonly
                code
              ></CSelect>
            </CFormSetItem>
            <CFormSetItem>
              <CTextField label={'발생원인'} name={'probableCause'} readonly />
            </CFormSetItem>

            <CFormSetItem grid={2}>
              <CTextField label={'상세원인'} name={'specificProblem'} readonly />
            </CFormSetItem>

            <CFormSetItem grid={2}>
              <CTextField
                label={'추가 메시지'}
                name={'additionalText'}
                readonly
                multiline
                multilineHeight={70}
                variant={'outlined'}
                color={'info'}
              />
            </CFormSetItem>

            <CFormSetItem grid={2}>
              <CTextField
                label={'추가 정보'}
                name={'additionalInformation'}
                readonly
                multiline
                multilineHeight={70}
                variant={'outlined'}
                color={'info'}
              />
            </CFormSetItem>

            <CFormSetItem>
              <CTextField
                label={'알람 ID'}
                name={'eventId'}
                readonly
              />
            </CFormSetItem>
            <CFormSetItem>
              <CTextField
                label={'외부 알람 ID'}
                name={'externalEventId'}
                readonly
              />
            </CFormSetItem>

          </CFormSet>
        </Card>


        <Card sx={{ p: 1.5, m: 1.5 }}>
          <CFormSet column={2} subTitle={'운영자 노트'}>
            {operatorNote && operatorNote?.noteList && (

              <CFormSetItem grid={2}>
                <TableContainer
                  sx={{
                    maxHeight: 'calc(100vh - 0)',
                    mt: 1.5,
                    ml: 1,
                    pb: 1,
                    borderBottom: '1px dotted #111',
                  }}
                >
                  <Table stickyHeader aria-labelledby="tableTitle" size={'medium'}>
                    <CTableHead
                      numSelected={0}
                      order={order}
                      orderBy={orderBy}
                      onSelectAllClick={() => { }}
                      onRequestSort={handleRequestSort}
                      rowCount={operatorNote?.noteList?.length}
                      headCells={headCells}
                      removeCheckBox={false}
                    />
                    <TableBody>
                      {operatorNote?.noteList && operatorNote?.noteList.map((row: any, i: number) => {
                        try {
                          // const item = JSON.parse(row.replace(/\n/gi, '\\n'));
                          const labelId = `enhanced-table-checkbox-${i}`;
                          return (
                            <TableRow
                              // hover
                              tabIndex={-1}
                              key={'operatorNote-' + i}
                              id={labelId}
                              sx={{ borderBottom: '1px dotted #EEE' }}
                            >
                              <TableCell align="left" sx={{ p: '16px', width: '150px' }}>
                                {row?.userNm}
                              </TableCell>
                              <TableCell align="left" sx={{ p: '16px', width: '50%', whiteSpace: 'pre-wrap' }}>
                                {row?.note}
                              </TableCell>
                              <TableCell align="center" sx={{ p: '4px', width: '150px' }}>
                                {row?.regDt}
                              </TableCell>
                            </TableRow>
                          );
                        } catch (e) { }
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </CFormSetItem>
            )}

            <CFormSetItem grid={1}>
              <CSelect
                label={'구분'}
                name={'oprtrNoteCtg'}
                options={CC.OPRTR_NOTE_CTG.__list__ as selectOptions[]}
                help={false}
                code
              ></CSelect>
            </CFormSetItem>
            <CFormSetItem grid={1}>
              <CTextField
                label={'알람 원인'}
                name={'noteProblCause'}
                help={false}
              ></CTextField>
            </CFormSetItem>

            <CFormSetItem grid={2}>
              <CTextField variant={'outlined'} color={'info'} label={'내용'} name={'oprtrNote'} multiline />
            </CFormSetItem>
          </CFormSet>
          <CButtonSet justifyContent={'right'} sx={{ mr: 1.5 }}>
            <CButtonSetItem color={'secondary'} type={'submit'}>{BUTTON_LABEL.SAVE}</CButtonSetItem>
          </CButtonSet>
        </Card>
      </Box>
      <CAlert
        key={'realtime-alarm'}
        isAlertOpen={isValidAlertOpen}
        alertCategory="warning"
        alertContent={validAlertMsg}
        handleAlertClose={handleValidAlertClose}
      />
    </FormProvider>

    // </>
  );
});

export default AiAlarmsDetailBasic;