import { ApiResponse } from 'apisauce';
import { Api } from '../api/api';
import { getGeneralApiProblem } from '../api/api-problem';
import { IResponseSnapshot } from './../../models/response/Response';
import { IVendorSnapshot } from './../../models/vendor/Vendor';
import { TGetVendorsResult } from './VendorTypes';

/**
 * # Vendor Api 서비스
 *
 * 서비스의 설명을 작성하세요.
 *
 * ## 사용방법
 *
 * ```ts
 * const service = new VendorApi();
 *
 * ```
 */
export class VendorApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  /**
   * INF_CAIMS_00216
   * 현재 저장된 벤더사의 아이디와 업체명을 조회한다.
   * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=2079446246
   * @param vendorType
   */
  async getVendors(): Promise<TGetVendorsResult> {
    try {
      const url: string = `/vendor`;
      const response: ApiResponse<any> = await this.api.apisauce.get(url);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;
      if (responseInfo.resultCode === 'S') {
        return {
          kind: `ok`,
          responseInfo: responseInfo,
          vendors: response.data.data as IVendorSnapshot[],
        };
      }

      return {
        kind: `ok`,
        responseInfo: responseInfo,
      };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  /**
   * INF_CAIMS_00239
   * 현재 저장된 제조사의 아이디와 업체명을 조회한다.
   * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=965284125
   */
  async getMakers(): Promise<TGetVendorsResult> {
    try {
      const url: string = `/maker`;
      const response: ApiResponse<any> = await this.api.apisauce.get(url);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;
      if (responseInfo.resultCode === 'S') {
        return {
          kind: `ok`,
          responseInfo: responseInfo,
          vendors: response.data.data as IVendorSnapshot[],
        };
      }

      return {
        kind: `ok`,
        responseInfo: responseInfo,
      };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }
}
