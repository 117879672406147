import { createSdSwHistoryContent } from './../sd-sw-history-content/SdSwHistoryContent';
import { createTrcodHistoryContent } from './../trcod-history-content/TrcodHistoryContent';
import { createOutputSwHistoryContent } from './../output-sw-history-content/OutputSwHistoryContent';
import { createSdMuxHistoryContent } from './../sd-mux-history-content/SdMuxHistoryContent';
import { createHdMuxHistoryContent } from './../hd-mux-history-content/HdMuxHistoryContent';
import { createAcoHistoryContent } from './../aco-history-content/AcoHistoryContent';
import { createInputSwHistoryContent } from './../input-sw-history-content/InputSwHistoryContent';
import { createEncodHistoryContent } from './../encod-history-content/EncodHistoryContent';
import { createPpCompnayHistoryContent } from './../pp-compnay-history-content/PpCompnayHistoryContent';
import { createAdvmHistoryContent } from './../advm-history-content/AdvmHistoryContent';
import { createChangeValue } from './../change-value/ChangeValue';
import { Instance, SnapshotOut, types } from 'mobx-state-tree';

/**
 * # ChannelHistoryContent
 *
 * ChannelHistoryContent을 설명하세요.
 */
export const ChannelHistoryContent = types
  .model('ChannelHistoryContent')
  // --------------------------------------------------------------------------
  .props({
    chSvcId: types.maybeNull(createChangeValue()),
    chNo: types.maybeNull(createChangeValue()),
    chNm: types.maybeNull(createChangeValue()),
    dbsChNm: types.maybeNull(createChangeValue()),
    chType: types.maybeNull(createChangeValue()),
    trtResol: types.maybeNull(createChangeValue()),
    araIntrlkYn: types.maybeNull(createChangeValue()),
    sadInfo: types.maybeNull(createChangeValue()),
    advmMemo: types.maybeNull(createChangeValue()),
    advm: types.maybeNull(createAdvmHistoryContent()),
    hdMip: types.maybeNull(createChangeValue()),
    sdMip: types.maybeNull(createChangeValue()),
    // clearMip: types.maybeNull(createChangeValue()),
    seeznIntrlkYn: types.maybeNull(createChangeValue()),
    hcnIntrlkYn: types.maybeNull(createChangeValue()),
    skylifeIntrlkYn: types.maybeNull(createChangeValue()),
    adultYn: types.maybeNull(createChangeValue()),
    psiIn: types.maybeNull(createChangeValue()),
    pat: types.maybeNull(createChangeValue()),
    pmt: types.maybeNull(createChangeValue()),
    videoPid: types.maybeNull(createChangeValue()),
    audio1Pid: types.maybeNull(createChangeValue()),
    audio2Pid: types.maybeNull(createChangeValue()),
    acapAit: types.maybeNull(createChangeValue()),
    webAit: types.maybeNull(createChangeValue()),
    acapOc: types.maybeNull(createChangeValue()),
    webOc: types.maybeNull(createChangeValue()),
    videoStchYn: types.maybeNull(createChangeValue()),
    dpsonSubtitYn: types.maybeNull(createChangeValue()),
    vcMultiYn: types.maybeNull(createChangeValue()),
    dplxYn: types.maybeNull(createChangeValue()),
    casEcodYn: types.maybeNull(createChangeValue()),
    ecmPid: types.maybeNull(createChangeValue()),
    pp: types.maybeNull(createPpCompnayHistoryContent()),
    encodHdMip: types.maybeNull(createChangeValue()),
    encodSdMip: types.maybeNull(createChangeValue()),
    encodCcYn: types.maybeNull(createChangeValue()),
    encodQtoneAPid: types.maybeNull(createChangeValue()),
    encodQtoneBPid: types.maybeNull(createChangeValue()),
    encodQtoneDPid: types.maybeNull(createChangeValue()),
    encodPmtPid: types.maybeNull(createChangeValue()),
    encodVideoPid: types.maybeNull(createChangeValue()),
    encodAudioPid: types.maybeNull(createChangeValue()),
    encodAudio2Pid: types.maybeNull(createChangeValue()),
    helthySttus: types.maybeNull(createChangeValue()),
    rglsSttus: types.maybeNull(createChangeValue()),
    rglsComotDate: types.maybeNull(createChangeValue()),
    encodList: types.maybeNull(types.array(createEncodHistoryContent())),
    inputSwList: types.maybeNull(types.array(createInputSwHistoryContent())),
    acoList: types.maybeNull(types.array(createAcoHistoryContent())),
    hdMuxList: types.maybeNull(types.array(createHdMuxHistoryContent())),
    sdMuxList: types.maybeNull(types.array(createSdMuxHistoryContent())),
    outputSwList: types.maybeNull(types.array(createOutputSwHistoryContent())),
    trcodList: types.maybeNull(types.array(createTrcodHistoryContent())),
    sdSwList: types.maybeNull(types.array(createSdSwHistoryContent())),
  })
  // --------------------------------------------------------------------------
  // MUTATEs ONLY! - 모델 상태를 변경
  // REQUESTs는 스토어 모델에 작성하세요.
  // ex) yarn gen model channelHistoryContent --store
  .actions((self) => ({
    /*
     * 여러 prop을 한번에 수정
     */
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as IChannelHistoryContent;
      (Object.keys(newProps) as TChannelHistoryContentKeys[]).forEach((key) => {
        //@ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type TChannelHistoryContent = Instance<typeof ChannelHistoryContent>;
type TChannelHistoryContentSnapshot = SnapshotOut<typeof ChannelHistoryContent>;

export interface IChannelHistoryContent extends TChannelHistoryContent {}
export type TChannelHistoryContentKeys = keyof TChannelHistoryContentSnapshot & string;
export interface IChannelHistoryContentSnapshot extends TChannelHistoryContentSnapshot {}
export const createChannelHistoryContent = () =>
  types.optional(ChannelHistoryContent, {} as TChannelHistoryContent);
