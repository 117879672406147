import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { createModelCode } from '../model-code/ModelCode';
import { createSimpleUser } from '../simple-user/SimpleUser';
import { createSopFile } from '../sop-file/SopFile';
import {createSopEventType} from "../sop-event-type/SopEventType";

export const Sop = types
  .model('Sop')
  .props({
    sopSid: types.optional(types.number, 0),
    sopNm: types.optional(types.maybeNull(types.string), ''),
    equipType: types.maybeNull(createModelCode()),
    trtSchm: types.optional(types.maybeNull(types.string), ''),
    delYn: types.optional(types.maybeNull(types.boolean), false),
    sopFileList: types.optional(types.array(createSopFile()), []),
    tkcgr: types.optional(types.maybeNull(types.string), ''),
    regDt: types.optional(types.string, ''),
    regUser: types.maybeNull(createSimpleUser()),
    updDt: types.optional(types.string, ''),
    updUser: types.maybeNull(createSimpleUser()),
  })
  .actions((self) => ({
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as ISop;
      (Object.keys(newProps) as TSopKeys[]).forEach((key) => {
        //@ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type TSop = Instance<typeof Sop>;
type TSopSnapshot = SnapshotOut<typeof Sop>;

export interface ISop extends TSop {}
export type TSopKeys = keyof TSopSnapshot & string;
export interface ISopSnapshot extends TSopSnapshot {}
export const createSop = () =>
  types.optional(Sop, {
    sopSid: 0,
    sopNm: '',
    equipType: null,
    trtSchm: '',
    tkcgr: '',
    delYn: false,
    regDt: '',
    regUser: null,
    updDt: '',
    updUser: null,
  } as TSop);

export interface ISopFilter {
  sopSid?: number;
  sopNm?: string;
  sopType?: number;
  sopUnderType?: number;
}

export type TSopFilter = ISopFilter;
