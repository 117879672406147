import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { IPpCompanySnapshot } from '..';
import { PpCompanyApi } from '../../services/pp-company/PpCompany';
import {
  TDeletePpCompanyResult,
  TDuplicatePpCompanyNmResult,
  TGetPpCompanysResult,
  TPostPpCompanyResult,
  TGetPpCompanyResult,
  TDeletesPpCompnayResult,
  TUpdatePpCompanyResult,
} from '../../services/pp-company/PpCompanyTypes';
import { timestampToDate } from '../../utils/dateExchanger';
import { withEnvironment } from '../extensions/with-environment';
import { createPagination, IPagination, IPaginationSnapshot } from '../pagination/Pagination';
import { createPpCompany, IPpCompany, PpCompany } from '../pp-company/PpCompany';
import { withRootStore } from './../extensions/with-root-store';
export const PpCompanyStore = types
  .model('PpCompanyStore')
  // --------------------------------------------------------------------------
  .props({
    isSuccess: types.optional(types.boolean, false),
    ppCompanys: types.optional(types.array(PpCompany), []),
    selectedPpCompany: createPpCompany(),
    selectedPpCompanyFM: types.optional(types.maybeNull(PpCompany), null),
    addPpCompany: createPpCompany(),
    updatePpCompany: createPpCompany(),
    pagination: createPagination(),
    ppNmOrLo: types.optional(types.string, ''),
    ppSidDelList: types.optional(types.array(types.number), []),
  })
  .extend(withEnvironment)
  .extend(withRootStore)
  .views((self) => ({}))
  .actions((self) => ({
    setSelectedPpCompany: (ppCompany: IPpCompanySnapshot) => {
      self.selectedPpCompany = ppCompany as IPpCompany;
    },
    setSelectedPpCompanyFM: (ppCompany: IPpCompanySnapshot | null) => {
      self.selectedPpCompanyFM = ppCompany as IPpCompany | null;
    },
    setPpCompanys: (ppCompanys: IPpCompanySnapshot[]) => {
      if (!ppCompanys) {
        return;
      }
      self.ppCompanys.replace(ppCompanys as IPpCompany[]);
    },
    setPagination: (pagination: IPaginationSnapshot) => {
      self.pagination = pagination as IPagination;
    },

    setPpLoOrNm: (ppNmOrLo?: string) => {
      if (ppNmOrLo != null && ppNmOrLo != undefined) {
        self.ppNmOrLo = ppNmOrLo as string;
      } else {
        self.ppNmOrLo = '';
      }
    },
    replcePpSidDelList: (ppSidDelList: number[]) => {
      self.ppSidDelList.replace(ppSidDelList);
    },
  }))
  .actions((self) => ({
    /**
     * INF_CAIMS_00224
     * PP사 목록을 조회한다.
     * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=1939453652
     * @param ppCompanyFilter
     * @returns
     */
    gets: async (ppCompanyFilter?: IPpCompanyFliter) => {
      self.setPpCompanys([]);
      try {
        const ppCompanyApi: PpCompanyApi = new PpCompanyApi(self.environment.api);
        let result: TGetPpCompanysResult;

        if (ppCompanyFilter) {
          result = await ppCompanyApi.gets(self.pagination, ppCompanyFilter);
        } else {
          result = await ppCompanyApi.gets(self.pagination);
        }

        if (
          self.rootStore.responseStore.getResponseResult(result) &&
          result.pagination &&
          result.pagination.page === self.pagination.page
        ) {
          if (!result.ppCompanys) {
            return;
          }

          if (result.ppCompanys.length > 0) {
            result.ppCompanys.forEach((item: IPpCompanySnapshot) => {
              if (item.regDt) {
                item.regDt = timestampToDate(Number.parseInt(item.regDt));
              }
              if (item.updDt) {
                item.updDt = timestampToDate(Number.parseInt(item.updDt));
              }
            });
          }

          self.setPpCompanys(result.ppCompanys);

          if (!result.pagination) {
            return;
          }

          self.setPagination(result.pagination);

          if (ppCompanyFilter?.ppNmOrLo) {
            self.setPpLoOrNm(ppCompanyFilter?.ppNmOrLo);
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },

    /**
     * INF_CAIMS_00224
     * PP사 목록을 조회한다.
     * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=1939453652
     * @param
     * @returns
     */
    modalGets: async () => {
      try {
        const ppCompanyApi: PpCompanyApi = new PpCompanyApi(self.environment.api);
        let result: TGetPpCompanysResult;

        result = await ppCompanyApi.gets(self.pagination, { ppNmOrLo: self.ppNmOrLo });

        if (
          self.rootStore.responseStore.getResponseResult(result) &&
          result.pagination &&
          result.pagination.page === self.pagination.page
        ) {
          if (!result.ppCompanys) {
            return;
          }

          if (result.ppCompanys.length > 0) {
            result.ppCompanys.forEach((item: IPpCompanySnapshot) => {
              if (item.regDt) {
                item.regDt = timestampToDate(Number.parseInt(item.regDt));
              }
              if (item.updDt) {
                item.updDt = timestampToDate(Number.parseInt(item.updDt));
              }
            });
          }

          self.setPpCompanys(result.ppCompanys);

          if (!result.pagination) {
            return;
          }

          self.setPagination(result.pagination);
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },

    /**
     * INF_CAIMS_00225
     * PP사를 등록한다.
     * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=1106095053
     * @param ppCompany
     */
    post: async (ppCompany: IPpCompany) => {
      try {
        const ppCompanyApi: PpCompanyApi = new PpCompanyApi(self.environment.api);
        const result: TPostPpCompanyResult = await ppCompanyApi.post(ppCompany);
        self.addPpCompany.resetPpCompany();
        if (self.rootStore.responseStore.getResponseResult(result)) {
          if (result.ppCompany) {
            if (result.ppCompany.regDt) {
              result.ppCompany.regDt = timestampToDate(Number.parseInt(result.ppCompany.regDt));
            }
            if (result.ppCompany.updDt) {
              result.ppCompany.updDt = timestampToDate(Number.parseInt(result.ppCompany.updDt));
            }
            self.setSelectedPpCompany(result.ppCompany);
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },

    /**
     * INF_CAIMS_00226
     * PP사를 수정한다.
     * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=392829932
     * @param PpCompany
     */
    put: async (PpCompany: IPpCompany) => {
      try {
        const ppCompanyApi: PpCompanyApi = new PpCompanyApi(self.environment.api);
        const result: TUpdatePpCompanyResult = await ppCompanyApi.put(PpCompany);

        self.updatePpCompany.resetPpCompany();
        if (self.rootStore.responseStore.getResponseResult(result)) {
          if (result.ppCompany) {
            if (result.ppCompany.regDt) {
              result.ppCompany.regDt = timestampToDate(Number.parseInt(result.ppCompany.regDt));
            }
            if (result.ppCompany.updDt) {
              result.ppCompany.updDt = timestampToDate(Number.parseInt(result.ppCompany.updDt));
            }
            self.setSelectedPpCompany(result.ppCompany);
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },

    /**
     * INF_CAIMS_00227
     * PP사를 삭제한다.
     * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=131297418
     * @param ppSid
     */
    delete: async (ppSid: number) => {
      try {
        const ppCompanyApi: PpCompanyApi = new PpCompanyApi(self.environment.api);
        const result: TDeletePpCompanyResult = await ppCompanyApi.delete(ppSid);
        self.rootStore.responseStore.getResponseResult(result);
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },

    /**
     * INF_CAIMS_00236
     * PP사의 이름을 중복체크한다.
     * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=708747388
     * @param ppSid
     * @param ppNm
     */
    checkDuplicatePpCompanyName: async (ppNm: string, ppSid?: number | null) => {
      try {
        const ppCompanyApi: PpCompanyApi = new PpCompanyApi(self.environment.api);
        const result: TDuplicatePpCompanyNmResult = await ppCompanyApi.checkDuplicatePpCompanyNm(
          ppNm,
          ppSid,
        );
        self.rootStore.responseStore.getResponseResult(result);
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },
    /**
     * INF_CAIMS_00237
     * PP사 목록 삭제
     * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=2045831926
     */
    deletes: async () => {
      try {
        const ppCompnayApi: PpCompanyApi = new PpCompanyApi(self.environment.api);
        const result: TDeletesPpCompnayResult = await ppCompnayApi.deletes(self.ppSidDelList);
        self.rootStore.responseStore.getResponseResult(result);
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },

    /**
     * INF_CAIMS_00238
     * 해당 PP사 상세조회
     * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=856365855
     * @param ppSid
     */
    get: async (ppSid: number) => {
      try {
        const ppCompanyApi: PpCompanyApi = new PpCompanyApi(self.environment.api);
        const result: TGetPpCompanyResult = await ppCompanyApi.get(ppSid);

        if (self.rootStore.responseStore.getResponseResult(result)) {
          if (result.ppCompnay) {
            self.setSelectedPpCompany(result.ppCompnay);
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },
  }));

type TPpCompanyStore = Instance<typeof PpCompanyStore>;
type TPpCompanyStoreSnapshot = SnapshotOut<typeof PpCompanyStore>;

export interface IPpCompanyStore extends TPpCompanyStore {}
export type TPpCompanyStoreKeys = keyof TPpCompanyStoreSnapshot & string;
export interface IPpCompanyStoreSnapshot extends TPpCompanyStoreSnapshot {}
export const createPpCompanyStore = () => types.optional(PpCompanyStore, {} as TPpCompanyStore);

export interface IPpCompanyFliter {
  ppNmOrLo?: string;
  ppSid?: string;
  ppNm?: string;
  ppLo?: string;
  ppMngr?: string;
}

export type TPpCompanyFliter = IPpCompanyFliter | IPpCompany;
