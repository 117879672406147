import { Instance, SnapshotOut, types } from 'mobx-state-tree';

export const ChartModel = types.model('Chart').props({
  label: types.maybeNull(types.string),
  value: types.maybeNull(types.number),
  orderIdx: types.maybeNull(types.number),
  name: types.maybeNull(types.string),
  bsCd: types.maybeNull(types.string),
  biobank: types.maybeNull(types.string),
  sex: types.maybeNull(types.number),
  dx: types.maybeNull(types.number),
  slabel: types.maybeNull(types.string),
  tlabel: types.maybeNull(types.string),
  pvalue: types.maybeNull(types.number),
});

type ChartType = Instance<typeof ChartModel>;
export interface Chart extends ChartType {}
type ChartSnapshotType = SnapshotOut<typeof ChartModel>;
export interface ChartSnapshot extends ChartSnapshotType {}
export const createChartDefaultModel = () =>
  types.optional(ChartModel, {
    label: '',
    value: 0,
    orderIdx: 0,
    name: '',
    bsCd: '',
    biobank: '',
    sex: 0,
    dx: 0,
    slabel: '',
    tlabel: '',
    pvalue: 0,
  });
