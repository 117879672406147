import { createSimpleUser } from './../simple-user/SimpleUser';
import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { createModelCode } from '../model-code/ModelCode';

export const Ruleset = types
  .model('Ruleset')
  .props({
    ruleSetSid: types.optional(types.number, 0),
    intrlkSysSid: types.optional(types.number, 0),
    ruleSetNm: types.optional(types.string, ''),
    equipType: types.maybeNull(createModelCode()),
    ruleSetType: types.maybeNull(createModelCode()),
    classNm: types.optional(types.maybeNull(types.string), ''),
    valExtrtClassNm: types.optional(types.maybeNull(types.string), ''),
    analsType: types.maybeNull(createModelCode()),
    analsRule: types.optional(types.maybeNull(types.string), ''),
    useYn: types.optional(types.boolean, false),
    delYn: types.optional(types.boolean, false),
    regDt: types.optional(types.string, ''),
    updDt: types.optional(types.string, ''),
    sysNm: types.optional(types.maybeNull(types.string), ''),
    mnfcSid: types.optional(types.number, 0),
    vendorSid: types.optional(types.number, 0),
    haType: createModelCode(),
    vip: types.optional(types.string, ''),
    mnfcNm: types.optional(types.string, ''),
    vendorNm: types.optional(types.string, ''),
    //구버전
    regId: types.optional(types.number, 0),
    updId: types.optional(types.number, 0),
    //신버전
    regUser: types.maybeNull(createSimpleUser()),
    updUser: types.maybeNull(createSimpleUser()),
    testString: types.optional(types.maybeNull(types.string), ''),
    extrtKeyList: types.optional(types.array(types.string), []),
  })
  .actions((self) => ({
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as IRuleset;
      (Object.keys(newProps) as TRulesetKeys[]).forEach((key) => {
        //@ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

type TRuleset = Instance<typeof Ruleset>;
type TRulesetSnapshot = SnapshotOut<typeof Ruleset>;

export interface IRuleset extends TRuleset {}
export type TRulesetKeys = keyof TRulesetSnapshot & string;
export interface IRulesetSnapshot extends TRulesetSnapshot {}
export const createRuleset = () =>
  types.optional(Ruleset, {
    ruleSetSid: 0,
    intrlkSysSid: 0,
    ruleSetNm: '',
    classNm: '',
    valExtrtClassNm: '',
    analsRule: '',
    useYn: false,
    delYn: false,
    regDt: '',
    regId: 0,
    updDt: '',
    updId: 0,
    sysNm: '',
    mnfcSid: 0,
    vendorSid: 0,
    vip: '',
    mnfcNm: '',
    vendorNm: '',
  } as TRuleset);

export interface IRulesetFilter {
  intrlkSysSid?: number;
  equipType?: number;
  ruleSetType?: number;
  analsType?: number;
  ruleSetNm?: string;
  useYn?: boolean;
  regDtSt?: number | string;
  regDtEd?: number | string;
  updDtSt?: number | string;
  updDtEd?: number | string;
}