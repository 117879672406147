import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { TrapVarSttus } from '../trap-var-sttus/TrapVarSttus';

export const Trap = types
  .model('Trap')
  .props({
    trapId: types.optional(types.maybeNull(types.string), ''),
    varOid: types.optional(types.maybeNull(types.string), ''),
    varNm: types.optional(types.maybeNull(types.string), ''),
    spcfProbOrdr: types.optional(types.maybeNull(types.number), 0),
    apdInfo: types.optional(types.maybeNull(types.string), ''),

    trapVarSttusList: types.optional(types.array(TrapVarSttus), []),
  })
  .actions((self) => ({
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as ITrap;
      (Object.keys(newProps) as TTrapKeys[]).forEach((key) => {
        //@ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

type TTrap = Instance<typeof Trap>;
type TTrapSnapshot = SnapshotOut<typeof Trap>;

export interface ITrap extends TTrap {}
export type TTrapKeys = keyof TTrapSnapshot & string;
export interface ITrapSnapshot extends TTrapSnapshot {}
export const createTrap = () =>
  types.optional(Trap, {
    trapId: '',
    varOid: '',
    varNm: '',
    spcfProbOrdr: 0,
    apdInfo: '',
  } as TTrap);
