import { ApiResponse } from 'apisauce';
import { IResponseSnapshot } from '../../models/';
import { TChannelHistoryFilter } from '../../models/channel-history-store/ChannelHistoryStore';
import { dateToTimestamp } from '../../utils/dateExchanger';
import { Api } from '../api/api';
import { getGeneralApiProblem } from '../api/api-problem';
import { IChannelHistorySnapshot } from './../../models/channel-history/ChannelHistory';
import { IPaginationSnapshot } from './../../models/pagination/Pagination';
import { timestampToDate } from './../../utils/dateExchanger';
import { TGetChannelHistoryResult, TGetChannelHistorysResult } from './ChannelHistoryTypes';

export class ChannelHistoryApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  /**
   * INF_CAIMS_00210
   * 채널 변경 이력 전체 목록을 조회한다.
   * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=1734640291
   * @param pagination
   * @param channelHistoryFilter
   * @returns
   */
  async gets(
    pagination: IPaginationSnapshot,
    channelHistoryFilter?: TChannelHistoryFilter,
  ): Promise<TGetChannelHistorysResult> {
    try {
      const url = '/channel/work/history';

      let params: any = {};

      if (channelHistoryFilter) {
        params = { ...createFilterParam(channelHistoryFilter) };
      }
      params.size = pagination.size;
      params.page = pagination.page;
      const response: ApiResponse<any> = await this.api.apisauce.get(url, params);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;

      if (responseInfo.resultCode === 'S') {
        return {
          kind: 'ok',
          responseInfo: responseInfo,
          channelHistorys: response.data.data,
          pagination: response.data.pagination,
        };
      }

      return { kind: 'ok', responseInfo: responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  /**
   * INF_CAIMS_00211
   * 해당 채널 변경이력의 상세이력를 조회한다.
   * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=1146358989
   * @param chChgWrkUid
   * @returns
   */
  async get(chChgWrkUid: string, chSvcId: number): Promise<TGetChannelHistoryResult> {
    try {
      const url = `/channel/work/history/${chChgWrkUid}/chSvcId/${chSvcId}`;
      const response: ApiResponse<any> = await this.api.apisauce.get(url);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;

      if (responseInfo.resultCode === 'S') {
        if (response.data.data) {
          response.data.data.regDt = timestampToDate(response.data.data.regDt);
          response.data.data.updDt = timestampToDate(response.data.data.updDt);
          const channelHistory: IChannelHistorySnapshot = response.data.data;

          return { kind: 'ok', responseInfo: responseInfo, channelHistory: channelHistory };
        }
      }

      return { kind: 'ok', responseInfo: responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  /**
   * 해당 채널 변경이력의 파일을 다운로드 받는다.
   * @param chChgWrkUid
   */
  async downloadFile(chChgWrkUid: string): Promise<TGetChannelHistoryResult> {
    const url = `/channel/work/history/${chChgWrkUid}/download/file`;
    return this.api.download(url, `channel-lineup(${chChgWrkUid})xlsx`);
  }
}

const createFilterParam = (channelHistoryFilter: TChannelHistoryFilter) => {
  let sendData: any = {};

  if (channelHistoryFilter.chSvcId) {
    sendData.chSvcId = channelHistoryFilter.chSvcId;
  }

  if (channelHistoryFilter.chWrkType) {
    sendData.chWrkType = channelHistoryFilter.chWrkType;
  }

  if (channelHistoryFilter.chWrkSttus) {
    sendData.exeSttus = channelHistoryFilter.chWrkSttus;
  }

  if (channelHistoryFilter.wrkrNm) {
    sendData.wrkrNm = channelHistoryFilter.wrkrNm;
  }

  if (channelHistoryFilter.wrkDtSt) {
    sendData.wrkDtSt = dateToTimestamp(channelHistoryFilter.wrkDtSt);
  }

  if (channelHistoryFilter.wrkDtEn) {
    sendData.wrkDtEn = dateToTimestamp(channelHistoryFilter.wrkDtEn);
  }

  return sendData;
};
