import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { createModelCode, IModelCodeSnapshot } from '../model-code/ModelCode';

export const TrapSvrtStng = types
  .model('TrapSvrtStng')
  .props({
    trapId: types.optional(types.string, ''),
    sttusVal: types.optional(types.string, ''),
    sttusValCode: createModelCode(),
    svrt: createModelCode(),
  })
  .actions((self) => ({
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as ITrapSvrtStng;
      (Object.keys(newProps) as TTrapSvrtStngKeys[]).forEach((key) => {
        //@ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

type TTrapSvrtStng = Instance<typeof TrapSvrtStng>;
type TTrapSvrtStngSnapshot = SnapshotOut<typeof TrapSvrtStng>;

export interface ITrapSvrtStng extends TTrapSvrtStng {}
export type TTrapSvrtStngKeys = keyof TTrapSvrtStngSnapshot & string;
export interface ITrapSvrtStngSnapshot extends TTrapSvrtStngSnapshot {}
export const createTrapSvrtStng = () =>
  types.optional(TrapSvrtStng, {
    trapId: '',
    sttusVal: '',
  } as TTrapSvrtStng);
