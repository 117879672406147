//
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
// material
import {Alert, Button, Card, IconButton, Input, InputAdornment, Stack} from '@mui/material';
import {useEffect, useRef, useState} from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import CTextField from '../../components/forms/CTextField';
import { FormProvider, RHFCheckbox, RHFTextField } from '../../components/hook-form';
// hooks
import { translate } from '../../i18n';
import { useStores } from '../../models';
import CDialog from "../../components/CDialog";
import {OtpModal} from "./otp-modal";
import {DIALOG_TITLE} from "../../components/settings/SettingConstEnum";
import {useNavigate} from "react-router-dom";
import CAlert from "../../components/CAlert";
import Login from "./login-screen";
// routes

// ----------------------------------------------------------------------
type InitialValues = {
  id: string;
  password: string;
  remember: boolean;
  afterSubmit?: string;
  loginCheck?: boolean;
  user_id: string;
  user_pwd: string;
};
export function LoginFormComponent() {
  const { authStore } = useStores();
  const [showPassword, setShowPassword] = useState(false);
  const [serverSwitch, setServerSwitch] = useState(false);
  const id = translate('registerScreen.id')!;
  const password = translate('registerScreen.password')!;
  const [isOtpOpen, setIsOtpOpen] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isAlertOpen, setIsAlertOpen] = useState<boolean>(false);

  const LoginSchema = Yup.object().shape({
    id: Yup.string().required(translate('loginScreen.validation.requireID')!),
    password: Yup.string().required(translate('loginScreen.validation.requirePassword')!),
  });

  const defaultValues = {
    id: '',
    password: '',
    remember: false,
  };

  const methods = useForm<InitialValues>({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    formState: { isSubmitting },
    getValues,
  } = methods;

  const setIsAlertClose = () => setIsAlertOpen(false);
  const setOtpClose = () => {
    if(sessionStorage.getItem("sec"))
    {
      sessionStorage.removeItem("sec");
    }
    if(sessionStorage.getItem("min"))
    {
      sessionStorage.removeItem("min");
    }
    if(sessionStorage.getItem("loginTime"))
    {
      sessionStorage.removeItem("loginTime");
    }
    if(sessionStorage.getItem("login"))
    {
      sessionStorage.removeItem("login");
    }
    setIsOtpOpen(false);
  }

  const [userId, setUserId] = useState('');
  const [userPw, setUserPw] = useState('');
  const setId = () => {
    setUserId(getValues('user_id') || '');
    setUserPw(getValues('user_pwd') || '');
  };

  const loginSucccess = async () => {
    await authStore.getLoginUserInfo();
  }

  const login = async () =>{
    if(localStorage.getItem("root"))
    {
      localStorage.removeItem("root");
    }
    sessionStorage.setItem("login","true");
    await authStore.loginProcess(userId,userPw,'p1').then(() => {
      const loginResponse = authStore.loginResponse;
      if(loginResponse)
      {
        if(loginResponse.loginResult === "SUCCESS")
        {
          if(loginResponse.isOtp)
          {
            const isLogin = sessionStorage.getItem("login");
            if(isLogin)
            {
              sessionStorage.setItem("loginTime", new Date().getTime().toString());
            }
            setIsOtpOpen(true)
          }
          else
          {
            if(sessionStorage.getItem("sec"))
            {
              sessionStorage.removeItem("sec");
            }
            if(sessionStorage.getItem("min"))
            {
              sessionStorage.removeItem("min");
            }
            if(sessionStorage.getItem("loginTime"))
            {
              sessionStorage.removeItem("loginTime");
            }
            if(sessionStorage.getItem("login"))
            {
              sessionStorage.removeItem("login");
            }
            loginSucccess();
          }
        }
        else
        {
          if(!sessionStorage.getItem("loginTime"))
          {
            sessionStorage.removeItem("login");
          }
          const message = loginResponse.errorMessage;
          setErrorMessage(message ? message : '로그인에 실패하였습니다. 관리자에게 문의 바랍니다.');
          setIsAlertOpen(true);
        }
      }
    });
  }

  return (
      // 로그인 action FormProvider 안에 있음.
      <>
        <FormProvider methods={methods}>
          {!serverSwitch && (
              <>
                <Input type="hidden" name="userId" value={userId} sx={{display: 'none'}}/>
                <Input type="hidden" name="userPwd" value={userPw} sx={{display: 'none'}}/>
                <Input type="hidden" name="type" value="p1" sx={{display: 'none'}}/>
              </>
          )}
          <Stack spacing={3}>
            <CTextField
                id={'user_id'}
                label={id}
                name={'user_id'}
                onChangeCallback={setId}
                onEnterCallback={login}
            />
            <CTextField
                id={'user_pwd'}
                label={password}
                name={'user_pwd'}
                type={showPassword ? '' : 'password'}
                onChangeCallback={setId}
                onEnterCallback={login}
            />

            {/* <Stack direction="row" alignItems="left" justifyContent="left" sx={{my: 2}}>
              <RHFCheckbox
                  name="remember"
                  color="secondary"
                  sx={{'.Mui-checked': {color: '#3366FF !important'}}}
                  label={translate('loginScreen.remember')!}
              />
            </Stack> */}

            <LoadingButton
                fullWidth
                size="large"
                type="button"
                variant="contained"
                color="secondary"
                loading={isSubmitting}
                onClick={login}
            >
              {translate('loginScreen.login')!}
            </LoadingButton>
          </Stack>
        </FormProvider>

        <CDialog
            isDialogOpen={isOtpOpen}
            dialogTitle={DIALOG_TITLE.login.otp.title}
            handleDialogClose={setOtpClose}
            maxWidth={'xm'}
            height={'auto'}
            minHeight={'10%'}
            isNotAnimate={true}
            hiddenModalBottom={true}
        >
          <OtpModal></OtpModal>
        </CDialog>
        <CAlert
            key={'Login-Error'}
            isAlertOpen={isAlertOpen}
            alertCategory={"error"}
            alertContent={errorMessage}
            alertTitle={'로그인 에러'}
            handleAlertClose={setIsAlertClose}
            hasCancelButton={false}
        />
      </>
  );
}
