import { Instance, SnapshotOut, types } from 'mobx-state-tree';

export const ModelCode = types
  .model('ModelCode')
  .props({
    code: types.optional(types.maybeNull(types.number), 0),
    pcode: types.optional(types.maybeNull(types.number), 0),
    value: types.optional(types.maybeNull(types.string), ''),
  })
  .actions((self) => ({
    resetModelCode: () => {
      self.code = 0;
      self.pcode = 0;
      self.value = '';
    },
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as IModelCode;
      (Object.keys(newProps) as TModelCodeKeys[]).forEach((key) => {
        //@ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

type TModelCode = Instance<typeof ModelCode>;
type TModelCodeSnapshot = SnapshotOut<typeof ModelCode>;

export interface IModelCode extends TModelCode {}
export type TModelCodeKeys = keyof TModelCodeSnapshot & string;
export interface IModelCodeSnapshot extends TModelCodeSnapshot {}
export const createModelCode = () =>
  types.optional(ModelCode, {
    code: null,
    pcode: null,
    value: '',
  } as TModelCode);
