import { ApiResponse } from 'apisauce';
import { Api } from '../api/api';
import { getGeneralApiProblem } from '../api/api-problem';
import {
  GetBankDeleteResult,
  GetBankDuplicateResult,
  GetBankInsertResult,
  GetBankListResult,
  GetBankUpdatedResult,
} from '../../services';
import { BankSnapshot } from '../../models';

export class BankApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  /**
   * 현재 페이지, 페이지 당 가져올 은행정보의 개수, 은행명으로 은행 데이터를 조회하는 API
   * @param currentPage
   * @param count
   * @param bankName
   * @returns
   */
  async getBankList(
    currentPage: number,
    count: number,
    bankName: string,
  ): Promise<GetBankListResult> {
    try {
      const SUB_URL = '/bankManagement/selectBankList';

      const sendData = {
        bankName: bankName,
        currentPage: currentPage,
        count: count,
      };

      const response: ApiResponse<any> = await this.api.apisauce.get(SUB_URL, sendData);
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      return {
        kind: 'ok',
        resultList: response.data.responseData,
      };
    } catch (e) {
      return { kind: 'notOk' };
    }
  }

  /**
   * 은행 정보 업데이트 API 이다.
   * 서버에서 FormData 형태로 받기 때문에 FormData를 사용하였다.
   * @param updateItem
   * @returns
   */
  async updateBank(updateItem: BankSnapshot): Promise<GetBankUpdatedResult> {
    try {
      const SUB_URL = '/bankManagement/updateBank';
      const formData = new FormData();
      formData.append('bankSeq', updateItem.bankSeq);
      if (updateItem.bankName) {
        formData.append('bankName', updateItem.bankName);
      }
      if (updateItem.bankCd) {
        formData.append('bankCd', updateItem.bankCd);
      }

      const response: ApiResponse<any> = await this.api.apisauce.put(SUB_URL, formData);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      return {
        kind: 'ok',
        result: response.data.responseData.resultCode === 'OK' ? true : false,
      };
    } catch (e) {
      return { kind: 'notOk' };
    }
  }

  /**
   * 은행의 고유 IDX로 은행 정보를 삭제하는 API
   * 서버에서 FormData 형태로 받기 때문에 FormData를 사용하였다.
   * @param deleteList
   * @returns
   */
  async deleteBank(deleteList: number[]): Promise<GetBankDeleteResult> {
    try {
      const SUB_URL = '/bankManagement/deleteBank';
      const formData = new FormData();
      formData.append('deleteIdList', deleteList.toString());

      const response: ApiResponse<any> = await this.api.apisauce.put(SUB_URL, formData);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      return {
        kind: 'ok',
        result: response.data.responseData.resultCode === 'OK' ? true : false,
      };
    } catch (e) {
      return { kind: 'notOk' };
    }
  }

  /**
   * 은행 정보를 생성하는 API
   * 서버에서 FormData 형태로 받기 때문에 FormData를 사용하였다.
   * @param insertItem
   * @returns
   */
  async insertBank(insertItem: BankSnapshot): Promise<GetBankInsertResult> {
    try {
      const SUB_URL = '/bankManagement/insertBank';
      const formData = new FormData();
      if (insertItem.bankName) {
        formData.append('bankName', insertItem.bankName);
      }
      if (insertItem.bankCd) {
        formData.append('bankCd', insertItem.bankCd);
      }

      const response: ApiResponse<any> = await this.api.apisauce.post(SUB_URL, formData);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      return {
        kind: 'ok',
        result: response.data.responseData.resultCode === 'OK' ? true : false,
      };
    } catch (e) {
      return { kind: 'notOk' };
    }
  }

  /**
   * 은행 정보 중 은행 코드를 중복검사하는 API
   * @param checkBankCode
   * @returns
   */
  async bankDuplicateCheck(checkBankCode: string): Promise<GetBankDuplicateResult> {
    try {
      const SUB_URL = '/bankManagement/isDuplicatedBankCd?bankCd=' + checkBankCode;

      const response: ApiResponse<any> = await this.api.apisauce.get(SUB_URL);
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }
      return {
        kind: 'ok',
        result: response.data.responseData.resultCode === 'FAIL' ? true : false,
      };
    } catch (e) {
      return { kind: 'notOk' };
    }
  }
}
