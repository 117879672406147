import { Instance, SnapshotOut, types } from 'mobx-state-tree';

import {
  EventInterlockStatus,
  IEventInterlockStatus,
  IEventInterlockStatusSnapshot,
} from '../event-interlock-status/EventInterlockStatus';
import { BatchHistory, IBatchHistory, IBatchHistorySnapshot } from '../batch-history/BatchHistory';
import { withEnvironment } from '../extensions/with-environment';
import { withRootStore } from '../extensions/with-root-store';
import { SystemStatusDashboardApi } from '../../services/system-status-dashboard/SystemStatusDashboard';
import { TGetSystemStatusDashboardResult } from '../../services/system-status-dashboard/SystemStatusDashboardTypes';
import { timestampToDate } from '../../utils/dateExchanger';

export const SystemStatusDashboardStore = types
  .model('SystemStatusDashboardStore')
  .props({
    eventInterlockStatusList: types.optional(types.array(EventInterlockStatus), []),
    tbBatchHistoryList: types.optional(types.array(BatchHistory), []),
    batchInterlockList: types.optional(types.array(BatchHistory), []),
  })
  .extend(withEnvironment)
  .extend(withRootStore)
  .views((self) => ({}))
  .actions((self) => ({
    setEventInterlockStatusList: (eventInterlockStatusList: IEventInterlockStatusSnapshot[]) => {
      // self.eventInterlockStatusList.clear();
      if (!eventInterlockStatusList) {
        return;
      }
      self.eventInterlockStatusList.replace(eventInterlockStatusList as IEventInterlockStatus[]);
    },
    setTbBatchHistoryList: (tbBatchHistoryList: IBatchHistorySnapshot[]) => {
      // self.eventInterlockStatusList.clear();
      if (!tbBatchHistoryList) {
        return;
      }
      self.tbBatchHistoryList.replace(tbBatchHistoryList as IBatchHistory[]);
    },
    setBatchInterlockList: (batchInterlockList: IBatchHistorySnapshot[]) => {
      // self.eventInterlockStatusList.clear();
      if (!batchInterlockList) {
        return;
      }
      self.batchInterlockList.replace(batchInterlockList as IBatchHistory[]);
    },
  }))
  .actions((self) => ({
    gets: async () => {
      try {
        const systemStatusDashboardApi: SystemStatusDashboardApi = new SystemStatusDashboardApi(
          self.environment.api,
        );

        const result: TGetSystemStatusDashboardResult = await systemStatusDashboardApi.gets();

        if (self.rootStore.responseStore.getResponseResult(result)) {
          // self.eventInterlockStatusList.resetEquipment();
          if (result.data) {
            if (result.data.eventInterlockStatusList && result.data.eventInterlockStatusList.length > 0) {
              result.data.eventInterlockStatusList.forEach(
                (item: IEventInterlockStatusSnapshot) => {
                  if (item.regDt) {
                    item.regDt = timestampToDate(Number.parseInt(item.regDt));
                  }
                  if (item.updDt) {
                    item.updDt = timestampToDate(Number.parseInt(item.updDt));
                  }
                  if (item.eventDt) {
                    item.eventDt = timestampToDate(Number.parseInt(item.eventDt));
                  }
                },
              );
              self.setEventInterlockStatusList(result.data.eventInterlockStatusList);
            }else{
              self.setEventInterlockStatusList([])
            }

            if (result.data.tbBatchHistoryList && result.data.tbBatchHistoryList.length > 0) {
              result.data.tbBatchHistoryList.forEach((item: IBatchHistorySnapshot) => {
                if (item.regDt) {
                  item.regDt = timestampToDate(Number.parseInt(item.regDt));
                }
                if (item.updDt) {
                  item.updDt = timestampToDate(Number.parseInt(item.updDt));
                }
                if (item.startDt) {
                  item.startDt = timestampToDate(Number.parseInt(item.startDt));
                }
                if (item.endDt) {
                  item.endDt = timestampToDate(Number.parseInt(item.endDt));
                }
              });
              self.setTbBatchHistoryList(result.data.tbBatchHistoryList);
            }else{
              self.setTbBatchHistoryList([])
            }

            if (result.data.batchInterlockList && result.data.batchInterlockList.length > 0) {
              result.data.batchInterlockList.forEach((item: IBatchHistorySnapshot) => {
                if (item.regDt) {
                  item.regDt = timestampToDate(Number.parseInt(item.regDt));
                }
                if (item.updDt) {
                  item.updDt = timestampToDate(Number.parseInt(item.updDt));
                }
                if (item.startDt) {
                  item.startDt = timestampToDate(Number.parseInt(item.startDt));
                }
                if (item.endDt) {
                  item.endDt = timestampToDate(Number.parseInt(item.endDt));
                }
              });             
              self.setBatchInterlockList(result.data.batchInterlockList);
            }else{
              self.setBatchInterlockList([])
            }
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },
  }));

type TSystemStatusDashboardStore = Instance<typeof SystemStatusDashboardStore>;
type TSystemStatusDashboardStoreSnapshot = SnapshotOut<typeof SystemStatusDashboardStore>;

export interface ISystemStatusDashboardStore extends TSystemStatusDashboardStore {}
export type TSystemStatusDashboardStoreKeys = keyof TSystemStatusDashboardStore & string;
export interface ISystemStatusDashboardStoreSnapshot extends TSystemStatusDashboardStoreSnapshot {}
