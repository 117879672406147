import { createModelCode } from './../model-code/ModelCode';
import { Instance, SnapshotOut, types } from 'mobx-state-tree';

/**
 * # RulesetMappgCond
 *
 * RulesetMappgCond을 설명하세요.
 */
export const RulesetMappgCond = types
  .model('RulesetMappgCond')
  //   ruleSetSid	룰셋SID	Number
  // ruleSetMappgSid	룰셋매핑Sid	Number
  // condSid	조건SID	Number
  // extrtKey	추출키	String
  // typeCond	조건	Code
  // comprVal	비교값	String
  // --------------------------------------------------------------------------
  .props({
    ruleSetSid: types.optional(types.maybeNull(types.number), null),
    ruleSetMappgSid: types.optional(types.maybeNull(types.number), null),
    condSid: types.optional(types.maybeNull(types.number), null),
    extrtKey: types.optional(types.maybeNull(types.string), null),
    typeCond: types.maybeNull(createModelCode()),
    comprVal: types.optional(types.maybeNull(types.string), null),
  })
  // --------------------------------------------------------------------------
  // MUTATEs ONLY! - 모델 상태를 변경
  // REQUESTs는 스토어 모델에 작성하세요.
  // ex) yarn gen model rulesetMappgCond --store
  .actions((self) => ({
    /*
     * 여러 prop을 한번에 수정
     */
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as IRulesetMappgCond;
      (Object.keys(newProps) as TRulesetMappgCondKeys[]).forEach((key) => {
        //@ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type TRulesetMappgCond = Instance<typeof RulesetMappgCond>;
type TRulesetMappgCondSnapshot = SnapshotOut<typeof RulesetMappgCond>;

export interface IRulesetMappgCond extends TRulesetMappgCond {}
export type TRulesetMappgCondKeys = keyof TRulesetMappgCondSnapshot & string;
export interface IRulesetMappgCondSnapshot extends TRulesetMappgCondSnapshot {}
export const createRulesetMappgCond = () =>
  types.optional(RulesetMappgCond, {
    ruleSetSid: null,
    ruleSetMappgSid: null,
    condSid: null,
    extrtKey: null,
    typeCond: null,
    comprVal: null,
  } as TRulesetMappgCond);
