import { HeadCell } from '../../../components/CTableHead';

export const headCells: readonly HeadCell[] = [
  {
    id: 'user_id',
    numeric: false,
    disablePadding: true,
    label: '운영자 ID',
    align: 'left',
  },
  {
    id: 'note',
    numeric: false,
    disablePadding: true,
    label: '노트',
    align: 'left',
  },
  {
    id: 'reg_date',
    numeric: false,
    disablePadding: true,
    label: '등록일',
    align: 'center',
  },
];
