import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { createSimpleUser } from './../simple-user/SimpleUser';

/**
 * # UsrgrpMenu
 *
 * UsrgrpMenu을 설명하세요.
 */
export const UsrgrpMenu = types
  .model('UsrgrpMenu')
  // --------------------------------------------------------------------------
  .props({
    // menuSId	메뉴ID	Number
    // menuNm	메뉴명	String
    // menuUri	메뉴URI	String
    // menuPsid	메뉴부모일련ID	Number
    // menuOrder	메뉴순서	Number
    // dscrpt	설명	String
    // useYn	사용여부	Boolean
    // regDt	등록일시	Number
    // regId	등록ID	Number
    // updDt	수정일시	Number
    // updId	수정ID	Number
    // usrgrpSid	사용자 그룹 일련 ID	Number
    menuSid: types.optional(types.maybeNull(types.number), 0),
    menuNm: types.optional(types.maybeNull(types.string), ''),
    menuUri: types.optional(types.maybeNull(types.string), ''),
    menuPsid: types.optional(types.maybeNull(types.number), 0),
    menuOrder: types.optional(types.maybeNull(types.number), 0),
    dscrpt: types.optional(types.maybeNull(types.string), ''),
    useYn: types.optional(types.maybeNull(types.boolean), false),
    regDt: types.optional(types.maybeNull(types.string), ''),
    regUser: types.maybeNull(createSimpleUser()),
    updDt: types.optional(types.maybeNull(types.string), ''),
    updId: types.maybeNull(createSimpleUser()),
    menuSidList: types.optional(types.maybeNull(types.array(types.number)), []),
    usrgrpSid: types.optional(types.maybeNull(types.number), 0),
  })
  // --------------------------------------------------------------------------
  // MUTATEs ONLY! - 모델 상태를 변경
  // REQUESTs는 스토어 모델에 작성하세요.
  // ex) yarn gen model usrgrpMenu --store
  .actions((self) => ({
    /*
     * 여러 prop을 한번에 수정
     */
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as IUsrgrpMenu;
      (Object.keys(newProps) as TUsrgrpMenuKeys[]).forEach((key) => {
        //@ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type TUsrgrpMenu = Instance<typeof UsrgrpMenu>;
type TUsrgrpMenuSnapshot = SnapshotOut<typeof UsrgrpMenu>;

export interface IUsrgrpMenu extends TUsrgrpMenu {}
export type TUsrgrpMenuKeys = keyof TUsrgrpMenuSnapshot & string;
export interface IUsrgrpMenuSnapshot extends TUsrgrpMenuSnapshot {}
export const createUsrgrpMenu = () =>
  types.optional(UsrgrpMenu, {
    menuSid: 0,
    menuNm: '',
    menuUri: '',
    menuPsid: 0,
    menuOrder: 0,
    dscrpt: '',
    useYn: false,
    regDt: '',
    updDt: '',
    menuSidList: [] as number[],
    usrgrpSid: 0,
  } as TUsrgrpMenu);
