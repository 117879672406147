import { TextField } from '@mui/material';
import { observer } from 'mobx-react-lite';
import CFormSetItem from './CFormSetItem';
import { FORMSET_ITEM_LABEL } from './settings/SettingConstEnum';
import { FormStyle } from './settings/SettingStyle';

interface RegUpdInfoFormProps {
  regUser?: string | null;
  regDt?: string | null;
  updUser?: string | null;
  updDt?: string | null;
  isEquip?: boolean | null;
}

/**
 * ## 기본 사용법
 *
 * > RegUpdInfoForm 컴포넌트입니다.
 * > 여러 개의 입력 폼 세트가 필요한 경우 코드를 복사하여 새로운 컴포넌트를 생성합니다.
 * >
 * > 컴포넌트 네이밍은 해당 서비스명 뒤에 "FormSet"을 붙여서 사용합니다
 * > 예) VOCFormSet, AlarmFormSet
 *>
 * > ### Common Style
 * > * **formSetLabelStyle** : Form의 Label에 사용합니다.
 * > * **searchRowStyle**: tr 태그에 사용합니다.
 *
 */
const RegUpdInfoForm = observer(function RegUpdInfoFormProps({
  regUser,
  regDt,
  updUser,
  updDt,
  isEquip,
  ...other
}: RegUpdInfoFormProps) {
  return (
    <>
      {regDt && (
        <CFormSetItem>
          <TextField
            label={FORMSET_ITEM_LABEL.common.reg}
            variant={'standard'}
            sx={FormStyle}
            size="small"
            defaultValue={ regUser ? regUser + ' (' + regDt + ')' : regDt }
            inputProps={ { readOnly: true, style: { overflow: 'auto' } } }
          ></TextField>
        </CFormSetItem>
      )}
      {updDt && (
        <CFormSetItem>
          <TextField
            label={isEquip ? "최종 업데이트 시간" : FORMSET_ITEM_LABEL.common.upd}
            variant={'standard'}
            sx={FormStyle}
            size="small"
            defaultValue={updUser ? updUser + ' (' + updDt + ')' : updDt}
            inputProps={{ readOnly: true, style: { overflow: 'auto' } }}
          ></TextField>
        </CFormSetItem>
      )}
    </>
  );
});

export default RegUpdInfoForm;
