import { Card, Container, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { observer } from 'mobx-react-lite';
import { useSnackbar } from 'notistack';
import { useEffect, useRef, useState } from 'react';
import { Page } from '../../components/Page';
import { useStores } from '../../models';
import { LoginFormComponent } from '../login/login-form';
import {ALERT_MSG, DIALOG_TITLE} from "../../components/settings/SettingConstEnum";
import CAlert from "../../components/CAlert";
import { useNavigate } from "react-router-dom";
import { ReactComponent as LogoSVG } from '../../assets/images/logo.svg';
import {OtpModal} from "./otp-modal";
import CDialog from "../../components/CDialog";
import Notice from '../../layouts/dashboard/Notice';


const LoginStyle = styled(Page)(({ theme }) => ({
  position: 'absolute',
  width: '100%',
  top: 0,
  height: '100%',
}));

// ----------------------------------------------------------------------

const LoginScreen = observer(() => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const navigate = useNavigate();

  const [loginOk, setLoginOk] = useState<{
    ok: 'warning' | 'success' | 'error';
    msg: string;
  }>({
    ok: 'warning',
    msg: '',
  });

  useEffect(() => {
    if (!window.localStorage.getItem('remember')) {
      window.localStorage.setItem('remember', '' + false);
    }
    videoRef.current?.play();
  }, []);

  return (

    <Page title="Login Screen | Dr.GENIE">
      <Notice />
      <video
        ref={videoRef}
        autoPlay={true}
        loop={true}
        muted
        controls={false}
        style={{
          objectFit: 'cover',
          width: '100vw',
          height: '100vh',
          position: 'fixed',
          top: 0,
          left: 0,
        }}
      >
        <source src={'/videos/loginVideo.mp4'} type="video/mp4" />
      </video>
      <LoginStyle>
        <Container sx={{ mt: '14vh', mb: '20vh', height: '60vh', overflow: 'visible', pt: '5vh', textAlign: 'center' }}>
          <LogoSVG height={'6vh'} style={{ maxWidth: '260px' }} fill={'#FFF'} />
          {/* <img
            src="/image/logo.svg"
            style={{ height: '6vh', margin: 'auto', maxWidth: '260px' }}
          /> */}
          <Card
            sx={{
              p: '4vh',
              m: 'auto',
              mt: '20px',
              width: '25vw',
              minWidth: '400px',
              maxWidth: '900px',
              backgroundColor: 'rgba(0,0,0,0.7)',
              'input.MuiInput-input:-internal-autofill-selected': { transitionDelay: '999999s' },
            }}
          >
            <LoginFormComponent />
          </Card>
          <Typography sx={{ paddingTop: 1, textAlign: 'center' }}  variant={'body1'}>이 사이트는 Google Chrome에 최적화되어 있습니다.</Typography>
          <Typography sx={{ paddingTop: 1, textAlign: 'center' }} variant={'body1'}>KT IDMS 계정으로 로그인 하세요.</Typography>
        </Container>
      </LoginStyle>
    </Page>
  );
});

export default LoginScreen;
