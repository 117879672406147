import { ISnmpAuthInfoSnapshot } from './../../models/snmp-auth-info/SnmpAuthInfo';
import { ApiResponse } from 'apisauce';
import { IPaginationSnapshot } from '../../models';
import { IConnectionFilter } from './../../models/connection/Connection';
import { TConnectionStoreKeys } from '../../models/connection-store/ConnectionStore';
import { Api } from '../api/api';
import { getGeneralApiProblem } from '../api/api-problem';
import { IAuthInfoSnapshot } from './../../models/auth-info/AuthInfo';
import { IConnectionSnapshot } from './../../models/connection/Connection';
import { IResponseSnapshot } from './../../models/response/Response';
import { timestampToDate, dateToTimestamp } from './../../utils/dateExchanger';
import {
  TDeleteConnectionResult,
  TGetConnectionResult,
  TGetConnectionsResult,
  TPostConnectionResult,
  TUpdateConnectionResult,
  TGetTestResult,
} from './ConnectionTypes';

/**
 * # Connection Api 서비스
 *
 * 서비스의 설명을 작성하세요.
 *
 * ## 사용방법
 *
 * ```ts
 * const service = new ConnectionApi();
 *
 * ```
 */
export class ConnectionApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  /**
   * INF_CAIMS_00136
   * 접속정보 전체 목록을 조회한다.
   * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=1257320080
   * @returns
   */
  async gets(
    pagination: IPaginationSnapshot,
    connectionFilter?: IConnectionFilter,
  ): Promise<TGetConnectionsResult> {
    try {
      const url: string = '/connection';
      interface ISendData extends IConnectionFilter {
        page: number;
        size: number;
      }

      const sendData: ISendData = {
        page: pagination.page,
        size: pagination.size,
      };

      if (connectionFilter) {
        connectionFilter = deleteAttribute(connectionFilter);
        if (connectionFilter.regDtSt) {
          connectionFilter.regDtSt = dateToTimestamp(connectionFilter.regDtSt);
        }
        if (connectionFilter.regDtEd) {
          connectionFilter.regDtEd = dateToTimestamp(connectionFilter.regDtEd);
        }
      }

      let params: ISendData = { ...sendData, ...connectionFilter };

      const response: ApiResponse<any> = await this.api.apisauce.get(url, params);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;
      if (responseInfo.resultCode === 'S') {
        if (response.data.data) {
          const pagination: IPaginationSnapshot = response.data.pagination;
          response.data.data.forEach((item: any) => {
            item.regDt = timestampToDate(Number.parseInt(item.regDt));
            item.updDt = timestampToDate(Number.parseInt(item.updDt));
          });
          const connections: IConnectionSnapshot[] = response.data.data;
          return {
            kind: `ok`,
            responseInfo: responseInfo,
            connections: connections,
            pagination: pagination,
          };
        }
      }

      return { kind: 'ok', responseInfo: responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  /**
   * INF_CAIMS_00137
   * 해당 접속정보의 상세 정보를 조회한다.
   * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=547545887
   * @param connSid
   * @returns
   */
  async get(connSid: number): Promise<TGetConnectionResult> {
    try {
      const url = `/connection/${connSid}`;
      const response: ApiResponse<any> = await this.api.apisauce.get(url);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;
      if (responseInfo.resultCode === 'S') {
        if (response.data.data) {
          response.data.data.regDt = timestampToDate(response.data.data.regDt);
          response.data.data.updDt = timestampToDate(response.data.data.updDt);
          const connection: IConnectionSnapshot = response.data.data;
          return { kind: `ok`, responseInfo: responseInfo, connection: connection };
        }
      }

      return { kind: 'ok', responseInfo: responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  /**
   * INF_CAIMS_00138
   * 새로운 접속정보을 등록한다.
   * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=1395265010
   * @returns
   */
  async post(connection: IConnectionSnapshot): Promise<TPostConnectionResult> {
    try {
      const url: string = '/connection';

      const param = createSendData(connection);
      const response: ApiResponse<any> = await this.api.apisauce.post(url, param);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;
      if (responseInfo.resultCode === 'S') {
        if (response.data.data) {
          response.data.data.regDt = timestampToDate(response.data.data.regDt);
          response.data.data.updDt = timestampToDate(response.data.data.updDt);
          const connection: IConnectionSnapshot = response.data.data;
          return { kind: `ok`, responseInfo: responseInfo, connection: connection };
        }
      }

      return { kind: 'ok', responseInfo: responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  /**
   *INF_CAIMS_00139
   *해당 접속정보 정보를 수정한다.
   *https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=2066720546
   * @param connSid
   * @returns
   */
  async put(connection: IConnectionSnapshot): Promise<TUpdateConnectionResult> {
    try {
      const url: string = `/connection/${connection.connSid}`;
      const response: ApiResponse<any> = await this.api.apisauce.put(
        url,
        createSendData(connection),
      );

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;
      if (responseInfo.resultCode === 'S') {
        if (response.data.data) {
          response.data.data.regDt = timestampToDate(response.data.data.regDt);
          response.data.data.updDt = timestampToDate(response.data.data.updDt);
          const connection: IConnectionSnapshot = response.data.data;
          return { kind: `ok`, responseInfo: responseInfo, connection: connection };
        }
      }

      return { kind: 'ok', responseInfo: responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  /**
   * INF_CAIMS_00140
   * 해당 접속정보을 삭제한다.
   * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=1183123755
   * @param connSid
   * @returns
   */
  async delete(connSid: number): Promise<TDeleteConnectionResult> {
    try {
      const url: string = `/connection/${connSid}`;
      const response: ApiResponse<any> = await this.api.apisauce.delete(url);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;
      return { kind: `ok`, responseInfo: responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  /**
   * INF_CAIMS_00141
   * 여러개 접속정보을 한번에 삭제한다.
   * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=1442592928
   * @param connSids
   * @returns
   */
  async deletes(connSids: number[]): Promise<TDeleteConnectionResult> {
    try {
      let param = ``;
      connSids.forEach((item: number, index: number) => {
        param += `connSidList=${item}`;
        if (index !== connSids.length - 1) {
          param += `&`;
        }
      });

      const url: string = `/connection?${param}`;
      const response: ApiResponse<any> = await this.api.apisauce.delete(url);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;
      return { kind: `ok`, responseInfo: responseInfo };
    } catch (e) {
      return { kind: `bad-data` };
    }
  }

  /**
   * INF_CAIMS_00142
   * 해당 접속정보을 테스트한다.
   * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=1266383929
   * @param connSid
   * @returns
   */
  async test(connSid: number): Promise<TGetTestResult> {
    try {
      const url: string = `/connection/${connSid}/test`;
      const response: ApiResponse<any> = await this.api.apisauce.get(url);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;

      if (responseInfo.resultCode === 'S') {
        if (response.data.data.needMlsc && response.data.data.needMlsc != null) {
          const needMlsc: number = response.data.data.needMlsc;
          if (response.data.data.returnData && response.data.data.returnData != null) {
            const returnData: string = response.data.data.returnData;
            return {
              kind: `ok`,
              responseInfo: responseInfo,
              needMlsc: needMlsc,
              returnData: returnData,
            };
          }
          return { kind: `ok`, responseInfo: responseInfo, needMlsc: needMlsc };
        }
      }

      return { kind: `ok`, responseInfo: responseInfo };
    } catch (e) {
      return { kind: `bad-data` };
    }
  }

  /**
   * INF_CAIMS_00248
   * 데이터 생성 전에 접속정보 테스트를 한다.
   * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=640030227
   * @param connection
   * @returns
   */
  async testBeforeCreate(connection: IConnectionSnapshot): Promise<TGetTestResult> {
    try {
      const url: string = `/connection/test`;
      const param = createSendData(connection);
      const response: ApiResponse<any> = await this.api.apisauce.post(url, param);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;

      if (responseInfo.resultCode === 'S') {
        if (response.data.data.needMlsc && response.data.data.needMlsc != null) {
          const needMlsc: number = response.data.data.needMlsc;
          if (response.data.data.returnData && response.data.data.returnData != null) {
            const returnData: string = response.data.data.returnData;
            return {
              kind: `ok`,
              responseInfo: responseInfo,
              needMlsc: needMlsc,
              returnData: returnData,
            };
          }
          return { kind: `ok`, responseInfo: responseInfo, needMlsc: needMlsc };
        }
      }
      return { kind: `ok`, responseInfo: responseInfo };
    } catch (e) {
      return { kind: `bad-data` };
    }
  }
}

const deleteAttribute = (connectionFilter: IConnectionFilter) => {
  (Object.keys(connectionFilter) as TConnectionStoreKeys[]).forEach((key: string) => {
    //@ts-ignore
    if (!connectionFilter[key]) {
      //@ts-ignore
      delete connectionFilter[key];
    }
  });
  return connectionFilter;
};

const createSendData = (connection: IConnectionSnapshot) => {
  interface IAuthInfo {
    connSid?: number | null;
    authMethod?: number | null;
    authId?: string | null;
    authPwd?: string | null;
    psphrs?: string | null;
    prvtKey?: string | null;
    fingerprint?: string | null;
  }

  interface ISnmpAuthInfo {
    snmpAuthMethod?: number;
    cmnty?: string | null;
    securNm?: string | null;
    authPrtl?: number | null;
    authPwd?: string | null;
    privPrtl?: number | null;
    privPwd?: string | null;
  }
  interface ISendData {
    connNm?: string | null;
    prtl: number | null | undefined;
    urlPfix?: string | null;
    ipadr: string | null;
    port?: string | null;
    intrlkSysSrvrSid?: number | null;
    intrlkSysSrvrNm?: string | null;
    equipSid?: number | null;
    equipHostNm?: string | null;
    snmpAuthInfo?: ISnmpAuthInfo;
    httpAuthInfo?: IAuthInfo;
    cliAuthInfo?: IAuthInfo;
  }

  let sendData: ISendData = {
    prtl: connection.prtl?.code,
    ipadr: connection.ipadr,
    intrlkSysSrvrSid: connection.intrlkSysSrvrSid,
    intrlkSysSrvrNm: connection.intrlkSysSrvrNm,
    equipSid: connection.equipSid,
    equipHostNm: connection.equipHostNm,
  };

  if (connection.connNm !== undefined) {
    sendData.connNm = connection.connNm;
  }

  if (connection.urlPfix !== undefined) {
    sendData.urlPfix = connection.urlPfix;
  }

  if (connection.port !== undefined) {
    sendData.port = connection.port;
  }

  const createAuthInfo = (authInfo: IAuthInfoSnapshot) => {
    let sendAuthInfo: IAuthInfo = {};
    if (authInfo.authId !== undefined) {
      sendAuthInfo.authId = authInfo.authId;
    }

    if (authInfo.authMethod) {
      if (authInfo.authMethod.code) {
        sendAuthInfo.authMethod = authInfo.authMethod.code;
      }
    }

    if (authInfo.authPwd !== undefined) {
      sendAuthInfo.authPwd = authInfo.authPwd;
    }
    if (authInfo.connSid) {
      sendAuthInfo.connSid = authInfo.connSid;
    }

    if (authInfo.fingerprint !== undefined) {
      sendAuthInfo.fingerprint = authInfo.fingerprint;
    }

    if (authInfo.prvtKey !== undefined) {
      sendAuthInfo.prvtKey = authInfo.prvtKey;
    }

    if (authInfo.psphrs !== undefined) {
      sendAuthInfo.psphrs = authInfo.psphrs;
    }

    return sendAuthInfo;
  };

  const createSnmpAuthInfo = (authInfo: ISnmpAuthInfoSnapshot) => {
    let sendSnmpInfo: ISnmpAuthInfo = {};

    if (authInfo.authPrtl) {
      if (authInfo.authPrtl.code) {
        sendSnmpInfo.authPrtl = authInfo.authPrtl.code;
      }
    }

    if (authInfo.authPwd) {
      sendSnmpInfo.authPwd = authInfo.authPwd;
    }
    if (authInfo.cmnty !== undefined) {
      sendSnmpInfo.cmnty = authInfo.cmnty;
    }
    if (authInfo.privPrtl) {
      if (authInfo.privPrtl.code) {
        sendSnmpInfo.privPrtl = authInfo.privPrtl.code;
      }
    }

    if (authInfo.privPwd !== undefined) {
      sendSnmpInfo.privPwd = authInfo.privPwd;
    }

    if (authInfo.securNm !== undefined) {
      sendSnmpInfo.securNm = authInfo.securNm;
    }
    if (authInfo.snmpAuthMethod) {
      if (authInfo.snmpAuthMethod.code) {
        sendSnmpInfo.snmpAuthMethod = authInfo.snmpAuthMethod.code;
      }
    }

    return sendSnmpInfo;
  };

  if (connection.snmpAuthInfo) {
    sendData.snmpAuthInfo = createSnmpAuthInfo(connection.snmpAuthInfo);
  }

  if (connection.httpAuthInfo) {
    sendData.httpAuthInfo = createAuthInfo(connection.httpAuthInfo);
  }

  if (connection.cliAuthInfo) {
    sendData.cliAuthInfo = createAuthInfo(connection.cliAuthInfo);
  }
  return sendData;
};
