import { Instance, SnapshotOut, types } from "mobx-state-tree"
import {withRootStore} from "../extensions/with-root-store";
import { withEnvironment } from "../extensions/with-environment"; 
import { createPagination, IPagination, IPaginationSnapshot } from '../pagination/Pagination';
import { IQualityReport, IQualityReportSnapshot, QualityReport, createQualityReport } from "./QualityReport";
import { 
  QualityReportApi, 
  TGeneralApiResponse, 
  TGetQualityReportResult, 
  TGetQualityReportsResult 
} from "../../services"; 

export const QualityReportStore = types
  .model("QualityReportStore")
  // --------------------------------------------------------------------------
  .props({
    qualityReports : types.optional(types.array(QualityReport), []),
    pdfUri: types.optional(types.maybeNull(types.string), null),
    pagination: createPagination(),
  })
  .extend(withEnvironment)
  .extend(withRootStore)
  .views((self) => ({}))
  .actions((self) => ({
    setQualityReports: (qualityReports: IQualityReportSnapshot[]) => {
      if (!qualityReports) {
        return;
      }
      self.qualityReports.replace(qualityReports as IQualityReport[]);
    },
    setPagination: (pagination: IPaginationSnapshot) => {
      self.pagination = pagination as IPagination;
    },
    setPdfUri : (url: string) => {
      self.pdfUri = url
    }
  }))
  .actions((self) => ({
    
    gets: async (qualityReportFilter?: IQualityReportFilter) => {
      self.setQualityReports([]);
      try {
        const qualityReportApi: QualityReportApi = new QualityReportApi(self.environment.api);
        let result: TGetQualityReportsResult;

        if (qualityReportFilter) {
          result = await qualityReportApi.gets(self.pagination, qualityReportFilter);
        } else {
          result = await qualityReportApi.gets(self.pagination);
        }

        if (self.rootStore.responseStore.getResponseResult(result) &&
          result.pagination &&
          result.pagination.page === self.pagination.page
        ) {
          self.setQualityReports(result.qualityReports ? result.qualityReports : [] );
          self.setPagination(result.pagination);
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },
    getPdf: async (qualityReport: IQualityReportSnapshot) => {
      try {
        const qualityReportApi: QualityReportApi = new QualityReportApi(self.environment.api);
        
        const result: any = await qualityReportApi.getPdf(qualityReport);

        if (self.rootStore.responseStore.getResponseResult(result)) {
          let localUri = result.responseInfo.responseInfo;
          self.setPdfUri(localUri);
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },
    downloadExcel: async (qualityReport: IQualityReportSnapshot) => {
      const qualityReportApi: QualityReportApi = new QualityReportApi(self.environment.api);
      await qualityReportApi.downloadExcel(qualityReport);
    },
  }));

// --------------------------------------------------------------------------
type TQualityReportStore = Instance<typeof QualityReportStore>;
type TQualityReportStoreSnapshot = SnapshotOut<typeof QualityReportStore>

export interface IQualityReportStore extends TQualityReportStore {}
export type TQualityReportStoreKeys = keyof TQualityReportStoreSnapshot & string;
export interface IQualityReportStoreSnapshot extends TQualityReportStoreSnapshot {}
export const createQualityReportStore = () => types.optional(QualityReportStore, {} as TQualityReportStore);

export interface IQualityReportFilter{
  rprtCycl?: number,
  chkDtSt?: string;
  chkDtEd?: string;
}

export type TQualityReportFilter = IQualityReportFilter | IQualityReport;