import { Instance, SnapshotOut, types } from 'mobx-state-tree';

/**
 * # LinkPort
 *
 * LinkPort을 설명하세요.
 */
export const LinkPort = types
  .model('LinkPort')
  // --------------------------------------------------------------------------
  .props({
    topolEquipPortCnctSid: types.optional(types.number, NaN),
    topolId: types.optional(types.string, ''),
    topolLinkId: types.optional(types.string, ''),
    srcPortSid: types.optional(types.number, NaN),
    dstPortSid: types.optional(types.number, NaN),
    descr: types.optional(types.string, ''),
    srcHostNm: types.optional(types.string, ''),
    srcPortNm: types.optional(types.string, ''),
    dstHostNm: types.optional(types.string, ''),
    dstPortNm: types.optional(types.string, ''),
  })
  // --------------------------------------------------------------------------
  // MUTATEs ONLY! - 모델 상태를 변경
  // REQUESTs는 스토어 모델에 작성하세요.
  // ex) yarn gen model linkPort --store
  .actions((self) => ({
    /*
     * 여러 prop을 한번에 수정
     */
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as ILinkPort;
      (Object.keys(newProps) as TLinkPortKeys[]).forEach((key) => {
        //@ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type TLinkPort = Instance<typeof LinkPort>;
type TLinkPortSnapshot = SnapshotOut<typeof LinkPort>;

export interface ILinkPort extends TLinkPort {}
export type TLinkPortKeys = keyof TLinkPortSnapshot & string;
export interface ILinkPortSnapshot extends TLinkPortSnapshot {}
export const createLinkPort = () => types.optional(LinkPort, {} as TLinkPort);
