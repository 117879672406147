import { Instance, SnapshotOut, types } from "mobx-state-tree"
import { withEnvironment } from "../extensions/with-environment"; 
import { 
  createNotification,
  INoticeCloseInfo,
  INotification, 
  INotificationSnapshot, 
  NoticeCloseInfo, 
  Notification 
} from "../notification/Notification"; 
import { createPagination, IPagination, IPaginationSnapshot } from '../pagination/Pagination';
import { 
  NotificationApi, 
  TGetNotificationResult, 
  TGetNotificationsResult 
} from "../../services"; 
import { withRootStore } from "../extensions/with-root-store";

export const CLOSE_TODAY_SIDS = "closeTodayNotice";
export const NotificationStore = types
  .model("NotificationStore")
  // --------------------------------------------------------------------------
  .props({
    showNotices: types.optional(types.array(Notification), []),
    notifications : types.optional(types.array(Notification), []),
    notification : createNotification(),
    pagination: createPagination(),
  })
  .extend(withRootStore)
  .extend(withEnvironment)
  .views((self) => ({}))
  .actions((self) => ({
    /**
     * [로컬 스토리지] 오늘날짜 아닌 것 제거.
     */
    clenupCloseTodaySidStorageData: () => {
      const today = new Date();
      today.setHours(0,0,0,0);

      const localStr: string | null = window.localStorage.getItem(CLOSE_TODAY_SIDS);
      let data: INoticeCloseInfo[] = localStr != null ? JSON.parse(localStr) as INoticeCloseInfo[] : [];
      data = data.filter((item) => item.dateTimestamp != today.getTime());

      window.localStorage.setItem(CLOSE_TODAY_SIDS, JSON.stringify(data));
    },
    /**
     * [로컬 스토리지] 오늘 하루 안보기. 공지 아이디 추가.
     * @param ntfSid 
     */
    addCloseTodaySidStorageData: (ntfSid: number) => {
      const today = new Date();
      today.setHours(0,0,0,0);

      const localStr: string | null = window.localStorage.getItem(CLOSE_TODAY_SIDS);
      let data: INoticeCloseInfo[] = localStr != null ? JSON.parse(localStr) as INoticeCloseInfo[] : [];
      data.push({
        ntfSid: ntfSid,
        dateTimestamp: today.getTime(),
      } as INoticeCloseInfo);

      window.localStorage.setItem(CLOSE_TODAY_SIDS, JSON.stringify(data));
    },
    /**
     * [로컬 스토리지] 오늘 하루 안보기, 공지 찾기
     * @param ntfSid
     * @returns 
     */
    getCloseTodaySidStorageData: (ntfSid: number) => {
      const today = new Date();
      today.setHours(0,0,0,0);

      const localStr: string | null = window.localStorage.getItem(CLOSE_TODAY_SIDS);
      let data: INoticeCloseInfo[] = localStr != null ? JSON.parse(localStr) as INoticeCloseInfo[] : [];
      let thisData: INoticeCloseInfo | null = null;
      data.forEach((item) => {
        if(item.ntfSid === ntfSid && item.dateTimestamp === today.getTime()) {
          thisData = item;
          return;
        }
      });
      return thisData;
    },
  }))
  .actions((self) => ({
    
    filterCloseTodayNotice: () => {
      const notices: INotification[] = self.showNotices;
      return notices.filter((item) => {
        const closeInfo: INoticeCloseInfo | null = self.getCloseTodaySidStorageData(item.ntfSid);
        return !closeInfo;
      });
    },

    setShowNotices: (notifications?: INotificationSnapshot[]) => {
      if(notifications) {
        self.showNotices.replace(notifications as INotification[]);
      }
      else {
        self.showNotices.replace([]);        
      }
    },
    setNotifications: (notifications?: INotificationSnapshot[]) => {
      if(notifications) {
        self.notifications.replace(notifications as INotification[]);
      }
      else {
        self.notifications.replace([]);
      }
    },
    setNotification: (notification?: INotificationSnapshot | null) => {
      if(notification)
      {
        self.notification = notification as INotification;
      }
      else
      {
        self.notification = {} as INotification;
      }
    },
    setPagination: (pagination: IPaginationSnapshot) => {
      self.pagination = pagination as IPagination;
    },
  }))
  // --------------------------------------------------------------------------
  .actions((self) => ({
    /**
     * 노출시킬 공지 사항 조회
     */
    getShowNotices: async () => 
    {
      self.setShowNotices([]);

      try
      {
        const notificationApi : NotificationApi = new NotificationApi(self.environment.api);
        const result: TGetNotificationsResult = await notificationApi.getShows();
  
        if(self.rootStore.responseStore.getResponseResult(result)) 
        {
          self.setShowNotices(result?.notifications);
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },

    /**
     * 목록 조회
     * @param notificationFilter
     */
    getList: async (notificationFilter?: INotificationFilter) => 
    {
      self.setNotifications([]);

      try
      {
        const notificationApi : NotificationApi = new NotificationApi(self.environment.api);
        const result: TGetNotificationsResult = await notificationApi.getList(self.pagination, notificationFilter);
  
        if(self.rootStore.responseStore.getResponseResult(result)
          && result.pagination && result.pagination.page === self.pagination.page) 
        {
          self.setPagination(result.pagination);
          self.setNotifications(result?.notifications);
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },

    /**
     * 상세정보 조회
     * @param ntfSid 
     */
    getDetail: async (ntfSid: number) => {
      self.setNotification();
      try
      {
        const notificationApi : NotificationApi = new NotificationApi(self.environment.api);
        const result: TGetNotificationResult = await notificationApi.get(ntfSid);

        if(self.rootStore.responseStore.getResponseResult(result)
          && result.notification) 
        {
          self.setNotification(result.notification);
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },

    /**
     * 등록
     */
    regist: async (notification: INotification) => {
      try
      {
        const notificationApi : NotificationApi = new NotificationApi(self.environment.api);
        const result: TGetNotificationResult = await notificationApi.post(notification);

        if(self.rootStore.responseStore.getResponseResult(result)
          && result.notification) 
        {
          self.setNotification(result.notification);
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },

    /**
     * 수정
     * @param ntfSid 
     */
    modify: async (ntfSid: number, notification: INotification) => {
      try
      {
        const notificationApi : NotificationApi = new NotificationApi(self.environment.api);
        const result: TGetNotificationResult = await notificationApi.put(ntfSid, notification);

        if(self.rootStore.responseStore.getResponseResult(result)
          && result.notification) 
        {
          self.setNotification(result.notification);
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },

    /**
     * 삭제
     */
    remove: async (ntfSid: number) => {
      try
      {
        const notificationApi : NotificationApi = new NotificationApi(self.environment.api);
        const result: TGetNotificationResult = await notificationApi.delete(ntfSid);
        self.rootStore.responseStore.getResponseResult(result);
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },

    /**
     * 목록 삭제
     */
    removeList: async (ntfSidList: number[]) => {
      try
      {
        const notificationApi : NotificationApi = new NotificationApi(self.environment.api);
        const result: TGetNotificationResult = await notificationApi.deleteList(ntfSidList);
        self.rootStore.responseStore.getResponseResult(result);
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },

  }));

// --------------------------------------------------------------------------
type TNotificationStore = Instance<typeof NotificationStore>;
type TNotificationStoreSnapshot = SnapshotOut<typeof NotificationStore>

export interface INotificationStore extends TNotificationStore {}
export type TNotificationStoreKeys = keyof TNotificationStoreSnapshot & string;
export interface INotificationStoreSnapshot extends TNotificationStoreSnapshot {}
export const createNotificationStore = () => types.optional(NotificationStore, {} as TNotificationStore);


export interface INotificationFilter {
  showStartDt?: string;
  showEndDt?: string;
  isNotice: boolean;
  ntfTitle?: string;
}