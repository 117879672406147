import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { createModelCode } from '../model-code/ModelCode';

export const TrapVarSttus = types
  .model('TrapVarSttus')
  .props({
    varOid: types.optional(types.string, ''),
    varNm: types.optional(types.string, ''),
    sttusVal: types.optional(types.string, ''),
    sttusNm: types.optional(types.string, ''),
  })
  .actions((self) => ({
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as ITrapVarSttus;
      (Object.keys(newProps) as TTrapVarSttusKeys[]).forEach((key) => {
        //@ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

type TTrapVarSttus = Instance<typeof TrapVarSttus>;
type TTrapVarSttusSnapshot = SnapshotOut<typeof TrapVarSttus>;

export interface ITrapVarSttus extends TTrapVarSttus {}
export type TTrapVarSttusKeys = keyof TTrapVarSttusSnapshot & string;
export interface ITrapVarSttusSnapshot extends TTrapVarSttusSnapshot {}
export const createTrapVarSttus = () =>
  types.optional(TrapVarSttus, {
    varOid: '',
    varNm: '',
    sttusVal: '',
    sttusNm: '',
  } as TTrapVarSttus);
