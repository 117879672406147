import * as React from 'react';
import { IButtonColorMap } from '../CButtonSetItem';
import { BUTTON_LABEL } from './SettingConstEnum';

export const ButtonColorMap: IButtonColorMap = {
  inherit: [
    BUTTON_LABEL.CLOSE,
    BUTTON_LABEL.SEARCH,
    BUTTON_LABEL.ADD,
    BUTTON_LABEL.CANCLE,
    BUTTON_LABEL.ATTACH_FILE,
    BUTTON_LABEL.INSPECTION_HISTORY,
    BUTTON_LABEL.ALARM_DETAILS,
    BUTTON_LABEL.ABLE_LOGIN,
    BUTTON_LABEL.WAKEUP,
    BUTTON_LABEL.CHANNEL_HISTORY,
  ],
  error: [BUTTON_LABEL.DELETE, BUTTON_LABEL.CLEAR],
  primary: [
    BUTTON_LABEL.EQUIPMENT_PAIRINSERT,
    BUTTON_LABEL.CHANGE_PASSWORD,
    BUTTON_LABEL.INSERT,
    BUTTON_LABEL.ID_DUPLICATE_CHECK,
    BUTTON_LABEL.TEST,
    BUTTON_LABEL.TERMINATE,
    BUTTON_LABEL.LOGIN,
    BUTTON_LABEL.EQUIPMENT_SELECT,
    BUTTON_LABEL.EQUIPMENT_DETAILS,
    BUTTON_LABEL.SAVE,
    BUTTON_LABEL.UPDATE,
  ],
  info: [
    BUTTON_LABEL.CHANNEL_LINE_DOWNLOAD,
    BUTTON_LABEL.ACK,
    BUTTON_LABEL.CONFIRM,
    BUTTON_LABEL.KDB,
    BUTTON_LABEL.RPA_TEMPLATE_DETAILS,
    BUTTON_LABEL.RPA_TEMPLATE_SELECT,
    BUTTON_LABEL.ALARM_SELECT,
    BUTTON_LABEL.CONNECTION_DETAILS,
    BUTTON_LABEL.APPROVED,
    BUTTON_LABEL.PP_SELECT,
    BUTTON_LABEL.CHANNEL_LINE_TEMPLATE_DOWNLOAD,
    BUTTON_LABEL.INTERACTIVESYS_SELECT,
    BUTTON_LABEL.EQUIP_TEMPLATE_DOWNLOAD,
  ],
  secondary: [
    BUTTON_LABEL.EQUIPMENT_PAIRUPDATE,
    BUTTON_LABEL.EVENT_SELECT,
    BUTTON_LABEL.CHANNEL_LINE_REGISTER,
    BUTTON_LABEL.EQUIP_UPLOAD,
    BUTTON_LABEL.PURGE,
    BUTTON_LABEL.RUNNING_CONSOLE_CONFIRM,
    BUTTON_LABEL.DUPLICATE_CHECK,
  ],
  warning: [
    BUTTON_LABEL.STOP_RUNNING,
    BUTTON_LABEL.RE_RUNNING,
    BUTTON_LABEL.STOP_RUNNING,
    BUTTON_LABEL.REFUSAL,
  ],
};
