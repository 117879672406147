import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { Equipment } from '../equipment/Equipment';
import { createModelCode } from '../model-code/ModelCode';
import { createSimpleUser } from '../simple-user/SimpleUser';

/**
 * # EquipGroup
 *
 * EquipGroup을 설명하세요.
 */
export const EquipGroup = types
  .model('EquipGroup')
  // --------------------------------------------------------------------------
  .props({
    equipGroupSid: types.optional(types.number, 0),
    equipGroupNm: types.optional(types.string, ''),
    equipType: createModelCode(),
    equipList: types.optional(types.maybeNull(types.array(Equipment)), []),
    regUser: createSimpleUser(),
    updUser: createSimpleUser(),
    regDt: types.optional(types.number, NaN),
    updDt: types.optional(types.number, NaN),
    delYn: types.optional(types.boolean, false),
    descr: types.optional(types.maybeNull(types.string), ''),
  })
  // --------------------------------------------------------------------------
  // MUTATEs ONLY! - 모델 상태를 변경
  // REQUESTs는 스토어 모델에 작성하세요.
  // ex) yarn gen model equipGroup --store
  .actions((self) => ({
    /*
     * 여러 prop을 한번에 수정
     */
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as IEquipGroup;
      (Object.keys(newProps) as TEquipGroupKeys[]).forEach((key) => {
        //@ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type TEquipGroup = Instance<typeof EquipGroup>;
type TEquipGroupSnapshot = SnapshotOut<typeof EquipGroup>;

export interface IEquipGroup extends TEquipGroup {}
export type TEquipGroupKeys = keyof TEquipGroupSnapshot & string;
export interface IEquipGroupSnapshot extends TEquipGroupSnapshot {}
export const createEquipGroup = () => types.optional(EquipGroup, {} as TEquipGroup);
