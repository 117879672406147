import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useStores } from '../../../models';

const AlarmSound = observer(() => {
  const rootStore = useStores();
  const { realtimeAlarmStore, loadingStore } = rootStore;
  const audio = React.useRef<HTMLAudioElement>(null);

  const [severity, setSeverity] = React.useState('');

  useEffect(() => {
    realtimeAlarmStore.setAlarmSoundToPlay('');
  }, []);

  useEffect(() => {
    setSeverity(realtimeAlarmStore.alarmSoundToPlay);
  }, [realtimeAlarmStore.alarmSoundToPlay]);

  useEffect(() => {
    const handleEnded = () => {
      // Audio has finished playing, execute setAlarmSoundToPlay("")
      realtimeAlarmStore.setAlarmSoundToPlay('');
    };

    if (audio?.current) {
      audio.current.onended = handleEnded;
      try {
        audio?.current.play();
      } catch (e) {
        console.log('play err', e.message);
      }
    }
  }, [severity]);

  useEffect(() => {
    if (realtimeAlarmStore.alarmSoundMute) {
      realtimeAlarmStore.setAlarmSoundToPlay('');
    }
  }, [realtimeAlarmStore.alarmSoundMute]);

  return (
    <>
      <audio ref={audio} src={severity} autoPlay />
    </>
  );
});

export default AlarmSound;
