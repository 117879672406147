import { circularProgressClasses } from '@mui/material';
import { ApiResponse } from 'apisauce';
import {
  IPaginationSnapshot,
  IResponseSnapshot,
  IStbFilter,
  IStbSnapshot,
  TStbFilter,
} from '../../models';
import { Api } from '../api/api';
import { getGeneralApiProblem } from '../api/api-problem';
import {
  TCheckDuplicateSaid,
  TDeleteStbResult,
  TGetStbResult,
  TGetStbsResult,
  TPostStbResult,
  TUpdateStbResult,
} from './StbTypes';

/**
 * # Stb Api 서비스
 *
 * 서비스의 설명을 작성하세요.
 *
 * ## 사용방법
 *
 * ```ts
 * const service = new StbApi();
 *
 * ```
 */
export class StbApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  /**
   * INF_CAIMS_00150
   * 전체 모니터링 셋탑 목록을 조회한다.
   * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=515222615
   * @param pagination
   * @param stbFilter
   * @returns
   */
  async gets(pagination: IPaginationSnapshot, stbFilter?: TStbFilter): Promise<TGetStbsResult> {
    try {
      const url = '/stb';
      let params: any = {};
      if (stbFilter) {
        params = filterSendData(stbFilter);
      }
      params.size = pagination.size;
      params.page = pagination.page;
      const response: ApiResponse<any> = await this.api.apisauce.get(url, params);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;
      return {
        kind: 'ok',
        stbs: response.data.data,
        pagination: response.data.pagination,
        responseInfo: responseInfo,
      };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  /**
   * INF_CAIMS_00151
   * 해당 모니터링 셋탑의 상세정보를 조회한다.
   * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=246419288
   * @param said
   * @returns
   */
  async get(said: string): Promise<TGetStbResult> {
    try {
      const url = `/stb/${said}`;
      const response: ApiResponse<any> = await this.api.apisauce.get(url);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;

      if (responseInfo.resultCode === 'S') {
        return {
          kind: 'ok',
          stb: response.data.data,
          responseInfo: responseInfo,
        };
      }

      return {
        kind: 'ok',
        responseInfo: responseInfo,
      };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  /**
   * INF_CAIMS_00152
   * 해로운 모니터링 셋탑을 등록한다.
   * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=2120536867
   * @param stb
   * @returns
   */
  async post(stb: IStbSnapshot): Promise<TPostStbResult> {
    try {
      const url = '/stb';
      const params = createSendData(stb);
      const response: ApiResponse<any> = await this.api.apisauce.post(url, params);
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const resposneInfo: IResponseSnapshot = response.data;

      if (resposneInfo.resultCode === 'S') {
        if (response.data.data) {
          const stb: IStbSnapshot = response.data.data;
          return {
            kind: 'ok',
            stb: stb,
            responseInfo: resposneInfo,
          };
        }
      }

      return {
        kind: 'ok',
        responseInfo: resposneInfo,
      };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  /**
   * INF_CAIMS_00153
   * 해당 모니터링 셋탑 정보를 수정한다.
   * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=119626900
   * @param stb
   * @returns
   */
  async put(stb: IStbSnapshot): Promise<TUpdateStbResult> {
    try {
      const url = `/stb/${stb.said}`;
      const params = createSendData(stb);
      const response: ApiResponse<any> = await this.api.apisauce.put(url, params);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;
      if (responseInfo)
        if (response.data.data) {
          const stb: IStbSnapshot = response.data.data;
          return {
            kind: 'ok',
            responseInfo: responseInfo,
            stb: stb,
          };
        }

      return {
        kind: 'ok',
        responseInfo: responseInfo,
      };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  /**
   * INF_CAIMS_00154
   * 해당 모니터링 셋탑을 삭제한다.
   * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=349850719
   * @param id
   * @returns
   */
  async delete(id: string): Promise<TDeleteStbResult> {
    try {
      const url = `/stb/${id}`;
      const response: ApiResponse<any> = await this.api.apisauce.delete(url);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;

      return {
        kind: 'ok',
        responseInfo: responseInfo,
      };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  /**
   * INF_CAIMS_00155
   * 여러개 모니터링 셋탑을 한번에 삭제한다.
   * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=1108389545
   * @param deleteStbList
   * @returns
   */
  async deletes(deleteStbList: string[]): Promise<TDeleteStbResult> {
    try {
      let param: string = ``;
      deleteStbList.forEach((item: string, index: number) => {
        param += `saidDelList=${item}`;
        if (index !== deleteStbList.length - 1) {
          param += `&`;
        }
      });

      const url = `/stb?${param}`;

      const response: ApiResponse<any> = await this.api.apisauce.delete(url);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;

      return {
        kind: 'ok',
        responseInfo: responseInfo,
      };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  /**
   * INF_CAIMS_00244
   * 특정 모니터링STB 이름이 데이터베이스에 이미 존재하는지 확인한다.
   * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=87312653
   * @param said
   * @returns
   */
  async checkDuplicateSaid(said: string): Promise<TCheckDuplicateSaid> {
    try {
      const url = `/stb/nameCheck?said=${said}`;

      const response: ApiResponse<any> = await this.api.apisauce.get(url);
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;
      if (responseInfo.resultCode === 'S') {
        return {
          kind: 'ok',
          responseInfo: responseInfo,
          result: response.data.data.result,
        };
      }

      return {
        kind: 'ok',
        responseInfo: responseInfo,
      };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }
}

const filterSendData = (stbFilter: IStbFilter) => {
  let sendData: any = {};

  if (stbFilter.said) {
    sendData.said = stbFilter.said;
  }

  if (stbFilter.stbMo) {
    sendData.stbMo = stbFilter.stbMo;
  }

  if (stbFilter.helthySttus) {
    sendData.helthySttus = stbFilter.helthySttus.code;
  }

  if (stbFilter.ctg) {
    sendData.ctg = stbFilter.ctg.code;
  }

  if (stbFilter.lo) {
    sendData.lo = stbFilter.lo.code;
  }

  if(stbFilter.eventZapYn !== undefined){
    sendData.eventZapYn = stbFilter.eventZapYn
  }

  return sendData;
};

const createSendData = (stb: IStbSnapshot) => {
  let sendData: any = {};

  if (stb.said !== undefined) {
    sendData.said = stb.said;
  }

  if (stb.stbMo !== undefined) {
    sendData.stbMo = stb.stbMo;
  }

  if (stb.helthySttus) {
    sendData.helthySttus = stb.helthySttus.code;
  }

  if (stb.mac !== undefined) {
    sendData.mac = stb.mac;
  }

  if (stb.ip !== undefined) {
    sendData.ip = stb.ip;
  }

  if (stb.ctg) {
    sendData.ctg = stb.ctg.code;
  }

  if (stb.lo) {
    sendData.lo = stb.lo.code;
  }

  if (stb.nttp !== undefined) {
    sendData.nttp = stb.nttp;
  }

  if (stb.opnDt !== undefined) {
    sendData.opnDt = stb.opnDt;
  }

  if (stb.rmark !== undefined) {
    sendData.rmark = stb.rmark;
  }

  if(stb.eventZapYn !== undefined){
    sendData.eventZapYn = stb.eventZapYn
  }

  return sendData;
};
