import { Box, Card, Table, TableBody, TableCell, TableHead, TableRow, Grid, TextField, Typography, Stack } from '@mui/material';
import * as _ from 'lodash';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { IRealTimeAlarmSnapshot, useStores } from '../../../models';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import * as echarts from 'echarts/core';
import ReactECharts from 'echarts-for-react';
import {
  getDarkThemeJson,
  getDefaultOption,
  getDefaultTooltip,
  makeTooltipDataHtmlWithPre,
  TooltipDataHtmlProps,
} from '../../../utils/graphUtil';
import {
  GridComponentOption,
  LegendComponentOption,
  TooltipComponentOption,
  MarkLineComponentOption,
  BarSeriesOption,
  LineSeriesOption
} from 'echarts';
import moment from 'moment';
import { collectStoredAnnotations } from 'mobx/dist/internal';
import CFormSet from '../../../components/CFormSet';
import CFormSetItem from '../../../components/CFormSetItem';
import CTextField from '../../../components/forms/CTextField';
import CSelect from '../../../components/forms/CSelect';
import FormProvider from '../../../components/hook-form/FormProvider';
import { useForm } from 'react-hook-form';

type EChartsOption = echarts.ComposeOption<
  | TooltipComponentOption
  | GridComponentOption
  | LegendComponentOption
  | MarkLineComponentOption
  | BarSeriesOption
  | LineSeriesOption
>;

export const AiAlarmsDetailQuetone = observer(() => {

  const { enqueueSnackbar } = useSnackbar();

  const rootStore = useStores();

  const { realtimeAlarmStore, responseStore, loadingStore } = rootStore;

  const alarm: IRealTimeAlarmSnapshot = { ...realtimeAlarmStore.selectedAlarm };

  const temp = {
    "predValue": [0, 344524320, 0.22234432, 0, 744532432, 0, 144524332, 0, 94452432],
    "realValue": [0, 64452423320, 0.344234432, 0, 6544532432, 0, 144524332, 0, 94452432],
    "minValue": [0, 41564320, 0.31134432, 0, 565532432, 0, 144524332, 0, 94452432],
    "maxValue": [0, 88824320, 0.22234432, 0, 1123532432, 0, 144524332, 0, 3215452432],
    "times": ["2022-09-01 00:00:00", "2022-09-01 02:00:00", "2022-09-01 04:00:00", "2022-09-01 06:00:00", "2022-09-01 08:00:00", "2022-09-01 10:00:00", "2022-09-01 12:00:00", "2022-09-01 14:00:00", "2022-09-01 16:00:00"]
  }
  // console.log('alarm.additionalInformation', alarm.additionalInformation)
  // if (alarm.additionalInformation === '' || alarm.additionalInformation === null) {
  // alarm.additionalInformation = JSON.stringify(temp);
  // }

  const chartType = (alarm.specificProblem?.toLowerCase() === 'too many a quetone') ? 'count' : 'interval';

  let chartData = {
    predValue: [], realValue: [], minValue: [], maxValue: [], times: []
  }
  try {
    if(alarm.additionalInformation && alarm.additionalInformation !== ''){
      chartData = JSON.parse(alarm.additionalInformation)
    }
  } catch (e) { }

  let chartTitle, chartLegend;
  switch (chartType) {
    case 'count':
      chartTitle = 'A 큐톤 건수 이상탐지 (큐톤 count LSTM)';
      chartLegend = ['예상', '발생', '최소', '최대'];
      break;
    default:
      chartTitle = 'B큐톤 편성 감시';
      chartLegend = ['편성', '삽입'];
      break;
  }

  const option: EChartsOption = {
    title: {
      text: chartTitle
    },
    tooltip: {
      trigger: 'axis'
    },
    legend: {
      data: chartLegend
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true
    },
    toolbox: {
      feature: {
        saveAsImage: {}
      }
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: chartData.times.map((r: string) => moment(r).format('MM-DD HH:mm'))
    },
    yAxis: {
      type: 'value'
    },
    series: [],

    // graphic: (),
  };

  switch (chartType) {
    case 'count':
      option.series = [
        {
          name: '예상', type: 'line',
          lineStyle: {
            color: '#999999',
            width: 2,
            type: 'dashed'
          }, data: chartData.predValue
        },
        {
          name: '발생', type: 'line',
          symbol: 'dot',
          symbolSize: 4,
          lineStyle: {
            color: '#FFFFFF',
            width: 2,
            type: 'solid'
          }, data: chartData.realValue
        },
        {
          name: '최소', type: 'line', data: chartData.minValue,
          areaStyle: {
            color: '#333333'
          },
        },
        {
          name: '최대', type: 'line', data: chartData.maxValue, areaStyle: {
            color: '#333333'
          },
        },
      ]
      break;
    case 'interval':
      option.series = [
        {
          name: '예상', type: 'line',
          lineStyle: {
            color: '#999999',
            width: 2,
            type: 'dashed'
          }, data: chartData.predValue
        },
        {
          name: '발생', type: 'line',
          symbol: 'dot',
          symbolSize: 4,
          lineStyle: {
            color: '#FFFFFF',
            width: 2,
            type: 'solid'
          }, data: chartData.realValue
        },
      ];
      break;
  }
  let noData: boolean = true;
  option.series.map((s) => {
    if (s.data && s.data.length < 1) {
      noData = false;
    }
  });
  if (!noData) {
    option.graphic = {
      type: 'text',
      left: 'center',
      top: 'middle',
      style: { text: '데이터가 없습니다.', fontSize: 18, fill: '#999999' }
    }
  }

  const methods = useForm<IRealTimeAlarmSnapshot>({ defaultValues: realtimeAlarmStore.selectedAlarm as IRealTimeAlarmSnapshot });

  const {
    control,
  } = methods;

  useEffect(() => {
    // const chartOption = getDefaultOption(title, xAxisData, series, makeTooltip(), noData);
    // setOptions(chartOption as EChartsOption);
  }, []);

  /**
   * 알람 발생 일시, 해지일시
알람 상태
알람 메시지
[채널SID, 채널 명, 채널번호]
   */
  return (
    <FormProvider methods={methods}>
      <Box >
        <ReactECharts
          option={option}
          notMerge
          theme={getDarkThemeJson()}
          style={{
            padding: '20px 0 0 0',
            height: '250px',
            maxHeight: '250px',
            width: '100%',
          }}
        />
      </Box>

      <Box sx={{ mt: 1.5 }}>
        <Card sx={{ p: 1.5, m: 1.5 }}>
          <CFormSet column={3} subTitle={'알람정보'}>
            <CFormSetItem>
              <CTextField
                name={'eventTime'}
                label={'알람 발생 일시'}
                readonly
              />
            </CFormSetItem>
            <CFormSetItem>
              <CTextField
                name={'clearanceTime'}
                label={'해지 일시'}
                readonly
              />
            </CFormSetItem>
            <CFormSetItem>
              <CTextField
                name={'eventState'}
                label={'알람 상태'}
                raw={alarm.eventState?.value}
                readonly
              />
            </CFormSetItem>
            <CFormSetItem>
              <CTextField
                name={'targetInstanceId'}
                label={'채널 SID'}
                readonly
              />
            </CFormSetItem>
            <CFormSetItem>
              <CTextField
                name={'targetInstanceName'}
                label={'채널명'}
                readonly
              />
            </CFormSetItem>
            <CFormSetItem>
              <CTextField
                name={'affectChannel'}
                label={'채널번호'}
                readonly
              />
            </CFormSetItem>
            <CFormSetItem grid={3}>
              <CTextField
                name={'message'}
                label={'알람 메시지'}
                variant={'outlined'}
                multiline
                multilineHeight={70}
              />
            </CFormSetItem>
          </CFormSet>
        </Card>
      </Box>
    </FormProvider>
  );
});

export default AiAlarmsDetailQuetone;