import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { createSimpleUser } from '../simple-user/SimpleUser';

/**
 * # Watch
 *
 * Watch을 설명하세요.
 */
export const Watch = types
  .model('Watch')
  // --------------------------------------------------------------------------
  .props({
    regDt: types.optional(types.number, 0),
    updDt: types.maybeNull(types.string),
    statDt: types.maybeNull(types.number),
    chSvcId: types.maybeNull(types.number),
    rank: types.optional(types.number, 1),
    rating: types.optional(types.number, 0),
    occupyRank: types.optional(types.number, 0),
    occupyRating: types.optional(types.number, 0),
    chNo: types.optional(types.number, 0),
    chNm: types.maybeNull(types.string),
    regUser: types.maybeNull(createSimpleUser()),
    updUser: types.maybeNull(createSimpleUser()),
  })
  // --------------------------------------------------------------------------
  // MUTATEs ONLY! - 모델 상태를 변경
  // REQUESTs는 스토어 모델에 작성하세요.
  // ex) yarn gen model watch --store
  .actions((self) => ({
    /*
     * 여러 prop을 한번에 수정
     */
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as IWatch;
      (Object.keys(newProps) as TWatchKeys[]).forEach((key) => {
        //@ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type TWatch = Instance<typeof Watch>;
type TWatchSnapshot = SnapshotOut<typeof Watch>;

export interface IWatch extends TWatch {}
export type TWatchKeys = keyof TWatchSnapshot & string;
export interface IWatchSnapshot extends TWatchSnapshot {}
export const createWatch = () => types.optional(Watch, {} as TWatch);
