import { check } from 'prettier';
import { IIntrlkSysSrvrFilter } from '../../models/intrlk-sys-srvr/IntrlkSysSrvr';
import { ApiResponse } from 'apisauce';
import {
  IIntrlkSysFilter,
  IIntrlkSysSnapshot,
  IIntrlkSysSrvr,
  IIntrlkSysSrvrSnapshot,
  IPaginationSnapshot,
  IResponseSnapshot,
  TIntrlkSysFilter,
} from '../../models';
import { Api } from '../api/api';
import { getGeneralApiProblem } from '../api/api-problem';
import { timestampToDate } from '../../utils/dateExchanger';
import {
  TDeleteIntrlkSysResult,
  TGetIntrlkSysResult,
  TGetIntrlkSysServersResult,
  TGetIntrlkSyssResult,
  TPostIntrlkSysResult,
  TGetIntrlkSysServerResult,
  TUpdateIntrlkSysResult,
  TCheckDeleteIntrlkSysResult,
} from './IntrlkSysTypes';

/**
 * # IntrlksSys Api 서비스
 *
 * 서비스의 설명을 작성하세요.
 *
 * ## 사용방법
 *
 * ```ts
 * const service = new IntrlksSysApi();
 *
 * ```
 */
export class IntrlkSysApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  /**
   * INF_CAIMS_00124
   * 연동 시스템 목록을 조회한다.
   * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=1988415981
   * @param pagination
   * @param intrlkSysFliter
   * @returns
   */
  async gets(
    pagination: IPaginationSnapshot,
    intrlkSysFliter?: TIntrlkSysFilter,
  ): Promise<TGetIntrlkSyssResult> {
    try {
      const url = '/system';
      let params: any = {};

      if (intrlkSysFliter) {
        params = filterSendData(intrlkSysFliter);
      }

      params.size = pagination.size;
      params.page = pagination.page;

      const response: ApiResponse<any> = await this.api.apisauce.get(url, params);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;

      if (responseInfo.resultCode === 'S') {
        return {
          kind: 'ok',
          responseInfo: responseInfo,
          intrlkSyss: response.data.data,
          pagination: response.data.pagination,
        };
      }
      return { kind: 'ok', responseInfo: responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  /**
   * INF_CAIMS_00125
   * 해당 연동시스템의 상세정보를 조회한다.
   * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=351035836
   * @param intrlkSysSid
   * @returns
   */
  async get(intrlkSysSid: number): Promise<TGetIntrlkSysResult> {
    try {
      const url = `/system/${intrlkSysSid}`;
      const response: ApiResponse<any> = await this.api.apisauce.get(url);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;

      if (responseInfo.resultCode === 'S') {
        return {
          kind: 'ok',
          responseInfo: responseInfo,
          intrlkSys: response.data.data,
        };
      }

      return { kind: 'ok', responseInfo: responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  /**
   * INF_CAIMS_00126
   * 새로운 연동시스템을 등록한다.
   * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=1849530440
   * @param intrlkSys
   * @returns
   */
  async post(intrlkSys: IIntrlkSysSnapshot): Promise<TPostIntrlkSysResult> {
    try {
      const url = '/system';
      const payload = createSendData(intrlkSys);
      const response: ApiResponse<any> = await this.api.apisauce.post(url, payload);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;

      return { kind: 'ok', intrlkSys: response.data.data, responseInfo: responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  /**
   * INF_CAIMS_00127
   * 해당 연동시스템 정보를 수정한다.
   * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=1404753331
   * @param intrlkSys
   * @returns
   */
  async put(intrlkSys: IIntrlkSysSnapshot): Promise<TUpdateIntrlkSysResult> {
    try {
      const url = '/system/' + intrlkSys.intrlkSysSid;
      const payload = createSendData(intrlkSys);
      const response: ApiResponse<any> = await this.api.apisauce.put(url, payload);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;

      return { kind: 'ok', intrlkSys: response.data.data, responseInfo: responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  /**
   * INF_CAIMS_00128
   * 해당 연동시스템을 삭제한다.
   * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=1401748613
   * @param intrlkSysSid
   * @returns
   */
  async delete(intrlkSysSid: number): Promise<TDeleteIntrlkSysResult> {
    try {
      const url = `/system/${intrlkSysSid}`;
      const response: ApiResponse<any> = await this.api.apisauce.delete(url);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;

      return { kind: 'ok', responseInfo: responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  /**
   * INF_CAIMS_00129
   * 여러개 연동시스템을 한번에 삭제한다.
   * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=1428605051
   * @param deleteIntrlSysList
   * @returns
   */
  async deletes(deleteIntrlSysList: number[]): Promise<TDeleteIntrlkSysResult> {
    let param: string = ``;
    deleteIntrlSysList.forEach((item: number, index: number) => {
      param += `intrlkSysSidList=${item}`;
      if (index !== deleteIntrlSysList.length - 1) {
        param += `&`;
      }
    });

    const url = `/system?${param}`;
    const response: ApiResponse<any> = await this.api.apisauce.delete(url);

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    const responseInfo: IResponseSnapshot = response.data;

    return { kind: 'ok', responseInfo: responseInfo };
  }

  /**
   * INF_CAIMS_00242
   * 연동시스템 전체 목록을 조회한다.
   * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=891519732
   */
  async getAll(): Promise<TGetIntrlkSyssResult> {
    try {
      const url: string = `/system/all`;
      const response: ApiResponse<any> = await this.api.apisauce.get(url);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;
      if (responseInfo.resultCode === 'S') {
        if (response.data.data) {
          const intrlkSyss: IIntrlkSysSnapshot[] = response.data.data;
          return { kind: `ok`, responseInfo: responseInfo, intrlkSyss: intrlkSyss };
        }
      }

      return { kind: `ok`, responseInfo: responseInfo };
    } catch (e) {
      return { kind: `bad-data` };
    }
  }

  /**
   * INF_CAIMS_00201
   * 전체 연동 시스템 서버 목록을 조회한다.
   * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=198928292
   * @returns
   */
  async getServers(
    pagination: IPaginationSnapshot,
    intrlkSysSrvrFilter?: IIntrlkSysSrvrFilter,
  ): Promise<TGetIntrlkSysServersResult> {
    try {
      interface ISendData extends IIntrlkSysSrvrFilter {
        page: number;
        size: number;
      }

      let param: ISendData = {
        size: pagination.size,
        page: pagination.page,
      };

      const createSendData = (intrlkSysSrvrFilter: IIntrlkSysSrvrFilter) => {
        type Tkeys = keyof IIntrlkSysFilter;

        (Object.keys(intrlkSysSrvrFilter) as Tkeys[]).forEach((key) => {
          if (!intrlkSysSrvrFilter[key]) {
            delete intrlkSysSrvrFilter[key];
          }
        });

        return intrlkSysSrvrFilter;
      };

      if (intrlkSysSrvrFilter) {
        param = { ...param, ...createSendData(intrlkSysSrvrFilter) };
      }

      const url: string = `/system/server`;
      const response: ApiResponse<any> = await this.api.apisauce.get(url, param);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }
      const responseInfo: IResponseSnapshot = response.data;

      if (responseInfo.resultCode === 'S') {
        if (response.data.data) {
          response.data.data.forEach((item: IIntrlkSysSrvrSnapshot) => {
            item.regDt = timestampToDate(Number.parseInt(item.regDt));
            item.updDt = timestampToDate(Number.parseInt(item.updDt));
          });
          const intrlkSysSrvrs: IIntrlkSysSrvrSnapshot[] = response.data.data;
          const pagination: IPaginationSnapshot = response.data.pagination;

          return {
            kind: `ok`,
            responseInfo: responseInfo,
            intrlkSysSrvrs: intrlkSysSrvrs,
            pagination: pagination,
          };
        }
      }

      return { kind: `ok`, responseInfo: responseInfo };
    } catch (e) {
      return { kind: `bad-data` };
    }
  }

  /**
   * INF_CAIMS_00202
   * 해당 연동 시스템 서버 상세정보를 조회한다.
   * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=452324458
   * @returns
   */
  async getServer(intrlkSysSrvrSid: number): Promise<TGetIntrlkSysServerResult> {
    try {
      const url: string = `/system/server/${intrlkSysSrvrSid}`;
      const response: ApiResponse<any> = await this.api.apisauce.get(url);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;
      if (responseInfo.resultCode === 'S') {
        if (response.data.data) {
          const intrlkSysSrvr: IIntrlkSysSrvrSnapshot = response.data.data;
          intrlkSysSrvr.regDt = timestampToDate(Number.parseInt(intrlkSysSrvr.regDt));
          intrlkSysSrvr.updDt = timestampToDate(Number.parseInt(intrlkSysSrvr.updDt));

          return { kind: `ok`, responseInfo: responseInfo, intrlkSysSrvr: intrlkSysSrvr };
        }
      }

      return { kind: `ok`, responseInfo: responseInfo };
    } catch (e) {
      return { kind: `bad-data` };
    }
  }

  /**
   * INF_CAIMS_00246
   * 특정 연동시스템을 삭제 할 수 있는지 확인한다.
   * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=1022464599
   * @param intrlkSysSidList
   * @returns
   */
  async checkDeleteIntrlkSys(intrlkSysSidList: number[]): Promise<TCheckDeleteIntrlkSysResult> {
    try {
      let param: string = ``;
      intrlkSysSidList.forEach((item: number, index: number) => {
        param += `intrlkSysSidList=${item}`;
        if (index !== intrlkSysSidList.length - 1) {
          param += `&`;
        }
      });
      const url: string = `/system/deleteCheck?${param}`;
      const response: ApiResponse<any> = await this.api.apisauce.get(url);
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;
      if (responseInfo.resultCode === 'S') {
        if (response.data.data) {
          const checkResult: string[] = response.data.data;
          return { kind: `ok`, responseInfo: responseInfo, checkResult: checkResult };
        }
      }
      return { kind: `ok`, responseInfo: responseInfo };
    } catch (e) {
      return { kind: `bad-data` };
    }
  }
}

const createSendData = (intrlkSys: IIntrlkSysSnapshot) => {
  let sendData: any = {};
  if (intrlkSys.intrlkSysSid) {
    sendData.intrlkSysSid = intrlkSys.intrlkSysSid;
  }

  if (intrlkSys.sysNm !== undefined) {
    sendData.sysNm = intrlkSys.sysNm;
  }

  if (intrlkSys.sysMngr !== undefined) {
    sendData.sysMngr = intrlkSys.sysMngr;
  }


  if (intrlkSys.mnfcSid) {
    sendData.mnfcSid = intrlkSys.mnfcSid;
  }

  if (intrlkSys.vendorSid) {
    sendData.vendorSid = intrlkSys.vendorSid;
  }

  if (intrlkSys.haType) {
    sendData.haType = intrlkSys.haType.code;
  }

  if (intrlkSys.sysType) {
    sendData.sysType = intrlkSys.sysType.code;
  }

  if (intrlkSys.vip !== undefined) {
    sendData.vip = intrlkSys.vip;
  }

  if (intrlkSys.mgmtEquipType !== undefined) {
    sendData.mgmtEquipType = intrlkSys.mgmtEquipType;
  }

  let intrlkSysSrvrListParamList: IIntrlkSysSrvr[] = [];

  if (intrlkSys.intrlkSysSrvrList) {
    intrlkSys.intrlkSysSrvrList.forEach((item: IIntrlkSysSrvrSnapshot) => {
      if (item.haSttus.code) {
        item.haSttusCode = item.haSttus.code;
      }

      let params: any = {
        intrlkSysSrvrSid: item.intrlkSysSrvrSid,
        intrlkSysSid: item.intrlkSysSid,
        sysSrvrNm: item.sysSrvrNm,
        haSttus: item.haSttusCode,
        mngIp: item.mngIp,
      };

      intrlkSysSrvrListParamList.push(params as IIntrlkSysSrvr);
    });

    sendData.intrlkSysSrvrList = intrlkSysSrvrListParamList;
  }

  return sendData;
};

const filterSendData = (intrlkSysFilter: IIntrlkSysFilter) => {
  let sendData: any = {};

  if (intrlkSysFilter.sysNm) {
    sendData.sysNm = intrlkSysFilter.sysNm;
  }

  return sendData;
};
