import { Instance, SnapshotOut, types } from 'mobx-state-tree';

/**
 * # SoketSnackbar
 *
 * SoketSnackbar을 설명하세요.
 */
export const SoketSnackbar = types
  .model('SoketSnackbar')
  .props({
    id: types.optional(types.maybeNull(types.string), null),
    message: types.optional(types.maybeNull(types.string), ''),
    type: types.optional(types.string, ''),
  })
  .actions((self) => ({
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as ISoketSnackbar;
      (Object.keys(newProps) as TSoketSnackbarKeys[]).forEach((key) => {
        //@ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

type TSoketSnackbar = Instance<typeof SoketSnackbar>;
type TSoketSnackbarSnapshot = SnapshotOut<typeof SoketSnackbar>;

export interface ISoketSnackbar extends TSoketSnackbar {}
export type TSoketSnackbarKeys = keyof TSoketSnackbarSnapshot & string;
export interface ISoketSnackbarSnapshot extends TSoketSnackbarSnapshot {}
export const createSoketSnackbar = () =>
  types.optional(SoketSnackbar, {
    id: null,
    message: '',
    type: '',
  } as TSoketSnackbar);
