import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { createModelCode } from '../model-code/ModelCode';

/**
 * # OutputSw
 *
 * OutputSw을 설명하세요.
 */
export const OutputSw = types
  .model('OutputSw')
  .props({
    equipSid: types.optional(types.number, 0),
    equipRole: createModelCode(),
    mainYn: types.optional(types.boolean, false),
    hostNm: types.optional(types.string, ''),
    mgmtIp: types.optional(types.string, ''),
    modelNm: types.optional(types.maybeNull(types.string), ''),
    frmwrVer: types.optional(types.maybeNull(types.string), ''),
    equipLo: types.optional(types.maybeNull(types.string), ''),
    equipMngr:  types.optional(types.maybeNull(types.string), ''),
    inPortSid: types.optional(types.maybeNull(types.number), 0),
    inPortNm: types.optional(types.maybeNull(types.string), ''),
    outPortSid: types.optional(types.maybeNull(types.number), 0),
    outPortNm: types.optional(types.maybeNull(types.string), ''),

    inPort: createModelCode(),
    outPort: createModelCode(),
  })
  .actions((self) => ({
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as IOutputSw;
      (Object.keys(newProps) as TOutputSwKeys[]).forEach((key) => {
        //@ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

type TOutputSw = Instance<typeof OutputSw>;
type TOutputSwSnapshot = SnapshotOut<typeof OutputSw>;

export interface IOutputSw extends TOutputSw {}
export type TOutputSwKeys = keyof TOutputSwSnapshot & string;
export interface IOutputSwSnapshot extends TOutputSwSnapshot {}
export const createOutputSw = () =>
  types.optional(OutputSw, {
    equipSid: 0,
    mainYn: false,
    hostNm: '',
    mgmtIp: '',
    modelNm: null,
    frmwrVer: null,
    inPortSid: null,
    inPortNm: null,
    outPortSid: null,
    outPortNm: null,
  } as TOutputSw);
