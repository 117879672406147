import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { selectOptions } from '../../components/forms/CSelect';
import { createStbStatisticsData } from '../stb-statistics-data/StbStatisticsData';

export const StbStatistics = types
  .model('StbStatistics')
  .props({
    statDt: types.optional(types.string, ''),
    preStatDt: types.optional(types.maybeNull(types.string), null),
    dataList: types.optional(types.array(createStbStatisticsData()), []),
  })
  .actions((self) => ({
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as IStbStatistics;
      (Object.keys(newProps) as TStbStatisticsKeys[]).forEach((key) => {
        //@ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type TStbStatistics = Instance<typeof StbStatistics>;
type TStbStatisticsSnapshot = SnapshotOut<typeof StbStatistics>;

export interface IStbStatistics extends TStbStatistics {}
export type TStbStatisticsKeys = keyof TStbStatisticsSnapshot & string;
export interface IStbStatisticsSnapshot extends TStbStatisticsSnapshot {}
export const createStbStatistics = () =>
  types.optional(StbStatistics, {
    statDt: '',
    preStatDt: '',
  } as TStbStatistics);

  export interface IStbStatisticsFilter {
    withPre?: boolean;
    regionList?: selectOptions[];
    modelList?: string;
    statDtSt?: string;
    statDtEd?: string;
    statType: number;
    statUnit?: number;
    useOneview?: boolean;
  }
  
  export type TStbStatisticsFilter = IStbStatisticsFilter;
