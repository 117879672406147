import { Instance, SnapshotOut, types } from "mobx-state-tree"

  import { withEnvironment } from "../extensions/with-environment"; 

  import {
  QuetoneGraphApi, TGetQuetoneGraphResult,
  TGetQuetoneGraphsResult
} from "../../services";
import {createQuetoneGraph, IQuetoneGraph, IQuetoneGraphSnapshot, QuetoneGraph} from "../quetone-graph/QuetoneGraph";
import {withRootStore} from "../extensions/with-root-store";

/**
 * # QuetoneGraphStore
 * 
 * QuetoneGraphStore을 설명하세요.
 */
export const QuetoneGraphStore = types
  .model("QuetoneGraphStore")
  // --------------------------------------------------------------------------
  .props({
    quetoneGraphs : types.optional(types.array(QuetoneGraph), []),
    selectedQuetoneGraph: createQuetoneGraph(),
  })
  .extend(withEnvironment)
    .extend(withRootStore)
  // eslint-disable-line @typescript-eslint/no-unused-vars
  .views((self) => ({}))
  // --------------------------------------------------------------------------
  // MUTATEs - 모델 상태를 변경
  .actions((self) => ({
    /**
     * quetoneGraphs을 교체
     *
     * @param `quetoneGraphs` 새로운 모델의 배열
     */
    setQuetoneGraphs: (quetoneGraphs: IQuetoneGraph[]) => {
      self.quetoneGraphs.replace(quetoneGraphs);
    },
    setSelectedQuetone: (quetone: IQuetoneGraphSnapshot) => {
      self.selectedQuetoneGraph = quetone as IQuetoneGraph;
    },
  }))
  // --------------------------------------------------------------------------
  // REQUESTs - 서비스 요청 및 기타 인터페이스 요청 
  .actions((self) => ({
    /**
     * 전체 목록을 Api를 통해 조회
     * 
     * 조회한 결과로 QuetoneGraphs를 교체한다. 실패시 에러 로그를 남긴다.
     */
    getQuetoneGraphs: async () => {
      const quetoneGraphApi : QuetoneGraphApi = new QuetoneGraphApi(self.environment.api);
      const result: TGetQuetoneGraphsResult = await quetoneGraphApi.getQuetoneGraphs();
      if (result.kind === 'ok') {
        self.setQuetoneGraphs(result.quetoneGraphs);
      } else {
        console.error(result.kind);
      }
    },
    get: async (chSid:string, chNm:string, quetoneType:string) => {
      try{
        const quetoneGraphApi : QuetoneGraphApi = new QuetoneGraphApi(self.environment.api);
        const result: TGetQuetoneGraphResult = await quetoneGraphApi.getQuetoneGraph(chSid, chNm, quetoneType);
        if (result.kind === 'ok') {
          self.setSelectedQuetone(result.quetoneGraph);
        }
      }catch (e)
      {
        self.rootStore.responseStore.errorProcessing(e);
      }

    },
  }))

// --------------------------------------------------------------------------
type TQuetoneGraphStore = Instance<typeof QuetoneGraphStore>;
type TQuetoneGraphStoreSnapshot = SnapshotOut<typeof QuetoneGraphStore>

export interface IQuetoneGraphStore extends TQuetoneGraphStore {}
export type TQuetoneGraphStoreKeys = keyof TQuetoneGraphStoreSnapshot & string;
export interface IQuetoneGraphStoreSnapshot extends TQuetoneGraphStoreSnapshot {}
export const createQuetoneGraphStore = () => 
  types.optional(QuetoneGraphStore, {} as TQuetoneGraphStore);
