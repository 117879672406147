import { HeadCell } from '../../../components/CTableHead';

export const headCellsAlarmSetup: readonly HeadCell[] = [
  {
    id: 'svrt',
    numeric: false,
    disablePadding: true,
    label: '알람 등급',
  },
  {
    id: 'audio',
    numeric: false,
    disablePadding: true,
    label: '미리듣기',
  },
  {
    id: 'fileName',
    numeric: false,
    disablePadding: true,
    label: '파일명',
  },
  {
    id: 'useYn',
    numeric: true,
    disablePadding: true,
    label: '사용여부',
  },
  {
    id: 'uploadFile',
    numeric: true,
    disablePadding: true,
    label: '업로드',
  }
];

