import { ApiResponse } from 'apisauce';
import { Api } from '../api/api';
import { getGeneralApiProblem } from '../api/api-problem';
import {
  IEventTypeFilter,
  IEventTypeSnapshot,
  TEventTypeKeys,
} from './../../models/event-type/EventType';
import { IResponseSnapshot } from './../../models/response/Response';
import { timestampToDate } from './../../utils/dateExchanger';
import { TGetAllEventTypeResult } from './EventTypeTypes';

/**
 * # EventType Api 서비스
 *
 * 서비스의 설명을 작성하세요.
 *
 * ## 사용방법
 *
 * ```ts
 * const service = new EventTypeApi();
 *
 * ```
 */
export class EventTypeApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  /**
   * INF_CAIMS_00247
   * 이벤트 유형 데이터 항목을 전체 조회한다.
   * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=116308996
   * @param eventTypeFilter
   * @returns
   */
  async gets(eventTypeFilter?: IEventTypeFilter): Promise<TGetAllEventTypeResult> {
    try {
      const url: string = `/eventType`;
      let response: ApiResponse<any>;
      if (eventTypeFilter) {
        response = await this.api.apisauce.get(url, deleteAttirbute(eventTypeFilter));
      } else {
        response = await this.api.apisauce.get(url);
      }

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;
      if (responseInfo.resultCode === 'S') {
        if (response.data.data) {
          response.data.data.forEach((item: IEventTypeSnapshot) => {
            if (item.regDt && item.updDt) {
              item.regDt = timestampToDate(Number.parseInt(item.regDt));
              item.updDt = timestampToDate(Number.parseInt(item.updDt));
            }
          });
          const eventTypes: IEventTypeSnapshot[] = response.data.data;
          return { kind: `ok`, responseInfo: responseInfo, eventTypes: eventTypes };
        }
      }
      return { kind: `ok`, responseInfo: responseInfo };
    } catch (e) {
      return { kind: `bad-data` };
    }
  }
}

const deleteAttirbute = (eventTypeFilter: IEventTypeFilter) => {
  (Object.keys(eventTypeFilter) as TEventTypeKeys[]).forEach((key: string) => {
    //@ts-ignore
    if (!eventTypeFilter[key]) {
      //@ts-ignore
      delete eventTypeFilter[key];
    }
  });
  return eventTypeFilter;
};
