// import { AlarmSetup } from './../../screens/RealtimeMonitoring/Common/AlarmSetup';
import { getValueTransition } from 'framer-motion/types/animation/utils/transitions';
/* eslint-disable eqeqeq */
import { Client } from '@stomp/stompjs';
import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { timestampToDate } from '../../utils/dateExchanger';
import { withEnvironment } from '../extensions/with-environment';
import { withRootStore } from '../extensions/with-root-store';
import { createPagination, IPagination, IPaginationSnapshot } from '../pagination/Pagination';
import { RealTimeAlarmApi } from '../../services/alarm/AlarmRealTime';
import {
  createRealTimeAlarm,
  IRealTimeAlarm,
  IRealTimeAlarmFilter,
  IRealTimeAlarmSnapshot,
} from '../alarm/RealTimeAlarm';
import {
  TGetAlarmSetupResult,
  TGetMonitoringAlarmsResult,
  TGetRealTimeAlarmResult,
  TGetRealTimeAlarmsResult,
} from '../../services/alarm/AlarmRealTimeTypes';
import {
  createSocketMessage,
  ISocketMessage,
  ISocketMessageSnapshot,
} from '../socket-message/SocketMessage';
import { toJS } from 'mobx';
import moment, { relativeTimeRounding } from 'moment';
import { isNull, set } from 'lodash';
import { isEmpty } from 'lodash';
import { EquipmentApi } from '../../services/equipment/Equipment';
import { TGetAlarmResult, TGetChannelsResult } from '../../services';
import { Channel, IChannel, IChannelSnapshot } from '../channel/Channel';
import { TOPOLOGY_IDS } from '../../components/settings/SettingConstEnum';
import { selectOptions } from '../../components/forms/CSelect';
import { IAlarmSetup, IAlarmSetupSnapshot, createAlarmSetup } from './AlarmSetup';

const countSet: any = {
  channel: {
    terrestrial: { count: 0, color: '' },
    pp: { count: 0, color: '' },
    cug: { count: 0, color: '' },
    // satellite: { count: 0, color: '' }, // cug 와 merge 10.07
    // aws: { count: 0, color: '' }, // cug 와 merge 10.07

    seezn: { count: 0, color: '' },
    skylife: { count: 0, color: '' },
    skytv: { count: 0, color: '' },


    // use for the oneview screen top area info
    uhd: { count: 0, color: '' },
    hd: { count: 0, color: '' },
    audio: { count: 0, color: '' },
    qtoneA: { count: 0, color: '' },
    qtoneB: { count: 0, color: '' },
    qtoneD: { count: 0, color: '' },
    qtoneTotal: { count: 0, color: '' },
    thumbnail: { count: 0, color: '' },
    stiching: { count: 0, color: '' },
  },
  service: {
    quetone: { count: 0, color: '' },
    person: { count: 0, color: '' },
    stiching: { count: 0, color: '' },
    thumbnail: { count: 0, color: '' },
  },
  infra: {
    encoder: { count: 0, color: '' },
    transfer: { count: 0, color: '' },
    l3: { count: 0, color: '' },
    ipaco: { count: 0, color: '' },
    mux: { count: 0, color: '' },
  },
}

export const RealTimeAlarmStore = types
  .model('RealTimeAlarmStore')
  .props({
    mode: types.optional(types.string, 'widget'),
    updated: types.optional(types.number, 0),
    alarms: types.optional(types.array(createRealTimeAlarm()), []),
    selectedAlarm: createRealTimeAlarm(),

    pagination: createPagination(),

    relatedAlarms: types.optional(types.array(createRealTimeAlarm()), []),
    relatedPagination: createPagination(),

    socketKey: types.optional(types.maybeNull(types.string), null),
    socketMessage: types.maybeNull(createSocketMessage()),
    socketDetailMessage: types.optional(types.string, ''),

    topologyAlarms: types.array(types.string),

    showSeverity: types.optional(types.array(types.string), ['critical', 'major', 'minor']),


    channels: types.frozen(countSet),
    countset: types.frozen(countSet),
    counts: types.frozen(countSet),
    useCount: types.optional(types.boolean, false),

    affectChannels: types.optional(types.array(Channel), []),

    probableCauses: types.optional(types.array(types.string), []),
    alarmSetup: types.optional(types.array(createAlarmSetup()), []),

    alarmSoundMute: types.optional(types.boolean, false),
    clearedAlarmSound: types.optional(types.maybeNull(types.string), null),
    indeterminateAlarmSound: types.optional(types.maybeNull(types.string), null),
    criticalAlarmSound: types.optional(types.maybeNull(types.string), null),
    majorAlarmSound: types.optional(types.maybeNull(types.string), null),
    minorAlarmSound: types.optional(types.maybeNull(types.string), null),
    warningAlarmSound: types.optional(types.maybeNull(types.string), null),
    informationAlarmSound: types.optional(types.maybeNull(types.string), null),

    alarmSoundToPlay: types.optional(types.string, '')
  })
  .extend(withEnvironment)
  .extend(withRootStore)
  .views((self) => ({}))
  .actions((self) => ({
    setMode: (type: string) => {
      self.mode = type;
    },
    setUpdated: () => {
      self.updated++;
    },
    setAlarms: (alarms: IRealTimeAlarmSnapshot[]) => {
      self.alarms.replace(alarms as IRealTimeAlarm[]);
    },
    setSelectedAlarm: (alarm: IRealTimeAlarmSnapshot) => {
      self.selectedAlarm = alarm as IRealTimeAlarm;
    },
    setPagination: (pagination: IPaginationSnapshot) => {
      self.pagination = pagination as IPagination;
    },
    setRelatedAlarms: (alarms: IRealTimeAlarmSnapshot[]) => {
      self.relatedAlarms.replace(alarms as IRealTimeAlarm[]);
    },
    resetSelectedAlarm: () => {
      self.selectedAlarm = {} as IRealTimeAlarm;
    },
    resetRelatedAlarms: () => {
      self.relatedAlarms.replace([]);
    },
    setRelatedPagination: (pagination: IPaginationSnapshot) => {
      self.relatedPagination = pagination as IPagination;
    },
    setShowSeverity: (severity: string[]) => {
      self.showSeverity.replace(severity);
    },
    setAlarmSetup: (setup: IAlarmSetupSnapshot[]) => {
      self.alarmSetup.replace(setup as IAlarmSetup[])
    },
    addAiAlarm: (item: IRealTimeAlarmSnapshot) => {
      self.alarms.unshift(item);
    },
    updateAiAlarm: (item: IRealTimeAlarmSnapshot) => {
      const copied = [...self.alarms];
      let alarmToUpdate = copied.find((obj)=> obj.eventId == item.eventId)

      if(alarmToUpdate && item.perceivedSeverity?.value === "Cleared" && item.eventState?.value === "Terminated"){
        let data = {...item, perceivedSeverity: toJS(alarmToUpdate.perceivedSeverity)} 

        copied.map((alarm, i) => {
          if (alarm.eventId == data.eventId) {
            self.alarms.splice(i, 1, data as IRealTimeAlarmSnapshot); 
          }
        });
      }else{
        copied.map((alarm, i) => {
          if (alarm.eventId == item.eventId) {
            self.alarms.splice(i, 1, item as IRealTimeAlarmSnapshot); 
          }
        });
      }
  
    },
    removeAiAlarm: (item: IRealTimeAlarmSnapshot) => {
      self.alarms.replace(self.alarms.filter((row) => { return row.eventId != item.eventId }));
    },
    addTopologyAlarms: (id: string) => {
      const list = [...self.topologyAlarms];
      list.push(id);
      self.topologyAlarms.replace(list);
    },
    setUseCount: (flag: boolean) => {
      self.useCount = flag;
    },
    setChannels: (data: any) => {
      self.channels = JSON.parse(data);
    },
    /**
     * Save count by type
     */
    setCounts: async () => {

      let equips: string[] = [];
      if (self.rootStore.topologyStore.equipList.length > 0) {
        self.rootStore.topologyStore.equipList.forEach((e: any) => {
          const j = JSON.parse(e);
          if (j.id === TOPOLOGY_IDS.BAEKSEOK) {
            equips = j.equips;
          }
        })
      }
      // console.log('set counts equips', equips)

      const setColor = (obj: any, severity: string) => {
        let res = 'critical';
        if (severity.toLowerCase() === 'major' && obj.color !== 'critical') {
          res = 'major';
        } else if (severity.toLowerCase() === 'minor' && (obj.color !== 'critical' && obj.color !== 'major')) {
          res = 'minor';
        }
        return res;
      }

      let req = JSON.parse(JSON.stringify(self.countset));

      const excludeKeys = ['uhd', 'hd', 'audio', 'qtoneA', 'qtoneB', 'qtoneD', 'qtoneTotal', 'thumbnail', 'stiching'];

      self.alarms.forEach((row: IRealTimeAlarmSnapshot, index: number) => {
        // console.log('row', row)
        switch (row.systemName?.toLowerCase()) {
          // case 'multiview':
          case 'ai':
            if (row.eventType?.value?.toLowerCase() === 'ai alarm' && row.probableCause === 'Channel Service' && row.specificProblem === 'Channel Service Error') {
              Object.keys(self.channels).forEach((key, index) => {

                if (excludeKeys.indexOf(key) > -1) {
                  return;
                }

                if (row.targetInstanceId !== undefined && row.targetInstanceId !== 'undefined' && row.targetInstanceId !== '') {
                  if ((!isNull(row.targetInstanceId) && !isEmpty(row.targetInstanceId)) && self.channels[key].chSvcIdList.indexOf(Number(row.targetInstanceId)) > -1) {
                    req.channel[((key === 'satellite' || key === 'aws') ? 'cug' : key)].count += 1;
                    req.channel[((key === 'satellite' || key === 'aws') ? 'cug' : key)].color = setColor(req.channel[((key === 'satellite' || key === 'aws') ? 'cug' : key)], row.perceivedSeverity?.value || '');
                  }
                }
              });
            } else if (row.eventType?.value?.toLowerCase() === 'ai alarm' && row.probableCause?.toLowerCase() === 'loss of disabled person signal') {
              // 장애인 방송 : Event type 이 AI Alarm 이고 probable cause 값이 Loss of Disabled Person Signal 인 경우.
              req.service.person.count += 1;
              req.service.person.color = setColor(req.service.person, row.perceivedSeverity?.value || '');
            } else if (row.eventType?.value?.toLowerCase() === 'ai alarm' &&
              (row.probableCause?.toLowerCase() === 'loss of quetone signal' || row.probableCause?.toLowerCase() === 'anomaly quetone signal')
            ) {
              req.service.quetone.count += 1;
              req.service.quetone.color = setColor(req.service.quetone, row.perceivedSeverity?.value || '');
            }
            break;
          case 'quetone':
            if (row.eventType?.value?.toLowerCase() === 'ai alarm' &&
              (row.probableCause?.toLowerCase() === 'loss of quetone signal' || row.probableCause?.toLowerCase() === 'anomaly quetone signal')
            ) {
              req.service.quetone.count += 1;
              req.service.quetone.color = setColor(req.service.quetone, row.perceivedSeverity?.value || '');
            }
            break;
          case 'videostiching':
            req.service.stiching.count += 1;
            req.service.stiching.color = setColor(req.service.stiching, row.perceivedSeverity?.value || '');
            break;
          case 'thumbnail':
            req.service.thumbnail.count += 1;
            req.service.thumbnail.color = setColor(req.service.thumbnail, row.perceivedSeverity?.value || '');
            break;
          case 'ams':
            req.infra.encoder.count += 1;
            req.infra.encoder.color = setColor(req.infra.encoder, row.perceivedSeverity?.value || '');
            break;
          case 'dr_lauren':
            req.infra.transfer.count += 1;
            req.infra.transfer.color = setColor(req.infra.transfer, row.perceivedSeverity?.value || '');
            break;
          case 'l3':
            req.infra.l3.count += 1;
            req.infra.l3.color = setColor(req.infra.l3, row.perceivedSeverity?.value || '');
            break;
          case 'ip_aco':
            req.infra.ipaco.count += 1;
            req.infra.ipaco.color = setColor(req.infra.ipaco, row.perceivedSeverity?.value || '');
            break;
          case 'mux':
            if (row.instanceId && equips.includes(row.instanceId) === true) {
              req.infra.mux.count += 1;
              req.infra.mux.color = setColor(req.infra.mux, row.perceivedSeverity?.value || '');
            }
            break;
          default:
            break;
        }
      });

      self.counts = req;
      // self.updated++;
    },

    setAffectChannels: (channelList: IChannelSnapshot[]) => {
      if (!channelList) {
        return;
      }
      self.affectChannels.replace(channelList as IChannel[]);
    },
    setProbableCauses: (probableCauseList: string[]) => {
      if (!probableCauseList) {
        return;
      }
      self.probableCauses.replace(probableCauseList as string[]);
    },
    setClearedAlarmSound: (url: string)=>{
      self.clearedAlarmSound= url
    }, 
    setIndeterminateAlarmSound: (url: string)=>{
      self.indeterminateAlarmSound= url
    }, 
    setCriticalAlarmSound: (url: string)=>{
      self.criticalAlarmSound= url
    }, 
    setMajorAlarmSound: (url: string)=>{
      self.majorAlarmSound= url
    }, 
    setMinorAlarmSound: (url: string)=>{
      self.minorAlarmSound= url
    }, 
    setWarningAlarmSound: (url: string)=>{
      self.warningAlarmSound= url
    }, 
    setInformationAlarmSound: (url: string)=>{
      self.informationAlarmSound= url
    }, 
    setAlarmSoundMute: (state: boolean) => {
      self.alarmSoundMute = state
    },
    setAlarmSoundToPlay : (url: string) => {
      self.alarmSoundToPlay = url
    }

  }))
  .actions((self) => ({
    /**
     * API
      •	실시간 발생 알람 목록 조회 : INF_CAIMS_00014
      •	실시간 발생 알람 상세 조회 : INF_CAIMS_00015
      •	실시간 발생 알람 인지 처리 : INF_CAIMS_00016
      •	실시간 발생 알람 Clear 처리 : INF_CAIMS_00017
      •	실시간 발생 알람 Terminmate 처리 : INF_CAIMS_00018

     * INF_CAIMS_00014
     * 실시간으로 발생한 알람 목록을 조회한다.
     * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=917183144
     * @param alarmFilter
     */
    gets: async (alarmFilter?: IRealTimeAlarmFilter) => {

      try {

        const alarmApi: RealTimeAlarmApi = new RealTimeAlarmApi(self.environment.api);
        const result: TGetRealTimeAlarmsResult = await alarmApi.gets(alarmFilter);

        if (self.rootStore.responseStore.getResponseResult(result)) {
          if (result.alarms) {
            result.alarms.forEach((item: IRealTimeAlarmSnapshot) => {
              item.eventTime = (item.eventTime) ? timestampToDate(Number.parseInt(item.eventTime)) : '';
              item.originalEventTime = (item.originalEventTime) ? timestampToDate(Number.parseInt(item.originalEventTime)) : '';
              item.acknowledgedTime = (item.acknowledgedTime) ? timestampToDate(Number.parseInt(item.acknowledgedTime)) : '';
              item.clearanceTime = (item.clearanceTime) ? timestampToDate(Number.parseInt(item.clearanceTime)) : '';
            });
            const filteredAlarms = result.alarms.filter(v => v.perceivedSeverity !== "Information");
            self.setAlarms(filteredAlarms);
            if (self.useCount === true) {
              self.setCounts();
            }
            self.setUpdated();
          }
        }
      } catch (e) {
        console.log(e);
        self.rootStore.responseStore.errorProcessing(e);
      }
    },
    getRealtimeMonitoringAlarm: async (alarmFilter?: IRealtimeMonitoringAlarmFilter) => {
      try{
        const alarmApi: RealTimeAlarmApi = new RealTimeAlarmApi(self.environment.api);
        const result: TGetMonitoringAlarmsResult = await alarmApi.getRealtimeMonitoringAlarm(
          alarmFilter,
          self.pagination,
        );

        if (self.rootStore.responseStore.getResponseResult(result)) {
          if (result.alarms) {
            result.alarms.forEach((item: IRealTimeAlarmSnapshot) => {
              item.eventDuration =
                item.eventTime &&
                typeof item.eventTime == 'number' &&
                item.clearanceTime &&
                typeof item.clearanceTime == 'number'
                  ? (((item.clearanceTime as number) - item.eventTime) as number) / 1000
                  : (Number(Date.now())- Number(item?.eventTime))/1000;
              item.eventTime = item.eventTime
                ? timestampToDate(Number.parseInt(item.eventTime))
                : '';
              item.originalEventTime = item.originalEventTime
                ? timestampToDate(Number.parseInt(item.originalEventTime))
                : '';
              item.acknowledgedTime = item.acknowledgedTime
                ? timestampToDate(Number.parseInt(item.acknowledgedTime))
                : '';
              item.clearanceTime = item.clearanceTime
                ? timestampToDate(Number.parseInt(item.clearanceTime))
                : '';
            });
            const filteredAlarms = result.alarms.filter(
              (v) => v.perceivedSeverity !== 'Information',
            );

            let resultAlarm = [...filteredAlarms];

            if (
              alarmFilter &&
              alarmFilter.probableCauseList &&
              alarmFilter.probableCauseList[0] == 'Channel Service'
            ) {
              resultAlarm = filteredAlarms.map((item) => {
                let sourceEventTime = null;
                if (item.additionalInformation) {
                  let parsedSourceEventTime = JSON.parse(
                    item.additionalInformation,
                  ).sourceEventTime;
                  if (parsedSourceEventTime) {
                    sourceEventTime = timestampToDate(Number.parseInt(parsedSourceEventTime));
                  }
                }

                return { ...item, sourceEventTime };
              });
            }

            self.setAlarms(resultAlarm);
            if (self.useCount === true) {
              self.setCounts();
            }
            self.setUpdated();
          }
          if (result.pagination) {
            self.setPagination(result.pagination);
          }
        }

        // set stuff
      } catch (e) {
        console.log('store error', e);
        self.rootStore.responseStore.errorProcessing(e);
      }
    },
    updateEventDuration: async () => {
      try {
        if (self.alarms) {
          const updatedAlarms = toJS(self.alarms);
          await updatedAlarms.forEach((item: IRealTimeAlarmSnapshot) => {
            if (!item.cleared) {
              item.eventDuration = (Date.now() - new Date(item?.eventTime).getTime()) / 1000;
              self.updateAiAlarm(item);
            }
          });
        }
      } catch (e) {
        console.log(e);
        self.rootStore.responseStore.errorProcessing(e);
      }
    },

    download: async (alarmFilter?: IRealTimeAlarmFilter) => {
      const alarmApi: RealTimeAlarmApi = new RealTimeAlarmApi(self.environment.api);
      await alarmApi.download(alarmFilter);
    },

    downloadExcel: async (alarmFilter?: IRealtimeMonitoringAlarmFilter) => {
      const alarmApi: RealTimeAlarmApi = new RealTimeAlarmApi(self.environment.api);
      await alarmApi.downloadExcel(alarmFilter);
    },

    /**
     * API
     * 연관알람
     * @param alarmFilter
     */
    getRelateds: async (eventId: string) => {
      try {
        const alarmApi: RealTimeAlarmApi = new RealTimeAlarmApi(self.environment.api);
        const result: TGetRealTimeAlarmsResult = await alarmApi.getRelateds(eventId);

        if (self.rootStore.responseStore.getResponseResult(result)) {
          if (result.alarms) {
            result.alarms.forEach((item: IRealTimeAlarmSnapshot) => {
              item.eventTime = (item.eventTime) ? timestampToDate(Number.parseInt(item.eventTime)) : '';
              item.originalEventTime = (item.originalEventTime) ? timestampToDate(Number.parseInt(item.originalEventTime)) : '';
              item.acknowledgedTime = (item.acknowledgedTime) ? timestampToDate(Number.parseInt(item.acknowledgedTime)) : '';
              item.clearanceTime = (item.clearanceTime) ? timestampToDate(Number.parseInt(item.clearanceTime)) : '';
            });
            self.setRelatedAlarms(result.alarms);
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },

    /**
     * INF_CAIMS_00015
     * 해당 알람 상세정보를 조회한다.
     * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=750435334
     * @param eventId
     */
    get: async (eventId: string) => {
      try {
        const alarmApi: RealTimeAlarmApi = new RealTimeAlarmApi(self.environment.api);
        const result: TGetRealTimeAlarmResult = await alarmApi.get(eventId);

        if (self.rootStore.responseStore.getResponseResult(result)) {
          if (result.alarm) {
            result.alarm.eventTime = (result.alarm.eventTime) ? timestampToDate(Number.parseInt(result.alarm.eventTime)) : '';
            result.alarm.originalEventTime = (result.alarm.originalEventTime) ? timestampToDate(Number.parseInt(result.alarm.originalEventTime)) : '';
            result.alarm.acknowledgedTime = (result.alarm.acknowledgedTime) ? timestampToDate(Number.parseInt(result.alarm.acknowledgedTime)) : '';
            result.alarm.clearanceTime = (result.alarm.clearanceTime) ? timestampToDate(Number.parseInt(result.alarm.clearanceTime)) : '';
            self.setSelectedAlarm(result.alarm);
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },
    /**
     * for dashboard sidebar panels (infra)
     * Bring a list of nutrition channels for the equipment
     * @param eventId
     */
    getAffectChannels: async (equipSid: string) => {
      try {
        self.setAffectChannels([]);
        const api: EquipmentApi = new EquipmentApi(self.environment.api);
        const result: TGetChannelsResult = await api.getChannelListUsingEquipment(equipSid);

        if (self.rootStore.responseStore.getResponseResult(result)) {
          result.channels?.map((c) => {
            c.regDt = (c.regDt) ? timestampToDate(Number.parseInt(c.regDt)) : '';
            c.updDt = (c.updDt) ? timestampToDate(Number.parseInt(c.updDt)) : '';
          });
          result.channels && self.setAffectChannels(result.channels);
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },
    // operator note
    put: async (alarm: IRealTimeAlarm) => {
      try {
        const alarmApi: RealTimeAlarmApi = new RealTimeAlarmApi(self.environment.api);
        const result: TGetRealTimeAlarmResult = await alarmApi.put(alarm);
        if (self.rootStore.responseStore.getResponseResult(result)) {
          if (result.alarm) {
            result.alarm.eventTime = (result.alarm.eventTime) ? timestampToDate(Number.parseInt(result.alarm.eventTime)) : '';
            result.alarm.originalEventTime = (result.alarm.originalEventTime) ? timestampToDate(Number.parseInt(result.alarm.originalEventTime)) : '';
            result.alarm.acknowledgedTime = (result.alarm.acknowledgedTime) ? timestampToDate(Number.parseInt(result.alarm.acknowledgedTime)) : '';
            result.alarm.clearanceTime = (result.alarm.clearanceTime) ? timestampToDate(Number.parseInt(result.alarm.clearanceTime)) : '';
            self.setSelectedAlarm(result.alarm);
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },

    update: async (eventId: string, type: string) => {
      try {
        const alarmApi: RealTimeAlarmApi = new RealTimeAlarmApi(self.environment.api);
        const result: TGetRealTimeAlarmResult = await alarmApi.update(eventId, type);
        if (self.rootStore.responseStore.getResponseResult(result)) {
          if (result.alarm) {
            const obj = { ...result.alarm };
            obj.eventTime = (obj.eventTime) ? timestampToDate(Number.parseInt(obj.eventTime)) : '';
            obj.originalEventTime = (obj.originalEventTime) ? timestampToDate(Number.parseInt(obj.originalEventTime)) : '';
            obj.acknowledgedTime = (obj.acknowledgedTime) ? timestampToDate(Number.parseInt(obj.acknowledgedTime)) : '';
            obj.clearanceTime = (obj.clearanceTime) ? timestampToDate(Number.parseInt(obj.clearanceTime)) : '';


            if (type.toLowerCase() === "terminate") {
              self.removeAiAlarm(obj as IRealTimeAlarmSnapshot);
              // self.resetSelectedAlarm();
              self.setSelectedAlarm(obj);
            } else {
              self.resetSelectedAlarm();
              self.setSelectedAlarm(obj);
              self.updateAiAlarm(obj);
            }
            self.setUpdated();
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },
    updateAlarm: async (eventId: string, type: string) => {
      try {
        const alarmApi: RealTimeAlarmApi = new RealTimeAlarmApi(self.environment.api);
        const result: TGetRealTimeAlarmResult = await alarmApi.update(eventId, type);
        if (self.rootStore.responseStore.getResponseResult(result)) {
          if (result.alarm) {
            const obj = { ...result.alarm };
            obj.eventTime = obj.eventTime ? timestampToDate(Number.parseInt(obj.eventTime)) : '';
            obj.originalEventTime = obj.originalEventTime
              ? timestampToDate(Number.parseInt(obj.originalEventTime))
              : '';
            obj.acknowledgedTime = obj.acknowledgedTime
              ? timestampToDate(Number.parseInt(obj.acknowledgedTime))
              : '';
            obj.clearanceTime = obj.clearanceTime
              ? timestampToDate(Number.parseInt(obj.clearanceTime))
              : '';
            obj.eventDuration = obj.cleared
              ? (new Date(obj?.clearanceTime).getTime() - new Date(obj?.eventTime).getTime()) / 1000
              : (Date.now() - new Date(obj?.eventTime).getTime()) / 1000;

            let sourceEventTime = null;
            if (obj.additionalInformation) {
              let parsedSourceEventTime = JSON.parse(obj.additionalInformation).sourceEventTime;
              if (parsedSourceEventTime) {
                sourceEventTime = timestampToDate(Number.parseInt(parsedSourceEventTime));
              }
            }
            obj.sourceEventTime = sourceEventTime;

            self.resetSelectedAlarm();
            self.setSelectedAlarm(obj);
            self.updateAiAlarm(obj);

            self.setUpdated();
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },

    multiProc: async (list: string[], type: string = 'ack') => {
      try {
        const alarmApi: RealTimeAlarmApi = new RealTimeAlarmApi(self.environment.api);
        const result: TGetRealTimeAlarmsResult = await alarmApi.multiProc(list, type);

        if (self.rootStore.responseStore.getResponseResult(result)) {
          // self.setSelectedAlarm(result.alarm);
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },

    getAlarmSetup: async (monitorType: string) => {
      try {
        self.setAlarmSetup([]);
        const api: RealTimeAlarmApi = new RealTimeAlarmApi(self.environment.api);
        const result: TGetAlarmSetupResult = await api.getAlarmSetup(monitorType);

        if (self.rootStore.responseStore.getResponseResult(result)) {
          result.setup && self.setAlarmSetup(result.setup);
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },

    updateAlarmSetup: async (data: IAlarmSetupSnapshot[], files: File[]) => {
      try {
        const api: RealTimeAlarmApi = new RealTimeAlarmApi(self.environment.api);
        const result: any = await api.updateSetupData(data, files);

        // if (self.rootStore.responseStore.getResponseResult(result)) {

        //   result.setup && self.setAlarmSetup(result.setup);
        // }
        if (result.kind === 'ok') {
          console.log('success');
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },

    getAllAlarmSounds: async (monitorType: string) => {
      try {
        const api: RealTimeAlarmApi = new RealTimeAlarmApi(self.environment.api);

        for (const alarm of self.alarmSetup) {
          const result: any = await api.getAlarmSound(monitorType, alarm.svrt?.code as number);
          if (result.kind === 'ok') {
            let localSoundUrl = result.responseInfo.responseInfo 
            
            if(alarm.svrt?.code == 200601){
              self.setClearedAlarmSound(localSoundUrl)
            }else if(alarm.svrt?.code == 200602){
              self.setIndeterminateAlarmSound(localSoundUrl)
            }else if(alarm.svrt?.code == 200603){
              self.setCriticalAlarmSound(localSoundUrl)
            }else if(alarm.svrt?.code == 200604){
              self.setMajorAlarmSound(localSoundUrl)
            }else if(alarm.svrt?.code == 200605){
              self.setMinorAlarmSound(localSoundUrl)
            }else if(alarm.svrt?.code == 200606){
              self.setWarningAlarmSound(localSoundUrl)
            }else if(alarm.svrt?.code == 200607){
              self.setInformationAlarmSound(localSoundUrl)
            }
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },

    socketCommunication: async (ws: Client) => {
      ws.onConnect = (frame) => {
        ws.subscribe('/event', (data) => {     
          const msg = JSON.parse(data.body);
          if (msg.type === 'EVENT') {
            console.log('Recived websocket message', msg.data);
            const recived = { ...msg.data };

            if (recived.systemName === 'Multiview') {
              return;
            }

            if (recived.eventState.value !== 'Terminated') {
              const severity = recived.perceivedSeverity.value.toLowerCase();
              if (self.showSeverity.indexOf(severity) < 0) {
                return;
              }
            }

            try {
              if (typeof recived.eventTime == 'number') {
                recived.eventTime = recived.eventTime ? timestampToDate(Number.parseInt(recived.eventTime)) : '';
              } else if (recived.eventTime && typeof recived.eventTime == 'string') {
                recived.eventTime = moment(recived.eventTime).format('YYYY-MM-DD HH:mm:ss');
              }
              recived.originalEventTime = recived.originalEventTime ? timestampToDate(Number.parseInt(recived.originalEventTime)) : '';
              recived.acknowledgedTime = recived.acknowledgedTime ? timestampToDate(Number.parseInt(recived.acknowledgedTime)) : '';
              recived.clearanceTime = recived.clearanceTime ? timestampToDate(Number.parseInt(recived.clearanceTime)) : '';
            } catch (e) {
              console.log(e);
            }

            if (recived.eventId !== null) {

              try {

                if (recived.eventState.value == "Terminated") {
                  self.removeAiAlarm(recived as IRealTimeAlarmSnapshot);
                } else if (recived.eventState.value == "Cleared" || recived.eventState.value == 'Acknowledged') {
                  self.updateAiAlarm(recived as IRealTimeAlarmSnapshot);
                } else if (recived.perceivedSeverity.value == "Information")
                {
                  self.removeAiAlarm(recived as IRealTimeAlarmSnapshot);
                }
                else {
                  const alarm = self.alarms.filter((row: IRealTimeAlarmSnapshot) => { return row.eventId == recived.eventId })[0];
                  if (alarm) { // 리스트에 있으면 업데이트 없으면 추가
                    self.updateAiAlarm(recived as IRealTimeAlarmSnapshot);
                  } else {
                    self.addAiAlarm(recived as IRealTimeAlarmSnapshot);
                  }
                }

                if (self.useCount === true) {
                  self.setCounts();
                }
              } catch (e) {
                console.log(e);
              }
            }

            self.setUpdated();

          }
        });
      };

      ws.onStompError = (frame) => {
        console.log('WebSocket Error: ' + toJS(frame));
      };

      ws.activate();  
    },

    realtimeAlarmSocket: async (probableCauseList: string[], ws: Client) => {
      self.setProbableCauses(probableCauseList);

      ws.onConnect = (frame) => {
        ws.subscribe('/event', (data) => {

          const msg = JSON.parse(data.body);
          if (msg.type === 'EVENT') {
            const recived = { ...msg.data };
            console.log('event: ', recived);

            if (recived.eventId == null) {
              console.log('invalid eventId...');
              return;
            }

            let probableCauses = self.probableCauses;
            if (!probableCauses.includes(recived.probableCause)) {
              return;
            }

            const severityFilter = ['Critical', 'Major', 'Minor', 'Warning', 'Cleared'];
            const stateFilter = ['Outstanding', 'Acknowledged', 'Terminated'];

            const severity = recived.perceivedSeverity.value;
            const state = recived.eventState.value;

            if (!severityFilter.includes(severity)) {
              return;
            }

            if (!stateFilter.includes(state)) {
              return;
            }

            try {
              // websocket time is sent as string type!
              if (typeof recived.eventTime == 'number') {
                recived.eventTime = recived.eventTime
                  ? timestampToDate(Number.parseInt(recived.eventTime))
                  : '';
              } else if (recived.eventTime && typeof recived.eventTime == 'string') {
                recived.eventTime = moment(recived.eventTime).format('YYYY-MM-DD HH:mm:ss');
              }

              if (recived.cleared && typeof recived.clearanceTime == 'number') {
                recived.clearanceTime = recived.clearanceTime
                  ? timestampToDate(Number.parseInt(recived.clearanceTime))
                  : '';
              } else if (
                recived.cleared &&
                recived.clearanceTime &&
                typeof recived.clearanceTime == 'string'
              ) {
                recived.clearanceTime = moment(recived.clearanceTime).format('YYYY-MM-DD HH:mm:ss');
              } else {
                recived.clearanceTime = '';
              }

              const currentDate = moment();
              const eventDate = moment(recived.eventTime);
              const sameDay = currentDate.isSame(eventDate, 'day');

              if (!sameDay) {
                return;
              }

              if (recived.cleared) {
                let eventDuration =
                  new Date(recived.clearanceTime).getTime() - new Date(recived.eventTime).getTime();
                recived.eventDuration = eventDuration ? eventDuration / 1000 : null;
              } else {
                let currTime = Date.now()/1000
                let eventTime =  moment(recived.eventTime, "YYYY-MM-DD HH:mm:ss").unix()

                  if(currTime > eventTime){
                    recived.eventDuration = 0 
                  }else{
                    recived.eventDuration = currTime - eventTime
                  }
              }

              let sourceEventTime = null;
              if (recived.additionalInformation) {
                let parsedSourceEventTime = JSON.parse(
                  recived.additionalInformation,
                ).sourceEventTime;
                if (parsedSourceEventTime) {
                  sourceEventTime = timestampToDate(Number.parseInt(parsedSourceEventTime));
                }
              }
              recived.sourceEventTime = sourceEventTime;

              const alarm = self.alarms.filter((row: IRealTimeAlarmSnapshot) => {
                return row.eventId == recived.eventId;
              })[0];

              if (alarm) {
                // 리스트에 있으면 업데이트 없으면 추가
                self.updateAiAlarm(recived as IRealTimeAlarmSnapshot);
              } else {
                if(severity=="Cleared"){
                  return
                }
                self.addAiAlarm(recived as IRealTimeAlarmSnapshot);
              }

              if (self.useCount === true) {
                self.setCounts();
              }

              if(self.alarmSoundMute){ 
                return
              }

              if(state != 'Outstanding'){
                return;
              }
             
              let useYn: boolean | null = false
              let svrtCode = recived.perceivedSeverity.code; 
              useYn = self.alarmSetup.filter((item) => item.svrt?.code == svrtCode)[0].useYn;

              self.setAlarmSoundToPlay("")
              if (svrtCode == 200601) {
                useYn && self.setAlarmSoundToPlay(self.clearedAlarmSound as string)
              } else if (svrtCode == 200602) {
                useYn && self.setAlarmSoundToPlay(self.indeterminateAlarmSound as string)
              } else if (svrtCode == 200603) {
                useYn && self.setAlarmSoundToPlay(self.criticalAlarmSound as string)
              } else if (svrtCode == 200604) {
                useYn && self.setAlarmSoundToPlay(self.majorAlarmSound as string)
              } else if (svrtCode == 200605) {
                useYn && self.setAlarmSoundToPlay(self.minorAlarmSound as string)
              } else if (svrtCode == 200606) {
                useYn && self.setAlarmSoundToPlay(self.warningAlarmSound as string)
              } else if (svrtCode == 200607) {
                useYn && self.setAlarmSoundToPlay(self.informationAlarmSound as string)
              }
              
            } catch (e) {
              console.log(e);
            }
          }
        });
      };

      ws.onWebSocketClose = () =>{
        console.log("socket closed...") 
      }

      ws.onStompError = (frame) => {
        console.log('WebSocket Error: ' + toJS(frame));
      };

      ws.activate();
    },
  }));

type TRealTimeAlarmStore = Instance<typeof RealTimeAlarmStore>;
export interface IRealTimeAlarmStore extends TRealTimeAlarmStore { }
type TRealTimeAlarmStoreSnapshot = SnapshotOut<typeof RealTimeAlarmStore>;
export type TRealTimeAlarmStoreKeys = keyof TRealTimeAlarmStoreSnapshot & string;
export interface IRealTimeAlarmStoreSnapshot extends TRealTimeAlarmStoreSnapshot { }
export const createRealTimeAlarmStore = () =>
  types.optional(RealTimeAlarmStore, {} as TRealTimeAlarmStore);

  export interface IRealtimeMonitoringAlarmFilter {
    eventState?: number ; 
    perceivedSeverity?: number;
    probableCause?: string;
    eventStateList?: number []; 
    perceivedSeverityList?: number [];
    probableCauseList?: string [];
    targetInstanceId? : string;
    targetInstanceName? : string;
   
    specificProblem? : string;
    message?: string;
    eventTimeSt?: string;
    eventTimeEd?: string;
    clearanceTimeSt?: string;
    clearanceTimeEd?: string; 
    rca?: string;
    mvTimeSt?: string;
    mvTimeEd?: string;
  }

  // IChannelAlarmFilter