import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { createStb, IStb, Stb } from '../../models/stb/Stb';
import {
  StbApi,
  TCheckDuplicateSaid,
  TDeleteStbResult,
  TGetStbResult,
  TGetStbsResult,
  TPostStbResult,
  TUpdateStbResult,
} from '../../services';
import { timestampToDate } from '../../utils/dateExchanger';
import { withEnvironment } from '../extensions/with-environment';
import { createPagination, IPagination, IPaginationSnapshot } from '../pagination/Pagination';
import { createResponse } from '../response/Response';
import { IStbSnapshot } from '../stb/Stb';
import { withRootStore } from './../extensions/with-root-store';

export const StbStore = types
  .model('StbStore')
  .props({
    stbs: types.optional(types.array(Stb), []),
    selectedStb: createStb(),
    deleteStbList: types.optional(types.array(types.string), []),
    pagination: createPagination(),
    responseInfo: createResponse(),
    checkDuplicatedSaid: types.optional(types.boolean, false),
  })
  .extend(withEnvironment)
  .extend(withRootStore)
  .views((self) => ({}))
  .actions((self) => ({
    setStbs: (stbs: IStbSnapshot[]) => {
      self.stbs.replace(stbs as IStb[]);
    },
    setPagination: (pagination: IPaginationSnapshot) => {
      self.pagination = pagination as IPagination;
    },
    setSelectedStb: (stb: IStbSnapshot) => {
      self.selectedStb = stb as IStb;
    },
    setDeleteStbList: (deleteSid: string[]) => {
      self.deleteStbList.replace(deleteSid);
    },
    appendDeleteStbList: (deleteSid: string) => {
      self.deleteStbList.push(deleteSid);
    },
    setCheckDuplicatedSaid: (checkDuplicatedSaid: boolean) => {
      self.checkDuplicatedSaid = checkDuplicatedSaid;
    },
  }))
  .actions((self) => ({
    /**
     * INF_CAIMS_00150
     * 전체 모니터링 셋탑 목록을 조회한다.
     * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=515222615
     * @param stbFilter
     */
    gets: async (stbFilter?: TStbFilter) => {
      self.setStbs([]);
      try {
        const stbApi: StbApi = new StbApi(self.environment.api);
        let result: TGetStbsResult;

        if (stbFilter) {
          result = await stbApi.gets(self.pagination, stbFilter);
        } else {
          result = await stbApi.gets(self.pagination);
        }
        if (
          self.rootStore.responseStore.getResponseResult(result) &&
          result.pagination &&
          result.pagination.page === self.pagination.page
        ) {
          if (result.stbs) {
            result.stbs.forEach((item: IStbSnapshot, index: number) => {
              item.regDt = timestampToDate(Number.parseInt(item.regDt));
              item.updDt = timestampToDate(Number.parseInt(item.updDt));
              var opendate = timestampToDate(Number.parseInt(item.opnDt));
              item.opnDt = opendate.substr(0,10);
            });

            self.setStbs(result.stbs);

            if (result.pagination) {
              self.setPagination(result.pagination);
            }
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },

    /**
     * INF_CAIMS_00151
     * 해당 모니터링 셋탑의 상세정보를 조회한다.
     * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=246419288
     * @param said
     */
    get: async (said: string) => {
      try {
        const stbApi: StbApi = new StbApi(self.environment.api);
        const result: TGetStbResult = await stbApi.get(said);

        if (self.rootStore.responseStore.getResponseResult(result)) {
          if (result.stb) {
            result.stb.regDt = timestampToDate(Number.parseInt(result.stb.regDt));
            result.stb.updDt = timestampToDate(Number.parseInt(result.stb.updDt));
            var opendate = timestampToDate(Number.parseInt(result.stb.opnDt));
            result.stb.opnDt = opendate.substr(0,10);
            self.setSelectedStb(result.stb);
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },

    /**
     * INF_CAIMS_00152
     * 해로운 모니터링 셋탑을 등록한다.
     * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=2120536867
     * @param stb
     */
    post: async (stb: IStbSnapshot) => {
      try {
        const stbApi: StbApi = new StbApi(self.environment.api);
        const result: TPostStbResult = await stbApi.post(stb);

        if (self.rootStore.responseStore.getResponseResult(result)) {
          if (result.stb) {
            result.stb.regDt = timestampToDate(Number.parseInt(result.stb.regDt));
            result.stb.updDt = timestampToDate(Number.parseInt(result.stb.updDt));
            var opendate = timestampToDate(Number.parseInt(result.stb.opnDt));
            result.stb.opnDt = opendate.substr(0,10);
            self.setSelectedStb(result.stb);
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },

    /**
     * INF_CAIMS_00153
     * 해당 모니터링 셋탑 정보를 수정한다.
     * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=119626900
     * @param stb
     */
    put: async (stb: IStbSnapshot) => {
      try {
        const stbApi: StbApi = new StbApi(self.environment.api);
        const result: TUpdateStbResult = await stbApi.put(stb);

        if (self.rootStore.responseStore.getResponseResult(result)) {
          if (result.stb) {
            result.stb.regDt = timestampToDate(Number.parseInt(result.stb.regDt));
            result.stb.updDt = timestampToDate(Number.parseInt(result.stb.updDt));
            var opendate = timestampToDate(Number.parseInt(result.stb.opnDt));
            result.stb.opnDt = opendate.substr(0,10);
            self.setSelectedStb(result.stb);
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },

    /**
     * INF_CAIMS_00154
     * 해당 모니터링 셋탑을 삭제한다.
     * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=349850719
     * @param said
     */
    delete: async (said: string) => {
      try {
        const stbApi: StbApi = new StbApi(self.environment.api);
        const result: TDeleteStbResult = await stbApi.delete(said);
        self.rootStore.responseStore.getResponseResult(result);
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },

    /**
     * INF_CAIMS_00155
     * 여러개 모니터링 셋탑을 한번에 삭제한다.
     * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=1108389545
     */
    deletes: async () => {
      try {
        const stbApi: StbApi = new StbApi(self.environment.api);
        const result: TDeleteStbResult = await stbApi.deletes(self.deleteStbList);
        self.rootStore.responseStore.getResponseResult(result);
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },

    /**
     * INF_CAIMS_00244
     * 특정 모니터링STB 이름이 데이터베이스에 이미 존재하는지 확인한다.
     * https://docs.google.com/spreadsheets/d/1QTd3Cs8KHMdalNN4_D__ctwBx5KioETl/edit#gid=87312653
     * @param said
     */
    checkDuplicateSaid: async (said: string) => {
      try {
        const stbApi: StbApi = new StbApi(self.environment.api);
        const result: TCheckDuplicateSaid = await stbApi.checkDuplicateSaid(said);
        if (self.rootStore.responseStore.getResponseResult(result)) {
          if (result.result) {
            self.setCheckDuplicatedSaid(true);
          } else {
            self.setCheckDuplicatedSaid(false);
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },

    updateNames: (simulators: any) => {
      const newList = [...self.stbs];
      newList.map((stb) => {
        simulators.map((simulator: any) => {
          if (stb.said == simulator.SAID) {
            stb.simulatorName = simulator.NAME;
            return;
          }
        });
      });
      self.setStbs(newList);
    },
  }));

type TStbStore = Instance<typeof StbStore>;
type TStbStoreSnapshot = SnapshotOut<typeof StbStore>;

export interface IStbStore extends TStbStore {}
export type TStbStoreKeys = keyof TStbStoreSnapshot & string;
export interface IStbStoreSnapshot extends TStbStoreSnapshot {}
export const createStbStore = () => types.optional(StbStore, {} as TStbStore);

export interface IStbFilter {
  said?: string;
  stbMo?: string;
  helthySttus?: { code: 0; value: ''; pcode: 0 };
  ctg?: { code: 0; value: ''; pcode: 0 };
  lo?: { code: 0; value: ''; pcode: 0 };
  eventZapYn?: boolean;
}

export type TStbFilter = IStbFilter;
