import { createSimpleUser } from './../simple-user/SimpleUser';
import { Usrgrp, IUsrgrp } from './../usrgrp/Usrgrp';
import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { createModelAllRole } from '../model-allRole/ModelAllRole';
import { createModelCode, IModelCode, ModelCode } from '../model-code/ModelCode';
import { selectOptions } from '../../components/forms/CSelect';

/**
 * Model description here for TypeScript hints.
 */

export const UserModel = types
  .model('User')
  .props({
    userSid: types.optional(types.number, 0),
    loginId: types.optional(types.string, ''),
    userNm: types.optional(types.string, ''),

    pwd: types.optional(types.maybeNull(types.string), ''),

    pwdCorrect: types.optional(types.maybeNull(types.string), ''),
    acntDsabldDt: types.optional(types.maybeNull(types.number), 0),
    acntLockDt: types.optional(types.maybeNull(types.number), 0),
    loginFailCnt: types.optional(types.maybeNull(types.number), 0),
    loginTi1sFailDt: types.optional(types.maybeNull(types.number), 0),
    allUsrgrpRoleId: types.maybeNull(createModelAllRole()),
    acntExpDt: types.optional(types.maybeNull(types.number), 0),
    pwdUpdDt: types.optional(types.maybeNull(types.number), 0),
    externalLinkYn: types.optional(types.boolean, false),
    lastLoginDt: types.optional(types.maybeNull(types.string), ''),
    whtd: types.optional(types.boolean, false),
    whtdDt: types.optional(types.maybeNull(types.number), 0),
    sessn: types.optional(types.number, -1),
    sessionYn: types.optional(types.maybeNull(types.boolean), false),
    regDt: types.optional(types.string, ''),
    updDt: types.optional(types.string, ''),
    useYn: types.optional(types.boolean, false),
    usrgrpList: types.optional(types.array(Usrgrp), []),
    defaultUsrgrpOptions: types.optional(types.array(ModelCode), []),
    svcSttusCd: createModelCode(),
    acntSttsCd: createModelCode(),
    loginPrvntSttsCd: createModelCode(),
    usrgrpSidList: types.optional(types.array(createModelCode()), []),
    userGroupList: types.optional(types.array(types.number), []),
    userGroupNmList: types.optional(types.string, ''),
    loginIdDuplicate: types.optional(types.boolean, false),

    //구버전
    regId: types.optional(types.maybeNull(types.number), 0),
    updId: types.optional(types.maybeNull(types.number), 0),

    //신버전
    regUser: types.maybeNull(createSimpleUser()),
    updUser: types.maybeNull(createSimpleUser()),
    //검색조건
    regSdt: types.optional(types.number || types.string, 0),
    regEdt: types.optional(types.number || types.string, 0),
  })
  .views((self) => ({}))
  .actions((self) => ({
    resetUser: () => {
      self.userSid = 0;
      self.loginId = '';
      self.userNm = '';

      self.regDt = '';
      self.regId = 0;
      self.updDt = '';
      self.updId = 0;
      self.regSdt = 0;
      self.regEdt = 0;
      self.pwd = '';
      self.pwdCorrect = '';
      self.allUsrgrpRoleId = null;
      self.acntDsabldDt = 0;
      self.acntLockDt = 0;
      self.loginFailCnt = 0;
      self.loginTi1sFailDt = 0;
      self.acntExpDt = 0;
      self.pwdUpdDt = 0;
      self.lastLoginDt = '';
      self.whtd = false;
      self.sessn = -1;
      self.sessionYn = false;
      self.whtdDt = 0;
      self.usrgrpList.replace([] as IUsrgrp[]);
      self.defaultUsrgrpOptions.replace([]);
      self.svcSttusCd = ModelCode.create({
        code: 0,
        value: '',
      });
      self.acntSttsCd = ModelCode.create({
        code: 0,
        value: '',
      });
      self.loginPrvntSttsCd = ModelCode.create({
        code: 0,
        value: '',
      });
    },

    setProps: (props: { [key: string]: any }) => {
      const updated = { ...self, ...props } as IUser;
      (Object.keys(updated) as TUserTypeKey[]).forEach((key) => {
        if (typeof self[key] === typeof updated[key]) {
          //@ts-ignore
          self[key] = updated[key];
        } else {
          throw new Error(`${key.toString} Input Type Error`);
        }
      });
    },
    setDefaultUsrgrpOptions: (defaultUsrgrpOptions: IModelCode[]) => {
      self.defaultUsrgrpOptions.replace(defaultUsrgrpOptions);
    },
  }));

type TUserType = Instance<typeof UserModel>;
type TUserSnapshotType = SnapshotOut<typeof UserModel>;

export interface IUser extends TUserType {}
export type TUserTypeKey = keyof TUserSnapshotType & string;
export interface IUserSnapshot extends TUserSnapshotType {}
export const createUserDefaultModel = () =>
  types.optional(UserModel, {
    userSid: 0,
    loginId: '',
    userNm: '',

    regDt: '',
    regId: 0,
    updDt: '',
    updId: 0,
    regSdt: 0,
    regEdt: 0,
    pwd: '',
    pwdCorrect: '',
    externalLinkYn: false,
    allUsrgrpRoleId: null,
    acntDsabldDt: 0,
    acntLockDt: 0,
    loginFailCnt: 0,
    loginTi1sFailDt: 0,
    acntExpDt: 0,
    pwdUpdDt: 0,
    lastLoginDt: '',
    whtd: false,
    sessn: -1,
    sessionYn: false,
    whtdDt: 0,
    useYn: false,
    usrgrpList: [] as IUsrgrp[],
  });
