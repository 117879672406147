import { Instance, SnapshotOut, types } from "mobx-state-tree"

/**
 * # Gauge
 * 
 * Gauge을 설명하세요.
 */
export const OneviewGauge = types
  .model("OneviewGauge")
  // --------------------------------------------------------------------------
  .props({
    type: types.optional(types.string, ''),
    value: types.optional(types.number, 0),
    nowValue: types.maybeNull(types.number),
    preValue: types.maybeNull(types.number),
  })
  // --------------------------------------------------------------------------
  // MUTATEs ONLY! - 모델 상태를 변경
  // REQUESTs는 스토어 모델에 작성하세요.
  // ex) yarn gen model gauge --store
  .actions((self) => ({
    /*
     * 여러 prop을 한번에 수정
     */
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as IOneviewGauge;
      (Object.keys(newProps) as TOneviewGaugeKeys[]).forEach((key) => {
        // @ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type TOneviewGauge = Instance<typeof OneviewGauge>;
type TOneviewGaugeSnapshot = SnapshotOut<typeof OneviewGauge>

export interface IOneviewGauge extends TOneviewGauge {}
export type TOneviewGaugeKeys = keyof TOneviewGaugeSnapshot & string;
export interface IOneviewGaugeSnapshot extends TOneviewGaugeSnapshot {}
export const createGauge = () => 
  types.optional(OneviewGauge, {} as TOneviewGauge);
