import { Instance, SnapshotOut, types } from 'mobx-state-tree';

/**
 * # SocketMessage
 *
 * SocketMessage을 설명하세요.
 */
export const SocketMessage = types
  .model('SocketMessage')
  // --------------------------------------------------------------------------
  .props({
    type: types.optional(types.maybeNull(types.string), null),
    key: types.optional(types.maybeNull(types.string), null),
    nowCnt: types.optional(types.maybeNull(types.number), null),
    totalCnt: types.optional(types.maybeNull(types.number), null),
    workStep: types.optional(types.maybeNull(types.number), null),
    workStepNm: types.optional(types.maybeNull(types.string), null),
    title: types.optional(types.maybeNull(types.string), null),
    resultCode: types.optional(types.maybeNull(types.string), null),
    messages: types.optional(types.maybeNull(types.array(types.string)), null),
    trueLabel: types.optional(types.maybeNull(types.string), null),
    falseLabel: types.optional(types.maybeNull(types.string), null),
    answer: types.optional(types.maybeNull(types.string), null),
    useLoading: types.optional(types.maybeNull(types.boolean), true),
  })
  // --------------------------------------------------------------------------
  // MUTATEs ONLY! - 모델 상태를 변경
  // REQUESTs는 스토어 모델에 작성하세요.
  // ex) yarn gen model socketMessage --store
  .actions((self) => ({
    /*
     * 여러 prop을 한번에 수정
     */
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as ISocketMessage;
      (Object.keys(newProps) as TSocketMessageKeys[]).forEach((key) => {
        //@ts-ignore
        self[key] = newProps[key];
      });
    },
    resetObject: () => {
      self.type = '';
      self.key = '';
      self.nowCnt = NaN;
      self.totalCnt = NaN;
      self.workStep = NaN;
      self.workStepNm = '';
      self.title = '';
      self.resultCode = '';
      self.messages?.replace([] as string[]);
      self.trueLabel = '';
      self.falseLabel = '';
      self.answer = '';
    },
  }));

// --------------------------------------------------------------------------
type TSocketMessage = Instance<typeof SocketMessage>;
type TSocketMessageSnapshot = SnapshotOut<typeof SocketMessage>;

export interface ISocketMessage extends TSocketMessage {}
export type TSocketMessageKeys = keyof TSocketMessageSnapshot & string;
export interface ISocketMessageSnapshot extends TSocketMessageSnapshot {}
export const createSocketMessage = () =>
  types.optional(SocketMessage, {
    type: null,
    key: null,
    nowCnt: null,
    totalCnt: null,
    workStep: null,
    workStepNm: null,
    title: null,
    resultCode: null,
    messages: null,
    trueLabel: null,
    falseLabel: null,
    answer: null,
    useLoading: true,
  } as TSocketMessage);
