import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { IAlarmSnapshot, useStores } from '../../../models';

import '../Oneview.css';
import {
  NetworkDiagramContext,
  NetworkDiagramProvider,
  NetworkDiagramState,
  TNetworkDiagramContext,
  UUID,
  Viewport,
  ViewportData,
} from 'network-diagram';
import { CallApiToStore } from '../../Home';

export const AiAlarmsDetailTopology = observer(() => {
  const rootStore = useStores();
  const { alarmStore, realtimeAlarmStore, topologyStore, loadingStore } = rootStore;

  const [defaultValues, setDefaultValues] = useState<IAlarmSnapshot>(alarmStore.selectedAlarm);

  const methods = useForm<IAlarmSnapshot>({
    defaultValues,
  });
  const { getValues, setValue } = methods;

  let data: ViewportData;

  let topologyContext: TNetworkDiagramContext;
  const networkDiagramState: NetworkDiagramState = {
    theme: 'dark',
    linkMarkerColors: ['#FFF'],
    data: emptyData
  };

  const getTopology = () => {
    if (realtimeAlarmStore.selectedAlarm.targetInstanceId) {
      topologyStore.getChannelTopology(parseInt(realtimeAlarmStore.selectedAlarm.targetInstanceId)).then(() => {
        try {
          if(topologyStore.topology !== ''){
            const topology = JSON.parse(topologyStore.topology);
            data = topology as ViewportData;
          }
          topologyContext.setFile(data);
        } catch (e) {
          console.log(e)
        }
      });
    }
  }

  useEffect(() => {
    getTopology();
  }, []);


  return (
    <div className="oneview topology-only">
      <div className="oneview-content">
        <div className="oneview-content-main">
          <NetworkDiagramProvider value={networkDiagramState}>
            <NetworkDiagramContext.Consumer>
              {(_topologyContext) => {
                topologyContext = _topologyContext;
                return (<Viewport data={topologyContext.context.data}></Viewport>);
              }}
            </NetworkDiagramContext.Consumer>
          </NetworkDiagramProvider>
        </div>
      </div>

      <div className="oneview-topleft-deco">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 234.64 192.71">
          <path
            d="M230.89,19.69c-1.9,0-3.46,1.42-3.71,3.26H52.46l-24.62,24.62V185.95h-2.8v6.6h6.6v-6.6h-2.8V47.98l24.03-24.03H227.18c.25,1.84,1.8,3.26,3.71,3.26,2.08,0,3.76-1.68,3.76-3.76s-1.68-3.76-3.76-3.76Z"
            style={{ fill: '#aaffff44' }}
          />
          <polygon
            points="25.77 37.79 44.35 19.21 44.35 6.36 46.51 4.2 167.33 4.2 165.97 7.41 174.75 3.7 165.97 0 167.33 3.2 46.1 3.2 43.35 5.94 43.35 18.8 25.06 37.09 23.08 35.11 18.42 39.78 23.08 44.44 27.75 39.78 25.77 37.79"
            style={{ fill: '#aaffff44' }}
          />
          <polygon
            points="14.33 189.57 0 189.57 0 192.71 18 192.71 18 50.4 14.33 50.4 14.33 189.57"
            style={{ fill: '#aaffff44' }}
          />
          <path
            d="M5.67,185.56c2.08,0,3.76-1.68,3.76-3.76,0-1.9-1.42-3.46-3.26-3.71V38.23L30.62,13.77l1.94,1.94,.71-.71-4.6-4.6-.71,.71,1.94,1.94L5.17,37.81V178.09c-1.84,.25-3.26,1.8-3.26,3.71,0,2.08,1.68,3.76,3.76,3.76Z"
            style={{ fill: '#aaffff44' }}
          />
        </svg>
      </div>

      <div className="oneview-topleft-deco-br">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 234.64 192.71">
          <path
            d="M230.89,19.69c-1.9,0-3.46,1.42-3.71,3.26H52.46l-24.62,24.62V185.95h-2.8v6.6h6.6v-6.6h-2.8V47.98l24.03-24.03H227.18c.25,1.84,1.8,3.26,3.71,3.26,2.08,0,3.76-1.68,3.76-3.76s-1.68-3.76-3.76-3.76Z"
            style={{ fill: '#aaffff44' }}
          />
          <polygon
            points="25.77 37.79 44.35 19.21 44.35 6.36 46.51 4.2 167.33 4.2 165.97 7.41 174.75 3.7 165.97 0 167.33 3.2 46.1 3.2 43.35 5.94 43.35 18.8 25.06 37.09 23.08 35.11 18.42 39.78 23.08 44.44 27.75 39.78 25.77 37.79"
            style={{ fill: '#aaffff44' }}
          />
          <polygon
            points="14.33 189.57 0 189.57 0 192.71 18 192.71 18 50.4 14.33 50.4 14.33 189.57"
            style={{ fill: '#aaffff44' }}
          />
          <path
            d="M5.67,185.56c2.08,0,3.76-1.68,3.76-3.76,0-1.9-1.42-3.46-3.26-3.71V38.23L30.62,13.77l1.94,1.94,.71-.71-4.6-4.6-.71,.71,1.94,1.94L5.17,37.81V178.09c-1.84,.25-3.26,1.8-3.26,3.71,0,2.08,1.68,3.76,3.76,3.76Z"
            style={{ fill: '#aaffff44' }}
          />
        </svg>
      </div>

      <div className="oneview-bottomleft-deco">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 150 209.6">
          <path
            d="M143.4,203v2.8H28.29l-24.03-24.03V7.47c1.84-.25,3.26-1.8,3.26-3.71C7.52,1.68,5.84,0,3.76,0S0,1.68,0,3.76c0,1.9,1.42,3.46,3.26,3.71V182.18l24.62,24.62h115.52v2.8h6.6v-6.6h-6.6Z"
            style={{ fill: '#aaffff44' }}
          />
        </svg>
      </div>
      <div className="oneview-bottomleft-deco-tr">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 150 209.6">
          <path
            d="M143.4,203v2.8H28.29l-24.03-24.03V7.47c1.84-.25,3.26-1.8,3.26-3.71C7.52,1.68,5.84,0,3.76,0S0,1.68,0,3.76c0,1.9,1.42,3.46,3.26,3.71V182.18l24.62,24.62h115.52v2.8h6.6v-6.6h-6.6Z"
            style={{ fill: '#aaffff44' }}
          />
        </svg>
      </div>
    </div>
  );
});


const emptyData: ViewportData = {
  id: UUID.generate(),
  name: '빈문서',
  userData: {},
  readonly: false,
  published: false,
  state: 'CLEAN',
  nodes: {},
  links: [],
};